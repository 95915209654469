import React, { useState, useEffect } from 'react';
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import { get } from 'lodash';

interface Dropdown {
    label?: string;
    children: any;
    isDefaultOpen?: boolean;
    displayStyle?: string;
}

const Dropdown: React.FC<Dropdown> = (props) => {
    
    const [isOpen, dropdownClick] = useState(get(props, 'isDefaultOpen', true));
    
    useEffect(() => {
        if (props.isDefaultOpen && isOpen !== props.isDefaultOpen) {
            dropdownClick(props.isDefaultOpen);
        }
    }, [props.isDefaultOpen])

    return (
        <div className={"filter-dropdown-item " + (isOpen ? "" : "close-expense")} style={{display: props.displayStyle}}>
            {props.label ? <div className="filter-dropdown-item-title js-dropdown"  onClick={() => dropdownClick(!isOpen)}>{props.label}
                <SvgIcon className={'icon icon-arrow-top'} href={"#svg_icon_arrow_top"} />
            </div> : null}
            <div className="filter-dropdown-item-content">
              {props.children}
            </div>
        </div>
    );
};

export default Dropdown;