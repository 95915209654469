import TextCutter from 'app/component/text-cutter/TextCutter';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Config, { ConfigTypes } from 'services/ConfigService';
import { IAO } from 'infrastructure/interfaces';
import { useTranslation } from 'react-i18next';
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import { formatter, nameFormatter, NameFormats, isSentenceContainsMaxLengthWord, betweenSeveralDates } from 'app/utils';
import AOTravel from './AOTravel';
import { get, chunk, uniq } from 'lodash';
import If from 'app/component/util/If';
import { loaderLock, loaderUnlock } from "store/common/actions";
import { showErrors } from 'store/exception/actions';
import { ADVANCE_APPLICATION_PROPETIES } from 'infrastructure/enum/object-properties.enum';
import qs from 'qs';
import {getAdvanceReportApplication, getCommonUserDetail} from "../../../store/selectors";
import {
  getCustomProperties, getCustomPropertiesById,
  getCustomPropertiesCodes,
  getCustomPropertiesDependencies, getCustomPropertiesValuesByIds, getLinkToExternalApplication,
  getLogicalName
} from "../../../services/ApiService";

import {RequestCost} from "./AODetail";

interface ICP {
  id: number,
  name: {
    ru: string,
    en: string,
  },
  codeName: string,
  value: string[],
  isVisible: boolean
}

interface AOHeaderProps {
  id: number,
  type: string,
  toggleEmployeeInfo: () => void
}


const AOHeader: React.FC<AOHeaderProps> = (props) => {

  const { t, i18n } = useTranslation();

  const advanceReportApplication: IAO = useSelector(getAdvanceReportApplication);
  const user = useSelector(getCommonUserDetail);

  let [isTrip, setIsTrip] = useState(false);
  let [cPropertiesValue, setCPropertiesValue] = useState([] as ICP[]);

  const dispatch = useDispatch();

  let [fieldAccess, setFieldAccess] = useState({
    assigneeEmployee: false,
    assigneeEmployeeCP: false,
    businessTripOption: false,
    number: false,
    associatedExpenseApplication_Number: false,
    type: false,
    orderNumber: false,
    journeyNumber: false,
    businessTripNumber: false,
    businessTarget: false,
    description: false,
    vbsElement: false,
    departureFromCity: false,
    arrivalToCity: false,
    startOn: false,
    endOn: false,
    payer: false,
    personalDaysNumber: false,
    personalDays: false,
    personalDaysUpdate: false,
    advanceCashAmount: false,
    totalAmount: false,
    flowHistory: false,
    status: false
  });

  useEffect(() => {
    isBusinessTrip();
    generateFieldsAccess();
    if (advanceReportApplication) {
      getCPropertysValue();
    }
  }, [advanceReportApplication])

  const isBusinessTrip = () => {
    let linkToTimeEnabledPermission = Config.getConfigToCompany(ConfigTypes.LINK_TO_TIME_ENABLED, advanceReportApplication.company.id);
    setIsTrip(linkToTimeEnabledPermission && advanceReportApplication.journey.journeyNumber != 0 && advanceReportApplication.journey.businessTripNumber != 0)
  };

  const getLogicalNameId = async () => {
    let result = await getLogicalName({ params: { NameEn: 'AdvanceReportApplication' } });

    if (result.headers.success) {
      return result.data[0].id;
    } else {
      return 0;
    }
  }

  const getCPropertysValue = async () => {
    dispatch(loaderLock());
    if (advanceReportApplication) {
      let logicalNameId = await getLogicalNameId();
      if (!logicalNameId) {
        dispatch(showErrors({ code: '', message: 'Не удалось получить доп. данные' }));
        return;
      }
      let cPRequest = await getCustomProperties( { params: { CompanyId: advanceReportApplication.company.id, ObjectLogicalNameId: logicalNameId } });
      if (cPRequest.headers.success) {
        const codeNamesList = cPRequest.data.map((property: any) => property.codeName);
        const propertysName = cPRequest.data.map((property: any) => {
          let itemType = 'text';

          if (property.isList) {
            itemType = 'select'
          }

          if (property.isAllocationAvailable) {
            itemType = 'allocation'
          }
          return {
            id: property.id,
            name: {
              ru: property.clientNameRu,
              en: property.clientNameEn,
            },
            codeName: property.codeName,
            type: itemType,
            isVisible: property.isVisible,
            ordinal: property.ordinal
          }

        });
        let result = await getCustomPropertiesCodes({
          params: {
            ObjectLogicalNameId: logicalNameId,
            ExternalObjectId: advanceReportApplication.id,
            CustomPropertyCodes: codeNamesList,
          },
          paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
        });
        if (result.headers.success) {
          const propertysValues = result.data.map((item: any) => {
            let itemType = 'text';
            let listValueId = 0;

            if (item.isList) {
              itemType = 'select'
              listValueId = item.listValueId;
            }

            if (item.isAllocation) {
              itemType = 'allocation'
              listValueId = item.listValueId;
            }

            let valueProperty = [item.nameRu];
            if (item.isAllocation) {
              valueProperty = [`${item.nameRu} - ${item.percent}%`];
            }

            return {
              id: item.customPropertyId,
              value: valueProperty,
              type: itemType,
              listValueId: listValueId,
              isVisible: item.isVisible
            }
          });
          let cpValues = propertysName.map((property: any) => {
            const valuePropertyItem = {
              value: propertysValues.filter((item: any) => item.id === property.id).map((item: any) => item.value).flatMap((item: any) => item)
            };
            return {
              id: property.id,
              name: property.name,
              codeName: property.codeName,
              value: valuePropertyItem ? valuePropertyItem.value : null,
              isVisible: property.isVisible,
              ordinal: property.ordinal
            }
          });
          let dependentCPValues = await getDependencyCPValues(propertysValues.filter((item: any) => (item.type == 'select' || item.type == 'allocation')).map((item: any) => item.listValueId));
          let cpValuesWithDependent = [...cpValues, ...dependentCPValues as any[]];
          setCPropertiesValue(cpValuesWithDependent);
        } else {
          dispatch(showErrors({ code: '', message: 'Не удалось получить доп. данные' }));
          return;
        }
      } else {
        dispatch(showErrors({ code: '', message: 'Не удалось получить доп. данные' }));
      }
    }
    dispatch(loaderUnlock());
  }

  const getDependencyCPValues = async (listValueIds: number[]): Promise<any> => {
    let logicalNameId = await getLogicalNameId();
    if (!logicalNameId) {
      dispatch(showErrors({ code: '', message: 'Не удалось получить доп. данные' }));
      return [];
    }

    if (!listValueIds.length) {
      return [];
    }

    let cPRequest = await getCustomPropertiesDependencies({
      params: { CustomPropertyListValueIds: listValueIds },
      paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
    if (cPRequest.headers.success && cPRequest.data[0]) {
      let dependent = cPRequest.data;
      let newCPIds = [] as number[];
      let newCPListValueIds = [] as number[];

      for (let indexDCP = 0; indexDCP < dependent.length; indexDCP++) {
        const dependentElement = dependent[indexDCP];
        newCPIds = [...newCPIds, ...dependentElement.propertyOnValue];
        newCPListValueIds = [...newCPListValueIds, ...dependentElement.valueOnValue];

      }

      if (newCPListValueIds.length) {
        let resultListValueCP = await getCustomPropertiesValuesByIds({
          params: { Ids: uniq(newCPListValueIds) },
          paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
        });

        if (resultListValueCP.headers.success) {
          let resultListValueCPIds = resultListValueCP.data.map((item: any) => item.customPropertyId);
          newCPIds = [...newCPIds, ...resultListValueCPIds];
        }
      }

      newCPIds = uniq(newCPIds);

      let newDependentCP = [];

      for (let indexNewCP = 0; indexNewCP < newCPIds.length; indexNewCP++) {
        let newCPIdsElement = newCPIds[indexNewCP];
        let cpIdsResult = await getCustomPropertiesById(newCPIdsElement);
        if (cpIdsResult.headers.success) {
          let cpType = 'text';

          if (cpIdsResult.data.isList) {
            cpType = 'select'
          }

          if (cpIdsResult.data.isAllocationAvailable) {
            cpType = 'allocation'
          }
          newDependentCP.push({
            id: cpIdsResult.data.id,
            editId: 0,
            name: { ru: cpIdsResult.data.clientNameRu, en: cpIdsResult.data.clientNameEn },
            type: cpType,
            codeName: cpIdsResult.data.codeName,
            isVisible: cpIdsResult.data.isVisible
          });
        }
      }

      let newDependentCPCodes = newDependentCP.map(item => item.codeName);

      if (newDependentCPCodes.length) {
        let codesResult = await getCustomPropertiesCodes({
          params: {
            ObjectLogicalNameId: logicalNameId,
            ExternalObjectId: advanceReportApplication.id,
            CustomPropertyCodes: newDependentCPCodes
          },
          paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
        });
        if (codesResult.headers.success) {
          const propertysValues = codesResult.data.map((item: any) => {
            let itemType = 'text';
            let listValueId = 0;

            if (item.isList) {
              itemType = 'select'
              listValueId = item.listValueId;
            }

            if (item.isAllocation) {
              itemType = 'allocation'
              listValueId = item.listValueId;
            }

            let valueProperty = [item.nameRu];
            if (item.isAllocation) {
              valueProperty = [`${item.nameRu} - ${item.percent}%`];
            }

            return {
              id: item.customPropertyId,
              value: valueProperty,
              listValueId: listValueId,
              type: itemType
            }
          });
          let cpValues = newDependentCP.map((property: any) => {
            const valuePropertyItem = {
              value: propertysValues.filter((item: any) => item.id === property.id).map((item: any) => item.value).flatMap((item: any) => item)
            };
            return {
              id: property.id,
              name: property.name,
              codeName: property.codeName,
              value: valuePropertyItem ? valuePropertyItem.value : null,
              isVisible: property.isVisible
            }
          });


          let dependentCPValues: any[] = await getDependencyCPValues(propertysValues.filter((item: any) => (item.type == 'select' || item.type == 'allocation')).map((item: any) => item.listValueId));

          if (dependentCPValues) {
            let cpValuesWithDependent: any[] = [...cpValues, ...dependentCPValues];
            return cpValuesWithDependent;
          }
        }
      }
      return [];
    }
    return [];
  }

  const goToTimeHandler = async () => {
    dispatch(loaderLock());
    let result = await getLinkToExternalApplication(advanceReportApplication.journey.journeyNumber, advanceReportApplication.journey.businessTripNumber);
    if (result.headers.success) {
      let redirectURL = get(result, 'data', '/');
      window.open(redirectURL, '_blank');
      // window.location.replace(redirectURL);
      dispatch(loaderUnlock());
    } else {
      dispatch(loaderUnlock());
      dispatch(showErrors({ code: 'takeInHand_action', message: 'Не удалось выполнить переход' }));
    }
  }

  const generateFieldsAccess = () => {
    if (advanceReportApplication && advanceReportApplication.properties) {
      let fieldAccess = {
        assigneeEmployee: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ASSIGNEE_EMPLOYEE),
        assigneeEmployeeCP: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ASSIGNEE_EMPLOYEE_CP),
        businessTripOption: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.BUSINESS_TRIP_OPTION),
        number: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.NUMBER),
        associatedExpenseApplication_Number: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.EXPENSE_APPLICATION_NUMBER),
        type: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.APPLICATION_TYPE),
        orderNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ORDER_NUMBER),
        journeyNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.JOURNEY_NUMBER),
        businessTripNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.BUSINESS_TRIP_NUMBER),
        businessTarget: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.BUSINESS_TARGET),
        description: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.DESCRIPTION),
        vbsElement: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.VBS_ELEMENT),
        departureFromCity: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.DEPARTURE_FROM_CITY),
        arrivalToCity: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ARRIVAL_TO_CITY),
        startOn: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.START_ON),
        endOn: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.END_ON),
        payer: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.PAYER),
        personalDaysNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.PERSONAL_DAYS_NUMBER),
        personalDays: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.PERSONAL_DAYS),
        personalDaysUpdate: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.PERSONAL_DAYS_UPDATE),
        advanceCashAmount: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ADVANCE_CASH_AMOUNT),
        totalAmount: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.TOTAL_AMOUNT),
        flowHistory: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.FLOW_HISTORY),
        status: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.STATUS),
      }
      setFieldAccess(fieldAccess);
      return;
    }
  }

  const splitValue = (text: string) => {
    let value = '';

    for (let i = 0; i < text.length; i++) {
      if (i % 27 === 0) {
        value += '\r\n';
      }

      value += text[i];
    }

    return value;
  }

  const cpRender = () => {
    let cpValues = [...cPropertiesValue];

    if (cpValues.length === 0) {
      return;
    }

    cpValues = cpValues
      .filter(item => item.isVisible)
      .filter(item => item.value)
      .filter(item => advanceReportApplication.properties.includes(`CustomProperty_${item.codeName}`))
      .sort((a: any, b: any) => a.ordinal > b.ordinal ? 1 : -1);

    return chunk(cpValues, 4).map((propertyPart: ICP[]) => {
      while (propertyPart.length < 4) {
        propertyPart.push({
          id: 0,
          name: { ru: '', en: '' },
          codeName: '',
          value: [],
          isVisible: true
        });
      }

      return (
        <div className="request-item-row">
          <div className="request-item-block-wrap" style={{justifyContent: 'space-between'}}>
            {propertyPart.map((property: ICP) => {
              return property.value && property.value.length ? (
                <div
                  className={`request-item-block`}
                  style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", width: '50%', minWidth: 220, overflowWrap: "break-word" }}
                >
                  <div className="request-item-label">{property.name[i18n.language as 'ru' | 'en']}</div>
                  <div className="request-item-content">
                    {property.value.map((item: string) =>
                      <div className="request-item-content" style={{ width: '50%', minWidth: 220, overflowWrap: "break-word" }}>
                        {item}
                      </div>)}
                  </div>
                </div>
              ): null
            })}
          </div>
        </div>
      )
    })
  }

  return props.type === 'Travel' ? (
      <div className="request-header-wrapper">
        <div>
      <div className="request-item-row">
        <div className="request-item-block-wrap" style={{justifyContent: 'space-between'}}>
          <div className={`request-item-block ${fieldAccess.businessTripNumber ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
            <div className="request-item-label">{t('request_detail.businessTrip')}</div>
            <div className={`request-item-content underlined ${isTrip ? 'pointer' : ''}`} onClick={isTrip ? goToTimeHandler : () => { }}>№{advanceReportApplication.journey.journeyNumber}/{advanceReportApplication.journey.businessTripNumber}</div>          </div>
          <div className={`request-item-block ${fieldAccess.assigneeEmployee ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
            <div className="request-item-label">
              {t('request_detail.employee')}
              <div onClick={props.toggleEmployeeInfo}>
                <SvgIcon className="icon icon-warning pointer" href="#svg_icon_warning" />
              </div>
            </div>
            <div className="request-item-content">{nameFormatter(advanceReportApplication.assigneeEmployee.name, NameFormats.FULL, i18n.language)}</div>
            <div className="request-item-other">
              <div>{advanceReportApplication.assigneeEmployee.phoneNumber}</div>
              <a href={`mailto:${advanceReportApplication.assigneeEmployee.email}`} tabIndex={-1}>{advanceReportApplication.assigneeEmployee.email}</a>
            </div>
          </div>
          <div className={`request-item-block ${fieldAccess.departureFromCity ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
            <div className="request-item-label">{t('request_detail.citiesOfDepartureOrDestination')}</div>
            <div className="request-item-content">{`${advanceReportApplication.departureFromCityId.name[i18n.language as 'ru' | 'en']} — ${advanceReportApplication.businessTripDestinations.length > 0 ? advanceReportApplication.businessTripDestinations[0].city.name[i18n.language as 'ru' | 'en'] : advanceReportApplication.arrivalToCityId.name[i18n.language as 'ru' | 'en']}`}</div>
            {advanceReportApplication.businessTripDestinations.filter((road, index) => { return index != 0 }).map((road: any) => {
              return (<div className="request-item-content">{get(road, 'city.name.' + i18n.language, 'null')}</div>)
            })}
          </div>
          <div className={`request-item-block ${fieldAccess.startOn ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
            <div className="request-item-label">{t('request_detail.datesOfDepartureOrReturn')} ({advanceReportApplication.businessTripDestinations.length == 0 ? betweenSeveralDates([{ startOn: advanceReportApplication.startOn, endOn: advanceReportApplication.endOn }]) : betweenSeveralDates(advanceReportApplication.businessTripDestinations.map((item: any) => ({ startOn: item.arrivalOn, endOn: item.leaveOn })))}д.)</div>
            <If condition={advanceReportApplication.businessTripDestinations.length == 0}>
              <div className="request-item-content">{formatter('D MMMM', i18n.language, advanceReportApplication.startOn, advanceReportApplication.endOn, { withYear: true, withMonth: true })}</div>
            </If>
            {advanceReportApplication.businessTripDestinations.map((road: any) => {
              return <div className="request-item-content">{formatter('D MMMM', i18n.language, get(road, 'arrivalOn', 'null'), get(road, 'leaveOn', 'null'), { withYear: true, withMonth: true })}</div>
            })}
          </div>
        </div>
      </div>
      <div className="request-item-row">
        <div className="request-item-block-wrap" style={{ justifyContent: 'space-between' }}>
          <div className="request-item-block" style={{ width: '50%', minWidth: 220 }}>
            <div className="request-item-label">{t('request_detail.payer')}</div>
            <div className="request-item-content">{advanceReportApplication.company.name[i18n.language as 'en' | 'ru']}</div>
          </div>
          <div className={`request-item-block ${fieldAccess.businessTarget ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
            <div className="request-item-label">{t('report_detail.business_goal')}</div>
            <div className="request-item-content">{advanceReportApplication.businessTarget.name[i18n.language as 'en' | 'ru']}</div>
          </div>
          {advanceReportApplication.description &&
          <div className={`request-item-block place ${fieldAccess.description ? '' : 'd-none'}`}
               style={{width: '50%', minWidth: 220}}>
            <div className="request-item-label">{t('request_detail.description')}</div>
            <div className="request-item-content">
              {isSentenceContainsMaxLengthWord(advanceReportApplication.description) ?
                  <TextCutter text={splitValue(advanceReportApplication.description)}
                              maxLength={100}/> : advanceReportApplication.description}
            </div>
          </div>}
        </div>
      </div>
      {cpRender()}
        </div>
        <div className="request-header-right-wrapper">
          <div className="request-header-right">
            <div className="request-item-block payer" style={{ width: '100%', marginTop: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: fieldAccess.personalDays ? 'space-between' : 'flex-end'}}>
              <AOTravel dates={advanceReportApplication.applicationTravelDates} personalDaysNumber={advanceReportApplication.personalDaysNumber}  isVisible={fieldAccess.personalDays} canEdit={fieldAccess.personalDaysUpdate} />
              <RequestCost advanceCashAmount={advanceReportApplication.advanceCashAmount}
                           totalAmount={advanceReportApplication.totalAmount}
                           approvedAmount={advanceReportApplication.approvedAmount}
                           status={advanceReportApplication.status}
                           approvedTotalAmount={advanceReportApplication.approvedTotalAmount}
                           approvedReportAmount={advanceReportApplication.approvedReportAmount} />
            </div>
          </div>
        </div>
      </div>
  ) : (
      <div className="request-header-wrapper">
        <div>
        <div className="request-item-row">
          <div className="request-item-block-wrap" style={{justifyContent: 'space-between'}}>
            <div className="request-item-block payer" style={{ width: '50%', minWidth: 220 }}>
              <div className="request-item-label">
                {t('request_detail.employee')}
                <div onClick={props.toggleEmployeeInfo}>
                  <SvgIcon className="icon icon-warning pointer" href="#svg_icon_warning" />
                </div>
              </div>
              <div className="request-item-content">{nameFormatter(advanceReportApplication.assigneeEmployee.name, NameFormats.FULL, i18n.language)}</div>
              <div className="request-item-other">
                <div>{advanceReportApplication.assigneeEmployee.phoneNumber}</div>
                <a href={`mailto:${advanceReportApplication.assigneeEmployee.email}`} tabIndex={-1}>{advanceReportApplication.assigneeEmployee.email}</a>
              </div>
            </div>
            <div className="request-item-block payer" style={{ width: '50%', minWidth: 220 }}>
              <div className="request-item-label">{t('request_detail.payer')}</div>
              <div className="request-item-content">{advanceReportApplication.company.name[i18n.language as 'en' | 'ru']}</div>
            </div>
            <div className={`request-item-block ${fieldAccess.description ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
              <div className={`${fieldAccess.businessTarget ? '' : 'd-none'}`}>
                <div className="request-item-label">{t('report_detail.business_goal')}</div>
                <div className="request-item-content">{advanceReportApplication.businessTarget.name[i18n.language as 'en' | 'ru']}</div>
              </div>
            </div>
            {advanceReportApplication.description && <div className={`request-item-block payer ${fieldAccess.description ? '' : 'd-none'}`}
                  style={{width: '50%', minWidth: 220}}>
              <div className="request-item-label">{t('request_detail.description')}</div>
              <div className="request-item-content">
                {isSentenceContainsMaxLengthWord(advanceReportApplication.description) ?
                    (<TextCutter text={splitValue(advanceReportApplication.description)}
                                 maxLength={100}/>) : advanceReportApplication.description}
              </div>
            </div>
              }
          </div>
        </div>
        {cpRender()}
        </div>
        <div className="request-header-right-wrapper">
          <div className="request-header-right">
            <div className="request-item-block payer" style={{ marginTop: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
              <RequestCost advanceCashAmount={advanceReportApplication.advanceCashAmount}
                           totalAmount={advanceReportApplication.totalAmount}
                           approvedAmount={advanceReportApplication.approvedAmount}
                           status={advanceReportApplication.status}
                           approvedTotalAmount={advanceReportApplication.approvedTotalAmount}
                           approvedReportAmount={advanceReportApplication.approvedReportAmount} />
            </div>
          </div>
        </div>
      </div>
  );
}

export default AOHeader;
