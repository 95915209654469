import React, { useEffect, useState } from "react";
import "./Daily.css";
import { useTranslation } from "react-i18next";
import If from "../util/If";
import { formatter, mergeDatesToPeriods } from "app/utils";
import NutritionPickerCalendar from "../date-picker/NutritionPickerCalendar";
import { Button } from "react-bootstrap";

interface NutritionDayProps {
  nutritionDay: {
    date: Date;
    mark: number;
    isPrivateDay: boolean;
    isRepresentationExpenses: boolean;
    numberOfMeal: number;
    isFoodPresent: boolean;
  }[];
  onChangeCount: (date: Date) => void;
  from: Date;
  to: Date;
  disabled?: boolean;
  isWidget?: boolean;
}

const NutritionDay: React.FC<NutritionDayProps> = (props) => {
  const { t, i18n } = useTranslation();

  let [isShowDatePicker, setShowDatePicker] = useState(false);
  let [allNutritionDates, setAllNutritionDates] = useState(
    [] as { date: Date; count: number }[]
  );
  let [nutritionDatesOne, setNutritionDatesOne] = useState("");
  let [nutritionDatesTwo, setNutritionDatesTwo] = useState("");
  let [nutritionDatesTree, setNutritionDatesTree] = useState("");

  useEffect(() => {
    let newAllNutritionDates = props.nutritionDay
      .filter((item) => !item.isPrivateDay)
      .map((item) => ({ date: item.date, count: item.numberOfMeal }));

    let newNutritionDatesOne = [] as { date: Date; count: number }[];
    let newNutritionDatesTwo = [] as { date: Date; count: number }[];
    let newNutritionDatesTree = [] as { date: Date; count: number }[];

    newAllNutritionDates.forEach((item) => {
      item.count === 1 && newNutritionDatesOne.push(item);
      item.count === 2 && newNutritionDatesTwo.push(item);
      item.count === 3 && newNutritionDatesTree.push(item);
    });

    let periodOne = mergeDatesToPeriods(
      newNutritionDatesOne.map((item) => item.date)
    );
    let periodTwo = mergeDatesToPeriods(
      newNutritionDatesTwo.map((item) => item.date)
    );
    let periodTree = mergeDatesToPeriods(
      newNutritionDatesTree.map((item) => item.date)
    );

    let periodOneString = [] as string[];
    let periodTwoString = [] as string[];
    let periodTreeString = [] as string[];

    periodOne.forEach((item) => {
      periodOneString.push(
        formatter("D MMMM", i18n.language, item.from, item.to, {
          withMonth: false,
          withYear: false,
        })
      );
    });

    periodTwo.forEach((item) => {
      periodTwoString.push(
        formatter("D MMMM", i18n.language, item.from, item.to, {
          withMonth: false,
          withYear: false,
        })
      );
    });

    periodTree.forEach((item) => {
      periodTreeString.push(
        formatter("D MMMM", i18n.language, item.from, item.to, {
          withMonth: false,
          withYear: false,
        })
      );
    });

    setNutritionDatesOne(periodOneString.join(", "));
    setNutritionDatesTwo(periodTwoString.join(", "));
    setNutritionDatesTree(periodTreeString.join(", "));
    setAllNutritionDates(newAllNutritionDates);
  }, [props.nutritionDay, i18n.language]);

  const toggleDatePicker = () => {
    setShowDatePicker(!isShowDatePicker);
  };

  const onSelect = (date: Date) => {
    props.onChangeCount(date);
  };

  return (
    <>
      <label className="input-label">
        {t("create_expense.daily_allowance.nutrition_day")}
      </label>
      <div className="request-item-content">
        <NutritionPickerCalendar
          isShown={isShowDatePicker}
          onSelect={onSelect}
          onClose={toggleDatePicker}
          nutritionDates={allNutritionDates}
          disableBeforeDate={props.from}
          disableAfterDate={props.to}
        />
        <div className="input-choose">
          <If condition={nutritionDatesOne !== ""}>
            <div className="input-choose-item">
              {t("create_expense.daily_allowance.nutrition_day_count_1", {
                dates: nutritionDatesOne,
              })}
            </div>
          </If>
          <If condition={nutritionDatesTwo !== ""}>
            <div className="input-choose-item">
              {t("create_expense.daily_allowance.nutrition_day_count_2", {
                dates: nutritionDatesTwo,
              })}
            </div>
          </If>
          <If condition={nutritionDatesTree !== ""}>
            <div className="input-choose-item">
              {t("create_expense.daily_allowance.nutrition_day_count_3", {
                dates: nutritionDatesTree,
              })}
            </div>
          </If>
          <If condition={!props.disabled}>
            {props.isWidget ? (
              <Button
                variant={"outline-secondary"}
                className="daily"
                onClick={toggleDatePicker}
              >
                + {t("create_expense.daily_allowance.change")}
              </Button>
            ) : (
              <div
                className="btn-expense btn_gray add-trip daily"
                onClick={toggleDatePicker}
              >
                + {t("create_expense.daily_allowance.change")}
              </div>
            )}
          </If>
        </div>
      </div>
    </>
  );
};

export default NutritionDay;
