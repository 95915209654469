import { BaseMapper } from "./base.mapper";
import { ApprovalSheet } from "infrastructure/interfaces";
import { ApprovalTierMapper } from "infrastructure/mappers";
import { get } from "lodash";

export class ApprovalSheetMapper extends BaseMapper<ApprovalSheet, any> {
  responseToEntity(response: any): ApprovalSheet {
    const approvalTierMapper = new ApprovalTierMapper();

    return {
      id: get(response, 'approvalSheet.id', 0),
      orderNumber: get(response, 'approvalSheet.orderNumber', 0),
      currentApprovalTierId: get(response, 'approvalSheet.currentApprovalTierId', 0),
      approvalTiers: approvalTierMapper.responsesToEntitys(get(response, 'approvalTiers', []))
    }
  };
}