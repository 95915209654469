import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import CreateOrderModal from "app/component/modal/CreateOrderModal";
import { showErrors } from "store/exception/actions";
import {
  statusNameMap,
  STATUS_TYPE,
} from "infrastructure/enum/status-type.enum";
import { getApplicationForExpenseDetail } from "../../../../store/selectors";
import { EXPENSE_APPLICATION_PROPETIES } from "../../../../infrastructure/enum/object-properties.enum";

interface ApplicationExpensesEmptyProps {
  id: number;
  isWidget?: boolean;
}

const ApplicationExpensesEmpty: React.FC<ApplicationExpensesEmptyProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let [isOpenAddExpenseModal, setOpenAddExpenseModal] = useState(false); //открытие формы добавление расходов

  const applicationForExpenseDetail = useSelector(
    getApplicationForExpenseDetail
  );

  const clickAddExpenseModalHandler = () => {
    if (applicationForExpenseDetail) {
      if (
        applicationForExpenseDetail.status === STATUS_TYPE.APPROVED ||
        applicationForExpenseDetail.status === STATUS_TYPE.ON_APPROVAL
      ) {
        dispatch(
          showErrors({
            code: "",
            message: t("status_error_message.status_expense_edit_EA", {
              status: t(
                `status.${
                  statusNameMap[
                    applicationForExpenseDetail.status as STATUS_TYPE
                  ]
                }`
              ),
            }),
          })
        );
      } else {
        if (
          applicationForExpenseDetail.status === STATUS_TYPE.POSTED ||
          applicationForExpenseDetail.status === STATUS_TYPE.REPORT_PREPARED ||
          applicationForExpenseDetail.status === STATUS_TYPE.EXECUTED
        ) {
          dispatch(
            showErrors({
              code: "",
              message: t("status_error_message.status__expense_edit_EA", {
                status: t(
                  `status.${
                    statusNameMap[
                      applicationForExpenseDetail.status as STATUS_TYPE
                    ]
                  }`
                ),
              }),
            })
          );
        } else {
          setOpenAddExpenseModal(!isOpenAddExpenseModal);
        }
      }
    }
  };

  return applicationForExpenseDetail ? (
    <>
      <div className="request-expenses-empty">
        <div className="tx-center">
          <img src="/img/empty-img-2.png" alt="" />
        </div>
        {applicationForExpenseDetail.properties.includes(
          EXPENSE_APPLICATION_PROPETIES.ADD_EXPENSE
        )
          ? t("request_detail.application_expenses.approveApplicationYouNeed")
          : null}
        {applicationForExpenseDetail.properties.includes(
          EXPENSE_APPLICATION_PROPETIES.ADD_EXPENSE
        ) ? (
          <span
            onClick={clickAddExpenseModalHandler}
            style={{ cursor: "pointer" }}
          >
            &nbsp;{t("request_detail.application_expenses.addExpenses")}
          </span>
        ) : null}
      </div>
      <CreateOrderModal
        id={props.id}
        isOpen={isOpenAddExpenseModal}
        isWidget={props.isWidget}
        onClose={() => setOpenAddExpenseModal(!isOpenAddExpenseModal)}
      />
    </>
  ) : null;
};

export default ApplicationExpensesEmpty;
