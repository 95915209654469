import { BaseMapper } from './base.mapper';
import { Notice } from 'infrastructure/interfaces';
import { get } from 'lodash';


export class NoticeMapper extends BaseMapper<Notice, any> {
  responseToEntity(response: any): Notice {
    return {
      id: get(response, 'id', 0),
      date: get(response, 'created', '0001-01-01T00:00:00'),
      userNameShort: get(response, 'data.name', 'name[STUB]'),
      title: get(response, 'data.title', 'title[STUB]'),
      type: get(response, 'type.type', ''),
      isRead: get(response, 'readAt', '0001-01-01T00:00:00') != null,
      object: {
        id: get(response, 'object.id', 0),
        logicalName: get(response, 'object.logicalName', '')
      },
  
      message: { 
        user: get(response, 'data.authorName', 'authorName[STUB]'), 
        text: get(response, 'data.message', 'message[STUB]') 
      },
  
      link: { 
        name: get(response, 'data.sectionTitle', 'sectionTitle[STUB]'), 
        href: get(response, 'data.link', 'link[STUB]') 
      }
  };
  };
}
