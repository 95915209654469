import { ActionCreator } from "redux";
import { ApplicationState } from 'store/reducers';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {SetAOList, REPORTS, AOActions, SetDetailedAO, SetAOListDownloaded, SetAODetailDownloaded} from './actionTypes';
import { loaderLock, loaderUnlock, historyNeedUpdatingThunk } from "store/common/actions";
import { showErrors } from "store/exception/actions";
import { AOMapper, AOListMapper } from "infrastructure/mappers";
import { IAOList, IAO, FilterParams } from "infrastructure/interfaces";
import {getAdvanceReportApplications, getAdvanceReportApplicationsWithParams} from "../../services/ApiService";

export const setAOList: ActionCreator<SetAOList> = (advanceReportList: IAOList, reloading: boolean) => ({
  type: REPORTS.SET_AO_LIST,
  payload: { advanceReportList: advanceReportList, reloading }
});

export const setDetailedAO: ActionCreator<SetDetailedAO> = (advanceReportApplication: IAO) => ({
  type: REPORTS.SET_DETAILED_AO,
  payload: { advanceReportApplication }
});

export const setAOListDownloaded: ActionCreator<SetAOListDownloaded> = (isReportListDownloaded: boolean) => ({
  type: REPORTS.SET_AO_LIST_DOWNLOADED,
  payload: { isReportListDownloaded: isReportListDownloaded }
});

export const setAODetailDownloaded: ActionCreator<SetAODetailDownloaded> = (isReportDetailDownloaded: boolean) => ({
  type: REPORTS.SET_AO_DETAIL_DOWNLOADED,
  payload: { isReportDetailDownloaded: isReportDetailDownloaded }
});

export const updateDetailedAO = (id: number): ThunkAction<Promise<void>, ApplicationState, any, AOActions> => {
  return async (dispatch: ThunkDispatch<ApplicationState, any, AOActions>, getState): Promise<void> => {
    await dispatch(loaderLock());
    let aoMapper = new AOMapper();
    let response = await getAdvanceReportApplications(id);
    if (response.headers.success) {
      dispatch(setDetailedAO(aoMapper.responseToEntity(response.data)));
      await dispatch(historyNeedUpdatingThunk());
    } else {
      //dispatch(showErrors({ code: 'update_detailed_report_error', message: `Не удалось загрузить авансовый отчет` }));
    }
    dispatch(setAODetailDownloaded(true));
    await dispatch(loaderUnlock());
  }
}

const getParams = (status: any[], cities: any[]) => {
  let result = '';
  let statuses: string[] = [];
  let city: string[] = [];
  if (status.length > 0) {
    status.forEach(state => { statuses.push(`Status=${state}`) });
  }
  if (cities.length > 0) {
    cities.forEach(item => { city.push(`CityId=${item}`) });
  }
  let resultArray = statuses.concat(city);
  if (resultArray.length > 0) {
    result += '?'
    result += resultArray.join('&');
  }
  return result;
}

export const updateAOList = (filterParams?: FilterParams, pageNumber: number = 1, reloading: boolean = false, itemsPerPage: number = 20): ThunkAction<Promise<void>, ApplicationState, any, AOActions> => {
  return async (dispatch: ThunkDispatch<ApplicationState, any, AOActions>, getState): Promise<void> => {
    !reloading && await dispatch(loaderLock());
    let reportApplicationMapper = new AOListMapper();
    let params: any = {
      PageSize: itemsPerPage,
      Page: pageNumber
    }
    if (filterParams) {
      // params.Status = filterParams.Status || null;
      params.Journey = filterParams.Journey || null;
      // params.CityId = filterParams.CityId || null;
      params.Id = filterParams.Id || null;
      params.Number = filterParams.Number || null;
      params.StartOn = filterParams.StartOn || null;
      params.EndOn = filterParams.EndOn || null;
      params.EmployeeId = filterParams.EmployeeId || null;
      params.CompanyId = filterParams.CompanyId || null;
      params.LegalEntityId = filterParams.LegalEntityId || null;
      params.OrgUnitId = filterParams.OrgUnitId || null;
      params.LocationId = filterParams.LocationId || null;
      params.ExpenseApplicationId = filterParams.ExpenseApplicationId || null;
      params.Personal = filterParams.Personal || null;
    }
    let response = await getAdvanceReportApplicationsWithParams(filterParams ? getParams(filterParams.Status || [], filterParams.CityId || []) : '', {
      params: params
    });
    if (response.headers.success) {
      dispatch(setAOList(reportApplicationMapper.responseToEntity(response.data), reloading));
    } else {
      await dispatch(showErrors({
        code: 'update_reports_error',
        message: `Не удалось загрузить список авансовых отчетов`
      }));
    }
    dispatch(setAOListDownloaded(true));
    !reloading && await dispatch(loaderUnlock());
  }
}