import Breadcrumbs from 'app/component/breadcrumbs/Breadcrumbs';
import CreateAOModal from 'app/component/modal/CreateAOModal';
import CreateExpenseModal from 'app/component/modal/CreateExpenseModal';
import ProfileUser from 'app/component/profile-user/ProfileUser';
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import {
  hideChat,
  hideFilter,
  hideHistory,
  showChat,
  showFilter,
  showHistory,
  updateProfileFieldPermissions
} from 'store/common/actions';
import { ApplicationState } from 'store/reducers';
import { IAO } from 'infrastructure/interfaces';
import { ADVANCE_APPLICATION_PROPETIES, EXPENSE_APPLICATION_PROPETIES } from 'infrastructure/enum/object-properties.enum';
import analitiks from "../../../services/AnaliticsService";
import {
  getAdvanceReportApplication,
  getApplicationForExpenseDetail,
  getCommonChatState,
  getCommonFilterState,
  getCommonHistoryState, getCommonUserDetail
} from "../../../store/selectors";
import {FILTER_FIELD_PERMISSIONS} from "../../../infrastructure/enum/field-permissions.enum";

interface HeaderContentProps extends RouteComponentProps<any> {
  accessDenied: boolean
}

const HeaderContent: React.FC<HeaderContentProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(getCommonUserDetail);
  let filterState: boolean = useSelector(getCommonFilterState);
  let chatState: boolean = useSelector(getCommonChatState);
  let historyState: boolean = useSelector(getCommonHistoryState);
  const advanceReportApplication: IAO = useSelector(getAdvanceReportApplication);
  const applicationForExpenseDetail = useSelector(getApplicationForExpenseDetail);

  const filterFieldPermissions = useSelector((state: ApplicationState) => state.common.filterFieldPermissions);

  let [isFilterOpen, setFilterOpen] = useState(false);
  let [isChatOpen, setChatOpen] = useState(false);
  let [isHistoryOpen, setHistoryOpen] = useState(false);
  let [isOpenCreateModal, setOpenCreateModal] = useState(false);

  useEffect(() => {
    dispatch(updateProfileFieldPermissions())
  }, [])

  useEffect(() => {
    setFilterOpen(filterState);
    setChatOpen(chatState);
    setHistoryOpen(historyState);
  });

  const clickHandler = () => {
    setOpenCreateModal(true);
    sendAnalytics();
  };

  const sendAnalytics = () => {
    if (props.location.pathname === '/AdvanceReportApplication') {
      analitiks('ClickCreateAO', {
        employeeId: user.id,
        occupationId: user.occupations[0].occupationId,
        companyId: user.occupations[0].company.id
      });
    }
    if (props.location.pathname === '/ExpenseApplication') {
      analitiks('ClickCreateZNR', {
        employeeId: user.id,
        occupationId: user.occupations[0].occupationId,
        companyId: user.occupations[0].company.id
      });
    }
  };

  const getFilterControls = (createButtonText?: string) => {
    return (
      <React.Fragment>
        {createButtonText && filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.CREATING_APPLICATION) ? (
          <div className="tx-center">
            <a className="btn-expense btn_green js-open-modal add-note link-mob-add-note"
              data-modal-id="creating-application" tabIndex={-1}
              onClick={clickHandler}>{createButtonText}
            </a>
          </div>) : null}
        <div className="tx-center" onClick={filterClickHandler}><a className="link-filter js-link-filter pointer">
          <SvgIcon className="icon icon-filter" href="#svg_icon_filter" />
          <span>{t('filters.filters')}</span></a></div>
        <Switch>
          <Route path='/AdvanceReportApplication/' render={() => {
            return <CreateAOModal isOpen={isOpenCreateModal} onClose={() => setOpenCreateModal(false)} />
          }} />
          <Route path='/ExpenseApplication/' render={() => {
            return <CreateExpenseModal isOpen={isOpenCreateModal} onClose={() => setOpenCreateModal(false)} />
          }} />
        </Switch>
      </React.Fragment>
    )
  };

  const getHeader = (text: string, titleHide: boolean, allOk: boolean, isDetail?: boolean, historyPermission: boolean = true) => {
    return (
      <div className={`header-content ${titleHide ? 'title-hide-1365' : ''}`} style={{marginBottom: '10px', alignItems: "start"}}>
        <ProfileUser accessDenied={props.accessDenied}/>
        {allOk && !props.accessDenied ?
          <div className={`breadcrumbs-wrap ${isDetail ? 'breadcrumbs-sm-row' : ''}`}>
          <Breadcrumbs isDetail={isDetail}/>
          <Switch>
            <Route exact path={'/AdvanceReportApplication/Detail/:id'} render={() => {
              return getChatToggleButton()
            }} />
            <Route exact path={'/ExpenseApplication/Detail/:id'} render={() => {
              return getChatToggleButton()
            }} />
          </Switch>
          <Switch>
            <Route exact path={'/AdvanceReportApplication'} render={() => {
              return getFilterControls(t('filters.create_application_order'))
            }} />
            <Route exact path={'/ExpenseApplication'} render={() => {
              return getFilterControls(t('filters.create_application'))
            }} />
            <Route exact path={'/ExpenseApplication/:status'} render={() => {
              return getFilterControls(t('filters.create_application'))
            }} />
            <Route exact path={'/AdvanceReportApplication/:status'} render={() => {
              return getFilterControls(t('filters.create_application_order'))
            }} />
            <Route exact path={'/Agreement'} render={() => {
              return getFilterControls()
            }} />
            <Route path={'/Journal'} render={() => {
              return getFilterControls()
            }} />
          </Switch>
        </div> : null}
        {allOk && historyPermission && !props.accessDenied ?
          <div className="filters-title">
            {text}
          </div> : null}

      </div>
    )
  };

  const getChatToggleButton = () => {
    return (
      <React.Fragment>
        <div className="tx-right">
          <a className="link-notice js-link-chat" onClick={historyClickHandler}>
            <SvgIcon y={8} className={'icon icon-notice'} href={'#svg_icon_notice-new'} />
          </a>
          <a className="link-chat js-link-chat" onClick={chatClickHandler}>
            <div className="icon icon-chat">
              <div className="chat-new-mess"></div>
              <svg>
                <use x="0" y="0" href="#svg_icon_chat"></use>
              </svg>
            </div>
          </a>
        </div>
      </React.Fragment>
    )
  };

  const filterClickHandler = () => {
    isFilterOpen ? dispatch(hideFilter()) : dispatch(showFilter());
    setFilterOpen(!isFilterOpen);
  };

  const chatClickHandler = () => {
    isChatOpen ? dispatch(hideChat()) : dispatch(showChat());
    setChatOpen(!isChatOpen);
  };

  const historyClickHandler = () => {
    isHistoryOpen ? dispatch(hideHistory()) : dispatch(showHistory());
    setHistoryOpen(!isHistoryOpen);
  };

  const isShowHistory = (text: string) => {
    if (text === 'AdvanceReportApplication') {
      return !!advanceReportApplication && advanceReportApplication.properties ? advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.FLOW_HISTORY) : false;
    } else if (text === 'ExpenseApplication') {
      return !!applicationForExpenseDetail && applicationForExpenseDetail.properties ? applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.FLOW_HISTORY) : false;
    } else return false;
  }

  return (
    <Switch>
      <Route path='/AdvanceReportApplication/Detail/:id' render={() => {
        return getHeader(t('history.reportTitle'), true, !!advanceReportApplication.id, true, isShowHistory('AdvanceReportApplication'));
      }} />
      <Route path='/ExpenseApplication/Detail/:id' render={() => {
        return getHeader(t('history.applicationTitle'), true, !!applicationForExpenseDetail, true, isShowHistory('ExpenseApplication'));
      }} />
      <Route path='/AdvanceReportApplication/' render={() => {
        return getHeader(t('filters.filters'), false, true, false)
      }} />
      <Route path='/ExpenseApplication/' render={() => {
        return getHeader(t('filters.filters'), false, true, false)
      }} />
      <Route path='/Journal' render={() => {
        return getHeader(t('filters.filters'), true, true, false);
      }} />
      <Route path='/Agreement' render={() => {
        return getHeader(t('filters.filters'), true, true, false);
      }} />
      <Route path='/' render={() => {
        return getHeader('', true, true, false)
      }} />
    </Switch>
  );
}

export default withRouter(HeaderContent);