import ModalContainer from 'app/component/modal/ModalContainer';
import Select, {SelectOptionType} from 'app/component/select/Select';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store/reducers";
import { find, isEmpty, get } from 'lodash';
import {getAdvanceReportApplication, getApplicationForExpenseDetail} from "../../../store/selectors";

interface AttachFileModalProps {
    isOpen: boolean,
    onClose: () => void,
    onSubmit: (expense?: any) => void,
    isReport?: boolean,
    objectId?: any
}

const AttachFileModal: React.FC<AttachFileModalProps> = (props) => {
    const {t, i18n} = useTranslation();

    const applicationForExpenseDetail = useSelector(getApplicationForExpenseDetail);
    const advanceReport = useSelector(getAdvanceReportApplication);
    const idExpenseOrReport: number | null = props.isReport ? advanceReport.id : applicationForExpenseDetail ? applicationForExpenseDetail.id : null;
    const defaultValueExpense = {
        id: idExpenseOrReport,
        expenseType: {
            logicalName: props.isReport ? 'AdvanceReportApplication' : 'ExpenseApplication',
            name: {
                ru:'Без расхода',
                en:'Without expense'
            }
        }
    };

    const [selectedExpense, setSelectedExpense] = useState(defaultValueExpense);
    let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

    let expenses: any = props.isReport ? advanceReport.expenses : applicationForExpenseDetail ? applicationForExpenseDetail.expenses : null;
    expenses = !isEmpty(expenses) ? [defaultValueExpense, ...expenses] : [defaultValueExpense];

    useEffect(() => {
        if(props.objectId && get(props.objectId.objectId, 'logicalName') === 'Expense'){
            const selectedExpense = find(expenses, ['id', Number(props.objectId.objectId.id)]);
            setSelectedExpense(selectedExpense);
        } else {
            setSelectedExpense(defaultValueExpense);
        }
    }, [props.isOpen]);


    const consumptionChooseHandler = (option: SelectOptionType) => {
        const selectedExpense = find(expenses, ['id', option.value]);
        setSelectedExpense(selectedExpense);
    };

    const closeHandler = () => {
        props.onClose();
    };

    const submitHandler = () => {
        props.onSubmit(selectedExpense);
    }

    const isHighestModal = (isHighest: boolean) => {
        setTabIndexDisabled(!isHighest);
    }

    const optionsForExpense = useMemo(() => {
        return expenses ? expenses.filter((option: any) => option.description !== "Daily allowance" && option.description !== "Суточные").map((option: any, index: any) => {
            return {
                value: option.id,
                text: `${option.expenseType.name[i18n.language as 'en' | 'ru']} ${index ? '-' : ''} ${index ? option.description : ''}`
            };
        }) : []
    }, [expenses]);

    return (
        <ModalContainer isOpen={props.isOpen} onEnter={submitHandler} destroy={true} highestModal={isHighestModal} overlayClick={closeHandler}>
            <div className="box-modal" id="add-attachment">
                <div className="box-modal_close arcticmodal-close" onClick={props.onClose}/>
                <div className="box-modal-title">{t('modals.attach_file.attach_file')}</div>
                <div className="box-modal-content">
                    <div className="input-wrap">
                        <div className="input-item">
                            <label className="input-label">{t('modals.attach_file.select_expense')}</label>
                            <Select
                                defaultText={(props.objectId && get(props.objectId.objectId, 'logicalName') === 'Expense') ?
                                    `${selectedExpense.expenseType.name[i18n.language as 'en' | 'ru']} - ${get(selectedExpense, 'description')} `
                                    : t('modals.attach_file.without_expense')}
                                options={optionsForExpense}
                                onChoose={consumptionChooseHandler}
                            />
                        </div>
                    </div>
                </div>
                <div className="box-modal-footer">
                    <a className="arcticmodal-close btn btn_black pointer" onClick={props.onClose} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.attach_file.cancel')}</a>
                    <a className="btn-expense btn_green pointer" onClick={submitHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.attach_file.complete')}</a>
                </div>
            </div>
        </ModalContainer>
    );
}

export default AttachFileModal;
