import React from 'react';

interface RadioProps {
    name: string,
    id: number,
    label: string,
    checked: boolean,
    onClick: (id: number) => void,
    disabled?: boolean,
    tabIndex?: -1 | 0;
    className?: string;
}

const Radio: React.SFC<RadioProps> = (props) => {
    const clickHandler = () => {
        if(props.disabled) return

        props.onClick(props.id);
    }

    const onChange = () => {}

    return (
        <div className={"radio " + (props.disabled ? 'disabled' : '')} onClick={clickHandler}>
            <input type="radio" name={props.name} id={props.name + '-' + props.id} checked={props.checked} onChange={onChange} tabIndex={props.tabIndex}/>
            <label htmlFor={props.name + '-' + props.id}>
                <div></div>
                {props.label}
            </label>
        </div>
    );
}

export default Radio;