import AttachmentFilesPanel from 'app/component/attachment-files-panel/AttachmentFilesPanel';
import CreateAOModal from 'app/component/modal/CreateAOModal';
import DeleteReportModal from 'app/component/modal/DeleteReportModal';
import EmployeeInformationModal from 'app/component/modal/EmployeeInformationModal';
import PrintMenu from 'app/component/menu/PrintMenu';
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import {formatter, getCurrencieCode, NameFormats, nameFormatter, toLocaleNumberFormatter} from 'app/utils';
import {STATUS_TYPE_REPORT, statusReportNameMap, statusReportStylesMap} from 'infrastructure/enum/status-report.enum';
import {OCCUPATION_ROLE_TYPE, ROLE_TYPE} from 'infrastructure/enum/user-role.enum';
import {IAO, IOccupations, UserDetail} from 'infrastructure/interfaces';
import {get, isEmpty} from 'lodash';
import React, {Fragment, useEffect, useState} from 'react';
import './AODetail.css';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import Config, {ConfigTypes} from 'services/ConfigService';
import AOAgreement from './AOAgreement';
import {clearAttachmentList, loaderLock, loaderUnlock, setPostings, updatePostings} from 'store/common/actions';
import {showErrors} from 'store/exception/actions';
import {setAODetailDownloaded, setDetailedAO, updateDetailedAO} from 'store/report/actions';
import {updateAttachmentsExpense} from '../../../store/common/actions';
import AOButtonsControl from './AOButtonsControl';
import ReportExpenses from './AOExpenses';
import AOHeader from './AOHeader';
import AOPostings from './AOPostings';
import If from 'app/component/util/If';
import {STATUS_TYPE} from 'infrastructure/enum/status-type.enum';
import SubmitCustomModal from 'app/component/modal/SubmitCustomModal';
import CreateOrderModal from 'app/component/modal/CreateOrderModal';
import {isIE} from 'react-device-detect';
import {ADVANCE_APPLICATION_PROPETIES} from 'infrastructure/enum/object-properties.enum';
import agreementService from 'services/entity/agreement.service';
// @ts-ignore
import AOActs from "./AOActs.tsx";
import {
  getAdvanceReportApplication,
  getAdvanceReportIsReportDetailDownloaded,
  getCommonAttachmentPermission,
  getCommonStateOptions,
  getCommonUserDetail
} from "../../../store/selectors";
import {
  getAssistancesById, postPaperWorks, updateAdvanceReportApplicationCancelById, updateApprovalsAdvanceReportApplicationMarkAsReadById,
  updateApprovalsAdvanceReportApplicationMarkAsUnreadById, updatePaperWorks
} from "../../../services/ApiService";

interface AODetailProps extends RouteComponentProps<any> {
}

const AODetail: React.FC<AODetailProps> = (props) => {
  const { t, i18n } = useTranslation();
  const id = props.match.params.id;

  const advanceReportApplication: IAO = useSelector(getAdvanceReportApplication);
  const user: UserDetail = useSelector(getCommonUserDetail);
  const { isAdvanceReportAttachmentPermission } = useSelector(getCommonAttachmentPermission);
  let stateOptions = useSelector(getCommonStateOptions);
  const advanceReport = useSelector(getAdvanceReportApplication);
  const isReportDetailDownloaded = useSelector(getAdvanceReportIsReportDetailDownloaded);
  const dispatch = useDispatch();

  let [isOpenEmployeeInfo, setOpenEmployeeInfo] = useState(false);
  let [isOpenDeleteReportModal, setOpenDeleteReportModal] = useState(false);
  let [isOpenEditRequestModal, setOpenEditRequestModal] = useState(false);
  let [isOpenPrintModal, setOpenPrintModal] = useState(false);
  let [isAppMode, setAppMode] = useState(true);
  let [isPostingsMode, setPostingsMode] = useState(false);
  let [isAgreementMode, setAgreementMode] = useState(false);
  let [isMenuOpen, setMenuOpen] = useState(false);
  let [isShowDailyRenew, setShowDailyRenew] = useState(false);
  let [dailyRenewEditId, setDailyRenewEditId] = useState(0);
  let [perDiemCalculationType, setPerDiemCalculationType] = useState(0);
  let [advanceReportAmountCalculationPermission, setAdvanceReportAmountCalculationPermission] = useState(0);

  let [canShowTakeInHand, setCanShowTakeInHand] = useState(null as unknown as boolean);
  let [isOpenTakeInHandModal, setIsOpenTakeInHandModal] = useState(false);
  let [assistancesIds, setAssistancesIds] = useState([] as any[]);
  let [rightStatusForWarning, setRightStatusForWarning] = useState(false);
  let [transactionResponseStatus, setTransactionResponseStatus] = useState(0 as number);
  let [fieldAccess, setFieldAccess] = useState({
    approvals: false,
    applicationType: false,
    cancelAO: false,
    number: false,
    associatedExpenseApplication_Number: false,
    type: false,
    orderNumber: false,
    journeyNumber: false,
    businessTripNumber: false,
    businessTarget: false,
    description: false,
    vbsElement: false,
    departureFromCity: false,
    arrivalToCity: false,
    startOn: false,
    endOn: false,
    personalDaysNumber: false,
    advanceCashAmount: false,
    totalAmount: false,
    transactions: false,
    flowHistory: false,
    printingForms: false,
    updateAO: false,
    status: false
  });

  useEffect(() => {
    return () => {
      dispatch(setAODetailDownloaded(false))
    }
  }, []);

  useEffect(() => {
    dispatch(updateDetailedAO(id));
    window.scrollTo(0, 0);
    return () => {
      dispatch(setDetailedAO({}));
      dispatch(clearAttachmentList());
    }
  }, [id])

  useEffect(() => {
    let companyId = advanceReportApplication && advanceReportApplication.company ? advanceReportApplication.company.id : 0;
    setAdvanceReportAmountCalculationPermission(Config.getConfigToCompany(ConfigTypes.ADVANCE_REPORT_AMOUNT_CALCULATION, companyId));
    if (advanceReportApplication && isBookerOccupation()) {
      dispatch(updatePostings(id, 'AdvanceReportApplication', {}, setResponseStatus));
    } else {
      dispatch(setPostings({ transactions: [] }));
    }

    dispatch(updateAttachmentsExpense(id, 'AdvanceReportApplication', get(advanceReportApplication, 'expenses', [])));
    markAsReaded();
    checkAssistance();
    generateFieldsAccess();

    setPerDiemCalculationType(Config.getConfigToCompany(ConfigTypes.PERDIEM_CALCULATION_TYPE, companyId));
    canOpenDailyExpense();
  }, [advanceReportApplication])

  useEffect(() => {
    if (isPostingsMode) {
      dispatch(updatePostings(id, 'AdvanceReportApplication', {}));
    }
  }, [isPostingsMode]);

  // const generateFieldsAccess = () => {
  //   let fieldAccess = {
  //       number: stubProperties.includes('AdvanceReport_Number'),
  //       associatedExpenseApplication_Number: stubProperties.includes('AdvanceReport_AssociatedExpenseApplication_Number'),
  //       type: stubProperties.includes('AdvanceReport_Type'),
  //       orderNumber: stubProperties.includes('AdvanceReport_OrderNumber'),
  //       journeyNumber: stubProperties.includes('AdvanceReport_JourneyNumber'),
  //       businessTripNumber: stubProperties.includes('AdvanceReport_BusinessTripNumber'),
  //       businessTarget: stubProperties.includes('AdvanceReport_BusinessTarget'),
  //       description: stubProperties.includes('AdvanceReport_Description'),
  //       vbsElement: stubProperties.includes('AdvanceReport_VbsElement'),
  //       departureFromCity: stubProperties.includes('AdvanceReport_DepartureFromCity'),
  //       arrivalToCity: stubProperties.includes('AdvanceReport_ArrivalToCity'),
  //       startOn: stubProperties.includes('AdvanceReport_StartOn'),
  //       endOn: stubProperties.includes('AdvanceReport_EndOn'),
  //       personalDaysNumber: stubProperties.includes('AdvanceReport_PersonalDaysNumber'),
  //       advanceCashAmount: stubProperties.includes('AdvanceReport_AdvanceCashAmount'),
  //       totalAmount: stubProperties.includes('AdvanceReport_TotalAmount'),
  //       flowHistory: stubProperties.includes('AdvanceReport_FlowHistory'),
  //       status: stubProperties.includes('AdvanceReport_Status'),
  //   }
  //   setFieldAccess(fieldAccess);
  //   return;
  // }

  const isBookerOccupation = (): boolean => {
    let occupations = user.occupations.map((occupation: IOccupations) => occupation.roles).flat().map((item)=>item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  }

  const setResponseStatus = (status: number) => {
    setTransactionResponseStatus(status);
  }

  const isCanViewPostings = (): boolean => {
    let bookerOccupation = user.occupations.find(occupation => occupation.roles.map((item)=>item.code).includes(OCCUPATION_ROLE_TYPE.BOOKER));
    let mainBookerOccupation = user.occupations.find(occupation => occupation.roles.map((item)=>item.code).includes(OCCUPATION_ROLE_TYPE.MAIN_BOOKER));
    let cfoOccupation = user.occupations.find(occupation => occupation.roles.map((item)=>item.code).includes(OCCUPATION_ROLE_TYPE.CFO));

    let mainOccupation: IOccupations | undefined;

    if (bookerOccupation) {
      mainOccupation = bookerOccupation;
    }

    if (mainBookerOccupation) {
      mainOccupation = mainBookerOccupation;
    }

    if (cfoOccupation) {
      mainOccupation = cfoOccupation;
    }

    if (mainOccupation && advanceReportApplication) {
      const bookerAndDocumentFromOneCompany = mainOccupation.company.id === advanceReportApplication.company.id;
      if (bookerAndDocumentFromOneCompany) {
        return true;
      } else if (!mainOccupation.isHierarchy) {
        return false;
      } else return transactionResponseStatus !== 403;
    }

    return false;
  }

  const isAssistantOccupation = (): boolean => {
    let occupations = user.occupations.map((occupation: IOccupations) => occupation.roles).flat().map((item)=>item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.ASSISTANT);
  }

  const isCoordinatorOccupation = (): boolean => {
    let occupations = user.occupations.map((occupation: IOccupations) => occupation.roles).flat().map((item)=>item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.COORDINATOR);
  }

  const checkAssistance = async () => {
    const data = {
      employeeId: user.id,
      objectLogicalName: "AdvanceReportApplication",
      objectId: advanceReportApplication.id,
    }
    if (advanceReportApplication && advanceReportApplication.assigneeEmployee && advanceReportApplication.assigneeEmployee.id) {
      let massRequest = user.occupations.map(occupation => getAssistancesById(occupation.occupationId));
      let massResponse = await Promise.all(massRequest);
      let massList = massResponse.map(response => {
        if (response.headers.success) {
          let assistancesList = get(response, 'data.value.assistances', []);
          return assistancesList.map((item: any) => item.employeeId);
        }
        return [];
      }).flatMap(item => item);

      setAssistancesIds(massList);

      /*let response = await Api.get(`/assistances/${user.id}`);
      if (response.headers.success) {
        let assistancesList = get(response, 'data.value.assistances', []);
        const idList = assistancesList.map((item: any) => item.employeeId);
        setAssistancesIds(idList);
      }*/
    }
  }

  const markAsReaded = async() => {
    if (advanceReportApplication && advanceReportApplication.status == STATUS_TYPE.ON_APPROVAL) {
      await updateApprovalsAdvanceReportApplicationMarkAsReadById(advanceReportApplication.id);
    }
  }

  const markAsUnreaded = async() => {
    if (advanceReportApplication && advanceReportApplication.status == STATUS_TYPE.ON_APPROVAL) {
      await updateApprovalsAdvanceReportApplicationMarkAsUnreadById(advanceReportApplication.id);
    }
  }

  useEffect(() => {
    agreementService
      .setId(advanceReportApplication.id)
      .setIsAdvance(true);
    if (advanceReportApplication.status === STATUS_TYPE_REPORT.DRAFT || advanceReportApplication.status === STATUS_TYPE_REPORT.APPROVAL_REQUIRES) {
      setCanShowTakeInHand(false);
      return
    }
    changeShowTakeInHand();
    return () => {
      setCanShowTakeInHand(null as unknown as boolean)
    }
  }, [
    advanceReportApplication.id,
    advanceReportApplication.status,
    advanceReportApplication.paperWork ? advanceReportApplication.paperWork.employeeId : undefined]);

  const deleteButtonSubmit = async () => {
    dispatch(loaderLock());
    let response = await updateAdvanceReportApplicationCancelById(id);
    if (response.headers.success) {
      setOpenDeleteReportModal(false);
      props.history.push('/AdvanceReportApplication');
    } else {
      dispatch(showErrors({ code: 'delete_report', message: 'Не удалось удалить авансовый отчет' }));
    }
    dispatch(loaderUnlock());
  }

  const hasState = (state: string) => {
    if (isEmpty(stateOptions)) {
      return false
    }

    if (stateOptions.indexOf(state) != -1) {
      return true;
    }

    return false
  }

  const toggleEmployeeInfo = () => {
    setOpenEmployeeInfo(!isOpenEmployeeInfo);
    if (isMenuOpen) togglePopup();
  }

  const togglePrintModal = () => {
    setOpenPrintModal(!isOpenPrintModal);
    if (isMenuOpen) togglePopup();
  }

  const showPostingForm = () => {
    return (
      <Fragment>
        <AOPostings id={id} />
        <AOButtonsControl id={id} isPostingsMode={isPostingsMode} setPostingsMode={() => setPostingsMode(true)} />
      </Fragment>
    )
  };

  const showAgreementForm = () => {
    return (
      <Fragment>
        <AOAgreement id={id} />
        {/* <AOButtonsControl id={id} isPostingsMode={isPostingsMode} setPostingsMode={() => setPostingsMode(true)} /> */}
      </Fragment>
    )
  };

  const showAppMode = () => {
    setAppMode(true);
    setPostingsMode(false);
    setAgreementMode(false);
  };

  const showPostingsMode = () => {
    setAppMode(false);
    setPostingsMode(true);
    setAgreementMode(false);
  };

  const showAgreementMode = () => {
    setAppMode(false);
    setPostingsMode(false);
    setAgreementMode(true);
  };

  const clickEditRequestModalHandler = () => {
    isMenuOpen && togglePopup();
    setOpenEditRequestModal(!isOpenEditRequestModal);

    // if (isBookerOccupation() && advanceReportApplication.status === STATUS_TYPE_REPORT.ON_APPROVAL && hasState('Approve')) {
    //   setOpenEditRequestModal(!isOpenEditRequestModal);
    //   return;
    // }
    //
    // if (!advanceReportApplication) {
    //   return;
    // }
    //
    // if (advanceReportApplication.status === STATUS_TYPE_REPORT.APPROVED || advanceReportApplication.status === STATUS_TYPE_REPORT.ON_APPROVAL) {
    //   dispatch(showErrors({
    //     code: '',
    //     message: t('status_error_message.status_edit_RA', { status: t(`report_status.${statusReportNameMap[advanceReportApplication.status as STATUS_TYPE_REPORT]}`) })
    //   }));
    //   return;
    // }
    //
    // if (advanceReportApplication.status === STATUS_TYPE_REPORT.POSTED) {
    //   dispatch(showErrors({
    //     code: '',
    //     message: t('status_error_message.status__edit_RA', { status: t(`report_status.${statusReportNameMap[advanceReportApplication.status as STATUS_TYPE_REPORT]}`) })
    //   }));
    //   return;
    // }
    // setOpenEditRequestModal(!isOpenEditRequestModal);
  };

  const clickDeleteExpenseApplicationModalHandler = () => {
    if (isMenuOpen) togglePopup();
    setOpenDeleteReportModal(!isOpenDeleteReportModal);
    // if (advanceReportApplication) {
    //   if (advanceReportApplication.status === STATUS_TYPE_REPORT.APPROVED || advanceReportApplication.status === STATUS_TYPE_REPORT.ON_APPROVAL) {
    //     dispatch(showErrors({
    //       code: '',
    //       message: t('status_error_message.status_delete_RA', { status: t(`report_status.${statusReportNameMap[advanceReportApplication.status as STATUS_TYPE_REPORT]}`) })
    //     }));
    //   } else {
    //     if (advanceReportApplication.status === STATUS_TYPE_REPORT.POSTED) {
    //       dispatch(showErrors({
    //         code: '',
    //         message: t('status_error_message.status__delete_RA', { status: t(`report_status.${statusReportNameMap[advanceReportApplication.status as STATUS_TYPE_REPORT]}`) })
    //       }));
    //     } else {
    //       setOpenDeleteReportModal(!isOpenDeleteReportModal);
    //     }
    //   }
    // }
  };

  const linkClickHandler = (id: number) => {
    props.history.push(`/ExpenseApplication/Detail/${id}`);
  }

  const togglePopup = () => {
    setMenuOpen(!isMenuOpen)
  };

  /*const getResolutionLevelGroup = () => {
    let resolutionOneLevel = levelOneUser.map(item => item.resolution).includes(STATUS_TYPE_REPORT.APPROVED);
    let resolutionTwoLevel = levelTwoUser.map(item => item.resolution).includes(STATUS_TYPE_REPORT.APPROVED);
    let resolutionThirdLevel = levelThirdUser.map(item => item.resolution).includes(STATUS_TYPE_REPORT.APPROVED);
    if (!resolutionOneLevel) {
      return levelOneUser;
    }
    if (!resolutionTwoLevel) {
      return levelTwoUser;
    }
    return levelThirdUser;
  }*/

  const userInAgreementList = async () => {
    let agreementList = await agreementService.getList();

    return agreementList && agreementList.approvalTiers.flatMap(item => item.employees).filter(item => item.id == user.id).length > 0;
  }

  const notPaperWorkEmployee = () => {
    return advanceReportApplication && advanceReportApplication.paperWork.employeeId != user.id;
  }
  
  const checkedListApproved = async () => {
    let currentTier = await agreementService.getCurrentTier();
    let visibleTakeInHand : boolean = false;
    if (currentTier) {
      currentTier.employees.forEach((item) => {
        if (item.id === user.id) {
          visibleTakeInHand = true
        }
      })
    }
    return visibleTakeInHand
  }

  const changeShowTakeInHand = async () => {
    if (advanceReportApplication.status !== STATUS_TYPE_REPORT.ON_APPROVAL) {
      setCanShowTakeInHand(false);
      return
    }

    let currentComposition = await agreementService.getCurrentComposition();
    let currentTier = await agreementService.getCurrentTier();

    if (await userInAgreementList() === false) {
      setCanShowTakeInHand(false);
      return
    }

    if (currentComposition == "All") {
      setCanShowTakeInHand(false);
      return
    } //Если composition All кнопка взять в работу не отображается.

    if (currentComposition == "Any" && currentTier && currentTier.employees.length == 1) {
      setCanShowTakeInHand(false); return
    } //Если composition Any и согласующий один кнопка не отображается

    if (currentComposition == "Any" && currentTier && currentTier.employees.length > 1 && notPaperWorkEmployee() && await checkedListApproved()) {
      setCanShowTakeInHand(true);
      return
    }//Если composition Any и согласующих 2 и более и для ЗНР/АО paperwokr null, то кнопка отображается
    //Если composition Any и согласующих 2 и более и для ЗНР/АО paperwokr заполнено, то кнопка отображается только для тех пользователь у которых paperwokr не совпадает с текущим пользователем .


    setCanShowTakeInHand(false)
  }

  const sendRequestToTakeInHand = async () => {
    dispatch(loaderLock());
    const requestParams = {
      objectType: "AdvanceReportApplication",
      objectId: advanceReportApplication ? advanceReportApplication.id : 0,
    }
    let result = await postPaperWorks(requestParams);
    if (result.headers.success) {
      dispatch(updateDetailedAO(id));
      setIsOpenTakeInHandModal(false);
      dispatch(loaderUnlock());
      return;
    } else {
      togleIsOpenTakeInHandModal();
      dispatch(loaderUnlock());
      dispatch(showErrors({ code: 'takeInHand_action', message: 'Не удалось взять в работу' }))
    }
    dispatch(loaderUnlock());
  }

  const togleIsOpenTakeInHandModal = () => {
    setIsOpenTakeInHandModal(!isOpenTakeInHandModal)
  }

  const takeInHand = () => {
    if (advanceReportApplication && advanceReportApplication.paperWork.employeeId == 0) {
      sendRequestToTakeInHand()
    } else {
      togleIsOpenTakeInHandModal()
    }

  }

  const finishHand = () => {
    const requestParams = {
      objectType: "AdvanceReportApplication",
      objectId: advanceReportApplication ? advanceReportApplication.id : 0,
    }
    updatePaperWorks(requestParams);
    dispatch(loaderLock());
    dispatch(updateDetailedAO(id))
    dispatch(loaderUnlock());
  }

  const openDailyRenew = async () => {
    dispatch(loaderLock());
    let dailyExpensesId = advanceReportApplication.expenses.filter((item: any) => { return item.expenseType.expenseGroup.code == "Perdiem" }).map((item: any) => item.id);

    if (dailyExpensesId.length > 0) {
      await setDailyRenewEditId(dailyExpensesId[0]);
    } else {
      await setDailyRenewEditId(0);
    }

    await setShowDailyRenew(true);

    dispatch(loaderUnlock());
  }

  const closeDailyRenew = () => {
    setShowDailyRenew(false);
  }

  const dailyAlertClickHandler = () => {
    if (rightStatusForWarning) {
      openDailyRenew();
    } else {
      return;
    }
  }

  const canOpenDailyExpense = () => {
    const accessStatuses = ['Cancelled', 'Posted', 'On Approval', 'Approved'];
    setRightStatusForWarning(!accessStatuses.includes(advanceReportApplication.status));
  }

  const generateFieldsAccess = () => {
    if (advanceReportApplication && advanceReportApplication.properties) {
      let fieldAccess = {
        approvals: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.APPROVALS),
        applicationType: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.APPLICATION_TYPE),
        cancelAO: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.CANCEL_AO),
        number: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.NUMBER),//stubProperties.includes('AdvanceReport_Number'),
        associatedExpenseApplication_Number: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.EXPENSE_APPLICATION_NUMBER),//stubProperties.includes('AdvanceReport_AssociatedExpenseApplication_Number'),
        type: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.APPLICATION_TYPE),//stubProperties.includes('AdvanceReport_Type'),
        orderNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ORDER_NUMBER),//stubProperties.includes('AdvanceReport_OrderNumber'),
        journeyNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.JOURNEY_NUMBER),//stubProperties.includes('AdvanceReport_JourneyNumber'),
        businessTripNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.BUSINESS_TRIP_NUMBER),//stubProperties.includes('AdvanceReport_BusinessTripNumber'),
        businessTarget: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.BUSINESS_TARGET),//stubProperties.includes('AdvanceReport_BusinessTarget'),
        description: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.DESCRIPTION),//stubProperties.includes('AdvanceReport_Description'),
        vbsElement: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.VBS_ELEMENT),//stubProperties.includes('AdvanceReport_VbsElement'),
        departureFromCity: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.DEPARTURE_FROM_CITY),//stubProperties.includes('AdvanceReport_DepartureFromCity'),
        arrivalToCity: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ARRIVAL_TO_CITY),//stubProperties.includes('AdvanceReport_ArrivalToCity'),
        startOn: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.START_ON),//stubProperties.includes('AdvanceReport_StartOn'),
        endOn: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.END_ON),//stubProperties.includes('AdvanceReport_EndOn'),
        personalDaysNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.PERSONAL_DAYS_NUMBER),//stubProperties.includes('AdvanceReport_PersonalDaysNumber'),
        advanceCashAmount: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ADVANCE_CASH_AMOUNT),//stubProperties.includes('AdvanceReport_AdvanceCashAmount'),
        totalAmount: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.TOTAL_AMOUNT),//stubProperties.includes('AdvanceReport_TotalAmount'),
        transactions: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.TRANSACTIONS),
        flowHistory: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.FLOW_HISTORY),//stubProperties.includes('AdvanceReport_FlowHistory'),
        printingForms: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.PRINTING_FORMS),
        updateAO: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.UPDATE_AO),
        status: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.STATUS),//stubProperties.includes('AdvanceReport_Status'),
      }
      setFieldAccess(fieldAccess);
      return;
    }
  }

  const showMainForm = () => {
    return (
      <Fragment>
        <div className="request">
          <div className={`request-item ${advanceReportApplication.expenseApplication.id ? 'prepayment' : ''}`} style={{position: 'relative'}}>
            <div className="request-item-row">
              <div
                className={`request-item-num`}><span className={`request-item-type ${fieldAccess.type ? '' : 'd-none'}`} style={{fontSize: '13px'}}>
                {t('report_detail.report_type')}: <b>{advanceReportApplication.applicationType.name[i18n.language as 'en' | 'ru']}</b>
              </span>({t('report_detail.created')} {advanceReportApplication.expenseApplication.id ?
                    <b className={'pointer'}
                      onClick={() => linkClickHandler(advanceReportApplication.expenseApplication.id)}>
                      {t('report_detail.by_request')} {advanceReportApplication.expenseApplication.id}</b> : ''}: {nameFormatter(advanceReportApplication.createdByEmployee.name, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language)} {formatter('DD.MM.YYYY', i18n.language, advanceReportApplication.createdAt)}{advanceReportApplication.postingDate ? `, \n${t('report_detail.posted')}: ${formatter('DD.MM.YYYY', i18n.language, advanceReportApplication.postingDate)}` : ''})
                </div>
              <a className="link-more-controls" onClick={togglePopup}>
                <div className="icon icon-more">
                  <svg>
                    <use x="0" y="0" href="#svg_icon_more" />
                  </svg>
                </div>
              </a>
              <If condition={isMenuOpen}>
                <div className="miss-click-handler" onClick={togglePopup} />
              </If>
              <div className="request-item-controls" style={{ display: isMenuOpen ? 'block' : '', zIndex: 100 }}>
                {advanceReportApplication.paperWork.id ? <div className={`inHandWrapper`}>{t('request_detail.in_hand')}: {nameFormatter(advanceReportApplication.paperWork.employeeName, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language)}</div> : null}
                {fieldAccess.printingForms ? <a onClick={togglePrintModal} className={'pointer'}>
                  <SvgIcon className="icon icon-download" href="#svg_icon_print" />
                  <span> {t('report_detail.print')}</span>
                </a> : null}
                  <If condition={fieldAccess.updateAO}>
                    <a className={`pointer`}
                      onClick={clickEditRequestModalHandler}>
                      <SvgIcon className="icon icon-edit" href="#svg_icon_edit" />
                      <span> {t('report_detail.edit')}</span>
                    </a>
                  </If>
                   <If condition={fieldAccess.cancelAO}>
                    <a className={`delete pointer`}
                      onClick={clickDeleteExpenseApplicationModalHandler}>
                      <SvgIcon className="icon icon-remove" href="#svg_icon_remove" />
                      <span> {t('report_detail.delete')}</span>
                    </a>
                  </If>
              </div>
              <PrintMenu id={id} isOpen={isOpenPrintModal} onClose={togglePrintModal} expenseApplicationId={advanceReportApplication.expenseApplication.id} isAdvance={true} companyId={advanceReportApplication.company.id} />
            </div>
            <div className="request-item-row" style={{borderBottom: '1px solid #98A2A8', paddingBottom: '17px'}}>
              <div
                className="request-item-name">{nameFormatter(advanceReportApplication.assigneeEmployee.name, NameFormats.NAME_WITH_LASTNAME, i18n.language)}</div>
              <div className={`request-list-item-status ${statusReportStylesMap[advanceReportApplication.status]} ${fieldAccess.status ? '' : 'd-none'}`}>
                {t('request_detail.status')}: {t(`report_status.${statusReportNameMap[advanceReportApplication.status]}`)}<span></span>
              </div>
            </div>
            <AOHeader id={id} type={advanceReportApplication.applicationType.code}
              toggleEmployeeInfo={toggleEmployeeInfo} />
            <If condition={perDiemCalculationType == 2 && advanceReportApplication.applicationType.code === 'Travel' && rightStatusForWarning}>
              <div className="request-item-row">
                {isIE ?
                  <div style={{ display: 'inline-table', alignItems: 'center', padding: '20px 25% 0px' }}>
                    <div style={{ width: '50px', height: 'calc(100% - 20px)', position: 'absolute', display: 'table-cell', marginLeft: '-55px' }}>
                      <SvgIcon className={'icon icon-notice'} href={'#svg_icon_daily-alert'} style={{ display: 'ruby-text' }} />
                    </div>
                    <div style={{ alignItems: 'center', width: '100%', fontSize: '16px', lineHeight: '20px', letterSpacing: '-0.011em' }}>
                      {t('request_detail.daily_alert_part_1')}<span onClick={dailyAlertClickHandler} style={rightStatusForWarning ? { textDecoration: 'underline', cursor: 'pointer' } : {}}>{t('request_detail.daily_alert_part_2')}</span>
                    </div>
                  </div>
                  :
                  <div style={{ display: 'flex', alignItems: 'center', padding: '20px 25% 0px' }}>
                    <div style={{ width: '50px' }}>
                      <SvgIcon className={'icon icon-notice'} href={'#svg_icon_daily-alert'} />
                    </div>
                    <div style={{ alignItems: 'center', width: '100%', fontSize: '16px', lineHeight: '20px', letterSpacing: '-0.011em' }}>
                      {t('request_detail.daily_alert_part_1')}<span onClick={dailyAlertClickHandler} style={rightStatusForWarning ? { textDecoration: 'underline', cursor: 'pointer' } : {}}>{t('request_detail.daily_alert_part_2')}</span>
                    </div>
                  </div>
                }

              </div>
            </If>
          </div>
          <ReportExpenses
              list={advanceReportApplication.expenses}
              id={id}
              applicationId={advanceReportApplication.applicationType.id}
          />
          
          <AOActs documentArr={advanceReport.additionalDocuments} />
          
          {/* {advanceReportApplication && advanceReportApplication.paperWork.employeeId == user.id ? <div className="btn btn_black pointer" onClick={finishHand}>{t('request_detail.request_button_controls.finish_the_work')}</div> : null} */}
          {/*  {canShowTakeInHand && !showAoButtonControl ? <div className={`request-bottom-controls tx-center`}><div className="btn btn_black pointer" onClick={takeInHand}>{t('request_detail.request_button_controls.take_in_hand')}</div></div> : null}
          {showAoButtonControl ? <AOButtonsControl id={id} isPostingsMode={isPostingsMode} setPostingsMode={() => setPostingsMode(true)} /> : null}*/}
          {canShowTakeInHand ?
            <div className={`request-bottom-controls tx-center`}>
              <div
                className="btn btn_black pointer"
                onClick={takeInHand}>{t('request_detail.request_button_controls.take_in_hand')}
              </div>
            </div> : null}
          {!canShowTakeInHand && <div>
            <AOButtonsControl
              id={id}
              isPostingsMode={isPostingsMode}
              setPostingsMode={() => setPostingsMode(true)} />
          </div>}
          {isAdvanceReportAttachmentPermission ? <AttachmentFilesPanel isReport={true} id={id} /> : null}
        </div>
        <EmployeeInformationModal isOpen={isOpenEmployeeInfo} onClose={toggleEmployeeInfo} id={id} isAdvance={true} isZNR={false}/>
        <CreateAOModal editId={id} isOpen={isOpenEditRequestModal} onClose={() => setOpenEditRequestModal(false)} />
        <DeleteReportModal id={id} isOpen={isOpenDeleteReportModal}
          onClose={() => setOpenDeleteReportModal(false)}
          onSubmit={deleteButtonSubmit} />
        <SubmitCustomModal isOpen={isOpenTakeInHandModal} title={t('request_detail.in_hand_request', { user: nameFormatter(advanceReportApplication.paperWork.employeeName, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language) })} onClose={togleIsOpenTakeInHandModal} onSubmit={sendRequestToTakeInHand} />
        <CreateOrderModal id={advanceReportApplication.id} editId={dailyRenewEditId == 0 ? undefined : dailyRenewEditId} isAdvance={true} isOpen={isShowDailyRenew} onClose={closeDailyRenew} dailyRenew={true} dailyRenewWithoutAlert={true} />
      </Fragment>
    )
  }

  const showTabMenu = () => {
    let tabArray = [<li onClick={showAppMode} className={`${isAppMode ? 'active' : ''} pointer`}><a>{t('report_detail.app')}</a></li>];

    if (fieldAccess.transactions) {
      tabArray.push(<li onClick={showPostingsMode} className={`${isPostingsMode ? 'active' : ''} pointer`}><a>{t('report_detail.postings')}</a></li>);
    }

    if (fieldAccess.approvals) {
      tabArray.push(<li onClick={showAgreementMode} className={`${isAgreementMode ? 'active' : ''} pointer`}><a>{t('report_detail.agreement')}</a></li>);
    }

    if (tabArray.length > 1) {
      return <div className="request-menu">
        <ul>
          {tabArray}
        </ul>
      </div>
    } else {
      return null
    }
  };
  
  return <Fragment>
    {advanceReportApplication.id && advanceReportAmountCalculationPermission != -1 && (
      <Fragment>
        {showTabMenu()}
        <If condition={isAppMode}>{showMainForm()}</If>
        <If condition={isPostingsMode}>{showPostingForm()}</If>
        <If condition={isAgreementMode}>{showAgreementForm()}</If>
      </Fragment>
    )}
    {!advanceReportApplication.id && advanceReportAmountCalculationPermission != -1 && isReportDetailDownloaded && (
      <div className="request-list empty">
        <div className="request-list-empty">
          <img src="/img/empty-img.png" />
          <div style={{ width: 'inherit' }}>{t('report_detail.not_found', { user: user.name[i18n.language as 'en' | 'ru'].split(' ')[0] })}</div>
        </div>
      </div>
    )}
    {advanceReportAmountCalculationPermission == -1 && isReportDetailDownloaded && (
      <div className="request-list empty">
        <div className="request-list-empty">
          <img src="/img/empty-img.png" />
          <div style={{ width: 'inherit' }}>{t('report_detail.not_access')}</div>
        </div>
      </div>
    )}
  </Fragment>
};

interface RequestCost {
  advanceCashAmount: number,
  totalAmount: number,
  approvedAmount: number
  status: string,
  approvedTotalAmount: number,
  approvedReportAmount: number,
}

export const RequestCost: React.FC<RequestCost> = props => {
  const advanceReportApplication: IAO = useSelector(getAdvanceReportApplication);
  const { t } = useTranslation();

  let [fieldAccess, setFieldAccess] = useState({
    number: false,
    associatedExpenseApplication_Number: false,
    expensesAccounting: false,
    reportExpenses: false,
    type: false,
    orderNumber: false,
    journeyNumber: false,
    businessTripNumber: false,
    businessTarget: false,
    description: false,
    vbsElement: false,
    departureFromCity: false,
    arrivalToCity: false,
    startOn: false,
    endOn: false,
    personalDaysNumber: false,
    advanceCashAmount: false,
    totalAmount: false,
    flowHistory: false,
    status: false
  });

  let [advanceReportAmountCalculationPermission, setAdvanceReportAmountCalculationPermission] = useState(0);

  useEffect(() => {
    setAdvanceReportAmountCalculationPermission(Config.getConfigToCompany(ConfigTypes.ADVANCE_REPORT_AMOUNT_CALCULATION, advanceReportApplication.company.id));
    generateFieldsAccess()
  }, [advanceReportApplication])

  const generateFieldsAccess = () => {
    if (advanceReportApplication && advanceReportApplication.properties) {
      let fieldAccess = {
        number: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.NUMBER),//stubProperties.includes('AdvanceReport_Number'),
        associatedExpenseApplication_Number: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.EXPENSE_APPLICATION_NUMBER),//stubProperties.includes('AdvanceReport_AssociatedExpenseApplication_Number'),
        expensesAccounting: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.EXPENSES_ACCOUNTING),
        reportExpenses: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.REPORT_EXPENSES),
        type: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.APPLICATION_TYPE),//stubProperties.includes('AdvanceReport_Type'),
        orderNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ORDER_NUMBER),//stubProperties.includes('AdvanceReport_OrderNumber'),
        journeyNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.JOURNEY_NUMBER),//stubProperties.includes('AdvanceReport_JourneyNumber'),
        businessTripNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.BUSINESS_TRIP_NUMBER),//stubProperties.includes('AdvanceReport_BusinessTripNumber'),
        businessTarget: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.BUSINESS_TARGET),//stubProperties.includes('AdvanceReport_BusinessTarget'),
        description: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.DESCRIPTION),//stubProperties.includes('AdvanceReport_Description'),
        vbsElement: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.VBS_ELEMENT),//stubProperties.includes('AdvanceReport_VbsElement'),
        departureFromCity: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.DEPARTURE_FROM_CITY),//stubProperties.includes('AdvanceReport_DepartureFromCity'),
        arrivalToCity: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ARRIVAL_TO_CITY),//stubProperties.includes('AdvanceReport_ArrivalToCity'),
        startOn: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.START_ON),//stubProperties.includes('AdvanceReport_StartOn'),
        endOn: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.END_ON),//stubProperties.includes('AdvanceReport_EndOn'),
        personalDaysNumber: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.PERSONAL_DAYS_NUMBER),//stubProperties.includes('AdvanceReport_PersonalDaysNumber'),
        advanceCashAmount: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ADVANCE_CASH_AMOUNT),//stubProperties.includes('AdvanceReport_AdvanceCashAmount'),
        totalAmount: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.TOTAL_AMOUNT),//stubProperties.includes('AdvanceReport_TotalAmount'),
        flowHistory: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.FLOW_HISTORY),//stubProperties.includes('AdvanceReport_FlowHistory'),
        status: true//advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.STATUS),//stubProperties.includes('AdvanceReport_Status'),
      }
      setFieldAccess(fieldAccess);
      return;
    }
  }

  return (
      <div className="request-item-row space-between cost-row" style={{ flexWrap: 'wrap' }}>
        <If condition={!advanceReportAmountCalculationPermission}>
          <div
              className={`request-item-block cost ${fieldAccess.totalAmount ? '' : 'd-none'}`}>
            <span>{t('request_detail.request_cost.commonAmountOnRequest')}</span>
            <span style={{marginTop: '4px'}} className="money">{toLocaleNumberFormatter(props.totalAmount)} {getCurrencieCode('rub')}</span>
          </div>
          {props.status != STATUS_TYPE_REPORT.DRAFT &&
          props.status != STATUS_TYPE_REPORT.APPROVAL_REQUIRES &&
          props.status != STATUS_TYPE_REPORT.ON_APPROVAL &&
          props.status != STATUS_TYPE_REPORT.NOT_APPROVED &&
          props.status != STATUS_TYPE_REPORT.REVOKED &&
          props.status != STATUS_TYPE_REPORT.CANCELLED ?
              <div className="request-item-block cost">
                <span>{t('request_detail.request_cost.approved')}</span>
                <span style={{marginTop: '4px'}} className="money">{toLocaleNumberFormatter(props.approvedAmount)} {getCurrencieCode('rub')}</span>
              </div> : null
          }
        </If>
        <If condition={!!advanceReportAmountCalculationPermission}>
          {fieldAccess.expensesAccounting && <div className="request-item-block cost">
            <span>{t('request_detail.request_cost.approvedTotalAmount')}</span>
            <span style={{marginTop: '4px'}} className="money advance">{toLocaleNumberFormatter(props.approvedTotalAmount)} {getCurrencieCode('rub')}</span>
          </div>}
          {fieldAccess.reportExpenses && <div className="request-item-block cost">
            <span>{t('request_detail.request_cost.approvedReportAmount')}</span>
            <span style={{marginTop: '4px'}} className="money">{toLocaleNumberFormatter(props.approvedReportAmount)} {getCurrencieCode('rub')}</span>
          </div>}
        </If>
      </div >
  )
};

export default withRouter(AODetail);
