import ModalContainer from 'app/component/modal/ModalContainer';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../checkbox/Checkbox';

interface SubmitApprovalModalProps {
    isOpen: boolean,
    onClose: () => void,
    onSubmit: () => void
}

const SubmitApprovalModal: React.FC<SubmitApprovalModalProps> = (props) => {

    const { t } = useTranslation();

    let [userAgree, setUserAgree] = useState(false);
    let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

    const userAgreeHandler = () => {
        setUserAgree(!userAgree);
    }

    const submitHandler = () => {
        userAgree && props.onSubmit();
    }

    const isHighestModal = (isHighest: boolean) => {
        setTabIndexDisabled(!isHighest);
    }

    return (
        <ModalContainer onEnter={submitHandler} isOpen={props.isOpen} highestModal={isHighestModal} destroy={true} overlayClick={props.onClose}>
            <div className="box-modal" id="note-delete" >
                <div className="box-modal_close arcticmodal-close pointer" onClick={props.onClose}></div>
                <div className="box-modal-title">
                    <div className="tx-center line-h-30">{t('request_detail.approval_daily_alert_title')}</div>
                </div>
                <div className="box-modal-content" >
                    <div className="text">
                        <div className="tx-center">{t('request_detail.approval_daily_alert_info')}</div>
                    </div>
                </div>
                <div className="box-modal-footer" style={{alignItems: 'center'}}>
                    <div className="input-item" style={{marginTop: '10px'}}>
                        <Checkbox name={'i_agree'} id={1} label={t('request_detail.approval_daily_alert_checkbox')} checked={userAgree} onClick={userAgreeHandler} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                    </div>
                    <a className="btn btn_black pointer" onClick={submitHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.delete_attachment.yes')}</a></div>
            </div>
        </ModalContainer>
    );
}

export default SubmitApprovalModal;
