import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import authService from 'services/AuthService';
import SvgIcon from '../svg-icon/SvgIcon';
import If from '../util/If';
import {useDispatch, useSelector} from 'react-redux';
import { ApplicationState } from 'store/reducers';
import { ROLE_TYPE, OCCUPATION_ROLE_TYPE } from 'infrastructure/enum/user-role.enum';
import { IOccupations } from 'infrastructure/interfaces';
import {getCommonUserDetail} from "../../../store/selectors";
import {PROFILE_FIELD_PERMISSIONS} from "../../../infrastructure/enum/field-permissions.enum";


export interface ProfileMenuProps {
  accessDenied: boolean,
  isOpen: boolean,
  onClose: () => void,
  isMobile?: boolean,
  toggleMobile?: () => void
}

const ProfileMenu: React.FC<ProfileMenuProps> = (props) => {
  const {t} = useTranslation();

  const userDetail = useSelector(getCommonUserDetail);
  const profileFieldPermissions = useSelector((state: ApplicationState) => state.common.profileFieldPermissions);

  const fieldPermissions = useMemo(() => {
    return {
      profile: profileFieldPermissions && profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.PROFILE),
      substitution: profileFieldPermissions && profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.SUBSTITUTION),
      settlement: profileFieldPermissions && profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.SETTLEMENT),
      assistance: profileFieldPermissions && profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.ASSISTANCE)
    }
  }, [profileFieldPermissions])

  const close = () => {
    props.onClose();
    props.isMobile && props.toggleMobile && props.toggleMobile();
  };

  const isAssistantManagerOccupation = (): boolean => {
    let occupations = userDetail.occupations.map((occupation: IOccupations) => occupation.roles).flat().map((item)=>item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.ASSISTANT_MANAGER);
  }

  return (
      <React.Fragment>
        {props.isOpen ? <div className="miss-click-handler" onClick={close}/> : ''}
        <div className={'mob-menu ' + (props.isOpen ? ' profile-user-open' : '')}>
          {!props.accessDenied ? <ul className="mob-menu-wrap">
            <If condition={fieldPermissions.profile}>
            <li><Link onClick={close} className="header-controls__link" tabIndex={-1}
                      to="/User/Profile">{t('user_menu.profile')}</Link></li>
            </If>
            <If condition={fieldPermissions.settlement}>
            <li><Link onClick={close} className="header-controls__link" tabIndex={-1}
                      to="/User/Settlement">{t('user_menu.settlement')}</Link></li>
            </If>
            <If condition={fieldPermissions.assistance}>
              <li><Link onClick={close} className="header-controls__link" tabIndex={-1}
                        to="/User/Assistance">{t('user_menu.assistance')}</Link></li>
            </If>
            <If condition={fieldPermissions.substitution}>
            <li><Link onClick={close} className="header-controls__link" tabIndex={-1}
                      to="/User/Substitution">{t('user_menu.substitution')}</Link>
            </li>
            </If>
          </ul> : null}
          <div className="logout">
            <a className="header-controls__link pointer" onClick={() => authService.logout()}>
              <SvgIcon className="icon icon-exit" href="#svg_icon_exit"/>
              {t('user_menu.logout')}
            </a>
          </div>
        </div>
      </React.Fragment>
  );
}
export default ProfileMenu;
