/**
 * @typedef {Object} AGREEMENT_RESOLUTION_TYPE
 * @property {string} APPROVED - согласовано
 * @property {string} REVOKED - отказано
 * @property {string} ON_APPROVAL - на согласовании
 */

export enum AGREEMENT_RESOLUTION_TYPE {
    APPROVED = 'Approved',
    REVOKED = 'Revoked',
    ON_APPROVAL = 'On Approval',
    NOT_APPROVED = 'NotApproved',
    REVISION = 'Revision',
}