import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatter, betweenTwoDates } from 'app/utils';
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import DatePickerCalendar from 'app/component/date-picker/DatePickerCalendar';
import { TravelDates, IAO, IOccupations } from 'infrastructure/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { updateDetailedAO } from 'store/report/actions';
import { loaderLock, loaderUnlock } from 'store/common/actions';
import moment from 'moment';
import { showErrors } from 'store/exception/actions';
import { STATUS_TYPE_REPORT } from 'infrastructure/enum/status-report.enum';
import { OCCUPATION_ROLE_TYPE } from 'infrastructure/enum/user-role.enum';
import If from 'app/component/util/If';
import Config, { ConfigTypes } from 'services/ConfigService';
import CreateOrderModal from 'app/component/modal/CreateOrderModal';
import {getAdvanceReportApplication, getCommonUserDetail} from "../../../store/selectors";
import {
  deleteExpensesById,
  updateAdvanceReportApplicationsById,
  updatePerDiemCalculations
} from "../../../services/ApiService";

interface AOTravelProps {
  dates: TravelDates[],
  personalDaysNumber: number,
  isVisible?: boolean,
  canEdit?: boolean
}

const AOTravel: React.FC<AOTravelProps> = (props) => {
  const { t, i18n } = useTranslation();

  let listDate = null;
  let [isShowDatePicker, setShowDatePicker] = useState(false);
  let [disableBeforeDate, setDisableBeforeDate] = useState(new Date());
  let [disableAfterDate, setDisableAfterDate] = useState(new Date());
  let [disabledDates, setDisabledDates] = useState([] as { from: Date, to: Date }[]);
  let [canEdit, setCanEdit] = useState(true);
  let [isShowDailyRenew, setShowDailyRenew] = useState(false);

  const user = useSelector(getCommonUserDetail);
  const advanceReportApplication: IAO = useSelector(getAdvanceReportApplication);
  const dispatch = useDispatch();


  const daysCount = (applicationTravelDates: TravelDates[]) => {
    let daysCount = 0;
    applicationTravelDates.forEach((dates: TravelDates) => {
      daysCount = daysCount + betweenTwoDates(dates.startDate, dates.endDate);
    });
    return daysCount;
  }

  const isBookerOccupation = (): boolean => {
    let occupations = user.occupations.map((occupation: IOccupations) => occupation.roles).flat().map((item)=>item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  }

  const saveSubmit = async (data: any) => {
    dispatch(loaderLock());
    let perDiemCalculationType = Config.getConfigToCompany(ConfigTypes.PERDIEM_CALCULATION_TYPE, advanceReportApplication.company.id);
    let response = await updateAdvanceReportApplicationsById(advanceReportApplication.id, data);
    if (response.headers.success) {
      let needRenew = false;
      let dateDiffer = (dateA: any, dateB: any) => {
        return +moment(dateA).format('YYYYMMDD') == +moment(dateB).format('YYYYMMDD')
      };

      if (perDiemCalculationType != 0 && advanceReportApplication.expenses.filter(item => item.expenseType.expenseGroup.code == "Perdiem").length > 0) {
        if (data.businessTripOption && !dateDiffer(data.businessTripOption.startOn, advanceReportApplication.startOn)) {
          needRenew = true;
        }
        if (data.businessTripOption && !dateDiffer(data.businessTripOption.endOn, advanceReportApplication.endOn)) {
          needRenew = true;
        }

        if (data.businessTripOption && data.businessTripOption.departureFromCityId != advanceReportApplication.departureFromCityId.id) {
          needRenew = true;
        }
        if (data.businessTripOption && data.businessTripOption.arrivalToCityId != advanceReportApplication.arrivalToCityId.id) {
          needRenew = true;
        }

        let oldTrip = advanceReportApplication.businessTripDestinations;
        let newTrip = data.businessTripOption ? data.businessTripOption.businessTripDestination ? data.businessTripOption.businessTripDestination : [] : []
        if (oldTrip.length == newTrip.length) {
          oldTrip.sort((a, b) => a.ordinal - b.ordinal);
          newTrip.sort((a: any, b: any) => a.ordinal - b.ordinal);

          for (let index = 0; index < oldTrip.length; index++) {
            const oldElement = oldTrip[index];
            const newElement = newTrip[index];
            if (oldElement.city.id != newElement.cityId) {
              needRenew = true;
            }
            if (!dateDiffer(oldElement.leaveOn, newElement.leaveOn)) {
              needRenew = true;
            }
            if (!dateDiffer(oldElement.arrivalOn, newElement.arrivalOn)) {
              needRenew = true;
            }
          }
        } else {
          needRenew = true;
        }
      }
      if (needRenew) {
        await dailyRenew();
      } else {
        dispatch(updateDetailedAO(advanceReportApplication.id));
      } 
      dispatch(loaderUnlock())
    } else {
      dispatch(showErrors({ code: 'save_advance_report', message: 'Не удалось сохранить данные' }))
    }
  }

  const dailyRenew = async () => {
    dispatch(loaderLock());
    let a = advanceReportApplication.expenses.filter((item: any) => { return item.expenseType.expenseGroup.code == "Perdiem" }).map((item: any) => item.id);

    for (let index = 0; index < a.length; index++) {
      const element = a[index];
      await deleteExpensesById(element);
    }

    let data = {
      applicationRef: {
        id: advanceReportApplication.id,
        logicalName: 'AdvanceReportApplication',
        name: {
          ru: 'AdvanceReportApplication',
          en: 'AdvanceReportApplication'
        }
      },
      calculations: []
    }
    await updatePerDiemCalculations(data);
    await dispatch(updateDetailedAO(advanceReportApplication.id));

    dispatch(loaderUnlock());
    openDailyRenew();
  }

  const openDailyRenew = () => {
    setShowDailyRenew(true);
  }

  const closeDailyRenew = () => {
    setShowDailyRenew(false);
  }

  const savePersonalTravel = (dates: TravelDates[]) => {
    if (advanceReportApplication) {
      let data: any = {
        applicationTypeId: advanceReportApplication.applicationType.id,
        businessTargetId: advanceReportApplication.businessTarget.id,
        description: advanceReportApplication.description,
        //costCentersOption: [...advanceReportApplication.costCenters.map(costCenter => ({ costCenterId: costCenter.id, percent: costCenter.percent }))],
        businessTripOption: {
          departureFromCityId: advanceReportApplication.departureFromCityId.id,
          startOn: advanceReportApplication.startOn,
          endOn: advanceReportApplication.endOn,
          arrivalToCityId: advanceReportApplication.arrivalToCityId.id,
          personalDaysNumber: daysCount(dates),
          applicationTravelDates: dates
        }
      }
      saveSubmit(data);
    }
  }

  if (props.dates.length) {
    listDate = props.dates.map((date, index) => {
      return (
        <div className="input-choose-item HarmoniaSansProCyr">{formatter('D MMMM', i18n.language, date.startDate, date.endDate, { withMonth: false, withYear: false })}
          <If condition={canEdit}>
            <div onClick={() => deleteHandler(index)}>
              <SvgIcon className="icon icon-close" href="#svg_icon_close" index={index} />
            </div>
          </If>
        </div>
      )
    })
  }

  let deleteHandler = (index: number) => {
    let result = [...props.dates];
    result.splice(index, 1);
    savePersonalTravel(result);
  }

  const toggleDatePicker = () => {
    setShowDatePicker(!isShowDatePicker);
  }

  const onSelected = (value: any) => {
    let result = [...props.dates];
    result.push({ startDate: value.startDate, endDate: value.endDate });
    savePersonalTravel(result);
    toggleDatePicker();
  }

  const checkCanEdit = (status: string) => {
    setCanEdit(!(status == STATUS_TYPE_REPORT.POSTED || status == STATUS_TYPE_REPORT.APPROVED || status == STATUS_TYPE_REPORT.ON_APPROVAL || (isBookerOccupation() && user.id !== advanceReportApplication.assigneeEmployee.id)));
  };

  useEffect(() => {
    if (advanceReportApplication) {
      setDisableBeforeDate(moment(advanceReportApplication.startOn, "YYYY-MM-DDThh:mm:ss").toDate());
      setDisableAfterDate(moment(advanceReportApplication.endOn, "YYYY-MM-DDThh:mm:ss").toDate());
      checkCanEdit(advanceReportApplication.status);
    }

  }, [advanceReportApplication]);

  useEffect(() => {
    if (props.dates) {
      let newDisabledDates = props.dates.map(date => {
        return {
          from: moment(date.startDate, "YYYY-MM-DDThh:mm:ss").toDate(),
          to: moment(date.endDate, "YYYY-MM-DDThh:mm:ss").toDate()
        }
      });
      setDisabledDates(newDisabledDates);
    }

  }, [props.dates]);

  return (
    <div className={`request-item-block ${props.isVisible ? '' : 'd-none'}`} style={{width: '100%'}}>
      <div className="request-item-label add-trip-label">{t('request_detail.personal_travel.personalTravel')} ({props.personalDaysNumber}д.)
        <If condition={props.canEdit}>
          <div className="btn btn_gray add-trip" id="datepicker" style={{marginLeft: '5px'}} onClick={toggleDatePicker}>+ {t('request_detail.personal_travel.add')}</div>
        </If>
      </div>
      <DatePickerCalendar
        isShown={isShowDatePicker}
        isDisableBeforeToday={true}
        isMultiChoice={true}
        isCleansed={true}
        onSelected={onSelected}
        onClose={toggleDatePicker}
        disableBeforeDate={disableBeforeDate}
        disableAfterDate={disableAfterDate}
        disabledDates={disabledDates}
        month={disableBeforeDate} />
      <div className="request-item-content">
        <div className="input-choose">
          {listDate}
        </div>
      </div>
      <div className="request-item-content"></div>
      <CreateOrderModal id={advanceReportApplication.id} isOpen={isShowDailyRenew} isAdvance={true} onClose={closeDailyRenew} dailyRenew={true} />
    </div>
  )
}

export default AOTravel;
