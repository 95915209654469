import React from "react";
import { CSSProperties } from "react";
import { Form } from "react-bootstrap";

interface CheckboxProps {
  name: string;
  id: number;
  label: string;
  checked: boolean;
  onClick: (id: number) => void;
  className?: string;
  type?: string;
  disabled?: boolean;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  tabIndex?: -1 | 0;
  isWidget?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const clickHandler = () => {
    !props.disabled && props.onClick(props.id);
  };

  const onChange = () => {};

  return props.isWidget ? (
    <div style={{marginBottom: "1rem"}}>
    <Form.Group
      className={`${!!props.className ? props.className : ""}`}
      onClick={clickHandler}
    >
      <Form.Check
        type="checkbox"
        name={props.name}
        id={props.name + "-" + props.id}
        checked={props.checked}
        label={props.label}
        disabled={props.disabled}
        onChange={onChange}
        tabIndex={props.tabIndex}
      />
    </Form.Group>
    </div>
  ) : (
    <div
      className={`checkbox ${!!props.className ? props.className : ""}`}
      onClick={clickHandler}
      style={props.style}
    >
      <input
        type="checkbox"
        name={props.name}
        id={props.name + "-" + props.id}
        checked={props.checked}
        disabled={props.disabled}
        onChange={onChange}
        tabIndex={props.tabIndex}
      />
      <label
        data-type={props.type}
        htmlFor={props.id.toString()}
        style={props.labelStyle}
      >
        {props.label}
      </label>
    </div>
  );
};

export default Checkbox;
