import { BaseMapper } from './base.mapper';
import { BusinessGoal, BusinessGoalResponse } from 'infrastructure/interfaces';


export class BusinessGoalMapper extends BaseMapper<BusinessGoal,BusinessGoalResponse> {
  responseToEntity(response: BusinessGoalResponse): BusinessGoal {
    return {
      id: response.businessTarget.id,
      name:{
        ru: response.businessTarget.name.ru,
        en: response.businessTarget.name.en
      }
    };
  };

  fullResponseToEntities(response: any): BusinessGoal[] {
    return this.responsesToEntitys(response.data)
  };
}
