import React, { useState, useRef, Fragment, useEffect } from 'react';
import EmployeeAutocomplete, { EmployeeAutocompleteOptionType } from '../autocomplete/EmployeeAutocomplete';
import DatePickerCalendar from '../date-picker/DatePickerCalendar';
import ModalContainer from './ModalContainer';
import { useTranslation } from 'react-i18next';
import { formatter, nameFormatter, NameFormats } from 'app/utils';
import SvgIcon from '../svg-icon/SvgIcon';
import Validator from '../validator/Validator';
import { useDispatch, useSelector } from 'react-redux';
import { loaderLock, loaderUnlock } from 'store/common/actions';
import { setSubstitutionList, updateSubstitutionList } from 'store/substitution/actions';
import { updateAOList } from 'store/report/actions';
import { showErrors } from 'store/exception/actions';
import { UserDetailMapper, SubstitutionMapper } from 'infrastructure/mappers';
import { ApplicationState } from 'store/reducers';
import Select, { SelectOptionType } from '../select/Select';
import { get } from 'lodash';
import Config, { ConfigTypes } from 'services/ConfigService';
import moment from 'moment';
import {getCommonUserDetail} from "../../../store/selectors";
import {
    getEmployeeDeputiesById,
    getOccupationsShortByEmployeeId,
    postEmployeeDeputiesById, updateEmployeeDeputiesById
} from "../../../services/ApiService";

interface CreateSubstitutionProps {
    editId?: number,
    isOpen: boolean;
    onClose: () => void;
}

const CreateSubstitution: React.FC<CreateSubstitutionProps> = (props) => {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    let userDetail = useSelector(getCommonUserDetail);

    let [start, setStart] = useState(null as any);
    let [end, setEnd] = useState(null as any);
    let [firstEmployee, setFirstEmployee] = useState({} as EmployeeAutocompleteOptionType);
    let [firstEmployeeOccupation, setFirstEmployeeOccupation] = useState({} as SelectOptionType);
    let [firstEmployeeOccupations, setFirstEmployeeOccupations] = useState([] as SelectOptionType[]);
    let [secondEmployee, setSecondEmployee] = useState({} as EmployeeAutocompleteOptionType);
    let [secondEmployeeOccupation, setSecondEmployeeOccupation] = useState({} as SelectOptionType);
    let [secondEmployeeOccupations, setSecondEmployeeOccupations] = useState([] as SelectOptionType[]);
    let [companyId, setCompanyId] = useState(0);
    let [showDateRange, setShowDateRange] = useState(false);
    let [isEdit, setIsEdit] = useState(false);
    let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

    let firstEmployeeValidatorRef = useRef({} as any);
    let firstEmployeeOccupationValidatorRef = useRef({} as any);
    let secondEmployeeValidatorRef = useRef({} as any);
    let secondEmployeeOccupationValidatorRef = useRef({} as any);
    let rangeDateValidatorRef = useRef({} as any);
    let firstEmployeeRef = useRef({} as any);
    let secondeEmployeeRef = useRef({} as any);

    useEffect(() => {
        if (props.isOpen) {
            if (props.editId) {
                editValues();
            } else {
                clearValues();
            }
        }
    }, [props.isOpen])

    const clearValues = () => {
        setStart(null);
        setEnd(null);
        setSecondEmployee({} as EmployeeAutocompleteOptionType);
        setSecondEmployeeOccupation({text: '---'} as any);
        setSecondEmployeeOccupations([]);

        let occupationArray = userDetail.occupations
            .filter(occupation => (Config.getConfigToCompany(ConfigTypes.EMPLOYEE_DEPUTIES_SOURCE, occupation.company.id) == 1))
            .map(occupation => {
                return {
                    text: `${occupation.company.name[i18n.language as 'ru']} - ${occupation.name[i18n.language as 'ru']}`,
                    value: occupation.occupationId,
                    companyId: occupation.company.id
                }
            });
        setFirstEmployee({ text: nameFormatter(userDetail.name, NameFormats.FULL, i18n.language), value: userDetail.occupations[0].occupationId } as EmployeeAutocompleteOptionType);
        setFirstEmployeeOccupation(occupationArray[0]);
        setFirstEmployeeOccupations(occupationArray);
        setCompanyId(occupationArray[0].companyId);

    }

    const editValues = async () => {
        dispatch(loaderLock());
        setIsEdit(true);

        let response = await getEmployeeDeputiesById(props.editId);
        if (response.headers.success) {
            let result = response.data;
            setFirstEmployee({ text: result.employeeOccupation.employee.name[i18n.language as 'ru'], value: result.employeeOccupation.id });
            setFirstEmployeeOccupation({
                text: `${result.employeeOccupation.company.name[i18n.language as 'ru']} - ${result.employeeOccupation.name[i18n.language as 'ru']}`,
                value: result.employeeOccupation.id,
                companyId: result.employeeOccupation.company.id
            } as any);

            let occupationArray = userDetail.occupations
                .filter(occupation => (Config.getConfigToCompany(ConfigTypes.EMPLOYEE_DEPUTIES_SOURCE, occupation.company.id) == 1))
                .map(occupation => {
                    return {
                        text: `${occupation.company.name[i18n.language as 'ru']} - ${occupation.name[i18n.language as 'ru']}`,
                        value: occupation.occupationId,
                        companyId: occupation.company.id
                    }
                });
            setFirstEmployeeOccupations(occupationArray);
            setCompanyId(result.employeeOccupation.company.id);

            let secondResult = await getOccupationsShortByEmployeeId(result.deputyOccupation.employee.id, result.employeeOccupation.company.id);

            if (secondResult.headers.success) {
                let newOccupations = secondResult.data.map((item: any) => {
                    return {
                        text: `${get(item, `company.name.${i18n.language}`, '')} - ${get(item, `name.${i18n.language}`, '')}`,
                        value: get(item, 'id', 0),
                        detail: {
                            companyId: get(item, 'company.id', 0)
                        }
                    }
                });

                setSecondEmployee({ text: result.deputyOccupation.employee.name[i18n.language as 'ru'], value: result.deputyOccupation.id });
                setSecondEmployeeOccupation({
                    text: `${result.deputyOccupation.company.name[i18n.language as 'ru']} - ${result.deputyOccupation.name[i18n.language as 'ru']}`,
                    value: result.deputyOccupation.id,
                    companyId: result.deputyOccupation.company.id
                } as any);
                setSecondEmployeeOccupations(newOccupations);

                setStart(moment(result.startOn, "YYYY-MM-DDThh:mm:ss").toDate());
                setEnd(moment(result.endOn, "YYYY-MM-DDThh:mm:ss").toDate());
            }
        } else {
            dispatch(showErrors({ code: 'update_agreement_list', message: `Не удалось загрузить замещение` }));
        }

        dispatch(loaderUnlock());
    }

    const validate = () => {
        let isValid = true;
        let validateList = [
            firstEmployeeValidatorRef.current.validate(),
            firstEmployeeOccupationValidatorRef.current.validate(),
            secondEmployeeValidatorRef.current.validate(),
            secondEmployeeOccupationValidatorRef.current.validate(),
            rangeDateValidatorRef.current.validate()
        ];
        validateList.forEach((item) => {
            if (!item) {
                isValid = false;
            }
        });
        return isValid;
    }

    const completeHandler = () => {
        if (props.editId) {
            editHandler();
        } else {
            saveHandler();
        }
    }

    const saveHandler = async () => {
        if (validate()) {
            let data = {
                employeeOccupationId: firstEmployeeOccupation.value,
                deputyOccupationId: secondEmployeeOccupation.value,
                startOn: start,
                endOn: end,
                companyId: companyId
            }
            saveSubmit(data);
        }
        setIsEdit(false);
    }

    const onClose = () => {
        props.onClose();
        setIsEdit(false);
    }

    const saveSubmit = async (data: any) => {
        dispatch(loaderLock());
        let response = await postEmployeeDeputiesById(data);
        if (response.headers.success) {
            dispatch(updateSubstitutionList());
            onClose();
        } else {
            if (response.status == 403) {
                dispatch(showErrors({ code: 'save_employee_deputies', message: response.data.Errors[0] }))

            }
            if (response.status != 403) {
                dispatch(showErrors({ code: 'save_employee_deputies', message: 'Не удалось создать замещение' }));
            }
            onClose();
        }
        dispatch(loaderUnlock());
    }

    const editHandler = async () => {
        if (validate()) {
            let data = {
                employeeOccupationId: firstEmployeeOccupation.value,
                deputyOccupationId: secondEmployeeOccupation.value,
                startOn: start,
                endOn: end,
                companyId: companyId
            }
            editSubmit(data);
        }
        setIsEdit(false);
    }

    const editSubmit = async (data: any) => {
        dispatch(loaderLock());
        let response = await updateEmployeeDeputiesById(props.editId, data);
        if (response.headers.success) {
            dispatch(updateSubstitutionList());
            onClose();
        } else {
            if (response.status == 403) {
                dispatch(showErrors({ code: 'save_employee_deputies', message: response.data.Errors[0] }))

            }
            if (response.status != 403) {
                dispatch(showErrors({ code: 'save_employee_deputies', message: 'Не удалось создать замещение' }));
            }
            onClose();
        }
        dispatch(loaderUnlock());
    }

    const firstEmployeeChooseHandler = (option: EmployeeAutocompleteOptionType) => {
        setFirstEmployee(option);
    }

    const firstEmployeeOccupationChooseHandler = (option: any) => {
        setFirstEmployeeOccupation(option);
        setCompanyId(option.companyId);
    }

    const secondEmployeeChooseHandler = async (option: EmployeeAutocompleteOptionType) => {
        if (!option.value) {
            setSecondEmployeeOccupation({} as any);
            setSecondEmployeeOccupations([]);
            return
        }
        let result = await getOccupationsShortByEmployeeId(option.value, companyId);

        if (result.headers.success) {
            let newOccupations = result.data.map((item: any) => {
                return {
                    text: `${get(item, `company.name.${i18n.language}`, '')} - ${get(item, `name.${i18n.language}`, '')}`,
                    value: get(item, 'id', 0),
                    detail: {
                        companyId: get(item, 'company.id', 0)
                    }
                }
            })

            if (newOccupations.length > 0) {
                setSecondEmployee({ text: option.text, value: newOccupations[0].value });
                setSecondEmployeeOccupation(newOccupations[0]);
                setSecondEmployeeOccupations(newOccupations);
            } else {
                setSecondEmployee({ text: option.text, value: option.value });
                setSecondEmployeeOccupation({} as any);
                setSecondEmployeeOccupations([]);
                dispatch(showErrors({
                    code: 'expense_applications_error',
                    message: t('sub_list.errors.empty_occupation')
                }));
            }
        } else {
            dispatch(showErrors({
                code: 'expense_applications_error',
                message: 'Не удалось получить список назначений'
            }));
        }
    }

    const secondEmployeeOccupationChooseHandler = (option: SelectOptionType) => {
        setSecondEmployeeOccupation(option);
    }

    const openDateRangeHandler = () => {
        setShowDateRange(true);
    }

    const closeDateRangeHandler = () => {
        setShowDateRange(false);
    }

    const selectRangeDateHandler = (value: any) => {
        setStart(value.startDate);
        setEnd(value.endDate ? value.endDate : value.startDate);
        closeDateRangeHandler();
    }

    const isHighestModal = (isHighest: boolean) => {
        setTabIndexDisabled(!isHighest);
    }

    return (
        <ModalContainer onEnter={saveHandler} isOpen={props.isOpen} highestModal={isHighestModal} overlayClick={onClose} destroy={true}>
            <div className="box-modal" id="add-reassignment">
                <div className="box-modal_close arcticmodal-close" onClick={onClose}></div>
                <div className="box-modal-title">{isEdit ? t('sub_list.edit_modal') : t('sub_list.add_modal')}</div>
                <div className="box-modal-content">
                    <div className="box-modal-form">
                        <div className="box-modal-form-block">
                            <div className="input-item-row">
                                <Validator ref={firstEmployeeValidatorRef} type={'text'} className={'fl-grow'}>
                                    <Fragment>
                                        <label className="input-label">
                                            {t('sub_list.person_charge')}<i className="input-required">*</i></label>
                                        <div className="input-search">
                                            <EmployeeAutocomplete
                                                ref={firstEmployeeRef}
                                                placeholder={t('sub_list.enter_name')}
                                                onChoose={firstEmployeeChooseHandler}
                                                defaultText={firstEmployee.text}
                                                readOnly={true}
                                                tabIndex={isTabIndexDisabled ? -1 : 0}
                                            />
                                        </div>
                                    </Fragment>
                                </Validator>
                            </div>
                            <div className="input-item-row">
                                <Validator ref={firstEmployeeOccupationValidatorRef} type={'select'} className={'fl-grow'}>
                                    <Fragment>
                                        <label className="input-label">
                                            {t('sub_list.person_charge_ccupation')}<i className="input-required">*</i></label>
                                        <Select
                                            ref={firstEmployeeRef}
                                            options={firstEmployeeOccupations}
                                            onChoose={firstEmployeeOccupationChooseHandler}
                                            defaultValue={firstEmployeeOccupation}
                                        />
                                    </Fragment>
                                </Validator>
                            </div>
                            <div className="input-item-row">
                                <Validator ref={secondEmployeeValidatorRef} type={'text'} className={'fl-grow'}>
                                    <Fragment>
                                        <label className="input-label">
                                            {t('sub_list.deputy')}<i className="input-required">*</i></label>
                                        <div className="input-search">
                                            <EmployeeAutocomplete
                                                ref={secondeEmployeeRef}
                                                placeholder={t('sub_list.enter_name')}
                                                onChoose={secondEmployeeChooseHandler}
                                                defaultText={secondEmployee.text}
                                                tabIndex={isTabIndexDisabled ? -1 : 0}
                                            />
                                        </div>
                                    </Fragment>
                                </Validator>
                            </div>
                            <div className="input-item-row">
                                <Validator ref={secondEmployeeOccupationValidatorRef} type={'select'} className={'fl-grow'}>
                                    <Fragment>
                                        <label className="input-label">
                                            {t('sub_list.deputy_occupation')}<i className="input-required">*</i></label>
                                        <Select
                                            ref={secondeEmployeeRef}
                                            options={secondEmployeeOccupations}
                                            onChoose={secondEmployeeOccupationChooseHandler}
                                            defaultValue={secondEmployeeOccupation}
                                        />
                                    </Fragment>
                                </Validator>
                            </div>
                        </div>
                        <div className="box-modal-form-block">
                            <div className="input-item-row">
                                <Validator ref={rangeDateValidatorRef} type={'text'}>
                                    <Fragment>
                                        <label className="input-label">
                                            {'Период замещения'}<i className="input-required">*</i></label>
                                        <div className="input-date pointer">
                                            <input className="input pointer" type="text" onClick={openDateRangeHandler} value={start && end ? (formatter('D MMMM YYYY', i18n.language, start) + ' - ' + formatter('D MMMM YYYY', i18n.language, end)) : ''} id="datepicker_8" readOnly tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                            <SvgIcon className={'icon icon-calendar pointer'} href={'#svg_icon_calendar'} />
                                        </div>
                                        <DatePickerCalendar
                                            onClose={closeDateRangeHandler}
                                            isShown={showDateRange}
                                            isCleansed={false}
                                            isMultiChoice={true}
                                            onSelected={selectRangeDateHandler}
                                            isDisableBeforeToday={true} />
                                    </Fragment>
                                </Validator>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box-modal-footer">
                    <a className="arcticmodal-close btn btn_black" onClick={onClose} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('sub_list.cancel')}</a>
                    <a className="btn-expense btn_green" onClick={completeHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('sub_list.complete')}</a>
                </div>
            </div>
        </ModalContainer>
    )
}

export default CreateSubstitution;