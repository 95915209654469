import React, { Fragment, useState, useRef, useEffect } from 'react';
import Dropdown from '../dropdown/Dropdown';
import EmployeeAutocomplete, { EmployeeAutocompleteOptionType } from '../autocomplete/EmployeeAutocomplete';
import CityAutocomplete, { CityAutocompleteOptionType } from '../autocomplete/CityAutocomplete';
import InputChoose, { InputChooseItem } from '../inputchoose/InputChoose';
import { useTranslation } from 'react-i18next';
import DateInput from '../date-input/DateInput';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/reducers';
import { hideFilter, showFilter } from 'store/common/actions';
import { updateJournalList, setJournalPage, JournalListFilter, setJournalFilter } from 'store/journal/actions';
import UploadButton from '../buttons-control/UploadButton';
import {JOURNAL_FIELD_PERMISSIONS} from "../../../infrastructure/enum/field-permissions.enum";
import {parseTime} from "../../utils";
import {getCommonFilterState, getJournalFilter} from "../../../store/selectors";

interface JournalFilterProps {
    isAdvance?: boolean;
}

const JournalFilter: React.FC<JournalFilterProps> = (props) => {
    const {t} = useTranslation();

    let [downloadDateStart, setDownloadDateStart] = useState(null as any);
    let [downloadDateEnd, setDownloadDateEnd] = useState(null as any);
    let [downloadLastDateStart, setDownloadLastDateStart] = useState(null as any);
    let [downloadLastDateEnd, setDownloadLastDateEnd] = useState(null as any);
    let [tripDateStart, setTripDateStart] = useState(null as any);
    let [tripDateEnd, setTripDateEnd] = useState(null as any);
    let [dateCreate, setDateCreate] = useState(null as any);
    let [employee, setEmployee] = useState({} as EmployeeAutocompleteOptionType);
    let [uploadNumber, setUploadNumber] = useState('');
    let [tripNumber, setTripNumber] = useState('');
    let [itemNumber, setItemNumber] = useState('');
    let [width, setWidth] = useState(0);
    let [unloaded, setUnloaded] = useState(0);
    let [options, setOptions] = useState([] as InputChooseItem[]);

    let cityAutocompleteRef = useRef({} as any);
    let dateCreateRef = useRef({} as any);
    let itemNumberRef = useRef({} as any);
    let tripNumberRef = useRef({} as any)
    let uploadNumberRef = useRef({} as any);
    let downloadDateStartRef = useRef({} as any);
    let downloadDateEndRef = useRef({} as any);
    let downloadLastDateStartRef = useRef({} as any);
    let downloadLastDateEndRef = useRef({} as any);
    let tripDateStartRef = useRef({} as any);
    let tripDateEndRef = useRef({} as any);
    let employeeRef = useRef({} as any);

    let filterState = useSelector(getCommonFilterState);
    let filterParams = useSelector(getJournalFilter);

    let user = useSelector((state: ApplicationState) => state.common.userDetail);
    const fieldPermissions = useSelector((state: ApplicationState) => state.journal.fieldPermissions);

    const dispatch = useDispatch();

    let [isFilterOpen, setFilterOpen] = useState(filterState);
    let [filterTimer, setFilterTimer] = useState(null as unknown as NodeJS.Timeout);
    let [isClearingFilter, setClearingFilter] = useState(false);
    let [isInitialized, setInitialized] = useState(false);

    const resizeHandler = () => {
        if (window.innerWidth <= 959) {
            dispatch(hideFilter())
        } else {
            dispatch(showFilter())
        }
    }

    const resizeEventHander = (e: any) => {
        if (width !== window.innerWidth) {
            resizeHandler();
            setWidth(window.innerWidth);
        }
    }

    useEffect(() => {
        setFilterOpen(filterState)
        window.addEventListener('resize', resizeEventHander)
        return () => {
            window.removeEventListener('resize', resizeEventHander);
        }
    }, [filterState])

    useEffect(() => {
        resizeHandler();
        if (props.isAdvance) {
            dispatch(setJournalPage(1));
            dispatch(setJournalFilter({}));
            dispatch(updateJournalList('AdvanceReportApplication', false));
        } else {
            dispatch(setJournalPage(1));
            dispatch(setJournalFilter({}));
            dispatch(updateJournalList('ExpenseApplication', false));
        }
        setInitialized(true);
        return () => {
            clearAll(false);
        }
    }, []);

    useEffect(() => {
        if (isInitialized) {
            filter();
            setClearingFilter(false);
        }
    }, [options, employee,  tripDateStart, tripDateEnd, dateCreate])

    useEffect(() => {
        if (isInitialized && !isClearingFilter) {
            clearTimeout(filterTimer);
            setFilterTimer(setTimeout(() => {
                filter();
            }, 2000))
        }
    }, [itemNumber, tripNumber, uploadNumber, downloadDateStart, downloadDateEnd, downloadLastDateStart, downloadLastDateEnd])

    const clearAll = (needUpdate: boolean = true) => {
        setDownloadDateStart(null);
        setDownloadDateEnd(null);
        setDownloadLastDateStart(null);
        setDownloadLastDateEnd(null);
        setTripDateStart(null);
        setTripDateEnd(null);
        setDateCreate(null);
        setEmployee({} as EmployeeAutocompleteOptionType);
        setUploadNumber('');
        setTripNumber('');
        setItemNumber('');
        setOptions([]);
        setUnloaded(0);
        dateCreateRef.current.clear();
        downloadDateStartRef.current.clear();
        downloadDateEndRef.current.clear();
        downloadLastDateStartRef.current.clear();
        downloadLastDateEndRef.current.clear();
        tripDateStartRef.current.clear();
        tripDateEndRef.current.clear();
        employeeRef.current.clear();
        setClearingFilter(true);

        dispatch(setJournalPage(1));
        dispatch(setJournalFilter({}));
        window.scrollTo(0, 0);
    }

    const filter = () => {
        let params = {} as JournalListFilter;

        if (uploadNumber != '') {
            params.uploadNumber = uploadNumber;
        }

        if (tripNumber != '') {
            params.tripNumber = tripNumber;
        }

        if (itemNumber != '') {
            params.itemNumber = itemNumber;
        }

        if (unloaded) {
            params.unloaded = unloaded;
        }

        if (employee && employee.value) {
            params.employee = +employee.value;
        }

        if (downloadDateStart) {
            params.downloadDateStart = downloadDateStart as Date;
        }

        if (downloadDateEnd) {
            params.downloadDateEnd = downloadDateEnd as Date;
        }

        if (downloadLastDateStart) {
            params.downloadLastDateStart = downloadLastDateStart as Date;
        }

        if (downloadLastDateEnd) {
            params.downloadLastDateEnd = downloadLastDateEnd as Date;
        }

        if (tripDateStart) {
            params.tripDateStart = tripDateStart as Date;
        }

        if (tripDateEnd) {
            params.tripDateEnd = tripDateEnd as Date;
        }

        if (dateCreate) {
            params.dateCreate = dateCreate as Date;
        }
        if (options) {
            params.cityId = [...options.map(option => option.value)];
        }

        dispatch(setJournalPage(1));
        dispatch(setJournalFilter(params));
        dispatch(updateJournalList(props.isAdvance ? 'AdvanceReportApplication': 'ExpenseApplication', false));
    }

    const forceUpdateFilter = () => {
        clearTimeout(filterTimer);
        filter();
    }

    const onEnterClickHandler = (event: any) => {
        if (event.key === 'Enter') {
            forceUpdateFilter();
        }
    }

    const itemNumberBlurHandler = () => {
        const filterItemNumber = filterParams.itemNumber ? filterParams.itemNumber : '';
        if (filterItemNumber != itemNumber) {
            clearTimeout(filterTimer);
            forceUpdateFilter();
        }
    }

    const tripNumberBlurHandler = () => {
        const filterTripNumber = filterParams.tripNumber ? filterParams.tripNumber : '';
        if (filterTripNumber != tripNumber) {
            clearTimeout(filterTimer);
            forceUpdateFilter();
        }
    }

    const uploadNumberBlurHandler = () => {
        const filterUploadNumber = filterParams.uploadNumber ? filterParams.uploadNumber : '';
        if (filterUploadNumber != uploadNumber) {
            clearTimeout(filterTimer);
            forceUpdateFilter();
        }
    }

    const downloadDateStartSelectHandler = (date: Date) => {
        let hours = 0;
        let minutes = 0;
        if (downloadDateStart) {
            hours = downloadDateStart && (downloadDateStart as Date).getHours();
            minutes = downloadDateStart && (downloadDateStart as Date).getMinutes();
        }

        setDownloadDateStart(new Date(date.setHours(hours, minutes)));
    }

    const downloadDateEndSelectHandler = (date: Date) => {
        let hours = 0;
        let minutes = 0;
        if (downloadDateEnd) {
            hours = downloadDateEnd && (downloadDateEnd as Date).getHours();
            minutes = downloadDateEnd && (downloadDateEnd as Date).getMinutes();
        }

        setDownloadDateEnd(new Date(date.setHours(hours, minutes)));
    }

    const downloadLastDateStartSelectHandler = (date: Date) => {
        let hours = 0;
        let minutes = 0;
        if (downloadLastDateStart) {
            hours = downloadLastDateStart && (downloadLastDateStart as Date).getHours();
            minutes = downloadLastDateStart && (downloadLastDateStart as Date).getMinutes();
        }

        setDownloadLastDateStart(new Date(date.setHours(hours, minutes)));
    }

    const downloadLastDateEndSelectHandler = (date: Date) => {
        let hours = 0;
        let minutes = 0;
        if (downloadLastDateEnd) {
            hours = downloadLastDateEnd && (downloadLastDateEnd as Date).getHours();
            minutes = downloadLastDateEnd && (downloadLastDateEnd as Date).getMinutes();
        }

        setDownloadLastDateEnd(new Date(date.setHours(hours, minutes)));
    }

    const tripDateStartSelectHandler = (value: any) => {
        setTripDateStart(value);
    }

    const tripDateEndSelectHandler = (value: any) => {
        setTripDateEnd(value);
    }

    const dateCreateSelectHandler = (value: any) => {
        setDateCreate(value);
    }

    const itemNumberChangeHandler = () => {
        if (itemNumberRef.current.value.length > 50) {
            return;
        }
        setItemNumber(itemNumberRef.current.value);
    }

    const employeeChooseHandler = (option: EmployeeAutocompleteOptionType) => {
        if (option.value !== employee.value) {
            setEmployee(option);
        }
    }

    const uploadNumberChangeHandler = () => {
        setUploadNumber(uploadNumberRef.current.value);
    }

    const tripNumberChangeHandler = () => {
        setTripNumber(tripNumberRef.current.value);
    }

    const downloadTimeStartChangeHandler = (time: string) => {
        const date = downloadDateStart ? new Date(downloadDateStart) : new Date();
        const [hours, minutes] = parseTime(time);
        setDownloadDateStart(new Date(date.setHours(hours, minutes)));
    }

    const downloadTimeEndChangeHandler = (time: string) => {
        const date = downloadDateEnd ? new Date(downloadDateEnd) : new Date();
        const [hours, minutes] = parseTime(time);
        setDownloadDateEnd(new Date(date.setHours(hours, minutes)));
    }

    const downloadLastTimeStartChangeHandler = (time: string) => {
        const date = downloadLastDateStart ? new Date(downloadLastDateStart) : new Date();
        const [hours, minutes] = parseTime(time);
        setDownloadLastDateStart(new Date(date.setHours(hours, minutes)));
    }

    const downloadLastTimeEndChangeHandler = (time: string) => {
        const date = downloadLastDateEnd ? new Date(downloadLastDateEnd) : new Date();
        const [hours, minutes] = parseTime(time);
        setDownloadLastDateEnd(new Date(date.setHours(hours, minutes)));
    }

    const cityChooseHandler = (option: CityAutocompleteOptionType) => {
        cityAutocompleteRef.current.clear();
        let cityOptions: InputChooseItem[] = [...options];
        let index = cityOptions.findIndex(city => city.value === option.value);
        if (index === -1) {
            cityOptions.push(option);
            setOptions(cityOptions);
        }
    }

    const cityDeleteHandler = (option: InputChooseItem) => {
        let cityOptions: InputChooseItem[] = [...options];
        cityOptions.splice(cityOptions.findIndex(city => city.value === option.value), 1);
        setOptions(cityOptions);
    }


    return (
        <Fragment>
            <div className="filters" style={isFilterOpen ? { display: 'block' } : { display: 'none' }}>
                {fieldPermissions && fieldPermissions.includes(JOURNAL_FIELD_PERMISSIONS.DOWNLOAD_BATCHES) ? <UploadButton/> : null}
                <div className="filters-wrap">
                    <Dropdown
                        label={t(`journal.filters.${props.isAdvance ? 'by_advance_created' : 'by_expense_created'}`)}
                        displayStyle={fieldPermissions && fieldPermissions.includes(JOURNAL_FIELD_PERMISSIONS.CREATE_DATE) ? undefined : 'none'}>
                        <div className="input-item">
                        <div className="input-picker-title">{t(`journal.filters.${props.isAdvance ? 'report_from' : 'application_from'}`)}</div>
                            <div className="input-picker-wrap">
                                <DateInput ref={dateCreateRef} placeholder={t('journal.filters.choose_date')} onSelect={dateCreateSelectHandler} format={'D MMMM'} />
                            </div>
                        </div>
                    </Dropdown>
                    <Dropdown label={t(`filters.${props.isAdvance ? 'by_number_report' : 'by_number_expense'}`)}>
                        <div className="input-item">
                            <input ref={itemNumberRef} className="input" type="text" value={itemNumber}
                                   onKeyPress={onEnterClickHandler} onBlur={itemNumberBlurHandler}
                                   onChange={itemNumberChangeHandler} placeholder={t(`filters.${props.isAdvance ? 'number_report' : 'number_expense'}`)} />
                        </div>
                    </Dropdown>
                    <Dropdown label={t('journal.filters.by_upload_date')}
                              displayStyle={fieldPermissions && fieldPermissions.includes(JOURNAL_FIELD_PERMISSIONS.LOAD_DATE) ? undefined : 'none'}>
                        <div className="input-item">
                            <div className="input-picker-title">{t('journal.filters.from')}</div>
                            <div className="input-picker-wrap">
                                <DateInput value={downloadDateStart} ref={downloadDateStartRef} placeholder={t('journal.filters.choose_date')} onSelect={downloadDateStartSelectHandler} format={'D MMMM YYYY HH:mm'} onChangeTime={downloadTimeStartChangeHandler} isStartDate={true}/>
                            </div>
                        </div>
                        <div className="input-item">
                            <div className="input-picker-title">{t('journal.filters.on')}</div>
                            <div className="input-picker-wrap">
                                <DateInput value={downloadDateEnd} ref={downloadDateEndRef} placeholder={t('journal.filters.choose_date')} onSelect={downloadDateEndSelectHandler} format={'D MMMM YYYY HH:mm'} onChangeTime={downloadTimeEndChangeHandler} isStartDate={false}/>
                            </div>
                        </div>
                    </Dropdown>
                    <Dropdown label={t('journal.filters.by_last_unload_date')}>
                        <div className="input-item">
                            <div className="input-picker-title">{t('journal.filters.from')}</div>
                            <div className="input-picker-wrap">
                                <DateInput value={downloadLastDateStart} ref={downloadLastDateStartRef} placeholder={t('journal.filters.choose_date')} onSelect={downloadLastDateStartSelectHandler} format={'D MMMM YYYY HH:mm'} onChangeTime={downloadLastTimeStartChangeHandler} isStartDate={true}/>
                            </div>
                        </div>
                        <div className="input-item">
                            <div className="input-picker-title">{t('journal.filters.on')}</div>
                            <div className="input-picker-wrap">
                                <DateInput value={downloadLastDateEnd} ref={downloadLastDateEndRef} placeholder={t('journal.filters.choose_date')} onSelect={downloadLastDateEndSelectHandler} format={'D MMMM YYYY HH:mm'} onChangeTime={downloadLastTimeEndChangeHandler} isStartDate={false}/>
                            </div>
                        </div>
                    </Dropdown>
                    <Dropdown label={t('journal.filters.trip_number')}
                              displayStyle={fieldPermissions && fieldPermissions.includes(JOURNAL_FIELD_PERMISSIONS.JOURNEY_NUM) ? undefined : 'none'}>
                        <div className="input-item">
                            <input ref={tripNumberRef}
                                   onKeyPress={onEnterClickHandler} onBlur={tripNumberBlurHandler}
                                   onChange={tripNumberChangeHandler} className="input" type="text" placeholder={t('journal.filters.number')} value={tripNumber} />
                        </div>
                    </Dropdown>
                    <Dropdown label={t('journal.filters.date_trip')}
                              displayStyle={fieldPermissions && fieldPermissions.includes(JOURNAL_FIELD_PERMISSIONS.JOURNEY_DATE) ? undefined : 'none'}>
                        <div className="input-item">
                            <div className="input-picker-title">{t('journal.filters.from')}</div>
                            <div className="input-picker-wrap">
                                <DateInput ref={tripDateStartRef} placeholder={t('journal.filters.choose_date')} onSelect={tripDateStartSelectHandler} format={'D MMMM'} />
                            </div>
                        </div>
                        <div className="input-item">
                            <div className="input-picker-title">{t('journal.filters.on')}</div>
                            <div className="input-picker-wrap">
                                <DateInput ref={tripDateEndRef} placeholder={t('journal.filters.choose_date')} onSelect={tripDateEndSelectHandler} format={'D MMMM'} />
                            </div>
                        </div>
                    </Dropdown>
                    <Dropdown label={t('journal.filters.by_employee')}
                              displayStyle={fieldPermissions && fieldPermissions.includes(JOURNAL_FIELD_PERMISSIONS.EMPLOYEE_ID) ? undefined : 'none'}>
                        <div className="input-item">
                            <div className="input-search">
                                <EmployeeAutocomplete ref={employeeRef} onChoose={employeeChooseHandler} placeholder={t('journal.filters.enter_name')} />
                            </div>
                        </div>
                    </Dropdown>
                    <Dropdown label={t('journal.filters.by_city')}
                              displayStyle={fieldPermissions && fieldPermissions.includes(JOURNAL_FIELD_PERMISSIONS.CITY_ID) ? undefined : 'none'}>
                        <div className="input-item">
                            <CityAutocomplete ref={cityAutocompleteRef} onChoose={cityChooseHandler} placeholder={t('filters.city')} />
                            <InputChoose options={options} onDelete={cityDeleteHandler} />
                        </div>
                    </Dropdown>
                    <Dropdown label={t('journal.filters.by_unload_number')}>
                        <div className="input-item">
                            <input ref={uploadNumberRef}
                                   onKeyPress={onEnterClickHandler} onBlur={uploadNumberBlurHandler}
                                   onChange={uploadNumberChangeHandler} className="input" type="text" placeholder={t('journal.filters.unload_number')} value={uploadNumber} />
                        </div>
                    </Dropdown>
                    <Dropdown>
                        <div className="filter-btns-two m-t-15">
                            <a className="btn btn_gray width-100 pointer" onClick={() => clearAll()}>{t('filters.reset')}</a>
                        </div>
                    </Dropdown>
                </div>
            </div >
        </Fragment >
    )
}

export default JournalFilter;
