/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalContainer from "app/component/modal/ModalContainer";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

interface DeleteExpenseModalProps extends RouteComponentProps<any> {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  id: number | null;
  isWidget?: boolean;
}

const DeleteExpenseModal: React.FC<DeleteExpenseModalProps> = (props) => {
  const { t } = useTranslation();

  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  const closeHandler = () => {
    props.onClose();
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return props.isWidget ? (
    <Modal show={props.isOpen} onHide={closeHandler} centered={true}>
      <Modal.Header closeButton={true} translate="no">
        <Modal.Title>
          {t("modals.delete_expense_modal.want_to_delete_expense")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button
          variant={"outline-dark"}
          onClick={closeHandler}
          style={{ minWidth: "229px" }}
          tabIndex={isTabIndexDisabled ? -1 : 0}
        >
          {t("modals.delete_expense_modal.no")}
        </Button>
        <Button
          variant={"success"}
          onClick={props.onSubmit}
          style={{ minWidth: "229px" }}
          tabIndex={isTabIndexDisabled ? -1 : 0}
        >
          {t("modals.delete_expense_modal.yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <ModalContainer
      isOpen={props.isOpen}
      highestModal={isHighestModal}
      destroy={true}
      overlayClick={closeHandler}
    >
      <div className="box-modal" id="note-delete">
        <div
          className="box-modal_close arcticmodal-close"
          onClick={closeHandler}
        ></div>
        <div className="box-modal-title">
          <div className="tx-center">
            {t("modals.delete_expense_modal.want_to_delete_expense")}
          </div>
        </div>
        <div className="box-modal-footer">
          <a
            className="arcticmodal-close btn btn_black"
            onClick={closeHandler}
            tabIndex={isTabIndexDisabled ? -1 : 0}
          >
            {t("modals.delete_expense_modal.no")}
          </a>
          <a
            className="btn-expense btn_green"
            onClick={props.onSubmit}
            tabIndex={isTabIndexDisabled ? -1 : 0}
          >
            {t("modals.delete_expense_modal.yes")}
          </a>
        </div>
      </div>
    </ModalContainer>
  );
};

export default withRouter(DeleteExpenseModal);
