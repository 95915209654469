export enum EXPENSE_APPLICATION_PROPETIES {
    ADDITIONAL_AMOUNT_EXPENSES = 'AdditionalAmountExpenses',
    ADD_EXPENSE = 'AddExpense',
    ADVANCE_CASH_AMOUNT = 'AdvanceCashAmount',
    ADVANCE_CASH_AMOUNT_UPDATE = 'AdvanceCashAmountUpdate',
    APPROVALS = 'Approvals',
    APPROVAL_BUTTONS = 'ApprovalButtons',
    APPROVED_AMOUNT = 'ApprovedAmount',
    APPLICATION_TYPE = 'ApplicationType',
    ASSIGNEE_EMPLOYEE = 'AssigneeEmployee',
    ASSIGNEE_EMPLOYEE_CP = 'AssigneeEmployeeCP',
    ATTACHMENTS = 'Attachments',
    BUSINESS_TRIP_OPTION = 'BusinessTripOption',
    BUSINESS_TARGET = 'BusinessTarget',
    BUSINESS_TRIP_NUMBER = 'BusinessTripNumber',
    CANCEL_ZNR = 'CancelZNR',
    COMMUNICATIONS = 'Communications',
    COST_CENTER = 'CostCenterId',
    CREATED_BY_EMPLOYEE = 'CreatedByEmployee',
    CREATE_AO_FROM_ZNR = 'CreateAOfromZNR',
    DESCRIPTION = 'Description',
    DEPARTURE_FROM_CITY = 'DepartureFromCity',
    ARRIVAL_TO_CITY = 'ArrivalToCity',
    END_ON = 'EndOn',
    EXPENSE_DELETE = 'Expense_Delete',
    EXPENSE_UPDATE = 'Expense_Update',
    FLOW_HISTORY = 'FlowHistory',
    JOURNEY_NUMBER = 'JourneyNumber',
    NUMBER = 'Number',
    ORDER_NUMBER = 'OrderNumber',
    PAYER = 'Payer',
    PERSONAL_DAYS_NUMBER = 'PersonalDaysNumber',
    PERSONAL_DAYS = 'PersonalDays',
    PERSONAL_DAYS_UPDATE = 'PersonalDaysUpdate',
    PRINTING_FORMS = 'PrintingForms',
    START_ON = 'StartOn',
    STATUS = 'Status',
    TRANSACTIONS = 'Transactions',
    TOTAL_AMOUNT = 'TotalAmount',
    UPDATE_ZNR = 'UpdateZNR',
    VBS_ELEMENT = 'VbsElement',
}

export enum ADVANCE_APPLICATION_PROPETIES {
    ACT_OF_ENTERTAINMENT_EXPENSES = 'ActOfEntertaimentExpenses',
    ACT_OF_ENTERTAINMENT_EXPENSES_UPDATE = 'ActOfEntertaimentExpensesUpdate',
    ADVANCE_CASH_AMOUNT = 'AdvanceCashAmount',
    ADD_EXPENSE = 'AddExpense',
    APPROVAL_BUTTONS = 'ApprovalButtons',
    APPROVALS = 'Approvals',
    APPLICATION_TYPE = 'ApplicationType',
    APPROVED_AMOUNT = 'ApprovedAmount',
    ARRIVAL_TO_CITY = 'ArrivalToCity',
    ASSIGNEE_EMPLOYEE = 'AssigneeEmployee',
    ASSIGNEE_EMPLOYEE_CP = 'AssigneeEmployeeCP',
    ATTACHMENTS = 'Attachments',
    BUSINESS_TRIP_OPTION = 'BusinessTripOption',
    BUSINESS_TRIP_NUMBER = 'BusinessTripNumber',
    BUSINESS_TARGET = 'BusinessTarget',
    CANCEL_AO = 'CancelAO',
    COST_CENTER = 'CostCenterId',
    COMMUNICATIONS = 'Communications',
    CREATED_BY_EMPLOYEE = 'CreatedByEmployee',
    DESCRIPTION = 'Description',
    DEPARTURE_FROM_CITY = 'DepartureFromCity',
    END_ON = 'EndOn',
    EXPENSE_APPLICATION_NUMBER = 'ExpenseApplicationNumber',
    EXPENSES_ACCOUNTING = 'ExpensesAccounting',
    EXPENSE_DELETE = 'Expense_Delete',
    EXPENSE_UPDATE = 'Expense_Update',
    FLOW_HISTORY = 'FlowHistory',
    JOURNEY_NUMBER = 'JourneyNumber',
    NUMBER = 'Number',
    ORDER_NUMBER = 'OrderNumber',
    PAYER = 'Payer',
    PERSONAL_DAYS = 'PersonalDays',
    PERSONAL_DAYS_UPDATE = 'PersonalDaysUpdate',
    PERSONAL_DAYS_NUMBER = 'PersonalDaysNumber',
    PRINTING_FORMS = 'PrintingForms',
    REPORT_EXPENSES = 'ReportExpenses',
    START_ON = 'StartOn',
    STATUS = 'Status',
    TOTAL_AMOUNT = 'TotalAmount',
    TRANSACTIONS = 'Transactions',
    UPDATE_AO = 'UpdateAO',
    VBS_ELEMENT = 'VbsElement',
}

export enum EXPENSE_PROPETIES {
    TYPE = "Expense_Type",
    IS_EXTERNAL = "Expense_IsExternal",
    IS_ADVANCE_CASH_AMOUNT =  "IsAdvanceCashRequired",
    AMOUNT = "Expense_Amount",
    AMOUNT_CURRENCY_ID =  "Expense_AmountCurrencyId",
    AMOUNT_RUB =  "Expense_AmountRub",
    AMOUNT_CURRENCY_RATE =  "Expense_AmountCurrencyRate",
    BILL_AMOUNT =  "BillAmount",
    BILL_AMOUNT_CURRENCY_ID =  "BillAmountCurrencyId",
    BILL_AMOINT_CURRENCY_RATE =  "BillAmountCurrencyRate",
    BILL_AMOUNT_RUB =  "Expense_BillAmountRub",
    BILL_NUMBER =  "Expense_BillNumber",
    BILL_DATE =  "Expense_BillDate",
    ACCOUNTABLE_FORM_NUMBER = 'Expense_AccountableFormNumber',
    CALCULATIONS_DETAILS = 'Expense_CalculationDetails',
    APPROVED_AMOUNT_RUB =  "Expense_ApprovedAmountRub",
    APPROVED_AMOUNT =  "Expense_ApprovedAmount",
    APPROVED_AMOUNT_CURRENCY_ID =  "Expense_ApprovedAmountCurrencyId",
    APPROVED_AMOUNT_CURRENCY_RATE =  "Expense_ApprovedAmountCurrencyRate",
    APPROVED_AMOUNT_COMMENT =  "Expense_ApprovedAmountComment",
    ADVANCE_CASH_REQUIRED =  "AdvanceCashRequired",
    PAYMENT_FORM = "Expense_PaymentForm",
    STATUS =  "Expense_Status",
    DESCRIPTION =  "Expense_Description",
    ATTACHMENTS = 'Expense_Attachments'
}

export enum TRANSACTION_PROPETIES {
    STATE = 'State',
    TRANSACTION_BATCH_ID = 'TransBatch',
    LOADED_AT = 'LoadedAt',
    DOC_DATE = 'DocDate',
    DOC_NUMBER = 'DocNumber',
    POSTING_DATE = 'PostingDate',
    BUSINESS_TARGET_ID = 'BusinessTarget',
    TX_TEXT = 'Text',
    ORDER_NUMBER = 'OrderNumber',
    EXPENSE_ITEM_ID = 'ExpenseItem',
    AMOUNT = 'Amount',
    CURRENCY_ID = 'Currency',
    CUR_RATE = 'CurRate',
    AMOUNT_RUB = 'AmountRub',
    LEDGER_OR_VENDER = 'LedgerOrVendor',
    POSTING_KEY = 'PostingKey',
    TAX_CODE_ID = 'TaxCode',
    REFERENCE_FIELD = 'ReferenceField',
    EXPENSE_ID = 'Expense',
    EMPLOYEE_ID = 'Employee',
    COMPANY_ID = 'Company',
    OPERATION_TYPE = 'OperationType',
    DC = 'DC',
}