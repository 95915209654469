import { BaseMapper } from "./base.mapper";
import { PaymentForm, PaymentFormResponse } from 'infrastructure/interfaces';
import { get } from 'lodash';

export class PaymentFormMapper extends BaseMapper<PaymentForm, PaymentFormResponse> {
  responseToEntity(response: PaymentFormResponse): PaymentForm {
    return {
      id: get(response, 'id', 0),
      name: {
        ru: get(response, 'name.ru', 'Имя[STUB]'),
        en: get(response, 'name.en', 'Name[STUB]')
      }
    }
  };
}