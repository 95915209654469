import { BaseMapper } from './base.mapper';
import { Employee } from 'infrastructure/interfaces';
import { get } from 'lodash';


export class EmployeeMapper extends BaseMapper<Employee, any> {
  responseToEntity(response: any): Employee {
    return {
      id: get(response, 'id', 0),
      name:{
        ru: get(response, 'name.ru', 'Андрей[STUB] Геннадьевич[STUB] Литвин[STUB]'),
        en: get(response, 'name.en', 'Andrey[STUB] Gennadevich[STUB] Litvin[STUB]')
      }
    };
  };
}
