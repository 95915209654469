/**
 * @typedef {Object} IMAGE_TYPE
 * @property {string} EDIT - редактировать
 * @property {string} DELETE - удалить
 * @property {string} PRINT - распечатать
 */

export enum IMAGE_TYPE {
    EDIT = 'edit',
    DELETE = 'delete',
    PRINT = 'print'
}