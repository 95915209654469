import React from 'react';
//component
import ButtonsControl from 'app/component/buttons-control/ButtonsControl';

interface ButtonsControlProps {
    id: number,
    isPostingsMode: boolean,
    setPostingsMode: () => void
}

const ZNRButtonsControl:React.FC<ButtonsControlProps> = (props) => {
    return (
        <ButtonsControl id={props.id} isPostingsMode={props.isPostingsMode} setPostingsMode={props.setPostingsMode} isAdvance={false}/>
    )
};

export default ZNRButtonsControl;
