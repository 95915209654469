/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  CSSProperties,
  useRef,
} from "react";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import Slidebar from "../slidebar/Slidebar";
import TextCutter from "../text-cutter/TextCutter";
import { Form } from "react-bootstrap";

export interface SelectProps {
  options: SelectOptionType[];
  onChoose?: (option: SelectOptionType) => void;
  defaultText?: string;
  defaultValue?: SelectOptionType | null;
  readOnly?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  isWidget?: boolean;
}

export interface SelectRef {
  clear(): void;
}

export interface SelectOptionType {
  value: string | number;
  text: string;
}

const Select: React.RefForwardingComponent<SelectRef, SelectProps> = (
  props,
  ref
) => {
  let [isOpen, setOpen] = useState(false);
  let [labelText, setLabelText] = useState(
    props.defaultValue && props.defaultValue.text
      ? props.defaultValue.text
      : props.defaultText
      ? props.defaultText
      : "---"
  );
  let [width, setWidth] = useState(0);

  let nameRef = useRef({} as any);

  useEffect(() => {
    window.addEventListener("resize", resizeEventHander);
    return () => {
      window.removeEventListener("resize", resizeEventHander);
    };
  });

  useEffect(() => {
    resizeEventHander();
  }, []);

  const resizeEventHander = () => {
    setWidth(nameRef.current.offsetWidth);
  };

  const titleClickHandler = () => {
    if (props.readOnly) {
      return;
    } else {
      setOpen(!isOpen);
    }
  };

  const optionClickHandler = (option: SelectOptionType) => {
    setLabelText(option.text);
    setOpen(false);
    if (props.onChoose) props.onChoose(option);
  };

  const missClickHandler = () => {
    if (isOpen) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (props.defaultValue && props.defaultValue.text) {
      setLabelText(props.defaultValue.text);
    } else if (props.defaultText) {
      setLabelText(props.defaultText);
    }
  }, [props.defaultValue]);

  const bootstrapOptionClickHandler = (value: string) => {
    const chosenOption = props.options.find(
      (option) => option.value === +value
    );
    if (props.onChoose && chosenOption) {
      props.onChoose(chosenOption);
    }
  };

  useImperativeHandle(ref, () => ({
    clear: () => {
      setOpen(false);
      setLabelText(
        props.defaultValue && props.defaultValue.text
          ? props.defaultValue.text
          : props.defaultText
          ? props.defaultText
          : "---"
      );
    },
  }));

  return props.isWidget ? (
    <Form.Control
      as="select"
      disabled={props.disabled}
      defaultValue={labelText}
      style={{ backgroundImage: "none" }}
      onChange={(e) => bootstrapOptionClickHandler(e.target.value)}
    >
      <option value={labelText} style={{ display: "none" }}>
        {labelText}
      </option>
      {props.options.map((option, index) => {
        return (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        );
      })}
    </Form.Control>
  ) : (
    <div
      className={`select ${isOpen ? "active" : ""} ${
        props.disabled ? "disabled" : ""
      }`}
      style={props.style ? props.style : undefined}
    >
      {isOpen ? (
        <div className="miss-click-handler" onClick={missClickHandler} />
      ) : (
        ""
      )}
      <div
        ref={nameRef}
        className={`select-title`}
        style={{
          lineHeight: (props.style && props.style.lineHeight) || undefined,
        }}
        onClick={titleClickHandler}
      >
        <TextCutter
          text={labelText}
          parentWidth={width}
          options={[
            {
              maxLength: 72, //old value 67
              maxWidth: 945,
              minWidth: 238,
              symbolSize: 12,
            },
          ]}
        />
        <SvgIcon className="icon icon-arrow-top" href="#svg_icon_arrow_top" />
      </div>
      <Slidebar
        className="select-content"
        style={{ zIndex: 20, maxHeight: "372px" }}
      >
        {props.options.map((option, index) => {
          return (
            <div
              className="select-content-item"
              key={index}
              onClick={() => optionClickHandler(option)}
            >
              <TextCutter
                maxLength={60}
                text={option.text}
                parentWidth={width}
                options={[
                  {
                    maxLength: 67,
                    maxWidth: 645,
                    minWidth: 238,
                    symbolSize: 12,
                  },
                ]}
              />
            </div>
          );
        })}
      </Slidebar>
    </div>
  );
};

export default forwardRef(Select);
