import React from 'react';
//component
import ListFilter from 'app/component/list-filter/ListFilter';

interface ZNRFilter {
}

const ZNRFilter: React.FC<ZNRFilter> = props => {
  return <ListFilter/>
}

export default ZNRFilter;