import React, { Fragment } from 'react';
import RegistryForm from 'app/component/reports'

const ReportsRegistries: React.FC = () => {
  return (
  <Fragment>
    <div className="filters" style={{ display: 'block' }}>
      <RegistryForm />
    </div>
  </Fragment>)
}

export default ReportsRegistries;