import { Reducer } from 'redux';
import { Exception, EXCEPTION, ExceptionActions } from './actionTypes';

export interface ExceptionState {
  isOpenExceptionModal: boolean,
  isOpenErrorModal: boolean,
  exceptionArray: Exception[],
  errorArray: any[]
  infoArray: any[]
}

export const initialState: ExceptionState = {
  isOpenExceptionModal: false,
  isOpenErrorModal: false,
  exceptionArray: [],
  errorArray: [],
  infoArray: []
};

let exceptionCounter: number = 0;
let infoCounter: number = 0;

export const reducer: Reducer<ExceptionState> = (state: ExceptionState = initialState, action) => {
  switch ((action as ExceptionActions).type) {
    case EXCEPTION.ADD_EXCEPTION:
      return {
        ...state,
        exceptionArray: state.exceptionArray.concat({ ...action.payload.exception, id: state.exceptionArray.length }),
      };
    case EXCEPTION.ADD_ERROR:
      return {
        ...state,
        errorArray: state.errorArray.concat({ ...action.payload.exception, id: exceptionCounter++, isOpenErrorModal: true })
      };
    case EXCEPTION.ADD_INFO:
      return {
        ...state,
        infoArray: state.infoArray.concat({ ...action.payload.info, id: infoCounter++, isOpenInfoModal: true })
      };
    case EXCEPTION.OPEN_EXCEPTION_MODAL:
      return { ...state, isOpenExceptionModal: true };
    case EXCEPTION.CLOSE_EXCEPTION_MODAL:
      return { ...state, isOpenExceptionModal: false };
    case EXCEPTION.CLOSE_ERROR_MODAL: {
      let arr: any[] = [];
      let isAllClosed: boolean = true;
      state.errorArray.forEach((item, index) => {
        if (item.id !== action.payload.errorId) {
          arr.push(item)
        } else {
          item.isOpenErrorModal = false;
          arr.push(item)
        }
        if (item.isOpenErrorModal) {
          isAllClosed = false;
        }
      });
      if (isAllClosed) {
        arr = []
      }
      return { ...state, errorArray: arr }
    }
    case EXCEPTION.CLOSE_INFO_MODAL: {
      let arr: any[] = [];
      let isAllClosed: boolean = true;
      state.infoArray.forEach((item, index) => {
        if (item.id !== action.payload.infoId) {
          arr.push(item)
        } else {
          item.isOpenInfoModal = false;
          arr.push(item)
        }
        if (item.isOpenInfoModal) {
          isAllClosed = false;
        }
      });
      if (isAllClosed) {
        arr = []
      }
      return { ...state, infoArray: arr }
    }
    default:
      return state;
  }
};

export default reducer;