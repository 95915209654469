import SvgIcon from 'app/component/svg-icon/SvgIcon';
import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import CreateSubstitution from 'app/component/modal/CreateSubstitution';
import Config, { ConfigTypes } from 'services/ConfigService';
import Grid from 'app/component/grid/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { loaderLock, loaderUnlock } from 'store/common/actions';
import { updateSubstitutionList } from 'store/substitution/actions';
import { nameFormatter, NameFormats, formatter } from 'app/utils';
import SubmitCustomModal from 'app/component/modal/SubmitCustomModal';
import { showErrors } from 'store/exception/actions';
import If from 'app/component/util/If';
import Else from 'app/component/util/Else';
import {getSubstitutionItemsCount, getSubstitutionList, getCommonUserDetail} from "../../../store/selectors";
import {deleteEmployeeDeputiesById} from "../../../services/ApiService";

const Substitution: React.FC = () => {
  const { t, i18n } = useTranslation();

  let [isShowCreate, setShowCreate] = useState(false);
  let [isShowDel, setShowDel] = useState(false);
  let [selectedId, setSelectedId] = useState(-1);
  let [gridData, setGritData] = useState([] as any[]);
  let [isLoading, setLoading] = useState(false);
  let [scrollYCoordinate, setScrollYCoordinate] = useState(0);

  const dispatch = useDispatch();

  let substitutionList = useSelector(getSubstitutionList);
  let itemsCount = useSelector(getSubstitutionItemsCount);
  let userDetail = useSelector(getCommonUserDetail);

  useEffect(() => {
    dispatch(updateSubstitutionList());
  }, [])

  useEffect(() => {
    let netData = substitutionList.map(item => ({
      first: nameFormatter(item.employee.name, NameFormats.LASTNAME_WITH_NAME, i18n.language),
      second: nameFormatter(item.deputyEmployee.name, NameFormats.LASTNAME_WITH_NAME, i18n.language),
      dates: `${formatter('DD.MM.YYYY', i18n.language, item.startOn)} — ${formatter('DD.MM.YYYY', i18n.language, item.endOn)}`,
      delete: Config.getConfigToCompany(ConfigTypes.EMPLOYEE_DEPUTIES_SOURCE, item.company.id) == 1 ? (<Fragment>
        <a onClick={() => handleEdit(item.id)}>
          <SvgIcon className="icon icon-edit pointer" href="#svg_icon_edit" />
        </a>
        <a onClick={() => handleDelete(item.id)}>
          <SvgIcon className="icon icon-remove pointer" href="#svg_icon_remove" />
        </a>
      </Fragment>) : null
    }))
    setGritData(netData);
  }, [substitutionList])

  const lifeScrolling = () => {
    let actualPage = 1;
    if (window.pageYOffset + window.innerHeight >= document.body.clientHeight / 1.2 && itemsCount > substitutionList.length && !isLoading) {
      setLoading(true);
      setScrollYCoordinate(window.pageYOffset);
      actualPage++
      dispatch(updateSubstitutionList(actualPage, true));
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', lifeScrolling);
    return () => {
      window.removeEventListener('scroll', lifeScrolling);
    }
  });

  useEffect(() => {
    setLoading(false);
  }, [substitutionList])

  const openCreateModal = () => {
    let isEmployeeDeputiesSourcePermission = userDetail.occupations
    .map(item => Config.getConfigToCompany(ConfigTypes.EMPLOYEE_DEPUTIES_SOURCE, item.company.id))
    .map(item => item == 1)
    .includes(true);

    if (!isEmployeeDeputiesSourcePermission) {
      dispatch(showErrors({ code: 'create_substituation', message: t('substitution.error.forbidden_create')}));
      return;
    }
    setSelectedId(-1);
    setShowCreate(true);
  }
  
  const closeCreateModal = () => {
    let isEmployeeDeputiesSourcePermission = userDetail.occupations
    .map(item => Config.getConfigToCompany(ConfigTypes.EMPLOYEE_DEPUTIES_SOURCE, item.company.id))
    .map(item => item == 1)
    .includes(true);

    if (!isEmployeeDeputiesSourcePermission) {
      dispatch(showErrors({ code: 'create_substituation', message: t('substitution.error.forbidden_create')}));
      return;
    }
    setSelectedId(-1);
    setShowCreate(false);
  }

  const toggleDelModal = () => {
    setShowDel(!isShowDel);
  }

  const handleDelete = (id: number) => {
    let isEmployeeDeputiesSourcePermission = userDetail.occupations
    .map(item => Config.getConfigToCompany(ConfigTypes.EMPLOYEE_DEPUTIES_SOURCE, item.company.id))
    .map(item => item == 1)
    .includes(true);

    if (!isEmployeeDeputiesSourcePermission) {
      dispatch(showErrors({ code: 'create_substituation', message: t('substitution.error.forbidden_delete')}));
      return;
    }
    setSelectedId(id);
    toggleDelModal();
  }

  const handleEdit = (id: number) => {
    let isEmployeeDeputiesSourcePermission = userDetail.occupations
    .map(item => Config.getConfigToCompany(ConfigTypes.EMPLOYEE_DEPUTIES_SOURCE, item.company.id))
    .map(item => item == 1)
    .includes(true);

    if (!isEmployeeDeputiesSourcePermission) {
      dispatch(showErrors({ code: 'create_substituation', message: t('substitution.error.forbidden_edit')}));
      return;
    }
    setSelectedId(id);
    setShowCreate(true);
  }

  const onDelSubmit = async () => {
    dispatch(loaderLock());
    let response = await deleteEmployeeDeputiesById(selectedId);
    if (response.headers.success) {
      dispatch(updateSubstitutionList());
      toggleDelModal();
    } else {
      dispatch(showErrors({ code: 'del_substituation', message: 'Не удалось удалить замещение' }))
    }
    dispatch(loaderUnlock());
  }

  return (
    <Fragment>
      <div className="profile-calculation">
        <div className="profile-calculation-main">
          <div className="profile-calc-table-wrap">
            <If condition={gridData.length == 0}>
            <div className="request-list empty">
              <div className="request-list-empty">
                  <img src="/img/empty-img.png" />
                  <div style={{width: 'inherit'}}>{t('substitution.empty_list', {name:nameFormatter(userDetail.name, NameFormats.NAME, i18n.language)})}</div>
              </div>
            </div>
            </If>
            <Else condition={gridData.length == 0}>
              <Grid options={{
                tableSub: true, headers: [
                  {
                    key: 'first',
                    headerText: t('sub_list.person_charge'),
                  },
                  {
                    key: 'second',
                    headerText: t('sub_list.deputy')
                  },
                  {
                    key: 'dates',
                    headerText: t('sub_list.dates')
                  },
                  {
                    key: 'delete',
                    headerText: ''
                  }
                ], isLastRowNarrow: true
              }} data={gridData} />
            </Else>
          </div>
        </div>
        <div className="profile-calculation-filter profile-calculation-filter-sub">
          <a className="btn-expense btn_green" onClick={openCreateModal}>{t('sub_list.add')}</a>
        </div>
      </div>
      <SubmitCustomModal isOpen={isShowDel} onClose={toggleDelModal} onSubmit={onDelSubmit} title={t('sub_list.delete')} />
      <CreateSubstitution editId={selectedId != -1 ? selectedId : undefined} isOpen={isShowCreate} onClose={closeCreateModal} />
    </Fragment>
  )
};

export default Substitution;