import React from 'react'
import ZNRChat from 'app/pages/ZNR/ZNRChat'
import { Switch, Route } from 'react-router';
import AOChat from 'app/pages/report/AOChat';

const ChatBox: React.FC = props => {
    return (
        <Switch>
            <Route path='/ExpenseApplication/Detail/:id' component={ZNRChat} />
            <Route path='/AdvanceReportApplication/Detail/:id' component={AOChat} />
        </Switch>
    )
};

export default ChatBox;