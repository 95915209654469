import React, {ReactNode, Fragment, useState, useEffect} from 'react';
import './ListItem.css';
import {
    IAOItem,
    ApplicationForExpenseItem,
    Expense,
    AOExpense,
    ApproveListItem,
    ICompany
} from 'infrastructure/interfaces';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IMAGE_TYPE} from 'infrastructure/enum/image-type.enum';
import Config, {ConfigTypes} from 'services/ConfigService';
import {STATUS_TYPE_REPORT, statusReportNameMap} from 'infrastructure/enum/status-report.enum';
import {showErrors} from 'store/exception/actions';
import {formatter, nameFormatter, NameFormats, toLocaleNumberFormatter, getCurrencieCode, getNextKey} from 'app/utils';
import {EXPENSE_TYPE, expenseStylesMap, expenseHrefMap} from 'infrastructure/enum/expense-type.enum';
import SvgIcon from '../svg-icon/SvgIcon';
import {Link, NavLink} from 'react-router-dom';
import {STATUS_TYPE, statusStylesMap, statusNameMap} from 'infrastructure/enum/status-type.enum';
import If from '../util/If';
import Else from '../util/If';
import {AGREEMENT_ITEM_LEVEL_TYPE} from 'infrastructure/enum/agreement-item-level-type.enum';
import {loaderLock, loaderUnlock} from 'store/common/actions';
import {RouteComponentProps, withRouter} from 'react-router';
import {isIE} from 'react-device-detect';
import {getCommonCompaniesCatalog} from "../../../store/selectors";
import {postPaperWorks} from "../../../services/ApiService";

interface ListItemProps extends RouteComponentProps<any> {
    item: IAOItem | ApplicationForExpenseItem | ApproveListItem,
    openDeleteModal: () => void,
    setSelectedId: (id: number) => void,
    isAdvance?: boolean,
    isAgreement?: boolean
}

const ListItem: React.FC<ListItemProps> = (props) => {

    let [agreementLevel, setAgreementLevel] = useState('' as string | {});
    let [agreementList, setAgreementList] = useState({name: "", description: ""});

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const advanceReportAmountCalculationPermission: number = Config.getConfigToCompany(ConfigTypes.ADVANCE_REPORT_AMOUNT_CALCULATION, props.item.companyId);
    const companies: ICompany[] = useSelector(getCommonCompaniesCatalog);

    useEffect(() => {
        getAgreementLevel();
    }, [props.item])

    const clickHandler = (e: any) => {
        const type: any = (e.target as HTMLElement).getAttribute('data-type');
        if (type !== IMAGE_TYPE.DELETE) {
            return;
        }
        e.preventDefault();
        props.setSelectedId(props.item.id);
        props.openDeleteModal();
    }

    const isExternal = () => {
        if (props.isAdvance) {
            return false;
        } else {
            const expenses = [...props.item.expenses];
            const hasExternal = expenses.filter((expense: Expense | AOExpense) => expense.external);
            return hasExternal.length > 0;
        }
    }

    const canDeleteFirst = () => {
        if (props.isAdvance) {
            return props.item.status === STATUS_TYPE_REPORT.APPROVED || props.item.status === STATUS_TYPE_REPORT.ON_APPROVAL;
        } else {
            return props.item.status === STATUS_TYPE.APPROVED || props.item.status === STATUS_TYPE.ON_APPROVAL;
        }
    }

    const canDeleteSecond = () => {
        if (props.isAdvance) {
            return props.item.status === STATUS_TYPE_REPORT.POSTED;
        } else {
            return props.item.status === STATUS_TYPE.POSTED || props.item.status === STATUS_TYPE.REPORT_PREPARED || props.item.status === STATUS_TYPE.EXECUTED;
        }
    }

    const generalName = {
        en: 'GE',
        ru: 'ОР'
    }

    const getName = (): string => {
        return props.item.applicationType.code === 'General'
            ? `${generalName[i18n.language as 'en' | 'ru']}: ${props.item.businessTargetName[i18n.language as 'en' | 'ru']} `
            : `${props.item.departureFromCity.name[i18n.language as 'en' | 'ru']} — ${props.item.arrivalToCity.name[i18n.language as 'en' | 'ru']}`;
    }

    const getAgreementLevel = () => {
        const {approvalProgress, status} = props.item;
        if (approvalProgress) {
            if (approvalProgress.level == AGREEMENT_ITEM_LEVEL_TYPE.ACCOUNTANT && status == STATUS_TYPE.ON_APPROVAL) {
                setAgreementLevel(`${t(`agreement.list.title.accounting`)} ${approvalProgress.assignedNames.length == 1 ? `(${nameFormatter(approvalProgress.assignedNames[0], NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language)})` : ''}`);
                return;
            }
            if (approvalProgress.level == AGREEMENT_ITEM_LEVEL_TYPE.LINE_MANAGER && status == STATUS_TYPE.ON_APPROVAL) {
                setAgreementLevel(`${t(`agreement.list.title.external`)} ${approvalProgress.assignedNames.length == 1 ? `(${nameFormatter(approvalProgress.assignedNames[0], NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language)})` : ''}`);
                return;
            }
            if (approvalProgress.level == AGREEMENT_ITEM_LEVEL_TYPE.FINANCIAL_DIRECTOR && status == STATUS_TYPE.ON_APPROVAL) {
                setAgreementLevel(`${t(`agreement.list.title.financial`)} ${approvalProgress.assignedNames.length == 1 ? `(${nameFormatter(approvalProgress.assignedNames[0], NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language)})` : ''}`);
                return;
            }
            if (approvalProgress.assignedNames.length === 1) {
                // @ts-ignore
                setAgreementList({
                    name: nameFormatter(approvalProgress.assignedNames[0], NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language),
                    description: approvalProgress.description ? approvalProgress.description[i18n.language as 'en' | 'ru'] : ""
                });
            }
            if (approvalProgress.assignedNames.length > 1) {
                // @ts-ignore
                setAgreementList({
                    name: "",
                    description: approvalProgress.description ? approvalProgress.description[i18n.language as 'en' | 'ru'] : null
                });
            }
        }
    }

    const getDate = (): string => {
        return props.item.applicationType.code === 'General'
            ? formatter('D MMMM YYYY', i18n.language as 'en' | 'ru', props.item.createdAt)
            : formatter('D MMMM', i18n.language, props.item.startOn, props.item.endOn, {
                withMonth: true,
                withYear: true
            });
    }

    const getExpenseIcons = () => {
        let otherCount = 0;
        let notOtherCount = 0;
        let result: ReactNode[] = [];
        let iconStyle = {marginRight: 'auto', marginLeft: 'auto'};
        if (props.item.expenses.length) {
            props.item.expenses.forEach((expense: Expense | AOExpense, index: number) => {
                if (expense.expenseType.groupCode === EXPENSE_TYPE.BOX || expense.expenseType.groupCode === EXPENSE_TYPE.PERDIEM || expense.expenseType.groupCode === EXPENSE_TYPE.VISA) {
                    otherCount++;
                } else if (notOtherCount < 2) {
                    notOtherCount++;
                    result.push(<SvgIcon key={index}
                                         className={expenseStylesMap[expense.expenseType.groupCode as EXPENSE_TYPE]}
                                         href={expenseHrefMap[expense.expenseType.groupCode as EXPENSE_TYPE]}
                                         style={iconStyle}/>)
                } else {
                    otherCount++;
                }
            })
        }
        if (otherCount > 1) {
            result.push(<SvgIcon key={0} className={expenseStylesMap[EXPENSE_TYPE.BOX]} counter={otherCount}
                                 href={expenseHrefMap[EXPENSE_TYPE.BOX]} style={iconStyle}/>)
        } else if (otherCount > 0) {
            result.push(<SvgIcon key={0} className={expenseStylesMap[EXPENSE_TYPE.BOX]}
                                 href={expenseHrefMap[EXPENSE_TYPE.BOX]} style={iconStyle}/>)
        }

        const iconGenerate = (result: React.ReactNode[]) => {
                return result.map(item => <div key={getNextKey('ListItemIcon')} style={{width: '70px'}}>{[item]}</div>)
        }

        return (
            <>
            {iconGenerate(result)}
            </>
        )
    }

    const viewDeleteButton = () => {
        const deleteButton = (
            <div className="delete">
                <If condition={isIE}>
                    <SvgIcon className="icon icon-remove" href="#svg_icon_remove" type={'delete'}
                             style={{display: 'table-caption'}}/>
                </If>
                <If condition={!isIE}>
                    <SvgIcon className="icon icon-remove" href="#svg_icon_remove" type={'delete'}/>
                </If>
            </div>
        );
        const emptyBlock = (<div style={{marginRight: '22px'}}/>)
        if (props.isAdvance) {
            return props.item.status !== STATUS_TYPE_REPORT.CANCELLED ? deleteButton : emptyBlock
        } else {
            return props.item.status !== STATUS_TYPE.CANCELLED ? deleteButton : emptyBlock
        }
    };

    const takeInHand = async (e: any) => {
        e.preventDefault();
        const requestParams = {
            objectType: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication',
            objectId: props.item.id,
        }
        dispatch(loaderLock());
        let response = await postPaperWorks(requestParams);
        if (response.headers.success) {
            props.history.push(`/${props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication'}/Detail/${props.item.id}`);
        } else {
            dispatch(showErrors({code: 'takeInHand_action', message: 'Не удалось взять в работу'}))
        }
        dispatch(loaderUnlock());
    }

    const canShowViewInHand = () => {
        const {isGroup} = props.item;
        return isGroup ? <div className={`btn_black small-btn pointer`}
                              onClick={takeInHand}>{t('request_detail.request_button_controls.take_in_hand')}</div> : null
    }

    const inHandStatus = () => {
        return props.item.paperWork.id !== 0 ? `${t('request_detail.in_hand')}: ${nameFormatter(props.item.paperWork.employeeName, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language)}`
            : canShowViewInHand()
    }

    const getCompanyById = (id: number): string => {
        const company = companies.find((item: ICompany) => id === item.id);
        if (company) {
            return company.name[i18n.language as 'ru' | 'en'];
        } else {
            return '';
        }
    }

    const preventDefault = (e: any) => {
        e.preventDefault();
    }

    return (
        <Link to={`/${props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication'}/Detail/${props.item.id}`}
              onClick={clickHandler} tabIndex={-1}>
            <div className={`request-list-item ${props.isAgreement && props.item.readAt ? 'viewed-item' : ''}`}>
                {props.isAdvance && props.item.expenseApplicationNumber ?
                    <div onClick={preventDefault} className="request-list-item-notice gray-line-byExpense">
                        <p>{t('request_detail.application_expenses.by_application')} <NavLink tabIndex={-1}
                                                                                              to={`/ExpenseApplication/Detail/${props.item.expenseApplication.id}`}>{`№${props.item.expenseApplication.id}`}</NavLink>
                        </p><span>По заявке</span></div> : null}
                <div className="request-list-item-date">{getDate()}</div>
                <div className="request-list-item-left" style={{maxWidth: 'none'}}>
                    <div className="request-list-item-num company-name">
                        {props.isAgreement ? `${t(`agreement.${props.item.type === 'AO' ? 'advance_report' : 'expense_application'}`)} №${props.item.id} ${getCompanyById(props.item.companyId)}` : ` №${props.item.id} ${getCompanyById(props.item.companyId)}`}
                    </div>
                    <div className="request-list-item-city">{getName()}</div>
                    <div
                        className="request-list-item-name">{nameFormatter(props.item.assigneeEmployee.name, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language)}</div>
                </div>
                <div className="request-list-item-center" style={{maxWidth: '210px'}}>
                    {getExpenseIcons()}
                </div>
                <div className="request-list-item-right">
                    <div className="request-list-item-wrap">
                        <If condition={!props.isAdvance || props.isAgreement || !advanceReportAmountCalculationPermission}>
                            <div className="request-list-item-cost">{props.isAgreement && props.item.readAt ?
                                <SvgIcon className="icon icon-eye"
                                         href="#svg_icon_eye"/> : null}{toLocaleNumberFormatter(props.item.totalAmount)} {getCurrencieCode('rub')}</div>
                        </If>
                        <If condition={props.isAdvance && !props.isAgreement && !!advanceReportAmountCalculationPermission}>
                            <If condition={isIE}>
                                <div className="request-list-item-cost advance" style={{
                                    position: 'absolute',
                                    top: '25px',
                                    right: '30px'
                                }}>{t('request_detail.request_cost.approvedTotalAmount')} — {toLocaleNumberFormatter(props.item.approvedTotalAmount)} {getCurrencieCode('rub')}</div>
                                <div className="request-list-item-cost amount" style={{
                                    position: 'absolute',
                                    top: '45px',
                                    right: '30px'
                                }}>{t('request_detail.request_cost.approvedReportAmount')} — {toLocaleNumberFormatter(props.item.approvedReportAmount)} {getCurrencieCode('rub')}</div>
                            </If>
                            <If condition={!isIE}>
                                <div
                                    className="request-list-item-cost advance">{t('request_detail.request_cost.approvedTotalAmount')} — {toLocaleNumberFormatter(props.item.approvedTotalAmount)} {getCurrencieCode('rub')}</div>
                                <div
                                    className="request-list-item-cost amount">{t('request_detail.request_cost.approvedReportAmount')} — {toLocaleNumberFormatter(props.item.approvedReportAmount)} {getCurrencieCode('rub')}</div>
                            </If>
                        </If>
                        <If condition={!props.isAdvance}>
                            <If condition={props.item.status == STATUS_TYPE.ON_APPROVAL}>
                                <div style={{display: 'flex', alignItems: 'center', marginRight: '14px'}}>
                                    <div style={{textAlign: "right"}}>
                                        <p style={{textAlign: 'right'}}>{agreementList.description}</p>
                                        <p>
                                            {agreementList.name ? "(" + agreementList.name + ")" : ""}
                                        </p>
                                    </div>
                                    <div>
                                        {props.isAgreement ? inHandStatus() :
                                            <span
                                                className={`request-list-item-status ${statusStylesMap[props.item.status as STATUS_TYPE]}`}
                                                style={{margin: 0}}
                                            >
                                        {agreementLevel}
                                                <span style={{marginTop: 0, marginLeft: 10}}/>
                                        </span>
                                        }
                                    </div>
                                </div>
                            </If>
                            <Else condition={props.item.status != STATUS_TYPE.ON_APPROVAL}>
                                <span
                                    className={`request-list-item-status ${statusStylesMap[props.item.status as STATUS_TYPE]}`}>
                                    {t('filters.status')}: {t(`statusZNR.${statusNameMap[props.item.status as STATUS_TYPE]}`)}<span/>
                                </span>
                            </Else>
                        </If>
                        <If condition={props.isAdvance}>
                            <If condition={props.item.status == STATUS_TYPE.ON_APPROVAL}>
                                {props.isAgreement ? (
                                    <div style={{display: 'flex', alignItems: 'center', marginRight: '14px'}}>
                                        <div style={{textAlign: "right"}}>
                                            <p>{agreementList.description}</p>
                                            <p>
                                                {agreementList.name ? "(" + agreementList.name + ")" : ""}
                                            </p>
                                        </div>
                                        <div>
                                            {props.isAgreement ? inHandStatus() :
                                                <span
                                                    className={`request-list-item-status ${statusStylesMap[props.item.status as STATUS_TYPE]}`}
                                                    style={{margin: 0}}
                                                >
                                                {agreementLevel}
                                                    <span/>
                                            </span>
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <span style={{display: 'flex'}}
                                          className={`request-list-item-status ${statusStylesMap[props.item.status as STATUS_TYPE]}`}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            textAlign: 'right',
                                            fontSize: '16px',
                                            lineHeight: '16px'
                                        }}>
                                            <p>{agreementList.description}</p>
                                            <p>{agreementList.name ? "(" + agreementList.name + ")" : ""}</p>
                                        </div>
                                        <span>{agreementLevel}</span>
                                    </span>
                                )}
                            </If>
                            <Else condition={props.item.status != STATUS_TYPE.ON_APPROVAL}>
                                <span
                                    className={`request-list-item-status ${statusStylesMap[props.item.status as STATUS_TYPE]}`}>
                                    {t('filters.status')}: {t(`statusAO.${statusNameMap[props.item.status as STATUS_TYPE]}`)}<span/>
                                </span>
                            </Else>
                        </If>
                    </div>
                    <If condition={!props.isAgreement}>
                        {viewDeleteButton()}
                    </If>
                </div>
            </div>
        </Link>
    )
}

export default withRouter(ListItem);
