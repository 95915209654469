import React, { useEffect, useState } from "react";
import "./Daily.css";
import { useTranslation } from "react-i18next";
import SvgIcon from "../svg-icon/SvgIcon";
import If from "../util/If";
import {
  getNextKey,
  formatter,
  enumerateDaysBetweenDates,
  mergeDatesToPeriods,
} from "app/utils";
import DatePickerCalendar from "../date-picker/DatePickerCalendar";
import moment from "moment";
import { Button } from "react-bootstrap";

interface PersonalDayProps {
  dates: {
    date: Date;
    mark: number;
    isPrivateDay: boolean;
    isRepresentationExpenses: boolean;
    isFoodPresent: boolean;
    numberOfMeal: number;
  }[];
  onChange: (value: { date: Date; mark: number }[], isDelete: boolean) => void;
  from: Date;
  to: Date;
  disabled?: boolean;
  isWidget?: boolean;
}

const PersonalDay: React.FC<PersonalDayProps> = (props) => {
  const { t, i18n } = useTranslation();

  let [isShowDatePicker, setShowDatePicker] = useState(false);
  let [personalDay, setPersonalDay] = useState(
    [] as {
      id: number;
      period: boolean;
      startDay: Date;
      endDay: Date;
    }[]
  );
  let [disabledDates, setDisabledDates] = useState(
    [] as {
      from: Date;
      to: Date;
    }[]
  );

  useEffect(() => {
    let newDisabledDates = personalDay.map((item) => ({
      to: item.endDay,
      from: item.startDay,
    }));
    setDisabledDates(newDisabledDates);
  }, [personalDay]);

  useEffect(() => {
    let newPersonalDay = [] as {
      id: number;
      period: boolean;
      startDay: Date;
      endDay: Date;
    }[];
    if (props.dates && props.dates.length > 0) {
      let period = mergeDatesToPeriods(
        props.dates.filter((item) => item.isPrivateDay).map((item) => item.date)
      );

      period.forEach((element) => {
        newPersonalDay.push({
          id: getNextKey("PersonalDay"),
          period: element.from !== element.to,
          startDay: element.from,
          endDay: element.to,
        });
      });
    }
    setPersonalDay(newPersonalDay);
  }, [props.dates]);

  const addDay = (value: any) => {
    let newDateArray = enumerateDaysBetweenDates(
      value.startDate,
      value.endDate,
      true
    );
    props.onChange(
      newDateArray.map((item) => ({
        date: item,
        mark: +moment(item).format("YYYYMMDD"),
      })),
      false
    );
    setShowDatePicker(false);
  };

  const removeDay = (event: any) => {
    const target = event.currentTarget.dataset.id;
    let removeDay = personalDay.find((item) => item.id === +target);
    if (removeDay) {
      let newDay = enumerateDaysBetweenDates(
        removeDay.startDay,
        removeDay.endDay,
        true
      );
      props.onChange(
        newDay.map((item) => ({
          date: item,
          mark: +moment(item).format("YYYYMMDD"),
        })),
        true
      );
    }
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!isShowDatePicker);
  };

  return (
    <>
      <label className="input-label">
        {t("create_expense.daily_allowance.personal_day")}
      </label>
      <div className="request-item-content">
        <DatePickerCalendar
          isShown={isShowDatePicker}
          isMultiChoice={true}
          isCleansed={true}
          onSelected={addDay}
          onClose={toggleDatePicker}
          disableBeforeDate={props.from}
          disableAfterDate={props.to}
          disabledDates={disabledDates}
          month={props.from}
        />
        <div className="input-choose">
          {personalDay.map((item) => {
            return (
              <div className="input-choose-item" key={item.id}>
                {formatter(
                  "D MMMM",
                  i18n.language,
                  item.startDay,
                  item.endDay,
                  { withMonth: false, withYear: false }
                )}
                <If condition={!props.disabled}>
                  <div onClick={removeDay} data-id={item.id}>
                    <SvgIcon
                      className="icon icon-close"
                      href="#svg_icon_close"
                    />
                  </div>
                </If>
              </div>
            );
          })}
          <If condition={!props.disabled}>
            {props.isWidget ? (
              <Button
                variant={"outline-secondary"}
                className={"daily"}
                id="datepicker"
                onClick={toggleDatePicker}
              >
                + {t("create_expense.daily_allowance.add")}
              </Button>
            ) : (
              <div
                className="btn-expense btn_gray add-trip daily"
                id="datepicker"
                onClick={toggleDatePicker}
              >
                + {t("create_expense.daily_allowance.add")}
              </div>
            )}
          </If>
        </div>
      </div>
    </>
  );
};

export default PersonalDay;
