import { BaseMapper } from './base.mapper';
import { AttachmentItem } from 'infrastructure/interfaces';
import { get} from 'lodash';

// const attachmentItems = [{
//     id: 123123,
//     name: 'Stub.js',
//     objectId: {
//         id: 123123,
//         logicalName: 'Stub',
//     },
//     createdAt: "1000-01-01T00:00:00",
//     modifiedAt: "1000-01-01T00:00:00",
//     size: '0'
// }]

export class AttachmentItemMapper extends BaseMapper<AttachmentItem[], any> {
    responseToEntity(responses: any): AttachmentItem[] {
        return (!responses.Errors && responses.length) ? [...responses.map((item : AttachmentItem) => ({
            id: get(item, 'id', 123123),
            name: get(item, 'name', 'Stub'),
            objectId: {
              id: get(item, 'objectId.id', 123123),
              logicalName: get(item, 'objectId.logicalName','STUB')
            },
            createdAt: get(item, 'createdAt'),
            modifiedAt: get(item, 'modifiedAt'),
            size: get(item, 'size')
        }))] : [];
    }
}