import React from 'react';
import ButtonsControl from 'app/component/buttons-control/ButtonsControl';

interface AOButtonsControl {
    id: number,
    isPostingsMode: boolean,
    setPostingsMode: () => void
}

const AOButtonsControl: React.FC<AOButtonsControl> = (props) => {
    return (<ButtonsControl id={props.id} isPostingsMode={props.isPostingsMode} isAdvance={true} setPostingsMode={props.setPostingsMode}/>)
}

export default AOButtonsControl;
