import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from 'app/component/modal/ModalContainer';

interface DeleteExpenseApplicationModal{
    isOpen: boolean,
    onClose: () => void,
    onDelete?: () => void,
    question?: string
}

const DeleteExpenseApplicationModal: React.FC<DeleteExpenseApplicationModal> = props => {
    const { t } = useTranslation();

    let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

    const closeHandler = () => {
        props.onClose();
    };

    const isHighestModal = (isHighest: boolean) => {
        setTabIndexDisabled(!isHighest);
    }

    return (
        <ModalContainer isOpen={props.isOpen} highestModal={isHighestModal} destroy={true} overlayClick={closeHandler}>
            <div className="box-modal" id="note-delete">
                <div className="box-modal_close arcticmodal-close" onClick={closeHandler}></div>
                <div className="box-modal-title">
                    <div className="tx-center">{props.question}</div>
                </div>
                <div className="box-modal-footer"><a className="arcticmodal-close btn btn_black" onClick={closeHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('delete_expense_application_modal.no')}</a><a
                    className="btn-expense btn_green" onClick={props.onDelete} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('delete_expense_application_modal.yes')}</a></div>
            </div>
        </ModalContainer>
    );
};

export default DeleteExpenseApplicationModal;