import {ActionCreator} from 'redux';
import {ThunkAction, ThunkDispatch} from 'redux-thunk'
import {ApplicationState} from 'store/reducers';
import {
  AddError,
  AddException,
  CloseErrorModal,
  CloseExceptionModal,
  Exception,
  EXCEPTION,
  ExceptionActions,
  OpenExceptionModal,
  Info,
  AddInfo,
  CloseInfoModal
} from './actionTypes';

const addException: ActionCreator<AddException> = (exception: Exception) => ({
  type: EXCEPTION.ADD_EXCEPTION,
  payload: {exception}
});

const addError: ActionCreator<AddError> = (exception: Exception) => ({
  type: EXCEPTION.ADD_ERROR,
  payload: {exception}
});

const addInfo: ActionCreator<AddInfo> = (info: Info) => ({
  type: EXCEPTION.ADD_INFO,
  payload: {info}
});

export const openExceptionModal: ActionCreator<OpenExceptionModal> = () => ({
  type: EXCEPTION.OPEN_EXCEPTION_MODAL
});

export const closeExceptionModal: ActionCreator<CloseExceptionModal> = () => ({
  type: EXCEPTION.CLOSE_EXCEPTION_MODAL
});

export const closeErrorModal: ActionCreator<CloseErrorModal> = (errorId: number) => ({
  type: EXCEPTION.CLOSE_ERROR_MODAL,
  payload: {errorId}
});

export const closeInfoModal: ActionCreator<CloseInfoModal> = (infoId: number) => ({
  type: EXCEPTION.CLOSE_INFO_MODAL,
  payload: {infoId}
});

export const throwException = (exception: Exception): ThunkAction<Promise<void>, ApplicationState, any, ExceptionActions> => {
  return async (dispatch: ThunkDispatch<ApplicationState, any, ExceptionActions>, getState): Promise<void> => {
    dispatch(addException(exception));
    dispatch(openExceptionModal());

  }
}

export const showErrors = (exception: Exception): ThunkAction<Promise<void>, ApplicationState, any, ExceptionActions> => {
  return async (dispatch: ThunkDispatch<ApplicationState, any, ExceptionActions>, getState): Promise<void> => {
    dispatch(addError(exception));
  }
}

export const showInfo = (info: Info): ThunkAction<Promise<void>, ApplicationState, any, ExceptionActions> => {
  return async (dispatch: ThunkDispatch<ApplicationState, any, ExceptionActions>, getState): Promise<void> => {
    dispatch(addInfo(info));
  }
}