import ModalContainer from 'app/component/modal/ModalContainer';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import If from '../util/If';

interface AssistanceWarningModalProps {
    isOpen: boolean,
    title: string,
    info: string,
    sub_info: string,
    todo: string,
    onClose: () => void,
    onSubmit: () => void
}

const AssistanceWarningModal: React.FC<AssistanceWarningModalProps> = (props) => {

    const {t} = useTranslation();
    let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

    const isHighestModal = (isHighest: boolean) => {
        setTabIndexDisabled(!isHighest);
    }

    return (
        <ModalContainer isOpen={props.isOpen} destroy={true} highestModal={isHighestModal} overlayClick={props.onClose}>
            <div className="box-modal" id="note-delete">
                <div className="box-modal_close arcticmodal-close pointer" onClick={props.onClose}></div>
                <div className="box-modal-title">
                    <div className="tx-center line-h-30">{props.title}</div>
                </div>
                <If condition={!!props.info}>
                    <div className="box-modal-content" >
                        <div className="text">
                            <div className="tx-center">{props.info}</div>
                            <div className="tx-center">{props.sub_info}</div>
                            <div className="tx-center m-t-20">{props.todo}</div>
                        </div>
                    </div>
                </If>
                <div className="box-modal-footer">
                    <a className="arcticmodal-close btn btn_black pointer" onClick={props.onClose} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.delete_attachment.no')}</a>
                    <a className="btn-expense btn_green pointer" onClick={props.onSubmit} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.delete_attachment.yes')}</a></div>
            </div>
        </ModalContainer>
    );
}

export default AssistanceWarningModal;
