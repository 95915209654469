import React from "react";
import ModalContainer from "app/component/modal/ModalContainer";
import { useSelector } from "react-redux";
import { getCommonLoaderState } from "../../../store/selectors";
import { Spinner } from "react-bootstrap";
import "./Loader.css";

interface LoaderProps {
  isWidget?: boolean;
}

const Loader: React.FC<LoaderProps> = (props) => {
  const loaderState = useSelector(getCommonLoaderState);

  return (
    <ModalContainer
      isOpen={loaderState || false}
      destroy={true}
      id={-1}
      style={props.isWidget ? { zIndex: 1200 } : undefined}
    >
      {props.isWidget ? (
        <Spinner animation="border" role="status" variant={"secondary"}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <div
          style={{ zIndex: 1000, position: "relative" }}
          className="lds-dual-ring"
        ></div>
      )}
    </ModalContainer>
  );
};

export default Loader;
