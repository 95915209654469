import { BaseMapper } from './base.mapper';
import { AssigneeEmployee } from 'infrastructure/interfaces';
import { get } from 'lodash';


export class AssigneeEmployeeMapper extends BaseMapper<AssigneeEmployee, any> {
  responseToEntity(response: any): AssigneeEmployee {
    return {
      id: get(response, 'id', 0),
      name:{
        ru: get(response, 'name.ru', ''),
        en: get(response, 'name.en', '')
      },
      phoneNumber: get(response, 'phoneNumber', ''),
      email: get(response, 'email', ''),
      occupationId: get(response, 'occupationId', 0),
    };
  };
}
