import TextCutter from 'app/component/text-cutter/TextCutter';
import React, { Fragment, useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import Config, { ConfigTypes } from 'services/ConfigService';
import qs from 'qs';
//component
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import PersonalTravel from './ZNRTravel';
//common
import { formatter, isSentenceContainsMaxLengthWord, NameFormats, nameFormatter, betweenSeveralDates} from 'app/utils';
import { ApplicationForExpenseDetail } from "infrastructure/interfaces/index";
import { EXPENSE_APPLICATION_PROPETIES } from 'infrastructure/enum/object-properties.enum'
import { get, chunk, uniq } from 'lodash';
import If from 'app/component/util/If';
import { loaderLock, loaderUnlock } from "store/common/actions";
import { showErrors } from 'store/exception/actions';
import {getCommonUserDetail} from "../../../../store/selectors";
import {
    getCustomProperties, getCustomPropertiesById,
    getCustomPropertiesCodes,
    getCustomPropertiesDependencies, getCustomPropertiesValuesByIds, getLinkToExternalApplication,
    getLogicalName
} from "../../../../services/ApiService";
import {RequestCost} from "./ZNRDetail";

interface ICP {
    id: number,
    name: {
        ru: string,
        en: string,
    },
    codeName: string,
    value: string[],
    isVisible: boolean
}

interface ZNRHeader {
    applicationForExpenseDetail: ApplicationForExpenseDetail,
    toggleEmployeeInfo: () => void,
}

const ZNRHeader: React.FC<ZNRHeader> = props => {
    const { t, i18n } = useTranslation();
    const user = useSelector(getCommonUserDetail);

    let [isTrip, setIsTrip] = useState(false);
    let [fieldAccess, setFieldAccess] = useState({
        number: false,
        applicationType: false,
        assigneeEmployee: false,
        assigneeEmployeeCP: false,
        orderNumber: false,
        journeyNumber: false,
        businessTripNumber: false,
        businessTripOption: false,
        businessTarget: false,
        description: false,
        vbsElement: false,
        departureFromCity: false,
        arrivalToCity: false,
        startOn: false,
        endOn: false,
        payer: false,
        personalDays: false,
        personalDaysUpdate: false,
        advanceCashAmount: false,
        approvedAmount: false,
        totalAmount: false,
        flowHistory: false,
        status: false,
        costCenter: false,
        personalDaysNumber: false,
    });
    let [cPropertysValue, setCPropertysValue] = useState([] as ICP[]);

    const dispatch = useDispatch();

    useEffect(() => {
        isBusinessTrip();
        generateFieldsAccess();
        if (props.applicationForExpenseDetail) {
            getCPropertysValue();
        }
    }, [props.applicationForExpenseDetail]);

    const getLogicalNameId = async () => {
        let result = await getLogicalName({ params: { NameEn: 'ExpenseApplication' } });

        if (result.headers.success) {
            return result.data[0].id;
        } else {
            return 0;
        }
    }

    const splitValue = (text: string) => {
        let value = '';
    
        for (let i = 0; i < text.length; i++) {
          if (i % 27 === 0) {
            value += '\r\n';
          }
    
          value += text[i];
        }
    
        return value;
      }

    const getCPropertysValue = async () => {
        dispatch(loaderLock());
        if (props.applicationForExpenseDetail) {
            let logicalNameId = await getLogicalNameId();
            if (!logicalNameId) {
                dispatch(showErrors({ code: '', message: 'Не удалось получить доп. данные' }));
                return;
            }
            let cPRequest = await getCustomProperties( { params: { CompanyId: props.applicationForExpenseDetail.company.id, ObjectLogicalNameId: logicalNameId } });
            if (cPRequest.headers.success) {
                const codeNamesList = cPRequest.data.map((property: any) => property.codeName);
                const propertysName = cPRequest.data.map((property: any) => {
                    let itemType = 'text';

                    if (property.isList) {
                        itemType = 'select'
                    }

                    if (property.isAllocationAvailable) {
                        itemType = 'allocation'
                    }
                    return {
                        id: property.id,
                        name: {
                            ru: property.clientNameRu,
                            en: property.clientNameEn,
                        },
                        codeName: property.codeName,
                        type: itemType,
                        isVisible: property.isVisible,
                        ordinal: property.ordinal
                    }

                });
                let result = await getCustomPropertiesCodes( {
                    params: {
                        ObjectLogicalNameId: logicalNameId,
                        ExternalObjectId: props.applicationForExpenseDetail.id,
                        CustomPropertyCodes: codeNamesList
                    },
                    paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
                });
                if (result.headers.success) {
                    const propertysValues = result.data.map((item: any) => {
                        let itemType = 'text';
                        let listValueId = 0;

                        if (item.isList) {
                            itemType = 'select'
                            listValueId = item.listValueId;
                        }

                        if (item.isAllocation) {
                            itemType = 'allocation'
                            listValueId = item.listValueId;
                        }

                        let valueProperty = [item.nameRu];
                        if (item.isAllocation) {
                            valueProperty = [`${item.nameRu} - ${item.percent}%`];
                        }

                        return {
                            id: item.customPropertyId,
                            value: valueProperty,
                            listValueId: listValueId,
                            type: itemType,
                            isVisible: item.isVisible
                        }
                    });
                    let cpValues = propertysName.map((property: any) => {
                        const valuePropertyItem = {
                            value: propertysValues.filter((item: any) => item.id === property.id).map((item: any) => item.value).flatMap((item: any) => item)
                        };
                        return {
                            id: property.id,
                            name: property.name,
                            codeName: property.codeName,
                            value: valuePropertyItem ? valuePropertyItem.value : null,
                            isVisible: property.isVisible,
                            ordinal: property.ordinal
                        }
                    });
                    let dependentCPValues = await getDependencyCPValues(propertysValues.filter((item: any) => (item.type == 'select' || item.type == 'allocation')).map((item: any) => item.listValueId));
                    let cpValuesWithDependent = [...cpValues, ...dependentCPValues as any[]];
                    setCPropertysValue(cpValuesWithDependent);
                }
            } else {
                dispatch(showErrors({ code: '', message: 'Не удалось получить доп. данные' }));
            }
        }
        dispatch(loaderUnlock());
    }

    const getDependencyCPValues = async (listValueIds: number[]): Promise<any> => {
        let logicalNameId = await getLogicalNameId();
        if (!logicalNameId) {
            dispatch(showErrors({ code: '', message: 'Не удалось получить доп. данные' }));
            return [];
        }

        if (!listValueIds.length) {
            return [];
        }

        let cPRequest = await getCustomPropertiesDependencies( {
            params: { CustomPropertyListValueIds: listValueIds },
            paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
        });
        if (cPRequest.headers.success && cPRequest.data[0]) {
            let dependent = cPRequest.data;
            let newCPIds = [] as number[];
            let newCPListValueIds = [] as number[];

            for (let indexDCP = 0; indexDCP < dependent.length; indexDCP++) {
                const dependentElement = dependent[indexDCP];
                newCPIds = [...newCPIds, ...dependentElement.propertyOnValue];
                newCPListValueIds = [...newCPListValueIds, ...dependentElement.valueOnValue];

            }

            if (newCPListValueIds.length) {
                let resultListValueCP = await getCustomPropertiesValuesByIds( {
                    params: { Ids: uniq(newCPListValueIds) },
                    paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
                });

                if (resultListValueCP.headers.success) {
                    let resultListValueCPIds = resultListValueCP.data.map((item: any) => item.customPropertyId);
                    newCPIds = [...newCPIds, ...resultListValueCPIds];
                }
            }

            newCPIds = uniq(newCPIds);

            let newDependentCP = [];

            for (let indexNewCP = 0; indexNewCP < newCPIds.length; indexNewCP++) {
                let newCPIdsElement = newCPIds[indexNewCP];
                let cpIdsResult = await getCustomPropertiesById(newCPIdsElement);
                if (cpIdsResult.headers.success) {
                    let cpType = 'text';

                    if (cpIdsResult.data.isList) {
                        cpType = 'select'
                    }

                    if (cpIdsResult.data.isAllocationAvailable) {
                        cpType = 'allocation'
                    }
                    newDependentCP.push({
                        id: cpIdsResult.data.id,
                        editId: 0,
                        name: { ru: cpIdsResult.data.clientNameRu, en: cpIdsResult.data.clientNameEn },
                        type: cpType,
                        codeName: cpIdsResult.data.codeName,
                        isVisible: cpIdsResult.data.isVisible
                    });
                }
            }

            let newDependentCPCodes = newDependentCP.map(item => item.codeName);

            if (newDependentCPCodes.length) {
                let codesResult = await getCustomPropertiesCodes( {
                    params: {
                        ObjectLogicalNameId: logicalNameId,
                        ExternalObjectId: props.applicationForExpenseDetail.id,
                        CustomPropertyCodes: newDependentCPCodes
                    },
                    paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' })
                });
                if (codesResult.headers.success) {
                    const propertysValues = codesResult.data.map((item: any) => {
                        let itemType = 'text';
                        let listValueId = 0;

                        if (item.isList) {
                            itemType = 'select'
                            listValueId = item.listValueId;
                        }

                        if (item.isAllocation) {
                            itemType = 'allocation'
                            listValueId = item.listValueId;
                        }

                        let valueProperty = [item.nameRu];
                        if (item.isAllocation) {
                            valueProperty = [`${item.nameRu} - ${item.percent}%`];
                        }

                        return {
                            id: item.customPropertyId,
                            value: valueProperty,
                            listValueId: listValueId,
                            type: itemType
                        }
                    });
                    let cpValues = newDependentCP.map((property: any) => {
                        const valuePropertyItem = {
                            value: propertysValues.filter((item: any) => item.id === property.id).map((item: any) => item.value).flatMap((item: any) => item)
                        };
                        return {
                            id: property.id,
                            name: property.name,
                            codeName: property.codeName,
                            value: valuePropertyItem ? valuePropertyItem.value : null,
                            isVisible: property.isVisible
                        }
                    });


                    let dependentCPValues: any[] = await getDependencyCPValues(propertysValues.filter((item: any) => (item.type == 'select' || item.type == 'allocation')).map((item: any) => item.listValueId));

                    if (dependentCPValues) {
                        let cpValuesWithDependent: any[] = [...cpValues, ...dependentCPValues];
                        return cpValuesWithDependent;
                    }
                }
            }
            return [];
        }
        return [];
    }

    const isBusinessTrip = () => {
        let companyId = props.applicationForExpenseDetail ? props.applicationForExpenseDetail.company.id : 0;
        let linkToTimeEnabledPermission = Config.getConfigToCompany(ConfigTypes.LINK_TO_TIME_ENABLED, companyId);
        setIsTrip(linkToTimeEnabledPermission && props.applicationForExpenseDetail.journeyNumber != 0 && props.applicationForExpenseDetail.businessTripNumber != 0)
    }

    const generateFieldsAccess = () => {
        let fieldAccess = {
            number: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.NUMBER),
            applicationType: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.APPLICATION_TYPE),
            assigneeEmployee: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ASSIGNEE_EMPLOYEE),
            assigneeEmployeeCP: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ASSIGNEE_EMPLOYEE_CP),
            orderNumber: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ORDER_NUMBER),
            journeyNumber: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.JOURNEY_NUMBER),
            businessTripNumber: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TRIP_NUMBER),
            businessTripOption: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TRIP_OPTION),
            businessTarget: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TARGET),
            description: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.DESCRIPTION),
            vbsElement: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.VBS_ELEMENT),
            departureFromCity: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.DEPARTURE_FROM_CITY),
            arrivalToCity: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ARRIVAL_TO_CITY),
            startOn: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.START_ON),
            endOn: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.END_ON),
            payer: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.PAYER),
            personalDays: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.PERSONAL_DAYS),
            personalDaysUpdate: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.PERSONAL_DAYS_UPDATE),
            advanceCashAmount: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ADVANCE_CASH_AMOUNT),
            approvedAmount: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.APPROVED_AMOUNT),
            totalAmount: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.TOTAL_AMOUNT),
            flowHistory: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.FLOW_HISTORY),
            status: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.STATUS),
            costCenter: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.COST_CENTER),
            personalDaysNumber: props.applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.PERSONAL_DAYS_NUMBER),
        }
        setFieldAccess(fieldAccess);
        return;
    }

    const goToTimeHandler = async () => {
        dispatch(loaderLock());
        let result = await getLinkToExternalApplication(props.applicationForExpenseDetail.journeyNumber,props.applicationForExpenseDetail.businessTripNumber);
        if (result.headers.success) {
            let redirectURL = get(result, 'data', '/');
            window.open(redirectURL, '_blank');
            // window.location.replace(redirectURL);
            dispatch(loaderUnlock());
        } else {
            dispatch(loaderUnlock());
            dispatch(showErrors({ code: 'takeInHand_action', message: 'Не удалось выполнить переход' }))
        }
    }

    const cpRender = () => {
        let cpValues = [...cPropertysValue];

        if (cpValues.length === 0) {
            return;
        }

        cpValues = cpValues
            .filter(item => item.isVisible)
            .filter(item => item.value)
            .filter(item => props.applicationForExpenseDetail.properties.includes(`CustomProperty_${item.codeName}`))
            .sort((a: any, b: any) => a.ordinal > b.ordinal ? 1 : -1);

        return chunk(cpValues, 4).map((propertyPart: ICP[]) => {
            while (propertyPart.length < 4) {
                propertyPart.push({
                    id: 0,
                    name: { ru: '', en: '' },
                    codeName: '',
                    value: [],
                    isVisible: true,
                });
            }

            return (
                <div className="request-item-row">
                    <div className="request-item-block-wrap" style={{justifyContent: "space-between"}}>
                        {propertyPart.map((property: ICP) => {
                            return property.value && property.value.length ? (
                                <div
                                    className={`request-item-block`}
                                    style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", width: '50%', minWidth: 220, overflowWrap: "break-word" }}
                                >
                                    <div className="request-item-label">{property.name[i18n.language as 'ru' | 'en']}</div>
                                    <div className="request-item-content">
                                        {property.value.map((item: string) =>
                                            <div className="request-item-block request-item-content" style={{ width: '50%', minWidth: 220, overflowWrap: "break-word" }}>
                                                {item}
                                            </div>)}
                                    </div>
                                </div>) : null;
                        })}
                    </div>
                </div>
            )
        })
    }

    if (props.applicationForExpenseDetail.applicationType.code === 'Travel') {
        return (
            <div className="request-header-wrapper">
                <div>
                    <div className="request-item-row">
                        <div className="request-item-block-wrap" style={{justifyContent: 'space-between'}}>
                            <div className={`request-item-block payer ${fieldAccess.businessTripNumber ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
                                {props.applicationForExpenseDetail.journeyNumber && (<div className="request-item-label">{t('request_detail.businessTrip')}</div>)}
                                <div className={`request-item-content underlined ${isTrip ? 'pointer' : ''}`} onClick={isTrip ? goToTimeHandler : () => { }}>№{props.applicationForExpenseDetail.journeyNumber}/{props.applicationForExpenseDetail.businessTripNumber}</div>                        </div>
                            <div className="request-item-block" style={{ width: '50%', minWidth: 220 }}>
                                <div className="request-item-label">
                                    {t('request_detail.employee')}
                                    <div onClick={props.toggleEmployeeInfo}>
                                        <SvgIcon className="icon icon-warning pointer" href="#svg_icon_warning" />
                                    </div>
                                </div>
                                <div className="request-item-content">{nameFormatter(props.applicationForExpenseDetail.assigneeEmployee.name, NameFormats.FULL, i18n.language)}</div>
                                <div className="request-item-other">
                                    <div>{props.applicationForExpenseDetail.assigneeEmployee.phoneNumber}</div>
                                    <a href="mailto:k.kostaninopolsky@shcneider.ru" tabIndex={-1}>{props.applicationForExpenseDetail.assigneeEmployee.email}</a>
                                </div>
                            </div>
                            <div className={`request-item-block payer ${fieldAccess.departureFromCity ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
                                <div className="request-item-label">{t('request_detail.citiesOfDepartureOrDestination')}</div>
                                <div className="request-item-content">{`${props.applicationForExpenseDetail.departureFromCity.name[i18n.language as 'ru' | 'en']} — ${props.applicationForExpenseDetail.businessTripDestinations.length > 0 ? get(props.applicationForExpenseDetail.businessTripDestinations[0], 'city.name.' + i18n.language, 'null') : props.applicationForExpenseDetail.arrivalToCity.name[i18n.language as 'ru' | 'en']}`}</div>
                                {props.applicationForExpenseDetail.businessTripDestinations.filter((road: any, index: any) => { return index != 0 }).map((road: any) => {
                                    return (<div className="request-item-content">{get(road, 'city.name.' + i18n.language, 'null')}</div>)
                                })}
                            </div>
                            <div className={`request-item-block payer ${fieldAccess.startOn ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
                                <div className="request-item-label">{t('request_detail.datesOfDepartureOrReturn')} ({props.applicationForExpenseDetail.businessTripDestinations.length == 0 ? betweenSeveralDates([{ startOn: props.applicationForExpenseDetail.startOn, endOn: props.applicationForExpenseDetail.endOn }]) : betweenSeveralDates(props.applicationForExpenseDetail.businessTripDestinations.map((item: any) => ({ startOn: item.arrivalOn, endOn: item.leaveOn })))}д.)</div>
                                <If condition={props.applicationForExpenseDetail.businessTripDestinations.length == 0}>
                                    <div className="request-item-content">{formatter('D MMMM', i18n.language, props.applicationForExpenseDetail.startOn, props.applicationForExpenseDetail.endOn, { withYear: true, withMonth: true })}</div>
                                </If>
                                {props.applicationForExpenseDetail.businessTripDestinations.map((road: any) => {
                                    return <div className="request-item-content">{formatter('D MMMM', i18n.language, get(road, 'arrivalOn', 'null'), get(road, 'leaveOn', 'null'), { withYear: true, withMonth: true })}</div>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="request-item-row">
                        <div className="request-item-block-wrap" style={{ justifyContent: 'space-between' }}>
                            <div className="request-item-block" style={{ width: '50%', minWidth: 220 }}>
                                <div className="request-item-label">{t('request_detail.payer')}</div>
                                <div className="request-item-content">{props.applicationForExpenseDetail.company.name[i18n.language as 'en' | 'ru']}
                                </div>
                            </div>
                            <div className={`request-item-block ${fieldAccess.businessTarget ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
                                <div className="request-item-label">{t('request_detail.businessTarget')}</div>
                                <div className="request-item-content">{props.applicationForExpenseDetail.businessTarget.name[i18n.language as 'en' | 'ru']}</div>
                            </div>
                            {props.applicationForExpenseDetail.description &&(<div className={`request-item-block ${fieldAccess.description ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
                                <div className="request-item-label">{t('request_detail.description')}</div>
                                <div className="request-item-content">{isSentenceContainsMaxLengthWord(props.applicationForExpenseDetail.description) ? <TextCutter text={splitValue(props.applicationForExpenseDetail.description)} maxLength={100} /> : props.applicationForExpenseDetail.description}</div>
                            </div>)}
                        </div>
                    </div>
                    {cpRender()}
                </div>
                <div className="request-header-right-wrapper">
                    <div className="request-header-right">
                        <div className="request-item-block payer" style={{ width: '100%', marginTop: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: fieldAccess.personalDays ? 'space-between' : 'flex-end'}}>
                            <PersonalTravel dates={props.applicationForExpenseDetail.expenseApplicationTravelDates} personalDaysNumber={props.applicationForExpenseDetail.personalDaysNumber} isVisible={fieldAccess.personalDays} canEdit={fieldAccess.personalDaysUpdate} />
                            <RequestCost advanceCashAmount={props.applicationForExpenseDetail.advanceCashAmount} totalAmount={props.applicationForExpenseDetail.totalAmount} approvedAmount={props.applicationForExpenseDetail.approvedAmount} expenses={props.applicationForExpenseDetail.expenses} />
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="request-header-wrapper">
                <div>
                    <div className="request-item-row">
                        <div className="request-item-block-wrap" style={{justifyContent: 'space-between'}}>
                            <div className="request-item-block payer" style={{ width: '50%', minWidth: 220 }}>
                                <div className="request-item-label ">
                                    {t('request_detail.employee')}
                                    <div onClick={props.toggleEmployeeInfo} >
                                        <SvgIcon className="icon icon-warning pointer" href="#svg_icon_warning" />
                                    </div>
                                </div>
                                <div className="request-item-content">{nameFormatter(props.applicationForExpenseDetail.assigneeEmployee.name, NameFormats.FULL, i18n.language)}</div>
                                <div className="request-item-other">
                                    <div>{props.applicationForExpenseDetail.assigneeEmployee.phoneNumber}</div>
                                    <a href={`mailto:${props.applicationForExpenseDetail.assigneeEmployee.email}`} tabIndex={-1}>{props.applicationForExpenseDetail.assigneeEmployee.email}</a>
                                </div>
                            </div>
                            <div className="request-item-block place" style={{ width: '50%', minWidth: 220 }}>
                                <div className="request-item-label">{t('request_detail.payer')}</div>
                                <div className="request-item-content">{props.applicationForExpenseDetail.company.name[i18n.language as 'en' | 'ru']}</div>
                            </div>
                            <div className={`request-item-block payer ${fieldAccess.businessTarget ? '' : 'd-none'}`} style={{ width: '50%', minWidth: 220 }}>
                                <div className="request-item-label">{t('request_detail.businessTarget')}</div>
                                <div className="request-item-content">{props.applicationForExpenseDetail.businessTarget.name[i18n.language as 'en' | 'ru']}</div>
                            </div>
                            {props.applicationForExpenseDetail.description &&
                            (<div className={`request-item-block place ${fieldAccess.description ? '' : 'd-none'}`}
                                  style={{width: '50%', minWidth: 220}}>
                                <div className="request-item-label">{t('request_detail.description')}</div>
                                <div
                                    className="request-item-content">{isSentenceContainsMaxLengthWord(props.applicationForExpenseDetail.description) ?
                                    <TextCutter text={splitValue(props.applicationForExpenseDetail.description)}
                                                maxLength={100}/> : props.applicationForExpenseDetail.description}</div>
                            </div>)}
                        </div>
                    </div>
                    {cpRender()}
                </div>
                <div className="request-header-right-wrapper">
                    <div className="request-header-right">
                        <div className="request-item-block payer" style={{ marginTop: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                            <RequestCost advanceCashAmount={props.applicationForExpenseDetail.advanceCashAmount} totalAmount={props.applicationForExpenseDetail.totalAmount} approvedAmount={props.applicationForExpenseDetail.approvedAmount} expenses={props.applicationForExpenseDetail.expenses} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default ZNRHeader;
