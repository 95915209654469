import Radio from 'app/component/radio/Radio';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserDetail, loaderLock, loaderUnlock } from 'store/common/actions';
import TextCutter from 'app/component/text-cutter/TextCutter';
import { IOccupations } from 'infrastructure/interfaces';
import {getCommonUserDetail} from "../../../store/selectors";
import {updateUserSettings} from "../../../services/ApiService";

const Profile: React.FC = () => {
  const { t, i18n } = useTranslation();
  let nameRef = useRef(null as any);

  let userDetail = useSelector(getCommonUserDetail);
  const dispatch = useDispatch();

  let [width, setWidth] = useState(0);
  let [occupations, setOccupations] = useState([] as any[]);

  useEffect(() => {
    dispatch(updateUserDetail());
    resizeHandler();
    initOccupations();
  }, []);

  const resizeHandler = () => {
    setWidth(nameRef.current.offsetWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  })

  const changeLanguageHandler = async (languageCode: string) => {
    dispatch(loaderLock());

    i18n.changeLanguage(languageCode);

    let response = await updateUserSettings(userDetail.id, {
      language: {
        code: languageCode
      }
    });
    if (response.headers.success) {
      dispatch(updateUserDetail());
    }
    else {
      //dispatch(showErrors({ code: 'approve_postings', message: 'Не удалось согласовать проводки' }));
    }

    dispatch(loaderUnlock());
  }

  const fullName: string[] = userDetail.name ? userDetail.name[i18n.language as 'en' | 'ru'].split(' ') : [];
  const firstName = fullName[0] ? fullName[0] : '';
  const middleName = fullName[1] ? fullName[1] : '';
  const lastName = fullName[2] ? fullName[2] : '';

  const initOccupations = () => {
    let newOccupations = [] as any[];
    let occupations = userDetail.occupations.map((item: IOccupations) => {
      return  {
        occupationId: item.occupationId,
        company: item.company.name[i18n.language as 'ru' | 'en'],//get(item.company.name., `company.name.${}`)
        code: item.code,
        name: item.name[i18n.language as 'ru' | 'en'],
      }
    })
    occupations.forEach((item: any) => {
      newOccupations.push({value: item.occupationId, company: item.company, name: item.name});
    });
    setOccupations(newOccupations);
  };

  const renderOccupations = () => {
    return occupations.map((occupation: any) => {
      return(
        <div className="profile-form-input-item">
          <input className="input" type="text" value={`${occupation.company} - ${occupation.name}`} readOnly />
        </div>        
      )
    })
  };

  return (
    <div className="profile-forms">
      <div className="profile-form">
        <div className="profile-form-title">{t('profile.personal_data')}</div>
        <div className="profile-form-input-item">
          <div className="profile-form-input-label">{t('profile.first_name')}</div>
          <input className="input" type="text" value={firstName} readOnly />
        </div>
        <div className="profile-form-input-item">
          <div className="profile-form-input-label">{t('profile.last_name')}</div>
          <input className="input" type="text" value={lastName} readOnly />
        </div>
        <div className="profile-form-input-item">
          <div className="profile-form-input-label">{t('profile.middle_name')}</div>
          <input className="input" type="text" value={middleName} readOnly />
        </div>
        <div className="profile-form-title">{t('profile.occupations')}</div>
        <div className="profile-form-input-item">
          {/* <div className="profile-form-input-label">{t('profile.first_name')}</div>
          <input className="input" type="text" value={firstName} readOnly /> */}
          {renderOccupations()}
        </div>
      </div>
      <div className="profile-form">
        <div className="profile-form-title">{t('profile.language_select')}</div>
        <div className="profile-setting-lang flex-center m-b-15">
          <Radio checked={i18n.language === 'ru'}
            label='Русский'
            onClick={() => { changeLanguageHandler('ru') }}
            id={1}
            name={'radio'}
            key={1} />
          <Radio checked={i18n.language === 'en'}
            label='English'
            onClick={() => { changeLanguageHandler('en') }}
            id={2}
            name={'radio'}
            key={2} />
        </div>
        <div className="profile-form-title">{t('profile.contact_details')}</div>
        <div className="profile-form-input-item">
          <div className="profile-form-input-label">{t('profile.phone_number')}</div>
          <input className="input" type="text" value={userDetail.phoneNumber ? userDetail.phoneNumber : ''} readOnly />
        </div>
        <div className="profile-form-input-item">
          <div className="profile-form-input-label">{t('profile.email')}</div>
          <div className="input" ref={nameRef}>
            <TextCutter parentWidth={width} text={userDetail.email} options={[{ maxLength: 58, minWidth: 240, maxWidth: 666, symbolSize: 12 }]} />
          </div>
        </div>
      </div>
    </div>)
};

export default Profile;