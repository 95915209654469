import React, {Fragment, useEffect, useState} from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {RouteComponentProps, withRouter} from "react-router";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store/reducers";
import {getAdvanceReportApplication, getApplicationForExpenseDetail} from "../../../store/selectors";

interface BreadcrumbsProps extends RouteComponentProps<any>{
    isDetail?: boolean
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const { t } = useTranslation();
  const [detailId, setDetailId] = useState(0);

  const applicationForExpense = useSelector(getApplicationForExpenseDetail);
  const advanceReport = useSelector(getAdvanceReportApplication);
  const [isAdvance, setAdvance] = useState(false);

    useEffect(() => {
        if (props.match.path === '/ExpenseApplication/Detail/:id') {
            setAdvance(false);
            setDetailId(applicationForExpense ? applicationForExpense.id : 0);
        }
        if (props.match.path === '/AdvanceReportApplication/Detail/:id') {
            setAdvance(true);
            setDetailId(advanceReport ? advanceReport.id : 0);
        }
    }, [props.location])

    return (
    <div className="breadcrumbs" style={{marginLeft: 0, marginTop: '4px', display: 'flex', flexDirection: "row"}}>
      {/* <Link to='/'>{t('breadcrumbs.home')}</Link> */}
        {props.isDetail &&
        <div style={{width: '100%'}}><span
            className="detail-label">{t(isAdvance ? 'report_detail.advance_report_number' : 'request_detail.application_for_expense_number', {id: detailId})}</span>
        </div>}
        <div style={{width: '100%', display: "flex", marginTop: props.isDetail ? '' : '27px'}}>
      <Switch>
        <Route path='/ExpenseApplication/Detail/:id' strict children={({ match }) =>
          <Fragment>
            <Link to='/ExpenseApplication' tabIndex={-1}>{t('breadcrumbs.request')}</Link>
            <span>{match ? match.params.id : null}</span>
          </Fragment>
        } />
        <Route path='/AdvanceReportApplication/Detail/:id' strict children={({ match }) =>
          <Fragment>
            <Link to='/AdvanceReportApplication' tabIndex={-1}>{t('breadcrumbs.expenses')}</Link>
            <span>{match ? match.params.id : null}</span>
          </Fragment>
        } />
        <Route path='/ExpenseApplication' children={() => <span style={{color: '#000', fontWeight: 700}}>{t('breadcrumbs.request')}</span>} />
        <Route path='/AdvanceReportApplication' children={() => <span style={{color: '#000', fontWeight: 700}}>{t('breadcrumbs.expenses')}</span>} />
        <Route path='/Agreement' children={() => <span style={{color: '#000', fontWeight: 700}}>{t('breadcrumbs.analytics')}</span>} />
        <Route path='/Journal/AdvanceReportApplication/:id' strict children={({ match }) =>
          <Fragment>
            <Link to='/Journal'>{t('breadcrumbs.journal')}</Link>
            <Link to='/Journal/AdvanceReportApplication'>авансовые отчеты</Link>
            <span>{match ? match.params.id : null}</span>
          </Fragment>
        } />
        <Route path='/Journal/ExpenseApplication/:id' strict children={({ match }) => 
          <Fragment>
            <Link to='/Journal'>{t('breadcrumbs.journal')}</Link>
            <Link to='/Journal/ExpenseApplication'>заявки на расход</Link>
            <span>{match ? match.params.id : null}</span>
          </Fragment>
        }/>
          <Route path='/Journal/Downloads/:id' strict children={({ match }) =>
              <Fragment>
                  <Link to='/Journal'>{t('breadcrumbs.journal')}</Link>
                  <Link to='/Journal/Downloads'>{t('breadcrumbs.downloads')}</Link>
                  <span>{match ? match.params.id : null}</span>
              </Fragment>
          } />
        <Route path='/Journal/AdvanceReportApplication' children={() =>
          <Fragment>
            <Link to='/Journal'>{t('breadcrumbs.journal')}</Link>
            <span>{t('breadcrumbs.expenses')}</span>
          </Fragment>
        } />
        <Route path='/Journal/ExpenseApplication' children={() =>
          <Fragment>
            <Link to='/Journal'>{t('breadcrumbs.journal')}</Link>
            <span>{t('breadcrumbs.request')}</span>
          </Fragment>
        } />
        <Route path='/Journal/Downloads' children={() =>
          <Fragment>
            <Link to='/Journal'>{t('breadcrumbs.journal')}</Link>
            <span>{t('breadcrumbs.downloads')}</span>
          </Fragment>
        } />
        <Route path='/Reports/Registry' children={() =>
          <Fragment>
            <Link to='/Reports'>{t('breadcrumbs.reports')}</Link>
            <span>{t('breadcrumbs.reports_registry')}</span>
          </Fragment>
        } />
        <Route path='/Reports' children={() =>
          <Fragment>
            <span>{t('breadcrumbs.reports')}</span>
          </Fragment>
        } />

        <Route path='/Journal' children={() => <span  style={{color: '#000', fontWeight: 700}}>{t('breadcrumbs.journal')}</span>} />
      </Switch>
        </div>
    </div>
  );
}


export default withRouter(Breadcrumbs);