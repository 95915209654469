import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { nameFormatter, NameFormats } from '../../../app/utils';
import { useDispatch } from 'react-redux';
import { showErrors } from '../../../store/exception/actions';
import SvgIcon from '../svg-icon/SvgIcon';
import Slidebar from '../slidebar/Slidebar';
import {getApiLegalEntity} from "../../../services/ApiService";

interface LegalEntityAutocompleteProps {
    defaultText?: string,
    placeholder?: string,
    onChoose?: (option: LegalEntityAutocompleteOptionType) => void,
    readOnly?: boolean,
    ref?: any
}

export interface LegalEntityAutocompleteOptionType {
    value: number,
    text: string
}

const LegalEntityAutocomplete: React.FC<LegalEntityAutocompleteProps> = React.forwardRef((props: LegalEntityAutocompleteProps, ref) => {
    let { t, i18n } = useTranslation();

    let [isOpen, setOpen] = useState(false);
    let [isLoading, setLoading] = useState(false);
    let [inputValue, setInputValue] = useState(props.defaultText ? props.defaultText : '');
    let [legalEntitys, setLegalEntitys] = useState([] as LegalEntityAutocompleteOptionType[]);
    let [error, setError] = useState(false);
    let [legalEntityTimer, setLegalEntity] = useState({} as any);

    let inputRef = useRef({} as HTMLInputElement);

    useEffect(() => {
        setInputValue(props.defaultText ? props.defaultText : '');
    }, [props.defaultText])

    const optionClickHandler = (option: LegalEntityAutocompleteOptionType) => {
        setInputValue(option.text);
        setOpen(false);
        if (props.onChoose) props.onChoose(option);
    }
    

    const changeInput = () => {        
        props.onChoose && props.onChoose({} as LegalEntityAutocompleteOptionType);
        setInputValue(inputRef.current.value);
        if (inputRef.current.value.length > 2) {
            clearTimeout(legalEntityTimer);
            let name = inputRef.current.value;
            (()=>{
                setLegalEntity(setTimeout(() => {getLegalEntity(name)}, 800));
            })();
        } else {
            setLegalEntitys([] as LegalEntityAutocompleteOptionType[]);
            setOpen(false);
            setLoading(false);
        }
    }

    const missClickHandler = () => {
        if (isOpen) {
            setOpen(false)
        }
    };

    const getLegalEntity = async (nameValue: string) => {
        setError(false);
        setLoading(true);
        setLegalEntitys([] as LegalEntityAutocompleteOptionType[]);
        setOpen(true);


        try {
            let result = await getApiLegalEntity({ params: { Name: nameValue } });
            if ((result.config.params.Name == inputRef.current.value)) {
                const {data} = result.data;
                let findlegalEntitys = data.filter((item:any) => (item.name.ru.toLowerCase().indexOf(inputRef.current.value.toLowerCase()) !== -1) || (item.name.en.toLowerCase().indexOf(inputRef.current.value.toLowerCase()) !== -1));
                setLegalEntitys(findlegalEntitys.map((item: any) => { return { value: item.id, text: item.name[i18n.language as 'ru'] } }));
            };
        } catch (e) {
            setError(true);
        }

        setLoading(false);
    }

    useImperativeHandle(ref, () => ({
        clear: () => {
            setInputValue('');
        },
        value: inputRef.current.value
    }));

    return (
        <div className={'select ' + (isOpen ? 'active' : '')}>
            {isOpen ? <div className="miss-click-handler" onClick={missClickHandler} /> : ''}
            <input className="input" type="text" ref={inputRef} value={inputValue} onChange={changeInput} placeholder={props.placeholder} readOnly={props.readOnly} />
            <SvgIcon href={'#svg_icon_search'} className={'icon icon-search'} />
            <Slidebar className="select-content" style={{ zIndex: 20, maxHeight: '260px' }}>
                {legalEntitys.length > 0 ? legalEntitys.map((option, index) => {
                    return <div className="select-content-item" key={index} onClick={() => optionClickHandler(option)}>{option.text}</div>
                }) : null}
                {!error && !isLoading && legalEntitys.length === 0 ? <div className="select-content-item" >{t('filters.no_legalEntity_found')} </div>
                    : null}
                {error ? <div className="select-content-item" >{t('create_expense.search_error')} </div>
                    : null}
                {isLoading ? <div className="select-content-item" ><div className="lds-ellipsis" style={{ marginLeft: 'calc(50% - 30px)' }}><div></div><div></div><div></div><div></div></div></div>
                    : null}
            </Slidebar>
        </div>
    )
})

export default LegalEntityAutocomplete;