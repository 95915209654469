import React from 'react';
import ZNRFilters from 'app/pages/ZNR/ZNRFilters';
import AOFilter from 'app/pages/report/AOFilter';
import { Switch, Route } from 'react-router';
import JournalFilterReport from 'app/pages/journal/JournalFilterReport';
import JournalFilterExpense from 'app/pages/journal/JournalFilterExpense';
import JournalDetailReportFilter from 'app/pages/journal/JournalDetailReportFilter';
import JournalDetailExpenseFilter from 'app/pages/journal/JournalDetailExpenseFilter';
import AgreementFilter from 'app/pages/agreement/AgreementFilter';
import JournalDownloadsFilter from 'app/pages/journal/JournalDownloadsFilter';
import JournalDetailDownloadsFilter from 'app/pages/journal/JournalDetailDownloadsFilter';

const Filter: React.FC = () => {
    return (
        <Switch>
            <Route path='/ExpenseApplication/Detail/:id' children={null} />
            <Route path='/AdvanceReportApplication/Detail/:id' children={null} />
            <Route path='/AdvanceReportApplication/:status' component={AOFilter} />
            <Route path='/ExpenseApplication/:status' component={ZNRFilters}/>
            <Route path='/ExpenseApplication'  component={ZNRFilters} />
            <Route path='/AdvanceReportApplication' component={AOFilter} />
            <Route path='/Agreement/:status' component={AgreementFilter} />
            <Route path='/Agreement' component={AgreementFilter}/>
            <Route path='/Journal/AdvanceReportApplication/:id' component={JournalDetailReportFilter} />
            <Route path='/Journal/ExpenseApplication/:id' component={JournalDetailExpenseFilter} />
            <Route path='/Journal/Downloads/:id' component={JournalDetailDownloadsFilter} />
            <Route path='/Journal/AdvanceReportApplication' component={JournalFilterReport}/>
            <Route path='/Journal/ExpenseApplication' component={JournalFilterExpense}/>
            <Route path='/Journal/Downloads' component={JournalDownloadsFilter}/>
        </Switch>
    );
};

export default Filter;
