import GridHeaderCell, {SortOrder} from 'app/component/grid/GridHeaderCell';
import React from 'react';
import {GridOptions, HeaderOptions} from './Grid';

interface GridHeaderProps {
  options: GridOptions,
  onSort: (key: string, sortOrder: SortOrder) => void,
}

const GridHeader: React.FC<GridHeaderProps> = (props) => {
  const sortHandler = (key: string, sortOrder: SortOrder) => {
      props.onSort(key, sortOrder)
  };

  return (
      <div className="profile-calc-table-row" >
        {
          props.options.headers.map((header: HeaderOptions, index: number) => {
            return <GridHeaderCell key={header.key} onSort={sortHandler} header={header} last={props.options.isLastRowNarrow && index === props.options.headers.length - 1}/>
          })
        }
      </div>
  );
};

export default GridHeader;