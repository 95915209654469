import React from 'react';
import Postings from '../../component/postings/Postings';

interface AOPostingsProps {
    id: number
}

const AOPostings: React.FC<AOPostingsProps> = (props) => {
    return (
        <Postings isAdvance id={props.id}/>
    )
}

export default AOPostings;