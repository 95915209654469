import { BaseMapper } from './base.mapper';
import { CostCenterReport } from 'infrastructure/interfaces';
import { get } from 'lodash';

export class CostCenterReportMapper extends BaseMapper<CostCenterReport, any> {
  responseToEntity(response: any): CostCenterReport {
    return {
        id: get(response, 'id', 6),
        name: {
          ru: get(response, 'name.ru', 'Имя[STUB]'),
          en: get(response, 'name.en', 'Name[STUB]')
        },
        percent: get(response, 'percent', -1)
    };
  };

  responsesToEntitys(responses: any[]): CostCenterReport[] {
      return [...responses.map(item => this.responseToEntity(item))]
  };
}
