import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isNull, get} from 'lodash'
import Config, { ConfigTypes } from 'services/ConfigService';
//store
import {
    updateApplicationForExpenseDetail,
    clearApplicationForExpenseDetail,
    setAdditionalExpense,
    setApplicationForExpenseDetailDownloaded
} from 'store/applicationForExpense/actions';
import { loaderLock, loaderUnlock, updatePostings, setPostings, clearAttachmentList, updateAttachmentsExpense } from "store/common/actions";
import { throwException, showErrors } from "store/exception/actions";
//component
import ZNRHeader from './ZNRHeader';
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import ZNRButtonsControl from './ZNRButtonsControl';
import AttachmentFilesPanel from 'app/component/attachment-files-panel/AttachmentFilesPanel';
import ApplicationExpensesPanel from './ZNRExpensesPanel';
import ZNRPostings from './ZNRPostings';
import ZNRAgreement from './ZNRAgreement';
import AmountEditForm from 'app/component/amount-edit-form/AmountEditForm'
//modal
import DeleteExpenseApplicationModal from 'app/component/modal/DeleteExpenseApplicationModal';
import CreateExpenseModal from 'app/component/modal/CreateExpenseModal';
import EmployeeInformationModal from 'app/component/modal/EmployeeInformationModal';
//common
import {
    formatter,
    NameFormats,
    nameFormatter,
    toLocaleNumberFormatter,
    getCurrencieCode,
    subtract
} from 'app/utils';
import { statusStylesMap, statusNameMap, STATUS_TYPE } from 'infrastructure/enum/status-type.enum';
import { OCCUPATION_ROLE_TYPE } from 'infrastructure/enum/user-role.enum';
import { EXPENSE_TYPE } from 'infrastructure/enum/expense-type.enum';
import analitiks from 'services/AnaliticsService';
import { Expense, IOccupations} from 'infrastructure/interfaces';
import SubmitCustomModal from 'app/component/modal/SubmitCustomModal';
import { EXPENSE_APPLICATION_PROPETIES } from 'infrastructure/enum/object-properties.enum';
import PrintMenu from 'app/component/menu/PrintMenu';
import agreementService from 'services/entity/agreement.service';
import {
    getApplicationForExpenseDetail, getCommonAttachmentPermission,
    getIsApplicationForExpenseDetailDownloaded,
    getCommonUserDetail
} from "../../../../store/selectors";
import {
    postPaperWorks,
    updateApprovalsExpenseMarkAsReadById,
    updateApprovalsExpenseMarkAsUnreadById,
    updateExpensesCancelById, updatePaperWorks
} from "../../../../services/ApiService";

interface ZNRDetail extends RouteComponentProps<any> {
}

const ZNRDetail: React.FC<ZNRDetail> = props => {
    const { t, i18n } = useTranslation();
    const id = props.match.params.id;

    let [isOpenDeleteExpenseApplicationModal, setOpenDeleteExpenseApplicationModal] = useState(false); //открытие модалки удаления заявки
    let [isOpenEditRequestModal, setOpenEditRequestModal] = useState(false);                           //открытие модалки редактирования заявки
    let [isPushDeleteButtonInModal, setPushDeleteButtonInModal] = useState(false);
    let [isOpenEmployeeInfo, setOpenEmployeeInfo] = useState(false);
    let [isAppMode, setAppMode] = useState(true);
    let [isPostingsMode, setPostingsMode] = useState(false);
    let [isAgreementMode, setAgreementMode] = useState(false);
    let [isMenuOpen, setMenuOpen] = useState(false);
    let [isOpenPrintModal, setOpenPrintModal] = useState(false);
    let [canShowTakeInHand, setCanShowTakeInHand] = useState(null as unknown as boolean);
    let [isOpenTakeInHandModal, setIsOpenTakeInHandModal] = useState(false);
    let [transactionResponseStatus, setTransactionResponseStatus] = useState(0 as number);

    const applicationForExpenseDetail = useSelector(getApplicationForExpenseDetail);
    const isApplicationForExpenseDownloaded = useSelector(getIsApplicationForExpenseDetailDownloaded);
    const user = useSelector(getCommonUserDetail);
    const { isExpenseApplicationAttachmentPermission } = useSelector(getCommonAttachmentPermission);
    let [fieldAccess, setFieldAccess] = useState({
        advanceCashAmount: false,
        applicationType: false,
        approvals: false,
        arrivalToCity: false,
        approvedAmount: false,
        businessTripNumber: false,
        businessTarget: false,
        cancelZNR: false,
        departureFromCity: false,
        description: false,
        endOn: false,
        flowHistory: false,
        journeyNumber: false,
        number: false,
        orderNumber: false,
        personalDaysNumber: false,
        printingForms: false,
        startOn: false,
        status: false,
        totalAmount: false,
        transactions: false,
        updateZNR: false,
        vbsElement: false,
    });

    //const role = user.roleCode;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setApplicationForExpenseDetailDownloaded(false))
        }
    }, [])

    useEffect(() => {
        agreementService
            .setId(applicationForExpenseDetail ? applicationForExpenseDetail.id : 0)
            .setIsAdvance(false);
        if (applicationForExpenseDetail && (applicationForExpenseDetail.status == STATUS_TYPE.DRAFT || applicationForExpenseDetail.status == STATUS_TYPE.APPROVAL_REQUIRES)) {
            setCanShowTakeInHand(false);
            return
        }
        changeShowTakeInHand();
        return () => {
            setCanShowTakeInHand(null as unknown as boolean)
        }
    }, [
        (applicationForExpenseDetail ? applicationForExpenseDetail.id : 0),
        (applicationForExpenseDetail && applicationForExpenseDetail.paperWork ? applicationForExpenseDetail.paperWork.employeeId : undefined),
        (applicationForExpenseDetail ? applicationForExpenseDetail.status : undefined)]);

    useEffect(() => {
        dispatch(updateApplicationForExpenseDetail(id));
        window.scrollTo(0, 0);
        return () => {
            dispatch(clearApplicationForExpenseDetail());
            dispatch(clearAttachmentList());
        }
    }, [id]);

    useEffect(() => {
        if (applicationForExpenseDetail && isBookerOccupation()) {
            dispatch(updatePostings(id, 'ExpenseApplication', {}, setResponseStatus));
        } else {
            dispatch(setPostings({ transactions: [] }));
        }
        generateFieldsAccess();
        applicationForExpenseDetail && analitiks('ZNRDetail', {
            znrId: applicationForExpenseDetail.id,
            employeeId: user.id,
            occupationId: get(user.occupations.find(occupation => occupation.company.id === applicationForExpenseDetail.company.id) || user.occupations[0], 'occupationId'),
            companyId: applicationForExpenseDetail.company.id
        });
        dispatch(updateAttachmentsExpense(id, 'ExpenseApplication', get(applicationForExpenseDetail, 'expenses', [])));
        markAsRead();
    }, [applicationForExpenseDetail]);

    useEffect(() => {
        if (isPostingsMode) {
            dispatch(updatePostings(id, 'ExpenseApplication', {}));
        }
    }, [isPostingsMode]);

    const generateFieldsAccess = () => {
        if (applicationForExpenseDetail) {
            let fieldAccess = {
                advanceCashAmount: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ADVANCE_CASH_AMOUNT),//stubProperties.includes('ExpenseApplication_AdvanceCashAmount'),
                approvedAmount: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.APPROVED_AMOUNT),//stubProperties.includes('ExpenseApplication_ApprovedAmount'),
                approvals: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.APPROVALS),
                applicationType: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.APPLICATION_TYPE),//stubProperties.includes('ExpenseApplication_ApplicationType'),
                arrivalToCity: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ARRIVAL_TO_CITY),//stubProperties.includes('ExpenseApplication_ArrivalToCity'),
                businessTripNumber: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TRIP_NUMBER),//stubProperties.includes('ExpenseApplication_BusinessTripNumber'),
                businessTarget: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TARGET),//stubProperties.includes('ExpenseApplication_BusinessTarget'),
                cancelZNR: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.CANCEL_ZNR),
                description: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.DESCRIPTION),//stubProperties.includes('ExpenseApplication_Description'),
                departureFromCity: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.DEPARTURE_FROM_CITY),//stubProperties.includes('ExpenseApplication_DepartureFromCity'),
                endOn: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.END_ON),//stubProperties.includes('ExpenseApplication_EndOn'),
                flowHistory: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.FLOW_HISTORY),//stubProperties.includes('ExpenseApplication_FlowHistory'),
                journeyNumber: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.JOURNEY_NUMBER),//stubProperties.includes('ExpenseApplication_JourneyNumber'),
                number: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.NUMBER),//stubProperties.includes('ExpenseApplication_Number'),
                orderNumber: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ORDER_NUMBER),//stubProperties.includes('ExpenseApplication_OrderNumber'),
                personalDaysNumber: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.PERSONAL_DAYS_NUMBER),//stubProperties.includes('ExpenseApplication_PersonalDaysNumber'),
                printingForms: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.PRINTING_FORMS),
                startOn: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.START_ON),//stubProperties.includes('ExpenseApplication_StartOn'),
                status: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.STATUS),//stubProperties.includes('ExpenseApplication_Status'),
                transactions: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.TRANSACTIONS),
                totalAmount: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.TOTAL_AMOUNT),//stubProperties.includes('ExpenseApplication_TotalAmount'),
                updateZNR: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.UPDATE_ZNR),
                vbsElement: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.VBS_ELEMENT)//stubProperties.includes('ExpenseApplication_VbsElement'),
            }
            setFieldAccess(fieldAccess);
            return;
        }
        return;
    }

    const groupCode = ['Avia', 'Train', 'Hotel', 'Transfer'];

    const additionalExpenseCalculate = (): number => {
        let companyId = applicationForExpenseDetail ? applicationForExpenseDetail.company.id : 0;
        let advanceСalculationMethod = Config.getConfigToCompany(ConfigTypes.ADVANCE_CALCULATION_METHOD, companyId);

        if (!!advanceСalculationMethod) {
            let additionalExpense = 0;
            if (applicationForExpenseDetail) {
                applicationForExpenseDetail.expenses.forEach(expens => {
                    if (expens.external && groupCode.includes(expens.expenseType.groupCode)) {
                        additionalExpense += expens.amountRub;
                    }
                })
                return subtract(applicationForExpenseDetail.totalAmount, additionalExpense);
            }
            return additionalExpense;
        } else {
            let additionalExpense = 0;
            applicationForExpenseDetail && applicationForExpenseDetail.expenses.forEach(expens => {
                if (expens.advanceCashRequired && !expens.external && !groupCode.includes(expens.expenseType.groupCode)) {
                    additionalExpense += expens.amountRub;
                }
            });
            return additionalExpense;
        }
    }

    const additionalExpense = additionalExpenseCalculate();
    dispatch(setAdditionalExpense(additionalExpense));

    //Удаление детальной заявки
    if (isPushDeleteButtonInModal) {
        dispatch(loaderLock());

        updateExpensesCancelById(id)//TODO вынести в api
            .then((response) => {
                setPushDeleteButtonInModal(false);
                setOpenDeleteExpenseApplicationModal(false);
                props.history.push('/ExpenseApplication');
            })
            .catch((e) => {
                dispatch(throwException({
                    code: 'ExpenseApplication_cancel',
                    message: `ExpenseApplication_cancel ${e.response.status}`
                }));
                setPushDeleteButtonInModal(false);
                setOpenDeleteExpenseApplicationModal(false);
                props.history.push('/ExpenseApplication');
            })
            .finally(() => {
                dispatch(loaderUnlock());
            });
    }

    const markAsRead = async() => {
        if (applicationForExpenseDetail && applicationForExpenseDetail.status == STATUS_TYPE.ON_APPROVAL) {
            await updateApprovalsExpenseMarkAsReadById(applicationForExpenseDetail.id);
        }
    }

    const markAsUnread = async() => {
        if (applicationForExpenseDetail && applicationForExpenseDetail.status == STATUS_TYPE.ON_APPROVAL) {
            await updateApprovalsExpenseMarkAsUnreadById(applicationForExpenseDetail.id);
        }
    }

    const togglePrintModal = () => {
        setOpenPrintModal(!isOpenPrintModal);
        if (isMenuOpen) togglePopup();
    }

    const toggleEmployeeInfo = () => {
        setOpenEmployeeInfo(!isOpenEmployeeInfo);
    };

    const clickEditRequestModalHandler = () => {
        if (isMenuOpen) togglePopup();
        setOpenEditRequestModal(!isOpenEditRequestModal);
        // if (applicationForExpenseDetail) {
        //     if (applicationForExpenseDetail.status === STATUS_TYPE.APPROVED || applicationForExpenseDetail.status === STATUS_TYPE.ON_APPROVAL) {
        //         dispatch(showErrors({
        //             code: '',
        //             message: t('status_error_message.status_edit_EA', { status: t(`status.${statusNameMap[applicationForExpenseDetail.status as STATUS_TYPE]}`) })
        //         }));
        //     } else {
        //         if (applicationForExpenseDetail.status === STATUS_TYPE.POSTED || applicationForExpenseDetail.status === STATUS_TYPE.REPORT_PREPARED || applicationForExpenseDetail.status === STATUS_TYPE.EXECUTED) {
        //             dispatch(showErrors({
        //                 code: '',
        //                 message: t('status_error_message.status__edit_EA', { status: t(`status.${statusNameMap[applicationForExpenseDetail.status as STATUS_TYPE]}`) })
        //             }));
        //         } else {
        //             setOpenEditRequestModal(!isOpenEditRequestModal);
        //         }
        //     }
        // }
    };

    const isExternal = () => {
        if (applicationForExpenseDetail) {
            const expenses = [...applicationForExpenseDetail.expenses]
            const hasExternal = expenses.filter((expense: Expense) => expense.external == true)//(x => !arr2.some(y => x.name === y.name));
            return hasExternal.length > 0;
        }
        else {
            return false;
        }
    }

    const clickDeleteExpenseApplicationModalHandler = () => {
        if (isMenuOpen) togglePopup();
        setOpenDeleteExpenseApplicationModal(!isOpenDeleteExpenseApplicationModal);
        // if (applicationForExpenseDetail) {
        //     if (applicationForExpenseDetail.status === STATUS_TYPE.APPROVED || applicationForExpenseDetail.status === STATUS_TYPE.ON_APPROVAL) {
        //         dispatch(showErrors({
        //             code: '',
        //             message: t('status_error_message.status_delete_EA', { status: t(`status.${statusNameMap[applicationForExpenseDetail.status as STATUS_TYPE]}`) })
        //         }));
        //     } else {
        //         if (applicationForExpenseDetail.status === STATUS_TYPE.POSTED || applicationForExpenseDetail.status === STATUS_TYPE.REPORT_PREPARED || applicationForExpenseDetail.status === STATUS_TYPE.EXECUTED) {
        //             dispatch(showErrors({
        //                 code: '',
        //                 message: t('status_error_message.status__delete_EA', { status: t(`status.${statusNameMap[applicationForExpenseDetail.status as STATUS_TYPE]}`) })
        //             }));
        //         } else
        //             if (isExternal()) {
        //                 dispatch(showErrors({
        //                     code: '',
        //                     message: t('status_error_message.status___delete_EA')
        //                 }));
        //                 return;
        //             } else {
        //                 setOpenDeleteExpenseApplicationModal(!isOpenDeleteExpenseApplicationModal);
        //             }
        //     }
        // }
    };

    const togglePopup = () => {
        setMenuOpen(!isMenuOpen)
    };

    const notPaperWorkEmployee = () => {
        return applicationForExpenseDetail && applicationForExpenseDetail.paperWork.employeeId != user.id;
    }

    const userInAgreementList = async () => {
        let agreementList = await agreementService.getList();

        return agreementList && agreementList.approvalTiers.flatMap(item => item.employees).filter(item => item.id == user.id).length > 0;
    }

    const checkedListApproved = async () => {
        let currentTier = await agreementService.getCurrentTier();
        let visibleTakeInHand: boolean = false;
        if (currentTier) {
            currentTier.employees.forEach((item) => {
                if (item.id === user.id) {
                    visibleTakeInHand = true
                }
            })
        }
        return visibleTakeInHand
    }

    const changeShowTakeInHand = async () => {
        if (applicationForExpenseDetail && applicationForExpenseDetail.status != STATUS_TYPE.ON_APPROVAL) {
            setCanShowTakeInHand(false);
            return
        }

        let currentComposition = await agreementService.getCurrentComposition();
        let currentTier = await agreementService.getCurrentTier();

        if (await userInAgreementList() === false) {
            setCanShowTakeInHand(false);
            return
        }

        if (currentComposition == "All") {
            setCanShowTakeInHand(false);
            return
        } //Если composition All кнопка взять в работу не отображается.

        if (currentComposition == "Any" && currentTier && currentTier.employees.length == 1) {
            setCanShowTakeInHand(false);
            return
        } //Если composition Any и согласующий один кнопка не отображается

        if (currentComposition == "Any" && currentTier && currentTier.employees.length > 1 && applicationForExpenseDetail && notPaperWorkEmployee() && await checkedListApproved()) {
            setCanShowTakeInHand(true);
            return
        }//Если composition Any и согласующих 2 и более и для ЗНР/АО paperwokr null, то кнопка отображается
        //Если composition Any и согласующих 2 и более и для ЗНР/АО paperwokr заполнено, то кнопка отображается только для тех пользователь у которых paperwokr не совпадает с текущим пользователем .

        setCanShowTakeInHand(false)
    }

    const sendRequestToTakeInHand = async () => {
        dispatch(loaderLock());
        const requestParams = {
            objectType: "ExpenseApplication",
            objectId: applicationForExpenseDetail ? applicationForExpenseDetail.id : 0,
        }
        let result = await postPaperWorks(requestParams);
        if (result.headers.success) {
            dispatch(updateApplicationForExpenseDetail(id));
            setIsOpenTakeInHandModal(false);
            dispatch(loaderUnlock());
            return;
        } else {
            togleIsOpenTakeInHandModal();
            dispatch(loaderUnlock());
            dispatch(showErrors({ code: 'takeInHand_action', message: 'Не удалось взять в работу' }))
        }
        dispatch(loaderUnlock());
    }

    const togleIsOpenTakeInHandModal = () => {
        setIsOpenTakeInHandModal(!isOpenTakeInHandModal)
    }

    const takeInHand = () => {
        if (applicationForExpenseDetail && applicationForExpenseDetail.paperWork.employeeId == 0) {
            sendRequestToTakeInHand()
        } else {
            togleIsOpenTakeInHandModal()
        }

    }

    const finishHand = async() => {
        const requestParams = {
            objectType: "ExpenseApplication",
            objectId: applicationForExpenseDetail ? applicationForExpenseDetail.id : 0,
        }
        await updatePaperWorks(requestParams);
        dispatch(loaderLock());
        dispatch(updateApplicationForExpenseDetail(id));
        dispatch(loaderUnlock());
    }

    const isBookerOccupation = (): boolean => {
        let occupations = user.occupations.map((occupation: IOccupations) => occupation.roles).flat().map((item) => item.code);
        return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
    }

    const setResponseStatus = (status: number) => {
        setTransactionResponseStatus(status);
    }


    const showMainForm = () => {
        return (applicationForExpenseDetail ?
            <Fragment>
                <div className="request">
                    <div className="request-item" style={{position: 'relative'}}>
                        <div className="request-item-row">
                            <div
                                className={`request-item-num break-spaces`}>
                                <span className={`request-item-type ${fieldAccess.applicationType ? '' : 'd-none'}`} style={{fontSize: '13px'}}>
                                {t('request_detail.requestType')}: <b>{applicationForExpenseDetail.applicationType.name[i18n.language as 'en' | 'ru']}</b></span> ({`${t('request_detail.created')}: ${nameFormatter(applicationForExpenseDetail.createdByEmployee.name, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language)} ${formatter('DD.MM.YYYY', i18n.language, applicationForExpenseDetail.createdAt)}${applicationForExpenseDetail.postingDate ? `, \n${t('request_detail.posted')}: ${formatter('DD.MM.YYYY', i18n.language, applicationForExpenseDetail.postingDate)}` : ''}`})
                            </div>
                            <a className="link-more-controls" onClick={togglePopup}>
                                <div className="icon icon-more">
                                    <svg>
                                        <use x="0" y="0" href="#svg_icon_more" />
                                    </svg>
                                </div>
                            </a>
                            {isMenuOpen ? <div className="miss-click-handler" onClick={togglePopup} /> : ''}
                                <div className="request-item-controls" style={{ display: isMenuOpen ? 'block' : '', zIndex: 100 }}>
                                    {applicationForExpenseDetail.paperWork.id ? <div className={`inHandWrapper`}>{t('request_detail.in_hand')}: {nameFormatter(applicationForExpenseDetail.paperWork.employeeName, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language)}</div> : null}
                                    {fieldAccess.printingForms ? <a onClick={togglePrintModal} className={'pointer'}>
                                        <SvgIcon className="icon icon-download" href="#svg_icon_print" />
                                        <span> {t('report_detail.print')}</span>
                                    </a> : null}
                                    {fieldAccess.updateZNR ? <a className={`js-open-modal pointer`} data-modal-id="edit-note"
                                        onClick={clickEditRequestModalHandler}>
                                        <SvgIcon className="icon icon-edit" href="#svg_icon_edit" />
                                        <span> {t('report_detail.edit')}</span>
                                    </a> : null}
                                    {fieldAccess.cancelZNR ? <a className={`delete js-open-modal pointer`} data-modal-id="note-delete"
                                        onClick={clickDeleteExpenseApplicationModalHandler}>
                                        <SvgIcon className="icon icon-remove" href="#svg_icon_remove" />
                                        <span> {t('report_detail.delete')}</span>
                                    </a> : null}
                                </div>
                            <PrintMenu id={id} isOpen={isOpenPrintModal} expenseApplicationId={applicationForExpenseDetail.id} onClose={togglePrintModal} companyId={applicationForExpenseDetail.company.id} />
                        </div>
                        <div className="request-item-row" style={{borderBottom: '1px solid #98A2A8', paddingBottom: '17px'}}>
                            <div className="request-item-name">{nameFormatter(applicationForExpenseDetail.assigneeEmployee.name, NameFormats.NAME_WITH_LASTNAME, i18n.language)}</div>
                            {fieldAccess.status ? <div
                                className={`request-list-item-status ${statusStylesMap[applicationForExpenseDetail.status as STATUS_TYPE]}`}>
                                {t('request_detail.status')}: {t(`statusZNR.${statusNameMap[applicationForExpenseDetail.status as STATUS_TYPE]}`)}<span></span>
                            </div> : null}
                        </div>
                        <ZNRHeader
                            applicationForExpenseDetail={applicationForExpenseDetail}
                            toggleEmployeeInfo={toggleEmployeeInfo}
                        />
                    </div>
                    <ApplicationExpensesPanel list={applicationForExpenseDetail.expenses} id={id} />
                    {/* {applicationForExpenseDetail && applicationForExpenseDetail.paperWork.employeeId == user.id ? <div className="btn btn_black pointer" onClick={finishHand}>{t('request_detail.request_button_controls.finish_the_work')}</div> : null} */}
                    {canShowTakeInHand ?
                        <div className={`request-bottom-controls tx-center`}>
                            <div className="btn btn_black pointer" onClick={takeInHand}>{t('request_detail.request_button_controls.take_in_hand')}
                            </div>
                        </div> : null}

                    {!canShowTakeInHand && <div>
                        <ZNRButtonsControl id={id} isPostingsMode={isPostingsMode} setPostingsMode={() => setPostingsMode(true)} />
                    </div>}
                    {applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ATTACHMENTS) ? <AttachmentFilesPanel id={id} /> : null}
                </div>
                <CreateExpenseModal editId={id} isOpen={isOpenEditRequestModal}
                    onClose={() => setOpenEditRequestModal(false)} />
                <DeleteExpenseApplicationModal
                    isOpen={isOpenDeleteExpenseApplicationModal}
                    onClose={() => setOpenDeleteExpenseApplicationModal(false)}
                    onDelete={() => setPushDeleteButtonInModal(true)}
                    question={t('delete_expense_application_modal.requestQuestion')} />
                <EmployeeInformationModal isOpen={isOpenEmployeeInfo} onClose={toggleEmployeeInfo} id={id} isZNR={true} />
                <SubmitCustomModal isOpen={isOpenTakeInHandModal} title={t('request_detail.in_hand_request', { user: nameFormatter(applicationForExpenseDetail.paperWork.employeeName, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language) })} onClose={togleIsOpenTakeInHandModal} onSubmit={sendRequestToTakeInHand} />
            </Fragment> : null
        )
    };

    const showPostingForm = () => {
        return (
            <Fragment>
                <ZNRPostings id={id} />
                <ZNRButtonsControl id={id} isPostingsMode={isPostingsMode} setPostingsMode={() => setPostingsMode(true)} />
            </Fragment>
        )
    };

    const showAgreementForm = () => {
        return (
            <Fragment>
                <ZNRAgreement id={id} />
                {/* <ZNRButtonsControl id={id} isPostingsMode={isPostingsMode} setPostingsMode={() => setPostingsMode(true)} /> */}
            </Fragment>
        )
    };

    const showAppMode = () => {
        setAppMode(true);
        setPostingsMode(false);
        setAgreementMode(false);
    };

    const showPostingsMode = () => {
        setAppMode(false);
        setPostingsMode(true);
        setAgreementMode(false);
    };

    const showAgreementMode = () => {
        setAppMode(false);
        setPostingsMode(false);
        setAgreementMode(true);
    };

    const showTabMenu = () => {
        let tabArray = [<li onClick={showAppMode} className={`${isAppMode ? 'active' : ''} pointer`}><a>{t('report_detail.app')}</a></li>];

        if (fieldAccess.transactions) {
            tabArray.push(<li onClick={showPostingsMode} className={`${isPostingsMode ? 'active' : ''} pointer`}><a>{t('report_detail.postings')}</a></li>);
        }

        if (fieldAccess.approvals) {
            tabArray.push(<li onClick={showAgreementMode} className={`${isAgreementMode ? 'active' : ''} pointer`}><a>{t('report_detail.agreement')}</a></li>);
        }

        if (tabArray.length > 1) {
            return <div className="request-menu">
                <ul>
                    {tabArray}
                </ul>
            </div>
        } else {
            return null
        }
    };

    return applicationForExpenseDetail ?
        <Fragment>
            {showTabMenu()}
            {isAppMode && showMainForm()}
            {isPostingsMode && showPostingForm()}
            {isAgreementMode && showAgreementForm()}
        </Fragment> :
        <Fragment>
            {isApplicationForExpenseDownloaded && <div className="request-list empty">
                <div className="request-list-empty">
                    <img src="/img/empty-img.png"/>
                    <div
                        style={{width: 'inherit'}}>{t('request_detail.not_found', {user: user.name[i18n.language as 'en' | 'ru'].split(' ')[0]})}</div>
                </div>
            </div>}
        </Fragment>
};

interface RequestCost {
    advanceCashAmount: number,
    totalAmount: number,
    approvedAmount: number | string,
    expenses: any[],
}

export const RequestCost: React.FC<RequestCost> = props => {
    const { t } = useTranslation();

    const user = useSelector(getCommonUserDetail);
    const applicationForExpenseDetail = useSelector(getApplicationForExpenseDetail);

    let [fieldAccess, setFieldAccess] = useState({
        number: false,
        applicationType: false,
        orderNumber: false,
        journeyNumber: false,
        businessTripNumber: false,
        businessTarget: false,
        description: false,
        vbsElement: false,
        departureFromCity: false,
        arrivalToCity: false,
        startOn: false,
        endOn: false,
        personalDaysNumber: false,
        advanceCashAmount: false,
        advanceCashAmountUpdate: false,
        additionalAmountExpenses: false,
        approvedAmount: false,
        totalAmount: false,
        flowHistory: false,
        status: false,
    });

    const { totalAmount, expenses } = props;
    const groupCode = [EXPENSE_TYPE.AVIA, EXPENSE_TYPE.TRAIN, EXPENSE_TYPE.BUILDING, EXPENSE_TYPE.TRANSFER];

    useEffect(() => {
        generateFieldsAccess();
    }, [applicationForExpenseDetail])

    const generateFieldsAccess = () => {
        if (applicationForExpenseDetail) {
            let fieldAccess = {
                number: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.NUMBER),//stubProperties.includes('ExpenseApplication_Number'),
                applicationType: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.APPLICATION_TYPE),//stubProperties.includes('ExpenseApplication_ApplicationType'),
                orderNumber: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ORDER_NUMBER),//stubProperties.includes('ExpenseApplication_OrderNumber'),
                journeyNumber: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.JOURNEY_NUMBER),//stubProperties.includes('ExpenseApplication_JourneyNumber'),
                businessTripNumber: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TRIP_NUMBER),//stubProperties.includes('ExpenseApplication_BusinessTripNumber'),
                businessTarget: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TARGET),//stubProperties.includes('ExpenseApplication_BusinessTarget'),
                description: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.DESCRIPTION),//stubProperties.includes('ExpenseApplication_Description'),
                vbsElement: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.VBS_ELEMENT),//stubProperties.includes('ExpenseApplication_VbsElement'),
                departureFromCity: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.DEPARTURE_FROM_CITY),//stubProperties.includes('ExpenseApplication_DepartureFromCity'),
                arrivalToCity: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ARRIVAL_TO_CITY),//stubProperties.includes('ExpenseApplication_ArrivalToCity'),
                startOn: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.START_ON),//stubProperties.includes('ExpenseApplication_StartOn'),
                endOn: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.END_ON),//stubProperties.includes('ExpenseApplication_EndOn'),
                personalDaysNumber: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.PERSONAL_DAYS_NUMBER),//stubProperties.includes('ExpenseApplication_PersonalDaysNumber'),
                approvedAmount: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.APPROVED_AMOUNT),//stubProperties.includes('ExpenseApplication_ApprovedAmount'),
                advanceCashAmount: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ADVANCE_CASH_AMOUNT),//stubProperties.includes('ExpenseApplication_AdvanceCashAmount'),
                advanceCashAmountUpdate: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ADVANCE_CASH_AMOUNT_UPDATE),
                additionalAmountExpenses: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.ADDITIONAL_AMOUNT_EXPENSES),
                totalAmount: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.TOTAL_AMOUNT),//stubProperties.includes('ExpenseApplication_TotalAmount'),
                flowHistory: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.FLOW_HISTORY),//stubProperties.includes('ExpenseApplication_FlowHistory'),
                status: applicationForExpenseDetail.properties.includes(EXPENSE_APPLICATION_PROPETIES.STATUS),//stubProperties.includes('ExpenseApplication_Status'),
            }
            setFieldAccess(fieldAccess);
            return;
        }
        return;
    }

    const additionalExpenseCalculate = (): number => {
        let companyId = applicationForExpenseDetail ? applicationForExpenseDetail.company.id : 0;
        let advanceСalculationMethod = Config.getConfigToCompany(ConfigTypes.ADVANCE_CALCULATION_METHOD, companyId);

        if (!!advanceСalculationMethod) {
            let additionalExpense = 0;
            expenses.forEach(expens => {
                if (expens.external && groupCode.includes(expens.expenseType.groupCode)) {
                    additionalExpense += expens.amountRub;
                }
            })
            return subtract(totalAmount, additionalExpense);
        } else {
            let additionalExpense = 0;
            expenses.forEach(expens => {
                if (expens.advanceCashRequired && !expens.external) {// && !groupCode.includes(expens.expenseType.groupCode)) {
                    additionalExpense += expens.amountRub
                }
            })
            return additionalExpense
        }

    }

    const isBookerOccupation = (): boolean => {
        let occupations = user.occupations.map((occupation: IOccupations) => occupation.roles).flat().map((item) => item.code);
        return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
    }

    const isShowAmountEdit = () => {
        let companyId = applicationForExpenseDetail ? applicationForExpenseDetail.company.id : 0;
        let advanceСalculationMethod = Config.getConfigToCompany(ConfigTypes.ADVANCE_CALCULATION_METHOD, companyId);

        if (!!advanceСalculationMethod) {
            if (applicationForExpenseDetail && isBookerOccupation()) {
                return applicationForExpenseDetail.assigneeEmployee.id == user.id && (!(applicationForExpenseDetail.status == STATUS_TYPE.ON_APPROVAL ||
                    applicationForExpenseDetail.status == STATUS_TYPE.APPROVED ||
                    applicationForExpenseDetail.status == STATUS_TYPE.POSTED ||
                    applicationForExpenseDetail.status == STATUS_TYPE.CANCELLED));
            }
            return applicationForExpenseDetail && (!(applicationForExpenseDetail.status == STATUS_TYPE.ON_APPROVAL ||
                applicationForExpenseDetail.status == STATUS_TYPE.APPROVED ||
                applicationForExpenseDetail.status == STATUS_TYPE.POSTED ||
                applicationForExpenseDetail.status == STATUS_TYPE.CANCELLED));
        }
        return false;
    }

    return (
        <div className="request-item-row space-between cost-row" style={{ flexWrap: 'wrap' }}>
            {
                !isNull(props.expenses) ?
                    <div className="request-item-block cost" >
                        <span>{t('request_detail.request_cost.additionalExpense')}</span>
                        <span className="money" style={{marginTop: '4px'}}>{toLocaleNumberFormatter(additionalExpenseCalculate())} {getCurrencieCode('rub')}</span>
                    </div>
                    : null
            }


            {
                !isNull(props.totalAmount) ?
                    <div className={`request-item-block cost ${fieldAccess.totalAmount ? '' : 'd-none'}`}>
                        <span>{t('request_detail.request_cost.commonAmountOnRequest')}</span>
                        <span className="money" style={{marginTop: '4px'}}>{toLocaleNumberFormatter(props.totalAmount)} {getCurrencieCode('rub')}</span>
                    </div>
                    : null
            }
            {
                !isNull(props.advanceCashAmount) ?
                    <div className={`request-item-block cost ${fieldAccess.advanceCashAmount ? '' : 'd-none'}`} >
                        <div style={{display: 'flex'}}>
                            <span>{t('request_detail.request_cost.advanceAmount')}</span>
                            {isShowAmountEdit() ? <div style={{marginLeft: '-12px'}}><AmountEditForm></AmountEditForm></div> : ''}
                        </div>
                        <span className="money" style={{marginTop: '4px'}}>{toLocaleNumberFormatter(props.advanceCashAmount)} {getCurrencieCode('rub')}</span>
                    </div>
                    : null
            }

        </div>
    )
};

export default withRouter(ZNRDetail);
