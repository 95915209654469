import React, { useState } from "react";
import { Expense } from "infrastructure/interfaces/index";
import { EXPENSE_TYPE } from "infrastructure/enum/expense-type.enum";
import ApplicationExpensesItem from "./ApplicationExpensesItem";

interface WrapperApplicationExpensesItemProps {
  id?: number;
  expenses: any;
  setExpenseId: Function;
  openDeleteExpenseModal: (isOpen: boolean) => void;
  openEditExpenseModal: (isOpen: boolean) => void;
  isWidget?: boolean;
}

const WrapperApplicationExpensesItem: React.FC<
  WrapperApplicationExpensesItemProps
> = (props) => {
  let [isShowAll, setIsShowAll] = useState(false);
  const isCanShowAll =
    props.expenses[0].expenseType.groupCode === EXPENSE_TYPE.AVIA &&
    props.expenses.length > 1;
  const clickHandler = () => {
    setIsShowAll(!isShowAll);
  };
  if (isShowAll) {
    let index = 0;
    return props.expenses.map((expense: Expense) => {
      index++;
      return (
        <ApplicationExpensesItem
          key={expense.id}
          clickHandler={clickHandler}
          isShowAll={isShowAll}
          isCanShowAll={isCanShowAll}
          index={index}
          id={expense.id}
          setExpenseId={props.setExpenseId}
          openDeleteExpenseModal={props.openDeleteExpenseModal}
          openEditExpenseModal={props.openEditExpenseModal}
          expense={expense}
          original={index === props.expenses.length}
          isWidget={props.isWidget}
        />
      );
    });
  } else {
    return (
      <ApplicationExpensesItem
        key={props.expenses[0].id}
        clickHandler={clickHandler}
        isShowAll={isShowAll}
        isCanShowAll={isCanShowAll}
        index={1}
        id={props.expenses[0].id}
        setExpenseId={props.setExpenseId}
        openDeleteExpenseModal={props.openDeleteExpenseModal}
        openEditExpenseModal={props.openEditExpenseModal}
        expense={props.expenses[0]}
        isWidget={props.isWidget}
      />
    );
  }
};

export default WrapperApplicationExpensesItem;
