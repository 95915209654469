import React from 'react';
import Postings from 'app/component/postings/Postings';
import {RouteComponentProps, withRouter} from 'react-router';

interface JournalDetailExpenseProps extends RouteComponentProps<any> {
}

const JournalDetailExpense:React.FC<JournalDetailExpenseProps> = (props) => {

    const id = props.match.params.id;

    return (
        <Postings id={id} isJournal={true}/>
    )
}

export default withRouter(JournalDetailExpense);