import React, {useEffect, useState} from 'react';
import './Menu.css';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store/reducers';
import { updateJournalIsArchive, updateJournalList} from 'store/journal/actions';
import { FilterParams } from 'infrastructure/interfaces';
import { setJournalIsArchive } from 'store/journal/actions'
import { updateAgreementList } from 'store/agreement/actions';
import {setFilterParams, updateMenuFieldPermissions} from 'store/common/actions';
import {MENU_FIELD_PERMISSIONS} from "../../../infrastructure/enum/field-permissions.enum";

interface StatusFilter {
  text: {
    en: string,
    ru: string
  },
  value: string,
  code: string
}

const Menu: React.FC = () => {
  const { t, i18n } = useTranslation();
  let [openRequestSubmenu, setOpenRequestSubmenu] = useState(false);
  let [openExpensesSubmenu, setOpenExpensesSubmenu] = useState(false);
  let [openStatusSubmenu, setOpenStatusSubmenu] = useState(false);
  let [openJournalSubmenu, setOpenJournalSubmenu] = useState(false);
  let [isArchiveActive, setIsArchiveActive] = useState(false);
  let [openAgreementSubmenu, setOpenAgreementSubmenu] = useState(false);
  let [openReportSubmenu, setOpenReportSubmenu] = useState(false);

  let menuFieldPermissions = useSelector((state: ApplicationState) => state.common.menuFieldPermissions);
  const journalFieldPermissions = useSelector((state: ApplicationState) => state.journal.fieldPermissions);
  const journalIsArchive = useSelector((state: ApplicationState) => state.journal.isArchive);

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(updateMenuFieldPermissions());
  }, [])

  const checkOpenRequestSubmenu = (match = {}) => {
    setOpenRequestSubmenu(!!match);
    return !!match;
  }

  const checkOpenExpensesSubmenu = (match = {}) => {
    setOpenExpensesSubmenu(!!match);
    return !!match;
  }

  const checkOpenStatusSubmenu = (match = {}) => {
    setOpenStatusSubmenu(!!match);
    return !!match;

  }
  const checkOpenJournalSubmenu = (match = {}, location: any) => {
    let isJournalPageOpened = location.pathname.indexOf('/Journal') !== -1;
    if (isJournalPageOpened) {
     setOpenJournalSubmenu((journalFieldPermissions && journalFieldPermissions.includes('JournalArchive')) || journalIsArchive)
    } else {
      setOpenJournalSubmenu(false);
    }
    return isJournalPageOpened;
  }

  const checkOpenReportsSubmenu = (match = {}, location: any) => {
    let check = location.pathname.indexOf('/Reports') !== -1;
    setOpenReportSubmenu(check);
    return check;
  }

  const checkOpenAgreementSubmenu = (match = {}) => {
    setOpenAgreementSubmenu(!!match);
    return !!match;
  }

  let configuredStatuses: StatusFilter[] = [
    {
      text: {
        ru: 'Черновик',
        en: 'Draft'
      },
      value: '0',
      code: 'draft'
    },
    {
      text: {
        ru: 'Требует согласования',
        en: 'Approval Requires'
      },
      value: '1',
      code: 'approval_requires'
    },
    {
      text: {
        ru: 'На согласовании',
        en: 'On Approval'
      },
      value: '2',
      code: 'on_approval'
    },
    {
      text: {
        ru: 'Согласован',
        en: 'Approved'
      },
      value: '3',
      code: 'approved'
    },
    {
      text: {
        ru: 'Не согласован',
        en: 'Not Approved'
      },
      value: '5',
      code: 'not_approved'
    },
    {
      text: {
        ru: 'Отозван',
        en: 'Revoked'
      },
      value: '4',
      code: 'revoked'
    },
    {
      text: {
        ru: 'Проведен',
        en: 'Posted'
      },
      value: '6',
      code: 'posted'
    },
    {
      text: {
        ru: 'Отменен',
        en: 'Cancelled'
      },
      value: '7',
      code: 'cancelled'
    },
    {
      text: {
        ru: 'На доработке',
        en: 'On Revision'
      },
      value: '9',
      code: 'on_revision'
    }
  ]

  const configuredStatusesExpenses: StatusFilter[] = [
    {
      text: {
        ru: 'Черновик',
        en: 'Draft'
      },
      value: '0',
      code: 'draft'
    },
    {
      text: {
        ru: 'Требует согласования',
        en: 'Approval Requires'
      },
      value: '1',
      code: 'approval_requires'
    },
    {
      text: {
        ru: 'На согласовании',
        en: 'On Approval'
      },
      value: '2',
      code: 'on_approval'
    },
    {
      text: {
        ru: 'Согласована',
        en: 'Approved'
      },
      value: '3',
      code: 'approved'
    },
    {
      text: {
        ru: 'Не согласована',
        en: 'Not Approved'
      },
      value: '4',
      code: 'not_approved'
    },
    {
      text: {
        ru: 'Отозвана',
        en: 'Revoked'
      },
      value: '5',
      code: 'revoked'
    },
    {
      text: {
        ru: 'Проведена',
        en: 'Posted'
      },
      value: '6',
      code: 'posted'
    },
    {
      text: {
        ru: 'Отменена',
        en: 'Cancelled'
      },
      value: '9',
      code: 'cancelled'
    },
    {
      text: {
        ru: 'На доработке',
        en: 'On Revision'
      },
      value: '10',
      code: 'on_revision'
    },

  ]

  const inHand = [
    {
      text: {
        ru: 'Новые',
        en: 'New'
      },
      value: '0',
      code: 'new'
    },
    {
      text: {
        ru: 'У меня в работе',
        en: 'In my hand'
      },
      value: '1',
      code: 'in_my_hand'
    },
    {
      text: {
        ru: 'У других в работе',
        en: 'In others hand'
      },
      value: '2',
      code: 'in_others_hand'
    },
  ]

  const exspenseNavLinkClickHandler = (value: string) => {
    let params: FilterParams = {
      Status: [value]
  }
    dispatch(setFilterParams(params));
  }

  const AONavLinkClickHandler = (value: string) => {
    let params: FilterParams = {
      Status: [value]
  }
    dispatch(setFilterParams(params));
  }

  const journalNavLinkClickHandler = () => {
    setIsArchiveActive(false)
    dispatch(updateJournalIsArchive(false))
    dispatch(updateJournalList('AdvanceReportApplication', false));
  }

  const archiveNavLinkClickHandler = () => {
    setIsArchiveActive(true)
    dispatch(updateJournalIsArchive(true))
    dispatch(updateJournalList('AdvanceReportApplication', false));
  }

  const AgreementNavLinkClickHandler = (value: string) => {
    let params:FilterParams = {
      WhoIsInPaperWork: [+value]
    }
    dispatch(updateAgreementList(params))
  }

  const checkIsArchiveActive = () => {
    return isArchiveActive
  }

  return (
    <aside className="sidebar-left">
      <nav className="sidebar-menu">
        <ul className="sidebar-menu__list">
          {menuFieldPermissions && menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.EXPENSE_APPLICATION) ? <li className="sidebar-menu__item">
            <NavLink className="sidebar-menu__link" to="/ExpenseApplication" activeClassName='active' tabIndex={-1} isActive={checkOpenRequestSubmenu}>
              <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8334 1.83331H4.50008C4.01385 1.83331 3.54754 2.02647 3.20372 2.37028C2.8599 2.7141 2.66675 3.18042 2.66675 3.66665V18.3333C2.66675 18.8195 2.8599 19.2859 3.20372 19.6297C3.54754 19.9735 4.01385 20.1666 4.50008 20.1666H15.5001C15.9863 20.1666 16.4526 19.9735 16.7964 19.6297C17.1403 19.2859 17.3334 18.8195 17.3334 18.3333V7.33331L11.8334 1.83331Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 16.5V11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.25 13.75H12.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.8333 1.83331V7.33331H17.3333" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {t('menu.request')}
            </NavLink>
            <ul className={`sidebar-menu__list-submenu ${openRequestSubmenu? 'active-submenu-list': ''}`} >
              {configuredStatusesExpenses.map((status, index) => (
                <li key={index}>
                  <NavLink to={`/ExpenseApplication/${status.code}`} activeClassName='active' tabIndex={-1} onClick={() => exspenseNavLinkClickHandler(status.value)} isActive={checkOpenStatusSubmenu}>{status.text[i18n.language as 'en' | 'ru']}</NavLink>
                </li>
              ))}
            </ul>
          </li> : null}
          {menuFieldPermissions && menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.ADVANCE_REPORT_APPLICATION) ? <li className="sidebar-menu__item">
            <NavLink className="sidebar-menu__link" to="/AdvanceReportApplication" activeClassName='active' tabIndex={-1} isActive={checkOpenExpensesSubmenu}>
              <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8334 1.83331H4.50008C4.01385 1.83331 3.54754 2.02647 3.20372 2.37028C2.8599 2.7141 2.66675 3.18042 2.66675 3.66665V18.3333C2.66675 18.8195 2.8599 19.2859 3.20372 19.6297C3.54754 19.9735 4.01385 20.1666 4.50008 20.1666H15.5001C15.9863 20.1666 16.4526 19.9735 16.7964 19.6297C17.1403 19.2859 17.3334 18.8195 17.3334 18.3333V7.33331L11.8334 1.83331Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.6666 15.5833H6.33325" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.6666 11.9167H6.33325" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.16658 8.25H7.24992H6.33325" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.8333 1.83331V7.33331H17.3333" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {t('menu.expenses')}
            </NavLink>
            <ul className={`sidebar-menu__list-submenu ${openExpensesSubmenu? 'active-submenu-list': ''}`}>
              {configuredStatuses.map((status, index) => (
                <li key={index}>
                  <NavLink to={`/AdvanceReportApplication/${status.code}`} activeClassName='active' tabIndex={-1} onClick={() => AONavLinkClickHandler(status.value)} isActive={checkOpenStatusSubmenu}>{status.text[i18n.language as 'en' | 'ru']}</NavLink>
                </li>
              ))}
            </ul>
          </li> : null}
          {menuFieldPermissions && menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.AGREEMENT) ? <li className="sidebar-menu__item">
            <NavLink className="sidebar-menu__link" to="/Agreement" activeClassName='active' tabIndex={-1} isActive={checkOpenAgreementSubmenu}>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.41658 20.1667H3.66659C3.18035 20.1667 2.71404 19.9735 2.37022 19.6297C2.02641 19.2859 1.83325 18.8196 1.83325 18.3333V11.9167C1.83325 11.4304 2.02641 10.9641 2.37022 10.6203C2.71404 10.2765 3.18035 10.0833 3.66659 10.0833H6.41658M12.8333 8.25001V4.58334C12.8333 3.854 12.5435 3.15453 12.0278 2.6388C11.5121 2.12307 10.8126 1.83334 10.0833 1.83334L6.41658 10.0833V20.1667H16.7566C17.1987 20.1717 17.6278 20.0167 17.9647 19.7303C18.3016 19.444 18.5236 19.0455 18.5899 18.6083L19.8549 10.3583C19.8948 10.0956 19.8771 9.8273 19.803 9.57207C19.7289 9.31685 19.6002 9.08078 19.4258 8.88023C19.2514 8.67968 19.0355 8.51945 18.793 8.41063C18.5506 8.30181 18.2873 8.247 18.0216 8.25001H12.8333Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {t('menu.analytics')}
            </NavLink>
            <ul className={`sidebar-menu__list-submenu ${openAgreementSubmenu? 'active-submenu-list': ''}`}>
              {inHand.map((status, index) => (
                <li key={index}>
                  <NavLink to={`/Agreement/${status.code}`} activeClassName='active' tabIndex={-1} onClick={() => AgreementNavLinkClickHandler(status.value)} isActive={checkOpenStatusSubmenu}>{status.text[i18n.language as 'en' | 'ru']}</NavLink>
                </li>
              ))}
            </ul>
          </li> : null}
          {menuFieldPermissions && menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.JOURNAL) ? <li className="sidebar-menu__item">
            <NavLink className="sidebar-menu__link" to="/Journal/AdvanceReportApplication" tabIndex={-1} isActive={(match, location) => checkOpenJournalSubmenu(match, location)} onClick={journalNavLinkClickHandler} activeClassName='active'>
              <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                  <path d="M5.41667 21.0833L1.75 17.4167L5.41667 13.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18.25 11.9167V13.75C18.25 14.7225 17.8637 15.6551 17.1761 16.3427C16.4884 17.0304 15.5558 17.4167 14.5833 17.4167H1.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14.5833 0.916672L18.2499 4.58334L14.5833 8.25001" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1.75 10.0833V8.25C1.75 7.27753 2.13631 6.3449 2.82394 5.65727C3.51158 4.96964 4.44421 4.58333 5.41667 4.58333H18.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="22" height="22" fill="white" transform="translate(-1)"/>
                  </clipPath>
                </defs>
              </svg>
                {t('menu.journal')}</NavLink>
              <ul className={`sidebar-menu__list-submenu ${openJournalSubmenu? 'active-submenu-list': ''}`}>
                  <li>
                      <NavLink to="/Journal/AdvanceReportApplication" activeClassName={true ? '' : 'sub_active'} tabIndex={-1} onClick={archiveNavLinkClickHandler} isActive={checkIsArchiveActive}>{t('menu.archive_journal')}</NavLink>
                  </li>
            </ul>
          </li> : null}
          {menuFieldPermissions && menuFieldPermissions.includes(MENU_FIELD_PERMISSIONS.REGISTRY) ? <li className="sidebar-menu__item">
            <NavLink className="sidebar-menu__link" to="/Reports" isActive={(match, location) =>checkOpenReportsSubmenu(match, location)} onClick={journalNavLinkClickHandler} activeClassName='active'>
              <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 4H17C17.5304 4 18.0391 4.21071 18.4142 4.58579C18.7893 4.96086 19 5.46957 19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4H7" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14 2H8C7.44772 2 7 2.44772 7 3V5C7 5.55228 7.44772 6 8 6H14C14.5523 6 15 5.55228 15 5V3C15 2.44772 14.5523 2 14 2Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {t('menu.reports')}</NavLink>
            <ul className="sidebar-menu__list-submenu" style={{ display: openReportSubmenu ? 'block' : 'none' }}>
              <li>
                <NavLink to="/Reports/Registry" activeClassName={true ? '' : 'sub_active'} onClick={archiveNavLinkClickHandler} isActive={checkIsArchiveActive}>{t('menu.reports_registry')}</NavLink>
              </li>
            </ul>
          </li> : null}
        </ul>
      </nav>
    </aside>
  );
}

export default Menu;
