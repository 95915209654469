import React, { useState, useRef, useEffect, useImperativeHandle, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { nameFormatter, NameFormats } from 'app/utils';
import { useDispatch } from 'react-redux';
import { showErrors } from 'store/exception/actions';
import SvgIcon from '../svg-icon/SvgIcon';
import Slidebar from '../slidebar/Slidebar';
import {getEmployeeByParams} from "../../../services/ApiService";

interface EmployeeAutocompleteProps {
    defaultText?: string,
    placeholder?: string,
    onChoose?: (option: EmployeeAutocompleteOptionType) => void,
    readOnly?: boolean,
    ref?: any,
    style?: CSSProperties,
    tabIndex?: -1 | 0
}

export interface EmployeeAutocompleteOptionType {
    value: number,
    text: string
}

const EmployeeAutocomplete: React.FC<EmployeeAutocompleteProps> = React.forwardRef((props: EmployeeAutocompleteProps, ref: any) => {
    let { t, i18n } = useTranslation();

    let [isOpen, setOpen] = useState(false);
    let [isLoading, setLoading] = useState(false);
    let [inputValue, setInputValue] = useState(props.defaultText ? props.defaultText : '');
    let [employees, setEmployees] = useState([] as EmployeeAutocompleteOptionType[]);
    let [employeesWithOccupations, setEmployeesWithOccupations] = useState([] as any);
    let [error, setError] = useState(false);
    let [employeeTimer, setEmployeeTimer] = useState({} as any);
    let [isSelected, setSelected] = useState(false);

    let inputRef = useRef({} as HTMLInputElement);

    useEffect(() => {
        setInputValue(props.defaultText ? props.defaultText : '');
    }, [props.defaultText])

    useEffect(() => {
        if (employees.find(employee => employee.text === inputValue)) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    }, [inputValue, employees])

    const onBlur = () => {
        if(!isSelected) {
            setInputValue('');
        }
    }

    const optionClickHandler = (option: EmployeeAutocompleteOptionType) => {
        setInputValue(option.text);
        setOpen(false);
        if (props.onChoose) props.onChoose(option);
    }
    

    const changeInput = () => {        
        props.onChoose && props.onChoose({} as EmployeeAutocompleteOptionType);
        setInputValue(inputRef.current.value);
        if (inputRef.current.value.length > 2) {
            clearTimeout(employeeTimer);
            let name = inputRef.current.value;
            (()=>{
                setEmployeeTimer(setTimeout(() => {getEmployee(name)}, 800));
            })();
        } else {
            setEmployees([] as EmployeeAutocompleteOptionType[]);
            setOpen(false);
            setLoading(false);
        }
    }

    const missClickHandler = () => {
        if (isOpen) {
            setOpen(false);
            setInputValue(props.defaultText ? props.defaultText : '');
        }
    };

    const getEmployee = async (nameValue: string) => {
        setError(false);
        setLoading(true);
        setEmployees([] as EmployeeAutocompleteOptionType[]);
        setOpen(true);


        try {
            let result = await getEmployeeByParams({ params: { Name: nameValue } });
            if ((result.config.params.Name == inputRef.current.value)) {
                setEmployeesWithOccupations(result.data.data.map((item: any) => { return { value: item.id, text: nameFormatter(item.name, NameFormats.FULL, i18n.language), occupations: item.occupations } }));
                setEmployees(result.data.data.map((item: any) => { return { value: item.id, text: nameFormatter(item.name, NameFormats.FULL, i18n.language) } }));
            };
        } catch (e) {
            setError(true);
        }

        setLoading(false);
    }

    useImperativeHandle(ref, () => ({
        clear: () => {
            setInputValue('');
        },
        getOccupations: (emolyeeId: number) => {
            return employeesWithOccupations.find((item: any) => item.value === emolyeeId) || [];
        },
        value: inputRef.current.value
    }));

    return (
        <div className={'select ' + (isOpen ? 'active' : '')} style={props.style}>
            {isOpen ? <div className="miss-click-handler" onClick={missClickHandler} /> : ''}
            <input className="input" type="text" ref={inputRef} value={inputValue} onBlur={onBlur} onChange={changeInput} placeholder={props.placeholder} readOnly={props.readOnly} tabIndex={props.tabIndex}/>
            <SvgIcon href={'#svg_icon_search'} className={'icon icon-search'} />
            <Slidebar className="select-content" style={{ zIndex: 20, maxHeight: '260px' }}>
                {employees.length > 0 ? employees.map((option, index) => {
                    return <div className="select-content-item" key={index} onClick={() => optionClickHandler(option)}>{option.text}</div>
                }) : null}
                {!error && !isLoading && employees.length === 0 ? <div className="select-content-item" >{t('create_expense.no_employee_found')} </div>
                    : null}
                {error ? <div className="select-content-item" >{t('create_expense.search_error')} </div>
                    : null}
                {isLoading ? <div className="select-content-item" ><div className="lds-ellipsis" style={{ marginLeft: 'calc(50% - 30px)' }}><div></div><div></div><div></div><div></div></div></div>
                    : null}
            </Slidebar>
        </div>
    )
})

export default EmployeeAutocomplete;