import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from 'app/component/modal/ModalContainer';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface DeleteReportModalProps extends RouteComponentProps<any> {
  isOpen: boolean,
  onSubmit: () => void,
  onClose: () => void,
  id: number | null
}

const DeleteReportModal: React.FC<DeleteReportModalProps> = props => {
  const { t } = useTranslation();

  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  const closeHandler = () => {
    props.onClose();
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  }

  return (
    <ModalContainer isOpen={props.isOpen} highestModal={isHighestModal} destroy={true} overlayClick={closeHandler}>
      <div className="box-modal" id="note-delete">
        <div className="box-modal_close arcticmodal-close" onClick={closeHandler}></div>
        <div className="box-modal-title">
          <div className="tx-center">{t('modals.delete_report_modal.want_to_delete_report')}</div>
        </div>
        <div className="box-modal-footer"><a className="arcticmodal-close btn btn_black" onClick={closeHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.delete_report_modal.no')}</a><a
          className="btn-expense btn_green" onClick={props.onSubmit} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.delete_report_modal.yes')}</a></div>
      </div>
    </ModalContainer>
  );
};

export default withRouter(DeleteReportModal);