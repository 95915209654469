import { ActionCreator } from 'redux';
import {
    ExpenseType,
    BusinessGoal,
    ConsumptionType,
    PaymentForm,
    PaymentFormResponse,
    ConsumptionTypeResponse,
    FilterParams,
    UserDetail,
    CurrencyList,
    Posting,
    AOExpense,
    Expense,
    AttachmentItem,
    AttachmentPermission,
    ManualExpenseCurrencyPermission,
    Clearing,
    BusinessTarget,
    ExpenseTypeItem,
    ICompany
} from 'infrastructure/interfaces';
import {
    ExpenseTypeMapper,
    BusinessGoalMapper,
    PaymentFormMapper,
    ConsumptionTypeMapper,
    UserDetailMapper,
    CurrencyListMapper,
    TransactionMapper,
    AttachmentItemMapper,
    ClearingMapper
} from 'infrastructure/mappers';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import authService from 'services/AuthService';
import { showErrors, throwException } from 'store/exception/actions';
import { ApplicationState } from 'store/reducers';
import { ApplicationForExpenseActions } from "../applicationForExpense/actionsTypes";
import { get } from 'lodash';
import { ExceptionActions } from 'store/exception/actionTypes';
import { formatter, numberFormatter } from 'app/utils';
import {
    AddAttachmentsExpense,
    AddNewTopModal,
    AddPosting,
    COMMON,
    CommonActions,
    DecrementLoaderCount,
    DelTopModal,
    HistoryNeedUpdating,
    HistoryUpdated,
    IncrementLoaderCount,
    SetAttachmentList,
    SetAttachmentPermission,
    SetAttachmentsExpense,
    SetBusinessGoal,
    SetChatShow,
    SetConsumptionType,
    SetCurrencies,
    SetCurrencyRate,
    SetExpenseType,
    SetFilterParams,
    SetFilterShow,
    SetHistoryShow,
    SetNoticePanelNewMessageCount,
    SetNoticePanelOpen,
    SetPaymentForm,
    SetPostings,
    SetStateOption,
    SetUserDetail,
    ToggleAttachments,
    ToggleOnboard,
    SetManualExpenseCurrencyPermission,
    SetClearings,
    SetApprovedCurrencyRate,
    SetBusinessTargetsCatalog,
    SetExpenseTypesCatalog,
    SetCompaniesList,
    SetIsArchivedPostings,
    SetTransactionBatchId,
    SetMenuFieldPermissions,
    SetFilterFieldPermissions,
    SetProfileFieldPermissions,
    SetTransactionsUpdateFieldPermissions,
    SetTransactionsReadFieldPermissions,
    SetTransactionsJournalFieldPermissions,
    SetCostCenterData,
    SetCostCenterIsChanged,
    SetSubcontoIsChanged,
    SetSubcontoData
} from './actionTypes';
import { EmployeeAutocompleteOptionType } from 'app/component/autocomplete/EmployeeAutocomplete';
import { setJournalIsArchive } from 'store/journal/actions';
import {
    getApiState,
    getApplicationTypes,
    getAttachments,
    getBusinessTargets,
    getClearing,
    getCurrencies,
    getCurrenciesConversion,
    getCurrentEmployee,
    getExpenseType, getFieldPermissionsReadFilter, getFieldPermissionsReadMainMenu, getFieldPermissionsReadProfile,
    getFieldPermissionsReadTransaction,
    getFieldPermissionsReadTransactionsJournal,
    getFieldPermissionsReadTransactionsRead,
    getPaymentForms,
    getTransactions,
    postConnectToken
} from "../../services/ApiService";
import api from "../../services/interceptor";


export const setExpenseType: ActionCreator<SetExpenseType> = (expenseTypes: ExpenseType[]) => ({
    type: COMMON.SET_EXPENSE_TYPE,
    payload: { expenseTypes }
});

export const setConsumptionType: ActionCreator<SetConsumptionType> = (consumptionTypes: ConsumptionType[]) => ({
    type: COMMON.SET_CONSUMPTION_TYPE,
    payload: { consumptionTypes }
});

export const setPaymentForm: ActionCreator<SetPaymentForm> = (paymentForms: PaymentForm[]) => ({
    type: COMMON.SET_PAYMENT_FORM,
    payload: { paymentForms }
});

export const setFilterParams: ActionCreator<SetFilterParams> = (filterParams: FilterParams) => ({
    type: COMMON.SET_FILTER_PARAMS,
    payload: { filterParams }
});

export const setStateOptions: ActionCreator<SetStateOption> = (stateOptions: string[]) => ({
    type: COMMON.SET_STATE_OPTIONS,
    payload: { stateOptions }
})

export const setUserDetail: ActionCreator<SetUserDetail> = (userDetail: UserDetail) => ({
    type: COMMON.SET_USER_DETAIL,
    payload: { userDetail }
})
export const setAttachmentPermission: ActionCreator<SetAttachmentPermission> = (attachmentPermission: AttachmentPermission) => ({
    type: COMMON.SET_ATTACHMENT_PERMISSION,
    payload: { attachmentPermission }
})

export const setManualExpenseCurrencyPermission: ActionCreator<SetManualExpenseCurrencyPermission> = (manualExpenseCurrencyPermission: ManualExpenseCurrencyPermission) => ({
    type: COMMON.SET_MANUAL_EXPENSE_CURRENCY_PERMISSION,
    payload: { manualExpenseCurrencyPermission }
})

export const setBusinessTargetsCatalog: ActionCreator<SetBusinessTargetsCatalog> = (catalog: BusinessTarget[]) => ({
    type: COMMON.SET_BUSINESS_TARGETS_CATALOG,
    payload: {catalog}
})

export const setExpenseTypesCatalog: ActionCreator<SetExpenseTypesCatalog> = (catalog: ExpenseTypeItem[]) => ({
    type: COMMON.SET_EXPENSE_TYPES_CATALOG,
    payload: {catalog}
})

export const setCompaniesList: ActionCreator<SetCompaniesList> = (catalog: ICompany[]) => ({
    type: COMMON.SET_COMPANIES_CATALOG,
    payload: {catalog}
})

export const setCurrencies: ActionCreator<SetCurrencies> = (currencies: CurrencyList) => ({
    type: COMMON.SET_CURRENCIES,
    payload: { currencies }
})

export const setPostings: ActionCreator<SetPostings> = (postings: Posting[]) => ({
    type: COMMON.SET_POSTINGS,
    payload: { postings }
})

export const setIsArchivedPostings: ActionCreator<SetIsArchivedPostings> = (isArchived: boolean) => ({
    type: COMMON.SET_IS_ARCHIVED_POSTINGS,
    payload: { isArchived }
})

export const setTransactionBatchId: ActionCreator<SetTransactionBatchId> = (transactionBatchId: number) => ({
  type: COMMON.SET_TRANSACTION_BATCH_ID,
    payload: { transactionBatchId }
})

export const addPosting: ActionCreator<AddPosting> = (posting: Posting) => ({
    type: COMMON.ADD_POSTING,
    payload: { posting }
})

export const setMenuFieldPermissions: ActionCreator<SetMenuFieldPermissions> = (menuFieldPermissions: string[]) => ({
    type: COMMON.SET_MENU_FIELD_PERMISSIONS,
    payload: { menuFieldPermissions }
})

export const setFilterFieldPermissions: ActionCreator<SetFilterFieldPermissions> = (filterFieldPermissions: string[]) => ({
    type: COMMON.SET_FILTER_FIELD_PERMISSIONS,
    payload: { filterFieldPermissions }
})

export const setProfileFieldPermissions: ActionCreator<SetProfileFieldPermissions> = (profileFieldPermissions: string[]) => ({
    type: COMMON.SET_PROFILE_FIELD_PERMISSIONS,
    payload: { profileFieldPermissions }
})

export const setTransactionsUpdateFieldPermissions: ActionCreator<SetTransactionsUpdateFieldPermissions> = (transactionsUpdateFieldPermissions: string[]) => ({
    type: COMMON.SET_TRANSACTIONS_UPDATE_FIELD_PERMISSIONS,
    payload: { transactionsUpdateFieldPermissions }
})

export const setTransactionsReadFieldPermissions: ActionCreator<SetTransactionsReadFieldPermissions> = (transactionsReadFieldPermissions: string[]) => ({
    type: COMMON.SET_TRANSACTIONS_READ_FIELD_PERMISSIONS,
    payload: { transactionsReadFieldPermissions }
})

export const setTransactionsJournalFieldPermissions: ActionCreator<SetTransactionsJournalFieldPermissions> = (transactionsJournalFieldPermissions: string[]) => ({
    type: COMMON.SET_TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS,
    payload: { transactionsJournalFieldPermissions }
})

export const setCostCenterData: ActionCreator<SetCostCenterData> = (costCenterData: any) => ({
    type: COMMON.SET_COST_CENTER_DATA,
    payload: { costCenterData }
})

export const setCostCenterIsChanged: ActionCreator<SetCostCenterIsChanged> = (costCenterIsChanged: boolean) => ({
    type: COMMON.SET_COST_CENTER_IS_CHANGED,
    payload: { costCenterIsChanged }
})

export const setSubcontoData: ActionCreator<SetSubcontoData> = (subcontoData: boolean) => ({
    type: COMMON.SET_SUBCONTO_DATA,
    payload: { subcontoData }
})

export const setSubcontoIsChanged: ActionCreator<SetSubcontoIsChanged> = (subcontoIsChanged: boolean) => ({
    type: COMMON.SET_SUBCONTO_IS_CHANGED,
    payload: { subcontoIsChanged }
})


export const updateStateOptions = (data: any): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());
        let params = {
            Id: data.id,
            LogicalName: data.type
        }
        let response = await getApiState({ params: params });
        if (response.headers.success) {
            dispatch(setStateOptions(response.data));
        }
        else {
            dispatch(setStateOptions([]));
            // dispatch(showErrors({ code: 'update_state_error', message: `Что-то пошло не так` }));
        }
        await dispatch(loaderUnlock());
    }
}

export const updateConsumptionType = (id: number, companyId?: number): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());
        let consumptionTypeMapper = new ConsumptionTypeMapper();
        let response = await getExpenseType({ params: { applicationTypeId: id,  PageSize: 100, companyId } });
        if (response.headers.success) {
            dispatch(setConsumptionType(consumptionTypeMapper.responsesToEntitys(response.data.data as ConsumptionTypeResponse[])));
        } else {
            await dispatch(showErrors({
                code: 'update_expense_type',
                message: 'Не удалось загрузить список типов расхода'
            }));
        }
        await dispatch(loaderUnlock());
    }
}

export const updatePaymentForm = (applicationTypeId: number, expenseTypeId: number): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());
        let paymentFormMapper = new PaymentFormMapper();
        let response = await getPaymentForms({ params: { applicationTypeId, expenseTypeId } });
        if (response.headers.success) {
            dispatch(setPaymentForm(paymentFormMapper.responsesToEntitys(response.data.data as PaymentFormResponse[])));
        } else {
            await dispatch(showErrors({ code: 'update_payment_form', message: 'Не удалось загрузить список форм оплаты' }))
        }
        await dispatch(loaderUnlock());
    }
}

export const updateCurrencies = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        let response = await getCurrencies({ params: { PageSize: 100 } });
        if (response.headers.success) {
            const currencyListMapper = new CurrencyListMapper();
            dispatch(setCurrencies(currencyListMapper.responseToEntity(response.data)))
        } else {
            await dispatch(showErrors({ code: 'update_currencies_error', message: 'Не удалось загрузить список валют' }))
        }
    }
}

export const updateExpenseType = (companyId?: number): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());
        let expenseTypeMapper = new ExpenseTypeMapper();
        let response = await getApplicationTypes({ params: {CompanyId : companyId} });
        if (response.headers.success) {
            dispatch(setExpenseType(expenseTypeMapper.fullResponseToEntitys(response.data)));
        } else {
            await dispatch(showErrors({ code: 'expense_type_error', message: `Не удалось загрузить доступные типы заявок` }));
        }
        await dispatch(loaderUnlock());
    }
}

export const setBusinessGoal: ActionCreator<SetBusinessGoal> = (businessGoals: BusinessGoal[]) => ({
    type: COMMON.SET_BUSINESS_GOAL,
    payload: { businessGoals }
});

export const updateBusinessGoal = (expenseTypeId: number): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());
        let businessGoalMapper = new BusinessGoalMapper();
        let response = await getBusinessTargets({
            params: {
                "applicationTypeId": expenseTypeId,
                PageSize: 0
            }
        });
        if (response.headers.success) {
            dispatch(setBusinessGoal(businessGoalMapper.fullResponseToEntities(response.data)));
        } else {
            await dispatch(showErrors({
                code: 'business_targets_error',
                message: 'Не удалось загрузить список бизнес целей'
            }))
        }
        await dispatch(loaderUnlock());
    }
}

export const updateUserDetail = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());
        let userDetailMapper = new UserDetailMapper();
        let response = await getCurrentEmployee();
        if (response.headers.success) {
            dispatch(setUserDetail(userDetailMapper.responseToEntity(response.data)))
        } else {
            dispatch(setUserDetail(userDetailMapper.responseToEntity({})));
            await dispatch(showErrors({code: 'User_detail_info', message: 'Что-то пошло не так'}))
        }
        await dispatch(loaderUnlock());
    }
}

const incrementLoaderCount: ActionCreator<IncrementLoaderCount> = () => ({
    type: COMMON.INCREMENT_LOADER_COUNT,
});

const decrementLoaderCount: ActionCreator<DecrementLoaderCount> = () => ({
    type: COMMON.DECREMENT_LOADER_COUNT,
});

export const loaderLock = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        dispatch(incrementLoaderCount());
    }
}

export const loaderUnlock = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        let state = getState();
        if (state.common.loaderCount === 0) {
            await dispatch(throwException({code: 'loaderUnlock', message: 'loader already unlock fully'}));
        } else {
            dispatch(decrementLoaderCount());
        }
    }
}

export const oldAuthorization = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());

        let searchParams = new URLSearchParams();
        searchParams.set('grant_type', 'password');
        searchParams.set('scope', 'expense.ui');
        searchParams.set('username', 'usermow@aeroclub.ru');
        searchParams.set('password', '12345');
        searchParams.set('client_id', 'Test.SSO.ExpenseUI');
        searchParams.set('client_secret', 'DemoSecret');

        try {
            let response = await postConnectToken(searchParams);
            api.defaults.headers = { ...api.defaults.headers, 'Authorization': `Bearer ${response.data.access_token}` };
            api.defaults.headers.common = {
                ...api.defaults.headers.common,
                "Content-Type": "application/json; charset=utf-8"
            };
        } catch (e) {
            await dispatch(throwException({
                code: 'auth_error',
                message: `Auth failure with status ${e.response.status}`
            }));
        }

        await dispatch(loaderUnlock());
    }
}
export const authorizationStatus = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());

        authService.getUser().then(user => {
            if (user) {
                alert('You are authorized');//set ok status 
            } else {
                alert('You are not authorized')//set no status 
            }
        });

        await dispatch(loaderUnlock());
    }
}

export const newAuthorization = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());

        await authService.login();

        await dispatch(loaderUnlock());
    }
}

export const setFilterShow: ActionCreator<SetFilterShow> = (filterState: boolean) => ({
    type: COMMON.SET_FILTER_SHOW,
    payload: { filterState }
})

export const showFilter = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        dispatch(setFilterShow(true));
    }
}

export const hideFilter = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        dispatch(setFilterShow(false));
    }
}

export interface UpdatePostingsFilters {
    postingKey?: number,
    lager?: string,
    employee?: number
}

export const updatePostings = (id: number, logicalName: string, filters: UpdatePostingsFilters, setStatus: Function = () => {}): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());
        dispatch(setPostings({ transactions: [] }));
        // let stub = [
        //     {
        //         "id": 52,
        //         "costCenter": { "id": 10, "logicalName": "CostCenter", "name": { "ru": "RU8889478", "en": "EN8889478" } },
        //         "employee": { name: { ru: 'Иван Простой', en: 'Ivan Prostoy' } },
        //         "businessTarget": { "id": 35, "logicalName": "BusinessTarget", "name": { "ru": "Сервисное обслуживание клиентов", "en": "Customer service" } },
        //         "txText": "General Expense , Low-value-purchases",
        //         "ledgerOrVendor": "000430434",
        //         "postingKey": 40,
        //         "amountRub": 3000,
        //         "createdAt": "2019-10-21T08:32:38.307",
        //         "docNumber": "1458",
        //         "docDate": "2019-10-18T13:18:55.563",
        //         "currency": { "numCode": 643, "name": "Russian Ruble" },
        //         "amount": 3000,
        //         "curRate": 1.00,
        //         "taxCode": { "id": 1, "code": "TXC_NDF", "name": { "ru": "20%", "en": "20%" } },
        //         "postingDate": "2019-10-21T08:32:38.0379491"
        //     },
        //     {
        //         "id": 53,
        //         "costCenter": { "id": 10, "logicalName": "CostCenter", "name": { "ru": "RU8889478", "en": "EN8889478" } },
        //         "employee": { name: { ru: 'Иван Простой', en: 'Ivan Prostoy' } },
        //         "businessTarget": { "id": 35, "logicalName": "BusinessTarget", "name": { "ru": "Сервисное обслуживание клиентов", "en": "Customer service" } },
        //         "txText": "General Expense , Low-value-purchases",
        //         "ledgerOrVendor": "000430434",
        //         "postingKey": 50,
        //         "amountRub": 3000,
        //         "createdAt": "2019-10-21T08:32:38.323",
        //         "docNumber": "1458",
        //         "docDate": "2019-10-18T13:18:55.563",
        //         "currency": { "numCode": 643, "name": "Russian Ruble" },
        //         "amount": 3000,
        //         "curRate": 1.00,
        //         "taxCode": { "id": 2, "code": "TXC_ADVANCE", "name": { "ru": "20%", "en": "20%" } },
        //         "postingDate": "2019-10-21T08:32:38.0379491"
        //     }
        // ];

        let transactionMapper = new TransactionMapper();

        //dispatch(setPostings({ transactions: transactionMapper.responsesToEntitys(stub) }))

        let params = {
            ObjectId: id,
            ObjectType: logicalName,
            PageSize: 500,
            postingKey: filters.postingKey,
            lager: filters.lager,
            EmployeeId: filters.employee
        }
        let response = await getTransactions({ params })
        if (response.headers.success && response.status != 204) {
            setStatus(response.status);
            let transactions = transactionMapper.responsesToEntitys(response.data.data);
            let transactionsWithObj = transactions.map((item) => {
                item.objectId.id = id;
                item.objectId.logicalName = logicalName;
                item.objectId.name = {
                    ru: logicalName,
                    en: logicalName
                };

                return item
            });
            dispatch(setPostings({ transactions: transactionsWithObj }));
            dispatch(setIsArchivedPostings(response.data.isArchived));
            dispatch(setJournalIsArchive(response.data.isArchived));
            dispatch(setTransactionBatchId(response.data.transactionBatchId));
        } else {
            setStatus(response.status);
            switch (response.status) {
                case 204:
                    //dispatch(showErrors({ code: 'update_postings', message: t('error.update_postings.204') }))
                    break;
                case 403:
                    //dispatch(showErrors({ code: 'update_postings', message: t('error.update_postings.403') }))
                    break;
                case 404:
                case 422:
                default:
                    //dispatch(showErrors({ code: 'update_postings', message: t('error.update_postings.404') }))
                    break;
            }

            //dispatch(setPostings({ transactions: transactionMapper.responsesToEntitys(stub) }))


        }
        dispatch(loaderUnlock());
    }
}

export const setAttachmentList: ActionCreator<SetAttachmentList> = (attachmentList: AttachmentItem[], isAddExpense: boolean = false) => ({
    type: COMMON.SET_ATTACHMENT_LIST,
    payload: { attachmentList, isAddExpense }
});


export const clearAttachmentList = (): ThunkAction<Promise<void>, ApplicationState, any, ApplicationForExpenseActions> => {
    return (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): any => {
        dispatch(setAttachmentList([]));
    }
};

export const setOnboardModal: ActionCreator<ToggleOnboard> = (state: boolean) => ({
    type: COMMON.TOGGLE_ONBOARD,
    payload: { state: state }
});

export const toggleOnboard1 = (): ThunkAction<Promise<void>, ApplicationState, any, ExceptionActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, ExceptionActions>, getState): Promise<void> => {
        let state = getState();
        dispatch(setOnboardModal(!state.common.onboardState));
    }
}

export const toggleAttachments = (): ThunkAction<Promise<void>, ApplicationState, any, ExceptionActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, ExceptionActions>, getState): Promise<void> => {
        dispatch(setAttachmentsToggle());
    }
}

export const setAttachmentsToggle: ActionCreator<ToggleAttachments> = () => ({
    type: COMMON.TOGGLE_ATTACHMENTS
});

export const setAttachmentsExpense: ActionCreator<SetAttachmentsExpense> = (attachmentsExpense: Expense[]) => ({
    type: COMMON.SET_ATTACHMENTS_EXPENSE,
    payload: { attachmentsExpense }
});

export const addAttachmentsExpense: ActionCreator<AddAttachmentsExpense> = (attachmentsExpense: Expense) => ({
    type: COMMON.ADD_ATTACHMENTS_EXPENSE,
    payload: { attachmentsExpense }
});

export const updateAttachmentsExpense = (id: number, logicalName: string, expenses: AOExpense[] | Expense[]): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());
        dispatch(setAttachmentsExpense([]));
        dispatch(setAttachmentList([]));
        let params = {
            'ObjectId.Id': id,
            'ObjectId.LogicalName': logicalName,
        };
        let attachmentItemMapper = new AttachmentItemMapper();
        let response = await getAttachments({ params });
        if (response.status == 200) {
            dispatch(setAttachmentList(attachmentItemMapper.responseToEntity(response.data.data)));
        }

        expenses.forEach((expense: AOExpense | Expense) => {
            let params = {
                'ObjectId.Id': expense.id,
                'ObjectId.LogicalName': 'Expense',
            };
            getAttachments({ params }).then((response) => {
                if (response.status == 200) {
                    dispatch(setAttachmentList(response.data.data, true)); //Todo маппер
                    dispatch(addAttachmentsExpense({
                        id: expense.id,
                        attachments: response.data.data
                    }))
                }
            });
        });
        await dispatch(loaderUnlock());
    }
};

export const updateCurrencyRate = (currencyId: number, alfaCode: string, dateRate: Date): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());
        let params = {
            dateRate: formatter('YYYY-MM-DD', 'ru', dateRate)
        }
        if (alfaCode != 'rub') {
            let response = await getCurrenciesConversion(currencyId, { params });
            if (response.headers.success) {
                dispatch(setCurrencyRate(response.data.rate));
            } else {
                await dispatch(showErrors({code: 'get_currency_rate', message: 'Не удалось получить курс валюты'}))
            }
        } else {
            dispatch(setCurrencyRate(1));
        }
        await dispatch(loaderUnlock());
    }
}

export const updateApprovedCurrencyRate = (currencyId: number, alfaCode: string, dateRate: Date, courseDollar: string): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());
        let params = {
            dateRate: formatter('YYYY-MM-DD', 'ru', dateRate)
        }
        if (alfaCode != 'rub') {
            if (!!courseDollar) {
                dispatch(setApprovedCurrencyRate(+numberFormatter(courseDollar, true)))
            } else {
                let response = await getCurrenciesConversion(currencyId, { params });
                if (response.headers.success) {
                    dispatch(setApprovedCurrencyRate(response.data.rate));
            } else {
                await dispatch(showErrors({code: 'get_currency_rate', message: 'Не удалось получить курс валюты'}))
            }
            }            
        } else {
            dispatch(setApprovedCurrencyRate(1));
        }
        await dispatch(loaderUnlock());
    }
}

export const historyNeedUpdatingThunk = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        dispatch(historyNeedUpdating());
    }
}

const historyNeedUpdating: ActionCreator<HistoryNeedUpdating> = () => ({
    type: COMMON.HISTORY_NEED_UPDATING
})

export const historyUpdated: ActionCreator<HistoryUpdated> = () => ({
    type: COMMON.HISTORY_UPDATED
})


export const addNewTopModal: ActionCreator<AddNewTopModal> = (newTopModal: number) => ({
    type: COMMON.ADD_NEW_TOP_MODAL,
    payload: { newTopModal }
});

export const delTopModal: ActionCreator<DelTopModal> = (topModal: number) => ({
    type: COMMON.DEL_TOP_MODAL,
    payload: { topModal }
});

export const setChatShow: ActionCreator<SetChatShow> = (chatState: boolean) => ({
    type: COMMON.SET_CHAT_SHOW,
    payload: { chatState }
})

export const setCurrencyRate: ActionCreator<SetCurrencyRate> = (currencyRate: number) => ({
    type: COMMON.SET_CURRENCY_RATE,
    payload: { currencyRate }
})

export const setApprovedCurrencyRate: ActionCreator<SetApprovedCurrencyRate> = (currencyRate: number) => ({
    type: COMMON.SET_APPROVED_CURRENCY_RATE,
    payload: { currencyRate }
})

export const showChat = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        dispatch(setChatShow(true));
    }
}

export const hideChat = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        dispatch(setChatShow(false));
    }
}

export const setHistoryShow: ActionCreator<SetHistoryShow> = (historyState: boolean) => ({
    type: COMMON.SET_HISTORY_SHOW,
    payload: { historyState }
})

export const showHistory = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        dispatch(setHistoryShow(true));
    }
}

export const hideHistory = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        dispatch(setHistoryShow(false));
    }
}

export const setNoticePanelOpen: ActionCreator<SetNoticePanelOpen> = (open: boolean) => ({
    type: COMMON.SET_NOTICE_PANEL_OPEN,
    payload: { open }
})

export const setNoticePanelNewMessageCount: ActionCreator<SetNoticePanelNewMessageCount> = (newMessageCount: number) => ({
    type: COMMON.SET_NOTICE_PANEL_NEW_MESSAGE_COUNT,
    payload: { newMessageCount }
})

export const setClearings: ActionCreator<SetClearings> = (clearingData: {
    dateStart: Date,
    dateEnd: Date,
    clearings: Clearing[],
    balanceStart: number,
    balanceEnd: number
}) => ({
    type: COMMON.SET_CLEARINGS,
    payload: { clearingData }
})

export const updateMenuFieldPermissions = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>): Promise<void> => {
        dispatch(loaderLock());
        const response = await getFieldPermissionsReadMainMenu();;
        if (response.headers.success) {
            dispatch(setMenuFieldPermissions(response.data));
        }
        dispatch(loaderUnlock());
    }
}

export const updateFilterFieldPermissions = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>): Promise<void> => {
        dispatch(loaderLock());
        const response = await getFieldPermissionsReadFilter();
        if (response.headers.success) {
            dispatch(setFilterFieldPermissions(response.data));
        }
        dispatch(loaderUnlock());
    }
}

export const updateProfileFieldPermissions = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>): Promise<void> => {
        dispatch(loaderLock());
        const response = await getFieldPermissionsReadProfile();
        if (response.headers.success) {
            dispatch(setProfileFieldPermissions(response.data));
        }
        dispatch(loaderUnlock());
    }
}

export const updateTransactionsUpdateFieldPermissions = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>): Promise<void> => {
        dispatch(loaderLock());
        const response = await getFieldPermissionsReadTransaction();
        if (response.headers.success) {
            dispatch(setTransactionsUpdateFieldPermissions(response.data));
        }
        dispatch(loaderUnlock());
    }
}

export const updateTransactionsReadFieldPermissions = (logicalName: string, id: string): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>): Promise<void> => {
        dispatch(loaderLock());
        const response = await getFieldPermissionsReadTransactionsRead({
            params: {
                ObjectLogicalName: logicalName,
                ObjectId: id
            }
        });
        if (response.headers.success) {
            dispatch(setTransactionsReadFieldPermissions(response.data));
        }
        dispatch(loaderUnlock());
    }
}

export const updateTransactionsJournalFieldPermissions = (): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>): Promise<void> => {
        dispatch(loaderLock());
        const response = await getFieldPermissionsReadTransactionsJournal();
        if (response.headers.success) {
            dispatch(setTransactionsJournalFieldPermissions(response.data));
        }
        dispatch(loaderUnlock());
    }
}

export const updateCalculations = (dateStart: Date, dateEnd: Date, employee: EmployeeAutocompleteOptionType): ThunkAction<Promise<void>, ApplicationState, any, CommonActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, CommonActions>, getState): Promise<void> => {
        await dispatch(loaderLock());

        const response = await getClearing({
            params: {
                startDate: dateStart,
                endDate: dateEnd,
                EmployeeId: employee.value != 0 ? employee.value : undefined
            }
        });

        if (response.headers.success) {
            let clearingMapper = new ClearingMapper();
            let clearings = clearingMapper.responsesToEntitys(response.data.data);
            dispatch(setClearings({
                dateStart: dateStart,
                dateEnd: dateEnd,
                clearings: clearings,
                balanceStart: get(response.data, 'balance.beginigBalance', 0),
                balanceEnd: get(response.data, 'balance.finalBalance', 0)
            }));
        }

        //dispatch(setClearings(clearingDataStub));
        await dispatch(loaderUnlock());
    }
};