import React, {Fragment, useRef, useState} from 'react';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showErrors, showInfo } from 'store/exception/actions';
import { loaderLock, loaderUnlock } from 'store/common/actions';
import analitiks from 'services/AnaliticsService';
import If from '../util/If';
import { updateJournalList } from 'store/journal/actions';
import {RouteComponentProps, withRouter} from "react-router";
import DownloadPostingsModal from "../modal/DownloadPostingsModal";
import {TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS} from "../../../infrastructure/enum/field-permissions.enum";
import AssistanceWarningModal from "../modal/AssistanceWarningModal";
import {getJournalIsArchive, getJournalList, getCommonUserDetail} from "../../../store/selectors";
import {postTransactionsDownload, updateBatchesArchive} from "../../../services/ApiService";

interface PostingsDownloadButtonProps extends RouteComponentProps{
    id: number,
    logicalName: string
    onDownload?: () => void,
    onError?: () => void,
}

const PostingsDownloadButton: React.FC<PostingsDownloadButtonProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector(getCommonUserDetail);
    let journalList = useSelector(getJournalList);
    let isArchive = useSelector(getJournalIsArchive);

    const transactionsJournalFieldPermissions = useSelector((state: ApplicationState) => state.common.transactionsJournalFieldPermissions);

    let [isOpenDownloadPostingsModal, setOpenDownloadPostingsModal] = useState(false);
    let [isOpenAssistanceWarningModal, setOpenAssistanceWarningModal] = useState(false);

    const currentItem = journalList.filter(item => item.referenceId == +props.id);
    
    const downloadHandler = async (date?: string) => {
        dispatch(loaderLock());
        let response = await postTransactionsDownload({}, {
            responseType: 'blob', params: date ? {
                ObjectType: props.logicalName,
                ObjectIds: +props.id,
                PostingDate: date
            } : {
                ObjectType: props.logicalName,
                ObjectIds: +props.id,
            }
        });
        if (response.headers.success) {
            analitiks('DownloadPostings', {transactionsId: +props.id, employeeId: user.id, occupationId: user.occupations[0].occupationId, companyId: user.occupations[0].company.id})
            let contentDisposition = response.headers['content-disposition'];
            let fileName = contentDisposition.slice(contentDisposition.lastIndexOf('\'') + 1);
            FileSaver.saveAs(response.data, decodeURI(fileName));
            if (!isArchive) {
                props.history.push(props.logicalName === 'ExpenseApplication' ? '/Journal/ExpenseApplication' : '/Journal/AdvanceReportApplication');
            }
        }
        else {
            //dispatch(showErrors({ code: 'approve_postings', message: 'Не удалось согласовать проводки' }));
        }
        dispatch(loaderUnlock());
    }
    const returnFromArchive = async() => {
        dispatch(loaderLock());
        let response = await updateBatchesArchive(+props.id, props.logicalName,{archive: false});
        if (response.headers.success) {
            dispatch(showInfo({ code: 'return_from_archive', message: `${props.logicalName == 'ExpenseApplication' ? t('postings.return _expense_info') : t('postings.return_advance_info')}`}));
        }
        dispatch(loaderUnlock());
    }

    const toggleDownloadPostingsModal = () => {
        setOpenDownloadPostingsModal(!isOpenDownloadPostingsModal);
    }

    const toggleAssistanceWarningModal = () => {
        setOpenAssistanceWarningModal(!isOpenAssistanceWarningModal);
    }

    return (
        <div className="request-bottom-controls">
            <div className="tx-center">
                <If condition={isArchive && transactionsJournalFieldPermissions.includes(TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS.TRANS_RETURN_FROM_ARCHIVE)}>
    <div className="btn btn_black" onClick={returnFromArchive}>{t('postings.return_from_archive')}</div>
                </If>
                <If condition={!isArchive && transactionsJournalFieldPermissions.includes(TRANSACTIONS_JOURNAL_FIELD_PERMISSIONS.TRANS_DOWNLOAD)}>
                <div className="btn-expense btn_green" onClick={toggleDownloadPostingsModal}>{t('postings.download')}</div>
                </If>
            </div>
            <AssistanceWarningModal
                isOpen={isOpenAssistanceWarningModal}
                title={t('journal.return_from_archive_warning_title')}
                info={t('journal.return_from_archive_warning_info')}
                sub_info={t('journal.return_from_archive_warning_sub_info')}
                todo={t('journal.return_from_archive_warning_todo')}
                onClose={toggleAssistanceWarningModal}
                onSubmit={() => { toggleAssistanceWarningModal(); returnFromArchive();}} />
            <DownloadPostingsModal isOpen={isOpenDownloadPostingsModal} onClose={toggleDownloadPostingsModal} onSubmit={(date) => {toggleDownloadPostingsModal(); downloadHandler(date)}}/>
        </div>
    );
}

export default withRouter(PostingsDownloadButton);
