import React, {useState, useRef, useEffect} from 'react';
import './AmountEditForm.css';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from 'store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import Config, { ConfigTypes } from 'services/ConfigService';
import { get } from 'lodash';
import { showErrors } from 'store/exception/actions';
import { loaderLock, loaderUnlock } from 'store/common/actions';
import { updateApplicationForExpenseDetail, } from 'store/applicationForExpense/actions';
import { statusNameMap, STATUS_TYPE } from 'infrastructure/enum/status-type.enum';
import ModalContainer from "../modal/ModalContainer";
import {getApplicationForExpense, getApplicationForExpenseDetail} from "../../../store/selectors";
import {
  getExpenseApplicationsAdvanceCashAmount,
  updateExpenseApplicationsAdvanceEdit
} from "../../../services/ApiService";


const AmountEditForm: React.FC = () => {

  const applicationForExpenseDetail = useSelector((state: ApplicationState) => state.applicationForExpense.applicationForExpenseDetail);
  const applicationForExpense = useSelector((state: ApplicationState) => state.applicationForExpense);
  const companyId = applicationForExpenseDetail ? applicationForExpenseDetail.company.id : 0;
  const advanceCalculationMethod = Config.getConfigToCompany(ConfigTypes.ADVANCE_CALCULATION_METHOD, companyId);


    let [isOpen, setOpen] = useState(false);
    let [cashAmount, setCashAmount] = useState(0);
    let [maxCashAmount, setMaxCashAmount] = useState(0);
    let [isValidCashAmount, setIsValidCash] = useState(true);
    let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

    let cashAmountRef = useRef({} as HTMLInputElement);

    const {i18n, t} = useTranslation();
    const dispatch = useDispatch();

  const updateSumAdvance = async () => {
    dispatch(loaderLock());
    if (applicationForExpenseDetail) {
      let result = await updateExpenseApplicationsAdvanceEdit(applicationForExpenseDetail.id, {
        applicationTypeId: applicationForExpenseDetail.applicationType.id,
        advanceCashAmount: cashAmount
      });
      if (result.headers.success) {
        dispatch(updateApplicationForExpenseDetail(applicationForExpenseDetail.id));
      } else {
        dispatch(showErrors({ code: 'expense_applications_error', message: `${result.data.Errors ? result.data.Errors : result.data}` }));
      }
    }
    dispatch(loaderUnlock());
    closeHandler();
  }

    const changeCashAmount = () => {
        if (cashAmountRef.current.value.indexOf(".") != -1) {
            cashAmountRef.current.value = cashAmountRef.current.value.substring(0, cashAmountRef.current.value.indexOf(".") + 3);
        }
        if (cashAmountRef.current.value.indexOf(",") != -1) {
            cashAmountRef.current.value = cashAmountRef.current.value.substring(0, cashAmountRef.current.value.indexOf(",") + 3);
        }
        setCashAmount(+(cashAmountRef.current.value));
        setIsValidCash(true);
    }

    const clickHandler = async() => {
        if (applicationForExpenseDetail) {
            if (!isOpen) {
                await setAdvanceAmountInitValue();
                setIsValidCash(true)
            }
            setOpen(!isOpen)
        }
    };

    const getCashAmount = async () => {
        dispatch(loaderLock());
        let result = null;
        if (applicationForExpenseDetail) {
            result = await getExpenseApplicationsAdvanceCashAmount(applicationForExpenseDetail.id);
            if (result.headers.success) {
            }
            dispatch(loaderUnlock());
            return result;
        }
    }

    const setAdvanceAmountInitValue = async () => {
        if (advanceCalculationMethod == 1 || advanceCalculationMethod == 0) {
            setCashAmount(applicationForExpenseDetail ? applicationForExpenseDetail.advanceCashAmount : 0)
        }
        if (advanceCalculationMethod == 1 || advanceCalculationMethod == 2) {
            await getCalculatedAdvanceCashAmount()
        }
    }

    useEffect(() => {
        async function calculate() {
            await calculateHandler();
        }

        calculate();
    }, [])

    const calculateHandler = async () => {
        const result = await getCashAmount();
        if (result) {
            cashAmountRef.current.value = result.data.amount;
            setCashAmount(result.data.amount);
            if (advanceCalculationMethod == 1 || advanceCalculationMethod == 2) {
                setMaxCashAmount(result.data.amount);
            }
        }
    }

    const getCalculatedAdvanceCashAmount = async () => {
        const result = await getCashAmount();
        if (result && result.headers.success) {
            applicationForExpenseDetail && applicationForExpenseDetail.advanceCashAmountIsEdited ? setCashAmount(applicationForExpenseDetail.advanceCashAmount) : setCashAmount(result.data.amount);
            if (advanceCalculationMethod == 2) {
                setMaxCashAmount(result.data.amount)
            }
        }
    }

    const closeHandler = () => {
        setOpen(false)
    }

    const validateCashAmount = (): boolean => {
        let additionalExpense = get(applicationForExpense, `additionalExpense`, 0);
        let isValid = maxCashAmount ? cashAmount >= 0 && cashAmount <= maxCashAmount : cashAmount >= 0 && cashAmount <= additionalExpense;
        if (!isValid) {
            dispatch(showErrors({
                code: 'expense_applications_error',
                message: maxCashAmount ? t('validator.cash_amount_error', {maxCashAmount}) : t('validator.cash_amount_error', {additionalExpense})
            }));
        }
        setIsValidCash(isValid);
        return isValid;
    }

    const saveHandler = () => {
        if (!validateCashAmount()) {
            return;
        }
        if (cashAmountRef.current.value == '') {
            dispatch(showErrors({
                code: 'expense_applications_error',
                message: t('create_expense.placeholder.amount')
            }));
            return
        }
        setCashAmount(+(cashAmountRef.current.value));
        updateSumAdvance()
    };


    const isHighestModal = (isHighest: boolean) => {
        setTabIndexDisabled(!isHighest);
    }

    return (
        <div className="profile-user-wrap" style={{'width': 'auto', 'minWidth': 'auto'}}>
            {(!!advanceCalculationMethod) ? <div className="btn btn_gray add-trip"
                                                 onClick={clickHandler}>+ {t('request_detail.request_cost.change')}</div> : ''}

            <ModalContainer isOpen={isOpen} destroy={true} highestModal={isHighestModal} overlayClick={closeHandler}>
                <div className="box-modal" id="add-expenses">
                    <div className="box-modal_close arcticmodal-close" onClick={closeHandler}/>
                    <div
                        className="box-modal-title">{t('request_detail.advance_amount_edit.title')}</div>
                    <div className="box-modal-content">
                        <div className="input-wrap">
                            <div className="input-wrap">
                                <div className={`input-item ${!isValidCashAmount ? 'error' : ''}`}>
                                    <label
                                        className="input-label">{t('request_detail.advance_amount_edit.advance_amount')}</label>
                                    <div className="main-advance-wrap">
                                        <div className="advance-cash-input">
                                            <input className="input" type="number" value={cashAmount}
                                                   ref={cashAmountRef}
                                                   tabIndex={isTabIndexDisabled ? -1 : 0}
                                                   onChange={changeCashAmount}
                                                   placeholder={t('request_detail.advance_amount_edit.advance_amount')}/>
                                            <div className="description-wrap">
                        <span
                            className="description">{t('request_detail.advance_amount_edit.enter_amount_or_calculate_automatically')}</span>
                                            </div>
                                        </div>
                                        <div className="btn btn_gray btn-calculate" onClick={calculateHandler}
                                        >{t('request_detail.advance_amount_edit.calculate_automatically')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-modal-footer">
                        <a className="btn btn_black pointer block" tabIndex={isTabIndexDisabled ? -1 : 0}
                           onClick={closeHandler}>{t('request_detail.advance_amount_edit.cancel')}</a>
                        <a className="btn-expense btn_green pointer block" tabIndex={isTabIndexDisabled ? -1 : 0}
                           onClick={saveHandler}>{t('request_detail.advance_amount_edit.save')}</a>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
}


export default AmountEditForm;
