import { ApprovalSheet, ApprovalTier } from "infrastructure/interfaces";
import { ApprovalSheetMapper } from 'infrastructure/mappers';
import {getApprovalsDetails} from "../ApiService";

const agreementServiceStore = {
    parameters: {
        id: 0,
        isAdvance: false,
        needUpdate: true
    },
    agreementList: {} as ApprovalSheet
}

const agreementService = {
    getList: async () => {
        if (!agreementServiceStore.parameters.needUpdate) {
            return agreementServiceStore.agreementList;
        }

        if(!agreementServiceStore.parameters.id){
            return null;
        }

        let result = await getApprovalsDetails({
            params: {
                id: agreementServiceStore.parameters.id,
                logicalName: agreementServiceStore.parameters.isAdvance ? 'AdvanceReportApplication': 'ExpenseApplication'
            }
        })

        if (result && result.headers.success) {
            let approvalSheetMapper = new ApprovalSheetMapper();
            let approvalSheet = approvalSheetMapper.responseToEntity(result.data);
            agreementServiceStore.parameters.needUpdate = true;
            agreementServiceStore.agreementList = approvalSheet;
            return agreementServiceStore.agreementList;
        }

        agreementServiceStore.agreementList = {} as ApprovalSheet;

        return null;
    },
    setId: (id: number) => {
        if (agreementServiceStore.parameters.id != id) {
            agreementServiceStore.parameters.needUpdate = true;
            agreementServiceStore.parameters.id = id;
        }
        return agreementService;
    },
    setIsAdvance: (isAdvance: boolean) => {
        if (agreementServiceStore.parameters.isAdvance != isAdvance) {
            agreementServiceStore.parameters.needUpdate = true;
            agreementServiceStore.parameters.isAdvance = isAdvance;
        }
        return agreementService;
    },
    getCurrentTier: async () => {
        let list = await agreementService.getList();

        if(!list){ return null}

        let approvalTiers = list.approvalTiers.find((item: ApprovalTier) => list && item.id === list.currentApprovalTierId) || null;
        
        return approvalTiers;
    },
    getCurrentComposition: async () => {
        let currentTier = await agreementService.getCurrentTier();

        if(!currentTier){ return null}

        return currentTier.composition;
    }
};

export default agreementService;
