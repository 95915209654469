/* eslint-disable react-hooks/exhaustive-deps */
import { get } from 'lodash'
import React, { CSSProperties, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isIE } from 'react-device-detect';

interface ValidatorProps {
  ref?: any,
  children: ReactNode,
  type?: string,
  errorMessage?: string,
  className?: string,
  isRoad?: boolean,
  style?: CSSProperties,
  isWidget?: boolean
}

const Validator: React.FC<ValidatorProps> = React.forwardRef((props: ValidatorProps, ref: any) => {
  let { t } = useTranslation();
  let [error, setError] = useState(false);
  let [errorIE, setErrorIE] = useState(false);
  let elementRef = useRef({} as any);
  const errorMessage: string = props.errorMessage ? props.errorMessage : t('validator.fill_the_field');
  let input: HTMLInputElement;
  let inputChild: ReactNode;
  let bootstrapSelectValue: string;

  useEffect(() => {
    if (props.children && (props.children as any).props.children) {
      inputChild = (props.children as any).props.children[1];
    } else if (props.children && !(props.children as any).props.children) {
      inputChild = props.children;
    }

    switch (true) {
      case props.type === 'text': {
        input = elementRef.current.querySelector('input');
        if (input.value !== '') {
          isIE && !!props.isRoad && setErrorIE(false);
          clearText()
        }
        input.addEventListener('input', () => {
          clearText()
        });
        return;
      }
      case props.type === 'textarea': {
        input = elementRef.current.querySelector('textarea');
        if (input.value !== '') {
          clearText()
        }
        input.addEventListener('input', () => {
          clearText()
        });
        return;
      }
      case props.type === 'select': {
        if (props.isWidget) {
          bootstrapSelectValue = elementRef.current.querySelector('.form-control') && elementRef.current.querySelector('.form-control').value;
          if (bootstrapSelectValue !== get(inputChild, 'props.defaultText') && bootstrapSelectValue !== '---' && bootstrapSelectValue !== errorMessage) {
            setError(false)
          }
        } else {
          input = elementRef.current.querySelector('.select-title span');
          if (input.textContent !== get(inputChild, 'props.defaultText')
            && input.textContent !== '---'
            && input.textContent !== errorMessage) {
            setError(false)
          }
        }
        return;
      }
      case props.type === 'autocomplete': {
        input = elementRef.current.querySelector('input');
        let defaultText = get(inputChild, 'props.defaultText')
        if (!defaultText) {
          defaultText = get(inputChild, 'props.children.props.defaultText')
        }
        if (defaultText === input.value && input.value !== '') {
          isIE && !!props.isRoad && setErrorIE(false);
          clearText()
        }
        input.addEventListener('input', () => {
          clearText()
        });
        return;
      }
    }
    return () => {
      input.removeEventListener('input', () => {
        validateTextInput();
      });
    }
  });

  const validateTextInput = () => {
    if (input.value.length > 0) {
      input.placeholder = get(inputChild, 'props.placeholder', '');
      setError(false);
      isIE && !!props.isRoad && setErrorIE(false);
      return true;
    } else {
      input.placeholder = errorMessage;
      setError(true);
      isIE && !!props.isRoad && setErrorIE(true);
      return false;
    }
  };

  const validateSelect = () => {
    if (props.isWidget && (get(inputChild, 'props.defaultText') === bootstrapSelectValue || get(inputChild, 'props.defaultText') === bootstrapSelectValue
        || bootstrapSelectValue === '---' || bootstrapSelectValue === errorMessage)) {
      setError(true);
      bootstrapSelectValue = errorMessage;
      if (elementRef.current.querySelector('.form-control')) {
        elementRef.current.querySelector('.form-control').children[0].value = errorMessage;
        elementRef.current.querySelector('.form-control').children[0].text = errorMessage;
        elementRef.current.querySelector('.form-control').value = errorMessage;
      }
      return false;
    } else if (!props.isWidget && (get(inputChild, 'props.defaultText') === input.textContent || get(inputChild, 'props.defaultText') === input.title
        || input.textContent === '---' || input.textContent === errorMessage)) {
      setError(true);
      input.textContent = errorMessage;
      return false;
    } else {
      setError(false);
      return true;
    }
  };

  const validateAutocomplete = () => {
    let defaultText = get(inputChild, 'props.defaultText')
    if (!defaultText) {
      defaultText = get(inputChild, 'props.children.props.defaultText')
    }
    if (defaultText !== input.value
      || input.value === '') {
      input.placeholder = errorMessage;
      setError(true);
      isIE && !!props.isRoad && setErrorIE(true);
      return false;
    } else {
      setError(false);
      isIE && !!props.isRoad && setErrorIE(false);
      return true;
    }
  };

  const clearSelect = () => {
    input.textContent = get(inputChild, 'props.defaultText', '---');
    setError(false);
  };

  const clearText = () => {
    if (isIE && !!props.isRoad) {
      return;
    }
    input.placeholder = get(inputChild, 'props.placeholder', '');
    setError(false);
  };

  useImperativeHandle(ref, () => ({
    validate() {
      switch (true) {
        case (props.type === 'text' || props.type === 'textarea'): {
          return validateTextInput()
        }
        case (props.type === 'select'): {
          return validateSelect()
        }
        case (props.type === 'autocomplete'): {
          return validateAutocomplete()
        }
      }
    },
    clear: () => {
      if (props.type === 'text' || props.type === 'textarea' || props.type === 'autocomplete') {
        clearText();
        input.value = '';
      }
      if (props.type === 'select') {
        clearSelect()
      }
    }
  }));

  return (
    <div
      ref={elementRef}
      className={`input-item ${(isIE && !!props.isRoad) ? (errorIE ? 'error' : '') : (error ? 'error' : '')} ${props.className ? props.className : ''}`}
      style={props.style}
    >
      {props.children}
    </div>
  );
});

export default Validator;