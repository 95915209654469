import Wizard from 'app/component/modal/Wizard';
import Radio from 'app/component/radio/Radio';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loaderLock, loaderUnlock, setOnboardModal } from 'store/common/actions';
import {getCommonOnboardState, getCommonUserDetail} from "../../../store/selectors";
import {updateEmployeesSettings} from "../../../services/ApiService";

const OnboardingWizard: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const isWizardOpen = useSelector(getCommonOnboardState);
  let currentUser = useSelector(getCommonUserDetail);

  let [currentStep, setCurrentStep] = useState(0);
  let [stepsCount, setStepsCount] = useState(4);
  let [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isWizardOpen);
  })

  const steps = [
    <div className="box-modal-content">
      <img src="img/modal-new-1.png" alt="" />
      <div className="modal-lang-choose flex-center">
        <div className="modal-lang-choose-item">
          <div className="title">Выберите язык системы</div>
          <Radio checked={i18n.language === 'ru'}
            label='Русский'
            onClick={() => { languageChooseHandler('ru') }}
            id={1}
            name={'radio'}
            key={1} />
          <div className="text-title">Русский</div>
          <div className="text">Язык можно будет поменять в профиле в настройке персональных данных</div>
        </div>
        <div className="modal-lang-choose-item">
          <div className="title">Choose system language</div>
          <Radio checked={i18n.language === 'en'}
            label='English'
            onClick={() => { languageChooseHandler('en') }}
            id={2}
            name={'radio'}
            key={2} />
          <div className="text-title">English</div>
          <div className="text">The language can be changed in the profile in the personal data settings</div>
        </div>
      </div>
    </div>,
    <div className="box-modal-content">
      <img className="m-t-50" src="img/modal-new-2.png" alt="" />
      <div className="title">
        {t('on_board.step_2_header')}
      </div>
      <div className="text">
        {t('on_board.step_2_description')}
      </div>
    </div>,
    <div className="box-modal-content"><img src="img/modal-new-3.png" alt="" />
      <div className="title"> {t('on_board.step_3_header')}</div>
      <div className="text">
        {t('on_board.step_3_description')}
      </div>
    </div>,
    <div className="box-modal-content">
      <img src="img/modal-new-4.png" alt="" />
      <div className="title">
        {t('on_board.step_4_header')}
      </div>
      <div className="text">{t('on_board.step_4_description')}</div>
    </div>,
    <div className="box-modal-content">
      <img src="img/modal-new-5.png" alt="" />
      <div className="title">
        {t('on_board.step_5_header')}
      </div>
      <div className="text">
        {t('on_board.step_5_description')}
      </div>
    </div>
  ];

  const closeHandler = async () => {
    dispatch(loaderLock());

    let response = await updateEmployeesSettings(currentUser.id, {
      language: {
        code: i18n.language
      },
      showOnBoarding: false
    });
    if (!response.headers.success) {
      //dispatch(showErrors({ code: 'approve_postings', message: 'Не удалось согласовать проводки' }));
    }
    dispatch(setOnboardModal(false));
    setOpen(false);
    setCurrentStep(0);

    dispatch(loaderUnlock());
  };

  const nextClickHandler = () => {
    setCurrentStep(currentStep + 1)
  };

  const getStep = (stepNumber: number) => {
    return steps[stepNumber]
  };

  const languageChooseHandler = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
  };

  return (
    <Wizard isOpen={isOpen} onClose={closeHandler} currentStep={currentStep} stepsCount={stepsCount}
      onNext={nextClickHandler} title={t('on_board.whats_new')}>
      {getStep(currentStep)}
    </Wizard>
  );
};

export default OnboardingWizard;