/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import ModalContainer from "app/component/modal/ModalContainer";
import { useSelector, useDispatch } from "react-redux";
import { closeExceptionModal } from "store/exception/actions";
import {
  getExceptionArray,
  getIsOpenExceptionModal,
} from "../../../store/selectors";
import { Button, Modal } from "react-bootstrap";

interface ExceptionModalProps {
  isWidget?: boolean;
}

const ExceptionModal: React.FC<ExceptionModalProps> = (props) => {
  const exceptionArray = useSelector(getExceptionArray);
  const isOpen = useSelector(getIsOpenExceptionModal);
  const dispatch = useDispatch();

  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  const closeHandler = () => {
    dispatch(closeExceptionModal());
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return props.isWidget ? (
    <Modal show={isOpen} onHide={closeHandler} centered={true}>
      <Modal.Header closeButton={true} translate="no">
        <Modal.Title>Ошибки</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="filters-wrap">
          {exceptionArray.map((exception, index) => {
            return (
              <div className="filter-dropdown-item" key={exception.id}>
                <div className="filter-dropdown-item-title ">
                  {exception.id}. -{exception.code}-
                </div>
                <div className="filter-dropdown-item-content">
                  <div className="input-choose">
                    <div className="input-choose-item">{exception.message}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button
          variant={"outline-dark"}
          onClick={closeHandler}
          tabIndex={isTabIndexDisabled ? -1 : 0}
        >
          Ок
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <ModalContainer
      isOpen={isOpen}
      highestModal={isHighestModal}
      destroy={true}
      overlayClick={closeHandler}
    >
      <div className="box-modal" id="exception-modal">
        <div className="box-modal_close" onClick={closeHandler}></div>
        <div className="box-modal-title">Ошибки</div>
        <div className="box-modal-content">
          <div className="filters-wrap">
            {exceptionArray.map((exception, index) => {
              return (
                <div className="filter-dropdown-item" key={exception.id}>
                  <div className="filter-dropdown-item-title ">
                    {exception.id}. -{exception.code}-
                  </div>
                  <div className="filter-dropdown-item-content">
                    <div className="input-choose">
                      <div className="input-choose-item">
                        {exception.message}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="box-modal-footer">
          <a
            className="btn-expense btn_green pointer"
            onClick={closeHandler}
            tabIndex={isTabIndexDisabled ? -1 : 0}
          >
            Ок
          </a>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ExceptionModal;
