import React, { Fragment, useState, useRef, useEffect } from 'react';
import DateInput from '../date-input/DateInput';
import { useTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import { loaderLock, loaderUnlock } from 'store/common/actions';
import { showErrors } from 'store/exception/actions';
//import analitiks from 'services/AnaliticsService';
import { useDispatch, useSelector } from 'react-redux';
import CompanyAutocomplete, { CompanyAutocompleteOptionType } from '../autocomplete/CompanyAutocomplete';
import SvgIcon from '../svg-icon/SvgIcon';
import './RegistryForm.css';
import {getAdvanceReportApplicationsReportRegistry} from "../../../services/ApiService";
import moment from "moment";

export interface DownloadParameters {
    
    downloadDateStart?: Date,
    downloadDateEnd?: Date
    
}

const RegistryForm: React.FC = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    let [downloadDateStart, setDownloadDateStart] = useState(null as any);
    let [downloadDateEnd, setDownloadDateEnd] = useState(null as any);
    let [company, setCompany] = useState({} as CompanyAutocompleteOptionType);
    let [fieldsWithError, setFieldsWithError] = useState([] as string[]);

    let dowloadDateStartRef = useRef({} as any);
    let downloadDateEndRef = useRef({} as any);
    let companyAutocompleteRef = useRef({} as any);

    const validStateField = (fieldName: string) => {
        return fieldsWithError.includes(fieldName) ? 'error' : '';
    }
    const validate = (): boolean => {
        let newFieldsWithError = [] as string[];

        let validDateStart = true;
        let validDateEnd = true;
        let validCompany = true;

        if (!downloadDateStart) {
            validDateStart = false;
            newFieldsWithError.push('date_from');
        }

        if (!downloadDateEnd || (downloadDateStart.valueOf() > downloadDateEnd.valueOf())) {
            validDateEnd = false;
            newFieldsWithError.push('date_to');
        }

        if (!company || !company.value || company.value == 0) {
            validCompany = false;
            newFieldsWithError.push('company');
        }

        setFieldsWithError(newFieldsWithError);
        return validDateStart && validDateEnd && validCompany;
    };
    const downaloadDateStartSelectHandler = (value: any) => {
        setDownloadDateStart(value);
    }

    const downloadDateEndSelectHandler = (value: any) => {
        setDownloadDateEnd(value);
    }

    const companyChooseHandler = (option: CompanyAutocompleteOptionType) => {
        setCompany(option);
    }

  
    const downloadHandler = async () => {
        if (!validate()) {
            return
        }

        dispatch(loaderLock());
        let response = await getAdvanceReportApplicationsReportRegistry(
        { 
            params: 
            { 
                dateFrom: moment(downloadDateStart).startOf('day').toDate() as Date,
                dateTo: moment(downloadDateEnd).endOf('day').toDate() as Date,

                companyId: company.value
            }, 
            responseType: 'blob'
        });

        if (response.headers.success) {
            
            let contentDisposition = response.headers['content-disposition'];
            let fileName = contentDisposition.slice(contentDisposition.lastIndexOf('\'') + 1);
            FileSaver.saveAs(response.data, decodeURI(fileName));
        } else {
            if (response.status === 404) {
                dispatch(showErrors({
                    code: 'registry_downloads',
                    message: t('reports.registry_form.error_reports_not_found')
                }));
            } else {
                dispatch(showErrors({
                    code: 'registry_downloads',
                    message: t('reports.registry_form.error_message_download')
                }));
            }
        }
        dispatch(loaderUnlock());
    }

  return (
  <Fragment>
    <div className="content-wrap">
        
        <div className="filters-title">{t('reports.registry_form.header')}</div>
        <div className="input-item-row">
            
                <div className={`input-item quoter ${validStateField('date_from')}`}>
                    <label className="input-label">{t('reports.registry_form.date_from')}<i className="input-required">*</i></label>
                    <div className="input-date">
                        <DateInput ref={dowloadDateStartRef} placeholder={t('reports.registry_form.choose_date')} onSelect={downaloadDateStartSelectHandler} format={'D MMMM'} />
                    </div>
                </div>
            
            
            
                <div className={`input-item quoter ${validStateField('date_to')}`}>
                    <label className="input-label">{t('reports.registry_form.date_to')}<i className="input-required">*</i></label>
                    <div className="input-date">
                        <DateInput ref={downloadDateEndRef} placeholder={t('reports.registry_form.choose_date')} onSelect={downloadDateEndSelectHandler} format={'D MMMM'} />
                    </div>
                </div>
            
            <div className="break"></div>
            
                <div className={`input-item half ${validStateField('company')}`}>
                    <label className="input-label">{t('reports.registry_form.company')}<i className="input-required">*</i></label>
                    <div className="input-date">
                        <CompanyAutocomplete ref={companyAutocompleteRef} onChoose={companyChooseHandler} placeholder={t('reports.registry_form.company')} />
                        <SvgIcon className={'icon icon-search'} href={'#svg_icon-search'} />
                    </div>                            
                </div>
            
        </div>
            
       
        <div className="request-bottom-controls">
            <div className="tx-center">
                <a className="btn-expense btn_green pointer" onClick={downloadHandler}>{t('reports.registry_form.download')}</a>
            </div>
        </div>

    </div>
      
    
  </Fragment>)
}

export default RegistryForm;