import React, {CSSProperties, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import SvgIcon from "../svg-icon/SvgIcon";
import Slidebar from "../slidebar/Slidebar";
import {useSelector} from "react-redux";
import {get} from "lodash";
import {getAdvanceReportApplication, getApplicationForExpenseDetail} from "../../../store/selectors";
import {getApiLedgerAccounts} from "../../../services/ApiService";

interface LedgerAccountAutocompleteProps {
    transactionId: number,
    defaultText?: string,
    placeholder?: string,
    onChoose?: (option: LedgerAccountAutocompleteOptionType) => void,
    readOnly?: boolean,
    ref?: any,
    style?: CSSProperties,
    tabIndex?: -1 | 0,
    disabled?: boolean,
}

export interface LedgerAccountAutocompleteOptionType {
    value: string,
    text: string
}

const LedgerAccountAutocomplete: React.FC<LedgerAccountAutocompleteProps> = React.forwardRef((props: LedgerAccountAutocompleteProps, ref: any) => {
    let { t, i18n } = useTranslation();

    let [isOpen, setOpen] = useState(false);
    let [isLoading, setLoading] = useState(false);
    let [inputValue, setInputValue] = useState(props.defaultText ? props.defaultText : '');
    let [ledgerAccounts, setLedgerAccounts] = useState([] as LedgerAccountAutocompleteOptionType[]);
    let [error, setError] = useState(false);
    let [ledgerAccountTimer, setLedgerAccountTimer] = useState({} as any);

    let inputRef = useRef({} as HTMLInputElement);

    const advanceReport = useSelector(getAdvanceReportApplication);
    const applicationForExpense = useSelector(getApplicationForExpenseDetail);

    useEffect(() => {
        setInputValue(props.defaultText ? props.defaultText : '');
    }, [props.defaultText])

    const optionClickHandler = (option: LedgerAccountAutocompleteOptionType) => {
        setInputValue(option.text);
        setOpen(false);
        if (props.onChoose) props.onChoose({text: option.value, value: option.value});
    }

    const changeInput = () => {
        props.onChoose && props.onChoose({ value: '', text: inputRef.current.value } as LedgerAccountAutocompleteOptionType);
        setInputValue(inputRef.current.value);
        if (inputRef.current.value.length > 0) {
            clearTimeout(ledgerAccountTimer);
            let text = inputRef.current.value;
            (()=>{
                setLedgerAccountTimer(setTimeout(() => {getLedgerAccounts(text)}, 800));
            })();
        } else {
            setLedgerAccounts([] as LedgerAccountAutocompleteOptionType[]);
            setOpen(false);
            setLoading(false);
        }
    }

    const missClickHandler = () => {
        if (isOpen) {
            setOpen(false)
        }
    };

    const getLedgerAccounts = async (text: string) => {
        setError(false);
        setLoading(true);
        setLedgerAccounts([] as LedgerAccountAutocompleteOptionType[]);
        setOpen(true);
        try {
            let result;
            if (applicationForExpense) {
                result = await getApiLedgerAccounts({params: {CompanyId: applicationForExpense.company.id, TransactionId: props.transactionId, SearchRequest: text}});
            } else {
                result = await getApiLedgerAccounts({params: {CompanyId: advanceReport.company.id, TransactionId: props.transactionId, SearchRequest: text}});
            }
            if ((result.config.params.SearchRequest == inputRef.current.value)) {
                setLedgerAccounts(result.data.data.map((item: any) => {
                    return {
                        value: get(item, 'code', ''),
                        text: get(item, 'code', '') === get(item, `name.${i18n.language}`, '')
                            ? get(item, 'code', '')
                            : `${get(item, 'code', '')} ${get(item, `name.${i18n.language}`, '')}`
                    };
                }));
            }
        } catch (e) {
            setError(true);
        }

        setLoading(false);
    }

    useImperativeHandle(ref, () => ({
        clear: () => {
            setInputValue('');
        },
        value: inputRef.current.value
    }));

    let defaultWidth = 184;
    let size = 0;
    const width = inputValue.length > 12 ? size + (inputValue.length - 12) * 5 : 0;
 
    return (
        <div className={'input-search' + (props.disabled ? ' disabled' : '')} style={{ width: defaultWidth + width, maxWidth: 210 }}>
        <div className={'select ' + (isOpen ? 'active' : '')} style={props.style}>
            {isOpen ? <div className="miss-click-handler" onClick={missClickHandler} /> : ''}
            <input className="input" type="text" ref={inputRef} value={inputValue} onChange={changeInput} placeholder={props.placeholder} readOnly={props.readOnly} tabIndex={props.tabIndex}/>
            <SvgIcon href={'#svg_icon_search'} className={'icon icon-search'} />
            <Slidebar className="select-content" style={{ zIndex: 20, maxHeight: '186px' }}>
                {ledgerAccounts.length > 0 ? ledgerAccounts.map((option, index) => {
                    return <div className="select-content-item" key={index} onClick={() => optionClickHandler(option)}>{option.text}</div>
                }) : null}
                {!error && !isLoading && ledgerAccounts.length === 0 ? <div className="select-content-item" >{t('filters.no_ledger_account_found')} </div>
                    : null}
                {error ? <div className="select-content-item" >{t('create_expense.search_error')} </div>
                    : null}
                {isLoading ? <div className="select-content-item" ><div className="lds-ellipsis" style={{ marginLeft: 'calc(50% - 30px)' }}><div></div><div></div><div></div><div></div></div></div>
                    : null}
            </Slidebar>
        </div>
        </div>
    )
})

export default LedgerAccountAutocomplete;
