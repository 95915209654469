import ModalContainer from 'app/component/modal/ModalContainer';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {getAttachmentsContent} from 'services/ApiService';
import {loaderLock, loaderUnlock} from 'store/common/actions';
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import FileSaver from "file-saver";

interface AttachedImagePreviewProps {
  isOpen: boolean,
  onClose: () => void,
  itemId: number,
  size?: string;
}

const AttachedImagePreview: React.FC<AttachedImagePreviewProps> = (props) => {
  let [imgData, setImgData] = useState('');

  const {t} = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    getImage()
  }, [props.isOpen]);

  const getImage = () => {
    if(props.isOpen && !imgData){
        dispatch(loaderLock());
        getAttachmentsContent(props.itemId, {
            responseType: 'arraybuffer'
        }).then((response) => {
            let buffer = Buffer.from(response.data, 'binary').toString('base64');
            setImgData(`data:${response.headers['content-type']};base64,${buffer}`);
        }).finally(() => {
            dispatch(loaderUnlock());
        });
    }
  };

    const clickDownloadHandler = async (id: number) => {
        let downland = await getAttachmentsContent(id, {responseType: 'blob'});
        let contentDisposition = downland.headers['content-disposition'];
        let fileName = contentDisposition.slice(contentDisposition.lastIndexOf('\'') + 1);
        FileSaver.saveAs(downland.data, decodeURI(fileName))
    };

  const downloadStyle: any = {
    position: 'absolute',
    top: '31px',
    right: '52px',
    display: 'block',
    width: '20px',
    height: '20px',
    cursor: 'pointer'
  };

  return (
      imgData ?
      <ModalContainer isOpen={props.isOpen} destroy={true} overlayClick={props.onClose}>
        <div className="box-modal preview-modal" id="add-attachment">
          <div style={downloadStyle} title={t('title.download')} onClick={() => clickDownloadHandler(props.itemId)}> <SvgIcon className={'icon icon-download'} href={'#svg_icon_download'}/></div>
          <div className="box-modal_close arcticmodal-close" title={t('title.close')}onClick={props.onClose}></div>
          <div className="box-modal-title" style={{paddingRight :'90px'}}>{t('modals.attach_file.attached_preview')}</div>
          <div className="image-modal-content">
            <img className={"preview-image"} src={imgData}/>
          </div>

        </div>
      </ModalContainer> : null
  );
}

export default AttachedImagePreview;
