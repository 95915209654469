import DevPanel from 'app/component/dev-panel/DevPanel';
import MenuHeader from 'app/component/menu/MenuHeader';
import NoticePanelButton from 'app/component/notice/NoticePanelButton';
import ProfileUserHeader from 'app/component/profile-user/ProfileUserHeader';
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import SupportRequest from 'app/component/modal/SupportRequest';
import React from 'react';
import { Link } from 'react-router-dom';

interface HeaderProps {
  accessDenied: boolean
}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <header className="header">
      <div className="container">
        <div className="header-wrap">
          <MenuHeader accessDenied={props.accessDenied}/>
          <ProfileUserHeader accessDenied={props.accessDenied}/>
          <div className="logo">{process.env.REACT_APP_IS_WHITE_LABEL === 'false' && <Link to='/' tabIndex={-1}><img src="/img/logo.svg" alt="logo"/></Link>}</div>
          <div className="logo-mob">{process.env.REACT_APP_IS_WHITE_LABEL === 'false' && <Link to='/'><img src="/img/mobile-logo.svg" alt="logo" /></Link>}</div>
          <div className="header-controls">
            {process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION' && !props.accessDenied ? (<Link className="header-controls__item" to="/Handbook" tabIndex={-1}>
              <SvgIcon className="icon icon-list-header" href="#svg_icon_list-header" />
            </Link>) : null}
            <SupportRequest />
            {!props.accessDenied && <NoticePanelButton />}
            {process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION' && !props.accessDenied ? <DevPanel /> : null}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;