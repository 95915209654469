/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Daily.css";
import { useTranslation } from "react-i18next";
import SvgIcon from "../svg-icon/SvgIcon";
import Select, { SelectOptionType } from "../select/Select";
import { useSelector } from "react-redux";
import If from "../util/If";
import { get } from "lodash";
import {
  getNextKey,
  formatter,
  enumerateDaysBetweenDates,
  mergeDatesToPeriods,
  numberFormatter,
  roundNumberToAccuracy,
  accountantDivineAmount,
  getCurrencieCode,
} from "app/utils";
import DatePickerCalendar from "../date-picker/DatePickerCalendar";
import moment from "moment";
import {
  getAdvanceReportApplication,
  getCommonCurrencies,
  getCommonPaymentForms,
} from "../../../store/selectors";
import {
  getCurrenciesConversion,
  getPerDiemCompanyById,
} from "../../../services/ApiService";

interface СalculationRowProps {
  dates: {
    date: Date;
    mark: number;
    isPrivateDay: boolean;
    isRepresentationExpenses: boolean;
    isFoodPresent: boolean;
    numberOfMeal: number;
  }[];
  initialAllDateResult: {
    day: Date;
    amountCurrencyId: number;
    currencyCode: string;
    amountCurrency: number;
    currencyRate: number;
    amountRub: number;
    paymentForm: SelectOptionType;
    valuesParamByDay: {
      parameterId: number;
      parameterValue: string;
    }[];
  }[];
  onChange: (dates: Date[], property: string, value: any) => void;
  from: Date;
  to: Date;
  needChange: Date;
  disabled?: boolean;
  companyId: number;
  permissions: string[];
}

const СalculationRow: React.FC<СalculationRowProps> = (props) => {
  const { t, i18n } = useTranslation();

  const paymentForms = useSelector(getCommonPaymentForms);
  const currencies = useSelector(getCommonCurrencies);
  const advanceReport = useSelector(getAdvanceReportApplication);

  let [allDateResult, setAllDateResult] = useState(
    [] as {
      id: number;
      dayStart: Date;
      dayEnd: Date;
      amountCurrencyId: number;
      currencyCode: string;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
      isOpenDatePicker: boolean;
    }[]
  );

  const generateAllDateResult = async () => {
    let newAllDateResult = [] as {
      id: number;
      dayStart: Date;
      dayEnd: Date;
      amountCurrencyId: number;
      currencyCode: string;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
      isOpenDatePicker: boolean;
    }[];

    let perDiemParametersResult = await getPerDiemCompanyById(props.companyId);
    let perDiemParametersMap = new Map<string, number>();
    perDiemParametersResult.data.forEach((item: any) =>
      perDiemParametersMap.set(get(item, "code", ""), get(item, "id", 0))
    );
    let privatDayId = perDiemParametersMap.get("IsPrivateDay");

    let dateResult = [
      ...props.initialAllDateResult.filter((item) => {
        let isPrivat = false;
        for (
          let valuesParamIndex = 0;
          valuesParamIndex < item.valuesParamByDay.length;
          valuesParamIndex++
        ) {
          if (
            privatDayId ===
              item.valuesParamByDay[valuesParamIndex].parameterId &&
            item.valuesParamByDay[
              valuesParamIndex
            ].parameterValue.toLowerCase() === "true"
          ) {
            isPrivat = true;
            break;
          }
        }
        return item.amountCurrency > 0 && !isPrivat;
      }),
    ];
    dateResult.sort((a, b) => +a.day - +b.day);

    if (dateResult.length === 0) {
      setAllDateResult(newAllDateResult);
      return;
    }

    let currencyMap = [] as string[];
    for (let index = 0; index < dateResult.length; index++) {
      let element = dateResult[index];
      if (!currencyMap.includes(element.currencyCode + element.currencyRate)) {
        currencyMap.push(element.currencyCode + element.currencyRate);
      }
    }

    let currencyDateMap = [];

    for (let index = 0; index < currencyMap.length; index++) {
      let elementCurrency = currencyMap[index];
      let currentCurrencyDay = [];

      for (let dayIndex = 0; dayIndex < dateResult.length; dayIndex++) {
        const elementDay = dateResult[dayIndex];
        if (
          elementDay.currencyCode + elementDay.currencyRate ===
          elementCurrency
        ) {
          currentCurrencyDay.push(elementDay);
        }
      }

      currencyDateMap.push(currentCurrencyDay);
    }

    for (let index = 0; index < currencyDateMap.length; index++) {
      let element = currencyDateMap[index];

      let newPeriod = mergeDatesToPeriods(element.map((item) => item.day));
      newPeriod.forEach((item) => {
        let currentPeriod = {
          id: getNextKey("СalculationRow"),
          dayStart: item.from,
          dayEnd: item.to,
          amountCurrencyId: element[0].amountCurrencyId,
          currencyCode: element[0].currencyCode,
          amountCurrency: 0,
          currencyRate: element[0].currencyRate,
          amountRub: 0,
          paymentForm: element[0].paymentForm,
          isOpenDatePicker: false,
        };

        element
          .filter((day) => {
            return (
              +moment(item.from).format("YYYYMMDD") <=
                +moment(day.day).format("YYYYMMDD") &&
              +moment(day.day).format("YYYYMMDD") <=
                +moment(item.to).format("YYYYMMDD")
            );
          })
          .forEach((item) => {
            currentPeriod.amountCurrency += item.amountCurrency;
            currentPeriod.amountRub += item.amountRub;
          });

        newAllDateResult.push(currentPeriod);
      });
    }

    if (paymentForms.length === 1) {
      newAllDateResult.forEach((item) => {
        item.paymentForm.text =
          paymentForms[0].name[i18n.language as "en" | "ru"];
        item.paymentForm.value = paymentForms[0].id;
      });
    }
    setAllDateResult(
      newAllDateResult
        .filter((item) => item.amountRub !== 0)
        .sort((a, b) => +a.dayStart - +b.dayStart)
    );

    if (paymentForms.length === 1) {
      let newDates = props.initialAllDateResult
        .filter((item) => item.paymentForm.value !== paymentForms[0].id)
        .map((item) => item.day);
      if (newDates.length > 0) {
        props.onChange(newDates, "paymentForm", {
          value: paymentForms[0].id,
          text: paymentForms[0].name[i18n.language as "en" | "ru"],
        });
      }
    }
  };

  useEffect(() => {
    generateAllDateResult();
  }, [props.needChange]);

  const paymentFormOptions: SelectOptionType[] = paymentForms.map((form) => ({
    value: form.id,
    text: form.name[i18n.language as "en" | "ru"],
  }));

  const currencyOptions: SelectOptionType[] = currencies.data.map((item) => ({
    value: item.id,
    text: getCurrencieCode(item.alphaCode),
  }));

  const paymentFormChoseHandler = (
    option: SelectOptionType,
    row: {
      id: number;
      dayStart: Date;
      dayEnd: Date;
      amountCurrencyId: number;
      currencyCode: string;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
      isOpenDatePicker: boolean;
    }
  ) => {
    let startDay = +moment(row.dayStart).format("YYYYMMDD");
    let endDay = +moment(row.dayEnd).format("YYYYMMDD");

    let newDates = props.initialAllDateResult
      .filter((item) => {
        let currDay = +moment(item.day).format("YYYYMMDD");
        return startDay <= currDay && currDay <= endDay;
      })
      .map((item) => item.day);
    props.onChange(newDates, "paymentForm", option);

    let newAllDateResult = [...allDateResult];

    for (let index = 0; index < newAllDateResult.length; index++) {
      const element = newAllDateResult[index];
      if (element.id === row.id) {
        element.paymentForm = option;
        break;
      }
    }

    setAllDateResult(newAllDateResult);
  };

  const currencyChoseHandler = async (
    option: SelectOptionType,
    row: {
      id: number;
      dayStart: Date;
      dayEnd: Date;
      amountCurrencyId: number;
      currencyCode: string;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
      isOpenDatePicker: boolean;
    }
  ) => {
    let startDay = +moment(row.dayStart).format("YYYYMMDD");
    let endDay = +moment(row.dayEnd).format("YYYYMMDD");
    let currency = currencies.data.find((item) => item.id === option.value);
    let course = 1;

    if (currency && currency.alphaCode !== "rub") {
      let params = {
        dateRate: formatter(
          "YYYY-MM-DD",
          "ru",
          advanceReport.startApprovalDate
            ? advanceReport.startApprovalDate
            : advanceReport.createdAt
        ),
      };

      let response = await getCurrenciesConversion(currency.id, { params });
      if (response.headers.success) {
        course = response.data.rate;
      }
    }

    let newDates = props.initialAllDateResult
      .filter((item) => {
        let currDay = +moment(item.day).format("YYYYMMDD");
        return startDay <= currDay && currDay <= endDay;
      })
      .map((item) => item.day);
    if (currency) {
      props.onChange(newDates, "currency", {
        id: currency.id,
        alphaCode: currency.alphaCode,
        rate: course,
      });

      let newAllDateResult = [...allDateResult];

      for (let index = 0; index < newAllDateResult.length; index++) {
        const element = newAllDateResult[index];
        if (element.id === row.id) {
          element.amountCurrencyId = currency.id;
          element.currencyCode = currency.alphaCode;
          element.currencyRate = course;
          element.amountRub = roundNumberToAccuracy(
            element.amountCurrency * course,
            2
          );
          break;
        }
      }

      setAllDateResult(newAllDateResult);
    }
  };

  const amountChoseHandler = (
    event: any,
    row: {
      id: number;
      dayStart: Date;
      dayEnd: Date;
      amountCurrencyId: number;
      currencyCode: string;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
      isOpenDatePicker: boolean;
    }
  ) => {
    let amountString = event.target.value;
    let amount = 0;

    if (amountString.includes(",")) {
      amountString = numberFormatter(amountString, true);
    }

    if (!!+amountString) {
      amount = +amountString;
    }

    if (
      (!+amountString && amountString.trim() !== "") ||
      event.nativeEvent.data === "."
    ) {
      return;
    }

    let startDay = +moment(row.dayStart).format("YYYYMMDD");
    let endDay = +moment(row.dayEnd).format("YYYYMMDD");

    let newDates = props.initialAllDateResult
      .filter((item) => {
        let currDay = +moment(item.day).format("YYYYMMDD");
        return startDay <= currDay && currDay <= endDay;
      })
      .map((item) => item.day);

    if (newDates.length > 0) {
      let [amountAll, amountLast] = accountantDivineAmount(
        amount,
        newDates.length,
        2
      );

      if (newDates.length > 1) {
        props.onChange(
          newDates.filter((item, index) => {
            return index !== newDates.length - 1;
          }),
          "amount",
          amountAll
        );

        props.onChange([newDates[newDates.length - 1]], "amount", amountLast);
      } else {
        props.onChange(newDates, "amount", amount);
      }
    }

    let newAllDateResult = [...allDateResult];

    for (let index = 0; index < newAllDateResult.length; index++) {
      const element = newAllDateResult[index];
      if (element.id === row.id) {
        element.amountCurrency = amount;
        element.amountRub = roundNumberToAccuracy(
          element.currencyRate * amount,
          2
        );
        break;
      }
    }

    setAllDateResult(newAllDateResult);
  };

  const rateChoseHandler = (
    event: any,
    row: {
      id: number;
      dayStart: Date;
      dayEnd: Date;
      amountCurrencyId: number;
      currencyCode: string;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
      isOpenDatePicker: boolean;
    }
  ) => {
    let rateString = event.target.value;
    let rate = 0;

    if (rateString.includes(",")) {
      rateString = numberFormatter(rateString, true);
    }

    if (!!+rateString) {
      rate = +rateString;
    }

    if (
      (!+rateString && rateString.trim() !== "") ||
      event.nativeEvent.data === "." ||
      row.currencyCode.toLowerCase() === "rub"
    ) {
      return;
    }

    let startDay = +moment(row.dayStart).format("YYYYMMDD");
    let endDay = +moment(row.dayEnd).format("YYYYMMDD");

    let newDates = props.initialAllDateResult
      .filter((item) => {
        let currDay = +moment(item.day).format("YYYYMMDD");
        return startDay <= currDay && currDay <= endDay;
      })
      .map((item) => item.day);

    if (newDates.length > 0) {
      props.onChange(newDates, "rate", rate);
    }

    let newAllDateResult = [...allDateResult];

    for (let index = 0; index < newAllDateResult.length; index++) {
      const element = newAllDateResult[index];
      if (element.id === row.id) {
        element.currencyRate = rate;
        element.amountRub = roundNumberToAccuracy(
          element.amountCurrency * rate,
          2
        );

        break;
      }
    }

    setAllDateResult(newAllDateResult);
  };

  const addNewRow = () => {
    let newAllDateResult = [...allDateResult];
    newAllDateResult.push({
      id: getNextKey("СalculationRow"),
      dayStart: props.initialAllDateResult[0].day,
      dayEnd: props.initialAllDateResult[0].day,
      amountCurrencyId: 1,
      currencyCode: "rub",
      amountCurrency: 0,
      currencyRate: 1,
      amountRub: 0,
      paymentForm:
        paymentFormOptions.length === 1
          ? paymentFormOptions[0]
          : { value: 0, text: "---" },
      isOpenDatePicker: false,
    });

    setAllDateResult(newAllDateResult);
  };

  const deleteRow = (row: {
    id: number;
    dayStart: Date;
    dayEnd: Date;
    amountCurrencyId: number;
    currencyCode: string;
    amountCurrency: number;
    currencyRate: number;
    amountRub: number;
    paymentForm: SelectOptionType;
    isOpenDatePicker: boolean;
  }) => {
    let newAllDateResult = [
      ...allDateResult.filter((item) => row.id !== item.id),
    ];

    for (let index = 0; index < allDateResult.length; index++) {
      const element = allDateResult[index];
      if (element.id === row.id) {
        let date = enumerateDaysBetweenDates(
          element.dayStart,
          element.dayEnd,
          true
        );
        props.onChange(date, "all", {
          amountCurrencyId: 1,
          currencyCode: "rub",
          amountCurrency: 0,
          currencyRate: 1,
          amountRub: 0,
          paymentForm:
            paymentFormOptions.length === 1
              ? paymentFormOptions[0]
              : { value: 0, text: "---" },
        });

        break;
      }
    }

    setAllDateResult(newAllDateResult);
  };

  const dateChoseHandler = (
    date: any,
    row: {
      id: number;
      dayStart: Date;
      dayEnd: Date;
      amountCurrencyId: number;
      currencyCode: string;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
    }
  ) => {
    let newAllDateResult = [...allDateResult];

    if (date.startDate === date.endDate) {
      for (let index = 0; index < newAllDateResult.length; index++) {
        const element = newAllDateResult[index];
        if (element.id === row.id) {
          element.dayStart = date.startDate;
          element.dayEnd = date.endDate;
          element.isOpenDatePicker = false;
          break;
        }
      }
    } else {
      let newDate = enumerateDaysBetweenDates(
        date.startDate,
        date.endDate,
        true
      );

      let allDate = [] as Date[];
      allDateResult
        .filter((item) => item.id !== row.id)
        .forEach((item) => {
          allDate = [
            ...allDate,
            ...enumerateDaysBetweenDates(item.dayStart, item.dayEnd, true),
          ];
        });

      let oldMark = allDate.map((item) => +moment(item).format("YYYYMMDD"));

      newDate = newDate.filter(
        (item) => !oldMark.includes(+moment(item).format("YYYYMMDD"))
      );

      let newPeriod = mergeDatesToPeriods(newDate);

      if (newPeriod.length === 1) {
        for (let index = 0; index < newAllDateResult.length; index++) {
          const element = newAllDateResult[index];
          if (element.id === row.id) {
            element.dayStart = date.startDate;
            element.dayEnd = date.endDate;
            element.isOpenDatePicker = false;
            break;
          }
        }
      }
      if (newPeriod.length > 1) {
        for (let index = 0; index < newAllDateResult.length; index++) {
          const element = newAllDateResult[index];
          if (element.id === row.id) {
            element.dayStart = newPeriod[0].from;
            element.dayEnd = newPeriod[0].to;
            element.isOpenDatePicker = false;

            newPeriod
              .filter((item, index) => index !== 0)
              .forEach((item) => {
                newAllDateResult.push({
                  id: getNextKey("СalculationRow"),
                  dayStart: item.from,
                  dayEnd: item.to,
                  amountCurrencyId: element.amountCurrencyId,
                  currencyCode: element.currencyCode,
                  amountCurrency: element.amountCurrency,
                  currencyRate: element.currencyRate,
                  amountRub: element.amountRub,
                  paymentForm: element.paymentForm,
                  isOpenDatePicker: false,
                });
              });
            break;
          }
        }
      }
    }

    newAllDateResult.sort((a, b) => +a.dayStart - +b.dayStart);

    massChange(newAllDateResult);
    setAllDateResult(newAllDateResult);
  };

  const massChange = (
    newAllDateResult: {
      id: number;
      dayStart: Date;
      dayEnd: Date;
      amountCurrencyId: number;
      currencyCode: string;
      amountCurrency: number;
      currencyRate: number;
      amountRub: number;
      paymentForm: SelectOptionType;
      isOpenDatePicker: boolean;
    }[]
  ) => {
    newAllDateResult.forEach((item) => {
      let date = enumerateDaysBetweenDates(item.dayStart, item.dayEnd, true);
      let [amountCurrencyAll, amountCurrencyLast] = accountantDivineAmount(
        item.amountCurrency,
        date.length,
        2
      );
      let [amountRubAll, amountRubLast] = accountantDivineAmount(
        item.amountRub,
        date.length,
        2
      );

      if (date.length > 1) {
        props.onChange(
          date.filter((item, index) => {
            return index !== date.length - 1;
          }),
          "all",
          {
            amountCurrencyId: item.amountCurrencyId,
            currencyCode: item.currencyCode,
            amountCurrency: amountCurrencyAll,
            currencyRate: item.currencyRate,
            amountRub: amountRubAll,
            paymentForm: item.paymentForm,
          }
        );

        props.onChange([date[date.length - 1]], "all", {
          amountCurrencyId: item.amountCurrencyId,
          currencyCode: item.currencyCode,
          amountCurrency: amountCurrencyLast,
          currencyRate: item.currencyRate,
          amountRub: amountRubLast,
          paymentForm: item.paymentForm,
        });
      } else {
        props.onChange(date, "all", {
          amountCurrencyId: item.amountCurrencyId,
          currencyCode: item.currencyCode,
          amountCurrency: roundNumberToAccuracy(item.amountCurrency, 2),
          currencyRate: item.currencyRate,
          amountRub: roundNumberToAccuracy(item.amountRub, 2),
          paymentForm: item.paymentForm,
        });
      }
    });
  };

  const openDatePicker = (id: number) => {
    let newAllDateResult = [...allDateResult];

    for (let index = 0; index < newAllDateResult.length; index++) {
      const element = newAllDateResult[index];
      if (element.id === id) {
        element.isOpenDatePicker = true;
        break;
      }
    }

    setAllDateResult(newAllDateResult);
  };

  const closeDatePicker = (id: number) => {
    let newAllDateResult = [...allDateResult];

    for (let index = 0; index < newAllDateResult.length; index++) {
      const element = newAllDateResult[index];
      if (element.id === id) {
        element.isOpenDatePicker = false;
        break;
      }
    }

    setAllDateResult(newAllDateResult);
  };

  return (
    <>
      {allDateResult.map((item) => {
        let currenciSymbol = getCurrencieCode(item.currencyCode);
        let currenciSymbolRub = getCurrencieCode("rub");
        let disabledDates = props.dates
          .filter((item) => item.isPrivateDay)
          .map((item) => ({ from: item.date, to: item.date }));

        return (
          <div className="input-item-row input-item-row-wrap">
            <div
              className="input-item"
              style={{ width: "240px", minWidth: 0, maxWidth: "none" }}
            >
              <label className="input-label">
                {t("create_expense.daily_allowance.date")}
              </label>
              <input
                disabled={props.disabled}
                className="input"
                type="text"
                value={formatter(
                  "D MMMM",
                  i18n.language,
                  item.dayStart,
                  item.dayEnd,
                  { withMonth: false, withYear: false }
                )}
                onClick={() => {
                  openDatePicker(item.id);
                }}
                readOnly
              />
              <DatePickerCalendar
                isShown={item.isOpenDatePicker}
                isMultiChoice={true}
                isCleansed={true}
                month={props.from}
                onSelected={(value) => {
                  dateChoseHandler(value, item);
                }}
                onClose={() => {
                  closeDatePicker(item.id);
                }}
                disableBeforeDate={props.from}
                disableAfterDate={props.to}
                disabledDates={[
                  ...disabledDates,
                  ...allDateResult
                    .filter((row) => row.id !== item.id)
                    .map((item) => ({ from: item.dayStart, to: item.dayEnd })),
                ]}
              />
            </div>
            <div
              className="input-item"
              style={{ width: "60px", minWidth: 0, maxWidth: "none" }}
            >
              <label className="input-label">
                {t("create_expense.daily_allowance.currency")}
              </label>
              <Select
                disabled={props.disabled}
                options={currencyOptions}
                defaultValue={{
                  text: currenciSymbol,
                  value: item.amountCurrencyId,
                }}
                style={{ lineHeight: "38px" }}
                onChoose={(option) => {
                  currencyChoseHandler(option, item);
                }}
              />
            </div>
            <div
              className="input-item"
              style={{ width: "100px", minWidth: 0, maxWidth: "none" }}
            >
              <label className="input-label">
                {t("create_expense.daily_allowance.sum", {
                  currency: currenciSymbol,
                })}
              </label>
              <input
                disabled={props.disabled}
                className="input"
                type="number"
                value={roundNumberToAccuracy(item.amountCurrency, 2)}
                onChange={(event) => {
                  amountChoseHandler(event, item);
                }}
              />
            </div>
            <div
              className="input-item"
              style={{ width: "80px", minWidth: 0, maxWidth: "none" }}
            >
              <label className="input-label">
                {t("create_expense.daily_allowance.rate", {
                  currency: currenciSymbol,
                })}
              </label>
              <input
                disabled={props.disabled}
                className="input"
                type="number"
                value={roundNumberToAccuracy(item.currencyRate, 4)}
                onChange={(event) => {
                  rateChoseHandler(event, item);
                }}
              />
            </div>
            <div
              className="input-item"
              style={{ width: "100px", minWidth: 0, maxWidth: "none" }}
            >
              <label className="input-label">
                {t("create_expense.daily_allowance.sum", {
                  currency: currenciSymbolRub,
                })}
              </label>
              <input
                disabled={props.disabled}
                className="input"
                type="text"
                value={roundNumberToAccuracy(item.amountRub, 2)}
                readOnly
              />
            </div>
            <div
              className="input-item"
              style={{ width: "170px", minWidth: 0, maxWidth: "none" }}
            >
              <label className="input-label">
                {t("create_expense.daily_allowance.payment_form")}
              </label>
              <Select
                disabled={props.disabled}
                options={paymentFormOptions}
                defaultValue={{
                  text: item.paymentForm.text,
                  value: item.paymentForm.value,
                }}
                style={{ lineHeight: "38px" }}
                onChoose={(option) => {
                  paymentFormChoseHandler(option, item);
                }}
              />
            </div>
            <div className="input-item input-remove" style={{ width: "22px" }}>
              <If condition={!props.disabled}>
                <a
                  onClick={() => {
                    deleteRow(item);
                  }}
                  title={"Удалить"}
                >
                  <SvgIcon
                    className="icon icon-remove"
                    href="#svg_icon_remove"
                  />
                </a>
              </If>
            </div>
          </div>
        );
      })}

      <div className="tx-center">
        <If condition={!props.disabled}>
          <a className="btn-add-cost pointer" onClick={addNewRow}>
            <SvgIcon className="icon icon-plus" href="#svg_icon_plus" />
            {"добавить валюту"}
          </a>
        </If>
      </div>
    </>
  );
};

export default СalculationRow;
