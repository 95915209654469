import React, { Fragment, useState, useEffect } from 'react';
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import { useTranslation } from 'react-i18next';
import { ApprovalEmployee } from 'infrastructure/interfaces';
import { nameFormatter, NameFormats, dateFormatter } from 'app/utils';
import { AGREEMENT_RESOLUTION_TYPE } from 'infrastructure/enum/agreement-resolution-type.enum';

interface AgreementItemRowProps {
    user?: ApprovalEmployee,
    id: number,
    isAdvance?: boolean,
}

const AgreementItemRow: React.FC<AgreementItemRowProps> = (props) => {
    const { t, i18n } = useTranslation();

    let [statusIcon, setStatusIcon] = useState(<span></span>);

    let [userName, setUserName] = useState('');
    let [alternateName, setAlternateName] = useState('');
    let [resolutionDate, setResolutionDate] = useState('');
    let [costCenter, setCostCenter] = useState('');

    useEffect(() => {
        if (props.user) {
            switch (props.user.resolution) {
                case AGREEMENT_RESOLUTION_TYPE.APPROVED:
                    setStatusIcon(<SvgIcon className="icon icon-table-done" href="#svg_icon_table-done" />);
                    break;
                case AGREEMENT_RESOLUTION_TYPE.REVOKED:
                    setStatusIcon(<SvgIcon className="icon icon-table-error" href="#svg_icon_table-error" />);
                    break;
                default:
                    setStatusIcon(<SvgIcon className="icon icon-table-wait" href="#svg_icon_table-wait" />);
                    break;
            }

            setUserName(nameFormatter(props.user.employee, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language as 'ru'));
            if(!!props.user.approvedByName.ru && (props.user.approvedByName.ru != props.user.employee.ru)){
                setAlternateName(nameFormatter(props.user.approvedByName, NameFormats.LASTNAME_WITH_FULL_INITIALS, i18n.language as 'ru'));
            }

            setResolutionDate(dateFormatter(props.user.resolutionDate, 'DD.MM.YYYY', i18n.language as 'ru'));
            setCostCenter(props.user.costCenterCode);

        }
    }, [props.user, i18n.language]);

    return (
        <Fragment>
            <div className="list-agreement-table-row">
                <div className="td">
                    {statusIcon}{userName}
                    {alternateName ? <><br/>({alternateName})</> : null}                    
                </div>
                <div className="td">{props.user ? props.user.occupation[i18n.language as 'ru'] : ''}</div>
                <div className="td">{resolutionDate}</div>
                <div className="td">{costCenter}</div>
            </div>
        </Fragment>
    )
}

export default AgreementItemRow;