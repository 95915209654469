import { BaseMapper } from './base.mapper';
import { get } from 'lodash';
import { CurrencyList } from 'infrastructure/interfaces';
import { CurrencyItemMapper } from './CurrencyItem.mapper';

export class CurrencyListMapper extends BaseMapper<CurrencyList, any> {
  responseToEntity(response: any): CurrencyList {
    const currencyItemMapper = new CurrencyItemMapper();
    return {
        data: currencyItemMapper.responsesToEntitys(get(response, 'data', [])),
        itemsCount: get(response, 'itemsCount', 0),
        itemsPerPage: get(response, 'itemsPerPage', 0),
        pageNumber: get(response, 'pageNumber', 0)
    };
  };
}
