import { ActionCreator } from "redux";
import { SetSubstitutionList, SUBSTITUTION, SubstitutionActions } from "./actionsTypes";
import { Substitution } from "infrastructure/interfaces";
import { ApplicationState } from "store/reducers";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { SubstitutionMapper } from "infrastructure/mappers";
import { showErrors } from "store/exception/actions";
import {getEmployeeDeputiesOccupationCurrent} from "../../services/ApiService";

export const setSubstitutionList: ActionCreator<SetSubstitutionList> = (substitutionList: Substitution[], itemsCount: number, isAdd: boolean) => ({
    type: SUBSTITUTION.SET_LIST,
    payload: { substitutionList,
                itemsCount,
                isAdd}
})

export const updateSubstitutionList = (pageNumber: number = 1, isAdd: boolean = false, itemsPerPage: number = 20): ThunkAction<Promise<void>, ApplicationState, any, SubstitutionActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, SubstitutionActions>, getState): Promise<void> => {
        await dispatch(loaderLock());

        let params: any = {
            PageSize: itemsPerPage,
            Page: pageNumber
        }
        let response = await getEmployeeDeputiesOccupationCurrent({
            params: params
        });
        if(response.headers.success) {
            let substitutionMapper = new SubstitutionMapper(); 
            dispatch(setSubstitutionList(substitutionMapper.responsesToEntitys(response.data.data), response.data.itemsCount, isAdd));
        } else {
            await dispatch(showErrors({
                code: 'update_agreement_list',
                message: `Не удалось загрузить список замещений`
            }));
        }

        await dispatch(loaderUnlock());
    }
}