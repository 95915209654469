import React from 'react';
import { RouteComponentProps } from 'react-router';
import ListFilter from 'app/component/list-filter/ListFilter';

interface ReportFilterProps extends RouteComponentProps<any> {
}

const ReportFilter: React.FC<ReportFilterProps> = (props) => {
  return <ListFilter isAdvance/>
}

export default ReportFilter;