import { BaseMapper } from './base.mapper';
import { ApplicationForExpenseDetail } from 'infrastructure/interfaces';
import { get, sortBy } from 'lodash';

export class ApplicationForExpenseDetailMapper extends BaseMapper<ApplicationForExpenseDetail, any> {
    responseToEntity(response: any): ApplicationForExpenseDetail {
        //fixMe когда вернуться корректные данные с бэкенда удалить данные заглушки
        const assigneeEmployee = {
            id: 0,
            logicalName: '',
            phoneNumber: '',
            email: '',
            name: {
                ru: '',
                en: ''
            },
            occupationId: 0
        };

        const createdByEmployee = {
            id: 5,
            logicalName: 'Stub',
            name: {
                ru: 'Заглушка Заглушка Заглушка',
                en: 'Stub Stub Stub'
            }
        };

        const expenseType = {
            id: 1,
            logicalName: 'Stub',
            name: {
                ru: 'Заглушка Заглушка Заглушка',
                en: 'Stub Stub Stub'
            }
        };

        const paymentForm = {
            id: 5,
            logicalName: 'Stub',
            name: {
                ru: 'Заглушка Заглушка Заглушка',
                en: 'Stub Stub Stub'
            }
        };

        const amountCurrency = {
            numCode: 'Stub',
            name: 'Stub Stub Stub'
        };

        const company = {
            id: 5,
            logicalName: 'Stub',
            name: {
                ru: 'Заглушка Заглушка Заглушка',
                en: 'Stub Stub Stub'
            }
        };

        const businessTarget = {
            id: 5,
            logicalName: 'Stub',
            name: {
                ru: 'Заглушка Заглушка Заглушка',
                en: 'Stub Stub Stub'
            }
        };

        const lineManager = {
            id: 5,
            logicalName: 'Stub',
            name: {
                ru: 'Заглушка Заглушка Заглушка',
                en: 'Stub Stub Stub'
            }
        };

        const customProperties = [{
            name: {
                ru: 'Заглушка',
                en: 'Stub'
            },
            value: {
                ru: 'Заглушка',
                en: 'Заглушка'
            }
        }];

        const departureFromCity = {
            id: 5,
            logicalName: 'Stub',
            name: {
                ru: 'Заглушка Заглушка Заглушка',
                en: 'Stub Stub Stub'
            }
        };

        const arrivalToCity = {
            id: 5,
            logicalName: 'Stub',
            name: {
                ru: 'Заглушка Заглушка Заглушка',
                en: 'Stub Stub Stub'
            }
        };

        // const objectId = {
        //     id: 5,
        //     logicalName: 'Stub',
        //     name: {
        //         ru: 'Заглушка Заглушка Заглушка',
        //         en: 'Stub Stub Stub'
        //     }
        // };
        //
        // const expenseApplicationTravelDates = [{
        //     id: 123123,
        //     startDate: "1000-01-01T00:00:00",
        //     endDate: "1000-01-01T00:00:00"
        // }];
        //
        // const costCenters = [{
        //     id: 123123,
        //     name: {
        //         ru: 'заглушка',
        //         en: 'stub'
        //     },
        //     percent: 0
        // }];

        return {
            id: get(response, 'id', 123123),
            applicationType: {
                id: get(response, 'applicationType.id', 123123),
                code: get(response, 'applicationType.code', 'Stub'),
                name: {
                    ru: get(response, 'applicationType.name.ru', 'заглушка'),
                    en: get(response, 'applicationType.name.en', 'stub')
                }
            },
            number: get(response, 'number', 123123),
            createdByEmployee: response.createdByEmployee ? {
                id: get(response, 'createdByEmployee.id', 0),
                logicalName: get(response, 'createdByEmployee.logicalName', ''),
                name: {
                    ru: get(response, 'createdByEmployee.name.ru', ''),
                    en: get(response, 'createdByEmployee.name.en', '')
                }
            } : createdByEmployee,
            assigneeEmployee: response.assigneeEmployee ? {
                id: get(response, 'assigneeEmployee.id', 123123),
                phoneNumber: response.assigneeEmployee.phoneNumber ? response.assigneeEmployee.phoneNumber : '',//get(response, 'assigneeEmployee.phoneNumber',  'stub'),
                email: response.assigneeEmployee.email ? response.assigneeEmployee.email : '',//get(response, 'assigneeEmployee.email',  'stub@stub'),
                name: {
                    ru: get(response, 'assigneeEmployee.name.ru', ''),
                    en: get(response, 'assigneeEmployee.name.en', '')
                },
                occupationId: get(response, 'assigneeEmployee.occupationId', 0)
            } : assigneeEmployee,
            createdAt: get(response, 'createdAt', 'stub'),
            businessTarget: response.businessTarget ? {
                id: get(response, 'businessTarget.id', 123123),
                logicalName: get(response, 'businessTarget.logicalName', 'stub'),
                name: {
                    ru: get(response, 'businessTarget.name.ru', 'Заглушка'),
                    en: get(response, 'businessTarget.name.en', 'stub')
                }
            } : businessTarget,
            description: get(response, 'description', 'stub'),
            vbsElement: get(response, 'vbsElement', 'stub'),
            company: response.company ? {
                id: get(response, 'company.id', 123123),
                logicalName: get(response, 'company.logicalName', 'stub'),
                name: {
                    ru: get(response, 'company.name.ru', 'заглушка'),
                    en: get(response, 'company.name.en', 'stub')
                }
            } : company,
            status: get(response, 'status', 'Draft'),
            isActive: get(response, 'isActive', false),
            advanceCashAmount: get(response, 'advanceCashAmount'),
            advanceCashAmountIsEdited: get(response, 'advanceCashAmountIsEdited'),
            totalAmount: get(response, 'totalAmount', 10000000),
            lineManager: response.lineManager ? {
                id: get(response, 'lineManager.id', 123123),
                logicalName: get(response, 'lineManager.logicalName', 'stub'),
                name: {
                    ru: get(response, 'lineManager.name.ru', 'заглушка'),
                    en: get(response, 'lineManager.name.en', 'stub')
                }
            } : lineManager,
            customProperties: response.customProperties ? response.customProperties.map((item: any) => ({
                name: {
                    ru: get(item, 'name.ru', 'Заглушка'),
                    en: get(item, 'name.en', 'Stub')
                },
                value: {
                    ru: get(item, 'value.ru', 'Заглушка'),
                    en: get(item, 'value.en', 'Заглушка')
                }
            })) : customProperties,
            orderNumber: get(response, 'orderNumber', 'stub'),
            approvedAmount: get(response, 'approvedAmount', 'costApproved[Stub]'),
            journeyNumber: get(response, 'journeyNumber', 123123),
            businessTripNumber: get(response, 'businessTripNumber', 123123),
            departureFromCity: response.departureFromCity ? {
                id: get(response, 'departureFromCity.id', 123123),
                logicalName: get(response, 'departureFromCity.logicalName', 'stub'),
                name: {
                    ru: get(response, 'departureFromCity.name.ru', 'заглушка'),
                    en: get(response, 'departureFromCity.name.en', 'stub'),
                }
            } : departureFromCity,
            arrivalToCity: response.arrivalToCity ? {
                id: get(response, 'arrivalToCity.id', 123123),
                logicalName: get(response, 'arrivalToCity.logicalName', 'stub'),
                name: {
                    ru: get(response, 'arrivalToCity.name.ru', 'заглушка'),
                    en: get(response, 'arrivalToCity.name.en', 'stub'),
                }
            } : arrivalToCity,
            startOn: get(response, 'startOn', "1000-01-01T00:00:00"),
            endOn: get(response, 'endOn', "1000-01-01T00:00:00"),
            businessTripDestinations: sortBy(get(response, 'businessTripDestinations', []).map((road: any) => {
                return {
                    city: {
                        id: get(road, 'city.id', -1),
                        name: {
                            ru: get(road, 'city.name.ru', ''),
                            en: get(road, 'city.name.en', '')
                        }
                    },
                    arrivalOn: get(road, 'arrivalOn', -1),
                    leaveOn: get(road, 'leaveOn', -1),
                    ordinal: get(road, 'ordinal', -1)
                }
            }), ['ordinal']),
            personalDaysNumber: get(response, 'personalDaysNumber', 0),
            expenseApplicationTravelDates: response.expenseApplicationTravelDates.length ? response.expenseApplicationTravelDates.map((item: any) => ({
                id: get(item, 'id', 123123),
                startDate: get(item, 'startDate', "1000-01-01T00:00:00"),
                endDate: get(item, 'endDate', "1000-01-01T00:00:00"),
            })) : response.expenseApplicationTravelDates,
            //properties: response.properties.length ? response.properties.map((item: any) => item) : [],
            expenses: response.expenses.length ? response.expenses.map((item: any) => ({
                id: get(item, 'id', 123123),
                relations: item.relations.length > 0 ? item.relations.map((relation: any) => ({
                    relatedExpenseId: get(relation, 'relatedExpenseId', 0),
                    type: get(relation, 'type', '')
                })) : item.relations,
                expenseType: item.expenseType ? {
                    id: get(item, 'expenseType.id', 123123),
                    logicalName: get(item, 'expenseType.logicalName', 'Expense'),
                    expenseGroup:{
                        code: get(item, 'expenseType.expenseGroup.code', 'Other')
                    },
                    name: {
                        ru: get(item, 'expenseType.name.ru', 'заглушка'),
                        en: get(item, 'expenseType.name.en', 'заглушка')
                    },
                    groupCode: get(item, 'expenseType.groupCode', 'Other'),
                    code: get(item, 'expenseType.code', 'Other')
                } : expenseType,
                external: get(item, 'external', false),
                isActive: get(item, 'isActive', false),
                advanceCashRequired: get(item, 'advanceCashRequired', true),
                amount: get(item, 'amount', 123123),
                amountCurrency: item.amountCurrency ? {
                    numCode: get(item, 'amountCurrency.numCode', 123123),
                    name: get(item, 'amountCurrency.name', 'stub'),
                    id: get(item, 'amountCurrency.id', 0)
                } : amountCurrency,
                amountRub: get(item, 'amountRub', 123123),
                paymentForm: item.paymentForm ? {
                    id: get(item, 'paymentForm.id', 123123),
                    logicalName: get(item, 'paymentForm.logicalName', 'stub'),
                    name: {
                        ru: get(item, 'paymentForm.name.ru', 'заглушка'),
                        en: get(item, 'paymentForm.name.en', 'stub')
                    }
                } : paymentForm,
                status: get(item, 'status', 'Draft'),
                description: get(item, 'description', 'stub'),
                notUsed: get(item, 'notUsed', false),
                showNotAttachmentAlert: get(item, 'showNotAttachmentAlert', false),
                accountableFormNumber: get(item, 'accountableFormNumber', ''),
                properties: get(item, 'properties', [])
            })) : [],
            // costCenters: response.costCenters.length ? response.costCenters.map((item: any) => ({
            //     id: get(item, 'id', 123123),
            //     name: {
            //         ru: get(item, 'name.ru', 'заглушка'),
            //         en: get(item, 'name.en', 'stub')
            //     },
            //     percent: get(item, 'percent', 123123)
            // })) : costCenters,
            paperWork: {
                id: get(response, 'paperWork.id', 0),
                employeeId: get(response, 'paperWork.employeeId', 0),
                employeeName: {
                    ru: get(response, 'paperWork.employeeName.ru', ''),
                    en: get(response, 'paperWork.employeeName.en', ''),
                },
                startedOn: get(response, 'paperWork.startedOn', 0),
            },
            postingDate: get(response, 'postingDate', null),
            properties: get(response, 'properties', []),
        };
    };
}
