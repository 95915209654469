import api from './interceptor';

export async function postConfigList() {
    return await api.post('/Config/list', {
        configCodes: [
            'AbilityUseOrNotExpense',
            'AdvanceReportAmountFullCalculation',
            'DisplayButtonPrintDocsAfterApprove',
            'hideVbsElementAndOrderNumber',
            'hideCostCentersBlock',//?
            'dependApprovedAmountCommentFromApprovedAmount',
            'CostCenterPercentageSplit',//?
            'PerDiemCalculationType',
            'EditCostExternalExpense',
            'AssistanceSource',
            'IsAvailableCurrencyForApprovedAmount',
            'EmployeeDeputiesSource',
            'AdvanceReportAmountCalculation',
            'AdvanceCalculationMethod',
            'LinkToTimeEnabled'
        ]
    });
}

export async function getCurrentEmployee() {
    return await api.get(`/Employees/current`);
}

export async function getAttachmentPermission() {
    return await api.get(`/settings/attachmentPermission`)
}

export async function getManualExpenseCurrencyChange() {
    return await api.get(`/settings/ManualExpenseCurrencyChange`)
}

export async function getCompanies() {
    return await api.get(`/companies`)
}

export async function getCompaniesWithParams(params: any) {
    return await api.get(`/companies`, params)
}

export async function getAssistances(params: any) {
    return await api.get(`/assistances`, params);
}

export async function getAssistancesById(id: number) {
    return await api.get(`/assistances/${id}`);
}

export function postAssistances(params: any): Promise<void> {
    return api.post(`/assistances`, params);
}

export function deleteAssistancesById(id: number): Promise<void> {
    return api.delete(`/assistances/${id}`);
}

export async function deleteAssistancesByOccupationId(id: number) {
    return await api.delete(`/assistances/occupation/${id}`);
}

export async function getEmployeeByParams(params: any) {
    return await api.get('/employees', params);
}

export async function getClearingCanread() {
    return await api.get('/clearing/canread');
}

export async function getClearing(params: any) {
    return await api.get('/clearing', params);
}

export async function deleteBatchesById(id: string | number | undefined) {
    return await api.delete(`/batches/${id}`)
}

export async function getExpenseApplicationOpen(params: any) {
    return await api.get(`/ExpenseApplications/open`, params);
}

export async function updateUserSettings(id: number, params: any) {
    return await api.put(`/Employees/${id}/settings`, params);
}

export async function deleteAdvanceReportAdditionalDocumentsById(id: number) {
    return await api.delete(`/AdvanceReportAdditionalDocuments?id=${id}`);
}

export async function updateApprovalsAdvanceReportApplicationMarkAsReadById(id: number) {
    return await api.put(`/Approvals/read?id=${id}&logicalName=AdvanceReportApplication`);
}

export async function updateApprovalsAdvanceReportApplicationMarkAsUnreadById(id: number) {
    return await api.put(`/Approvals/unread?id=${id}&logicalName=AdvanceReportApplication`);
}

export async function updateApprovalsExpenseMarkAsReadById(id: number) {
    return await api.put(`/Approvals/read?id=${id}&logicalName=ExpenseApplication`);
}

export async function updateApprovalsExpenseMarkAsUnreadById(id: number) {
    return await api.put(`/Approvals/unread?id=${id}&logicalName=ExpenseApplication`);
}

export async function updateAdvanceReportApplicationCancelById(id: number) {
    return await api.put(`/advancereportapplications/${id}/cancel`);
}

export async function updateExpensesCancelById(id: number) {
    return await api.put(`/ExpenseApplications/${id}/cancel`);
}

export async function updateExpensesById(id: number | undefined, data: any) {
    return await api.put(`/ExpenseApplications/${id}`, data);
}

export async function updateAdvanceReportApplicationsById(id: number, data: any) {
    return await api.put(`/AdvanceReportApplications/${id}`, data);
}

export async function postPaperWorks(params: any) {
    return await api.post(`/PaperWorks`, params);
}

export async function updatePaperWorks(params: any) {
    return await api.put(`/PaperWorks`, params);
}

export async function deleteExpensesById(id: number) {
    return await api.delete(`/Expenses/${id}`);
}

export async function updatePerDiemCalculations(data: any) {
    return await api.put(`/PerDiemCalculations`, data);
}

export async function getLogicalName(params: any) {
    return await api.get(`/CustomProperties/logicalnames`, params)
}

export async function getCustomProperties(params: any) {
    return await api.get(`/customproperties`, params)
}

export async function getCustomPropertiesCodes(params: any) {
    return await api.get(`/CustomPropertyValues/codes`, params)
}

export async function getCustomPropertiesDependencies(params: any) {
    return await api.get(`/customPropertyListValues/dependencies`, params)
}

export async function getCustomPropertiesValuesByIds(params: any) {
    return await api.get(`/CustomPropertyListValues/byids`, params)
}

export async function getCustomPropertiesById(id: number) {
    return await api.get(`/CustomProperties/${id}`);
}

export async function getLinkToExternalApplication(journeyNumber: number, businessTripNumber: number) {
    return await api.get(`/LinkToExternalApplication/Time?journeyNumber=${journeyNumber}&businessTripNumber=${businessTripNumber}`);
}

export async function deleteEmployeeDeputiesById(id: number) {
    return await api.delete(`/EmployeeDeputies/${id}`);
}

export async function getEmployeeDeputiesById(id: number | undefined) {
    return await api.get(`/EmployeeDeputies/${id}`);
}

export async function postEmployeeDeputiesById(data: any) {
    return await api.post(`/EmployeeDeputies`, data);
}

export async function updateEmployeeDeputiesById(id: number | undefined, data: any) {
    return await api.put(`/EmployeeDeputies/${id}`, data);
}

export async function getCurrenciesConversion(id: number, params: any) {
    return await api.get(`/Currencies/${id}/Conversion`, params);
}

export async function updateExpenseApplicationsAdvanceEdit(id: number, params: any) {
    return await api.put(`/ExpenseApplications/${id}/AdvanceEdit`, params);
}

export async function getApprovalsDetails(params: any) {
    return await api.get(`/approvals/details`, params);
}

export async function getApprovalsByParams(filterParams: any, params: any) {
    return await api.get(`/approvals${filterParams}`, params);
}

export async function getExpensesApplicationsByParams(filterParams: any, params: any) {
    return await api.get(`/ExpenseApplications${filterParams}`, params);
}

export async function getExpensesById(id: number) {
    return await api.get(`/ExpenseApplications/${id}`);
}

export async function getApiState(params: any) {
    return await api.get(`/state`, params);
}

export async function getExpenseType(params: any) {
    return await api.get(`/ExpenseTypes`, params);
}

export async function getPaymentForms(params: any) {
    return await api.get(`/paymentForms/`, params);
}

export async function getApplicationTypes(params: any) {
    return await api.get('/ApplicationTypes', params);
}

export async function getCurrencies(params: any) {
    return await api.get(`/Currencies`, params);
}

export async function getBusinessTargets(params: any) {
    return await api.get('/BusinessTargets', params);
}

export async function getTransactions(params: any) {
    return await api.get('/transactions', params);
}

export async function getAttachments(params: any) {
    return await api.get('/Attachments', params);
}

export async function getBatches(params: any) {
    return await api.get(`/batches`, params);
}

export async function getBatchesInternal(params: any, requestParams: any) {
    return await api.get(`/batches/internal/${params}`, requestParams);
}

export async function getBatchesInternalWithParams(params: any) {
    return await api.get(`/batches/internal`, params);
}

export async function getBatchesById(id: number, params: any) {
    return await api.get(`/batches/${id}`, {params})
}

export async function postConnectToken(params: any) {
    return await api.post('/connect/token', params);
}

export async function getAdvanceReportApplications(id: number) {
    return await api.get(`/advancereportapplications/${id}`);
}

export async function getAdvanceReportApplicationsWithParams(filterParams: any, params: any) {
    return await api.get(`/advancereportapplications${filterParams}`, params);
}

export async function getExpenseApplicationsAdvanceCashAmount(id: number) {
    return await api.get(`/ExpenseApplications/${id}/AdvanceCashAmount`);
}

export async function postAttachments(formData: any, config: any) {
    return await api.post('/Attachments', formData, config);
}

export async function putAttachmentsById(id: number, params: any) {
    return await api.put(`/Attachments/${id}`, params);
}

export async function deleteAttachmentsById(id: number) {
    return await api.delete(`/Attachments/${id}`);
}

export async function getAttachmentsPreview(id: number, params: any) {
    return await api.get(`/Attachments/${id}/preview`, params);
}

export async function getAttachmentsContent(id: number, params: any) {
    return await api.get(`/Attachments/${id}/content`, params);
}

export async function getCities(params: any) {
    return await api.get('/cities', params);
}

export async function getApiCostCenters(params: any) {
    return await api.get('/CostCenters', params);
}

export async function getApiLocations(params: any) {
    return await api.get('/locations', params);
}

export async function getApiLegalEntity(params: any) {
    return await api.get('/legalEntity', params);
}

export async function getApiLedgerAccounts(params: any) {
    return await api.get('/LedgerAccounts', params);
}

export async function getApiOrgUnit(params: any) {
    return await api.get('/orgUnit', params);
}

export async function updateApprovalsById(id: number, params: any) {
    return await api.put(`/approvals/${id}`, params);
}

export async function postApprovals(id: number, logicalName: string) {
    return await api.post(`/approvals/${logicalName}/${id}`);
}

export async function getApprovalsByObject(params: any) {
    return await api.get(`/approvals/byObject`, params);
}

export async function updateApprovalsItems(approvalSheetId: number, sheetId: number, params: any) {
    return await api.put(`/approvals/${approvalSheetId}/items/${sheetId}`, params);
}

export async function updateApprovalsReNew(approvalSheetId: number, params: any) {
    return await api.put(`/approvals/${approvalSheetId}/renew`, params);
}

export async function postAdvanceReportApplications(id: number, params?: any) {
    return await api.post(`/advancereportapplications/${id}`, params);
}

export async function postApiTransactions(params: any) {
    return await api.post('/transactions', params);
}

export async function updateApiTransactions(params: any) {
    return await api.put('/transactions', params);
}

export async function updateApiTransactionsById(id: number, params: any) {
    return await api.put(`/transactions/${id}`, params);
}

export async function updateBatchesArchive(id: number, logicalName: string, params: any) {
    return await api.put(`/batches/archive?Id=${id}&LogicalName=${logicalName}`, params);
}

export async function postTransactionsDownload(data: any, params: any) {
    return await api.post('/transactions/download', data, params);
}

export function postBatches(data: any, params: any) {
    return api.post('/Batches', data, params);
}

export async function postCommunications(postData: any) {
    return await api.post('/communications', postData);
}

export async function getCommunicationsByLogicalName(logicalName: string, params: any) {
    return await api.get('/communications/' + logicalName, params);
}

export async function postCustomPropertyValuesByObject(data: any) {
    return await api.post(`/CustomPropertyValues/byobject`, data);
}

export async function updateCustomPropertyValues(data: any) {
    return await api.put(`/CustomPropertyValues`, data);
}

export async function getCustomPropertyListValues(params: any) {
    return await api.get(`/customproperties/listvalues`, params);
}

export async function getCustomPropertyListValuesDependencies(params: any) {
    return await api.get(`/customPropertyListValues/dependencies`, params);
}

export async function getPerDiemCompanyById(id: number) {
    return await api.get(`/PerDiemParameters/Company/${id}`);
}

export async function getPerDiemCalculationsByObjectDetails(documentId: number, isAdvance: string) {
    return await api.get(`/PerDiemCalculations/byObject/details?Id=${documentId}&LogicalName=${isAdvance}`);
}

export async function postPerDiemBudgeting(documentId: number, isAdvance: string, data: any) {
    return await api.post(`/PerDiemBudgeting/preview?Id=${documentId}&LogicalName=${isAdvance}`, data);
}

export async function getHistoryByLogicalRout(logicalRout: string, objectId: number, params: any) {
    return await api.get(`/${logicalRout}/${objectId}/history`, params);
}

export async function getCitiesByCitiesById(cityRequest: string) {
    return await api.get(`Cities/bycities?ids=${cityRequest}`);
}

export async function postTransactionsDownloadWithParams(isDownloadAllChosen: string, data: any, params: any) {
    return await api.post(`/transactions/download${isDownloadAllChosen}`, {}, params);
}

export async function updateBatchesArchiveBulk(byFilter: string, data: any, params: any) {
    return await api.put(`/Batches/archive/bulk${byFilter}`, data, params);
}

export async function getAdvanceReportApplicationReportsTypesByCompanyId(companyId: number, id: number, expenseApplicationId: number) {
    return await api.get(`/AdvanceReportApplications/reports/types?companyId=${companyId}&advanceReportApplicationId=${id}&expenseApplicationId=${expenseApplicationId}`);
}

export async function getExpenseApplicationsReportsTypesByCompanyId(companyId: number, expenseApplicationId: number) {
    return await api.get(`/ExpenseApplications/reports/types?companyId=${companyId}&expenseApplicationId=${expenseApplicationId}`);
}

export async function getAdvanceReportApplicationByIdReportsType(id: number, type: string, extension: string, utcOffset: number, params: any) {
    return await api.get(`/AdvanceReportApplications/${id}/reports/${type}/${extension}?utcOffset=${utcOffset}?`, params)
}

export async function getExpenseApplicationsByIdReportsType(id: number, type: string, extension: string, utcOffset: number, params: any) {
    return await api.get(`/ExpenseApplications/${id}/reports/${type}/${extension}?utcOffset=${utcOffset}?`, params)
}

export async function getFieldPermissionsUpdateAdvanceReportApplications(id: number) {
    return await api.get(`/FieldPermissions/update/AdvanceReportApplication/${id}`);
}

export async function getFieldPermissionsUpdateExpenseApplications(id: number) {
    return await api.get(`/FieldPermissions/update/ExpenseApplication/${id}`);
}

export async function getFieldPermissionsUpdateExpense(id: number) {
    return await api.get(`/FieldPermissions/update/Expense/${id}`);
}

export async function getFieldPermissionsCreateAdvanceReportApplications() {
    return await api.get(`/FieldPermissions/create/AdvanceReportApplication`);
}

export async function getFieldPermissionsCreateExpense(params: any) {
    return await api.get(`/FieldPermissions/create/Expense`, params);
}

export async function getFieldPermissionsCreateExpenseApplications() {
    return await api.get(`/FieldPermissions/create/ExpenseApplication`);
}

export async function getOccupationsShortByEmployeeId(id: number, companyId: number) {
    return await api.get(`/Occupations/short?employeeId=${id}&companyId=${companyId}`);
}

export async function updateAdvanceReportApplicationsByIdWithParams(id: number | undefined, params: any) {
    return await api.put(`/advancereportapplications/${id}`, params);
}

export async function postExpenseApplications(expenseApplications: any) {
    return await api.post('/ExpenseApplications', expenseApplications);
}

export async function getExpensesApiById(id: any) {
    return await api.get(`/Expenses/${id}`);
}

export async function updateAdvanceReportAdditionalDocumentsAttach(value: string | number | undefined, id: any) {
    return await api.put(`AdvanceReportAdditionalDocuments/${value}/attach/${id}`);
}

export async function updateAdvanceReportAdditionalDocumentsDetach(value: string | number | undefined, id: any) {
    return await api.put(`AdvanceReportAdditionalDocuments/${value}/detach/${id}`);
}

export async function postPerDiemCalculations(data: any) {
    return await api.post(`/PerDiemCalculations`, data);
}

export async function postExpenses(data: any) {
    return await api.post(`/Expenses`, data);
}

export async function updateExpensesByIdUsed(id: number, state: boolean, config: any) {
    return await api.put(`/Expenses/${id}/used`, state, config);
}

export async function updateExpensesApiById(id: number | null | undefined, data: any) {
    return await api.put(`/Expenses/${id}`, data);
}

export async function getFieldPermissionsUpdateTransactions(id: number) {
    return await api.get(`/FieldPermissions/update/Transaction/${id}`);
}

export async function getTaxCodes() {
    return await api.get(`/TaxCodes`);
}

export async function getExpenseApplicationsCustomProperties(id: number) {
    return await api.get(`/expenseapplications/${id}/customproperties`);
}

export async function getAdvanceReportApplicationCustomProperties(id: number) {
    return await api.get(`/advancereportapplications/${id}/customproperties`);
}

export async function updateEmployeesSettings(id: number, params: any) {
    return await api.put(`/Employees/${id}/settings`, params);
}

export async function postAdvanceReportApplicationsReports(id: number) {
    return await api.post(`/AdvanceReportApplications/${id}/reports/report`)
}

export async function postSupports(formData: any, config: any) {
    return await api.post('/Supports', formData, config);
}

export async function getNotificationsWithParams(params: any) {
    return await api.get('/notifications', params);
}

export async function updateNotificationsById(id: number, params: any) {
    return await api.put(`/notifications/${id}`, params);
}

export async function updateNotifications(params: any) {
    return await api.put(`/notifications/`, params);
}

export async function getAdvanceReportApplicationsReportRegistry(params: any) {
    return await api.get('/advancereportapplications/reports/registry', params);
}

export async function getAdvanceReportApplicationsReportDocumentsById(id: number) {
    return await api.get(`AdvanceReportAdditionalDocuments/${id}`);
}

export async function getCustomPropertyValuesCodesByObjectLogicalNameId(objectLogicalNameId: number, occupationId: number) {
    return await api.get(`CustomPropertyValues/codes?ObjectLogicalNameId=${objectLogicalNameId}&ExternalObjectId=${occupationId}&CustomPropertyCodes=POSITION`);
}

export async function getEmployeeDeputiesOccupationCurrent(params: any) {
    return await api.get('/EmployeeDeputies/occupation/current', params);
}

export async function getFieldPermissionsUpdateAdvanceReportAdditionalDocument(id: number) {
    return await api.get(`/FieldPermissions/update/AdvanceReportAdditionalDocument/${id}`);
}

export async function getFieldPermissionsCreateAdvanceReportAdditionalDocument() {
    return await api.get('/FieldPermissions/create/AdvanceReportAdditionalDocument');
}

export async function postAdvanceReportAdditionalDocuments(date: any) {
    return await api.post('AdvanceReportAdditionalDocuments', date);
}

export async function updateAdvanceReportAdditionalDocuments(id: number, actExpense: number) {
    return await api.put(`AdvanceReportAdditionalDocuments/${id}/attach/${actExpense}`);
}

export async function postMeetingParticipants(participantData: any) {
    return await api.post(`/MeetingParticipants`, participantData);
}

export async function updateAdvanceReportAdditionalDocumentsById(id: number | undefined, editDate: any) {
    return await api.put(`AdvanceReportAdditionalDocuments/${id}`, editDate);
}

export async function deleteMeetingParticipantsById(id: any) {
    return await api.delete(`/MeetingParticipants?id=${id}`);
}

 export async function getFieldPermissionsReadMainMenu() {
         return await api.get('FieldPermissions/read/MainMenu');
     }

     export async function getFieldPermissionsReadFilter() {
         return await api.get('FieldPermissions/read/Filter');
     }

     export async function getFieldPermissionsReadProfile() {
         return await api.get('FieldPermissions/read/Profile');
     }

     export async function getFieldPermissionsReadTransaction() {
         return await api.get('FieldPermissions/read/Transaction');
     }

     export async function getFieldPermissionsReadTransactionsRead(params: any) {
         return await api.get('FieldPermissions/read/TransactionsRead', params);
     }

     export async function getFieldPermissionsReadTransactionsJournal() {
         return await api.get('FieldPermissions/read/TransactionsJournal');
     }

     export async function getFieldPermissionsReadJournal(isArchive: boolean) {
         return await api.get(`/FieldPermissions/read/Journal/${isArchive ? 'Archive' : ''}`);;
     }

    export async function postCustomPropertiesListValues(data: any) {
        return await api.post(`/customproperties/listvalues`, data);
    }


