import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { IAOList, FilterParams, ApplicationForExpenseList, ApproveList } from 'infrastructure/interfaces';
import Config, { ConfigTypes } from 'services/ConfigService';
import { updateAOList } from 'store/report/actions';
import { loaderLock, loaderUnlock, setFilterParams } from 'store/common/actions';
import { showErrors } from 'store/exception/actions';
import CreateAOModal from '../modal/CreateAOModal';
import { updateApplicationForExpenseList } from 'store/applicationForExpense/actions';
import { get, isEmpty } from 'lodash';
import CreateExpenseModal from '../modal/CreateExpenseModal';
import SubmitCustomModal from '../modal/SubmitCustomModal';
import ListItem from './ListItem';
import { updateAgreementList } from 'store/agreement/actions';
import If from '../util/If';
import analitiks from 'services/AnaliticsService'
import { getNextKey } from 'app/utils';
import {
  getAdvanceReportList,
  getAgreementList,
  getApplicationForExpenseList,
  getCommonFilterParams,
  getAdvanceReportIsReportListDownloaded,
  getCommonUserDetail
} from "../../../store/selectors";
import {updateAdvanceReportApplicationCancelById, updateExpensesCancelById} from "../../../services/ApiService";

interface ListProps {
  isAdvance?: boolean,
  isAgreement?: boolean
}

const List: React.FC<ListProps> = (props) => {
  const { t, i18n } = useTranslation();

  let reportList: IAOList | null = useSelector(getAdvanceReportList);
  let expenseList: ApplicationForExpenseList | null = useSelector(getApplicationForExpenseList);
  let isAOListDownloaded = useSelector(getAdvanceReportIsReportListDownloaded);
  let agreementList: ApproveList = useSelector(getAgreementList);
  let filterParams: FilterParams = useSelector(getCommonFilterParams);
  let user = useSelector(getCommonUserDetail);
  const advanceReportAmountCalculationPermission = Config.getConfigToCompany(ConfigTypes.ADVANCE_REPORT_AMOUNT_CALCULATION, user.occupations[0].company.id); //TODO: ConfigFix
  const dispatch = useDispatch();

  let [isOpenCreateModal, setOpenCreateModal] = useState(false);
  let [selectedReportId, setSelectedReportId] = useState(0);
  let [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  let [isLoading, setLoading] = useState(false);
  let [scrollYCoordinate, setScrollYCoordinate] = useState(0);
  let [selectedType, setSelectedType] = useState('');

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!isOpenDeleteModal);
  }

  const updateList = (...args: any[]) => {
    if (props.isAgreement) {
      dispatch(updateAgreementList(...args));
      return;
    }
    props.isAdvance ? dispatch(updateAOList(...args)) : dispatch(updateApplicationForExpenseList(...args));
  }

  const lifeScrolling = () => {
    let itemsCount = 0;
    let actualCount = 0;
    let itemsPerPage = 0;
    let actualPage = 0;
    if (props.isAgreement) {
      itemsCount = get(agreementList, 'itemsCount', 0);
      actualCount = get(agreementList, 'data.length', 0);
      itemsPerPage = get(agreementList, 'itemsPerPage', 0);
      actualPage = get(agreementList, 'pageNumber', 0);
    } else {
      itemsCount = props.isAdvance ? get(reportList, 'itemsCount', 0) : get(expenseList, 'itemsCount', 0);
      actualCount = props.isAdvance ? get(reportList, 'data.length', 0) : get(expenseList, 'data.length');
      itemsPerPage = props.isAdvance ? get(reportList, 'itemsPerPage', 0) : get(expenseList, 'itemsPerPage', 0);
      actualPage = props.isAdvance ? get(reportList, 'pageNumber', 0) : get(expenseList, 'pageNumber', 0);
    }
    if (window.pageYOffset === 0 || itemsCount <= itemsPerPage) return;
    if (window.pageYOffset + window.innerHeight >= document.body.clientHeight / 2 && itemsCount > actualCount && !isLoading) {
      setLoading(true);
      setScrollYCoordinate(window.pageYOffset);
      updateList(filterParams, ++actualPage, true);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', lifeScrolling);
    return () => {
      window.removeEventListener('scroll', lifeScrolling);
    }
  });

  useEffect(() => {
    setLoading(false);

  }, [reportList, expenseList, agreementList])

  useEffect(() => {
    dispatch(setFilterParams({}));
      analitiks(props.isAdvance ? 'AOList' : 'ZNRList', {
          employeeId: user.id,
          occupationId: user.occupations[0].occupationId,
          companyId: user.occupations[0].company.id
      });
      isAuth()
  }, []);

  const isAuth = () => {
    if(localStorage.getItem('customAuthFlag')){
      analitiks('Authentication', {
        employeeId: user.id,
        occupationId: user.occupations[0].occupationId,
        companyId: user.occupations[0].company.id
      });
      localStorage.removeItem('customAuthFlag')
    }
  }

  const deleteButtonClick = async () => {
    dispatch(loaderLock());
    let response = props.isAdvance || selectedType === 'AO'
        ? await updateAdvanceReportApplicationCancelById(selectedReportId)
        : await updateExpensesCancelById(selectedReportId);
    if (response.headers.success) {
      setOpenDeleteModal(false);
      updateList(filterParams);
    } else {
      dispatch(showErrors({ code: 'delete',
        message: t(props.isAdvance ? 'modals.error.message.failed_to_delete_advance_report' : 'modals.error.message.failed_to_delete_expense_application')
      }));
    }
    dispatch(loaderUnlock());
  }

  const toggleCreateModal = () => {
    setOpenCreateModal(!isOpenCreateModal);
  }

  const expensesEmptyItems = (
      <Fragment>
        {((!props.isAdvance && expenseList !== null) || (props.isAdvance && isAOListDownloaded)) && <div className="request-list-empty">
          <img src="/img/empty-img.png"/>
          <div
              style={{width: 'inherit'}}>{t(props.isAgreement ? 'agreement.empty' : props.isAdvance ? 'report_detail.report_empty_list' : 'request_list.noRequestForExpenses', {user: user.name[i18n.language as 'en' | 'ru'].split(' ')[0]})}</div>
          {props.isAgreement ? null : (
              <a className="btn btn_gray pointer"
                 onClick={toggleCreateModal}>{t(props.isAdvance ? 'filters.create_application_order' : 'request_list.createRequest')}</a>
          )}
        </div>}
        {props.isAgreement ? null : props.isAdvance && !props.isAgreement
            ? <CreateAOModal isOpen={isOpenCreateModal} onClose={toggleCreateModal} />
            : <CreateExpenseModal isOpen={isOpenCreateModal} onClose={toggleCreateModal} />}

      </Fragment>
  )

  const expensesNotAccessItems = (
    <Fragment>
      <div className="request-list-empty">
        <img src="/img/empty-img.png" />
        <div style={{width: 'inherit'}}>{t('report_detail.not_access')}</div>
      </div>

    </Fragment>
  )

  const handleSelectId = (id: number, isAdvance: boolean) => {
    setSelectedReportId(id);
    setSelectedType(isAdvance ? 'AO' : 'ZNR');
  }

  const expensesItems =
    <Fragment>
      <If condition={props.isAgreement}>
        {agreementList.data.map((item) => (
          <ListItem key={getNextKey('ListItem')} item={item} isAgreement={true} openDeleteModal={toggleDeleteModal} setSelectedId={(id: number) => handleSelectId(item.id, item.type === 'AO')} isAdvance={item.type === 'AO'} />
        ))}
      </If>
      <If condition={!props.isAgreement}>
        <If condition={props.isAdvance}>
          {reportList.data.map((report) => (
            <ListItem key={getNextKey('ListItem')} isAdvance item={report} openDeleteModal={toggleDeleteModal} setSelectedId={(id: number) => setSelectedReportId(id)} />
          ))}
        </If>
        <If condition={!props.isAdvance}>
          {expenseList && expenseList.data.map((report) => (
            <ListItem key={getNextKey('ListItem')} item={report} openDeleteModal={toggleDeleteModal} setSelectedId={(id: number) => setSelectedReportId(id)} />
          ))}
        </If>
      </If>
      <SubmitCustomModal isOpen={isOpenDeleteModal} onClose={toggleDeleteModal} onSubmit={deleteButtonClick} title={t(props.isAdvance || selectedType === 'AO' ? 'modals.delete_report_modal.want_to_delete_report' : 'delete_expense_application_modal.requestQuestion')} />
    </Fragment>;

  const isEmptyList = (): boolean => {
    if (props.isAgreement) {
      return isEmpty(agreementList.data);
    }
    if (props.isAdvance && reportList) {
      return isEmpty(reportList.data)
    } else if (expenseList) {
      return isEmpty(expenseList.data)
    } else {
      return true;
    }
  }

  return (
    <div className={"request-list" + (isEmptyList() || ((props.isAdvance || props.isAgreement) && advanceReportAmountCalculationPermission == -1)? ' empty' : '')}>
      {!isEmptyList() && (props.isAdvance || props.isAgreement) && advanceReportAmountCalculationPermission != -1 && expensesItems}
      {!isEmptyList() && !(props.isAdvance || props.isAgreement) && expensesItems}
      {isEmptyList() && !(props.isAdvance || props.isAgreement) && expensesEmptyItems}
      {isEmptyList() && (props.isAdvance || props.isAgreement) && advanceReportAmountCalculationPermission != -1 && expensesEmptyItems}
      {(props.isAdvance || props.isAgreement) && advanceReportAmountCalculationPermission == -1 && expensesNotAccessItems}
      {isLoading ? <div className="lds-ellipsis" style={{ marginLeft: 'calc(50% - 30px)', marginTop: '10px' }}><div></div><div></div><div></div><div></div></div> : null}
    </div>
  )
}

export default List;
