import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/reducers";
import ErrorModal from "./ErrorModal"

interface ErrorsPropsInterface {
  isWidget?: boolean;
}

const Errors: React.FC<ErrorsPropsInterface> = (props) => {
  const errorArray = useSelector((state: ApplicationState) => {
    return Object.values(
      state.exception.errorArray.reduce(
        (obj, item) => ({ [item.code]: item }),
        {}
      )
    );
  });

  return (
    <>
      {errorArray.map((error, index) => {
        return <ErrorModal error={error} isWidget={props.isWidget} />;
      })}
    </>
  );
};
export default Errors;