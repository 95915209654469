import ModalContainer from 'app/component/modal/ModalContainer';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/reducers';
import PrintMenu from "../menu/PrintMenu";
import {IAO} from "../../../infrastructure/interfaces";
import {getAdvanceReportApplication, getCommonUserDetail} from "../../../store/selectors";

interface AuthorizationModalProps {
    isOpen: boolean,
    isAdvance?: boolean,
    showPrintButton?: boolean,
    onClose: () => void,
    onSubmit: () => void
}

const AuthorizationModal: React.FC<AuthorizationModalProps> = (props) => {

    const {t} = useTranslation();
    let [closeCounter, setCloseCounter] = useState(45);
    let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);
    let [isOpenPrintModal, setOpenPrintModal] = useState(false);

    let counter = 0;

    let user = useSelector(getCommonUserDetail);
    const advanceReportApplication: IAO = useSelector(getAdvanceReportApplication);

    useEffect(() => {
        if (props.isOpen && !props.isAdvance) {
            if (closeCounter > 0) {
               counter = countdown()
            } else {
                handleClose()
            }
        }
    }, [closeCounter, props.isOpen]);

    const countdown = () => {
        let counter = window.setTimeout(() => {
            if (closeCounter > 0) {
                setCloseCounter(closeCounter - 1);
                clearTimeout(counter)
            }
        }, 1000)
        return counter
    };

    const handleClose = () => {
        setCloseCounter(45);
        clearTimeout(counter);
        props.onClose();
    };

    const isHighestModal = (isHighest: boolean) => {
        setTabIndexDisabled(!isHighest);
    }

    const togglePrintModal = () => {
        setOpenPrintModal(!isOpenPrintModal);
    }

    return (
        <ModalContainer isOpen={props.isOpen} destroy={false} highestModal={isHighestModal} overlayClick={props.onClose}>
            <div className="box-modal" id="modal-auth">
                <div className="box-modal_close arcticmodal-close pointer" onClick={handleClose}></div>
                <div className="box-modal-title">{t('modals.authorization.title')}</div>
                <div className="box-modal-content">
                    <div className="text">
                        <div
                            className="tx-center">{t(props.isAdvance ? 'modals.authorization.report_message' : 'modals.authorization.application_message', {email: user.email})}
                        </div>
                    </div>
                    {props.isAdvance ? <img src="/img/img-modal-auth-print.svg"/> : <img src="/img/img-modal-auth.png"/>}
                </div>
                <div className="box-modal-footer">
                    <div className="tx-center">
                        {!props.isAdvance ?
                            <a className="arcticmodal-close btn btn_black pointer"
                               onClick={handleClose}
                               tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.authorization.close')}</a>
                            : props.showPrintButton ?
                                <a className="btn-expense btn_green pointer"
                                   onClick={togglePrintModal}
                                   tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.authorization.print')}</a>
                                : null
                        }
                    </div>
                </div>
                {!props.isAdvance && <div className="box-modal-descr">
                    <div
                        className="tx-center">{`${t('modals.authorization.close_message')}  ...${closeCounter} ${t('modals.authorization.time_unit')}`}</div>
                </div>}
                {advanceReportApplication && advanceReportApplication.company &&
                <PrintMenu id={advanceReportApplication.id} isOpen={isOpenPrintModal} onClose={togglePrintModal} isAdvance={true} shouldStayOpened={true} companyId={advanceReportApplication.company.id} />}
            </div>
        </ModalContainer>
    );
};

export default AuthorizationModal;
