import React, { useState, useEffect } from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import History from 'app/component/history/History'
import { IAO } from 'infrastructure/interfaces';
import { useSelector } from 'react-redux';
import { ADVANCE_APPLICATION_PROPETIES } from 'infrastructure/enum/object-properties.enum';
import {getAdvanceReportApplication} from "../../../store/selectors";

const AOHistory: React.FC<RouteComponentProps<any>> = (props) => {
  const advanceReportApplication: IAO = useSelector(getAdvanceReportApplication);

  let [historyPermission, setHistoryPermission] = useState(false);

  useEffect(() => {
    if (advanceReportApplication && advanceReportApplication.properties) {
      setHistoryPermission(advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.FLOW_HISTORY))
    }
  }, [advanceReportApplication])


  return advanceReportApplication.id ? (
    <div className={`${historyPermission ? '' : 'd-none'}`}>
      <History objectId={advanceReportApplication.id} logicalRout={'AdvanceReportApplications'}/>
    </div>
  ) : null;
};

export default withRouter(AOHistory);
