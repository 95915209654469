/**
 * @typedef {Object} STATUS_TYPE_REPORT
 * @property {string} DRAFT - Черновик
 * @property {string} APPROVED - Согласована
 * @property {string} APPROVAL_REQUIRES - Требует согласования
 * @property {string} ON_APPROVAL - На согласовании
 * @property {string} REVOKED - Отозвана
 * @property {string} NOT_APPROVED - Не согласована
 * @property {string} POSTED - Проведен
 * @property {string} CANCELLED - Отменена
 */

export enum STATUS_TYPE_REPORT {
    DRAFT = 'Draft',
    APPROVED = 'Approved',
    APPROVAL_REQUIRES = 'Approval Requires',
    ON_APPROVAL = 'On Approval',
    REVOKED = 'Revoked',
    NOT_APPROVED = 'NotApproved',
    POSTED = 'Posted',
    CANCELLED = 'Cancelled',
    ON_REVISION = 'OnRevision'
}

export const statusReportStylesMap: any = {
    [STATUS_TYPE_REPORT.DRAFT]: 'gray',
    [STATUS_TYPE_REPORT.APPROVAL_REQUIRES]: 'yellow',
    [STATUS_TYPE_REPORT.ON_APPROVAL]: 'orange',
    [STATUS_TYPE_REPORT.APPROVED]: 'green',
    [STATUS_TYPE_REPORT.NOT_APPROVED]: 'red',
    [STATUS_TYPE_REPORT.REVOKED]: 'gray',
    [STATUS_TYPE_REPORT.POSTED]: 'blue',
    [STATUS_TYPE_REPORT.CANCELLED]: 'black',
    [STATUS_TYPE_REPORT.ON_REVISION]: 'yellow'
};

export const statusReportNameMap: any = {
    [STATUS_TYPE_REPORT.DRAFT]: 'draft',
    [STATUS_TYPE_REPORT.APPROVAL_REQUIRES]: 'approval_requires',
    [STATUS_TYPE_REPORT.ON_APPROVAL]: 'on_approval',
    [STATUS_TYPE_REPORT.APPROVED]: 'approved',
    [STATUS_TYPE_REPORT.NOT_APPROVED]: 'not_approved',
    [STATUS_TYPE_REPORT.REVOKED]: 'revoked',
    [STATUS_TYPE_REPORT.POSTED]: 'posted',
    [STATUS_TYPE_REPORT.CANCELLED]: 'cancelled',
    [STATUS_TYPE_REPORT.ON_REVISION]: 'оn_revision'
};

export const getReportEnumByName = (stringStatus: string): STATUS_TYPE_REPORT => {
    switch (stringStatus) {
        case 'Draft':
            return STATUS_TYPE_REPORT.DRAFT;
        case 'ApprovalRequires':
            return STATUS_TYPE_REPORT.APPROVAL_REQUIRES;
        case 'OnApproval':
            return STATUS_TYPE_REPORT.ON_APPROVAL;
        case 'Approved':
            return STATUS_TYPE_REPORT.APPROVED;
        case 'NotApproved':
            return STATUS_TYPE_REPORT.NOT_APPROVED;
        case 'Revoked':
            return STATUS_TYPE_REPORT.REVOKED;
        case 'Posted':
            return STATUS_TYPE_REPORT.POSTED;
        case 'OnRevision':
            return STATUS_TYPE_REPORT.ON_REVISION;
        default:
            return STATUS_TYPE_REPORT.DRAFT;
    }
}