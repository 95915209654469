import { BaseMapper } from './base.mapper';
import { HistoryAudit } from 'infrastructure/interfaces';
import { get } from 'lodash';


export class HistoryAuditMapper extends BaseMapper<HistoryAudit, any> {
  responseToEntity(response: any): HistoryAudit {
    let result: HistoryAudit = {
      id: get(response, 'eventId', 'eventId[STUB]'),
      date: get(response, 'stamp', 'stamp[STUB]'),
      autor: {
        id: get(response, 'user.id', -1),
        login: get(response, 'user.login', 'login[STUB]'),
        fullName: {
          ru: get(response, 'user.fullName.ru', 'fullName.ru[STUB]'),
          en: get(response, 'user.fullName.en', 'fullName.en[STUB]'),
        }
      },
      description: {
        ru: get(response, 'description.ru', 'descriptionRu[STUB]'),
        en: get(response, 'description.en', 'descriptionEn[STUB]'),
      },
      type: get(response, 'type', -1)
    };
    
    let changeObj = get(response, 'objects', []);
    for (let indexA = 0; indexA < changeObj.length; indexA++) {
      let changes = get(changeObj[indexA], 'changes', []);
      for (let indexB = 0; indexB < changes.length; indexB++) {
        let changes = get(changeObj[indexA], 'changes', []);
        if (result.changes){
          result.changes.push({
            id: get(changeObj[indexA], 'id', -1),
            property: get(changes[indexB], 'property', 'property[STUB]'),
            changeType: get(changes[indexB], 'changeType', -1),
            oldValue: get(changes[indexB], 'oldValue', 'oldValue[STUB]'),
            newValue: get(changes[indexB], 'newValue', 'newValue[STUB]'),
            newNameEn: get(changes[indexB], 'newNameEn', 'newNameEn[STUB]'),
            newNameRu: get(changes[indexB], 'newNameRu', 'newNameRu[STUB]'),
            oldNameRu: get(changes[indexB], 'oldNameRu', 'oldNameRu[STUB]'),
            oldNameEn: get(changes[indexB], 'oldNameEn', 'oldNameEn[STUB]'),
          });
        } else{
          result.changes = [{
            id: get(changeObj[indexA], 'id', -1),
            property: get(changes[indexB], 'property', 'property[STUB]'),
            changeType: get(changes[indexB], 'changeType', -1),
            oldValue: get(changes[indexB], 'oldValue', 'oldValue[STUB]'),
            newValue: get(changes[indexB], 'newValue', 'newValue[STUB]'),
            newNameEn: get(changes[indexB], 'newNameEn', 'newNameEn[STUB]'),
            newNameRu: get(changes[indexB], 'newNameRu', 'newNameRu[STUB]'),
            oldNameRu: get(changes[indexB], 'oldNameRu', 'oldNameRu[STUB]'),
            oldNameEn: get(changes[indexB], 'oldNameEn', 'oldNameEn[STUB]'),
          }];
        }
      }
    }

    return result;
  };
}
