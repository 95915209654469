import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { updateApplicationForExpenseDetail } from "store/applicationForExpense/actions";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { throwException, showErrors, showInfo } from "store/exception/actions";
import DeleteExpenseModal from "app/component/modal/DeleteExpenseModal";
import CreateOrderModal from "app/component/modal/CreateOrderModal";
import { Expense } from "infrastructure/interfaces/index";
import { findIndex, pull, get } from "lodash";
import { getApplicationForExpenseDetail } from "../../../../store/selectors";
import {
  deleteExpensesById,
  updatePerDiemCalculations,
} from "../../../../services/ApiService";
import WrapperApplicationExpensesItem from "./WrapperApplicationExpensesItem";

interface ApplicationExpensesListProps {
  id: number;
  list: Expense[];
  isWidget?: boolean;
}

const ApplicationExpensesList: React.FC<ApplicationExpensesListProps> = (
  props
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const expenseApplication = useSelector(getApplicationForExpenseDetail);

  let [expenseId, setExpenseId] = useState(0); //TODO передача id для удаления и редактирования
  let [isOpenDeleteExpenseModal, setOpenDeleteExpenseModal] = useState(false); //TODO открытие формы удаления расходов
  let [isOpenEditExpenseModal, setOpenEditExpenseModal] = useState(false); //TODO открытие формы редактирования расходов

  const newList = [...props.list];
  newList.forEach((expense: any) => {
    if (expense && expense.relations.length > 1) {
      expense.relations.forEach((relation: any) => {
        let relatedId: number = relation.relatedExpenseId;
        let relatedIndex: number = findIndex(
          newList,
          (expense) => expense.id === relatedId
        );
        let relatedExpense: Expense = newList[relatedIndex];
        let relationItems = relatedExpense.relations;
        expense.relations = expense.relations.filter(
          (relation: any) =>
            !relationItems.some(
              (item: any) => relation.relatedExpenseId === item.relatedExpenseId
            )
        );
      });
    }
  });
  newList.forEach((expense: Expense) => {
    if (expense && expense.relations.length > 0) {
      let relatedIndex = findIndex(
        newList,
        (item) => item.id === expense.relations[0].relatedExpenseId
      );
      let expenseIndex = findIndex(newList, expense);
      let relatedExpense = newList[relatedIndex];
      pull(newList, relatedExpense);
      newList.splice(expenseIndex + 1, 0, relatedExpense);
    }
  });

  const getSortList = (): Expense[][] => {
    let externalArr: Expense[][] = [];
    let internalArr: Expense[] = [];
    newList.forEach((expense: Expense) => {
      if (expense.relations.length > 0) {
        internalArr.push(expense);
      } else {
        internalArr.push(expense);
        externalArr.push(internalArr.reverse());
        internalArr = [];
      }
    });
    return externalArr;
  };

  const onDelete = async () => {
    dispatch(loaderLock());
    let dailyExpensesIds = expenseApplication
      ? expenseApplication.expenses
          .filter((item: any) => {
            return item.expenseType.expenseGroup.code === "Perdiem";
          })
          .map((item: any) => item.id)
      : [];
    if (dailyExpensesIds.includes(expenseId)) {
      dailyExpensesIds.forEach((item) => {
        if (expenseId !== item) {
          deleteExpensesById(item);
        }
      });
      let response = await deleteExpensesById(expenseId);
      if (response.headers.success) {
        let data = {
          applicationRef: {
            id: expenseApplication ? expenseApplication.id : 0,
            logicalName: "ExpenseApplication",
            name: {
              ru: "ExpenseApplication",
              en: "ExpenseApplication",
            },
          },
          calculations: [],
        };
        await updatePerDiemCalculations(data);
        dispatch(updateApplicationForExpenseDetail(props.id));
      } else {
        dispatch(
          showErrors({
            code: "",
            message: response.data,
          })
        );
      }
      setOpenDeleteExpenseModal(false);
      dispatch(loaderUnlock());
      return;
    }

    await deleteExpensesById(expenseId)
      .then((response: any) => {
        setOpenDeleteExpenseModal(false);
        dispatch(updateApplicationForExpenseDetail(props.id));
        if (!response.headers.success) {
          dispatch(
            showErrors({
              code: "",
              message: response.data,
            })
          );
        } else {
          let adjustAdvanceAmountWarning = get(
            response.data,
            "adjustAdvanceAmountWarning",
            false
          );
          if (adjustAdvanceAmountWarning) {
            dispatch(
              showInfo({
                code: "info.adjust_advance_amount",
                message: t("modals.info.adjust_advance_amount"),
              })
            );
          }
        }
      })
      .catch((e: any) => {
        dispatch(
          throwException({
            code: "ExpenseApplication_cancel",
            message: `ExpenseApplication_cancel ${e.response.status}`,
          })
        );
        setOpenDeleteExpenseModal(false);
        dispatch(updateApplicationForExpenseDetail(props.id));
      })
      .finally(() => {
        dispatch(loaderUnlock());
      });
  };

  return (
    <>
      {getSortList().map((item, index) => {
        return (
          <WrapperApplicationExpensesItem
            key={index}
            setExpenseId={setExpenseId}
            openDeleteExpenseModal={setOpenDeleteExpenseModal}
            openEditExpenseModal={setOpenEditExpenseModal}
            expenses={item}
            isWidget={props.isWidget}
          />
        );
      })}
      <CreateOrderModal
        id={props.id}
        editId={expenseId}
        isOpen={isOpenEditExpenseModal}
        onClose={() => setOpenEditExpenseModal(false)}
        isWidget={props.isWidget}
      />
      <DeleteExpenseModal
        id={expenseId}
        isOpen={isOpenDeleteExpenseModal}
        onClose={() => setOpenDeleteExpenseModal(false)}
        onSubmit={() => onDelete()}
        isWidget={props.isWidget}
      />
    </>
  );
};

export default ApplicationExpensesList;
