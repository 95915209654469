import React, {useState, useRef, useEffect, useCallback, useMemo} from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import CreateAOModal from '../modal/CreateAOModal';
import CreateExpenseModal from '../modal/CreateExpenseModal';
import Dropdown from '../dropdown/Dropdown';
import DatePickerCalendar from '../date-picker/DatePickerCalendar';
import SvgIcon from '../svg-icon/SvgIcon';
import { formatter} from 'app/utils';
import EmployeeAutocomplete, { EmployeeAutocompleteOptionType } from '../autocomplete/EmployeeAutocomplete';
import CompanyAutocomplete, { CompanyAutocompleteOptionType } from '../autocomplete/CompanyAutocomplete';
import LegalEntityAutocomplete, { LegalEntityAutocompleteOptionType } from '../autocomplete/LegalEntityAutocomplete';
import LocationsAutocomplete, { LocationsAutocompleteOptionType } from '../autocomplete/LocationsAutocomplete';
import OrgUnitAutocomplete, { OrgUnitAutocompleteOptionType } from '../autocomplete/OrgUnitAutocomplete';
import InputChoose, { InputChooseItem } from '../inputchoose/InputChoose';
import CityAutocomplete, { CityAutocompleteOptionType } from '../autocomplete/CityAutocomplete';
import Checkbox from '../checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/reducers';
import {setFilterParams, hideFilter, showFilter, updateFilterFieldPermissions} from 'store/common/actions';
import { updateAOList } from 'store/report/actions';
import { FilterParams } from 'infrastructure/interfaces';
import { isEmpty} from 'lodash';
import { updateApplicationForExpenseList } from 'store/applicationForExpense/actions';
import analitiks from 'services/AnaliticsService'
import Radio from '../radio/Radio';
import {getCommonFilterParams, getCommonFilterState, getCommonUserDetail} from "../../../store/selectors";
import {FILTER_FIELD_PERMISSIONS} from "../../../infrastructure/enum/field-permissions.enum";

interface ListFilterProps extends RouteComponentProps<any> {
    isAdvance?: boolean
}

interface StatusFilter {
    text: {
        en: string,
        ru: string
    },
    value: string,
    code: string
}

const ListFilter: React.FC<ListFilterProps> = (props) => {
    let { t, i18n } = useTranslation();

    let [isOpenCreateModal, setOpenCreateModal] = useState(false);
    let [statuses, setStatuses] = useState([] as string[]);
    let [numberTip, setNumberTip] = useState('');
    let [options, setOptions] = useState([] as InputChooseItem[]);
    let [startDate, setStartDate] = useState(null as any);
    let [endDate, setEndDate] = useState(null as any);
    let [isOpenToCalendar, setOpenToCalendar] = useState(false);
    let [isOpenBackCalendar, setOpenBackCalendar] = useState(false);
    let [isMount, setIsMount] = useState(false);
    let [isInitialized, setInitialized] = useState(false);
    let [employee, setEmployee] = useState({} as EmployeeAutocompleteOptionType);
    let [company, setCompany] = useState({} as CompanyAutocompleteOptionType);
    let [location, setLocation] = useState({} as LocationsAutocompleteOptionType);
    let [legalEntity, setLegalEntity] = useState({} as LegalEntityAutocompleteOptionType);
    let [orgUnit, setOrgUnit] = useState({} as OrgUnitAutocompleteOptionType);
    let [itemId, setItemId] = useState('');
    let [itemNumber, setItemNumber] = useState('');
    let [width, setWidth] = useState(0);
    let [numberExpense, setNumberExpense] = useState('');
    let [onlyMyDocs, setOnlyMyDocs] = useState(false);
    let numberTipRef = useRef({} as HTMLInputElement);
    let itemIdRef = useRef({} as HTMLInputElement);
    let itemNumberRef = useRef({} as HTMLInputElement);
    let employeeAutocompleteRef = useRef({} as any);
    let companyAutocompleteRef = useRef({} as any);
    let locationAutocompleteRef = useRef({} as any);
    let orgUnitAutocompleteRef = useRef({} as any);
    let legalEntityAutocompleteRef = useRef({} as any);
    let numberExpenseRef = useRef({} as HTMLInputElement);
    let cityAutocompleteRef = useRef({} as any);

    const dispatch = useDispatch();

    const statusFromNavigation = props.match.params.status;

    let filterParams = useSelector(getCommonFilterParams);
    let filterState: boolean = useSelector(getCommonFilterState);
    const user = useSelector(getCommonUserDetail);
    const filterFieldPermissions = useSelector((state: ApplicationState) => state.common.filterFieldPermissions);

    const showDocumentFilter: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.IS_PERSONAL)
    }, [filterFieldPermissions]);
    const showStatusFilter: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.STATUS)
    }, [filterFieldPermissions]);
    const showNumberExpenseFilter: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.EXPENSE_APPLICATION_ID)
    }, [filterFieldPermissions]);
    const showNumberReportFilter: boolean = useMemo(() => {
        // return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.ADVANCE_REPORT_APPLICATION_ID)
        return true;
    }, [filterFieldPermissions]);
    const showBusinessTripNumberFilter: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.JOURNEY)
    }, [filterFieldPermissions]);
    const showOrgUnitFilter: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.ORG_UNIT)
    }, [filterFieldPermissions]);
    const showLocationFilter: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.LOCATION)
    }, [filterFieldPermissions]);
    const showCompanyFilter: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.COMPANY)
    }, [filterFieldPermissions]);
    const showLegalEntityFilter: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.LEGAL_ENTITY)
    }, [filterFieldPermissions]);
    const showCityFilter: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.CITY)
    }, [filterFieldPermissions]);
    const showEmployeeFilter: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.EMPLOYEE)
    }, [filterFieldPermissions]);
    const showBusinessTripDateFilter: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.DATE_OF_BUSINESSTRIP)
    }, [filterFieldPermissions]);
    const showCreateApplicationButton: boolean = useMemo(() => {
        return filterFieldPermissions && filterFieldPermissions.includes(FILTER_FIELD_PERMISSIONS.CREATING_APPLICATION)
    }, [filterFieldPermissions]);

    let [isFilterOpen, setFilterOpen] = useState(filterState);
    let [filterTimer, setFilterTimer] = useState(null as unknown as NodeJS.Timeout);
    let [isClearingFilter, setClearingFilter] = useState(false);

    const update = (params: FilterParams, force: boolean = false) => {
        if (!configuredStatuses.find(element => props.history.location.pathname.includes(element.code)) || force) {
            props.isAdvance ? dispatch(updateAOList(params)) : dispatch(updateApplicationForExpenseList(params));
        }
    }

    useEffect(() => {
        setInitialized(true)
        dispatch(updateFilterFieldPermissions());
        setIsMount(true);
        update({});
        resizeHandler();
    }, [])

    useEffect(() => {
        if (statusFromNavigation) {
            if (isMount) {
                setIsMount(false);
            }
            let status = configuredStatuses.find(element => element.code === statusFromNavigation);
            status && setStatuses([status.value]);
            //status && saveParameters(true, [status.value], true);
            return;
        }
        if (props.location.pathname === (props.isAdvance ? '/AdvanceReportApplication' : '/ExpenseApplication') && statuses.length === 1) {
            let params: any = {};
            Object.keys(filterParams).forEach(key => {
                if (key === 'Status') {
                    params[key] = [];
                } else {
                    params[key] = (filterParams as any)[key];
                }
            })
            setStatuses([]);
        }
    }, [props.match.params.status])

    const resizeEventHander = (e: any) => {
        if (width !== window.innerWidth) {
            resizeHandler();
            setWidth(window.innerWidth);
        }
    }

    const resizeHandler = () => {
        if (window.innerWidth <= 959) {
            dispatch(hideFilter())
        } else {
            dispatch(showFilter())
        }
    }

    useEffect(() => {
        setFilterOpen(filterState)
        window.addEventListener('resize', resizeEventHander)
        return () => {
            window.removeEventListener('resize', resizeEventHander);
        }
    }, [filterState])

    useEffect(() => {
        if (isInitialized) {
            saveParameters(false, [], true);
            setClearingFilter(false);
        }
    }, [onlyMyDocs, statuses, options, startDate, endDate, employee, company, legalEntity, location, orgUnit])

    useEffect(() => {
        if (isInitialized && !isClearingFilter) {
            clearTimeout(filterTimer);
            setFilterTimer(setTimeout(() => {
                saveParameters(false, [], true)
            }, 2000))
        }
    }, [numberTip, numberExpense, itemId, itemNumber])

    const onStatusClickHandler = (status: string) => {
        if (statuses.length === 0) {
            let state = configuredStatuses.find(element => element.value === status);
                state && props.history.push(`/${props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication'}/${state.code}`);
            return;
        }
        if (statuses.length === 2 && statuses.includes(status)) {
            let chosenStatus = statuses.find(element => element !== status);
            let state = configuredStatuses.find(element => element.value === chosenStatus);
            state && props.history.push(`/${props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication'}/${state.code}`);
            return;
        }
        let statusesArray = [...statuses];
        let index = statusesArray.findIndex(item => item === status);
        if (index === -1) {
            statusesArray.push(status);
        } else {
            statusesArray.splice(index, 1);
        }
        setStatuses(statusesArray);
    }

    const itemNumberBlurHandler = () => {
        const filterItemNumber = filterParams.Id ? filterParams.Id : '';
        if (filterItemNumber != itemNumber) {
            clearTimeout(filterTimer);
            forceUpdateFilter();
        }
    }

    const numberExpenseBlurHandler = () => {
        const filterNumberExpense = filterParams.ExpenseApplicationId ? filterParams.ExpenseApplicationId : '';
        if (filterNumberExpense != numberExpense) {
            clearTimeout(filterTimer);
            forceUpdateFilter();
        }
    }

    const numberTipBlurHandler = () => {
        const filterNumberExpense = filterParams.Journey ? filterParams.Journey : '';
        if (filterNumberExpense != numberTip) {
            clearTimeout(filterTimer);
            forceUpdateFilter();
        }
    }

    const numberTipChangeHandler = () => {
        if (numberTipRef.current.value.length > 50) {
            return;
        }
        setNumberTip(numberTipRef.current.value);
    }

    const itemNumberChangeHandler = () => {
        if (itemNumberRef.current.value.length > 50) {
            return;
        }
        setItemNumber(itemNumberRef.current.value);
    }

    const employeeChooseHandler = (option: EmployeeAutocompleteOptionType) => {
        if (option.value !== employee.value) {
            setEmployee(option);
        }
    }
    
    const companyChooseHandler = (option: CompanyAutocompleteOptionType) => {
        if (option.value !== company.value) {
            setCompany(option);
        }
    }
    
    const locationChooseHandler = (option: LocationsAutocompleteOptionType) => {
        if (option.value !== location.value) {
            setLocation(option);
        }
    }
    
    const legalEntityChooseHandler = (option: LegalEntityAutocompleteOptionType) => {
        if (option.value !== legalEntity.value) {
            setLegalEntity(option);
        }
    }
    
    const orgUnitChooseHandler = (option: OrgUnitAutocompleteOptionType) => {
        if (option.value !== orgUnit.value) {
            setOrgUnit(option);
        }
    }

    const clearAllClickHandler = (changeRoute?: boolean) => {
        setStatuses([]);
        setNumberTip('');
        setStartDate('');
        setOptions([]);
        setEndDate('');
        setEmployee({} as EmployeeAutocompleteOptionType);
        setCompany({} as CompanyAutocompleteOptionType);
        setLegalEntity({} as LegalEntityAutocompleteOptionType);
        setLocation({} as LocationsAutocompleteOptionType);
        setOrgUnit({} as OrgUnitAutocompleteOptionType);
        setNumberExpense('');
        setItemId('');
        setItemNumber('');
        setOnlyMyDocs(false);
        employeeAutocompleteRef.current.clear();
        companyAutocompleteRef.current.clear();
        locationAutocompleteRef.current.clear();
        orgUnitAutocompleteRef.current.clear();
        legalEntityAutocompleteRef.current.clear();
        cityAutocompleteRef.current.clear();
        setClearingFilter(true);
        window.scrollTo(0, 0);
        !changeRoute && props.history.push(props.isAdvance ? '/AdvanceReportApplication' : '/ExpenseApplication');
    }

    const onSelectedToDate = (value: any) => {
        setStartDate(value.startDate);
        setOpenToCalendar(false);
    };

    const onSelectedBackDate = (value: any) => {
        setEndDate(value.startDate);
        setOpenBackCalendar(false);
    }

    const onClickToCalendar = () => {
        setOpenToCalendar(!isOpenToCalendar);
    };

    const onClickBackCalendar = () => {
        setOpenBackCalendar(!isOpenBackCalendar);
    }

    const numberExpenseChangeHandler = () => {
        if (numberExpenseRef.current.value.length > 50) {
            return;
        }
        setNumberExpense(numberExpenseRef.current.value);
    }

    const onCityChooseHandler = (option: CityAutocompleteOptionType) => {
        cityAutocompleteRef.current.clear();
        let cityOptions: InputChooseItem[] = [...options];
        let index = cityOptions.findIndex(city => city.value === option.value);
        if (index === -1) {
            cityOptions.push(option);
            setOptions(cityOptions);
        }
    }

    const onCityDeleteHandler = (option: InputChooseItem) => {
        let cityOptions: InputChooseItem[] = [...options];
        cityOptions.splice(cityOptions.findIndex(city => city.value === option.value), 1);
        setOptions(cityOptions);
    }

    const saveParameters = (isRoute?: boolean, statusesByRoute?: string[], isNeedMount: boolean = false) => {
        let params: FilterParams = {
            Status: !isEmpty(statusesByRoute) ? statusesByRoute : statuses,
            Journey: numberTip,
            CityId: [...options.map(option => option.value)],
            StartOn: startDate,
            EndOn: endDate,
            EmployeeId: employee.value,
            CompanyId: company.value,
            LegalEntityId: legalEntity.value,
            OrgUnitId: orgUnit.value,
            LocationId: location.value,
            Id: itemNumber,
            Personal: onlyMyDocs
        }
        if (props.isAdvance) {
            params.ExpenseApplicationId = numberExpense;
        }
        if (isMount && !isNeedMount) {
            setIsMount(false);
            update({});
            return;
        }
        let status = configuredStatuses.find(element => element.code === statusFromNavigation);
        if ((statuses.some(state => status && status.value !== state) && statuses.length !== 1) || statuses.length === 0 && props.location.pathname !== '/AdvanceReportApplication') {
            props.history.push(props.isAdvance ? '/AdvanceReportApplication' : '/ExpenseApplication');
        }
        if (statuses.length === 1) {
            let state = configuredStatuses.find(element => element.value === statuses[0]);
            update(params, true);
            dispatch(setFilterParams(params));
            state && props.history.push(`/${props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication'}/${state.code}`);
            return;
        }
        update(params)
        dispatch(setFilterParams(params));
    }

    const forceUpdateFilter = () => {
        clearTimeout(filterTimer);
        saveParameters(false, [], true);
    }

    const onEnterClickHandler = (event: any) => {
        if (event.key === 'Enter') {
            forceUpdateFilter();
        }
    }

    let configuredStatuses: StatusFilter[] = props.isAdvance
        ? [
            {
                text: {
                    ru: 'Черновик',
                    en: 'Draft'
                },
                value: '0',
                code: 'draft'
            },
            {
                text: {
                    ru: 'Требует согласования',
                    en: 'Approval Requires'
                },
                value: '1',
                code: 'approval_requires'
            },
            {
                text: {
                    ru: 'На согласовании',
                    en: 'On Approval'
                },
                value: '2',
                code: 'on_approval'
            },
            {
                text: {
                    ru: 'Согласован',
                    en: 'Approved'
                },
                value: '3',
                code: 'approved'
            },  
            {
                text: {
                    ru: 'Не согласован',
                    en: 'Not Approved'
                },
                value: '5',
                code: 'not_approved'
            },              
            {
                text: {
                    ru: 'Отозван',
                    en: 'Revoked'
                },
                value: '4',
                code: 'revoked'
            },            
            {
                text: {
                    ru: 'Проведен',
                    en: 'Posted'
                },
                value: '6',
                code: 'posted'
            },
            {
                text: {
                    ru: 'Отменен',
                    en: 'Cancelled'
                },
                value: '7',
                code: 'cancelled'
            },
            {
                text: {
                  ru: 'На доработке',
                  en: 'On Revision'
                },
                value: '9',
                code: 'on_revision'
              }
        ] : [
            {
                text: {
                    ru: 'Черновик',
                    en: 'Draft'
                },
                value: '0',
                code: 'draft'
            },                      
            {
                text: {
                    ru: 'Требует согласования',
                    en: 'Approval Requires'
                },
                value: '1',
                code: 'approval_requires'
            },
            {
                text: {
                    ru: 'На согласовании',
                    en: 'On Approval'
                },
                value: '2',
                code: 'on_approval'
            },
            {
                text: {
                    ru: 'Согласована',
                    en: 'Approved'
                },
                value: '3',
                code: 'approved'
            }, 
            {
                text: {
                    ru: 'Не согласована',
                    en: 'Not Approved'
                },
                value: '4',
                code: 'not_approved'
            },
            {
                text: {
                    ru: 'Отозвана',
                    en: 'Revoked'
                },
                value: '5',
                code: 'revoked'
            },            
            {
                text: {
                    ru: 'Проведена',
                    en: 'Posted'
                },
                value: '6',
                code: 'posted'
            },
            // {
            //     text: {
            //         ru: 'Подготовлен отчет',
            //         en: 'Report prepared'
            //     },
            //     value: '7',
            //     code: 'report_prepared'
            // },
            // {
            //     text: {
            //         ru: 'Исполнено',
            //         en: 'Executed'
            //     },
            //     value: '8',
            //     code: 'executed'
            // },
            {
                text: {
                    ru: 'Отменена',
                    en: 'Cancelled'
                },
                value: '9',
                code: 'cancelled'
            },
            {
                text: {
                  ru: 'На доработке',
                  en: 'On Revision'
                },
                value: '10',
                code: 'on_revision'
              }
        ];

    const clearStart = (e: any) => {
        e.preventDefault();
        setStartDate(null);
    }

    const clearEnd = (e: any) => {
        e.preventDefault();
        setEndDate(null);
    }

    return (
        <React.Fragment>
            <div className="filters" style={isFilterOpen ? { display: 'block' } : { display: 'none' }}>
                {showCreateApplicationButton ? <a className="btn-expense btn_green add-note pointer" onClick={() => {
                    setOpenCreateModal(true);
                    analitiks(props.isAdvance ? 'ClickCreateAO' : 'ClickCreateZNR', {
                        employeeId: user.id,
                        occupationId: user.occupations[0].occupationId,
                        companyId: user.occupations[0].company.id
                    })
                }}>{t(props.isAdvance ? 'filters.create_application_order' : 'filters.create_application')}</a> : null}
                <div className="filters-wrap">
                    <Dropdown label={t('filters.documents')} displayStyle={showDocumentFilter ? undefined : 'none'}>
                        <Radio checked={onlyMyDocs} label={t('filters.only_mine')} onClick={() => setOnlyMyDocs(true)}  id={2} name={'radio2'} key={2} />
                        <Radio checked={!onlyMyDocs} label={t('filters.all')} onClick={() => setOnlyMyDocs(false)}  id={1} name={'radio1'} key={1} />
                    </Dropdown>
                    <Dropdown label={t('filters.by_status')} displayStyle={showStatusFilter ? undefined : 'none'}>
                        <React.Fragment>
                            {configuredStatuses.map((configuredStatus, index) => (
                                <Checkbox key={index} name={'test'} id={index} label={`${configuredStatus.text[i18n.language as 'en' | 'ru']}`} checked={statuses.findIndex(status => status === configuredStatus.value) !== -1} onClick={() => onStatusClickHandler(configuredStatus.value)} />
                            ))}
                        </React.Fragment>
                    </Dropdown>
                    {/* <Dropdown label={t('filters.by_id')}>
                        <div className="input-item">
                            <input ref={itemIdRef} className="input" type="number" value={itemId} onChange={itemIdChangeHandler} placeholder={t(`filters.${props.isAdvance ? 'id_advance' : 'id_expense'}`)} />
                        </div>
                    </Dropdown> */}
                    <Dropdown label={t(`filters.${props.isAdvance ? 'by_number_report' : 'by_number_expense'}`)} isDefaultOpen={false}
                              displayStyle={!props.isAdvance ? showNumberExpenseFilter ? undefined : 'none'
                                  : showNumberReportFilter ? undefined : 'none'}>
                        <div className="input-item">
                            <input ref={itemNumberRef} className="input" type="number" value={itemNumber}
                                   onKeyPress={onEnterClickHandler} onBlur={itemNumberBlurHandler}
                                   onChange={itemNumberChangeHandler} placeholder={t(`filters.${props.isAdvance ? 'number_report' : 'number_expense'}`)} />
                        </div>
                    </Dropdown>
                    {props.isAdvance && showNumberExpenseFilter ? (
                        <Dropdown label={t(`filters.by_number_expense`)} isDefaultOpen={false}>
                            <div className="input-item">
                                <input ref={numberExpenseRef} className="input" type="number" value={numberExpense}
                                       onKeyPress={onEnterClickHandler} onBlur={numberExpenseBlurHandler}
                                       onChange={numberExpenseChangeHandler} placeholder={t(`filters.number_expense`)} />
                            </div>
                        </Dropdown>
                    ) : null}
                    <Dropdown label={t('filters.by_bysiness_trip_number')} displayStyle={showBusinessTripNumberFilter ? undefined : 'none'} isDefaultOpen={false}>
                        <div className="input-item">
                            <input ref={numberTipRef} className="input" type="number" value={numberTip}
                                   onKeyPress={onEnterClickHandler} onBlur={numberTipBlurHandler}
                                   onChange={numberTipChangeHandler} placeholder={t('filters.business_trip_number')} />
                        </div>
                    </Dropdown>
                    <Dropdown label={t('filters.by_orgUnit')} displayStyle={showOrgUnitFilter ? undefined : 'none'} isDefaultOpen={false}>
                        <div className="input-item">
                            <div className="input-search">
                                <OrgUnitAutocomplete ref={orgUnitAutocompleteRef} onChoose={orgUnitChooseHandler} placeholder={t('filters.orgUnit')} />
                                <SvgIcon className={'icon icon-search'} href={'#svg_icon-search'} />
                            </div>                            
                        </div>
                    </Dropdown>
                    <Dropdown label={t('filters.by_location')} displayStyle={showLocationFilter ? undefined : 'none'} isDefaultOpen={false}>
                        <div className="input-item">
                            <div className="input-search">
                                <LocationsAutocomplete ref={locationAutocompleteRef} onChoose={locationChooseHandler} placeholder={t('filters.location')} />
                                <SvgIcon className={'icon icon-search'} href={'#svg_icon-search'} />
                            </div>                            
                        </div>
                    </Dropdown>
                    <Dropdown label={t('filters.by_company')} displayStyle={showCompanyFilter ? undefined : 'none'} isDefaultOpen={false}>
                        <div className="input-item">
                            <div className="input-search">
                                <CompanyAutocomplete ref={companyAutocompleteRef} onChoose={companyChooseHandler} placeholder={t('filters.company')} />
                                <SvgIcon className={'icon icon-search'} href={'#svg_icon-search'} />
                            </div>                            
                        </div>
                    </Dropdown>
                    <Dropdown label={t('filters.by_legalEntity')} displayStyle={showLegalEntityFilter ? undefined : 'none'} isDefaultOpen={false}>
                        <div className="input-item">
                            <div className="input-search">
                                <LegalEntityAutocomplete ref={legalEntityAutocompleteRef} onChoose={legalEntityChooseHandler} placeholder={t('filters.legalEntity')} />
                                <SvgIcon className={'icon icon-search'} href={'#svg_icon-search'} />
                            </div>                            
                        </div>
                    </Dropdown>
                    <Dropdown label={t('filters.by_city')} displayStyle={showCityFilter ? undefined : 'none'} isDefaultOpen={false}>
                        <div className="input-item">
                            <CityAutocomplete ref={cityAutocompleteRef} onChoose={onCityChooseHandler} placeholder={t('filters.city')} />
                            <InputChoose options={options} onDelete={onCityDeleteHandler} />
                        </div>
                    </Dropdown>
                    <Dropdown label={t("filters.by_employee")} displayStyle={showEmployeeFilter ? undefined : 'none'} isDefaultOpen={false}>
                        <div className="input-item">
                            <div className="input-search">
                                <EmployeeAutocomplete ref={employeeAutocompleteRef} onChoose={employeeChooseHandler} placeholder={t("filters.enter_name")} />
                                <SvgIcon className={'icon icon-search'} href={'#svg_icon-search'} />
                            </div>
                        </div>
                    </Dropdown>
                    <Dropdown label={t('filters.by_business_trip_date')} displayStyle={showBusinessTripDateFilter ? undefined : 'none'} isDefaultOpen={false}>
                        <React.Fragment>
                            <div className="input-item">
                                {startDate ? <div className="input-picker-title">{t('filters.one_way')}</div> : null}
                                <div className={`input-picker-wrap ${startDate ? 'active' : ''}`}>
                                    <input value={startDate ? formatter('D MMMM', i18n.language, startDate) : ''} onClick={onClickToCalendar} className="input datepicker pointer" type="text" id="datepicker" placeholder={t('filters.one_way')} readOnly={true} />
                                    {startDate ? <SvgIcon className={"icon icon-close"} href={"#svg_icon_close"} onClick={clearStart} /> : <SvgIcon className={"icon icon-calendar"} href={"#svg_icon_calendar"} onClick={onClickToCalendar} />}
                                </div>
                                <DatePickerCalendar disableAfterDate={endDate? endDate: null} startDate={startDate} isShown={isOpenToCalendar} onSelected={onSelectedToDate} isCleansed={false} isMultiChoice={false} onClose={() => setOpenToCalendar(false)} />
                            </div>
                            <div className="input-item">
                                {endDate ? <div className="input-picker-title">{t('filters.back')}</div> : null}
                                <div className={`input-picker-wrap ${endDate ? 'active' : ''}`}>
                                    <input value={endDate ? formatter('D MMMM', i18n.language, endDate) : ''} onClick={onClickBackCalendar} className="input datepicker pointer" type="text" id="datepicker_2" placeholder={t('filters.back')} readOnly={true} />
                                    {endDate ? <SvgIcon className={"icon icon-close"} href={"#svg_icon_close"} onClick={clearEnd} /> : <SvgIcon onClick={onClickBackCalendar} className={"icon icon-calendar"} href={"#svg_icon_calendar"} />}
                                </div>
                                <DatePickerCalendar disableBeforeDate={startDate? startDate: null} startDate={endDate} isShown={isOpenBackCalendar} onSelected={onSelectedBackDate} isCleansed={false} isMultiChoice={false} onClose={() => setOpenBackCalendar(false)} />
                            </div>
                        </React.Fragment>
                    </Dropdown>
                    <Dropdown>
                        <div className="filter-btns-two m-t-15">
                            <a className="btn btn_gray width-100 pointer" onClick={() => clearAllClickHandler()}>{t('filters.reset')}</a>
                        </div>
                    </Dropdown>
                </div>
            </div>
            {props.isAdvance
                ? <CreateAOModal isOpen={isOpenCreateModal} onClose={() => setOpenCreateModal(false)} />
                : <CreateExpenseModal isOpen={isOpenCreateModal} onClose={() => setOpenCreateModal(false)} />}
        </React.Fragment>
    );
}

export default withRouter(ListFilter);