/**
 * @typedef {Object} AGREEMENT_ITEM_LEVEL_TYPE
 * @property {string} LINE_MANAGER - Линейные менеджеры
 * @property {string} ACCOUNTANT - Линейные бухгалтеры
 * @property {string} FINANCIAL_DIRECTOR - Главное руководство
 */

export enum AGREEMENT_ITEM_LEVEL_TYPE {
    LINE_MANAGER = 'LineManager',
    ACCOUNTANT = 'Accountant',
    FINANCIAL_DIRECTOR = 'FinancialDirector',
}