import './Tooltip.css';
import React, { useState, useEffect } from 'react';
import { getNextKey } from 'app/utils';

interface TooltipProps {
  textArray?: (string | undefined)[],
  fontSize?: number,
  width?: number,
  active?: boolean,
  block?: boolean
  notHovered?: boolean
}

const Tooltip: React.FC<TooltipProps> = (props) => {
  let [tooltipProps, setTooltipProps] = useState({
    fontSize: '16px',
    width: '120px',
    marginLeft: '-60px'
  })

  useEffect(()=>{
    let newProps = {...tooltipProps};

    if(props.fontSize){
      newProps.fontSize = props.fontSize + 'px';
    }

    if(props.width){
      newProps.width = props.width + 'px';
      newProps.marginLeft = '-' + (props.width/2) + 'px';
    }

    setTooltipProps(newProps);
  },[props.fontSize, props.width]);

  return (
    <div className={"tooltip-expense" + (props.block ? " block" : "")}>{props.children}
      <span className={"tooltiptext" + (props.active ? " active" : "") + (props.notHovered ? "" : " hovered")} style={{fontSize: tooltipProps.fontSize, width: tooltipProps.width, marginLeft: tooltipProps.marginLeft}}>{props.textArray ? props.textArray.map(item => <div key={getNextKey('Tooltip')}>{item} <br/></div>) : ''}</span>
    </div>
  );
};

export default Tooltip;