import React, { useState, useRef, useEffect } from "react";
import "./CityAutocomplete.css";
import SvgIcon from "../svg-icon/SvgIcon";
import Slidebar from "../slidebar/Slidebar";
import { Form } from "react-bootstrap";

export interface CostCenterPercentAutocompleteProps {
  onChoose?: (text: string) => void;
  defaultText?: string;
  placeholder?: string;
  readOnly?: boolean;
  options?: CostCenterPercentAutocompleteOptionType[];
  disabled?: boolean;
  tabIndex?: -1 | 0;
  isWidget?: boolean;
}

export interface CostCenterPercentAutocompleteOptionType {
  value: number;
  text: string;
}

const CostCenterPercentAutocomplete: React.FC<
  CostCenterPercentAutocompleteProps
> = (props) => {
  let [isOpen, setOpen] = useState(false);
  let [inputValue, setInputValue] = useState(
    props.defaultText ? props.defaultText : ""
  );

  let inputRef = useRef({} as HTMLInputElement);

  useEffect(() => {
    setInputValue(props.defaultText ? props.defaultText : "");
  }, [props.defaultText]);

  const optionClickHandler = (
    option: CostCenterPercentAutocompleteOptionType
  ) => {
    setInputValue(option.text);
    setOpen(false);
    if (props.onChoose) props.onChoose(option.text);
  };

  const titleClickHandler = () => {
    if (props.readOnly) {
      return;
    } else {
      setOpen(!isOpen);
    }
  };

  const changeInput = () => {
    if (isNumberInputPositive(inputRef)) {
      return;
    }
    if (inputRef.current.value === "") {
      setInputValue("");
      if (props.onChoose) props.onChoose("");
      return;
    }
    if (+inputRef.current.value >= 1 && +inputRef.current.value <= 100) {
      setInputValue(inputRef.current.value);
      if (props.onChoose) props.onChoose(inputRef.current.value);
    }
  };

  const isNumberInputPositive = (ref: any) => {
    return (
      ref.current.value.match(/^\d+$/) === null && ref.current.value.length > 0
    );
  };

  const missClickHandler = () => {
    if (isOpen) {
      setOpen(false);
    }
  };

  const bootstrapOptionClickHandler = (value: string) => {
    const chosenOption =
      props.options && props.options.find((option) => option.value === +value);
    if (props.onChoose && chosenOption) {
      props.onChoose(chosenOption.text);
    }
  };

  return props.isWidget ? (
    <Form.Control
      as="select"
      disabled={props.disabled || props.readOnly}
      defaultValue={inputValue}
      style={{ backgroundImage: "none" }}
      onChange={(e) => bootstrapOptionClickHandler(e.target.value)}
    >
      <option value={inputValue} style={{ display: "none" }}>
        {inputValue}
      </option>
      {props.options &&
        props.options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          );
        })}
    </Form.Control>
  ) : (
    <div
      className={
        "select " +
        (isOpen ? "active" : "") +
        (props.disabled ? " disabled" : "")
      }
    >
      {isOpen ? (
        <div className="miss-click-handler" onClick={missClickHandler} />
      ) : (
        ""
      )}
      <div className="select-title">
        <input
          style={{ border: "none", padding: 0 }}
          type="text"
          ref={inputRef}
          value={inputValue}
          onInput={changeInput}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          disabled={props.disabled}
          tabIndex={props.tabIndex}
        />
        <SvgIcon
          className="icon icon-arrow-top"
          href="#svg_icon_arrow_top"
          onClick={titleClickHandler}
        />
      </div>
      <Slidebar
        className="select-content"
        style={{ zIndex: 20, maxHeight: "185px" }}
      >
        {props.options && props.options.length > 0
          ? props.options.map((option, index) => {
              return (
                <div
                  className="select-content-item"
                  key={index}
                  onClick={() => optionClickHandler(option)}
                >
                  {option.text}
                </div>
              );
            })
          : null}
      </Slidebar>
    </div>
  );
};

export default CostCenterPercentAutocomplete;
