import SvgIcon from 'app/component/svg-icon/SvgIcon';
import React, {Fragment, useEffect, useRef, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {loaderLock, loaderUnlock} from 'store/common/actions';
import {NameFormats, nameFormatter} from 'app/utils';
import { ApplicationState } from 'store/reducers';
import Config, {ConfigTypes} from 'services/ConfigService';
import {showErrors} from 'store/exception/actions';
import If from 'app/component/util/If';
import {difference, get} from 'lodash';
import ModalContainer from 'app/component/modal/ModalContainer';
import EmployeeAutocomplete from 'app/component/autocomplete/EmployeeAutocomplete';
import Radio from 'app/component/radio/Radio';
import Slidebar from 'app/component/slidebar/Slidebar';
import SubmitCustomModal from 'app/component/modal/SubmitCustomModal';
import AssistanceWarningModal from 'app/component/modal/AssistanceWarningModal';
import {PROFILE_FIELD_PERMISSIONS} from "../../../infrastructure/enum/field-permissions.enum";
import {getCommonUserDetail} from "../../../store/selectors";
import {
  deleteAssistancesById, deleteAssistancesByOccupationId,
  getAssistances,
  getAssistancesById,
  getEmployeeByParams,
  postAssistances
} from "../../../services/ApiService";

const Assistance: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  let userDetail = useSelector(getCommonUserDetail);
  let profileFieldPermissions = useSelector((state: ApplicationState) => state.common.profileFieldPermissions);

  let [assistantList, setAssistantList] = useState([] as {
    assistantId: number,
    assistantName: {
      ru: string,
      en: string
    },
    occupation: {
      id: number,
      name: {
        ru: string,
        en: string
      }
    },
    assistantCompany: {
      id: number,
      ru: string,
      en: string
    },
    numberOfAssistances: number,
    updateTime: number
  }[]);
  let [isOpenEditModal, setOpenEditModal] = useState(false);
  let [editAssistant, setEditAssistant] = useState({ assistantId: 0, assistantName: { ru: '', en: '' }, assistantCompany: { id: 0, ru: '', en: '' } });
  let [inputValue, setInputValue] = useState(0);
  let [isOpenWarningAssistanceModal, setOpenWarningAssistanceModal] = useState(false);
  let [isOpenWarningDeleteModal, setOpenWarningDeleteModal] = useState(false);
  let [assistantIds, setAssistantIds] = useState([] as number[]);
  let [employeeIds, setEmployeeIds] = useState([] as number[]);
  let [deleteAssistantId, setDeleteAssistantId] = useState(0);


  useEffect(() => {
    getAssistant();
  }, []);

  const onChoose = (value: { value: number, text: string }) => {
    if (value.value) {
      setAssistantList([]);
      setInputValue(value.value);
      getAssistant(value.value);
    }

    if (!value.value && inputValue != 0) {
      setAssistantList([]);
      setInputValue(0);
      getAssistant();
    }
  }

  const openCreateModal = () => {
    initRowEdit({ assistantId: 0, assistantName: { ru: '', en: '' }, assistantCompany: { id: 0, ru: '', en: '' }, });
  }

  const initRowEdit = (editUser: { assistantId: number, assistantName: { ru: string, en: string }, assistantCompany: { id: number, ru: string, en: string } }) => {
    setEditAssistant(editUser);
    setOpenEditModal(true);
  }

  const onCloseHandler = () => {
    setOpenEditModal(false);
  }

  const toggleWarningAssistanceModal = () => {
    setOpenWarningAssistanceModal(!isOpenWarningAssistanceModal);
  }

  const toggleWarningDeleteModal = () => {
    setOpenWarningDeleteModal(!isOpenWarningDeleteModal);
  }

  const preSaveHandler = async (assistantIds: number[], employeeIds: number[]) => {
    setAssistantIds(assistantIds);
    setEmployeeIds(employeeIds);
    if (editAssistant.assistantId == 0) {
      let response = await getAssistancesById(assistantIds[0]);
      if (response.headers.success) {
        let assistancesList = get(response, 'data.value.assistances', []);
        if (assistancesList.length > 0) {
          toggleWarningAssistanceModal();
          return
        } else {
          onSaveHandler(assistantIds, employeeIds)
          return;
        }
      }
    }
    onSaveHandler(assistantIds, employeeIds);
  }

  const save = () => {
    toggleWarningAssistanceModal();
    onSaveHandler(assistantIds, employeeIds);
  }

  const onSaveHandler = async (assistantIds: number[], employeeIds: number[]) => {
    dispatch(loaderLock());

    let resultAction = [] as { assistantId: number, addIds: number[], removeIds: number[], removeAssistant: boolean }[];

    if (assistantIds.length > 0) {
      for (let index = 0; index < assistantIds.length; index++) {
        const assistantId = assistantIds[index];
        let assistenceArray = await getAssistance(assistantId);
        let assistenceIds = assistenceArray.map((item: any) => item.employeeId);

        let addIds = [] as number[];
        let removeIds = [] as number[];
        let removeAssistant = false;

        if (index == 0) {
          addIds = difference(employeeIds, assistenceIds);
          let removeEmployeeIds = difference(assistenceIds, employeeIds);
          removeIds = assistenceArray.filter((item: any) => removeEmployeeIds.includes(item.employeeId)).map((item: any) => item.assistanceId);
          if (removeIds.length > 0 && removeIds.length == assistenceIds.length) {
            removeAssistant = true;
          }
        } else {
          addIds = difference(employeeIds, assistenceIds);
        }

        resultAction.push({ assistantId: assistantId, addIds: addIds, removeIds: removeIds, removeAssistant: removeAssistant })
      }
    }

    let allWait = [] as Promise<void>[];

    resultAction.forEach((item) => {

      if (!item.removeAssistant && item.removeIds.length > 0) {
        allWait.push(deleteAssistance(item.removeIds));
      }

      if (item.removeAssistant) {
        allWait.push(deleteAssistant(item.assistantId));
      }

      if (item.addIds.length > 0) {
        allWait.push(addAssistance(item.assistantId, item.addIds));
      }
    });

    await Promise.all(allWait);

    if (inputValue == 0) {
      await getAssistant();
    } else {
      await getAssistant(inputValue);
    }
    dispatch(loaderUnlock());
    setOpenEditModal(false);
  }

  const getAssistant = async (employee?: number) => {
    dispatch(loaderLock());
    let response = await getAssistances({ params: { PageSize: 1000, Page: 1, AssistantEmployeeId: employee } });
    if (response.headers.success) {
      let newAssistantList = response.data.data.map((item: any) => {
        return {
          assistantId: get(item, 'assistantId', 0),
          assistantName: {
            ru: get(item, 'assistantName.ru', ''),
            en: get(item, 'assistantName.en', '')
          },
          occupation: {
            id: 0,
            name: {
              ru: get(item, 'assistantOccupationName.ru', ''),
              en: get(item, 'assistantOccupationName.en', '')
            }
          },
          assistantCompany: {
            id: get(item, 'company.id', ''),
            ru: `${get(item, 'company.name.ru', '')} - ${get(item, 'assistantOccupationName.ru', '')}`,
            en: `${get(item, 'company.name.en', '')} - ${get(item, 'assistantOccupationName.en', '')}`
          },
          numberOfAssistances: get(item, 'numberOfAssistances', 0),
          updateTime: +(new Date())
        }
      });
      setAssistantList(newAssistantList);
    } else {
      dispatch(showErrors({ code: 'del_substituation', message: 'Не удалось загрузить ассистентов' }))
    }
    dispatch(loaderUnlock());
  }

  const getAssistance = async (assistantId: number) => {
    dispatch(loaderLock());

    let response = await getAssistancesById(assistantId);
    if (response.headers.success) {
      let newAssistanceList = response.data.value.assistances.map((item: any) => {
        return {
          assistanceId: get(item, 'assistanceId', 0),
          employeeId: get(item, 'employeeOccupationId', 0)
        }
      });
      dispatch(loaderUnlock());
      return newAssistanceList;
    } else {
      dispatch(showErrors({ code: 'del_substituation', message: 'Не удалось загрузить сотрудников' }));
      dispatch(loaderUnlock());
      return [];
    }
  }

  const addAssistance = async (assistantId: number, employeeIds: number[]) => {
    dispatch(loaderLock());

    let allRequest = [] as Promise<void>[];

    employeeIds.forEach(item => allRequest.push(postAssistances({ "assistantOccupationId": assistantId, "employeeOccupationId": item })));

    let responses = await Promise.all(allRequest);

    responses.forEach((response: any) => {
      if (response.headers.success) {

      } else {
        dispatch(showErrors({ code: 'del_substituation', message: 'Не удалось добавить сотрудника к ассистенту' }))
      }
    });

    dispatch(loaderUnlock());
  }

  const deleteAssistance = async (assistanceId: number[]) => {
    dispatch(loaderLock());

    let allRequest = [] as Promise<void>[];

    assistanceId.forEach(item => allRequest.push(deleteAssistancesById(item)));

    let responses = await Promise.all(allRequest);

    responses.forEach((response: any) => {
      if (response.headers.success) {

      } else {
        dispatch(showErrors({ code: 'del_substituation', message: 'Не удалось удалить сотрудника к ассистенту' }))
      }
    });

    dispatch(loaderUnlock());
  }

  const deleteAssistant = async (assistantId: number) => {
    dispatch(loaderLock());
    let response = await deleteAssistancesByOccupationId(assistantId);
    if (response.headers.success) {
    } else {
      dispatch(showErrors({ code: 'del_substituation', message: 'Не удалось удалить ассистента' }))
    }
    dispatch(loaderUnlock());
  };

  const deleteAssistantSubmit = async () => {
    dispatch(loaderLock());
    await deleteAssistant(deleteAssistantId);
    setDeleteAssistantId(0);
    setOpenWarningDeleteModal(false);
    if (inputValue == 0) {
      await getAssistant();
    } else {
      await getAssistant(inputValue);
    }
    dispatch(loaderUnlock());
  };

  const deleteHandler = async (assistantId: number) => {
    setDeleteAssistantId(assistantId);
    setOpenWarningDeleteModal(true);
  };

  const showAssistance: boolean = useMemo(() => {
    return profileFieldPermissions && profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.ASSISTANCE)
  }, [profileFieldPermissions])

  return (
    <Fragment>
      <div className="profile-calculation">
        <div className="profile-calculation-main">
          <div className="profile-calc-table-wrap">
            <div className={'input-item'} style={{ marginBottom: '30px' }}>
              <div className="input-search">
                <EmployeeAutocomplete onChoose={onChoose} placeholder={t('assistance_list.search_placeholder')} />
              </div>
            </div>
            <If condition={assistantList.length == 0}>
              <div className="request-list empty">
                <div className="request-list-empty">
                  <img src="/img/empty-img.png" />
                  <div style={{ width: 'inherit' }}>{t('assistance_list.empty_list')}</div>
                </div>
              </div>
            </If>
            <If condition={assistantList.length > 0}>
              <div className="profile-calc-table table-sub">
                <div className="profile-calc-table-row">
                  <div className="profile-calc-table-th" style={{ maxWidth: 'none', width: 'calc(50% - 75px)' }}>{t('assistance_list.table.assistant')}</div>
                  <div className="profile-calc-table-th" style={{ maxWidth: 'none', width: 'calc(50% + 75px)' }}>{t('assistance_list.table.employee')}</div>
                </div>
                {assistantList.map((item) => {
                  return <AssistanceRow key={item.assistantId}
                    assistantId={item.assistantId}
                    assistantName={{ ru: item.assistantName.ru, en: item.assistantName.en }}
                    assistantCompany={{ id: item.assistantCompany.id, ru: item.assistantCompany.ru, en: item.assistantCompany.en }}
                    assistantOccupation={item.occupation}
                    assistantCompanyId={item.assistantCompany.id}
                    numberOfAssistances={item.numberOfAssistances}
                    updateTime={item.updateTime}
                    onEdit={initRowEdit}
                    onDelete={deleteHandler} />
                })}
              </div>
            </If>
          </div>
        </div>
        <If condition={showAssistance}>
          <div className="profile-calculation-filter profile-calculation-filter-sub"><a className="btn-expense btn_green" onClick={openCreateModal}>{t('assistance_list.add')}</a></div>
        </If>
      </div>
      <AssistanceModal isOpen={isOpenEditModal} assistant={editAssistant} onClose={onCloseHandler} onSave={preSaveHandler} />
      <SubmitCustomModal isOpen={isOpenWarningDeleteModal} title={t('assistance_list.delete_alert')} onClose={toggleWarningDeleteModal} onSubmit={deleteAssistantSubmit} />
      <AssistanceWarningModal isOpen={isOpenWarningAssistanceModal} title={t('assistance_list.rewrite_alert_title')} info={t('assistance_list.rewrite_alert_info')} sub_info={t('assistance_list.rewrite_alert_sub_info')} todo={t('assistance_list.rewrite_alert_todo')} onClose={toggleWarningAssistanceModal} onSubmit={save} />
    </Fragment>
  )
};

export default Assistance;


interface AssistanceRowProps {
  assistantId: number,
  assistantName: {
    ru: string,
    en: string
  },
  assistantCompany: {
    id: number,
    ru: string,
    en: string
  },
  assistantOccupation: {
    id: number,
    name: {
      ru: string,
      en: string
    }
  },
  assistantCompanyId: number,
  numberOfAssistances: number,
  onEdit: (editUser: { assistantId: number, assistantName: { ru: string, en: string }, assistantCompany: { id: number, ru: string, en: string } }) => void,
  onDelete: (assistantId: number) => void,
  updateTime: number
}

const AssistanceRow: React.FC<AssistanceRowProps> = (props) => {
  const { t, i18n } = useTranslation();

  const assistanceSource = Config.getConfigToCompany(ConfigTypes.ASSISTANCE_SOURCE, props.assistantCompanyId);

  const dispatch = useDispatch();

  let [isOpen, setOpen] = useState(false);
  let [assistanceList, setAssistanceList] = useState([] as { assistanceId: number, employeeId: number, employeeName: { ru: string, en: string } }[]);

  useEffect(() => {
    if (isOpen) {
      getAllAssistances();
    }
  }, [props.updateTime]);

  const toggleRow = async () => {
    if (!isOpen) {
      await getAllAssistances();
    }
    setOpen(!isOpen);
  }

  const getAllAssistances = async () => {
    dispatch(loaderLock());
    let response = await getAssistancesById(props.assistantId);
    if (response.headers.success) {
      let newAssistanceList = response.data.value.assistances.map((item: any) => {
        return {
          assistanceId: get(item, 'assistanceId', 0),
          employeeId: get(item, 'employeeId', 0),
          employeeName: {
            ru: `${get(item, 'employeeName.ru', '')} - ${get(item, 'employeeOccupationName.ru', '')}`,
            en: `${get(item, 'employeeName.ru', '')} - ${get(item, 'employeeOccupationName.ru', '')}`
          }
        }
      });
      setAssistanceList(newAssistanceList);
    } else {
      dispatch(showErrors({ code: 'del_substituation', message: 'Не удалось загрузить сотрудников' }))
    }
    dispatch(loaderUnlock());
  }

  const handleEdit = async (event: any, user: { assistantId: number, assistantName: { ru: string, en: string }, assistantCompany: { id: number, ru: string, en: string } }) => {
    props.onEdit(user);
    event.stopPropagation();
  };

  const handleDelete = async (event: any, assistantId: number) => {
    props.onDelete(assistantId);
    event.stopPropagation();
  };

  return <Fragment>
    <div className="profile-calc-table-row" onClick={toggleRow}>
      <div className="profile-calc-table-td" style={{ maxWidth: 'none', width: 'calc(50% - 75px)', background: isOpen ? 'rgba(233, 236, 242, 0.3)' : '#FFF' }}>{props.assistantName[i18n.language as 'ru' | 'en']} - {props.assistantOccupation.name[i18n.language as 'ru' | 'en']}</div>
      <div className="profile-calc-table-td" style={{ maxWidth: 'none', width: 'calc(50% + 75px)', background: isOpen ? 'rgba(233, 236, 242, 0.3)' : '#FFF', justifyContent: 'space-between' }}>
        <div style={{ float: 'left' }}>
          {props.numberOfAssistances}
        </div>
        <If condition={assistanceSource == 1}>
          <div style={{ float: 'right', display: 'flex' }}>
            <div className="btn btn_gray add-trip" onClick={(event) => { handleEdit(event, { assistantId: props.assistantId, assistantName: props.assistantName, assistantCompany: props.assistantCompany }) }} style={{ marginRight: '10px' }}>{t('assistance_list.table.edit')}</div>
            <div onClick={(event) => { handleDelete(event, props.assistantId) }} ><SvgIcon className="icon icon-remove" href="#svg_icon_remove" type={'delete'} /></div>
          </div>

        </If>
      </div>
    </div>

    {assistanceList.map((item) => {
      return <div className="profile-calc-table-row" style={{ display: isOpen ? 'flex' : 'none' }}>
        <div className="profile-calc-table-td" style={{ maxWidth: 'none', width: 'calc(50% - 75px)', background: 'rgba(233, 236, 242, 0.3)' }}></div>
        <div className="profile-calc-table-td" style={{ maxWidth: 'none', width: 'calc(50% + 75px)', background: 'rgba(233, 236, 242, 0.3)', justifyContent: 'space-between' }}>
          <div style={{ float: 'left' }}>
            {item.employeeName[i18n.language as 'ru' | 'en']}
          </div>
        </div>
      </div>
    })}
  </Fragment>
};


interface AssistanceModalProps {
  isOpen: boolean,
  assistant: {
    assistantId: number,
    assistantName: {
      ru: string,
      en: string
    },
    assistantCompany: {
      id: number,
      ru: string,
      en: string
    }
  },
  onClose: () => void,
  onSave: (assistantIds: number[], employeeIds: number[]) => void
}

const AssistanceModal: React.FC<AssistanceModalProps> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  let [inputValue, setInputValue] = useState('');
  let [employeeTimer, setEmployeeTimer] = useState({} as any);

  let [searchUsers, setSearchUsers] = useState([] as { id: number, name: { ru: string, en: string }, company: { id: number, ru: string, en: string } }[]);
  let [assistantUsers, setAssistantUsers] = useState([] as { id: number, name: { ru: string, en: string }, company: { id: number, ru: string, en: string } }[]);
  let [assistanceUsers, setAssistanceUsers] = useState([] as { assistanceId: number, employeeId: number, name: { ru: string, en: string }, company: { id: number, ru: string, en: string } }[]);
  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  let inputRef = useRef({} as HTMLInputElement);

  useEffect(() => {
    if (props.isOpen) {
      let newAssistantUsers = [];
      if (props.assistant.assistantId != 0) {
        newAssistantUsers.push({
          id: props.assistant.assistantId,
          name: props.assistant.assistantName,
          company: {
            id: props.assistant.assistantCompany.id,
            ru: props.assistant.assistantCompany.ru,
            en: props.assistant.assistantCompany.en
          }
        });
        getAllAssistances();
      }
      setAssistantUsers(newAssistantUsers);
      setSearchUsers([]);
      setAssistanceUsers([]);
      setInputValue('');
    }
  }, [props.isOpen]);

  const changeInput = () => {
    setSearchUsers([]);
    setInputValue(inputRef.current.value);
    clearTimeout(employeeTimer);
    if (inputRef.current.value.length > 2) {
      let name = inputRef.current.value;
      (() => {
        setEmployeeTimer(setTimeout(() => { getEmployee(name) }, 800));
      })();
    }
  }

  const getEmployee = async (nameValue: string) => {
    dispatch(loaderLock());

    let result = await getEmployeeByParams({ params: { Name: nameValue } });
    if ((result.config.params.Name == inputRef.current.value)) {
      let newSearchUser = [];
      if (result.headers.success == true) {
        newSearchUser = result.data.data.map((item: any) => {
          return item.occupations.map((occupation: any) => {
            return {
              id: occupation.id,
              name: {
                ru: nameFormatter(item.name, NameFormats.FULL, 'ru'),
                en: nameFormatter(item.name, NameFormats.FULL, 'en')
              },
              occupation: {
                id: occupation.id,
                name: {
                  ru: occupation.name.ru,
                  en: occupation.name.ru
                }
              },
              company: {
                id: occupation.company.id,
                ru: `${occupation.company.name.ru} - ${occupation.name.ru}`,
                en: `${occupation.company.name.en} - ${occupation.name.ru}`
              }
            };
          });
        });
      }

      newSearchUser = newSearchUser.flatMap((item: any) => item);

      let assistantUsersIds = assistantUsers.map(item => item.id);
      let assistanceUsersIds = assistanceUsers.map(item => item.employeeId);

      newSearchUser = newSearchUser.filter((item: any) => {
        return (!assistantUsersIds.includes(item.id) && !assistanceUsersIds.includes(item.id));
      });
      setSearchUsers(newSearchUser);
    }
    dispatch(loaderUnlock());
  }

  const getAllAssistances = async () => {
    dispatch(loaderLock());
    let response = await getAssistancesById(props.assistant.assistantId);
    if (response.headers.success) {
      let newAssistanceList = response.data.value.assistances.map((item: any) => {
        return {
          assistanceId: get(item, 'assistanceId', 0),
          employeeId: get(item, 'employeeOccupationId', 0),
          //employeeId: get(item, 'employeeId', 0),
          name: {
            ru: get(item, 'employeeName.ru', ''),
            en: get(item, 'employeeName.en', '')
          },
          occupation: {
            id: get(item, 'employeeOccupationId', 0),
            name: {
              ru: get(item, 'employeeOccupationName.ru', ''),
              en: get(item, 'employeeOccupationName.ru', '')
            }
          },
          company: {
            id: get(item, 'company.id', 0),
            ru: `${get(item, 'company.name.ru', '')} - ${get(item, 'employeeOccupationName.ru', '')}`,
            en: `${get(item, 'company.name.en', '')} - ${get(item, 'employeeOccupationName.ru', '')}`
          }
        }
      });
      setAssistanceUsers(newAssistanceList);
    } else {
      dispatch(showErrors({ code: 'del_substituation', message: 'Не удалось загрузить сотрудников' }))
    }
    dispatch(loaderUnlock());
  }

  const addHandler = (assistantId: number, type: 'assistant' | 'assistance') => {
    if (type == 'assistant') {
      let newUser = searchUsers.find(item => item.id == assistantId);
      if (newUser) {
        let newAssistantUsers = [...assistantUsers];
        newAssistantUsers.push({
          id: newUser.id,
          name: newUser.name,
          company: newUser.company
        });
        setAssistantUsers(newAssistantUsers);
      }
    }

    if (type == 'assistance') {
      let newUser = searchUsers.find(item => item.id == assistantId);
      if (newUser) {
        let newAssistanceUsers = [...assistanceUsers];
        newAssistanceUsers.push({
          assistanceId: 0,
          employeeId: newUser.id,
          name: newUser.name,
          company: newUser.company
        });
        setAssistanceUsers(newAssistanceUsers);
      }
    }

    let newSearchUsers = searchUsers.filter(item => item.id != assistantId);
    setSearchUsers(newSearchUsers);
  };

  const deleteAssistantHandler = (assistantId: number) => {
    setAssistantUsers(assistantUsers.filter(item => item.id != assistantId));
  };

  const deleteAssistanceHandler = (assistantId: number) => {
    setAssistanceUsers(assistanceUsers.filter(item => item.employeeId != assistantId));
  };

  const onSaveHandler = () => {
    props.onSave(assistantUsers.map(item => item.id), assistanceUsers.map(item => item.employeeId));
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  }

  return <ModalContainer isOpen={props.isOpen} highestModal={isHighestModal} overlayClick={props.onClose} destroy={true}>
    <div className="box-modal" id="add-reassignment">
      <div className="box-modal_close arcticmodal-close" onClick={props.onClose}></div>
      <div className="box-modal-title">{t('assistance_list.modal.header')}</div>
      <div className="box-modal-content">
        <div className="box-modal-form">
          <div className="box-modal-form-block">
            <div className="input-item-row">
              <div className={'input-item'}>
                <label className="input-label">{t('assistance_list.modal.search')}</label>
                <div className="input-search">
                  <input className="input" type="text" ref={inputRef} value={inputValue} onChange={changeInput} placeholder={t('assistance_list.modal.search_placeholder')} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                  <SvgIcon href={'#svg_icon_search'} className={'icon icon-search'} />
                </div>
              </div>
            </div>
            <div className="box-modal-form-block">
              <Slidebar style={{ maxHeight: '170px' }}>
                {searchUsers.map((item) => {
                  return <AssistanceModalRow
                    key={item.id}
                    type='search'
                    assistantId={item.id}
                    name={item.name[i18n.language as 'ru']}
                    company={item.company[i18n.language as 'ru']}
                    companyId={item.company.id}
                    onAdd={addHandler}
                  />
                })}
              </Slidebar>
            </div>
          </div>
          <div className="box-modal-form-block">
            <div className="title">{t('assistance_list.modal.assistant_header')}</div>
            <div className="box-modal-form-block">
              <Slidebar style={{ maxHeight: '170px' }}>
                {assistantUsers.length > 0 ? assistantUsers.map((item) => {
                  return <AssistanceModalRow
                    key={item.id}
                    type='assistant'
                    assistantId={item.id}
                    name={item.name[i18n.language as 'ru']}
                    company={item.company[i18n.language as 'ru']}
                    companyId={item.company.id}
                    onDelete={deleteAssistantHandler}
                  />
                }) : <AssistanceModalRow type='message' assistantId={0} name={t('assistance_list.modal.add_assistant_message')} company={''} companyId={0} />}
              </Slidebar>
            </div>
          </div>
          <div className="box-modal-form-block">
            <div className="title">{t('assistance_list.modal.employee_header')}</div>
            <div className="box-modal-form-block">
              <Slidebar style={{ maxHeight: '170px' }}>
                {assistanceUsers.length > 0 ? assistanceUsers.map((item) => {
                  return <AssistanceModalRow
                    key={item.employeeId}
                    type='assistance'
                    assistantId={item.employeeId}
                    name={item.name[i18n.language as 'ru']}
                    company={item.company[i18n.language as 'ru']}
                    companyId={item.company.id}
                    onDelete={deleteAssistanceHandler} />
                }) : <AssistanceModalRow type='message' assistantId={0} name={t('assistance_list.modal.add_employee_message')} company={''} companyId={0} />}
              </Slidebar>
            </div>
          </div>
        </div>
      </div>
      <div className="box-modal-footer">
        <a className="arcticmodal-close btn btn_black" onClick={props.onClose} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('assistance_list.modal.cancel')}</a>
        <a className="btn-expense btn_green" onClick={onSaveHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('assistance_list.modal.save')}</a>
      </div>
    </div>
  </ModalContainer>

};



interface AssistanceModalRowProps {
  assistantId: number,
  name: string,
  company: string,
  companyId: number,
  type: 'search' | 'assistant' | 'assistance' | 'message',
  onAdd?: (assistantId: number, type: 'assistant' | 'assistance') => void,
  onDelete?: (assistantId: number, type: 'assistant' | 'assistance') => void
};

const AssistanceModalRow: React.FC<AssistanceModalRowProps> = (props) => {
  const { t } = useTranslation();

  let [background, setBackground] = useState('');
  let [isAssistant, setAssistant] = useState(false);
  let [assistanceSource, setAssistanceSource] = useState(0);

  useEffect(() => {
    if (props.companyId) {
      setAssistanceSource(Config.getConfigToCompany(ConfigTypes.ASSISTANCE_SOURCE, props.companyId));
    }
  }, [props.companyId]);

  useEffect(() => {
    switch (props.type) {
      case 'search':
        setBackground('#D9E2E7');
        break;
      case 'assistant':
        setBackground('#F0F0EF');
        break;
      case 'assistance':
        setBackground('#E8EDEE');
        break;
      default:
        setBackground('#F0F0EF');
        break;
    }
  }, []);

  const radioAssistantClickHandler = () => {
    setAssistant(true);
  }

  const radioEmployerClickHandler = () => {
    setAssistant(false);
  }

  const addClickHandler = () => {
    props.onAdd && props.onAdd(props.assistantId, isAssistant ? 'assistant' : 'assistance');
  }

  const deleteClickHandler = () => {
    props.onDelete && props.onDelete(props.assistantId, isAssistant ? 'assistant' : 'assistance');
  }

  return (
    <div style={{ display: 'flex', width: '100%', height: '62px', marginTop: '4px', background: background, borderRadius: '4px' }}>
      <div style={{ width: '52%' }}>
        <div style={{
          marginTop: '12px',
          marginLeft: '12px',
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '20px'
        }}>{props.name}</div>
        <div style={{
          marginTop: '3px',
          marginLeft: '12px',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '16px'
        }}>{props.company}</div>
      </div>
      <div style={{ display: 'flex', width: '43%', marginTop: '16px' }}>
        {props.type == 'search' && assistanceSource == 1 ? <>
          <Radio
            key={1}
            name={'employeTypeRadio' + props.assistantId}
            id={1}
            label={t('assistance_list.modal.option_assistant')}
            checked={isAssistant}
            onClick={radioAssistantClickHandler}

          />
          <div style={{ width: '20px' }} />
          <Radio
            key={2}
            name={'employeTypeRadio' + props.assistantId}
            id={2}
            label={t('assistance_list.modal.option_employee')}
            checked={!isAssistant}
            onClick={radioEmployerClickHandler}
          />
        </> : null
        }

      </div>
      <div style={{ width: '5%', marginTop: '20px' }} >
        {props.type == 'search' && assistanceSource == 1 ? <div onClick={addClickHandler}><SvgIcon className="icon icon-close" href="#svg_icon_plus" style={{ width: '22px', height: '22px' }} /></div> : null}
        {((props.type == 'assistant' || props.type == 'assistance') && assistanceSource == 1) ? <div onClick={deleteClickHandler} style={{ marginTop: '1px' }}><SvgIcon className="icon icon-close" href="#svg_icon_close" /></div> : null}
      </div>
    </div>
  );
};