import React, { useEffect, useState } from 'react';
import ModalContainer from 'app/component/modal/ModalContainer';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/reducers';
import { AssigneeEmployee, CustomProperties, BasicType } from 'infrastructure/interfaces';
import { useTranslation } from 'react-i18next';
import { updateDetailedAO } from 'store/report/actions';
import { updateApplicationForExpenseDetail } from 'store/applicationForExpense/actions';
import {getAdvanceReportApplication, getApplicationForExpenseDetail} from "../../../store/selectors";
import {
  getAdvanceReportApplicationCustomProperties,
  getExpenseApplicationsCustomProperties
} from "../../../services/ApiService";

interface EmployeeInformationModalProps {
  id: number,
  isOpen: boolean,
  onClose: () => void,
  isAdvance?: boolean
  isZNR?: boolean
}

const EmployeeInformationModal: React.FC<EmployeeInformationModalProps> = (props) => {
  const { t, i18n } = useTranslation();

  let [employeeInformation, setEmployeeInformation] = useState({} as AssigneeEmployee);
  let [customProperties, setCustomProperties] = useState([] as CustomProperties[]);
  let [lineManager, setLineManager] = useState({} as BasicType);
  let advanceReport = useSelector(getAdvanceReportApplication);
  let applicationForExpenseDetail = useSelector(getApplicationForExpenseDetail);
  let [customPropertiesArr, setCustomPropertiesArr] = useState({customProperties: []});
  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);
  
  const dispatch = useDispatch();

  useEffect(() => {
    if(props.isOpen) {
      props.isAdvance ? dispatch(updateDetailedAO(props.id)) : dispatch(updateApplicationForExpenseDetail(props.id));
    }
    const getCustomPropertiesArr = async () => {
      let result = await props.isZNR ? await getExpenseApplicationsCustomProperties(props.id)
          : await getAdvanceReportApplicationCustomProperties(props.id)
      if (result.headers.success) {
        setCustomPropertiesArr(result.data);
      }
    };
    getCustomPropertiesArr();
  }, [props.isOpen])

  useEffect(() => {
    if(props.isOpen){
      setInitialValues();
    }
  }, [advanceReport, applicationForExpenseDetail])

  const setInitialValues = async () => {
    if(props.isAdvance && advanceReport) {
      setEmployeeInformation(advanceReport.assigneeEmployee);
      setCustomProperties(advanceReport.customProperties);
      setLineManager(advanceReport.lineManger);
    } else if(applicationForExpenseDetail) {
      setEmployeeInformation(applicationForExpenseDetail.assigneeEmployee);
      setCustomProperties(applicationForExpenseDetail.customProperties);
      setLineManager(applicationForExpenseDetail.lineManager);
    }
  }

  const getCustomProperties = () => {
    const blockCount = Math.floor(customProperties.length / 2);
    let structure: any[] = [];
    for (let row = 0; row < blockCount; row++) {
      let children = [];
      for (let column = 0; column < 2; column++) {
        let index = (row + 1) * (column + 1) - 1;
        children.push(
        <div className="input-item">
            <label className="input-label">{customProperties[index].name[i18n.language as 'en' | 'ru']}</label>
            <input className="input" type="text" value={customProperties[index].value[i18n.language as 'en' | 'ru']} readOnly tabIndex={isTabIndexDisabled ? -1 : 0}/>
        </div>);
      }
      structure.push(<div className="input-item-row line-two">{children}</div>);
    }
  
    if(customPropertiesArr.customProperties) {
      structure.push(
        customPropertiesArr.customProperties.map((item: any) => {
          return (
              <div style={{ display: "flex", width: "48%", minWidth: 200, }}>
                <div className="input-item">
                  <label className="input-label">{item.name[i18n.language as 'en' | 'ru']}</label>
                  <input className="input" type="text" value={item.value[i18n.language as 'en' | 'ru']} readOnly tabIndex={isTabIndexDisabled ? -1 : 0}/>
                </div>
              </div>
          )
        })
      )
    }
    
    return structure;
  }

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  }

  return (
    <ModalContainer isOpen={props.isOpen} highestModal={isHighestModal} destroy={true} overlayClick={props.onClose}>
      <div className="box-modal" id="edit-note">
        <div className="box-modal-title">{employeeInformation.name ? employeeInformation.name[i18n.language as 'en' | 'ru'] : ''}</div>
        <div className="box-modal_close arcticmodal-close" onClick={props.onClose} />
        <div className="box-modal-content">
          <div className="box-modal-form">
            <div className="box-modal-form-block">
              {getCustomProperties()}
            </div>
          </div>
        </div>
        <div className="box-modal-footer"><a className="arcticmodal-close btn btn_black" onClick={props.onClose} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.employee_information.ok')}</a></div>
      </div>
    </ModalContainer>
  )
}

export default EmployeeInformationModal;
