import { isIE } from 'react-device-detect'
import axios, { AxiosRequestConfig } from 'axios';
import authService from 'services/AuthService';

let redirected = false;

const config: AxiosRequestConfig = {
    baseURL: window.location.origin + process.env.REACT_APP_API_URL_POSTFIX,
    validateStatus: (status) => {
        if (status === 401 && !redirected) {
            redirected = true;
            authService.login();
        }
        return true;
    }
};

const api = axios.create(config);

api.interceptors.response.use(
    (response) => {
        response.headers.success = response.status < 400;
        return response;
    },
    (error) => {
        return Promise.reject(error);
    });

if (isIE) {
    api.defaults.headers.get['Pragma'] = 'no-cache';
    api.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';
}

export default api;
