import { BaseMapper } from './base.mapper';
import { IAOItem } from 'infrastructure/interfaces';
import { EmployeeMapper } from './Employee.mapper';
import { get, isArray } from 'lodash';
import { BasicTypeMapper } from './BasicType.mapper';

export class AOItemMapper extends BaseMapper<IAOItem, any> {
  responseToEntity(response: any): IAOItem {
    const basicTypeMapper = new BasicTypeMapper();
    const advanceReportEmployeeMapper = new EmployeeMapper();
    return {
      id: get(response, 'id', 0),
      status: get(response, 'status', 'Approved'),
      number: get(response, 'number', '0'),
      expenseApplication: {
        id: get(response, 'expenseApplication.id', 0),
        logicalName: get(response, 'expenseApplication.logicalName', ''),
      },
      expenseApplicationNumber: get(response, 'expenseApplicationNumber', -1),
      applicationType: {
        id: get(response, 'applicationType.id', 0),
        name: {
          ru: get(response, 'applicationType.name.ru', 'Общехозяйственный расход[STUB]'),
          en: get(response, 'applicationType.name.en', 'General expense[STUB]')
        },
        code: get(response, 'applicationType.code', 'General')
      },
      assigneeEmployee: advanceReportEmployeeMapper.responseToEntity(get(response, 'assigneeEmployee', {})),
      totalAmount: get(response, 'totalAmount', 0),
      approvedTotalAmount: get(response, 'approvedTotalAmount', 0),
      approvedReportAmount: get(response, 'approvedReportAmount', 0),
      createdAt: get(response, 'createdAt', new Date()),
      departureFromCity: basicTypeMapper.responseToEntity(get(response, 'departureFromCity', {})),
      arrivalToCity: basicTypeMapper.responseToEntity(get(response, 'arrivalToCity', {})),
      startOn: get(response, 'startOn', new Date()),
      endOn: get(response, 'endOn', new Date()),
      expenses: isArray(get(response, 'expenses', [])) ? get(response, 'expenses', []) : [],
      businessTargetName: {
        ru: get(response, 'businessTarget.name.ru', 'Имя[STUB]'),
        en: get(response, 'businessTarget.name.en', 'Name[STUB]')
      },
      type: 'AO',
      approvalProgress: {
        level: get(response, 'approvalProgress.level', ''),
        assignedNames: (response.approvalProgress && response.approvalProgress.assignedNames.length) ? response.approvalProgress.assignedNames.map((item: any) => ({
          ru: get(item, 'ru', ''),
          en: get(item, 'en', '')
        })) : {
          ru: get(response, 'approvalProgress.assignedNames.ru', ''),
          en: get(response, 'approvalProgress.assignedNames.en', '')
        },
        description: {
          ru: get(response, 'approvalProgress.description.ru'),
          en: get(response, 'approvalProgress.description.en'),
        }
      },
      readAt: get(response, 'readAt', null),
      paperWork: {
        id: get(response, 'object.paperWork.id', 0),
        employeeId: get(response, 'object.paperWork.employeeId', 0),
        employeeName:{
          ru: get(response, 'object.paperWork.employeeName.ru', ''),
          en: get(response, 'object.paperWork.employeeName.en', ''),
      },
        startedOn: get(response, 'object.paperWork.startedOn', 0),
      },
      isGroup: get(response, 'isGroup', false),
      companyId: get(response, 'companyId','')
    };
  };

  responsesToEntitys(responses: any[]): IAOItem[] {
    return [...responses.map(item => this.responseToEntity(item))];
  }
}
