import { Reducer } from 'redux';
import {
    ApplicationForExpenseActions,
    APPLICATION_FOR_EXPENSE_LIST,
    APPLICATION_FOR_EXPENSE_DETAIL,
    ADDITIONAL_EXPENSE,
} from './actionsTypes';
import { ApplicationForExpenseList, ApplicationForExpenseDetail } from "infrastructure/interfaces";

export interface ApplicationForExpenseState {
    applicationForExpenseList: ApplicationForExpenseList | null,
    applicationForExpenseDetail: ApplicationForExpenseDetail | null,
    additionalExpense: number,
    isApplicationForExpenseDetailDownloaded: boolean
}

export const initialState: ApplicationForExpenseState = {
    applicationForExpenseList: null,
    applicationForExpenseDetail: null,
    additionalExpense: 0,
    isApplicationForExpenseDetailDownloaded: false
};

export const reducer: Reducer<ApplicationForExpenseState> = (state: ApplicationForExpenseState = initialState, action) => {
    switch ((action as ApplicationForExpenseActions).type) {
        case APPLICATION_FOR_EXPENSE_LIST.SET_APPLICATION_FOR_EXPENSE_LIST:
            if(action.payload.reloading){ //Флаг дозагрузки данных при скролинге
                const loadedExpenseApplicationItems = action.payload.applicationForExpenseList.data;
                const oldExpenseApplicationItems = state.applicationForExpenseList ? state.applicationForExpenseList.data : [];

                action.payload.applicationForExpenseList.data = [...oldExpenseApplicationItems,...loadedExpenseApplicationItems];
                return {...state, applicationForExpenseList: action.payload.applicationForExpenseList};
            }
            return {...state, applicationForExpenseList: action.payload.applicationForExpenseList};
        case APPLICATION_FOR_EXPENSE_DETAIL.SET_APPLICATION_FOR_EXPENSE_DETAIL:
            return {...state, applicationForExpenseDetail: action.payload.applicationForExpenseDetail};
        case ADDITIONAL_EXPENSE.SET_ADDITIONAL_EXPENSE:
            return {...state, additionalExpense: action.payload.additionalExpense};
        case APPLICATION_FOR_EXPENSE_DETAIL.IS_APPLICATION_FOR_EXPENSE_DETAIL_DOWNLOADED:
            return {...state, isApplicationForExpenseDetailDownloaded: action.payload.isApplicationForExpenseDetailDownloaded};
        default:
            return state;
    }
};

export default reducer;