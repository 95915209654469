import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from 'app/component/modal/ModalContainer';
import { ApplicationState } from 'store/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { loaderLock, loaderUnlock } from 'store/common/actions';
import { showErrors } from 'store/exception/actions';
import { RouteComponentProps, withRouter } from 'react-router';
import SelectWithSection from '../select/SelectWithSection';
import SvgIcon from '../svg-icon/SvgIcon';
import DatePickerCalendar from '../date-picker/DatePickerCalendar';
import Radio from '../radio/Radio';
import Checkbox from '../checkbox/Checkbox';
import { IAO } from 'infrastructure/interfaces';
import { capitalizeFirstLetter, formatter, getCurrencieCode, getNextKey, toLocaleNumberFormatter } from 'app/utils';
import { get } from 'lodash';
import moment from 'moment';
import OccupationAutocomplete from '../autocomplete/OccupationAutocomplete';
import {getAdvanceReportApplication, getCommonExpenseTypes} from "../../../store/selectors";
import {
  deleteMeetingParticipantsById,
  getAdvanceReportApplicationsReportDocumentsById,
  getCustomPropertyValuesCodesByObjectLogicalNameId,
  getFieldPermissionsCreateAdvanceReportAdditionalDocument,
  getFieldPermissionsUpdateAdvanceReportAdditionalDocument,
  getLogicalName,
  postAdvanceReportAdditionalDocuments,
  postMeetingParticipants,
  updateAdvanceReportAdditionalDocuments, updateAdvanceReportAdditionalDocumentsAttach,
  updateAdvanceReportAdditionalDocumentsById, updateAdvanceReportAdditionalDocumentsDetach
} from "../../../services/ApiService";

interface ADActModalProps extends RouteComponentProps<any> {
  isOpen: boolean,
  onClose: (isSimpleClose?: boolean) => void,
  onComplete: () => void
  editId?: number,
  documentId: number,
  createActDetail?: { expenseId: number, expenseSum: number, expenseCurrencyCode: string, expenseDescription: string }
}

const ADActModal: React.FC<ADActModalProps> = (props) => {
  let { t, i18n } = useTranslation();

  const advanceReportDetail: IAO = useSelector(getAdvanceReportApplication) || {} as IAO;

  const dispatch = useDispatch();

  let [openCalendar, setOpenCalendar] = useState(false);
  let [openParticipant, setOpenParticipant] = useState('close');
  let [expenses, setExpenses] = useState([] as any[]);
  let [actValues, setActValues] = useState({
    actNumber: '',
    actExpenses: [] as { id: number, key: number }[],
    date: undefined as Date | undefined,
    place: '',
    description: '',
    participants: [] as {
      key: number,
      id: number,
      isEmployee: boolean,
      isResponsible: boolean,
      occupationId: number,
      name: string,
      role: string,
      companyId: number,
      companyName: string
    }[]
  });
  let [startActValues, setStartActValues] = useState({
    actExpenses: [] as { id: number, key: number }[],
    participants: [] as {
      key: number,
      id: number,
      isEmployee: boolean,
      isResponsible: boolean,
      occupationId: number,
      name: string,
      role: string,
      companyId: number,
      companyName: string
    }[]
  });
  let [fieldsWithError, setFieldsWithError] = useState([] as string[]);
  let [actAccess, setActAccess] = useState({
    expense: false,
    place: false,
    date: false,
    description: false,
    participant: false
  });
  let [defaultCompany, setDefaultCompany] = useState('');
  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);



  useEffect(() => {
    if (props.isOpen) {
      init()
    }
  }, [props.isOpen]);

  const init = async () => {
    dispatch(loaderLock());

    await initAccess();
    let newDefaultCompany = advanceReportDetail.company.name[i18n.language as 'ru'];
    setDefaultCompany(newDefaultCompany);

    await setActValues({
      actNumber: '',
      actExpenses: [],
      date: undefined,
      place: '',
      description: '',
      participants: []
    });

    let newActValue = {
      actNumber: '',
      actExpenses: [],
      date: undefined,
      place: '',
      description: '',
      participants: []
    } as typeof actValues;

    let newExpenses = advanceReportDetail.expenses
      .filter(item => item.expenseType.needIncludeToAdvanceReportAdditionalDocuments)
      .map(item => ({
        text: `${item.expenseType.name[i18n.language as 'ru']} - ${item.description}`,
        value: item.id,
        section: `${toLocaleNumberFormatter(item.amountRub)} ${getCurrencieCode('RUB')}`
      }));

    let createActDetailExpenseId = props.createActDetail ? props.createActDetail.expenseId : 0;
    if (!props.editId && props.createActDetail && !newExpenses.filter(item => item.value == createActDetailExpenseId).length) {
      newExpenses.push({
        text: props.createActDetail.expenseDescription,
        value: props.createActDetail.expenseId,
        section: `${toLocaleNumberFormatter(props.createActDetail.expenseSum)} ${props.createActDetail.expenseCurrencyCode}`
      });
    }

    setExpenses(newExpenses);

    if (props.editId) {
      let currentAct = await getAdvanceReportApplicationsReportDocumentsById(props.editId);
      if (currentAct.headers.success) {
        let date = currentAct.data
        newActValue = {
          actNumber: get(date, 'actNumber', ''),
          actExpenses: get(date, 'expenses', []).map((item: any) => ({ id: item.id, key: getNextKey('actExpensesKey') })),
          date: moment(get(date, 'meetingDate', null)).toDate(),
          place: get(date, 'meetingPlace', ''),
          description: get(date, 'description', ''),
          participants: get(date, 'meetingParticipants', []).map((item: any) => {
            return {
              key: getNextKey('actParticipant'),
              id: get(item, 'id', null),
              isEmployee: !!get(item, 'occupationId', null),
              isResponsible: get(item, 'isResponsibleForMeeting', false),
              occupationId: get(item, 'occupationId', null),
              name: get(item, 'fio', ''),
              role: get(item, 'occupationName', ''),
              companyId: 5,
              companyName: get(item, 'companyName', '')
            }
          })
        }
      }
    }

    if (!props.editId) {
      let objectLogicalNameId = 0;
      let position = '';

      let resultON = await getLogicalName({ params: { NameEn: 'Occupation' } });
      
      if (resultON.headers.success) {
        objectLogicalNameId = resultON.data[0].id;
      }

      let positionRequest = await getCustomPropertyValuesCodesByObjectLogicalNameId(objectLogicalNameId, advanceReportDetail.assigneeEmployee.occupationId);
      

      if (positionRequest.headers.success && positionRequest.data[0]) {
        position = positionRequest.data[0]['name' + capitalizeFirstLetter(i18n.language)];
      }

      newActValue.participants = [{
        key: getNextKey('actParticipant'),
        id: 0,
        isEmployee: true,
        isResponsible: true,
        occupationId: advanceReportDetail.assigneeEmployee.occupationId,
        name: advanceReportDetail.assigneeEmployee.name[i18n.language as 'ru'],
        role: position,
        companyId: 5,
        companyName: advanceReportDetail.company.name[i18n.language as 'ru']
      }];
    }

    if (props.createActDetail) {
      newActValue.actExpenses = [{ id: props.createActDetail.expenseId, key: getNextKey('actExpensesKey') }];
    }

    await setActValues(newActValue);
    await setStartActValues({
      actExpenses: newActValue.actExpenses,
      participants: newActValue.participants
    });

    dispatch(loaderUnlock());
  }

  const initAccess = async () => {
    let newActAccess = {
      expense: false,
      place: false,
      date: false,
      description: false,
      participant: false
    };

    let result;

    if (props.editId) {
      result = await getFieldPermissionsUpdateAdvanceReportAdditionalDocument(props.editId);
    } else {
      result = await getFieldPermissionsCreateAdvanceReportAdditionalDocument();
    }

    if (result.headers.success) {
      let rules = result.data && result.data.includes ? result.data : [];
      newActAccess = {
        expense: rules.includes('Expense'),
        place: rules.includes('MeetingPlace'),
        date: rules.includes('MeetingDate'),
        description: rules.includes('Description'),
        participant: rules.includes('MeetingParticipant')
      };
    }

    setActAccess(newActAccess);
  };

  const changeValue = (type: string, value: any) => {
    let newActValues = { ...actValues };
    let newFieldsWithError = [...fieldsWithError];

    switch (type) {
      case 'place':
        newActValues.place = value.target.value;
        newFieldsWithError = newFieldsWithError.filter(item => item != 'place');
        break;
      case 'description':
        newActValues.description = value.target.value;
        newFieldsWithError = newFieldsWithError.filter(item => item != 'description');
        break;
      case 'date':
        newActValues.date = value.startDate;
        newFieldsWithError = newFieldsWithError.filter(item => item != 'date');
        setOpenCalendar(false);
        break;
      case 'expense':
        newActValues.actExpenses = actValues.actExpenses.map(item => {
          if (value.oldExpense.key == item.key) {
            newFieldsWithError = newFieldsWithError.filter(element => element != `expense-${item.key}`);
            return { id: value.option.value, key: item.key };
          } else {
            return item;
          }
        })
        break;
      case 'addExpense':
        newActValues.actExpenses.push({ id: 0, key: getNextKey('actExpensesKey') });
        break;
      case 'deleteExpense':
        newActValues.actExpenses = actValues.actExpenses.filter(item => item.key != value.key);
        newFieldsWithError = newFieldsWithError.filter(element => element != `expense-${value.key}`);
        break;
      case 'addParticipant':
        newActValues.participants.push(value);
        setOpenParticipant('close');
        break;
      case 'deleteParticipant':
        newActValues.participants = actValues.participants.filter(item => item.key != value);
        break;
    }

    setFieldsWithError(newFieldsWithError);
    setActValues(newActValues)
  }

  const getValueByExpenseId = (expenseId: number) => {
    let findeExpense = expenses.find((item: any) => item.value == expenseId);

    if (!findeExpense && props.createActDetail && props.createActDetail.expenseId == expenseId) {
      findeExpense = { text: props.createActDetail.expenseDescription, value: props.createActDetail.expenseId, section: `${props.createActDetail.expenseSum} ${props.createActDetail.expenseCurrencyCode}` }
    }

    if (findeExpense) {
      return findeExpense;
    } else {
      return null;
    }
  };

  const save = async () => {
    dispatch(loaderLock());

    let saveDate = {
      meetingDate: actValues.date,
      description: actValues.description,
      documentType: 'ActOfEntertaimentExpenses',
      entityObjectId: props.documentId,
      entityLogicalName: 'AdvanceReportApplication',
      meetingPlace: actValues.place
    };

    let createdAct = await postAdvanceReportAdditionalDocuments(saveDate);

    if (createdAct.headers.success) {
      let createdActId = createdAct.data.id;
      for (let index = 0; index < actValues.actExpenses.length; index++) {
        const actExpense = actValues.actExpenses[index].id;
        let attachedExpense = await  updateAdvanceReportAdditionalDocuments(createdActId,actExpense);
      }

      for (let index = 0; index < actValues.participants.length; index++) {
        const actParticipant = actValues.participants[index];
        let participantData = {
          occupationId: actParticipant.occupationId ? actParticipant.occupationId : undefined,
          advanceReportAdditionalDocumentId: createdActId,
          fio: actParticipant.name,
          companyName: actParticipant.companyName,
          occupationName: actParticipant.role,
          isResponsibleForMeeting: actParticipant.isResponsible
        }
        let attachedParticipant = await postMeetingParticipants(participantData);
      }

    }

    props.onComplete();
    dispatch(loaderUnlock());
  };

  const edit = async () => {
    dispatch(loaderLock());

    let editDate = {
      id: props.editId,
      documentType: 'ActOfEntertaimentExpenses',
      actNumber: actValues.actNumber,
      meetingDate: actValues.date,
      description: actValues.description,
      meetingPlace: actValues.place,
      entityObjectId: props.documentId,
      entityLogicalName: 'AdvanceReportApplication',
    };

    let editedAct = await updateAdvanceReportAdditionalDocumentsById(props.editId, editDate);

    if (editedAct.headers.success) {
      let createdActId = props.editId;
      let attachExpense = actValues.actExpenses.filter(item => !startActValues.actExpenses.map(item => item.id).includes(item.id));
      let detachExpense = startActValues.actExpenses.filter(item => !actValues.actExpenses.map(item => item.id).includes(item.id));
      let attachParticipant = actValues.participants.filter(item => !item.id);
      let detachParticipant = startActValues.participants.filter(participant => !actValues.participants.map(item => item.id).includes(participant.id))

      for (let index = 0; index < attachExpense.length; index++) {
        const actExpense = attachExpense[index].id;
        let attachedExpense = await updateAdvanceReportAdditionalDocumentsAttach(createdActId, actExpense);
      }

      for (let index = 0; index < detachExpense.length; index++) {
        const actExpense = detachExpense[index].id;
        let dettachedExpense = await updateAdvanceReportAdditionalDocumentsDetach(createdActId, actExpense);
      }

      for (let index = 0; index < attachParticipant.length; index++) {
        const actParticipant = attachParticipant[index];
        let participantData = {
          occupationId: actParticipant.occupationId ? actParticipant.occupationId : undefined,
          advanceReportAdditionalDocumentId: createdActId,
          fio: actParticipant.name,
          companyName: actParticipant.companyName,
          occupationName: actParticipant.role,
          isResponsibleForMeeting: actParticipant.isResponsible
        }
        let attachedParticipant = await postMeetingParticipants(participantData);
      }

      for (let index = 0; index < detachParticipant.length; index++) {
        const actParticipant = detachParticipant[index];
        let detachedParticipant = await deleteMeetingParticipantsById(actParticipant.id);
      }
    }

    props.onComplete();
    dispatch(loaderUnlock());
  };

  const validate = () => {
    let newFieldsWithError = [] as string[];
    let validExpense = true;
    let validDate = true;
    let validPlace = true;
    let validDescription = true;
    let validParticipant = true;

    if (actValues.actExpenses.filter(item => !item.id).length) {
      validExpense = false;
      actValues.actExpenses.filter(item => !item.id).forEach(item => newFieldsWithError.push(`expense-${item.key}`));
    }

    if (!actValues.date) {
      validDate = false;
      newFieldsWithError.push('date');
    }

    if (!actValues.place.trim().length) {
      validPlace = false;
      newFieldsWithError.push('place');
    }

    if (!actValues.description.trim().length) {
      validDescription = false;
      newFieldsWithError.push('description');
    }

    if (!actValues.participants.length) {
      validParticipant = false;
      dispatch(showErrors({ code: 'del_substituation', message: 'Привяжите хотя бы одного участника' }));
    }

    setFieldsWithError(newFieldsWithError);
    return validExpense && validDate && validPlace && validDescription && validParticipant;
  };

  const validStateField = (fieldName: string) => {
    return fieldsWithError.includes(fieldName) ? 'error' : '';
  }

  const closeHandler = () => {
    props.onClose(true);
  }

  const saveHandler = () => {
    if (!validate()) {
      return
    }

    if (props.editId) {
      edit();
    } else {
      save();
    }
  }

  const isHighestModal = (isHighest: boolean) => {
     setTabIndexDisabled(!isHighest);
  }

  return (
    <Fragment>
      <ModalContainer onEnter={saveHandler} isOpen={props.isOpen} destroy={true} highestModal={isHighestModal} overlayClick={closeHandler}>
        <div className="box-modal" id="add-expenses1">
          <div className="box-modal_close" onClick={closeHandler}></div>
          <div className="box-modal-title">{t('modals.ad_act.header')}</div>
          <div className="box-modal-content">
            <div className="box-modal-form">
              <div className="box-modal-form-block">
                <div className="title">{t('modals.ad_act.expense_title')}</div>
                <>
                  {actValues.actExpenses.map(expense => {
                    return (<div className="input-item-row" key={getNextKey('actExpenses')}>
                      <div className={`input-item ${validStateField(`expense-${expense.key}`)}`} style={{ marginBottom: '5px' }}>
                        <label className="input-label">
                          {t('modals.ad_act.expense')}
                          <i className="input-required">*</i>
                        </label>
                        <SelectWithSection
                          defaultValue={getValueByExpenseId(expense.id)}
                          options={expenses.filter(item => !actValues.actExpenses.includes(item.id))}
                          onChoose={option => changeValue('expense', { option: option, oldExpense: expense })}
                          style={{ marginRight: '0px' }}
                          disabled={!actAccess.expense}
                        />
                      </div>
                      <div className="input-item input-remove" style={{ width: '30px', marginBottom: '5px' }}>
                        <label className="input-label" style={{ display: !actAccess.expense ? 'none' : undefined }} />
                        <a onClick={() => changeValue('deleteExpense', expense)} title={t('report_detail.delete')} style={{ display: !actAccess.expense ? 'none' : undefined }}>
                          <SvgIcon className="icon icon-remove" href="#svg_icon_remove" />
                        </a>
                      </div>
                    </div>)
                  })}
                  <div className="input-item-row" style={{ display: actValues.actExpenses.length < expenses.length && actAccess.expense ? '' : 'none' }}>
                    <div className="cost-centers" style={{ marginBottom: '20px' }}>
                      <div className="tx-center" style={{ display: '' }}>
                        <a className="btn-add-cost pointer" onClick={() => changeValue('addExpense', {})}>
                          <SvgIcon className="icon icon-plus" href="#svg_icon_plus" />
                          {t('modals.ad_act.expense_delete')}
                        </a>
                      </div>
                    </div>
                  </div>
                </>
                <div className="input-item-row">
                  <div className={`input-item ${validStateField('date')} ${actAccess.date ? '' : 'disabled'}`}>
                    <label className="input-label">{t('modals.ad_act.date')}<i className="input-required">*</i></label>
                    <div className={`input-date`}>
                      <input
                        value={actValues.date ? formatter('D MMMM YYYY', i18n.language, actValues.date) : ''}
                        onClick={() => setOpenCalendar(true)}
                        className="input datepicker pointer"
                        type="text"
                        id="datepicker"
                        placeholder={t('modals.ad_act.date_placeholder')}
                        readOnly={true}
                        tabIndex={isTabIndexDisabled ? -1 : 0}
                      />
                      <SvgIcon className={"icon icon-calendar"} href={"#svg_icon_calendar"} onClick={() => actAccess.date ? setOpenCalendar(true) : null} />
                    </div>
                    <DatePickerCalendar
                      startDate={actValues.date}
                      isShown={openCalendar}
                      onClose={() => setOpenCalendar(false)}
                      isCleansed={false}
                      onSelected={date => changeValue('date', date)}
                      isMultiChoice={false}
                    />
                  </div>
                  <div className={`input-item ${validStateField('place')} ${actAccess.place ? '' : 'disabled'}`}>
                    <label className="input-label">{t('modals.ad_act.place')}<i className="input-required">*</i></label>
                    <div className={`input-date`}>
                      <input
                        className="input"
                        type="text"
                        onChange={event => changeValue('place', event)}
                        value={actValues.place}
                        placeholder={t('modals.ad_act.place_placeholder')}
                        style={{ paddingRight: '33px' }}
                        readOnly={!actAccess.place}
                        tabIndex={isTabIndexDisabled ? -1 : 0}
                      />
                      <SvgIcon className={"icon icon-calendar"} href={"#svg_icon_plase"} style={{ width: '25px', height: '25px', marginRight: '5px' }} />
                    </div>
                  </div>
                </div>
                <div className="input-item-row" id="add-expenses">
                  <div className={`input-item ${validStateField('description')} ${actAccess.description ? '' : 'disabled'}`}>
                    <label className="input-label">{t('modals.ad_act.description')}<i className="input-required">*</i></label>
                    <textarea
                      maxLength={400}
                      onChange={event => changeValue('description', event)}
                      value={actValues.description}
                      placeholder={t('modals.ad_act.description_placeholder')}
                      readOnly={!actAccess.description}
                      tabIndex={isTabIndexDisabled ? -1 : 0}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="box-modal-form">
              <div className="box-modal-form-block">
                <div className="title">{t('modals.ad_act.participant_title')}</div>
                {actValues.participants.filter(item => item.isEmployee).length == 0 ?
                  (
                    <div className="input-item-row">
                      <div className="input-item">
                        <label className="input-label">{t('modals.ad_act.company_title', { company: defaultCompany })}</label>
                        <div className="request-item-content">
                          <div className="input-choose">
                            <div className="btn btn_gray add-trip daily" id="datepicker" onClick={() => setOpenParticipant('employee')} style={{display: actAccess.participant ? undefined : 'none'}}>+ {t('create_expense.daily_allowance.add')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                }
                {actValues.participants
                  .filter(item => item.isEmployee)
                  .map(item => item.companyName)
                  .filter((item, index, array) => array.indexOf(item) === index)
                  .map(companyName => {
                    return (
                      <div className="input-item-row">
                        <div className="input-item">
                          <label className="input-label">{t('modals.ad_act.company_title', { company: companyName })}</label>
                          <div className="request-item-content">
                            <div className="input-choose">
                              {actValues.participants
                                .filter(item => item.isEmployee)
                                .filter(item => item.companyName == companyName)
                                .map(item => {
                                  return (<div className="input-choose-item" key={item.key} style={{ marginBottom: '8px' }}>
                                    <SvgIcon className={"icon icon-calendar"} href={"#svg_icon_case"} style={{ display: item.isResponsible ? undefined : 'none', width: '16px', height: '16px', marginLeft: '0px', }} />
                              &nbsp;{`${item.name}, ${item.role}`}
                                    <div onClick={() => changeValue('deleteParticipant', item.key)} data-id={item.key} style={{ display: actAccess.participant ? undefined : 'none' }}>
                                      <SvgIcon className="icon icon-close" href="#svg_icon_close" />
                                    </div>
                                  </div>)
                                })}
                              <div className="btn btn_gray add-trip daily" id="datepicker" onClick={() => setOpenParticipant('employee')} style={{ display: actAccess.participant ? undefined : 'none' }}>+ {t('create_expense.daily_allowance.add')}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                <div className="input-item-row">
                  <div className="input-item">
                    <label className="input-label">{t('modals.ad_act.other_title')}</label>
                    <div className="request-item-content">
                      <div className="input-choose">
                        {actValues.participants
                          .filter(item => !item.isEmployee)
                          .map(item => {
                            return (<div className="input-choose-item" key={item.key} style={{ background: '#F9EBEA', marginBottom: '8px' }}>
                              &nbsp;{`${item.name}, ${item.role}, ${item.companyName}`}
                              <div onClick={() => changeValue('deleteParticipant', item.key)} data-id={item.key} style={{ display: actAccess.participant ? undefined : 'none' }}>
                                <SvgIcon className="icon icon-close" href="#svg_icon_close" />
                              </div>
                            </div>)
                          })}
                        <div className="btn btn_gray add-trip daily" id="datepicker" onClick={() => setOpenParticipant('other')} style={{ display: actAccess.participant ? undefined : 'none' }}>+ {t('create_expense.daily_allowance.add')}</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="box-modal-footer">
            <a className="btn btn_black pointer" onClick={closeHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.create_advance_report_modal.cancel')}</a>
            <a className="btn-expense btn_green pointer" onClick={saveHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.create_advance_report_modal.complete')}</a>
          </div>
        </div>
      </ModalContainer >
      <AddMeetingParticipantsModal
        isOpen={openParticipant}
        onClose={() => setOpenParticipant('close')}
        onComplete={participant => changeValue('addParticipant', participant)}
        includesParticipants={actValues.participants}
      />
    </Fragment >
  )
}

export default withRouter(ADActModal);





interface AddMeetingParticipantsModalProps {
  isOpen: string,
  onClose: () => void,
  onComplete: (participant: any) => void,
  includesParticipants: any
}

const AddMeetingParticipantsModal: React.FC<AddMeetingParticipantsModalProps> = (props) => {
  let { t, i18n } = useTranslation();

  const expenseTypes = useSelector(getCommonExpenseTypes);

  const dispatch = useDispatch();

  let [participant, setParticipant] = useState({
    key: 1,
    id: 0,
    isEmployee: false,
    isResponsible: false,
    occupationId: 0,
    name: '',
    role: '',
    companyId: 5,
    companyName: ''
  });
  let [fieldsWithError, setFieldsWithError] = useState([] as string[]);
  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  useEffect(() => {
    if (props.isOpen == 'employee' || props.isOpen == 'other') {

      setParticipant({
        key: getNextKey('actParticipant'),
        id: 0,
        isEmployee: props.isOpen == 'employee',
        isResponsible: false,
        occupationId: 0,
        name: '',
        role: '',
        companyId: 5,
        companyName: ''
      });
    }
  }, [props.isOpen]);

  const changeValue = (type: string, value: any) => {
    let newFieldsWithError = [...fieldsWithError];
    let newParticipant = { ...participant };

    switch (type) {
      case 'name':
        newParticipant.name = value.target.value;
        newFieldsWithError = newFieldsWithError.filter(item => item != 'name');
        break;
      case 'role':
        newParticipant.role = value.target.value;
        newFieldsWithError = newFieldsWithError.filter(item => item != 'role');
        break;
      case 'companyName':
        newParticipant.companyName = value.target.value;
        newFieldsWithError = newFieldsWithError.filter(item => item != 'company');
        break;
      case 'responsible':
        newParticipant.isResponsible = !participant.isResponsible;
        break;
      case 'employee':
        newParticipant.isEmployee = value;
        newParticipant.isResponsible = false;
        newFieldsWithError = [];
        break;
      case 'occupationAutocomplete':
        if (value.value) {
          newParticipant.occupationId = value.occupation.id;
          newParticipant.name = value.employee.name[i18n.language as 'ru'];
          newParticipant.role = value.occupation.position ? value.occupation.position[i18n.language as 'ru'] : '';
          newParticipant.companyName = value.occupation.company.name[i18n.language as 'ru'];
          newFieldsWithError = newFieldsWithError.filter(item => item != 'occupation');
        } else {
          newParticipant.occupationId = 0;
          newParticipant.name = '';
          newParticipant.role = '';
          newParticipant.companyName = '';
          newFieldsWithError = newFieldsWithError.filter(item => item != 'occupation');
        }
        break;
    }

    setFieldsWithError(newFieldsWithError);
    setParticipant(newParticipant);
  }

  const validate = () => {
    let newFieldsWithError = [];

    let validOccupation = true;

    let validName = true;
    let validRole = true;
    let validCompany = true;


    if (participant.isEmployee) {
      let hasOcupation = props.includesParticipants.map((item: any) => item.occupationId);
      validOccupation = !hasOcupation.includes(participant.occupationId);
      if (!validOccupation) {
        dispatch(showErrors({ code: 'del_substituation', message: 'Сотрудник уже привязан' }));
      }

      if (!participant.occupationId) {
        validOccupation = false;
        newFieldsWithError.push('occupation');
      }
    } else {
      if (!participant.name.trim().length) {
        validName = false;
        newFieldsWithError.push('name');
      }

      if (!participant.role.trim().length) {
        validRole = false;
        newFieldsWithError.push('role');
      }

      if (!participant.companyName.trim().length) {
        validCompany = false;
        newFieldsWithError.push('company');
      }
    }

    setFieldsWithError(newFieldsWithError);
    return validOccupation && validName && validRole && validCompany;
  }

  const validStateField = (fieldName: string) => {
    return fieldsWithError.includes(fieldName) ? 'error' : '';
  }

  const closeHandler = () => {
    props.onClose();
  }

  const saveHandler = () => {
    if (!validate()) {
      return
    }
    let resultParticipant = { ...participant, occupationId: participant.isEmployee ? participant.occupationId : 0 };
    props.onComplete(resultParticipant);
  }

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  }

  return (
    <Fragment>
      <ModalContainer isOpen={props.isOpen == 'employee' || props.isOpen == 'other'} destroy={true} highestModal={isHighestModal} overlayClick={closeHandler}>
        <div className="box-modal" id="add-expenses1">
          <div className="box-modal_close" onClick={closeHandler}></div>
          <div className="box-modal-title">{t('modals.ad_act.participants.title')}</div>
          <div className="box-modal-content">
            <div className="box-modal-form">
              <div className="box-modal-form-block">
                <div className="input-item-row">
                  <div className={`input-item ${validStateField('occupation')}`} style={{ display: participant.isEmployee ? undefined : 'none' }}>
                    <label className="input-label">{t('modals.ad_act.participants.fio_role')}</label>
                    <div className="input-search">
                      <OccupationAutocomplete onChoose={(option) => { changeValue('occupationAutocomplete', option) }} placeholder={t('journal.filters.enter_name')} withPosition={true} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                    </div>
                  </div>
                  <div className={`input-item ${validStateField('name')}`} style={{ display: participant.isEmployee ? 'none' : undefined }}>
                    <label className="input-label">{t('modals.ad_act.participants.fio')}</label>
                    <input className="input" type="text" onChange={event => changeValue('name', event)} value={participant.name} placeholder={t('modals.ad_act.participants.fio_placeholder')} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                  </div>
                  <div className={`input-item ${validStateField('role')}`} style={{ display: participant.isEmployee ? 'none' : undefined }}>
                    <label className="input-label" >{t('modals.ad_act.participants.role')}</label>
                    <input className="input" type="text" onChange={event => changeValue('role', event)} value={participant.role} placeholder={t('modals.ad_act.participants.role_placeholder')} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                  </div>
                  <div className="input-item" style={{ width: '171px', marginRight: '0px', display: participant.isEmployee ? undefined : 'none' }}>
                    <Checkbox name={'responsible'} tabIndex={isTabIndexDisabled ? -1 : 0} id={1} label={t('modals.ad_act.participants.responsible')} checked={participant.isResponsible} onClick={() => changeValue('responsible', {})} style={{ marginTop: '30px', marginBottom: '0px' }} labelStyle={{ fontSize: '14px', fontWeight: 'bold', lineHeight: '12px' }} />
                  </div>
                  <div className={`input-item ${validStateField('company')}`} style={{ display: participant.isEmployee ? 'none' : undefined }}>
                    <label className="input-label">{t('modals.ad_act.participants.company')}</label>
                    <input className="input" type="text" onChange={event => changeValue('companyName', event)} value={participant.companyName} placeholder={t('modals.ad_act.participants.company_placeholder')} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                  </div>
                </div>
                <div className="box-modal-checkbox-wrap m-b-15">
                  <Radio checked={participant.isEmployee}
                    label={t('modals.ad_act.participants.employee')}
                    onClick={() => changeValue('employee', true)}
                    id={1}
                    name={'radoii'}
                    key={1}
                    tabIndex={isTabIndexDisabled ? -1 : 0}
                  />
                  <Radio checked={!participant.isEmployee}
                    label={t('modals.ad_act.participants.other')}
                    onClick={() => changeValue('employee', false)}
                    id={2}
                    name={'radoii'}
                    key={2}
                    tabIndex={isTabIndexDisabled ? -1 : 0}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="box-modal-footer">
            <a className="btn btn_black pointer" onClick={closeHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.create_advance_report_modal.cancel')}</a>
            <a className="btn-expense btn_green pointer" onClick={saveHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.create_advance_report_modal.complete')}</a>
          </div>
        </div>
      </ModalContainer >
    </Fragment >
  )
}
