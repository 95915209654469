/**
 * @typedef {Object} STATUS_TYPE
 * @property {number} DRAFT - Черновик
 * @property {number} APPROVAL_REQUIRES - Требует согласования
 * @property {number} ON_APPROVAL - На согласовании
 * @property {number} APPROVED - Согласована
 * @property {number} NOT_AGREED - Не согласована
 * @property {number} REVOKED - Отозвана
 * @property {number} POSTED - Проведен аванс
 * @property {number} REPORT_PREPARED - Подготовлен отчет
 * @property {number} EXECUTED - Исполнена
 * @property {number} CANCELLED - Отменена
 */

export enum STATUS_TYPE {
    DRAFT             = 'Draft',
    APPROVAL_REQUIRES = 'Approval Requires',
    ON_APPROVAL       = 'On Approval',
    APPROVED          = 'Approved',
    NOT_APPROVED      = 'Not Approved',
    NOTAPPROVED       = 'NotApproved',
    REVOKED           = 'Revoked',
    POSTED            = 'Posted',
    REPORT_PREPARED   = 'Report prepared',
    EXECUTED          = 'Executed',
    CANCELLED         = 'Cancelled',
    ON_REVISION       = 'OnRevision'
}

export const statusStylesMap = {
    [STATUS_TYPE.DRAFT]               : 'gray',
    [STATUS_TYPE.APPROVAL_REQUIRES]   : 'yellow',
    [STATUS_TYPE.ON_APPROVAL]         : 'orange',
    [STATUS_TYPE.APPROVED]            : 'green',
    [STATUS_TYPE.NOT_APPROVED]        : 'red',
    [STATUS_TYPE.NOTAPPROVED]        : 'red',
    [STATUS_TYPE.REVOKED]             : 'gray',
    [STATUS_TYPE.POSTED]              : 'blue',
    [STATUS_TYPE.REPORT_PREPARED]     : 'blue',
    [STATUS_TYPE.EXECUTED]            : 'black',
    [STATUS_TYPE.CANCELLED]           : 'black',
    [STATUS_TYPE.ON_REVISION]         : 'yellow',
};

export const statusNameMap = {
    [STATUS_TYPE.DRAFT]               : 'draft',
    [STATUS_TYPE.APPROVAL_REQUIRES]   : 'approval-requires',
    [STATUS_TYPE.ON_APPROVAL]         : 'on-the-coordination',
    [STATUS_TYPE.APPROVED]            : 'approved',
    [STATUS_TYPE.NOT_APPROVED]        : 'not-agreed',
    [STATUS_TYPE.NOTAPPROVED]         : 'not_approved',
    [STATUS_TYPE.REVOKED]             : 'revoked',
    [STATUS_TYPE.POSTED]              : 'posted',
    [STATUS_TYPE.REPORT_PREPARED]     : 'report-prepared',
    [STATUS_TYPE.EXECUTED]            : 'executed',
    [STATUS_TYPE.CANCELLED]           : 'cancelled',
    [STATUS_TYPE.ON_REVISION]         : 'on-revision',
};
