import { ActionCreator } from 'redux';
import { ApplicationState } from 'store/reducers';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
    ApplicationForExpenseActions,
    APPLICATION_FOR_EXPENSE_LIST,
    SetApplicationForExpenseList,
    APPLICATION_FOR_EXPENSE_DETAIL,
    SetApplicationForExpenseDetail,
    SetAdditionalExpense,
    ADDITIONAL_EXPENSE, SetApplicationForExpenseDetailDownloaded,
} from './actionsTypes';
import { loaderLock, loaderUnlock, historyNeedUpdatingThunk } from 'store/common/actions';
import { throwException } from '../exception/actions';
import { ApplicationForExpenseListMapper, ApplicationForExpenseDetailMapper} from 'infrastructure/mappers';
import { ApplicationForExpenseList, FilterParams, ApplicationForExpenseDetail } from "infrastructure/interfaces";
import {getExpensesApplicationsByParams, getExpensesById, updateExpensesById} from "../../services/ApiService";

export const setApplicationForExpenseList: ActionCreator<SetApplicationForExpenseList> = (applicationForExpenseList: ApplicationForExpenseList, reloading: boolean) => ({
    type: APPLICATION_FOR_EXPENSE_LIST.SET_APPLICATION_FOR_EXPENSE_LIST,
    payload: { applicationForExpenseList, reloading }
});

const getParams = (status: any[], cities: any[]) => {
    let result = '';
    let statuses: string[] = [];
    let city: string[] = [];
    if(status.length > 0) {
      status.forEach(state => { statuses.push(`Status=${state}`) });
    }
    if(cities.length > 0) {
      cities.forEach(item => { city.push(`CityId=${item}`) });
    }
    let resultArray = statuses.concat(city);
    if(resultArray.length > 0) {
      result += '?'
      result += resultArray.join('&');
    }
    return result;
  }

export const updateApplicationForExpenseList = (filterParams?: FilterParams, pageNumber: number = 1, reloading: boolean = false, itemsPerPage: number = 20): ThunkAction<Promise<void>, ApplicationState, any, ApplicationForExpenseActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, ApplicationForExpenseActions>, getState): Promise<void> => {
        !reloading && await dispatch(loaderLock());

        let applicationForExpenseListMapper = new ApplicationForExpenseListMapper();
        let params: any = {
            PageSize: itemsPerPage,
            Page: pageNumber
        };
        if (filterParams) {
            // params.Status = filterParams.Status || null;
            params.Journey = filterParams.Journey || null;
            // params.CityId = filterParams.CityId || null;
            params.StartOn = filterParams.StartOn || null;
            params.Id = filterParams.Id || null;
            params.Number=filterParams.Number || null;
            params.EndOn = filterParams.EndOn || null;
            params.EmployeeId = filterParams.EmployeeId || null;
            params.CompanyId = filterParams.CompanyId || null;
            params.LegalEntityId = filterParams.LegalEntityId || null;
            params.OrgUnitId = filterParams.OrgUnitId || null;
            params.LocationId = filterParams.LocationId || null;
            params.Personal = filterParams.Personal || null;
            params.CreatedAdvanceReportApplication = filterParams.CreatedAdvanceReportApplication || filterParams.CreatedAdvanceReportApplication === false ? false : null;
        }
        try {
            let response = await getExpensesApplicationsByParams(filterParams ? getParams(filterParams.Status || [], filterParams.CityId || []) : '', {
                params: params
            });
            dispatch(setApplicationForExpenseList(applicationForExpenseListMapper.responseToEntity(response.data), reloading));
        } catch {
            dispatch(setApplicationForExpenseList({data: []}));
            await dispatch(throwException({ code: 'application_for_expense_list', message: `Упал запрос на список заявок` }));
        }
        !reloading && await dispatch(loaderUnlock());
    }
};

export const setApplicationForExpenseDetail: ActionCreator<SetApplicationForExpenseDetail> = (applicationForExpenseDetail: ApplicationForExpenseDetail) => ({
    type: APPLICATION_FOR_EXPENSE_DETAIL.SET_APPLICATION_FOR_EXPENSE_DETAIL,
    payload: { applicationForExpenseDetail }
});

export const setApplicationForExpenseDetailDownloaded: ActionCreator<SetApplicationForExpenseDetailDownloaded> = (isApplicationForExpenseDetailDownloaded: boolean) => ({
    type: APPLICATION_FOR_EXPENSE_DETAIL.IS_APPLICATION_FOR_EXPENSE_DETAIL_DOWNLOADED,
    payload: { isApplicationForExpenseDetailDownloaded }
});

export const setAdditionalExpense: ActionCreator<SetAdditionalExpense> = (additionalExpense: number) => ({
    type: ADDITIONAL_EXPENSE.SET_ADDITIONAL_EXPENSE,
    payload: { additionalExpense }
});

export const updateApplicationForExpenseDetail = (id: number): ThunkAction<Promise<void>, ApplicationState, any, ApplicationForExpenseActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, ApplicationForExpenseActions>, getState): Promise<void> => {
        await dispatch(loaderLock());

        let applicationForExpenseDetailMapper = new ApplicationForExpenseDetailMapper();
        
        try {
            let response = await getExpensesById(id);
            dispatch(setApplicationForExpenseDetail(applicationForExpenseDetailMapper.responseToEntity(response.data)));
            await dispatch(historyNeedUpdatingThunk());
        } catch {
            /*dispatch(throwException({ code: 'auth_error', message: `Auth failure with status ${get(e, 'response.status')}` }));*/
        }
        dispatch(setApplicationForExpenseDetailDownloaded(true));
        await dispatch(loaderUnlock());
    }
};

export const saveApplicationForExpenseDetail = (expenseApplication: any, id: number): ThunkAction<Promise<void>, ApplicationState, any, ApplicationForExpenseActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, ApplicationForExpenseActions>, getState): Promise<void> => {
        await dispatch(loaderLock());
        try {
            let response = await updateExpensesById(id, expenseApplication);
        } catch (e) {
            await dispatch(throwException({ code: 'save_edit_expense_application', message: `Save_Expense_Application failure with status ${e.response.status}` }));
        }
        await dispatch(loaderUnlock());
    }
};

export const clearApplicationForExpenseDetail = (): ThunkAction<Promise<void>, ApplicationState, any, ApplicationForExpenseActions> => {
    return (dispatch: ThunkDispatch<ApplicationState, any, ApplicationForExpenseActions>, getState): any => {
        dispatch(setApplicationForExpenseDetail(null));
    }
};
