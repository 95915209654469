import { BaseMapper } from "./base.mapper";
import { ApprovalTier } from "infrastructure/interfaces";
import { ApprovalEmployeeMapper } from "infrastructure/mappers";
import { get, sortBy } from "lodash";

export class ApprovalTierMapper extends BaseMapper<ApprovalTier, any> {
  responseToEntity(response: any): ApprovalTier {
    const approvalEmployeeMapper = new ApprovalEmployeeMapper();
    return {
      id: get(response, 'id', 0),
      composition: get(response, 'composition', 0),
      ordinal: get(response, 'ordinal', 0),
      description: {
        ru: get(response, 'description.ru', 0),
        en: get(response, 'description.en', 0)
      },
      employees: approvalEmployeeMapper.responsesToEntitys(get(response, 'approvalItems', []))
    }
  };

  responsesToEntitys(responses: any[]): ApprovalTier[] {
    let entitys: ApprovalTier[] = [];
    for (let index = 0; index < responses.length; index++) {
        entitys.push(this.responseToEntity(responses[index]));
    }
    entitys = sortBy(entitys, ['ordinal']);
    return entitys;
};
}