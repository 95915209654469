import React, {useRef} from "react";
import SvgIcon from "../svg-icon/SvgIcon";

interface TimePickerProps {
    onChangeHours?: (hours: string) => void;
    onChangeMinutes?: (minutes: string) => void;
}

export const TimePicker = (props: TimePickerProps) => {

    let minutesInput = useRef({} as HTMLInputElement);

    const onChangeHours = (event: React.FormEvent<HTMLInputElement>) => {
        if (isNaN(event.target.value) || !event.target.value) {
            event.target.value = "";
        }

        if (event.target.value.length === 3) {
            event.target.value = event.target.value[2];
        }

        if (!event.target.value || +event.target.value < 0) {
            event.target.value = "00";
        }

        if (+event.target.value > 23) {
            event.target.value = "23";
            minutesInput.current.focus();
        }

        if ((event.target.value.length === 1 && +event.target.value > 2) || (event.target.value.length === 2)) {
            minutesInput.current.focus();
        }

        props.onChangeHours && props.onChangeHours(event.target.value)
    }

    const onChangeMinutes = (event: React.FormEvent<HTMLInputElement>) => {
        if (isNaN(event.target.value) || !event.target.value) {
            event.target.value = "";
        }

        if (event.target.value.length === 3) {
            event.target.value = event.target.value[2];
        }

        if (!event.target.value || +event.target.value < 0) {
            event.target.value = "00";
        }

        if (+event.target.value > 59) {
            event.target.value = "59";
        }

        props.onChangeMinutes && props.onChangeMinutes(event.target.value)
    }

    return (
        <div className="TimePicker-wrapper" style={{
            display: "flex",
            justifyContent: "center",
            left: 0,
            right: 0,
            marginLeft: 0,
            marginRight: 0
        }}>
            <div className="calendar-time-svg">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
                        stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 7V11.5L15 13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div className="calendar-time">
                <input className="calendar-time-hours" type="number" placeholder="чч" min={0} max={23} maxLength={2}
                       onInput={onChangeHours}/>
                <span> : </span>
                <input className="calendar-time-minutes" ref={minutesInput} type="number" placeholder="мм" min={0} max={59} maxLength={2}
                       onInput={onChangeMinutes}/>
            </div>
        </div>
    )
}