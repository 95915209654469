import React from 'react';
import JournalDetailFilter from 'app/component/journal-page/JournalDetailFilter';
import { RouteComponentProps } from 'react-router';

interface JournalDetailExpenseFilterProps extends RouteComponentProps<any> {
}

const JournalDetailExpenseFilter: React.FC<JournalDetailExpenseFilterProps> = (props) => {
    return (
        <JournalDetailFilter id={props.match.params.id} />
    )
}

export default JournalDetailExpenseFilter;