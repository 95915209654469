import { BaseMapper } from "./base.mapper";
import { IAOList } from 'infrastructure/interfaces';
import { AOItemMapper } from "./AOItem.mapper";
import { get } from 'lodash';

export class AOListMapper extends BaseMapper<IAOList, any> {
  responseToEntity(response: any): IAOList {
    const advanceReportItemMapper = new AOItemMapper;
    return {
        data: advanceReportItemMapper.responsesToEntitys(get(response, 'data', [])),
        itemsCount: get(response, 'itemsCount', 0),
        itemsPerPage: get(response, 'itemsPerPage', 0),
        pageNumber: get(response, 'pageNumber', 0)
      }
    }
  }