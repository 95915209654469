import React from 'react';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router';
//component
import Home from 'app/pages/home/Home';
import ZNRList from 'app/pages/ZNR/ZNRList';
import ZNRDetail from 'app/pages/ZNR/ZNR-Detail/ZNRDetail';
import AODetail from 'app/pages/report/AODetail';
import AOList from 'app/pages/report/AOList';
import OpenZNR from 'app/pages/open/OpenZNR';
import User from 'app/component/user/User';
import Journal from 'app/pages/journal/Journal';
import JournalDetailReport from 'app/pages/journal/JournalDetailReport';
import JournalDetailExpense from 'app/pages/journal/JournalDetailExpense';
import JournalDetailDownloads from 'app/pages/journal/JournalDetailDownloads';
import AgreementList from 'app/pages/agreement/AgreementList';
import { ReportsAll, ReportsRegistries } from 'app/pages/reports';

interface BodyProps extends RouteComponentProps<any> {
}

const Body: React.FC<BodyProps> = (props) => {
    return (
        <main className={`main ${props.location.pathname.startsWith('/User') ? 'main-profile' : ''}`}>
            <Switch>
                <Route path='/ExpenseApplication/open/:tripNumber/:journeyNumber' component={OpenZNR} />
                <Route path='/ExpenseApplication/Detail/:id' component={ZNRDetail} />
                <Route path='/AdvanceReportApplication/Detail/:id' component={AODetail}/>
                <Route path='/ExpenseApplication/:status' component={ZNRList}/>
                <Route path='/AdvanceReportApplication/:status' component={AOList}/>                
                <Route path='/AdvanceReportApplication' component={AOList}/>
                <Route path='/ExpenseApplication' component={ZNRList} />
                <Route path='/Agreement/:status' component={AgreementList} />
                <Route path='/Agreement' component={AgreementList} />
                <Route path='/Journal' component={Journal} />
                <Route path='/User' component={User} />
                
                <Route path='/Reports/Registry' component={ReportsRegistries} />
                <Route path='/Reports' component={ReportsAll} />
                <Route path='/' exact component={Home} />
            </Switch>
        </main >
    );
};

export default withRouter(Body);