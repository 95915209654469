import { BaseMapper } from "./base.mapper";
import { IAO } from "infrastructure/interfaces";
import { BasicTypeMapper } from "./BasicType.mapper";
import { AOExpenseMapper } from "./AOExpense.mapper";
import { CustomPropertiesMapper } from "./CustomProperties.mapper";
import { CostCenterReportMapper } from "./CostCenterReport.mapper";
import { TravelDatesMapper } from "./TravelDates.mapper";
import { EmployeeMapper } from "./Employee.mapper";
import { AssigneeEmployeeMapper } from "./AssigneeEmployee.mapper";
import { AOTypeMapper } from "./AOType.mapper";
import { get, sortBy } from "lodash";
import { getCurrencieCode, toLocaleNumberFormatter } from "app/utils";

export class AOMapper extends BaseMapper<IAO, any> {
  responseToEntity(response: any): IAO {
    const basicTypeMapper = new BasicTypeMapper();
    const advanceReportExpensesMapper = new AOExpenseMapper();
    const customPropertiesMapper = new CustomPropertiesMapper();
    const costCenterReportMapper = new CostCenterReportMapper();
    const applicationTravelDatesMapper = new TravelDatesMapper();
    const advanceReportEmployeeMapper = new EmployeeMapper();
    const advanceAssigneeEmployeeMapper = new AssigneeEmployeeMapper();
    const advanceReportApplicationTypeMapper = new AOTypeMapper();
    return {
      id: get(response, 'id', 0),
      applicationType: advanceReportApplicationTypeMapper.responseToEntity(get(response, 'applicationType', {})),
      number: get(response, 'number', '0'),
      expenseApplication: {
        id: get(response, 'expenseApplication.id', 0),
        name: {
          ru: get(response, 'expenseApplication.name.ru', 'Заявка на расход[STUB]'),
          en: get(response, 'expenseApplication.name.en', 'Request for expense[STUB]')
        }
      },
      expenseApplicationNumber: get(response, 'expenseApplicationNumber', -1),
      createdByEmployee: advanceReportEmployeeMapper.responseToEntity(get(response, 'createdByEmployee', {})),
      assigneeEmployee: advanceAssigneeEmployeeMapper.responseToEntity(get(response, 'assigneeEmployee', {})),
      createdAt: get(response, 'createdAt', new Date()),
      startApprovalDate: get(response, 'startApprovalDate', null),
      businessTarget: basicTypeMapper.responseToEntity(get(response, 'businessTarget', {})),
      description: get(response, 'description', 'описание[STUB]'),
      vbsElement: get(response, 'vbsElement', 'vbs[STUB]'),
      company: basicTypeMapper.responseToEntity(get(response, 'company', {})),
      status: get(response, 'status', 'Approved'),
      isActive: get(response, 'isActive', false),
      advanceCashAmount: get(response, 'advanceCashAmount', -1),
      totalAmount: get(response, 'totalAmount', -1),
      approvedTotalAmount: get(response, 'approvedTotalAmount', 0),
      approvedReportAmount: get(response, 'approvedReportAmount', 0),
      approvedAmount: get(response, 'approvedAmount', 0),
      lineManger: basicTypeMapper.responseToEntity(get(response, 'lineManager', {})),
      customProperties: customPropertiesMapper.responsesToEntitys(get(response, 'customProperties', [])),
      orderNumber: get(response, 'orderNumber', '-1'),
      journey: {
        businessTripNumber: get(response, 'journey.businessTripNumber', -1),
        journeyNumber: get(response, 'journey.journeyNumber', -1)
      },
      departureFromCityId: basicTypeMapper.responseToEntity(get(response, 'departureFromCity', {})),
      arrivalToCityId: basicTypeMapper.responseToEntity(get(response, 'arrivalToCity', {})),
      startOn: get(response, 'startOn', new Date()),
      endOn: get(response, 'endOn', new Date()),
      businessTripDestinations: sortBy(get(response, 'businessTripDestination', []).map((road: any)=>{
        return{
          city: {
            id: get(road, 'city.id', -1),
            name:{
              ru: get(road, 'city.name.ru', ''),
              en: get(road, 'city.name.en', '')
            }
          },
          arrivalOn: get(road, 'arrivalOn', new Date()),
          leaveOn: get(road, 'leaveOn', new Date()),
          ordinal: get(road, 'ordinal', -1)
        }
      }),['ordinal']),
      personalDaysNumber: get(response, 'personalDaysNumber', 0),
      applicationTravelDates: applicationTravelDatesMapper.responsesToEntitys(get(response, 'applicationTravelDates', [])),
      expenses: advanceReportExpensesMapper.responsesToEntitys(get(response, 'expenses', [])),
      costCenters: costCenterReportMapper.responsesToEntitys(get(response, 'costCenters', [])),
      paperWork: {
        id: get(response, 'paperWork.id', 0),
        employeeId: get(response, 'paperWork.employeeId', 0),
        employeeName:{
            ru: get(response, 'paperWork.employeeName.ru', ''),
            en: get(response, 'paperWork.employeeName.en', ''),
        },
        startedOn: get(response, 'paperWork.startedOn', 0),
      },
      postingDate: get(response, 'postingDate', null),
      properties: get(response, 'properties', []),
      additionalDocuments: get(response, 'additionalDocuments', []).map((item : any) => (
          {
            ...item,
            value: item.id,
            text: item.description,
            section: toLocaleNumberFormatter(item.totalAmount) + ` ${getCurrencieCode('RUB')}`
          }
      ))
    }
  };
}
