import { Action } from 'redux';

export const LOGO = {
  SET_CURRENT: 'LOGO/SET_CURRENT',
  SET_NEXT: 'LOGO/SET_NEXT'
}

export interface LogoModeSetNext extends Action {
  type: typeof LOGO.SET_NEXT;
}

export interface LogoModeSetCurrent extends Action {
  type: typeof LOGO.SET_CURRENT;
  payload: { mode: number };
}

export type LogoActions = LogoModeSetNext | LogoModeSetCurrent;
