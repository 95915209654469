import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import authService from 'services/AuthService';
import SvgIcon from '../svg-icon/SvgIcon';
import If from '../util/If';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/reducers';
import { ROLE_TYPE, OCCUPATION_ROLE_TYPE } from 'infrastructure/enum/user-role.enum';
import { IOccupations } from 'infrastructure/interfaces';
import {getCommonUserDetail} from "../../../store/selectors";
import {PROFILE_FIELD_PERMISSIONS} from "../../../infrastructure/enum/field-permissions.enum";

export interface ProfileUserHeaderProps {
  accessDenied: boolean
}

const ProfileUserHeader: React.FC<ProfileUserHeaderProps> = (props) => {

  const userDetail = useSelector(getCommonUserDetail);
  const profileFieldPermissions = useSelector((state: ApplicationState) => state.common.profileFieldPermissions);

  const fieldPermissions = useMemo(() => {
    return {
      profile: profileFieldPermissions && profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.PROFILE),
      substitution: profileFieldPermissions && profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.SUBSTITUTION),
      settlement: profileFieldPermissions && profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.SETTLEMENT),
      assistance: profileFieldPermissions && profileFieldPermissions.includes(PROFILE_FIELD_PERMISSIONS.ASSISTANCE)
    }
  }, [profileFieldPermissions])

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleHandler = () => {
    setMenuOpen(!isMenuOpen)
  };

  const closeHandler = () => {
    setMenuOpen(false);
  }

  const {t} = useTranslation();

  const isAssistantManagerOccupation = (): boolean => {
    let occupations = userDetail.occupations.map((occupation: IOccupations) => occupation.roles).flat().map((item)=>item.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.ASSISTANT_MANAGER);
  }

  return (
      <React.Fragment>
        <a onClick={toggleHandler} className="header-profile js-profile">
          <SvgIcon className="icon icon-profile" href="#svg_icon_profile"/>
        </a>
        <div className={`mob-menu ${isMenuOpen ? 'active' : ''}`}>
          <div onClick={toggleHandler} className="close"></div>
          {!props.accessDenied && <ul className="mob-menu-wrap">
            <If condition={fieldPermissions.profile}>
            <li><Link onClick={closeHandler} to="/User/Profile">{t('user_menu.profile')}</Link></li>
            </If>
            <If condition={fieldPermissions.settlement}>
            <li><Link onClick={closeHandler} to="/User/Settlement">{t('user_menu.settlement')}</Link></li>
            </If>
            <If condition={fieldPermissions.assistance}>
              <li><Link onClick={closeHandler} to="/User/Assistance">{t('user_menu.assistance')}</Link></li>
            </If>
            <If condition={fieldPermissions.substitution}>
            <li><Link onClick={closeHandler} to="/User/Substitution">{t('user_menu.substitution')}</Link></li>
            </If>
          </ul> }
          <div className="logout">
            <a onClick={() => authService.logout()} className={'pointer'}>
              <SvgIcon className="icon icon-exit" href="#svg_icon_exit"/>
              {t('user_menu.logout')}
            </a>
          </div>
        </div>
      </React.Fragment>
  );
}
export default ProfileUserHeader;