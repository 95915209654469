import { BaseMapper } from './base.mapper';
import { Transaction } from 'infrastructure/interfaces';
import { get, isArray } from 'lodash';

export class TransactionMapper extends BaseMapper<Transaction, any> {
  responseToEntity(response: any): Transaction {
    return {
      id: get(response, 'id', 1),
      createdAt: get(response, 'createdAt', new Date()),
      postingDate: get(response, 'postingDate', new Date()),
      docDate: get(response, 'docDate', new Date()),
      currency: {
        name: get(response, 'currency.name', ''),
        numCode: get(response, 'currency.numCode', 0)
      },
      amount: get(response, 'amount', 0),
      amountRub: get(response, 'amountRub', 0),
      curRate: get(response, 'curRate', 1),
      employeeName: {
        ru: get(response, 'employee.name.ru', ''),
        en: get(response, 'employee.name.en', '')
      },
      businessTargetName: {
        ru: get(response, 'businessTarget.name.ru', ''),
        en: get(response, 'businessTarget.name.en', '')
      },
      number: get(response, 'docNumber', ''),
      postingKey: get(response, 'postingKey', -1),
      taxCode: {
        id: get(response, 'taxCode.id', -1),
        code: get(response, 'taxCode.code', ''),
        name: {
          ru: get(response, 'taxCode.name.ru', ''),
          en: get(response, 'taxCode.name.en', '')
        },
        recoveryIndicator: get(response, 'taxCode.recoveryIndicator', ''),
      },
      costCenterName: {
        ru: get(response, 'costCenter.name.ru', ''),
        en: get(response, 'costCenter.name.en', '')
      },
      ledgerAccount: get(response, 'ledgerOrVendor', ''),
      textOperation: get(response, 'txText', ''),
      objectId: {
        id: get(response, 'objectId.id', ''),
        logicalName: get(response, 'objectId.logicalName', ''),
        name: {
          ru: get(response, 'objectId.name.ru', ''),
          en: get(response, 'objectId.name.en', '')

        }
      },
      expenseItem: {
        id: get(response, 'expenseItem.id', 0),
        code: get(response, 'expenseItem.code', ''),
        name: {
          ru: get(response, 'expenseItem.nameRu', ''),
          en: get(response, 'expenseItem.nameEn', '')
        },
        nameEn: get(response, 'expenseItem.nameEn', ''),
        nameRu: get(response, 'expenseItem.nameRu', ''),
      },
      state: get(response, 'state', ''),
      doesCostCenterDiffer: get(response, 'doesCostCenterDiffer', false),
      properties: get(response, 'properties', [])
    };
  };

  responsesToEntitys(response: any[]): Transaction[] {
    return [...isArray(response) ? response.map(item => this.responseToEntity(item)) : []];
  }
}
