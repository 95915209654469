import { BaseMapper } from './base.mapper';
import { CustomProperties } from 'infrastructure/interfaces';
import { get } from 'lodash';

export class CustomPropertiesMapper extends BaseMapper<CustomProperties, any> {
  responseToEntity(response: any): CustomProperties {
    return {
      name: {
        ru: get(response, 'name.ru', 'Имя[STUB]'),
        en: get(response, 'name.en', 'Name[STUB]')
      },
      value:{
        ru: get(response, 'value.ru', 'Значение[STUB]'),
        en: get(response, 'value.en', 'Value[STUB]')
      }
    };
  };

  responsesToEntitys(responses: any[]): CustomProperties[] {
      return [...responses.map(item => this.responseToEntity(item))]
  };
}
