/// <reference path="./Slidebar.d.ts" />

import React from 'react'
import SimpleBar from 'simplebar-react';

interface SlidebarProps {
  style?: any,
  className?: string
}

const Slidebar: React.FC<SlidebarProps> = props => {
  return (
      <SimpleBar className={props.className} style={props.style}>
        {props.children}
      </SimpleBar>
)
};

export default Slidebar;