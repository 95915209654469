import {Action} from 'redux';
import {ApplicationForExpenseList, ApplicationForExpenseDetail} from "infrastructure/interfaces";

//APPLICATION_FOR_EXPENSE_LIST
export const APPLICATION_FOR_EXPENSE_LIST = {
    SET_APPLICATION_FOR_EXPENSE_LIST: 'APPLICATION_FOR_EXPENSE_LIST/SET_APPLICATION_FOR_EXPENSE_LIST'
};

export interface SetApplicationForExpenseList extends Action {
    type: typeof APPLICATION_FOR_EXPENSE_LIST.SET_APPLICATION_FOR_EXPENSE_LIST;
    payload: { applicationForExpenseList: ApplicationForExpenseList }
}

//EXPENSE_APPLICATION_DETAIL
export const APPLICATION_FOR_EXPENSE_DETAIL = {
    SET_APPLICATION_FOR_EXPENSE_DETAIL: 'APPLICATION_FOR_EXPENSE_DETAIL/SET_APPLICATION_FOR_EXPENSE_DETAIL',
    IS_APPLICATION_FOR_EXPENSE_DETAIL_DOWNLOADED: 'APPLICATION_FOR_EXPENSE_DETAIL/IS_APPLICATION_FOR_EXPENSE_DETAIL_DOWNLOADED'
};

//ADDITIONAL_EXPENSE
export const ADDITIONAL_EXPENSE = {
    SET_ADDITIONAL_EXPENSE: 'ADDITIONAL_EXPENSE/SET_ADDITIONAL_EXPENSE'
};

export interface SetApplicationForExpenseDetail extends Action {
    type: typeof APPLICATION_FOR_EXPENSE_DETAIL.SET_APPLICATION_FOR_EXPENSE_DETAIL;
    payload: { applicationForExpenseDetail: ApplicationForExpenseDetail }
}

export interface SetAdditionalExpense extends Action {
    type: typeof ADDITIONAL_EXPENSE.SET_ADDITIONAL_EXPENSE;
    payload: { additionalExpense: number }
}

export interface SetApplicationForExpenseDetailDownloaded extends Action {
    type: typeof APPLICATION_FOR_EXPENSE_DETAIL.IS_APPLICATION_FOR_EXPENSE_DETAIL_DOWNLOADED;
    payload: { isApplicationForExpenseDetailDownloaded: boolean }
}

export type ApplicationForExpenseActions = SetApplicationForExpenseList | SetApplicationForExpenseDetail | SetApplicationForExpenseDetailDownloaded;
