import { BaseMapper } from './base.mapper';
import { JournalItem } from 'infrastructure/interfaces';
import { get } from 'lodash';

export class JournalItemMapper extends BaseMapper<JournalItem, any> {
  responseToEntity(response: any): JournalItem {
    return {
      id: get(response, 'id', 0),
      name: get(response, 'name', ''),
      employee: {
        id: get(response, 'employeeId.id', ''),
        firstName: {
          ru: get(response, 'employeeId.firstName.ru', ''),
          en: get(response, 'employeeId.firstName.en', '')
        },
        lastName: {
          ru: get(response, 'employeeId.lastName.ru', ''),
          en: get(response, 'employeeId.lastName.en', '')
        },
        middleName: {
          ru: get(response, 'employeeId.middleName.ru', ''),
          en: get(response, 'employeeId.middleName.en', '')
        }
      },
      state: get(response, 'state', ''),
      totalAmount: get(response, 'totalAmount', 0),
      referenceId: get(response, 'referenceId', 0),
      referenceType: get(response, 'referenceType', ''),
      referenceState: get(response, 'referenceState', ''),
      loadedAt: get(response, 'loadedAt', ''),
      lastLoadedAt: get(response, 'lastLoadedAt', ''),
      createdAt: get(response, 'createdAt', ''),
      transactionsCount: get(response, 'transactionsCount', 0)
    };
  };
}
