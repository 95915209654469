import { ApproveListItem } from "infrastructure/interfaces";
import { BaseMapper } from "./base.mapper";
import { BasicTypeMapper } from "./BasicType.mapper";
import { get, isArray } from 'lodash';
import { AssigneeEmployeeMapper } from "./AssigneeEmployee.mapper";

export class ApproveListItemMapper extends BaseMapper<ApproveListItem, any> {
    responseToEntity(response: any): ApproveListItem {
        const basicTypeMapper = new BasicTypeMapper();
        const assigneeEmployeeMapper = new AssigneeEmployeeMapper();
        return {
            id: get(response, 'object.id', 0),
            status: get(response, 'object.status'),
            number: get(response, 'object.number', '0'),
            applicationType: {
                id: get(response, 'object.applicationType.id', 0),
                name: {
                    ru: get(response, 'object.applicationType.name.ru', 'Общехозяйственный расход'),
                    en: get(response, 'object.applicationType.name.en', 'General expense')
                },
                code: get(response, 'object.applicationType.code', 'General')
            },
            assigneeEmployee: assigneeEmployeeMapper.responseToEntity(get(response, 'object.assigneeEmployee', {})),
            totalAmount: get(response, 'object.totalAmount', 0),
            approvedTotalAmount: get(response, 'approvedTotalAmount', 0),
            approvedReportAmount: get(response, 'approvedReportAmount', 0),
            createdAt: get(response, 'object.createdAt', new Date()),
            departureFromCity: basicTypeMapper.responseToEntity(get(response, 'object.departureFromCity', {})),
            arrivalToCity: basicTypeMapper.responseToEntity(get(response, 'object.arrivalToCity', {})),
            startOn: get(response, 'startOn', new Date()),
            endOn: get(response, 'endOn', new Date()),
            expenses: isArray(get(response, 'object.expenseTypes', [])) ? [...get(response, 'object.expenseTypes', []).map((item: any) => ({expenseType: {groupCode: get(item, 'groupCode', 'Other')}}))] : [],
            businessTargetName: {
                ru: get(response, 'object.businessTarget.name.ru', 'Имя[STUB]'),
                en: get(response, 'object.businessTarget.name.en', 'Name[STUB]')
            },
            expenseApplication: {
              id: get(response, 'expenseApplication.id', 0),
              logicalName: get(response, 'expenseApplication.logicalName', ''),
            },
            expenseApplicationNumber: get(response, 'object.expenseApplicationNumber', ''),
            type: get(response, 'object.logicalName', 'ExpenseApplication') === 'ExpenseApplication' ? 'ZNR' : 'AO',
            approvalProgress: {
              level: get(response, 'approvalProgress.level', ''),
              assignedNames: (response.approvalProgress && response.approvalProgress.assignedNames.length) ? response.approvalProgress.assignedNames.map((item: any) => ({
                ru: get(item, 'ru', ''),
                en: get(item, 'en', '')
              })) : {
                ru: get(response, 'approvalProgress.assignedNames.ru', ''),
                en: get(response, 'approvalProgress.assignedNames.en', '')
              }
            },
            readAt: get(response, 'readAt', null),
            paperWork: {
                id: get(response, 'object.paperWork.id', 0),
                employeeId: get(response, 'object.paperWork.employeeId', 0),
                employeeName:{
                  ru: get(response, 'object.paperWork.employeeName.ru', ''),
                  en: get(response, 'object.paperWork.employeeName.en', ''),
              },
                startedOn: get(response, 'paperWork.startedOn', 0),
              },
              isGroup: get(response, 'isGroup', false),
              companyId: get(response.object, 'companyId','')
        }
    }

    responsesToEntitys(responses: any[]): ApproveListItem[] {
        return [...responses.map(item => this.responseToEntity(item))];
    }
}
