import { BaseMapper } from './base.mapper';
import { Clearing } from 'infrastructure/interfaces';
import { get } from 'lodash';
import moment from 'moment';


export class ClearingMapper extends BaseMapper<Clearing, any> {
  responseToEntity(response: any): Clearing {
    return {
      id: get(response, 'id', 0),
      objectId: get(response, 'object.id', 0),
      objectLogicalName: get(response, 'object.logicalName', ''),
      amountRub: get(response, 'amountRub', 0),
      date: moment(get(response, 'createdAt', '2000-01-01T00:00:00'), "YYYY-MM-DDThh:mm:ss").toDate(),
      operationType: {
        ru: get(response, 'operationType.ru',''),
        en: get(response, 'operationType.en',''),
      }
    };
  };

  responsesToEntitys(date: any[]): Clearing[] {
    return [...date.map(item => this.responseToEntity(item))]
  };
}
