import React, { useState, useRef, useEffect, Fragment } from 'react';
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import { useTranslation } from 'react-i18next';
import Validator from '../validator/Validator';
import CityAutocomplete, { CityAutocompleteOptionType } from '../autocomplete/CityAutocomplete';
import { formatter, returnOnlyDate } from 'app/utils';
import DatePickerCalendar from '../date-picker/DatePickerCalendar';
import { useDispatch } from 'react-redux';
import { showErrors } from 'store/exception/actions';
import { isIE } from 'react-device-detect';

export interface RoadObj {
    id: number,
    departureFromCityId: number,
    departureFromCityName: string,
    arrivalToCityId: number,
    arrivalToCityName: string,
    startOn: any,
    endOn: any,
    validate: boolean,
    initRoad?: boolean
    
}

interface RoadProps {
    onChange: (costCenters: RoadObj[]) => void,
    onChangeRoadMode: () => void,
    multiRoadMode: boolean,
    isReadOnly?: boolean,
    defaultOptions?: RoadObj[],
    isAdvance?: boolean,
    tabIndex?: -1 | 0
}

const Road: React.FC<RoadProps> = (props) => {
    let { t } = useTranslation();

    let [roads, setRoads] = useState(props.defaultOptions ? props.defaultOptions : [] as RoadObj[]);

    const roadHandler = (index: number,
        roadDepartureFromCityId: number,
        roadDepartureFromCityName: string,
        roadArrivalToCityId: number,
        roadArrivalToCityName: string,
        roadStartOn: any,
        roadEndOn: any) => {
        let newRoads: RoadObj[] = roads.map((road, currentIndex) => {
            if (currentIndex == index) {
                return {
                    id: road.id,
                    departureFromCityId: roadDepartureFromCityId,
                    departureFromCityName: roadDepartureFromCityName,
                    arrivalToCityId: roadArrivalToCityId,
                    arrivalToCityName: roadArrivalToCityName,
                    startOn: roadStartOn,
                    endOn: roadEndOn,
                    validate: false
                }
            } else {
                return road;
            }
        });
        props.onChange(newRoads);
    }

    const deleteHandler = (index: number) => {
        let newRoads: RoadObj[] = roads.filter((road, i) => {
            return index != i;
        });
        props.onChange(newRoads);
    }

    const createRoad = () => {
        let newRoads: RoadObj[] = roads.map(road => road);
        let newRoad: RoadObj = {
            id: newRoads[newRoads.length-1].id + 1000,
            departureFromCityId: 0,
            departureFromCityName: '',
            arrivalToCityId: 0,
            arrivalToCityName: '',
            startOn: null as any,
            endOn: null as any,
            validate: false
        } as RoadObj;

        if (roads.length === 1 || roads.length === 2) {
            newRoad.departureFromCityId = roads[roads.length - 1].arrivalToCityId;
            newRoad.departureFromCityName = roads[roads.length - 1].arrivalToCityName;
        }

        newRoads.push(newRoad);
        props.onChange(newRoads);
    }

    const changeRoadModeHandler = () => {
        props.onChangeRoadMode();
    }

    useEffect(() => {
        setRoads(props.defaultOptions ? props.defaultOptions : [] as RoadObj[]);
    }, [props.defaultOptions]);

    return (
        <Fragment>
            {roads.map((road, index) => {
                return <RoadItem key={road.id} isAdvance={props.isAdvance} readOnly={props.isReadOnly} index={index} road={road} onChange={roadHandler} onDeleteClick={deleteHandler} validate={road.validate} multiRoad={props.multiRoadMode} disableBeforeDate={roads[index - 1] ? roads[index - 1].endOn : undefined} tabIndex={props.tabIndex}/>
            })}
            {!props.isReadOnly ? 
                <div style={{ width: "100%", textAlign: "center", marginBottom: '15px' }}>
                {props.multiRoadMode ?
                    <a className="btn-add-cost pointer" onClick={createRoad} style={{ float: "left" }} tabIndex={props.tabIndex}>
                        {t('roads.add')}
                    </a>
                    : null}
                <a className="btn-add-cost pointer" onClick={changeRoadModeHandler} style={{ float: "right" }} tabIndex={props.tabIndex}>
                    {props.multiRoadMode ? t('roads.one_road') : t('roads.multi_road')}
                </a>
            </div> : null}
        </Fragment>
    );
}

interface RoadItemProps {
    road: RoadObj,
    index: number,
    validate: boolean,
    multiRoad: boolean,
    readOnly?: boolean,
    disableBeforeDate?: Date,
    isAdvance?: boolean,
    tabIndex?: -1 | 0,
    onChange: (index: number,
        roadDepartureFromCityId: number,
        roadDepartureFromCityName: string,
        roadArrivalToCityId: number,
        roadArrivalToCityName: string,
        roadStartOn: any,
        roadEndOn: any
    ) => void,
    onDeleteClick: (id: number) => void
}

const RoadItem: React.FC<RoadItemProps> = (props) => {
    let { i18n, t } = useTranslation();
    let [roadDepartureFromCityId, setRoadDepartureFromCityId] = useState(props.road.departureFromCityId);
    let [roadDepartureFromCityName, setRoadDepartureFromCityName] = useState(props.road.departureFromCityName);
    let [roadArrivalToCityId, setRoadArrivalToCityId] = useState(props.road.arrivalToCityId);
    let [roadArrivalToCityName, setRoadArrivalToCityName] = useState(props.road.arrivalToCityName);
    let [roadStartOn, setRoadStartOn] = useState(props.road.startOn);
    let [roadEndOn, setRoadEndOn] = useState(props.road.endOn);

    let [isOpenDepartureDate, setOpenDepartureDate] = useState(false);
    let [isOpenReturnDate, setOpenReturnDate] = useState(false);

    let dispatch = useDispatch();
    let depCityValidatorRef = useRef({} as any);
    let desCityValidatorRef = useRef({} as any);
    let depDateValidatorRef = useRef({} as any);
    let desDateValidatorRef = useRef({} as any);

    const onSelectCityFrom = (option: CityAutocompleteOptionType) => {
        setRoadDepartureFromCityName(option.text);
        setRoadDepartureFromCityId(option.value);
        props.onChange(props.index, +option.value, option.text, roadArrivalToCityId, roadArrivalToCityName, roadStartOn, roadEndOn);
    };

    const onSelectCityTo = (option: CityAutocompleteOptionType) => {
        setRoadArrivalToCityName(option.text);
        setRoadArrivalToCityId(option.value);
        props.onChange(props.index, roadDepartureFromCityId, roadDepartureFromCityName, +option.value, option.text, roadStartOn, roadEndOn);
    };

    const onSelectDepartureDate = (value: any) => {
        setRoadStartOn(value.startDate);
        props.onChange(props.index, roadDepartureFromCityId, roadDepartureFromCityName, roadArrivalToCityId, roadArrivalToCityName, value.startDate, roadEndOn);
        toggleDepartureDate();
    };

    const toggleDepartureDate = () => {
        setOpenDepartureDate(!isOpenDepartureDate);
    };

    const onSelectReturnDate = (value: any) => {
        setRoadEndOn(value.startDate)
        props.onChange(props.index, roadDepartureFromCityId, roadDepartureFromCityName, roadArrivalToCityId, roadArrivalToCityName, roadStartOn, value.startDate);
        toggleReturnDate();
    };

    const toggleReturnDate = () => {
        setOpenReturnDate(!isOpenReturnDate);
    };

    const clickDelete = () => {
        props.onDeleteClick(props.index);
    }

    const onChangeCityFrom = (text: string) => {
        setRoadDepartureFromCityId(0);
        setRoadDepartureFromCityName(text);
        props.onChange(props.index, 0, text, roadArrivalToCityId, roadArrivalToCityName, roadStartOn, roadEndOn);
    }

    const onChangeCityTo = (text: string) => {
        setRoadArrivalToCityId(0);
        setRoadArrivalToCityName(text);
        props.onChange(props.index, roadDepartureFromCityId, roadDepartureFromCityName, 0, text, roadStartOn, roadEndOn);
    }

    const validate = () => {
        if (props.index == 0) {
            if (roadDepartureFromCityId === 0) {
                depCityValidatorRef.current.clear();
                depCityValidatorRef.current.validate();
                setRoadDepartureFromCityName('');
            }
        }
        if (roadArrivalToCityId === 0) {
            desCityValidatorRef.current.clear();
            desCityValidatorRef.current.validate();
            setRoadArrivalToCityName('');
        }
        depDateValidatorRef.current.validate();
        desDateValidatorRef.current.validate();
        if (!props.multiRoad) {
            validateDateInterval();
        }
    };

    const validateDateInterval = () => {
        if ((roadStartOn && roadEndOn) && roadStartOn.valueOf() > roadEndOn.valueOf()) {
            depDateValidatorRef.current.clear();
            desDateValidatorRef.current.clear();
            depDateValidatorRef.current.validate();
            desDateValidatorRef.current.validate();
            setRoadStartOn(null);
            setRoadEndOn(null);
            dispatch(showErrors({
                code: 'expense_applications_error',
                message: t('validator.date_select_error')
            }));
            return false
        }
        return true
    };

    useEffect(() => {
        setRoadArrivalToCityId(props.road.arrivalToCityId);
        setRoadDepartureFromCityId(props.road.departureFromCityId);
        setRoadDepartureFromCityName(props.road.departureFromCityName);
        setRoadArrivalToCityName(props.road.arrivalToCityName);
        setRoadEndOn(props.road.endOn);
        setRoadStartOn(props.road.startOn);
    }, [props.road])

    useEffect(() => {
        if (!props.validate) {
            return;
        }
        validate();
    }, [props.validate]);

    useEffect(() => {
        let a = props.disableBeforeDate;
        let b = roadStartOn;
        a && a.setHours(0,0,0,0);
        b && b.setHours(0,0,0,0);
        if ((!returnOnlyDate(props.disableBeforeDate as Date) && props.index != 0) || (returnOnlyDate(props.disableBeforeDate as Date) && returnOnlyDate(props.disableBeforeDate as Date) > returnOnlyDate(roadStartOn))) {
            setRoadStartOn(null);
            props.onChange(props.index, roadDepartureFromCityId, roadDepartureFromCityName, roadArrivalToCityId, roadArrivalToCityName, null, roadEndOn);
            depDateValidatorRef.current.clear();
        }
    }, [props.disableBeforeDate]);

    useEffect(() => {
        let a = roadStartOn;
        let b = roadEndOn;
        a && a.setHours(0,0,0,0);
        b && b.setHours(0,0,0,0);
        if (!a || (a && a > b)) {
            setRoadEndOn(null);
            props.onChange(props.index, roadDepartureFromCityId, roadDepartureFromCityName, roadArrivalToCityId, roadArrivalToCityName, roadStartOn, null);
            desDateValidatorRef.current.clear();
        }
    }, [props.road.startOn]);

    return (
        <div className="input-item-row">
            {props.index == 0 ? (<Validator type={'autocomplete'} ref={depCityValidatorRef} className={props.readOnly ? 'disabled' : ''} isRoad={isIE}>
                <React.Fragment>
                    <label className="input-label">
                        {t('create_expense.departure_city')}
                        <i className="input-required">*</i>
                    </label>
                    <CityAutocomplete onChoose={onSelectCityFrom} placeholder={t('create_expense.placeholder.from')}
                        defaultText={roadDepartureFromCityName} onChange={onChangeCityFrom} readOnly={props.readOnly} tabIndex={props.tabIndex}/>
                </React.Fragment>
            </Validator>) : null}
            <Validator type={'autocomplete'} ref={desCityValidatorRef} className={props.readOnly ? 'disabled' : ''} isRoad={isIE}>
                <React.Fragment>
                    <label className="input-label">
                        {t('create_expense.destination_city')}
                        <i className="input-required">*</i>
                    </label>
                    <div style={props.index > 0 ? { minWidth: '297px' } : {}}>
                        <CityAutocomplete onChoose={onSelectCityTo} placeholder={t('create_expense.placeholder.to')}
                            defaultText={roadArrivalToCityName} onChange={onChangeCityTo} readOnly={props.readOnly} tabIndex={props.tabIndex}/>
                    </div>
                </React.Fragment>
            </Validator>
            <Validator type={'text'} ref={depDateValidatorRef} className={props.readOnly ? 'disabled' : ''} isRoad={isIE}>
                <React.Fragment>
                    <label className="input-label">
                        {props.multiRoad ? t('roads.date_to') : t('create_expense.departure_date')}
                        <i className="input-required">*</i>
                    </label>
                    <div className="input-date">
                        <input className="input pointer" type="text"
                            value={roadStartOn ? formatter('D MMMM', i18n.language, roadStartOn) : ''}
                            onClick={toggleDepartureDate} readOnly id="datepicker_departure" tabIndex={props.tabIndex}/>
                        <SvgIcon className="icon icon-calendar pointer" href="#svg_icon_calendar" />
                    </div>
                    <DatePickerCalendar startDate={roadStartOn} isCleansed={true} isMultiChoice={false}
                        isShown={isOpenDepartureDate} onSelected={onSelectDepartureDate} month={roadStartOn ? roadStartOn : props.disableBeforeDate}
                        onClose={toggleDepartureDate} isDisableBeforeToday={!props.isAdvance} disableBeforeDate={props.disableBeforeDate}
                    disableAfterDate={roadEndOn ? roadEndOn : null}/>
                </React.Fragment>
            </Validator>
            <Validator type={'text'} ref={desDateValidatorRef} className={props.readOnly ? 'disabled' : ''} isRoad={isIE}>
                <React.Fragment>
                    <label className="input-label">
                        {props.multiRoad ? t('roads.date_from') : t('create_expense.return_date')}
                        <i className="input-required">*</i>
                    </label>
                    <div className="input-date">
                        <input className="input pointer" type="text"
                            value={roadEndOn ? formatter('D MMMM', i18n.language, roadEndOn) : ''}
                            onClick={toggleReturnDate} readOnly id="datepicker_return" tabIndex={props.tabIndex}/>
                        <SvgIcon className="icon icon-calendar pointer" href="#svg_icon_calendar" />
                    </div>
                    <DatePickerCalendar startDate={roadEndOn} isCleansed={true} isMultiChoice={false}
                        isShown={isOpenReturnDate} onSelected={onSelectReturnDate} month={roadEndOn ? roadEndOn : roadStartOn}
                        onClose={toggleReturnDate} isDisableBeforeToday={!props.isAdvance} disableBeforeDate={roadStartOn} />
                </React.Fragment>
            </Validator>
            {props.multiRoad ? (
                <div className={`input-item input-remove ${props.readOnly ? 'disabled' : ''}`} style={{ width: '22px', visibility: props.index === 0 ? 'hidden' : 'visible' }}>
                    <label className="input-label"></label>
                    <a onClick={clickDelete} tabIndex={props.tabIndex}>
                        <SvgIcon className="icon icon-remove" href="#svg_icon_remove" />
                    </a>
                </div>) : null}
        </div>
    );
}

export default Road;