import React, { useEffect, useRef, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalContainer from './ModalContainer';
import { forEach, isEmpty } from 'lodash';
import Validator from '../validator/Validator';
import { loaderLock, loaderUnlock } from '../../../store/common/actions';
import { showErrors, showInfo } from '../../../store/exception/actions';
import SvgIcon from '../../component/svg-icon/SvgIcon';
import TextCutter from '../text-cutter/TextCutter';
import {getCommonUserDetail} from "../../../store/selectors";
import {getAttachments, postSupports} from "../../../services/ApiService";

interface SupportRequestProps {
}

const SupportRequest: React.FC<SupportRequestProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getCommonUserDetail);
 
  let [isOpen, setOpen] = useState(false);
  let [description, setDescription] = useState('');
  let [attachFiles, setAttachFiles] = useState([] as any);
  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);
  
  const descriptionRef = useRef({} as HTMLTextAreaElement);
  const descriptionValidatorRef = useRef({} as any);

  const getAttachFiles = (attachFiles: any) => {
    setAttachFiles(attachFiles);
  };

  const changeDescription = () => {
      setDescription(descriptionRef.current.value);
  };

  const clickHandler = () => {
    setOpen(!isOpen);
    if (!isOpen) {
      setDescription('');
    }
  };

  const saveHandler = () => {
    if (validate()) {
        saveSubmit();
    }
  };

  const saveSubmit = () => {
    dispatch(loaderLock());
    if (attachFiles.length == 0 || checkOversizing(attachFiles)) {
      sendSupportRequest();
    } else {
      dispatch(showErrors({
        code: '',
        message: t('attach_file_error.oversizing')
      }));
    }
    dispatch(loaderUnlock());
  };
  
  const checkOversizing = (attachFiles: any) => {
    let sumSize = attachFiles
      .map((file:any) => {
      return file.size
    })
      .reduce((sum: number, current: number) => {
      return sum + current
    });
    if (sumSize < 100*1024*1024) {
      return true
    } else {
      return false
    }
  };

  const sendSupportRequest = async () => {
    let formData = new FormData();
    attachFiles.forEach((file: any) => {
      formData.append('Attachments', file);
    });
    formData.append('Content', description);
    formData.append('Actor.Id', String(user.id));
    formData.append('Actor.FullName.Ru', user.name.ru);
    formData.append('Actor.FullName.En', user.name.en);
    formData.append('Actor.Login', user.email);
    formData.append('Stamp', (new Date()).toUTCString());
    formData.append('URL', window.location.href);

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let result = await postSupports(formData, config);
    if (result.status == 201) {
      dispatch(showInfo({ 
        code: '', 
        message: t('modals.support-request.success', {mail:user.email}) 
      }));
      setOpen(false);
    } else {
      dispatch(showErrors({ 
        code: '', 
        message: 'Что-то пошло не так' 
      }));
    }
  };

  const validate = (): boolean => {
  let isValid = true;
    if (typeof descriptionValidatorRef.current.validate !== 'function') {
      return false;
    }
  let validateList = [descriptionValidatorRef.current.validate()];
    validateList.forEach((item) => {
      if (!item) {
        isValid = false;
      }
    });
    return isValid;
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  }

  return (
    <React.Fragment>
      <a className="support-link">
        <SvgIcon className="icon icon-support pointer" href="#svg_icon_support_without_circle" onClick={clickHandler}/>
      </a>
      <ModalContainer onEnter={saveHandler} isOpen={isOpen} destroy={true} highestModal={isHighestModal} overlayClick={clickHandler}>
        <div className="box-modal" id="add-expenses">
          <div className="box-modal_close arcticmodal-close" onClick={clickHandler} />
            <div className="box-modal-title">{t('modals.support-request.create-request')}</div>
            <div className="box-modal-content">
              <div className="input-wrap">
                <Validator ref={descriptionValidatorRef} type={'textarea'}>
                  <Fragment>
                    <label className="input-label">{t('modals.support-request.description')}</label>
                    <textarea className="box-modal-textarea" name="text" ref={descriptionRef} maxLength={400} onChange={changeDescription} value={description} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                  </Fragment>
                </Validator>
                <FileAttach isOpen={isOpen} getAttachFiles={getAttachFiles} /> 
              </div>
            </div>
            <div className="box-modal-footer">
              <div className="arcticmodal-close btn btn_black pointer" onClick={clickHandler}>{t('modals.support-request.cancel')}</div>
              <div className="btn-expense btn_green pointer" onClick={saveHandler}>{t('modals.support-request.complete')}</div>
            </div>
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

interface FileAttach {
    isOpen: boolean,
    editId?: number | null;
    getAttachFiles: (attachFiles: any) => void
  }
  
  const FileAttach: React.FC<FileAttach> = props => {
    const { t } = useTranslation();
    const dropZone = useRef({} as any);
    const dispatch = useDispatch();
  
    let [attachFiles, setAttachFiles] = useState([] as any);
    let [isDragOver, setIsDragOver] = useState(false);
    let [filesName, setFilesName] = useState([] as any);
    let [attachId, setAttachId] = useState([] as any);
    let [width, setWidth] = useState(0);
  
    let nameRef = useRef({} as any);
  
    const resizeHandler = () => {
      if (!isEmpty(filesName)) {
        setWidth(nameRef.current.offsetWidth);
      }
    }
  
    useEffect(() => {
      resizeHandler();
    }, [filesName]);
  
    useEffect(() => {
      window.addEventListener('resize', resizeHandler);
      return () => {
        window.removeEventListener('resize', resizeHandler);
      }
    })
  
    useEffect(() => {
      props.getAttachFiles(attachFiles);
    }, [attachFiles]);
  
    useEffect(() => {
      if (props.editId) {
        let params = {
          'ObjectId.Id': props.editId,
          'ObjectId.LogicalName': 'Expense',
        };
        dispatch(loaderLock());
        getAttachments({ params }).then((response: any) => {
          if (response.status == 200) {
            let filesName = response.data.data.map((item: any) => item.name);
            let filesId = response.data.data.map((item: any) => item.id);
            setFilesName(filesName);
            setAttachId(filesId);
          }
        }).finally(() => {
          dispatch(loaderUnlock());
        })
      }
    }, []);
  
    useEffect(() => {
      window.addEventListener('dragover', (e) => {
        e.preventDefault();
      });
      window.addEventListener('drop', (e) => {
        e.preventDefault();
      });
      return () => {
        window.removeEventListener('drop', (e) => {
          e.preventDefault();
        });
        window.removeEventListener('dragover', (e) => {
          e.preventDefault();
        });
      }
    }, []);
  
    const dropZoneChangeHandler = () => {
      let file = [...dropZone.current.files];
      checkSizeAndFormat(file);
      dropZone.current.value = '';
    };
  
    const dropZoneDropHander = (e: any) => {
      setIsDragOver(false);
      let files = [...e.dataTransfer.files];
      checkSizeAndFormat(files);
      dropZone.current.value = '';
    };
  
    const checkSizeAndFormat = (files: any) => {
      if(files.length == 0) {return}

      let maxFileSize = 10485760 as number;
      const checkSizeAndFormatFiles: boolean[] = [];
      forEach(files, (item: any, index: any) => {
        if (!((item.size <= maxFileSize) &&
          (
            (item.type == 'image/png') ||
            (item.type == 'image/jpeg') ||
            (item.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (item.type == 'application/msword') ||
            (item.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (item.type == 'application/vnd.ms-excel') ||
            (item.type == 'application/pdf')
          ))) {
          checkSizeAndFormatFiles.push(false);
        };
      });
      
      if (isEmpty(checkSizeAndFormatFiles)) {
        let filesArray = [...attachFiles];
        let filesNameArray = [...filesName];
        forEach(files, (item: any, index: any) => {
          filesArray.push(item);
          filesNameArray.push(item.name);
        });
        setAttachFiles(filesArray);
        setFilesName(filesNameArray);
      } else {
        dispatch(showErrors({
          code: '',
          message: t('attach_file_error.massage')
        })
        );
      }
    };
  
    const deleteAttachHandler = (index: number) => {
      if (!props.editId) {
        let filesArray = [...attachFiles];
        filesArray.splice(Number(index), 1);
        setAttachFiles(filesArray);
  
        let filesNameArray = [...filesName];
        filesNameArray.splice(Number(index), 1);
        setFilesName(filesNameArray);
      }
      if (props.editId) {  
        if (!isEmpty(attachFiles)) {
          let filesArray = [...attachFiles];
          filesArray.splice(Number(index), 1);
          setAttachFiles(filesArray);
        }
  
        let filesNameArray = [...filesName];
        filesNameArray.splice(index, 1);
        setFilesName(filesNameArray);
      }
    };

    return (
      <Fragment>
        <div className="request-files">
          <div className="request-files-dropzone" onDragOver={() => { setIsDragOver(true) }}
            onDragLeave={() => { setIsDragOver(false) }}
            onDrop={dropZoneDropHander}
            style={!isDragOver ? {
              position: 'relative',
              zIndex: 10, background: 'url(img/icon-dropzone.svg) no-repeat 50% 40%'
            } :
              {
                position: 'relative',
                zIndex: 10,
                background: 'url(img/icon-dropzone.svg) no-repeat 50% 40%',
                backgroundColor: 'rgba(229, 230, 234, 1)',
              }}>
            <input ref={dropZone} onChange={dropZoneChangeHandler} id="file-input" type="file" name="file" multiple title='' tabIndex={-1} style={{
              width: '100%',
              height: '100%',
              opacity: 0,
              position: 'absolute',
              zIndex: -11,
              cursor: 'pointer'
            }} />
            <label htmlFor="file-input" style={{
              position: 'absolute',
              left: '10px',
              right: '10px',
              textAlign: 'center',
              top: '70%'
            }}>{t('attach_file_message.massage')}</label>
          </div>
        </div>
        {!isEmpty(filesName) ?
          (<div className={"input-wrap"}><div className="request-expenses-item-file " ref={nameRef} style={{ marginBottom: '20px' }}>
            <div><SvgIcon className={'icon icon-attach'} href={'#svg_icon_attach'} />{t('modals.support-request.attached')} ({filesName.length}):&nbsp;</div>{filesName.map((name: any, index: any) => (
              <Fragment>
                <p className="flex-center" style={{ marginTop: "10px" }}>
                  <b key={index}><TextCutter text={name} parentWidth={width} options={[{
                    maxLength: 90,
                    maxWidth: 645,
                    minWidth: 266,
                    symbolSize: 6
                  }, {
                    maxLength: 40,
                    maxWidth: 265,
                    minWidth: 0,
                    symbolSize: 6
                  }]} /></b>
                  <a >
                    <div className="icon icon-close file pointer">
                      <SvgIcon href={"#svg_icon_close"} index={index} onClick={() => deleteAttachHandler(index)} />
                    </div>
                  </a>
                </p>
              </Fragment>
            )
            )}
          </div></div>)
          : null}
      </Fragment>
    )
  };

export default SupportRequest;