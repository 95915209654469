import React, { useState, Fragment } from 'react';
import SvgIcon from '../svg-icon/SvgIcon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface NoticePanelItem {
    id: number,
    date: string,
    userNameShort: string,
    title: string,
    type: string,
    isRead: boolean,

    message?: { user: string, text: string },

    attachments?: { name: string, size: string, href: string }[],

    link?: { name: string, href: string }
}

const NoticePanelItem: React.FC<{ notice: NoticePanelItem, onClick: (id: number) => void }> = (props) => {
    let [loader, setLoader] = useState(false);

    const clickHandler = () => {
        if (!props.notice.isRead && !loader && props.notice.type != 'nomessage' && props.notice.type != 'loader') {
            setLoader(true);
            props.onClick(props.notice.id)
        }
    };

    return (
        <div className={'history-request-item ' + (props.notice.isRead ? '' : 'not-view')} onClick={clickHandler}>
            {loader ? <div className="lds-ellipsis" style={{ marginLeft: '50%', marginTop: '0px', position: 'absolute' }}><div></div><div></div><div></div><div></div></div> : null}
            <div className="history-request-item-header">
                <div>{props.notice.date}</div>
                {/* <div>{props.notice.userNameShort}</div> */}
            </div>
            <div className="history-request-item-bold">
                {props.notice.title}
                {!(props.notice.type == 'nomessage' || props.notice.type == 'loader') ? <SvgIcon className="icon icon-arrow" href="#svg_icon_arrow-history" /> : null}
                {props.notice.type == 'loader' ? <div className="lds-ellipsis" style={{ marginLeft: 'calc(50% - 30px)', marginTop: '-10px', position: 'absolute' }}><div></div><div></div><div></div><div></div></div> : null}
            </div>
            {(() => {
                switch (props.notice.type) {
                    case 'message':
                        return <Fragment>
                            <NoticePanelItemMessage
                                user={props.notice.message ? props.notice.message.user : ''}
                                text={props.notice.message ? props.notice.message.text : ''}
                            />
                            <NoticePanelItemLink
                                name={props.notice.link ? props.notice.link.name : ''}
                                href={props.notice.link ? props.notice.link.href : ''}
                            />
                        </Fragment>;
                    case 'attach':
                        return <NoticePanelItemAttachment
                            attachments={props.notice.attachments ? props.notice.attachments : []}
                        />;
                    case 'link':
                        return <NoticePanelItemLink
                            name={props.notice.link ? props.notice.link.name : ''}
                            href={props.notice.link ? props.notice.link.href : ''}
                        />;
                    default:
                        return null;
                }
            })()}
        </div>
    );
}

const NoticePanelItemMessage: React.SFC<{ user: string, text: string }> = (props) => {
    return (
        <div className="history-request-message">
            <div className="history-request-message-from">{props.user}</div>
            <div className="history-request-message-text">
                {props.text}
                <SvgIcon className="icon icon-more" href="#svg_icon_more" />
            </div>
        </div>
    );
}

const NoticePanelItemAttachment: React.SFC<{ attachments: { name: string, size: string, href: string }[] }> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="history-request-attach">
            <span>{t('notice.attachment')}:</span>
            {props.attachments.map((attachment, index) => {
                return (
                    <div className="history-request-attach-item" key={index}>
                        <a target='_blank' rel="noopener noreferrer" href={attachment.href}>
                            <SvgIcon className="icon icon-attach" href="#svg_icon_attach" />
                            {attachment.name}
                        </a>
                        <span>&nbsp;({attachment.size})</span>
                    </div>
                )
            })}
        </div >
    );
}

const NoticePanelItemLink: React.SFC<{ name: string, href: string }> = (props) => {
    return (
        <Link className="history-request-link" rel="noopener noreferrer" to={props.href} tabIndex={-1}>
            <span>{props.name}</span>
            <SvgIcon className="icon icon-arrow" href="#svg_icon_arrow-history" />
        </Link>
    );
}

export default NoticePanelItem;