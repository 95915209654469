import React, {useState, Fragment, useRef, useEffect} from "react";
import { forEach } from 'lodash';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
//component
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import AttachedImagePreview from 'app/component/modal/AttachedImagePreview';
//store
import {loaderLock, loaderUnlock} from "store/common/actions";
import { ApplicationState } from 'store/reducers';
import { showErrors } from 'store/exception/actions';
//modal
import AttachFileModal from 'app/component/modal/AttachFileModal';
import DeleteAttachmentModal from 'app/component/modal/DeleteAttachmentModal';
//common
import { Expense, AttachmentItem } from 'infrastructure/interfaces';
import {updateDetailedAO } from "../../../store/report/actions";
import {updateApplicationForExpenseDetail} from "store/applicationForExpense/actions";
import TextCutter from "../text-cutter/TextCutter";
import {
    getAdvanceReportApplication,
    getApplicationForExpenseDetail,
    getCommonAttachmentList
} from "../../../store/selectors";
import {
    deleteAttachmentsById, getAttachmentsContent,
    getAttachmentsPreview,
    postAttachments,
    putAttachmentsById
} from "../../../services/ApiService";

interface AttachmentFilesPanel {
    isReport?: boolean
    id: number
}

const AttachmentFilesPanel: React.FC<AttachmentFilesPanel> = props => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    let [isOpenAttachFile, setOpenAttachFile] = useState(false);
    let [attachFile, setAttachFile] = useState(null as any);
    let [isDragOver, setIsDragOver] = useState(false);

    const applicationForExpenseDetail = useSelector(getApplicationForExpenseDetail);
    const advanceReport = useSelector(getAdvanceReportApplication);
    let expenses: any = props.isReport ? advanceReport.expenses : applicationForExpenseDetail ? applicationForExpenseDetail.expenses : null;

    const toggleAttachFile = () => {
        setOpenAttachFile(!isOpenAttachFile);
        setAttachFile(null);
    };

    const loadingAttachFiles = (formData: any) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        return postAttachments(formData, config)
    };

    const toggleSubmitAttachFile = (expense: any) => {
        const promiseAttachArray: any = [];
        forEach(attachFile, (file) => {
            let formData = new FormData();
            formData.append('Content', file);
            formData.append('ObjectId.Id', expense.id);
            formData.append('ObjectId.LogicalName', (expense.expenseType.name.ru === 'Без расхода') ? expense.expenseType.logicalName : 'Expense');
            promiseAttachArray.push(loadingAttachFiles(formData));
        });
        setOpenAttachFile(!isOpenAttachFile);
        dispatch(loaderLock());
        Promise.all(promiseAttachArray).then((response) => {
            props.isReport ? dispatch(updateDetailedAO(props.id)) : dispatch(updateApplicationForExpenseDetail(props.id));
        }).finally(() => dispatch(loaderUnlock()));
    };

    let dropZone = useRef({} as any);



    useEffect(() => {
        window.addEventListener('dragover', (e) => {
            e.preventDefault();
        });
        window.addEventListener('drop', (e) => {
            e.preventDefault();
        });
        return () => {
            window.removeEventListener('drop', (e) => {
                e.preventDefault();
            });
            window.removeEventListener('dragover', (e) => {
                e.preventDefault();
            });
        }
    },[]);

    const dropZoneChangeHandler = () => {
        let file = [...dropZone.current.files];
        checkSizeAndFormat(file);
        dropZone.current.value = '';
    }

    const dropZoneDropHandler = (e: any) => {
        setIsDragOver(false)
        let files = [...e.dataTransfer.files];
        checkSizeAndFormat(files);
        dropZone.current.value = '';
    }

    const checkSizeAndFormat = (files: any) => {
        if(files.length == 0) {return}

        let maxFileSize = 104857600 as number;
        const checkSizeAndFormatFiles: boolean[] = [];
        forEach(files, (item: any, index: any) => {
            if (!((item.size <= maxFileSize) &&
                (
                    (item.type == 'image/png') ||
                    (item.type == 'image/jpeg') ||
                    (item.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
                    (item.type == 'application/msword') ||
                    (item.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
                    (item.type == 'application/vnd.ms-excel') ||
                    (item.type == 'application/pdf')
                ))) {
                checkSizeAndFormatFiles.push(false);
            }
        });

        if(isEmpty(checkSizeAndFormatFiles)) {
            setAttachFile(files);
                setOpenAttachFile(true);
            } else {
            dispatch(showErrors({
                code: '',
                message: t('attach_file_error.massage')
                })
            );
        }

    };


    return (
        <Fragment>
            <div className="request-files">
                <div className="request-files-title">{t('request_detail.added_files.attachedFiles')}</div>
                <div  className="request-files-dropzone" onDragOver={() => {setIsDragOver(true)}}
                      onDragLeave={() => {setIsDragOver(false)}}
                      onDrop={dropZoneDropHandler}
                      style={!isDragOver ? {
                              position: 'relative',
                              zIndex: 10, background: 'url(img/icon-dropzone.svg) no-repeat 50% 40%'
                          } :
                          {
                              position: 'relative',
                              zIndex: 10,
                              background: 'url(img/icon-dropzone.svg) no-repeat 50% 40%',
                              backgroundColor: 'rgba(229, 230, 234, 1)',
                          }}>
                    <input ref={dropZone} onChange={dropZoneChangeHandler} id="file-input" type="file" name="file" multiple title='' tabIndex={-1} style={{width: '100%', height: '100%', opacity: 0, position: 'absolute', zIndex: -11, cursor: 'pointer'}}/>
                    <label htmlFor="file-input" style={{
                        position: 'absolute', left: '10px',
                        right: '10px',
                        textAlign: 'center', top: '70%', cursor: 'pointer'
                    }}>{t('attach_file_message.massage')}</label>
                </div>
                <AttachmentFilesList id={props.id} isReport={props.isReport}/>
            </div>
            <AttachFileModal isOpen={isOpenAttachFile} onClose={toggleAttachFile} onSubmit={toggleSubmitAttachFile} isReport={props.isReport}/>
        </Fragment>
    )
};

interface AttachmentFilesList {
    isReport?: boolean
    id: number
}

const AttachmentFilesList: React.FC<AttachmentFilesList> = props => {
    const attachmentList = useSelector(getCommonAttachmentList);
    return ( !isEmpty(attachmentList) ? <div className="request-files-wrap">
        { attachmentList.map((item: AttachmentItem )=> {
                return <AttachmentFileItem key={item.id} item={item} id={props.id} isReport={props.isReport}/>
            })}
        </div> : null
    )
};

interface AttachmentFileItem {
    item: AttachmentItem
    isReport?: boolean
    id: number
}

const AttachmentFileItem: React.FC<AttachmentFileItem> = props => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    let [isOpenPopup, setOpenPopup] = useState(false);
    let [isOpenAttachFile, setOpenAttachFile] = useState(false);
    let [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
    let [imgData, setImgData] = useState('');
    let [isOpenImagePreview, setOpenImagePreview] = useState(false);
    let [width, setWidth] = useState(0);

    let nameRef = useRef(null as any);

    const applicationForExpenseDetail = useSelector(getApplicationForExpenseDetail);
    const advanceReport = useSelector(getAdvanceReportApplication);
    let expenses: any = props.isReport ? advanceReport.expenses : applicationForExpenseDetail ? applicationForExpenseDetail.expenses : null;

    const toggleSubmitEditAttachFile = (expense: any) => {
        let formData = new FormData();
        formData.append('Content', '');
        formData.append('ObjectId.Id', expense.id);
        formData.append('ObjectId.LogicalName', expense.expenseType.logicalName);

        dispatch(loaderLock());
        putAttachmentsById(props.item.id, formData).then((response) => {
            props.isReport ? dispatch(updateDetailedAO(props.id)) : dispatch(updateApplicationForExpenseDetail(props.id))
        }).finally(()=>{
            dispatch(loaderUnlock());
        });
        setOpenAttachFile(!isOpenAttachFile);
    };

    const toggleDeleteModal = () => {
        setOpenDeleteModal(!isOpenDeleteModal);
    };

    const toggleImagePreviewModal = () => {
        setOpenImagePreview(!isOpenImagePreview);
    };

    const toggleSubmitDeleteFile = () => {
        dispatch(loaderLock());
        deleteAttachmentsById(props.item.id).then((response) => {
            setOpenDeleteModal(!isOpenDeleteModal);
            props.isReport ? dispatch(updateDetailedAO(props.id)) : dispatch(updateApplicationForExpenseDetail(props.id))
        }).finally(()=>{
            dispatch(loaderUnlock());
        });
    };

    const getImage = () => {
        dispatch(loaderLock());
        getAttachmentsPreview(props.item.id, {
            responseType: 'arraybuffer'
        }).then((response) => {
            let buffer = Buffer.from(response.data, 'binary').toString('base64');
            setImgData(`data:${response.headers['content-type']};base64,${buffer}`);
        }).finally(()=>{
            dispatch(loaderUnlock());
        });
    };

    useEffect(() => {
        resizeEventHander();
        getImage();
    }, []);

    const resizeEventHander = () => {
        setWidth(nameRef.current.offsetWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', resizeEventHander)
        return () => {
          window.removeEventListener('resize', resizeEventHander);
        }
    })

    const clickDownloadHandler = async ( id: number, e?: any, ) => {
        if (e) e.stopPropagation();
        let downland = await getAttachmentsContent(id, {responseType: 'blob'});
        let contentDisposition = downland.headers['content-disposition'];
        let fileName = contentDisposition.slice(contentDisposition.lastIndexOf('\'') + 1);
        FileSaver.saveAs(downland.data, decodeURI(fileName))
    };

    const isFormatForDownload = () => {
        const format: any = props.item.name.match(/[0-9a-z]+$/i);
        switch (format[0]) {
            case 'docx' :
                return t('title.download');
            case 'xlsx' :
                return t('title.download');
            case 'doc' :
                return t('title.download');
            case 'xls' :
                return t('title.download');
            case 'pdf' :
                return t('title.download');
            default :
                return t('title.open');
        }
    };

    const clickHandlerPreviewOrDownload = () => {
        const format: any = props.item.name.match(/[0-9a-z]+$/i);
        switch (format[0]) {
            case 'docx' :
                return (e: any) => clickDownloadHandler(props.item.id);
            case 'xlsx' :
                return (e: any) => clickDownloadHandler(props.item.id);
            case 'doc' :
                return (e: any) => clickDownloadHandler(props.item.id);
            case 'xls' :
                return (e: any) => clickDownloadHandler(props.item.id);
            case 'pdf' :
                return (e: any) => clickDownloadHandler(props.item.id);
            default :
                return toggleImagePreviewModal
        }
    };

    const getAttachmentClassByFormat = () => {
        const format: any = props.item.name.match(/[0-9a-z]+$/i);
        switch (format[0]) {
            case 'docx' :
                return "doc";
            case 'doc' :
                return "doc";
            case 'xlsx' :
                return "doc";
            case 'xls' :
                return "doc";
            case 'pdf' :
                return "doc";
            default :
                return "img";
        }
    };

    const togglePopup = (event?: any) => {
        if (event) event.stopPropagation();
        setOpenPopup(!isOpenPopup);
    };

    const toggleAttachFile = (event?: any) => {
        if (event) event.stopPropagation();
        setOpenAttachFile(!isOpenAttachFile);
        if (isOpenPopup){
            togglePopup();
        }
    };

    const onDelete = (event?: any) => {
        if (event) event.stopPropagation();
        togglePopup();
        toggleDeleteModal();
    };

    return (
        <Fragment>
        <div className={`request-files-item ${getAttachmentClassByFormat()} pointer`} onClick={clickHandlerPreviewOrDownload()} title={isFormatForDownload()}>
            {isOpenPopup ? <div className="miss-click-handler" onClick={togglePopup}/> : ''}
            <div className="request-files-item-dropdown" style={{display: isOpenPopup ? 'block' : 'none'}}>
                <a className="pointer" onClick={toggleAttachFile}>
                    <SvgIcon className="icon icon-edit" href="#svg_icon_edit"/>
                    {t('report_detail.edit')}</a>
                <a className="pointer" onClick={onDelete}>
                    <SvgIcon className="icon icon-remove" href="#svg_icon_remove"/>
                    {t('report_detail.delete')}</a>
            </div>
            <div onClick={togglePopup}>
                <SvgIcon className="icon icon-more" href="#svg_icon_more"/>

            </div>
            <div className="request-files-item-pic " ><img src={imgData}/></div>
            <div className="request-files-item-info pointer" title={t('title.download')} onClick={(e) => clickDownloadHandler(props.item.id, e)}>
                <div>{moment(props.item.createdAt).format('DD.MM.YYYY')}</div>
                <div ref={nameRef}><TextCutter text={props.item.name} parentWidth={width} options={[{
                    maxLength: 33,
                    maxWidth: 274,
                    minWidth: 48,
                    symbolSize: 12
                }]}/></div>
                <div>({props.item.size})</div>
            </div>
        </div>
        <AttachFileModal isOpen={isOpenAttachFile} onClose={toggleAttachFile} onSubmit={toggleSubmitEditAttachFile} objectId={props.item} isReport={props.isReport}/>
        <DeleteAttachmentModal isOpen={isOpenDeleteModal} onClose={toggleDeleteModal} onSubmit={toggleSubmitDeleteFile} />
        <AttachedImagePreview size={props.item.size} isOpen={isOpenImagePreview} onClose={toggleImagePreviewModal} itemId={props.item.id}/>
        </Fragment>
    )
};

export default AttachmentFilesPanel;
