import { BaseMapper } from "./base.mapper";
import { ApprovalEmployee } from "infrastructure/interfaces";
import { get } from "lodash";
import moment from 'moment';

export class ApprovalEmployeeMapper extends BaseMapper<ApprovalEmployee, any> {
  responseToEntity(response: any): ApprovalEmployee {
    let resolutionDate = get(response, 'completedAtFact', null);
    return {
      id: get(response, 'id', 0),
      employee: {
        ru: get(response, 'occupation.employee.ru', ''),
        en: get(response, 'occupation.employee.en', '')
      },
      occupation: {
        ru: get(response, 'occupation.name.ru', ''),
        en: get(response, 'occupation.name.en', '')
      },
      //approvedByName: null,
      //completedAtPlan: "2023-06-11T10:01:49.8283985",
      //completedAtFact: null,
      resolution: get(response, 'resolution', ''),
      costCenterCode: "CC_DFLT1", 
      //readAt: "2020-09-14T10:01:54.9993469"
      approvedByName: {
        ru: get(response, 'approvedByName.ru', ''),
        en: get(response, 'approvedByName.en', ''),
      },
      resolutionDate: resolutionDate ? moment.utc(get(response, 'completedAtFact', null), "YYYY-MM-DDThh:mm:ss").toDate() : undefined,
    }
  };
}