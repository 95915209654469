import React, { Fragment, useState, useRef, useEffect } from 'react';
import Dropdown from 'app/component/dropdown/Dropdown';
import Radio from 'app/component/radio/Radio';
import { useSelector, useDispatch } from 'react-redux';
import EmployeeAutocomplete, { EmployeeAutocompleteOptionType } from 'app/component/autocomplete/EmployeeAutocomplete';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { hideFilter, showFilter } from 'store/common/actions';
import { updateDownlandJournalDetail, UpdateDownlandJournalFilters } from 'store/journal/actions';
import UploadButton from 'app/component/buttons-control/UploadButton';
import {getCommonFilterState} from "../../../store/selectors";

interface JournalDetailFilterProps extends RouteComponentProps<{id: string}> {}

enum TRANSACTION_TYPE {
    ALL = 'all',
    DEBIT = 40,
    CREDIT = 50,
    NDS = 0
}

const JournalDetailFilter: React.FC<JournalDetailFilterProps> = (props) => {
    const { t } = useTranslation();

    let [transactionType, setTransactionType] = useState(TRANSACTION_TYPE.ALL);
    let [employee, setEmployee] = useState({} as EmployeeAutocompleteOptionType);
    let [numberAccount, setNumberAccount] = useState('');
    let [width, setWidth] = useState(0);

    let numberAccountRef = useRef({} as HTMLInputElement);
    let employeeRef = useRef({} as any);

    const dispatch = useDispatch();

    let filterState: boolean = useSelector(getCommonFilterState);

    let [isFilterOpen, setFilterOpen] = useState(filterState);
    let [filterTimer, setFilterTimer] = useState(null as unknown as NodeJS.Timeout);
    let [isClearingFilter, setClearingFilter] = useState(false);
    let [isInited, setInited] = useState(false);

    const resizeEventHander = (e: any) => {
        if (width !== window.innerWidth) {
            resizeHandler();
            setWidth(window.innerWidth);
        }
    }

    const resizeHandler = () => {
        if (window.innerWidth <= 959) {
            dispatch(hideFilter())
        } else {
            dispatch(showFilter())
        }
    }

    useEffect(() => {
        setFilterOpen(filterState)
        window.addEventListener('resize', resizeEventHander)
        return () => {
            window.removeEventListener('resize', resizeEventHander);
        }
    }, [filterState])

    useEffect(() => {
        dispatch(updateDownlandJournalDetail(+props.match.params.id, {}));
        resizeHandler();
        setInited(true);
    }, []);

    useEffect(() => {
        if (isInited) {
            filterHandler();
            setClearingFilter(false);
        }
    }, [employee, transactionType])

    useEffect(() => {
        if (isInited && !isClearingFilter) {
            clearTimeout(filterTimer);
            setFilterTimer(setTimeout(() => {
                filterHandler();
            }, 2000))
        }
    }, [numberAccount])

    const numberAccountChangeHandler = () => {
        setNumberAccount(numberAccountRef.current.value);
    }

    const employeeChooseHandler = (option: EmployeeAutocompleteOptionType) => {
        if (option.value !== employee.value) {
            setEmployee(option);
        }
    }

    const transactionTypeChangeHandler = (transaction: TRANSACTION_TYPE) => {
        setTransactionType(transaction);
    }

    const clearAllClickHandler = () => {
        setEmployee({} as EmployeeAutocompleteOptionType);
        employeeRef.current.clear();
        setNumberAccount('');
        setTransactionType(TRANSACTION_TYPE.ALL);
        setClearingFilter(true);
    }
    
    const filterHandler = () => {
        let params = {} as UpdateDownlandJournalFilters;

        if(transactionType != TRANSACTION_TYPE.ALL){
            params.postingKey = transactionType;
        }
        
        if(numberAccount.trim() != ''){
            params.lager = numberAccount;
        }

        if(employee && employee.value){
            params.employee = +employee.value;
        }
        dispatch(updateDownlandJournalDetail(+props.match.params.id, params));
    }

    const forceUpdateFilter = () => {
        clearTimeout(filterTimer);
        filterHandler();
    }

    const onEnterClickHandler = (event: any) => {
        if (event.key === 'Enter') {
            forceUpdateFilter();
        }
    }


    return (
        <Fragment>
            <div className="filters" style={isFilterOpen ? { display: 'block' } : { display: 'none' }}>
                <UploadButton />
                <div className="filters-wrap">
                    <Dropdown label={t('downloads.filter.by_type')}>
                        <Radio id={1} label={t('downloads.filter.all')} name={'radio1'} onClick={() => transactionTypeChangeHandler(TRANSACTION_TYPE.ALL)} checked={transactionType === TRANSACTION_TYPE.ALL} />
                        <Radio id={2} label={t('downloads.filter.debit')} name={'radio2'} onClick={() => transactionTypeChangeHandler(TRANSACTION_TYPE.DEBIT)} checked={transactionType === TRANSACTION_TYPE.DEBIT} />
                        <Radio id={3} label={t('downloads.filter.credit')} name={'radio3'} onClick={() => transactionTypeChangeHandler(TRANSACTION_TYPE.CREDIT)} checked={transactionType === TRANSACTION_TYPE.CREDIT} />
                        <Radio id={4} label={t('downloads.filter.without_VAT')} name={'radio4'} onClick={() => transactionTypeChangeHandler(TRANSACTION_TYPE.NDS)} checked={transactionType === TRANSACTION_TYPE.NDS} />
                    </Dropdown>
                    <Dropdown label={t('downloads.filter.by_account')}>
                        <div className="input-item">
                            <input ref={numberAccountRef} type='text' placeholder={t('downloads.filter.accountNumber')}
                                   onKeyPress={onEnterClickHandler}
                                   onChange={numberAccountChangeHandler} value={numberAccount} />
                        </div>
                    </Dropdown>
                    <Dropdown label={t('downloads.filter.by_employee')}>
                        <div className="input-item">
                            <div className="input-search">
                                <EmployeeAutocomplete ref={employeeRef} onChoose={employeeChooseHandler} placeholder={t('downloads.filter.enterSoname')} />
                            </div>
                        </div>
                    </Dropdown>
                    <Dropdown>
                        <div className="filter-btns-two m-t-15">
                            <a className="btn btn_gray width-100 pointer" onClick={clearAllClickHandler}>{t('filters.reset')}</a>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </Fragment>
    )
}

export default JournalDetailFilter;
