import ym from 'react-yandex-metrika';
const { gtag } = require("ga-gtag");


const analitiks = (eventId: string, params: {} = {}): void => {
    ym('reachGoal', eventId, params);
    gtag('event', eventId, params);
    
}

export default analitiks