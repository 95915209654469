import { Action } from "redux";
import { IAOList, IAO } from "infrastructure/interfaces";

export const REPORTS = {
  SET_AO_LIST: 'REPORTS/SET_AO_LIST',
  UPDATE_AO_LIST: 'REPORTS/UPDATE_AO_LIST',
  SET_DETAILED_AO: 'REPORTS/SET_DETAILED_AO',
  UPDATE_DETAILED_AO: 'REPORTS/UPDATE_DETAILED_AO',
  SET_AO_LIST_DOWNLOADED: 'REPORTS/SET_AO_LIST_DOWNLOADED',
  SET_AO_DETAIL_DOWNLOADED: 'REPORTS/SET_AO_DETAIL_DOWNLOADED'
}

export interface SetAOList extends Action {
  type: typeof REPORTS.SET_AO_LIST,
  payload: { advanceReportList: IAOList }
}

export interface SetDetailedAO extends Action {
  type: typeof REPORTS.SET_DETAILED_AO,
  payload: { advanceReportApplication: IAO }
}

export interface SetAOListDownloaded extends Action {
  type: typeof REPORTS.SET_AO_LIST_DOWNLOADED,
  payload: { isReportListDownloaded: boolean }
}

export interface SetAODetailDownloaded extends Action {
  type: typeof REPORTS.SET_AO_DETAIL_DOWNLOADED,
  payload: { isReportDetailDownloaded: boolean }
}

export type AOActions = SetAOList | SetDetailedAO | SetAOListDownloaded | SetAODetailDownloaded;