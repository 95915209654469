import Slidebar from 'app/component/slidebar/Slidebar';
import React, { useState, Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from '../svg-icon/SvgIcon';
import { NoticeMapper } from 'infrastructure/mappers'
import { Notice } from 'infrastructure/interfaces'
import NoticePanelItem from './NoticePanelItem';
import moment from 'moment';
import { includes, uniqBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { setNoticePanelOpen, setNoticePanelNewMessageCount } from 'store/common/actions';
import {getCommonNoticePanelIsOpen, getCommonNoticePanelNewMessagesCount} from "../../../store/selectors";
import {getNotificationsWithParams, updateNotifications, updateNotificationsById} from "../../../services/ApiService";

const NoticePanel: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isOpen = useSelector(getCommonNoticePanelIsOpen);
  const newNoticesCount = useSelector(getCommonNoticePanelNewMessagesCount);
  const dispatch = useDispatch();

  let [noticesGot, setNoticesGot] = useState(false);
  let [notices, setNotices] = useState([] as NoticePanelItem[]);
  let [noticesRaw, setNoticesRaw] = useState([] as Notice[]);
  let [noticesCount, setNoticesCount] = useState(0);
  let [modeAll, setModeAll] = useState(false);
  let [error, setError] = useState(false);
  let [errorMessage, setErrorMessage] = useState('');
  let [smallLoader, setSmallLoader] = useState(false);
  let [fullLoader, setFullLoader] = useState(false);
  let [scrollFinish, setScrollFinish] = useState(false);
  let [currentPage, setCurrentPage] = useState(1);

  const ref = useRef({} as any);

  const clickHandler = () => {
    dispatch(setNoticePanelOpen(false));
  };

  const getAllNotices = async (page: number, isNewNotices = false) => {
    const newNoticesParams = { params: { pageSize: 0, page: page, all: false, sort: 1 } };
    const allNoticesParams = { params: { pageSize: 10, page: page, all: true, sort: 1 } };
    let result = await getNotificationsWithParams(isNewNotices ? newNoticesParams : allNoticesParams);
    let noticeMapper = new NoticeMapper();
    if (result.headers.success) {
      if (page == 1) {
        if(result.status == 200){
          setNoticesRaw(noticeMapper.responsesToEntitys(result.data.data));
        }else {
          setNoticesRaw(noticeMapper.responsesToEntitys([]));
        }
      } else {
        if(result.status == 200){
          setNoticesRaw(uniqBy([...noticesRaw, ...noticeMapper.responsesToEntitys(result.data.data)], 'id'));
        }
      }
      if(result.status == 204){
        setScrollFinish(true);
      }
    } 
    setFullLoader(false);
    setSmallLoader(false);
  };

  const getNotices = async () => {
    setFullLoader(true);
    setScrollFinish(false);
    setCurrentPage(1);
    setStrollTop();

    if (modeAll) {
      await getAllNotices(1);
    } else {
      await getAllNotices(1, true);
    }
  };

  const getNextNotices = async () => {
    setSmallLoader(true);
    if (modeAll) {
      await getAllNotices(currentPage + 1);
    } else {
      await getAllNotices(currentPage + 1, true);
    }
    setCurrentPage(currentPage + 1);
  };

  const getAllNoticesCount = async () => {
    let result = await getNotificationsWithParams({ params: { CountOnly: true, all: false } });
    if (result.headers.success) {
      if (noticesCount != result.data.itemsCount) {
        setNoticesCount(result.data.itemsCount);
        await getNewNoticesCount();
      }
    }
  };

  const getNoticesCount = async () => {
    if (modeAll) {
      await getAllNoticesCount();
    } else {
      await getNewNoticesCount();
    }
  };

  const getNewNoticesCount = async () => {
    let result = await getNotificationsWithParams({ params: { CountOnly: true, all: false } });
    if (result.headers.success) {
      if (newNoticesCount != result.data.itemsCount) {
        dispatch(setNoticePanelNewMessageCount(result.data.itemsCount));
      }
    }
  };

  const setStrollTop = () => {
    let noticesElement: any;
    noticesElement = ref.current.querySelector('.simplebar-content');
    noticesElement.scrollTo(0,0);
  };

  const clickModeAll = () => {
    setModeAll(true);
  };

  const clickModeNew = () => {
    setModeAll(false);
  };

  const genLink = (object: Notice['object']) => {
    return '/' + object.logicalName + '/Detail/' + object.id;
  };

  const genType = (type: string) => {
    let messageProperty = ['NEWMESSAGE'];
    let linkProperty = ['FORAPPROVAL', 'APPROVED', 'DENIED', 'RECALL', 'ONREVISION', 'LEVELISACCOUNTANT'];

    if (includes(messageProperty, type)) {
      return 'message'
    }

    if (includes(linkProperty, type)) {
      return 'link'
    }

    return '';
  };

  const readOneNotice = async (id: number) => {
    if (id <= 0) { return }
    let result = await updateNotificationsById(id, { read: true });
    await getNewNoticesCount();
    await getAllNotices(1, true);
  };

  const readAllNotice = async () => {
    setFullLoader(true);
    let result = await updateNotifications({ read: true });

    await getNewNoticesCount();
    await getAllNotices(1, true);
  };

  const newNoticeClick = async(id: number) => {
    if (id <= 0) { return }
    await readOneNotice(id);
  };

  const readAllNoticeClick = async() => {
    if (newNoticesCount == 0) { return }
    await readAllNotice();
  };

  useEffect(() => {
    let timerID = setInterval(() => getNoticesCount(), 10000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  useEffect(() => {
    getNotices();
  }, [modeAll]);

  useEffect(() => {
    setNotices(noticesRaw.filter((item) => modeAll || !item.isRead).map((notice) => {
      return {
        id: notice.id,
        date: moment.utc(notice.date, "YYYY-MM-DDThh:mm:ss").local().locale(i18n.language as 'ru').format('DD MMMM ’YY HH:mm'),
        userNameShort: notice.userNameShort,
        title: notice.title,
        type: genType(notice.type),
        isRead: notice.isRead,
        message: {
          user: '@' + notice.message.user,
          text: notice.message.text
        },
        link: {
          name: t('notice.goTo', { object: t('notice.' + notice.object.logicalName) }),
          href: genLink(notice.object)
        }
      }
    }));
  }, [noticesRaw, i18n.language]);

  useEffect(() => {
    if (!noticesGot) {
      setNoticesGot(true);
      getNoticesCount()
    }

    let noticesElement: any;
    let scrollHandler: any;

    noticesElement = ref.current.querySelector('.simplebar-content');
    if (noticesElement) {
      scrollHandler = () => {
        if (!scrollFinish && !smallLoader && noticesElement && noticesElement.scrollTop + noticesElement.clientHeight  > noticesElement.scrollHeight - (noticesElement.scrollHeight * 0.2)) {
          getNextNotices();
        }
      };
      noticesElement.addEventListener('scroll', scrollHandler);
    }

    return (() => {
      if (noticesElement && scrollHandler) {
        noticesElement.removeEventListener('scroll', scrollHandler);
      }
    })
  });

  const onChange = () => {}

  return (
    <Fragment>
      {isOpen ? <div className="miss-click-handler" onClick={clickHandler} /> : ''}
      <div className={'history-request history-request-header' + (isOpen ? ' notice-open' : '')} ref={ref}>
        <div style={{ zIndex: 1000, position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', background: 'black', opacity: 0.1, display: fullLoader ? 'block' : 'none' }}/>
        <div className="filters-title">
          <span>{t('notice.title')}</span>
          <a className="close" onClick={clickHandler}>
            <SvgIcon className="icon icon-close" href="#svg_icon_close" />
          </a>
        </div>
        <div className="filters__info-nav">
          {modeAll ? <span className="pointer">{t('notice.all')}</span> : <a className="pointer" onClick={clickModeAll} style={{ marginRight: '20px' }}>{t('notice.all')}</a>}
          {modeAll
            ? <a className="pointer" onClick={clickModeNew}>{t('notice.notread')}{newNoticesCount > 0 ? newNoticesCount > 99 ? <span>99+</span> : <span>{newNoticesCount}</span> : null}</a>
            : <span className="pointer" style={{ marginRight: 'auto' }}>{t('notice.notread')}{newNoticesCount > 0 ? newNoticesCount > 99 ? <a><span>99+</span></a> : <a><span>{newNoticesCount}</span></a> : null}</span>
          }
          <label className="container-checkbox">
            <input type="checkbox" checked={newNoticesCount == 0} tabIndex={-1} onClick={readAllNoticeClick} title={t('notice.readAll')} onChange={onChange}/><span className="checkmark" title={t('notice.readAll')}/>
          </label>
        </div>
        <Slidebar className="history-request-items">
          {!error ? notices.map((noticeData, index) => {
            return <NoticePanelItem key={noticeData.id} notice={noticeData} onClick={newNoticeClick} />
          }) : null}
          {!modeAll && notices.length == 0 && !error ? <NoticePanelItem key={0} notice={{ id: 0, date: '', userNameShort: '', isRead: false, title: 'Непрочитанных уведомлений нет', type: 'nomessage' }} onClick={newNoticeClick} /> : null}
          {error ? <NoticePanelItem key={0} notice={{ id: 0, date: '', userNameShort: '', isRead: false, title: '', type: 'nomessage' }} onClick={newNoticeClick} /> : null}
          {smallLoader ? <NoticePanelItem key={0} notice={{ id: 0, date: '', userNameShort: '', isRead: false, title: errorMessage, type: 'loader' }} onClick={newNoticeClick} /> : null}
        </Slidebar>
        <a className="btn btn_black btn-close" onClick={clickHandler} style={{  }}>{t('notice.close')}</a>
        <div style={{ zIndex: 1000, position: 'absolute', top: 'calc(50% - 32px)', left: 'calc(50% - 32px)', display: fullLoader ? 'block' : 'none' }} className="lds-dual-ring"/>
      </div>
    </Fragment>
  );
}

export default NoticePanel;