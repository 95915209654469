import { ActionCreator } from "redux";
import { SetAgreementList, AGREEMENT, AgreementActions } from "./actionsTypes";
import { ApproveList, FilterParams } from "infrastructure/interfaces";
import { ApplicationState } from "store/reducers";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { ApproveListMapper } from "infrastructure/mappers";
import { showErrors } from "store/exception/actions";
import {getApprovalsByParams} from "../../services/ApiService";

export const setAgreementList: ActionCreator<SetAgreementList> = (agreementList: ApproveList, reloading: boolean) => ({
    type: AGREEMENT.SET_LIST,
    payload: { agreementList, reloading }
})

const getParams = (whoIsInPaperWork: any[], cities: any[]) => {
    let result = '';
    let statuses: string[] = [];
    let city: string[] = [];
    if (whoIsInPaperWork.length > 0) {
        whoIsInPaperWork.forEach(state => {
            statuses.push(`WhoIsInPaperWork=${state}`)
        });
    }
    if (cities.length > 0) {
        cities.forEach(item => {
            city.push(`CityId=${item}`)
        });
    }
    let resultArray = statuses.concat(city);
    if (resultArray.length > 0) {
        result += '?'
        result += resultArray.join('&');
    }
    return result;
}

export const updateAgreementList = (filterParams?: FilterParams, pageNumber: number = 1, reloading: boolean = false, itemsPerPage: number = 20): ThunkAction<Promise<void>, ApplicationState, any, AgreementActions> => {
    return async (dispatch: ThunkDispatch<ApplicationState, any, AgreementActions>, getState): Promise<void> => {
        !reloading && await dispatch(loaderLock());
        let approveListMapper = new ApproveListMapper();
        let params: any = {
            PageSize: itemsPerPage,
            Page: pageNumber,
        }
        if (filterParams) {
            params.Journey = filterParams.Journey || null;
            params.Id = filterParams.Id || null;
            params.Number = filterParams.Number || null;
            params.StartOn = filterParams.StartOn || null;
            params.EndOn = filterParams.EndOn || null;
            params.EmployeeId = filterParams.EmployeeId || null;
            params.ExpenseApplicationId = filterParams.ExpenseApplicationId || null;
            params.Type = filterParams.Type || null;
            //params.WhoIsInPaperWork = filterParams.WhoIsInPaperWork;
        }
        let response = await getApprovalsByParams(filterParams ? getParams(filterParams.WhoIsInPaperWork || [0,1], filterParams.CityId || []) : getParams([0,1], []), {
            params: params
        })
        if(response.headers.success) {
            dispatch(setAgreementList(approveListMapper.responseToEntity(response.data), reloading));
        } else {
            await dispatch(showErrors({ code: 'update_agreement_list', message: `Не удалось загрузить список согласований`}));
        }
        !reloading && await dispatch(loaderUnlock());
    }
}
