import React, {Fragment, useState, useRef, useEffect} from 'react';
import FileSaver from "file-saver";
import {useTranslation} from "react-i18next";
//component
import AttachedImagePreview from 'app/component/modal/AttachedImagePreview';
//common
import TextCutter from '../text-cutter/TextCutter';
import {getAttachmentsContent} from "../../../services/ApiService";


interface ExpenseAttachmentsProps {
    attachFile: any
    width: number
    isLast?: boolean
}
const ExpenseAttachments: React.FC<ExpenseAttachmentsProps> = (props) => {
    const { t, i18n } = useTranslation();
    let [isOpenImagePreview, setOpenImagePreview] = useState(false);

    const clickDownloadHandler = async (id: number) => {
        let downland = await getAttachmentsContent(id, {responseType: 'blob'});
        let contentDisposition = downland.headers['content-disposition'];
        let fileName = contentDisposition.slice(contentDisposition.lastIndexOf('\'') + 1);
        FileSaver.saveAs(downland.data, decodeURI(fileName))
    };

    const toggleImagePreviewModal = () => {
        setOpenImagePreview(!isOpenImagePreview);
    };

    const isFormatForDownload = (name : string) => {
        const format: any = name.match(/[0-9a-z]+$/i);
        switch (format[0]) {
            case 'docx' :
                return t('title.download');
            case 'xlsx' :
                return t('title.download');
            case 'doc' :
                return t('title.download');
            case 'xls' :
                return t('title.download');
            case 'pdf' :
                return t('title.download');
            default :
                return t('title.open');
        }
    };

    const clickHandlerPreviewOrDownload = (name : string, id: number) => {
        const format: any = name.match(/[0-9a-z]+$/i);
        switch (format[0]) {
            case 'docx' :
                return (e: any) => clickDownloadHandler(id);
            case 'xlsx' :
                return (e: any) => clickDownloadHandler(id);
            case 'doc' :
                return (e: any) => clickDownloadHandler(id);
            case 'xls' :
                return (e: any) => clickDownloadHandler(id);
            case 'pdf' :
                return (e: any) => clickDownloadHandler(id);
            default :
                return toggleImagePreviewModal
        }
    }

    return (
        <Fragment>
         <p style={{ marginTop: '6px'}}>
        <b>&nbsp;<a className="pointer" title={isFormatForDownload(props.attachFile.name)} key={props.attachFile.id} onClick={clickHandlerPreviewOrDownload(props.attachFile.name, props.attachFile.id)}>
            <TextCutter text={props.attachFile.name} maxLength={95} parentWidth={props.width} options={[{
                maxLength: 85,
                maxWidth: 850,
                minWidth: 636,
                symbolSize: 6
            },{
                maxLength: 55,
                maxWidth: 635,
                minWidth: 466,
                symbolSize: 6
            },
            {
                maxLength: 60,
                maxWidth: 465,
                minWidth: 0,
                symbolSize: 6
            }]}/>
            </a>
            {!props.isLast && (<span>,</span>)}</b>
         </p>
         <AttachedImagePreview isOpen={isOpenImagePreview} onClose={toggleImagePreviewModal} itemId={props.attachFile.id}/>
        </Fragment>
    );
};

export default ExpenseAttachments;