import React, { useState, useEffect, useRef } from 'react';
import ModalContainer from './ModalContainer';
import { Transaction } from 'infrastructure/interfaces';
import { useTranslation } from 'react-i18next';
import { formatter, getCurrencieCode, optional } from 'app/utils';
import Select, { SelectOptionType } from '../select/Select';
import { get } from 'lodash';
import { showErrors } from 'store/exception/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store/reducers';
import {loaderLock, loaderUnlock, updatePostings, updateTransactionsUpdateFieldPermissions} from 'store/common/actions';
import analitiks from 'services/AnaliticsService';
import { isIE } from 'react-device-detect';
import { TRANSACTION_PROPETIES } from 'infrastructure/enum/object-properties.enum';
import CustomProperty from '../custom-property/CustomProperty';
import LedgerAccountAutocomplete from "../autocomplete/LedgerAccountAutocomplete";
import {getCommonUserDetail} from "../../../store/selectors";
import {
    getFieldPermissionsUpdateTransactions,
    getTaxCodes,
    updateApiTransactionsById
} from "../../../services/ApiService";
import {TRANSACTIONS_UPDATE_FIELD_PERMISSIONS} from "../../../infrastructure/enum/field-permissions.enum";

interface EditPostingModalProps {
    isOpen: boolean,
    onClose: () => void,
    transaction: Transaction,
    onSubmit: () => void
}

const EditPostingModal: React.FC<EditPostingModalProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(getCommonUserDetail);
    const transactionsUpdateFieldPermissions = useSelector((state: ApplicationState) => state.common.transactionsUpdateFieldPermissions);

    let customPropertyRef = useRef({} as any);

    const currenciesOptions = [
        { value: 0, text: getCurrencieCode('usd') },
        { value: -1, text: getCurrencieCode('rub') }
    ]

    let [amountMST, setAmountMST] = useState(-1);
    let [ledgerAccount, setLedgerAccount] = useState({text: '', value: ''});
    let [isLedgerAccountValid, setIsLedgerAccountValid] = useState(true);
    let [taxCode, setTaxCode] = useState('');
    let [taxCodeIndex, setTaxCodeIndex] = useState(0);
    let [taxOptions, setTaxOptions] = useState([] as any[]);

    let [editPermissions, setEditPermissions] = useState([] as string[]);
    let [createPermissions, setCreatePermissions] = useState([] as string[]);
    let [readPermissions, setReadPermissions] = useState([] as string[]);

    let [permissionsList, setPermissionsList] = useState([] as string[]);
    let [permissions, setPermissions] = useState({
        state: true,
        transactionBatchId: true,
        loadedAt: true,
        docDate: true,
        docNumber: true,
        postingDate: true,
        businessTargetId: true,
        txText: true,
        orderNumber: true,
        expenseItemId: true,
        amount: true,
        currencyId: true,
        currencyRate: true,
        amountRub: true,
        ledgerOrVendor: true,
        postingKey: true,
        taxCodeId: true,
        referenceField: true,
        expenseId: true,
        employeeId: true,
        companyId: true,
        operationType: true,
        DC: true,
    });

    let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

    const getTaxOptionIndexByCode = (code: string): number => {
        for (let index = 0; index < taxOptions.length; index++) {
            if (taxOptions[index].value == code) {
                return index;
            }
        }
        return 0
    }

    const ledgerAccountsChangeHandler = (option: any) => {
        setLedgerAccount(option);
        setIsLedgerAccountValid(true);
    }

    const taxCodeChangeHandler = (option: SelectOptionType) => {
        setTaxCode(option.value as string);
        setTaxCodeIndex(getTaxOptionIndexByCode(option.value as string));
    }

    const amountMSTRef = useRef({} as any);

    const amountMSTChangeHandler = () => {
        setAmountMST(amountMSTRef.current.value);
    }

    useEffect(() => {
        setTaxCodeIndex(getTaxOptionIndexByCode(taxCode));
    }, [taxCode, taxOptions]);

    useEffect(() => {
        if (props.isOpen) {
            setIsLedgerAccountValid(true);
            setAmountMST(props.transaction.amountRub);
            setTaxCode(props.transaction.taxCode.code);
            updateTaxCode();
            getUpdatePermissions();
            dispatch(updateTransactionsUpdateFieldPermissions());
            setReadPermissions(props.transaction.properties);
            setLedgerAccount(props.transaction.ledgerAccount ? {
                text: props.transaction.ledgerAccount,
                value: props.transaction.ledgerAccount
            } : {text: '', value: ''});
            customPropertyRef.current.build();
        }
    }, [props.isOpen]);

    const getUpdatePermissions = async () => {
        let result = await getFieldPermissionsUpdateTransactions(props.transaction.id);
        if (result.headers.success) {
            let permissions = result.data;
            setEditPermissions(permissions);
        }
    };

    const errorMessage = {
        ru: 'Введено недопустимое значение. Новое значение суммы должно быть не больше текущего значения',
        en: 'Entered value is invalid. New value of the expense amount must be less than current value'
    }

    const editSubmit = async () => {
        if (!customPropertyRef.current.validate()) {
            return;
        }
        if (!ledgerAccount.value) {
            setIsLedgerAccountValid(false);
            return;
        } else {
            setIsLedgerAccountValid(true);
        }

        dispatch(loaderLock());

        let data = {
            id: props.transaction.id,
            action: "Put",
            ledgerOrVendor: ledgerAccount.value,
            taxCodeId: taxOptions[taxCodeIndex].id,
            objectId: {
                id: props.transaction.objectId.id,
                logicalName: props.transaction.objectId.logicalName,
                name: {
                    ru: props.transaction.objectId.name.ru,
                    en: props.transaction.objectId.name.en
                }
            }
        }

        let response = await updateApiTransactionsById(props.transaction.id, data);
        if (response.headers.success) {
            await customPropertyRef.current.edit()
            analitiks('EditingPostings', { transactionId: props.transaction.id, employeeId: user.id, occupationId: user.occupations[0].occupationId, companyId: user.occupations[0].company.id })
            dispatch(updatePostings(props.transaction.objectId.id, props.transaction.objectId.logicalName, {}));
            props.onClose();
        } else {
            dispatch(showErrors({ code: 'edit_transaction', message: 'Не удалось отредактировать проводку' }));
        }
        dispatch(loaderUnlock());
    }

    const updateTaxCode = async () => {
        dispatch(loaderLock());
        let response = await getTaxCodes();
        if (response.headers.success) {
            setTaxOptions(response.data.data.map((item: any) => {
                return {
                    value: get(item, 'code', 0),
                    text: get(item, 'name.' + i18n.language, ''),
                    id: get(item, 'id', 0),
                    recoveryIndicator: get(item, 'recoveryIndicator', '')
                }
            }));
        } else {

        }
        dispatch(loaderUnlock());
    }

    const generateClassName = (properties: string): string => {
        let classes = [];
        !readPermissions.includes(properties) && classes.push('d-none');
        !editPermissions.includes(properties) && classes.push('disabled');
        return classes.join(' ');
    }

    const isHighestModal = (isHighest: boolean) => {
        setTabIndexDisabled(!isHighest);
    }

    return (
        <ModalContainer destroy isOpen={props.isOpen} highestModal={isHighestModal} overlayClick={props.onClose}>
            <div className="box-modal" id="add-reassignment">
                <div className="box-modal_close arcticmodal-close" onClick={props.onClose}></div>
                <div className="box-modal-title">{t('modals.edit_posting.edit')}</div>
                <div className="box-modal-content">
                    <div className="box-modal-form">
                        <div className="box-modal-form-block" style={{marginBottom: '0px'}}>
                            <div className="input-item-row">
                                <div className={`input-item w-150 ${generateClassName(TRANSACTION_PROPETIES.TRANSACTION_BATCH_ID)}`}>
                                    <label className="input-label">ID<i className="input-required">*</i></label>
                                    <input className="input" type="text" value={props.transaction.id} disabled={generateClassName(TRANSACTION_PROPETIES.TRANSACTION_BATCH_ID).includes('disabled')} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                </div>
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.EMPLOYEE) ? <div className={`input-item`}>
                                    <label className="input-label">
                                        {t('modals.edit_posting.employee')}<i className="input-required">*</i></label>
                                    <input className="input" type="text"
                                           value={get(props.transaction, `employeeName.${i18n.language as 'en' | 'ru'}`, '')}
                                           tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                </div> : null}
                            </div>
                            <div className="input-item-row">
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.DOC_DATE) ? <div className={`input-item w-150`}>
                                    <label className="input-label">
                                        {t('modals.edit_posting.created_date')}<i className="input-required">*</i></label>
                                    <input className="input" type="text"
                                           value={formatter('DD MMMM YYYY', i18n.language, props.transaction.createdAt)}
                                           tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                </div> : null}
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.DOC_NUMBER) ? <div className={`input-item w-315`}>
                                    <label className="input-label">
                                        {t('modals.edit_posting.number')}</label>
                                    <input className="input" type="text" value={props.transaction.number}
                                           tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                </div> : null}
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.POSTING_DATE) ? <div className={`input-item w-150`}>
                                    <label className="input-label">
                                        {t('modals.edit_posting.posting_date')}<i className="input-required">*</i></label>
                                    <input className="input" type="text"
                                           value={formatter('DD MMMM YYYY', i18n.language, props.transaction.postingDate)}
                                           tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                </div> : null}
                            </div>
                            <div className="input-item-row" >
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.BUSINESS_TARGET) ? <div className={`input-item`}>
                                    <label className="input-label">
                                        {t('modals.edit_posting.goal')}</label>
                                    <input className="input" type="text"
                                           value={get(props.transaction, `businessTargetName.${i18n.language as 'en' | 'ru'}`, '')}
                                           tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                </div> : null}
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.EXPENSE_ITEM) ? <div className={`input-item`}>
                                    <label className="input-label">
                                        {t('modals.edit_posting.expense_item')}</label>
                                    <input className="input" type="text"
                                           value={get(props.transaction, `expenseItem.name.${i18n.language as 'en' | 'ru'}`, '')}
                                           tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                </div> : null}
                            </div>
                            <div className="input-item-row" style={{marginBottom: '-25px'}}>
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.CURRENCY) ? <div className={`input-item w-81`} style={{ width: isIE ? '15%' : '100%' }}>
                                    <label className="input-label">
                                        {t('modals.edit_posting.currency')}<i className="input-required">*</i></label>
                                    <Select options={currenciesOptions} defaultValue={currenciesOptions[1]}/>
                                </div> : null}
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.AMOUNT) ? <div className={`input-item w-150 w-min-150`} style={{ width: isIE ? '20%' : '100%' }}>
                                    <label className="input-label">
                                        {t('modals.edit_posting.amount', { currency: currenciesOptions[1].text })}<i className="input-required">*</i></label>
                                    <input className="input" type="number" value={props.transaction.amount} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                </div> : null}
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.CUR_RATE) ? <div className={`input-item w-81`} style={{ width: isIE ? '15%' : '100%' }}>
                                    <label className="input-label">{t('modals.edit_posting.course', { currency: currenciesOptions[1].text })}<i className="input-required">*</i></label>
                                    <input className="input" type="number" value={props.transaction.curRate} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                    <div className="descr">{t('modals.edit_posting.at_date_posting')}</div>
                                </div> : null}
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.AMOUNT_RUB) ? <div className={`input-item`} style={{ width: isIE ? '20%' : '100%' }}>
                                    <label className="input-label">{t('modals.edit_posting.amount_rub', {currency: getCurrencieCode('rub')})}</label>
                                    <input className="input" type="number" onChange={amountMSTChangeHandler} ref={amountMSTRef} value={amountMST} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                </div> : null}
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.LEDGER_OR_VENDOR) ? <div className={`input-item ${isLedgerAccountValid ? '' : 'error'}`} style={{ width: isIE ? '23.5%' : '100%' }}>
                                    <label className="input-label">{t('modals.edit_posting.account')}<i className="input-required">*</i></label>
                                    <LedgerAccountAutocomplete defaultText={ledgerAccount.text} onChoose={ledgerAccountsChangeHandler} transactionId={props.transaction.id} tabIndex={-1}/>
                                </div> : null}
                            </div>
                        </div>
                        <div className="box-modal-form-block">
                            <div className="input-item-row">
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.POSTING_KEY) ? <div className={`input-item w-150`} style={{ width: isIE ? '25%' : '100%' }}>
                                    <label className="input-label">
                                        {t('modals.edit_posting.type')}<i className="input-required">*</i></label>
                                    <input className="input" type="text" value={optional<string>(t('transaction.postingKey.' + props.transaction.postingKey), '')}
                                           tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                </div> : null}
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.TAX_CODE) ? <div className={`input-item`} style={{ width: isIE ? '25%' : '100%' }}>
                                    <label className="input-label">
                                        {t('modals.edit_posting.tax')}<i className="input-required">*</i></label>
                                    <Select options={taxOptions} defaultValue={taxOptions[taxCodeIndex]} onChoose={taxCodeChangeHandler}/>
                                </div> : null}
                                {transactionsUpdateFieldPermissions.includes(TRANSACTIONS_UPDATE_FIELD_PERMISSIONS.EXPENSE) ? <div className={`input-item`} style={{ width: isIE ? '25%' : '100%'}}>
                                    <label className="input-label">
                                        {t('modals.edit_posting.recovery_indicator')}<i className="input-required"></i></label>
                                    <input className="input" type="text" value={taxOptions[taxCodeIndex] != undefined ? taxOptions[taxCodeIndex].recoveryIndicator : null}
                                           tabIndex={isTabIndexDisabled ? -1 : 0}/>
                                </div> : null}
                            </div>
                        </div>

                        <CustomProperty
                            id={props.transaction.id}
                            isEdit={true}
                            isTransaction={true}
                            ref={customPropertyRef}
                            permissions={editPermissions}
                            tabIndex={isTabIndexDisabled ? -1 : 0}
                        />

                    </div>
                </div>
                <div className="box-modal-footer">
                    <a className="arcticmodal-close btn btn_black pointer" onClick={props.onClose} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.edit_posting.cancel')}</a>
                    <a className="btn-expense btn_green pointer" onClick={editSubmit} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('modals.edit_posting.complete')}</a></div>
            </div>
        </ModalContainer>
    )
}

export default EditPostingModal;
