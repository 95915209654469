import { Action } from 'redux';

export const EXCEPTION = {
  ADD_EXCEPTION: 'EXCEPTION/ADD_EXCEPTION',
  OPEN_EXCEPTION_MODAL: 'EXCEPTION/OPEN_MODAL',
  CLOSE_EXCEPTION_MODAL: 'EXCEPTION/CLOSE_MODAL',
  ADD_ERROR: 'EXCEPTION/ADD_ERROR',
  ADD_INFO: 'EXCEPTION/ADD_INFO',
  CLOSE_ERROR_MODAL: 'ERROR/CLOSE_MODAL',
  CLOSE_INFO_MODAL: 'ERROR/CLOSE_INFO_MODAL'
}

export interface Exception {
  id?: number,
  code: string,
  message: string
}

export interface Info {
  id?: number,
  code: string,
  message: string
}

export interface AddException extends Action {
  type: typeof EXCEPTION.ADD_EXCEPTION;
  payload: { exception: Exception};
}

export interface OpenExceptionModal extends Action {
  type: typeof EXCEPTION.OPEN_EXCEPTION_MODAL;
}

export interface CloseExceptionModal extends Action {
  type: typeof EXCEPTION.CLOSE_EXCEPTION_MODAL;
}

export interface AddError extends Action {
  type: typeof EXCEPTION.ADD_ERROR;
  payload: { exception: Exception};
}

export interface AddInfo extends Action {
  type: typeof EXCEPTION.ADD_INFO;
  payload: { info: Info};
}

export interface CloseErrorModal extends Action {
  type: typeof EXCEPTION.CLOSE_ERROR_MODAL;
  payload: { errorId: number};
}

export interface CloseInfoModal extends Action {
  type: typeof EXCEPTION.CLOSE_INFO_MODAL;
  payload: { infoId: number};
}

export type ExceptionActions = AddException | OpenExceptionModal | CloseExceptionModal | CloseErrorModal | AddError;