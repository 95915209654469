import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import './CityAutocomplete.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {getCities} from "../../../services/ApiService";

export interface CityAutocompleteProps {
    onChoose?: (option: CityAutocompleteOptionType) => void,
    onChange?: (text: string) => void,
    defaultText?: string,
    placeholder?: string,
    readOnly?: boolean,
    ref?: any,
    tabIndex?: -1 | 0
}

export interface CityAutocompleteOptionType {
    value: number,
    text: string
}

const CityAutocomplete: React.FC<CityAutocompleteProps> = React.forwardRef((props:CityAutocompleteProps, ref) => {
    let { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    let [isOpen, setOpen] = useState(false);
    let [isLoading, setLoading] = useState(false);
    let [inputValue, setInputValue] = useState(props.defaultText ? props.defaultText : '');
    let [citys, setCitys] = useState([] as CityAutocompleteOptionType[]);
    let [error, setError] = useState(false);

    let inputRef = useRef({} as HTMLInputElement);

    useEffect(() => {
        setInputValue(props.defaultText ? props.defaultText : '');
    }, [props.defaultText])

    const optionClickHandler = (option: CityAutocompleteOptionType) => {
        setInputValue(option.text);
        setOpen(false);
        if (props.onChoose) props.onChoose(option);
    }

    const changeInput = () => {
        setInputValue(inputRef.current.value);
        if (props.onChange) {
            props.onChange(inputRef.current.value);
        }
        if (inputRef.current.value.length > 2) {
            getCity();
        } else {
            setCitys([] as CityAutocompleteOptionType[]);
            setOpen(false);
        }
    }

    const missClickHandler = () => {
        if (isOpen) {
            setOpen(false)
        }
     };

    const getCity = async () => {
        setCitys([] as CityAutocompleteOptionType[]);
        setOpen(true);
        setLoading(true);
        setError(false);

        try {
            let result = await getCities({ params: { Name: inputRef.current.value } })
            setCitys(result.data.data.map((item: any) => { return { value: item.id, text: item.name[i18n.language as 'ru'] } }));
        } catch (e){
            setError(true);
        }

        setLoading(false);
    }

    useImperativeHandle(ref, () => ({
        clear: () => {
            setInputValue('');
        }
    }))

    return (
        <div className={'select ' + (isOpen ? 'active' : '')}>
             {isOpen ? <div className="miss-click-handler" onClick={missClickHandler}/> : ''}
            <input className="input" type="text" ref={inputRef} value={inputValue} onChange={changeInput} placeholder={props.placeholder ? props.placeholder : ''} readOnly={props.readOnly} tabIndex={props.tabIndex}/>
            <div className="select-content">
                {citys.length > 0 ? citys.map((option, index) => {
                    return <div className="select-content-item" key={index} onClick={() => optionClickHandler(option)}>{option.text}</div>
                }) : null}
                {!error && !isLoading && citys.length === 0 ? <div className="select-content-item" >{t('create_expense.no_city_found')} </div>
                    : null}
                {error ? <div className="select-content-item" >{t('create_expense.search_error')} </div>
                : null}
                {isLoading ? <div className="select-content-item" ><div className="lds-ellipsis" style={{ marginLeft: 'calc(50% - 30px)' }}><div></div><div></div><div></div><div></div></div></div> 
                : null}
            </div>
        </div>
    );
});

export default CityAutocomplete;