import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import analitiks from 'services/AnaliticsService';
import JournalPage from '../../component/journal-page/JournalPage';
import {getCommonUserDetail} from "../../../store/selectors";

const JournalReport:React.FC = () => {
    
    const user = useSelector(getCommonUserDetail);
    
    useEffect(() => {
        analitiks('JournalAOWithPostings', {employeeId: user.id, occupationId: user.occupations[0].occupationId, companyId: user.occupations[0].company.id})
    }, [])
    
    return (
        <JournalPage isAdvance={true}/>
    )
}

export default JournalReport;
