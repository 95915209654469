import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Config, { ConfigTypes } from 'services/ConfigService';
import Radio from 'app/component/radio/Radio';
import Select, { SelectOptionType, SelectRef } from 'app/component/select/Select';
import ModalContainer from 'app/component/modal/ModalContainer';
import { ApplicationState } from 'store/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { updateExpenseType, updateBusinessGoal, setBusinessGoal as setBusinessGoalAction, loaderLock, loaderUnlock } from 'store/common/actions';
import { showErrors } from 'store/exception/actions';
import { ExpenseType, TravelDates } from 'infrastructure/interfaces';
import { nameFormatter, NameFormats, getNextKey, toDate, returnDateWithoutUTC, betweenTwoDates } from 'app/utils';
import _, { get, sum, flatten, result } from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { updateApplicationForExpenseDetail } from 'store/applicationForExpense/actions';
import Validator from 'app/component/validator/Validator';
import EmployeeAutocomplete, { EmployeeAutocompleteOptionType } from '../autocomplete/EmployeeAutocomplete';
import { ROLE_TYPE, OCCUPATION_ROLE_TYPE } from 'infrastructure/enum/user-role.enum';
import Road, { RoadObj } from './Road';
import CreateOrderModal from './CreateOrderModal';
import moment from 'moment';
import analitiks from 'services/AnaliticsService';
import { isIE } from 'react-device-detect';
import { EXPENSE_APPLICATION_PROPETIES } from 'infrastructure/enum/object-properties.enum';
import CustomProperty from '../custom-property/CustomProperty';
import {
  getApplicationForExpenseDetail,
  getCommonBusinessGoals,
  getCommonExpenseTypes,
  getCommonUserDetail
} from "../../../store/selectors";
import {
  deleteExpensesById,
  getAssistancesById,
  getFieldPermissionsCreateExpenseApplications,
  getFieldPermissionsUpdateExpenseApplications,
  getOccupationsShortByEmployeeId,
  postExpenseApplications,
  updateExpensesById, updatePerDiemCalculations
} from "../../../services/ApiService";

interface CreateExpenseModal extends RouteComponentProps<any> {
  isOpen: boolean,
  onClose: (isSimpleClose?: boolean) => void,
  editId?: number,
  hasNext?: boolean
}

interface ExpenseApplications {
  applicationTypeId?: number,
  businessTargetId: number,
  description?: string,
  businessTripOption?: {
    departureFromCityId: number,
    startOn: Date | null,
    arrivalToCityId: number,
    endOn: Date | null,
    personalDaysNumber?: number,
    applicationTravelDates?: { startDate: Date, endDate: Date }[],
    businessTripDestination?: {
      cityId: number,
      arrivalOn: Date | null,
      leaveOn: Date | null,
      ordinal: number
    }[]
  }
  assigneeEmployeeId?: number
  occupationId?: number
}

const CreateExpenseModal: React.FC<CreateExpenseModal> = (props) => {
  let { t, i18n } = useTranslation();

  const expenseTypes = useSelector(getCommonExpenseTypes);
  const applicationForExpenseDetail = useSelector(getApplicationForExpenseDetail);
  const businessGoalOptions = useSelector(getCommonBusinessGoals);
  const userDetail = useSelector(getCommonUserDetail);

  const dispatch = useDispatch();
  let [editMode, setEditMode] = useState(false);
  let [multiRoadMode, setMultiRoadMode] = useState(false);
  let [isExternal, setIsExternal] = useState(false);

  let [expenseType, setExpenseType] = useState(11);
  let [expenseTypeCode, setExpenseTypeCode] = useState('General');
  let [description, setDescription] = useState('');
  let [businessGoal, setBusinessGoal] = useState({} as SelectOptionType);
  let [businessGoalOnlyOne, setBusinessGoalOnlyOne] = useState(false);
  let [assistance, setAssistance] = useState({ text: '', value: 0 });
  let [responsible, setResponsible] = useState({ text: '', value: 0 });
  let [employeeAutocompleteReadOnly, setEmployeeAutocompleteReadOnly] = useState(false);
  let [roads, setRoads] = useState([] as RoadObj[]);
  let [bookerAndOwner, setBookerAndOwner] = useState(false);
  let [isShowDailyRenew, setShowDailyRenew] = useState(false);
  let [assistanceOptions, setAssistanceOptions] = useState([] as SelectOptionType[]);
  let [responsibleOccupation, setResponsibleOccupation] = useState({} as SelectOptionType);
  let [responsibleOccupationOptions, setResponsibleOccupationOptions] = useState([] as SelectOptionType[]);
  let [creatorOccupationOptions, setCreatorOccupationOptions] = useState([] as SelectOptionType[]);
  let [occupation, setOccupation] = useState({ value: 0, text: '' } as SelectOptionType);
  let [selectedOccupation, setSelectedOccupation] = useState({} as any);
  let [occupationSelected, setOccupationSelected] = useState(false);
  let [multiOccupations, setMultiOccupations] = useState(false);
  let [editPermissions, setEditPermissions] = useState([] as string[]);
  let [createPermissions, setCreatePermissions] = useState([] as string[]);
  let [readPermissions, setReadPermissions] = useState([] as string[]);
  let [assigneeDetail, setAssigneeDetail] = useState({ userId: 0, occupationId: 0, companyId: 0 });
  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);


  let descriptionRef = useRef({} as HTMLInputElement);
  let businessGoalRef = useRef<SelectRef>({} as SelectRef);
  let assistanceRef = useRef<SelectRef>({} as SelectRef);
  let creatorOccupationRef = useRef<SelectRef>({} as SelectRef);
  let customPropertyRef = useRef({} as any);

  let businessGoalValidatorRef = useRef({} as any);
  let assistanceValidatorRef = useRef({} as any);
  let responsibleValidatorRef = useRef({} as any);
  let creatorOccupationValidatorRef = useRef({} as any);
  let responsibleOccupationRef = useRef({} as any);
  let dataRef = useRef({} as any)

  let employeeRef = useRef({} as any);
  let arrayExpenseTypes = useRef<Array<any>>();

  useEffect(() => {
    if (props.isOpen) {
      setSelectedOccupation({} as any);
      setOccupationSelected(false);
      setMultiOccupations(false);
      setAssistanceOptions([]);
      setResponsibleOccupationOptions([]);
      if (props.editId) {
        //getEditPermissions();
        initEditMode();
        if (applicationForExpenseDetail && applicationForExpenseDetail.properties) {
          setReadPermissions(applicationForExpenseDetail.properties);
        }
      } else {
        if (userDetail.occupations.length > 1) {
          setMultiOccupations(true);
        } else {
          //getCreatePermissions();
          initClearMode();
          creatorOccupationtChooseHandler({ text: '', value: userDetail.occupations[0].occupationId })
          //initAssistanceOptions(userDetail.occupations[0].occupationId);
          setAssigneeDetail({
            userId: userDetail.id,
            occupationId: userDetail.occupations[0].occupationId,
            companyId: userDetail.occupations[0].company.id
          });

          dispatch(updateExpenseType(userDetail.occupations[0].company.id));
        }
      }

      initCreatorOccupationOptions(userDetail.occupations);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (_.isEqual(expenseTypes, arrayExpenseTypes.current)) {
      return;
    }
    arrayExpenseTypes.current = expenseTypes;
    if (expenseTypes && expenseTypes.length > 0) {
      let expenseTypeGeneral = {} as ExpenseType;
      expenseTypes.forEach((item) => {
        if (item.code === 'General') {
          expenseTypeGeneral = item;
        }
      });
      if (!expenseTypeGeneral.id && expenseTypes.length > 0) {
        expenseTypeGeneral = expenseTypes[0];
      }
      setExpenseType(expenseTypeGeneral.id);
      setExpenseTypeCode(expenseTypeGeneral.code);
      dispatch(updateBusinessGoal(expenseTypeGeneral.id));
    } else {
      dispatch(setBusinessGoalAction([]));
    }
  }, [expenseTypes]);

  useEffect(() => {
    if (applicationForExpenseDetail && applicationForExpenseDetail.assigneeEmployee.id != userDetail.id && isBookerOccupation()) {
      setBookerAndOwner(true);
    } else { setBookerAndOwner(false); }
  }, [applicationForExpenseDetail]);

  useEffect(() => {
    if (!businessGoalOptions) {
      return;
    }

    let businessGoals = businessGoalOptions.map((businessGoalOption) => {
      return {
        value: businessGoalOption.id,
        text: businessGoalOption.name[i18n.language as 'ru']
      };
    });

    if (businessGoals.length == 1) {
      setBusinessGoal(businessGoals[0]);
      setBusinessGoalOnlyOne(true);
    } else {
      setBusinessGoal({} as SelectOptionType);
      setBusinessGoalOnlyOne(false);
    }

    if (props.editId) {
      let businessTargetId = applicationForExpenseDetail ? applicationForExpenseDetail.businessTarget.id : -1;
      let businessTargetName = applicationForExpenseDetail ? applicationForExpenseDetail.businessTarget.name[i18n.language as 'ru'] : '';
      setBusinessGoal({
        value: businessTargetId,
        text: businessTargetName
      });
    }

  }, [businessGoalOptions]);

  const getEditPermissions = async () => {
    let result = await getFieldPermissionsUpdateExpenseApplications(applicationForExpenseDetail ? applicationForExpenseDetail.id : 0);
    if (result.headers.success) {
      let permissions = result.data
      setEditPermissions(permissions);
    }
  };

  const getCreatePermissions = async () => {
    let result = await getFieldPermissionsCreateExpenseApplications();
    if (result.headers.success) {
      let permissions = result.data
      setCreatePermissions(permissions);
    }
  }

  // const generateFieldAcess = () => {
  //   let fieldAccess = {
  //     number: permissions.includes(EXPENSE_APPLICATION_PROPETIES.NUMBER),
  //     applicationType: permissions.includes(EXPENSE_APPLICATION_PROPETIES.APPLICATION_TYPE),
  //     journeyNumber: permissions.includes(EXPENSE_APPLICATION_PROPETIES.JOURNEY_NUMBER),
  //     businessTripNumber: permissions.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TRIP_NUMBER),
  //     businessTarget: permissions.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TARGET),
  //     description: permissions.includes(EXPENSE_APPLICATION_PROPETIES.DESCRIPTION),
  //     vbsElement: permissions.includes(EXPENSE_APPLICATION_PROPETIES.VBS_ELEMENT),
  //     departureFromCity: permissions.includes(EXPENSE_APPLICATION_PROPETIES.DEPARTURE_FROM_CITY),
  //     arrivalToCity: permissions.includes(EXPENSE_APPLICATION_PROPETIES.ARRIVAL_TO_CITY),
  //     startOn: permissions.includes(EXPENSE_APPLICATION_PROPETIES.START_ON),
  //     endOn: permissions.includes(EXPENSE_APPLICATION_PROPETIES.END_ON),
  //     personalDaysNumber: permissions.includes(EXPENSE_APPLICATION_PROPETIES.PERSONAL_DAYS_NUMBER),
  //     advanceCashAmount: permissions.includes(EXPENSE_APPLICATION_PROPETIES.ADVANCE_CASH_AMOUNT),
  //     approvedAmount: permissions.includes(EXPENSE_APPLICATION_PROPETIES.APPROVED_AMOUNT),
  //     totalAmount: permissions.includes(EXPENSE_APPLICATION_PROPETIES.TOTAL_AMOUNT),
  //     flowHistory: permissions.includes(EXPENSE_APPLICATION_PROPETIES.FLOW_HISTORY),
  //     status: permissions.includes(EXPENSE_APPLICATION_PROPETIES.STATUS),
  //   }
  //   setFieldAccess(fieldAccess);
  // }

  const generateClassName = (properties: string): string => {
    let classes = [];
    if (editMode) {
      !readPermissions.includes(properties) && classes.push('d-none');
      !editPermissions.includes(properties) && classes.push('disabled');
      return classes.join(' ');
    }
    !createPermissions.includes(properties) && classes.push('disabled');
    return classes.join(' ');
  }

  const isBookerOccupation = (): boolean => {
    if (!selectedOccupation.occupationId) {
      return false;
    }
    let occupations = selectedOccupation.roles.map((role: any) => role.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
  }

  const isAssistantManagerOccupation = (): boolean => {
    if (!selectedOccupation.occupationId) {
      return false;
    }
    let occupations = selectedOccupation.roles.map((role: any) => role.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.ASSISTANT_MANAGER);
  }

  const isCoordinatorOccupation = (): boolean => {
    if (!selectedOccupation.occupationId) {
      return false;
    }
    let occupations = selectedOccupation.roles.map((role: any) => role.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.COORDINATOR);
  }

  const initClearMode = async () => {
    setEditMode(false);
    await getCreatePermissions();
    //setExpenseType(11);
    //setExpenseTypeCode('General');
    setDescription('');
    setBusinessGoal({} as SelectOptionType);
    //setAssignee({ value: selectedOccupation.occupationId, text: `${selectedOccupation.emplo.name[i18n.language as 'ru']} - ${selectedOccupation.company} - ${selectedOccupationName}`});
    setRoads([{
      departureFromCityId: 0,
      departureFromCityName: '',
      arrivalToCityId: 0,
      arrivalToCityName: '',
      startOn: null as any,
      endOn: null as any,
      validate: false
    }] as RoadObj[]);
  }

  const initEditMode = async () => {
    setEditMode(true);
    await getEditPermissions();
    let businessTargetId = applicationForExpenseDetail ? applicationForExpenseDetail.businessTarget.id : -1;
    let businessTargetName = applicationForExpenseDetail ? applicationForExpenseDetail.businessTarget.name[i18n.language as 'ru'] : '';
    let applicationTypeId = applicationForExpenseDetail != null ? applicationForExpenseDetail.applicationType.id : -1;
    let applicationTypeCode = applicationForExpenseDetail != null ? applicationForExpenseDetail.applicationType.code : '';

    await setAssigneeDetail({
      userId: applicationForExpenseDetail ? applicationForExpenseDetail.assigneeEmployee.id : 0,
      occupationId: applicationForExpenseDetail ? applicationForExpenseDetail.assigneeEmployee.occupationId : 0,
      companyId: applicationForExpenseDetail ? applicationForExpenseDetail.company.id : 0
    });

    let hasExternalExpense = false;
    applicationForExpenseDetail && applicationForExpenseDetail.expenses.forEach((expense) => {
      if (!hasExternalExpense && expense.external) {
        hasExternalExpense = true;
      }
    });
    setIsExternal(hasExternalExpense);

    await dispatch(updateExpenseType(applicationForExpenseDetail ? applicationForExpenseDetail.company.id : 0));
    await dispatch(updateBusinessGoal(applicationTypeId));

    setExpenseType(applicationTypeId);
    setExpenseTypeCode(applicationTypeCode);

    setBusinessGoal({
      value: businessTargetId,
      text: businessTargetName
    });
    setDescription(applicationForExpenseDetail != null ? applicationForExpenseDetail.description : '');

    if (applicationTypeCode == 'Travel' && applicationForExpenseDetail) {
      let mapEditRoads = ([] as RoadObj[]).concat({
        id: getNextKey('road'),
        departureFromCityId: applicationForExpenseDetail ? applicationForExpenseDetail.departureFromCity.id : -1,
        departureFromCityName: applicationForExpenseDetail ? applicationForExpenseDetail.departureFromCity.name[i18n.language as 'ru'] : '',
        arrivalToCityId: applicationForExpenseDetail ? applicationForExpenseDetail.businessTripDestinations.length > 0 ? applicationForExpenseDetail.businessTripDestinations[0].city.id : applicationForExpenseDetail.arrivalToCity.id : -1,
        arrivalToCityName: applicationForExpenseDetail ? applicationForExpenseDetail.businessTripDestinations.length > 0 ? applicationForExpenseDetail.businessTripDestinations[0].city.name[i18n.language as 'ru'] : applicationForExpenseDetail.arrivalToCity.name[i18n.language as 'ru'] : '',
        startOn: applicationForExpenseDetail ? toDate(applicationForExpenseDetail.startOn) : '',
        endOn: applicationForExpenseDetail ? (applicationForExpenseDetail.businessTripDestinations.length > 0 ? toDate(applicationForExpenseDetail.businessTripDestinations[0].leaveOn) : toDate(applicationForExpenseDetail.endOn)) : '',
        validate: false
      }).concat(applicationForExpenseDetail.businessTripDestinations.filter((road, index) => { return index != 0 }).map((road) => {
        return {
          id: getNextKey('road'),
          departureFromCityId: -1,
          departureFromCityName: '',
          arrivalToCityId: road.city.id,
          arrivalToCityName: road.city.name[i18n.language as 'ru'],
          startOn: toDate(road.arrivalOn),
          endOn: toDate(road.leaveOn),
          validate: false
        }
      }));

      setRoads(mapEditRoads);
      setMultiRoadMode(mapEditRoads.length > 1);

    }

    if (applicationForExpenseDetail) {
      setAssistance({ text: nameFormatter(applicationForExpenseDetail.assigneeEmployee.name, NameFormats.FULL, i18n.language), value: applicationForExpenseDetail.assigneeEmployee.id });
      setEmployeeAutocompleteReadOnly(true);
    }
    await customPropertyRef.current.build();
  };

  const changeDescription = () => {
    setDescription(descriptionRef.current.value);
  };

  const typeRadioClickHandler = (id: number) => {
    setExpenseType(+id);
    expenseTypes.forEach(expType => {
      if (expType.id === id) {
        setExpenseTypeCode(expType.code);
        dispatch(updateBusinessGoal(expType.id));
        setBusinessGoal({} as SelectOptionType);
        businessGoalRef.current.clear();
      }
    });
    clearValidation();
  };

  const businessGoalChooseHandler = (option: SelectOptionType) => {
    setBusinessGoal(option);
  };

  const initCreatorOccupationOptions = (data: any) => {
    let newOccupationOptions = [] as SelectOptionType[];
    let occupationOptions = data.map((item: any) => {
      return {
        occupationId: item.occupationId ? item.occupationId : item.id,
        company: item.company.name[i18n.language as 'ru' | 'en'],//get(item.company.name., `company.name.${}`)
        code: item.code,
        name: item.name[i18n.language as 'ru' | 'en'],
      }
    })
    occupationOptions.forEach((item: any) => {
      newOccupationOptions.push({ value: item.occupationId, text: `${item.company} - ${item.name}` })
    })
    setCreatorOccupationOptions(newOccupationOptions);
    if (newOccupationOptions.length === 1) {
      setOccupation(newOccupationOptions[0]);
    }
  };

  const initAssistanceOptions = async (occupationId: number) => {
    dispatch(loaderLock());
    let newAssistantOptions = [] as SelectOptionType[];

    let selectedOccupation = userDetail.occupations.find(item => item.occupationId == occupationId);

    let selectedOccupationName = selectedOccupation ? selectedOccupation.name[i18n.language as 'ru'] : '';
    let selectedOccupationCompany = selectedOccupation ? selectedOccupation.company.name[i18n.language as 'ru'] : '';

    await setAssigneeDetail({
      userId: userDetail.id,
      occupationId: selectedOccupation ? selectedOccupation.occupationId : 0,
      companyId: selectedOccupation ? selectedOccupation.company.id : 0
    });

    let response = await getAssistancesById(occupationId);
    if (response.headers.success) {
      let assistanceList = response.data.value.assistances.map((item: any) => {
        return {
          name: {
            ru: get(item, 'employeeName.ru', ''),
            en: get(item, 'employeeName.en', '')
          },
          employeeOccupationName: {
            ru: get(item, 'employeeOccupationName.ru', ''),
            en: get(item, 'employeeOccupationName.en', '')
          },
          company: {
            id: get(item, 'company.id', 0),
            name: {
              ru: get(item, 'company.name.ru', ''),
              en: get(item, 'company.name.en', '')
            }
          },
          employeeOccupationId: get(item, 'employeeOccupationId', ''),
          employeeId: get(item, 'employeeId', 0)
        }
      });
      if (assistanceList.length > 0) {
        newAssistantOptions.push({
          value: occupationId,
          text: `${userDetail.name[i18n.language as 'ru']} - ${selectedOccupationCompany} - ${selectedOccupationName}`,
          detail: {
            name: get(selectedOccupation, 'employee.name', { ru: '', en: '' }),
            employeeOccupationName: get(selectedOccupation, 'name', { ru: '', en: '' }),
            company: {
              id: get(selectedOccupation, 'company.id', 0),
              name: get(selectedOccupation, 'company.name', { ru: '', en: '' })
            },
            employeeOccupationId: get(selectedOccupation, 'occupationId', 0),
            employeeId: userDetail.id
          }
        } as any);
        assistanceList.forEach((item: any) => {
          newAssistantOptions.push({ value: item.employeeId, text: `${item.name[i18n.language as 'ru']} - ${item.company.name[i18n.language as 'ru']} - ${item.employeeOccupationName[i18n.language as 'ru']}`, detail: item } as any);
        });
      }
    } else {
      dispatch(showErrors({ code: 'del_substituation', message: 'Не удалось загрузить сотрудников' }));

    }
    setAssistanceOptions(newAssistantOptions);
    if (newAssistantOptions.length > 0 && !isAssistantManagerOccupation() && !isCoordinatorOccupation()) {
      setAssistance({ value: occupationId, text: `${userDetail.name[i18n.language as 'ru']} - ${selectedOccupationCompany} - ${selectedOccupationName}` });
    }
    dispatch(loaderUnlock());
  };

  const creatorOccupationtChooseHandler = async (option: any) => {
    let selectedOccupation = userDetail.occupations.find(item => item.occupationId == option.value);

    await setOccupation(option);
    await setSelectedOccupation(selectedOccupation);
    await setOccupationSelected(true);
    await setResponsibleOccupationOptions([]);

    let companyId = selectedOccupation ? selectedOccupation.company.id : 0;
    let occupationId = selectedOccupation ? selectedOccupation.occupationId : 0;
    await setAssigneeDetail({
      userId: userDetail.id,
      occupationId: occupationId,
      companyId: companyId
    });
    await dispatch(updateExpenseType(companyId));


    customPropertyRef && customPropertyRef.current && customPropertyRef.current.build();

    await initClearMode();
    await initAssistanceOptions(occupationId);
  };

  const assistanceChooseHandler = async (option: any) => {
    setAssistance(option);
    await setAssigneeDetail({
      userId: option.detail.employeeId,
      occupationId: option.detail.employeeOccupationId,
      companyId: option.detail.company.id
    });
    await dispatch(updateExpenseType(option.detail.company.id));

    customPropertyRef && customPropertyRef.current && customPropertyRef.current.build();
    await initClearMode();
  };

  const responsibleChooseHandler = async (option: EmployeeAutocompleteOptionType) => {
    setAssistance(option);

    if (option && option.value) {
      dispatch(loaderLock);
      let result = await getOccupationsShortByEmployeeId(option.value, assigneeDetail.companyId);


      if (result.headers.success) {
        let newOption = result.data.map((item: any) => {
          return {
            text: `${get(item, `company.name.${i18n.language}`, '')} - ${get(item, `name.${i18n.language}`, '')}`,
            value: get(item, 'id', 0),
            detail: {
              companyId: get(item, 'company.id', 0)
            }
          }
        })

        await setResponsibleOccupationOptions(newOption);
        await setResponsibleOccupation(newOption[0]);
        await setAssigneeDetail({
          userId: option.value,
          occupationId: newOption[0].value,
          companyId: newOption[0].detail.companyId
        });
        customPropertyRef && customPropertyRef.current && customPropertyRef.current.build();
        await dispatch(updateExpenseType(newOption[0].detail.companyId));
        // await initClearMode();
      } else {
        dispatch(showErrors({
          code: 'expense_applications_error',
          message: 'Не удалось получить список назначений'
        }));
      }
      dispatch(loaderUnlock);
    }
  }

  const responsibleOccupationChooseHandler = async (option: any) => {
    await setResponsibleOccupation(option);
    await setAssigneeDetail({
      userId: assigneeDetail.userId,
      occupationId: option.value,
      companyId: option.detail.companyId
    });
    customPropertyRef && customPropertyRef.current && customPropertyRef.current.build();
    await dispatch(updateExpenseType(option.detail.companyId));
    await initClearMode();
  }

  const changeRoads = (roads: RoadObj[]) => {
    setRoads(roads);
  }

  const changeRoadMode = () => {
    let newRoads = [] as RoadObj[];
    newRoads.push({
      id: getNextKey('road'),
      departureFromCityId: roads[0].departureFromCityId,
      departureFromCityName: roads[0].departureFromCityName,
      arrivalToCityId: roads[0].arrivalToCityId,
      arrivalToCityName: roads[0].arrivalToCityName,
      startOn: roads[0].startOn,
      endOn: roads[0].endOn,
      validate: roads[0].validate
    });
    if (!multiRoadMode) {
      newRoads.push({
        id: getNextKey('road'),
        departureFromCityId: 0,
        departureFromCityName: '',
        arrivalToCityId: 0,
        arrivalToCityName: '',
        startOn: null as any,
        endOn: null as any,
        validate: false
      });
    }
    setRoads(newRoads);
    setMultiRoadMode(!multiRoadMode);
  }

  const closeHandler = () => {
    props.onClose(true);
  }

  const validate = () => {
    let isValid = true;
    if (typeof businessGoalValidatorRef.current.validate !== 'function') {
      return false;
    }
    let validateList = [
      businessGoalValidatorRef.current.validate()
    ];
    if (creatorOccupationOptions.length > 1 && !editMode) {
      validateList.push(...validateList, creatorOccupationValidatorRef.current.validate());
    }
    if (isCoordinatorOccupation()) {
      validateList = [...validateList, responsibleValidatorRef.current.validate()]
    }
    if (assistanceOptions.length > 0 && !isAssistantManagerOccupation() && !isCoordinatorOccupation()) {
      validateList = [...validateList, assistanceValidatorRef.current.validate()]
    }
    if (expenseTypeCode === 'Travel') {
      setRoadsValidation(true);
      roads.forEach((road, index) => {
        if (!validateRoad(road, index)) {
          isValid = false;
        }
      })
    }
    validateList.forEach((item) => {
      if (!item) {
        isValid = false;
        return;
      }
    });
    if (!customPropertyRef.current.validate()) {
      isValid = false;
    }
    return isValid;
  };

  const setRoadsValidation = (validate: boolean) => {
    let newRoads: RoadObj[] = roads.map(road => {
      road.validate = validate;
      return road;
    });
    setRoads(newRoads);
  }

  const validateRoad = (road: RoadObj, index: number): boolean => {
    if (road.departureFromCityId === 0 && index === 0) {
      return false;
    }
    if (road.arrivalToCityId === 0) {
      return false;
    }
    if (!road.startOn) {
      return false;
    }
    if (!road.endOn) {
      return false;
    }
    if (!multiRoadMode && (road.startOn.valueOf() > road.endOn.valueOf())) {
      return false;
    }
    return true;
  };

  const saveHandler = () => {
    /*if (applicationForExpenseDetail && applicationForExpenseDetail.assigneeEmployee.id != userDetail.id) {
      closeHandler();
      return;
    }*/
    if (!validate()) {
      return;
    }
    if (editMode) {
      edit();
    } else {
      create();
    }
    setRoadsValidation(false);
  };

  const create = () => {
    saveCreate({...dataRef.current, occupationId: assigneeDetail.occupationId });
  }

  const saveCreate = async (expenseApplications: ExpenseApplications) => {
    dispatch(loaderLock());
    let response = await postExpenseApplications(expenseApplications);
    if (response.headers.success) {
      await customPropertyRef.current.saveById(response.data.id, response.data.company.id);
      analitiks('SaveFormCreateZNR', { znrId: response.data.id, type: response.data.applicationType.id, employeeId: userDetail.id, occupationId: userDetail.occupations[0].occupationId, companyId: userDetail.occupations[0].company.id });
      props.history.push(`/ExpenseApplication/Detail/${response.data.id}`);
      props.onClose(false);
    } else {
      dispatch(showErrors({ code: 'expense_applications_error', message: `Не удалось создать заявку на расход` }));
    }
    dispatch(loaderUnlock());
  }

  const daysCount = (applicationTravelDates: TravelDates[]) => {
    let daysCount = 0;
    applicationTravelDates.forEach((dates: TravelDates) => {
      daysCount = daysCount + betweenTwoDates(dates.startDate, dates.endDate);
    });
    return daysCount;
  }

  const edit = async () => {
    saveEdit(dataRef.current);
  }

  useEffect(() => {
    dataRef.current.applicationTypeId = expenseType;
  }, [expenseType])

  useEffect(() => {
    dataRef.current.businessTargetId = +businessGoal.value;
  }, [businessGoal])

  useEffect(() => {
    dataRef.current.description = description;
  }, [description])

  useEffect(() => {
    dataRef.current.assigneeEmployeeId = assigneeDetail && assigneeDetail.userId;
  }, [assigneeDetail])

  useEffect(() => {
    if (expenseTypeCode !== 'Travel') {
      delete dataRef.current.businessTripOption;
    }
  }, [expenseTypeCode])

  useEffect(() => {
    if (expenseTypeCode === 'Travel') {
      let dayNumber = (date: Date) => {
        return +moment(date).format('YYYYMMDD');
      }
      
      if (roads.length > 0) {
      let applicationTravelDates = applicationForExpenseDetail ? applicationForExpenseDetail.expenseApplicationTravelDates.filter(item => {
        if (dayNumber(roads[0].startOn) > dayNumber(item.startDate)) {
          return false
        }
        return dayNumber(roads[roads.length - 1].endOn) >= dayNumber(item.endDate);
      }) : [];

      dataRef.current.businessTripOption = {
        departureFromCityId: roads[0] ? roads[0].departureFromCityId : 0,
        startOn: roads[0] && roads[0].startOn ? returnDateWithoutUTC(roads[0].startOn) : null,
        arrivalToCityId: roads[roads.length - 1] ? roads[roads.length - 1].arrivalToCityId : 0,
        endOn: roads[roads.length - 1] && roads[roads.length - 1].endOn  ? returnDateWithoutUTC(roads[roads.length - 1].endOn) : null,
        personalDaysNumber: applicationTravelDates ? daysCount(applicationTravelDates) : 0,
        applicationTravelDates: applicationTravelDates
      };
      
      dataRef.current.businessTripOption.businessTripDestination = roads.map((road, index) => {
         return {
          cityId: road ? road.arrivalToCityId : 0,
          arrivalOn:road && road.startOn ? returnDateWithoutUTC(road.startOn) : null,
          leaveOn: road && road.endOn ? returnDateWithoutUTC(road.endOn) : null,
          ordinal: index + 1
        }
      });
      }
    }

  },[roads])

  const saveEdit = async (expenseApplications: ExpenseApplications) => {
    dispatch(loaderLock());
    let perDiemCalculationType = Config.getConfigToCompany(ConfigTypes.PERDIEM_CALCULATION_TYPE, assigneeDetail.companyId);

    try {
      let needRenew = false;
      let dateDiffer = (dateA: any, dateB: any) => {
        return +moment(dateA).format('YYYYMMDD') == +moment(dateB).format('YYYYMMDD')
      };

      if (perDiemCalculationType != 0 && applicationForExpenseDetail && applicationForExpenseDetail.expenses.filter(item => item.expenseType.expenseGroup.code == "Perdiem").length > 0) {
        if (expenseApplications.businessTripOption && !dateDiffer(expenseApplications.businessTripOption.startOn, applicationForExpenseDetail.startOn)) {
          needRenew = true;
        }
        if (expenseApplications.businessTripOption && !dateDiffer(expenseApplications.businessTripOption.endOn, applicationForExpenseDetail.endOn)) {
          needRenew = true;
        }

        if (expenseApplications.businessTripOption && expenseApplications.businessTripOption.departureFromCityId != applicationForExpenseDetail.departureFromCity.id) {
          needRenew = true;
        }
        if (expenseApplications.businessTripOption && expenseApplications.businessTripOption.arrivalToCityId != applicationForExpenseDetail.arrivalToCity.id) {
          needRenew = true;
        }

        let oldTrip = applicationForExpenseDetail ? applicationForExpenseDetail.businessTripDestinations : [];
        let newTrip = expenseApplications.businessTripOption ? expenseApplications.businessTripOption.businessTripDestination ? expenseApplications.businessTripOption.businessTripDestination : [] : []
        if (oldTrip.length == newTrip.length) {
          oldTrip.sort((a, b) => a.ordinal - b.ordinal);
          newTrip.sort((a, b) => a.ordinal - b.ordinal);

          for (let index = 0; index < oldTrip.length; index++) {
            const oldElement = oldTrip[index];
            const newElement = newTrip[index];
            if (oldElement.city.id != newElement.cityId) {
              needRenew = true;
            }
            if (!dateDiffer(oldElement.leaveOn, newElement.leaveOn)) {
              needRenew = true;
            }
            if (!dateDiffer(oldElement.arrivalOn, newElement.arrivalOn)) {
              needRenew = true;
            }
          }
        } else {
          needRenew = true;
        }
      }

      let response = await updateExpensesById(props.editId, expenseApplications);
      if (!response.headers.success) {
        dispatch(showErrors({ code: 'expense_applications_error', message: response.data.Errors ? String(response.data.Errors) : String(response.data) }));
        // dispatch(showErrors({ code: 'expense_applications_error', message: String(response.data) }));
      } else {
        await customPropertyRef.current.edit();
        props.editId && await dispatch(updateApplicationForExpenseDetail(props.editId));
        if (needRenew) {
          dailyRenew();
        }
        analitiks('EditingZNR', { id: applicationForExpenseDetail ? applicationForExpenseDetail.id : null, employeeId: userDetail.id, occupationId: userDetail.occupations[0].occupationId, companyId: userDetail.occupations[0].company.id, });
      }
      props.onClose(false);
    } catch (e) {
      dispatch(showErrors({ code: 'expense_applications_error', message: `Create expense applications failure. ${e}` }));
    }

    dispatch(loaderUnlock());
  }

  const clearValidation = () => {
    businessGoalValidatorRef.current.clear();
    if (isAssistantManagerOccupation() || isCoordinatorOccupation()) {
      //responsibleValidatorRef.current.clear();
    }
    if (assistanceOptions.length > 0 && !isAssistantManagerOccupation() && !isCoordinatorOccupation()) {
      //assistanceValidatorRef.current.clear();
    }
  };

  const dailyRenew = async () => {
    dispatch(loaderLock());
    let a = applicationForExpenseDetail ? applicationForExpenseDetail.expenses.filter((item: any) => { return item.expenseType.expenseGroup.code == "Perdiem" }).map((item: any) => item.id) : [];
    for (let index = 0; index < a.length; index++) {
      const element = a[index];
      await deleteExpensesById(element);
    }

    let data = {
      applicationRef: {
        id: applicationForExpenseDetail ? applicationForExpenseDetail.id : 0,
        logicalName: 'ExpenseApplication',
        name: {
          ru: 'ExpenseApplication',
          en: 'ExpenseApplication'
        }
      },
      calculations: []
    }
    updatePerDiemCalculations(data);
    props.editId && await dispatch(updateApplicationForExpenseDetail(props.editId));
    dispatch(loaderUnlock());
    openDailyRenew();
  }

  const openDailyRenew = () => {
    setShowDailyRenew(true);
  }

  const closeDailyRenew = () => {
    setShowDailyRenew(false);
  }

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  }

  const rights = {
    businessTrip: createPermissions.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TARGET) || editPermissions.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TARGET),
    travel: createPermissions.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TRIP_OPTION) || editPermissions.includes(EXPENSE_APPLICATION_PROPETIES.BUSINESS_TRIP_OPTION)
  }

  return (
    <Fragment>
      <ModalContainer onEnter={saveHandler} isOpen={props.isOpen} destroy={true} highestModal={isHighestModal} overlayClick={closeHandler}>
        <div className="box-modal" id="expense-modal">
          <div className="box-modal_close" onClick={closeHandler}></div>
          <div className="box-modal-title">{t(editMode ? 'create_expense.title_edit' : 'create_expense.title_create')}</div>
          <div className="box-modal-content">
            <div className="box-modal-form">
              <div className={`box-modal-form-block ${generateClassName(EXPENSE_APPLICATION_PROPETIES.APPLICATION_TYPE)}`}>
                <div className="title">{t('create_expense.application_type')}</div>
                <div className="input-item">
                  <div className="box-modal-checkbox-wrap">
                    {expenseTypes.map((expType, index) => {
                      return (
                        <Radio
                          className={index === 1 ? 'radio-restyle' : ''}
                          key={expType.id}
                          name="expenseTypeRadio"
                          id={expType.id}
                          label={expType.name[i18n.language as 'ru' | 'en']}
                          checked={expType.id === expenseType}
                          onClick={typeRadioClickHandler}
                          tabIndex={isTabIndexDisabled ? -1 : 0}
                        />);
                    })}
                  </div>
                </div>
              </div>
              <div className="box-modal-form-block">
                <div className="title">{t('create_expense.application_details')}</div>
                {expenseTypeCode === 'Travel' && (rights.travel ?
                    <div className="box-modal-form-block">
                      <Road defaultOptions={roads} onChange={changeRoads}
                            onChangeRoadMode={changeRoadMode} multiRoadMode={multiRoadMode}
                            tabIndex={isTabIndexDisabled ? -1 : 0}/>
                    </div>
                    : <div className="box-modal-form-block">
                      <Road defaultOptions={roads} isReadOnly={true} onChange={changeRoads}
                            onChangeRoadMode={changeRoadMode} multiRoadMode={multiRoadMode}
                            tabIndex={isTabIndexDisabled ? -1 : 0}/>
                    </div>)
                }

                {creatorOccupationOptions.length > 1 && !editMode ? (
                  <Validator type={'select'} ref={creatorOccupationValidatorRef} errorMessage={t("create_expense.select_occupation")}>
                    <React.Fragment>
                      <label className="input-label">
                        {t('create_expense.select_occupation')}
                        <i className="input-required">*</i>
                      </label>
                      <Select
                        ref={creatorOccupationRef}
                        options={creatorOccupationOptions}
                        onChoose={creatorOccupationtChooseHandler}
                        defaultText={t('create_expense.select_occupation')}
                        disabled={!!props.editId}
                        style={{ marginRight: '-12px' }}
                      />
                    </React.Fragment>
                  </Validator>
                ) : null}
                {(assistanceOptions.length > 0 && !isAssistantManagerOccupation() && !isCoordinatorOccupation()) ? (
                  <Validator type={'select'} ref={assistanceValidatorRef} >
                    <React.Fragment>
                      <label className="input-label">
                        {t('create_expense.assignee_employee')}
                        <i className="input-required">*</i>
                      </label>
                      <Select
                        ref={assistanceRef}
                        defaultValue={assistance}
                        options={assistanceOptions}
                        onChoose={assistanceChooseHandler}
                        defaultText={t('create_expense.placeholder.select_employee')}
                        disabled={!!props.editId}
                        style={{ marginRight: '-12px' }}
                      />
                    </React.Fragment>
                  </Validator>
                ) : null}
                {isCoordinatorOccupation() ? (
                  <Validator type={'text'} ref={responsibleValidatorRef} isRoad={isIE}>
                    <React.Fragment>
                      <label className="input-label">
                        {t('create_expense.assignee_employee')}
                        <i className="input-required">*</i>
                      </label>
                      <div className={`input-item  ${bookerAndOwner ? 'disabled' : ''}`} style={{ marginBottom: '0px' }}>
                        <div className="input-search" style={{ marginRight: '-12px' }}>
                          <EmployeeAutocomplete placeholder={t('create_expense.placeholder.enter_employee')} onChoose={responsibleChooseHandler}
                            defaultText={responsible.text != '' ? responsible.text : undefined} readOnly={employeeAutocompleteReadOnly} tabIndex={isTabIndexDisabled ? -1 : 0}/>
                        </div>
                      </div>
                    </React.Fragment>
                  </Validator>
                ) : null}
                {(responsibleOccupationOptions.length > 0) ? (
                  <Validator type={'select'} ref={responsibleOccupationRef} >
                    <React.Fragment>
                      <label className="input-label">
                        {t('create_expense.assignee_employee_occupation')}
                        <i className="input-required">*</i>
                      </label>
                      <Select
                        //ref={assistantRef}
                        defaultValue={responsibleOccupation}
                        options={responsibleOccupationOptions}
                        onChoose={responsibleOccupationChooseHandler}
                        defaultText={t('create_expense.placeholder.select_employee')}
                        disabled={!!props.editId}
                        style={{ marginRight: '-12px' }}
                      />
                    </React.Fragment>
                  </Validator>
                ) : null}

                {businessGoal &&
                (
                    < Validator type={'select'} ref={businessGoalValidatorRef}
                                errorMessage={t("create_expense.select_business_goal")}>
                      <div>
                        <label className="input-label">
                          {t('create_expense.business_goal')}
                          <i className="input-required">*</i>
                        </label>
                        <Select
                            disabled={!rights.businessTrip}
                            ref={businessGoalRef}
                            defaultValue={editMode || businessGoalOnlyOne ? businessGoal : null}
                            options={businessGoalOptions.map((businessGoalOption, index) => {
                                  return {
                                    value: businessGoalOption.id,
                                    text: businessGoalOption.name[i18n.language as 'ru']
                                  };
                                }
                            )}
                            onChoose={businessGoalChooseHandler}
                            defaultText={t('modals.create_advance_report_modal.enter_business_goal')}
                            style={{marginRight: '-12px'}}
                        />
                      </div>
                    </Validator>
                )
                }
                <div className="box-modal-form-block">
                  <div className="input-item-row">
                    <div className="input-item">
                      <label className="input-label">{t('create_expense.description')}</label>
                      <input className={`input ${generateClassName(EXPENSE_APPLICATION_PROPETIES.DESCRIPTION)}`} type="text" ref={descriptionRef} value={description} maxLength={400}
                             onChange={changeDescription} placeholder={t('create_expense.placeholder.description')}
                             tabIndex={isTabIndexDisabled ? -1 : 0}/>
                    </div>
                  </div>
                </div>
                <CustomProperty
                  dataExpense={dataRef.current}
                  type={"ExpenseApplication"}
                  id={props.editId}
                  isEdit={!!props.editId}
                  isZNR={true}
                  ref={customPropertyRef}
                  permissions={!!props.editId ? editPermissions : createPermissions}
                  occupationsId={assigneeDetail.occupationId}
                  companyId={props.editId ? (applicationForExpenseDetail && applicationForExpenseDetail.company ? applicationForExpenseDetail.company.id : undefined) : assigneeDetail.companyId}
                  tabIndex={isTabIndexDisabled ? -1 : 0}
                />
              </div>
            </div>
          </div>
          <div className="box-modal-footer">
            <a className="btn btn_black pointer" onClick={closeHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('create_expense.cancel')}</a>
            {props.hasNext
              ? <a className="btn-expense btn_green pointer" onClick={saveHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('request_detail.request_button_controls.next')}</a>
              : <a className="btn-expense btn_green pointer" onClick={saveHandler} tabIndex={isTabIndexDisabled ? -1 : 0}>{t('create_expense.complete')}</a>
            }
          </div>
        </div>

      </ModalContainer>
      <CreateOrderModal id={props.editId ? props.editId : 0} isOpen={isShowDailyRenew} onClose={closeDailyRenew} dailyRenew={true} />
    </Fragment>
  );
}

export default withRouter(CreateExpenseModal);
