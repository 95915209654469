import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from "react-i18next";
import SubmitCustomModal from 'app/component/modal/SubmitCustomModal';
import SubmitApprovalModal from 'app/component/modal/SubmitApprovalModal';
import AutorizationModal from 'app/component/modal/AuthorizationModal';
import CommentModal from 'app/component/modal/CommentModal';
import If from 'app/component/util/If';
import { ApplicationState } from '../../../store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { updateStateOptions, loaderLock, loaderUnlock, setStateOptions, updatePostings } from 'store/common/actions';
import Config, { ConfigTypes } from 'services/ConfigService';
import { updateDetailedAO } from 'store/report/actions';
import { showErrors, showInfo } from 'store/exception/actions';
import { OCCUPATION_ROLE_TYPE } from 'infrastructure/enum/user-role.enum';
import { isEmpty } from 'lodash';
import { updateApplicationForExpenseDetail } from 'store/applicationForExpense/actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { STATUS_TYPE_EXPENSE } from 'infrastructure/enum/status-expense.enum';
import FileSaver from 'file-saver';
import { STATUS_TYPE_REPORT } from 'infrastructure/enum/status-report.enum';
import analitiks from 'services/AnaliticsService';
import { IOccupations } from 'infrastructure/interfaces';
import CustomPropertyActivation from '../custom-property-activation/CustomPropertyActivation';
import agreementService from 'services/entity/agreement.service';
import ADActFiller from '../modal/ADActFiller';
import DownloadPostingsModal from "../modal/DownloadPostingsModal";
import {
    getAdvanceReportApplication,
    getApplicationForExpenseDetail, getCommonIsArchivedPostings,
    getCommonPostings, getCommonStateOptions,
    getCommonUserDetail
} from "../../../store/selectors";
import {
    getApprovalsByObject, postAdvanceReportApplications, postApiTransactions,
    postApprovals, postTransactionsDownload, updateApiTransactions,
    updateApprovalsById,
    updateApprovalsItems, updateApprovalsReNew, updateBatchesArchive
} from "../../../services/ApiService";
import {
    ADVANCE_APPLICATION_PROPETIES,
    EXPENSE_APPLICATION_PROPETIES
} from "../../../infrastructure/enum/object-properties.enum";
import {TRANSACTIONS_READ_FIELD_PERMISSIONS} from "../../../infrastructure/enum/field-permissions.enum";
import i18n from "i18next";

interface ButtonsControlProps extends RouteComponentProps {
    id: number,
    isPostingsMode: boolean,
    setPostingsMode: () => void,
    isAdvance?: boolean
}

const ButtonsControl: React.FC<ButtonsControlProps> = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector(getCommonUserDetail);
    //const role = user.roleCode;

    const report = useSelector(getAdvanceReportApplication);
    const expense = useSelector(getApplicationForExpenseDetail);
    const postings = useSelector(getCommonPostings);
    const isArchivedPostings = useSelector(getCommonIsArchivedPostings);
    const transactionsReadFieldPermissions = useSelector((state: ApplicationState) => state.common.transactionsReadFieldPermissions);

    let [isOpenRevokeModal, setOpenRefuseForApprovalModal] = useState(false);
    let [isOpenApprovalRequestModal, setOpenSubmitForApprovalModal] = useState(false);
    let [isOpenRefuseModal, setOpenRefuseModal] = useState(false);
    let [isOpenApprovalModal, setOpenApprovalModal] = useState(false);
    let [isOpenPostingsApprovalModal, setOpenPostingsApprovalModal] = useState(false);
    let [isOpenAuthorizationModal, setOpenAuthorizationModal] = useState(false);
    let [isOpenCommentModal, setOpenCommentModal] = useState(false);
    let [isOpenReviseModal, setOpenReviseModal] = useState(false);
    let [isOpenRenewModal, setOpenRenewModal] = useState(false);
    let [isOpenDownloadPostingsModal, setOpenDownloadPostingsModal] = useState(false);
    let [openCommentModalType, setOpenCommentModalType] = useState('renew' as 'renew' | 'revise' | 'refuse');
    let [comment, setComment] = useState('');
    let [perDiemCalculationType, setPerDiemCalculationType] = useState(0);
    let [showPrintButtonInAuthorizationModal, setShowPrintButtonInAuthorizationModal] = useState(false)


    let stateOptions = useSelector(getCommonStateOptions);

    useEffect(() => {
        return () => {
            dispatch(setStateOptions([]));
        }
    }, [])

    useEffect(() => {
        dispatch(updateStateOptions({ id: props.id, type: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication' }));
        let companyId = props.isAdvance ? report.company.id : (expense ? expense.company.id : 0);
        let newPerDiemCalculationType = Config.getConfigToCompany(ConfigTypes.PERDIEM_CALCULATION_TYPE, companyId);
        setPerDiemCalculationType(newPerDiemCalculationType);
    }, [report, expense])

    const isBookerOccupation = (): boolean => {
        let occupations = user.occupations.map((occupation: IOccupations) => occupation.roles).flat().map((item) => item.code);
        return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
    }

    const toggleRevokeModal = () => {
        setOpenRefuseForApprovalModal(!isOpenRevokeModal);
    }

    const toggleApprovalRequestModal = () => {
        setOpenSubmitForApprovalModal(!isOpenApprovalRequestModal);
    }

    const toggleRefuseModal = () => {
        setOpenRefuseModal(!isOpenRefuseModal);
    }

    const toggleApprovalModal = () => {
        setOpenApprovalModal(!isOpenApprovalModal);
    }

    const togglePostingsApprovalModal = () => {
        setOpenPostingsApprovalModal(!isOpenPostingsApprovalModal);
    }

    const toggleAuthorizationModal = () => {
        if (isOpenAuthorizationModal) {
            update();
        }
        setOpenAuthorizationModal(!isOpenAuthorizationModal);
    }

    const toggleCommentModal = () => {
        setOpenCommentModal(!isOpenCommentModal);
    }

    const toggleReviseModal = () => {
        setOpenReviseModal(!isOpenReviseModal);
    }

    const toggleRenewModal = () => {
        setOpenRenewModal(!isOpenRenewModal);
    }

    const toggleDownloadPostingsModal = () => {
        setOpenDownloadPostingsModal(!isOpenDownloadPostingsModal);
    }

    const submitCommentModal = (value: string) => {
        if (value && value != '') {
            setComment(value);
            toggleCommentModal();
            switch (openCommentModalType) {
                case 'renew':
                    toggleRenewModal();
                    break;
                case 'revise':
                    toggleReviseModal();
                    break;
                case 'refuse':
                    toggleRefuseModal();
                    break;
            }
        } else {

        }
    }

    const getCommentModalTitle = () => {
        switch (openCommentModalType) {
            case 'renew':
                return t('agreement.title.renew');
            case 'revise':
                return t('agreement.title.revise');
            case 'refuse':
                return t('agreement.title.refuse');
            default:
                return '';
        }
    }

    const activateRequest = (index: any) => {
        if ((props.isAdvance && report.status == STATUS_TYPE_REPORT.DRAFT) || (!props.isAdvance && expense && expense.status == STATUS_TYPE_EXPENSE.DRAFT)) {
            return <div key={index} className={'btn-expense btn_gray-full disabled'}
                        onClick={toggleApprovalRequestModal}>{t('request_detail.request_button_controls.submitForApproval')}</div>
        }
    }

    const reviseRequest = (index: any) => {
        return props.isPostingsMode ? ""
            : <div key={index} className={'btn-expense btn_yellow pointer'} onClick={() => { setOpenCommentModalType('revise'); toggleCommentModal(); }}>{t('request_detail.request_button_controls.submitForRevise')}</div>
    }

    const renewRequest = (index: any) => {
        if (props.isAdvance && !props.isPostingsMode && report.properties.includes('ActOfEntertaimentExpenses') && (hasNotAttachedExpense() || hasActWithoutAttachedExpense())) {
            return <ADActFiller id={props.id} isAdvance={props.isAdvance} buttonOption={{
                className: 'btn-expense btn_green pointer',
                text: t('request_detail.request_button_controls.submitForApproval'),
                onClick: () => { setOpenCommentModalType('renew'); toggleCommentModal(); }
            }} />
        } else {
        return props.isPostingsMode ? ""
            : <div key={index} className={'btn-expense btn_green pointer'} onClick={() => { setOpenCommentModalType('renew'); toggleCommentModal(); }}>{t('request_detail.request_button_controls.submitForApproval')}</div>
    }
    }

    const approvalRequest = (index: any) => {
        if (props.isAdvance && !props.isPostingsMode && report.properties.includes('ActOfEntertaimentExpenses') && (hasNotAttachedExpense() || hasActWithoutAttachedExpense())) {
            return <ADActFiller
            id={props.id}
            isAdvance={props.isAdvance}
            buttonOption={{
                className: 'btn-expense btn_green pointer',
                text: t('request_detail.request_button_controls.submitForApproval'),
                onClick: toggleApprovalRequestModal
            }}
            />
        } else {
        return props.isPostingsMode ? ""
            : <div key={index} className={'btn-expense btn_green pointer'} onClick={toggleApprovalRequestModal}>{t('request_detail.request_button_controls.submitForApproval')}</div>
    }
    }

    const revoke = (index: any) => {
        return props.isPostingsMode ? ""
            : <div key={index} className="btn-expense btn_yellow pointer" onClick={toggleRevokeModal}>{t('request_detail.request_button_controls.refuseForApproval')}</div>
    }

    const isPostingStatus = (statusArr: string[]) => {
        for (let postingAIndex = 0; postingAIndex < statusArr.length; postingAIndex++) {
            const status = statusArr[postingAIndex];
            for (let postingBIndex = 0; postingBIndex < postings.transactions.length; postingBIndex++) {
                const element = postings.transactions[postingBIndex];
                if (element.state == status) {
                    return true;
                }
            }
        }
        return false;
    }

    const approve = (index: any) => {
        if (props.isAdvance && !props.isPostingsMode) {
            if(report.properties.includes('ActOfEntertaimentExpenses') && (hasNotAttachedExpense() || hasActWithoutAttachedExpense())){
                return <ADActFiller
                id={props.id}
                isAdvance={props.isAdvance}
                buttonOption={{
                    className: 'btn-expense btn_green pointer',
                    text: t('request_detail.request_button_controls.approval'),
                    onClick: toggleApprovalModal
                }}
                />
            } else {
            return <div key={index} className="btn-expense btn_green pointer" onClick={toggleApprovalModal}>{t('request_detail.request_button_controls.approval')}</div>
        }
        }

        if (!props.isAdvance && !props.isPostingsMode) {
            return <div key={index} className="btn-expense btn_green pointer" onClick={toggleApprovalModal}>{t('request_detail.request_button_controls.approval')}</div>
        }
    }

    const postingsButton = () => {
        if (props.isAdvance) {
            return (<Fragment>
                <If condition={transactionsReadFieldPermissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.TRANS_CREATE)}>
                    <div className="btn-expense btn_green pointer" onClick={generatePostings}>{t('report_detail.generate_postings')}</div>
                </If>
                <If condition={isPostingStatus(['Draft', 'Cancelled']) && report.status == STATUS_TYPE_REPORT.APPROVED}>
                    <div className="btn-expense btn_green pointer" onClick={postingsSubmit}>{t('postings.accept')}</div>
                </If>
                <If condition={isArchivedPostings === false && transactionsReadFieldPermissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.TRANS_CANCEL)}>
                    <div className="btn-expense btn_red pointer" onClick={postingsRollback}>{t('postings.recall')}</div>
                </If>
                <If condition={isArchivedPostings}>
                    <div className="btn-expense btn_black" onClick={returnFromArchive}>{t('postings.return_from_archive')}</div>
                </If>
                <If condition={transactionsReadFieldPermissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.TRANS_DOWNLOAD)}>
                    <div className="btn-expense btn_green pointer" onClick={toggleDownloadPostingsModal}>{t('postings.download')}</div>
                </If>
            </Fragment>)
        }

        if (!props.isAdvance) {
            return (
                <Fragment>
                    {transactionsReadFieldPermissions.includes(TRANSACTIONS_READ_FIELD_PERMISSIONS.TRANS_DOWNLOAD) && <div className="btn-expense btn_green pointer"
                         onClick={toggleDownloadPostingsModal}>{t('postings.download')}</div>}
                    <If condition={isArchivedPostings && isPostingStatus(['Posted']) && !!expense && expense.status == STATUS_TYPE_EXPENSE.POSTED}>
                        <div className="btn-expense btn_black"
                             onClick={returnFromArchive}>{t('postings.return_from_archive')}</div>
                    </If>
                </Fragment>);
        }
    }

    const decline = (index: any) => {
        if (props.isAdvance && !props.isPostingsMode) {
            return <div key={index} className="btn-expense btn_red pointer" onClick={() => { setOpenCommentModalType('refuse'); toggleCommentModal(); }}>{t('request_detail.request_button_controls.refuse')}</div>
        }

        if (!props.isAdvance && !props.isPostingsMode) {
            return <div key={index} className="btn-expense btn_red pointer" onClick={() => { setOpenCommentModalType('refuse'); toggleCommentModal(); }}>{t('request_detail.request_button_controls.refuse')}</div>
        }
    }

    const createAOfromZNR = (index: any) => {
        return <div key={index} className="btn-expense btn_green pointer" onClick={generateAOfromZNRSubmit}>{t('request_detail.request_button_controls.createAOfromZNR')}</div>
    }

    const update = () => {
        props.isAdvance ? dispatch(updateDetailedAO(props.id)) : dispatch(updateApplicationForExpenseDetail(props.id));
        dispatch(updateStateOptions({ id: props.id, type: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication' }))
    }

    const revokeSubmit = async () => {
        dispatch(loaderLock());
        let agreementList = await agreementService.getList();
        // @ts-ignore
        let approvalSheetId = agreementList.id
        if (agreementList) {
            let response = await updateApprovalsById(approvalSheetId, { action: 'Cancel' });
            if (response.headers.success) {
                toggleRevokeModal();
                update();
            } else {
                dispatch(showErrors({ code: 'approvals_action', message: 'Не удалось отозвать' }))
            }
        } else {
            dispatch(showErrors({ code: 'approvals_action', message: 'Не удалось получить данных о листе' }))
        }
        dispatch(loaderUnlock());
    }

    const hasNotAttachedExpense = async () => {
        if (props.isAdvance) {
            let additionalDocuments = report.additionalDocuments;
            let expenses = report.expenses;

            let allExpensesId = additionalDocuments.map(item => item.expenses).flat().map(item => item.id);

            let hasNotAttachedExpense = !!expenses.filter(item => item.expenseType.needIncludeToAdvanceReportAdditionalDocuments && !allExpensesId.includes(item.id)).length;

            /*if(hasNotAttachedExpense){
                toggleApprovalRequestModal();
                dispatch(showErrors({ code: 'submit_for_approval', message: `Невозможно отправить на согласование, есть расход которому необходимо создать Акт или привязать уже созданный.` }))
                return
            }*/

            return hasNotAttachedExpense;
            }
        return false
            }

    const hasActWithoutAttachedExpense = () => {
        if (props.isAdvance) {
            let additionalDocuments = report.additionalDocuments;
            let hasActWithoutAttachedExpense = additionalDocuments.filter(item => item.expenses.length == 0).length > 0;
            return hasActWithoutAttachedExpense;
        }
        return false
    }

    const approvalRequestSubmit = async () => {
        let logicalName = props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication';
        dispatch(loaderLock());
        let response = await postApprovals(props.id, logicalName);
        if (response.headers.success) {
            analitiks(props.isAdvance ? 'SendingAOForApproval' : 'SendingZNRForApproval', props.isAdvance ? {
                aoId: +props.id,
                employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id
            } : {
                    znrId: +props.id,
                    employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id
                })
            if (props.isAdvance) {
                let companyId = report && report.company ? report.company.id : 0;
                setShowPrintButtonInAuthorizationModal(Config.getConfigToCompany(ConfigTypes.DISPLAY_BUTTON_PRINT_DOCS_AFTER_APPROVE, companyId));
            }
            toggleApprovalRequestModal();
            toggleAuthorizationModal();
        } else {
            let message = '';
            message += t('error.failed_to_submit_for_approval');
            if(response && response.data.Messages) {
                message += '\n';
                if(i18n.language === 'ru') {
                    message += response.data.Messages[0].Ru
                } else {
                    message += response.data.Messages[0].En
                }
            }
            toggleApprovalRequestModal();
            dispatch(showErrors({ code: 'submit_for_approval', message }))
        }
        dispatch(loaderUnlock());
    }

    const refuseSubmit = async () => {
        dispatch(loaderLock());
        let approvalSheet = await getApprovalsByObject({ params: { id: props.id, logicalName: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication' } });
        if (approvalSheet.headers.success) {
            analitiks(props.isAdvance ? 'NotApprovedAO' : 'NotApprovedZNR', props.isAdvance ? {
                aoId: +props.id,
                employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id
            } : {
                    znrId: +props.id,
                    employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id
                })
            let sheetId = approvalSheet.data.id;
            let approvalSheetId = approvalSheet.data.approvalSheetId;
            let response = await updateApprovalsItems(approvalSheetId, sheetId,{ resolution: 'NotApproved', comment: comment });
            if (response.headers.success) {
                toggleApprovalModal();
                props.history.push('/Agreement');
            } else {
                dispatch(showErrors({ code: 'approvals_action', message: 'Не удалось отказать в согласовании' }))
            }
        } else {
            dispatch(showErrors({ code: 'approvals_action', message: 'Не удалось получить данных о листе' }))
        }
        dispatch(loaderUnlock());
    }

    const approvalSubmit = async (event?: any) => {
        dispatch(loaderLock());
        let approvalSheet = await getApprovalsByObject({ params: { id: props.id, logicalName: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication' } });
        if (approvalSheet.headers.success) {
            analitiks(props.isAdvance ? 'ApprovedAO' : 'ApprovedZNR', props.isAdvance ? {
                aoId: +props.id,
                employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id
            } : {
                    znrId: +props.id,
                    employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id
                })
            let sheetId = approvalSheet.data.id;
            let approvalSheetId = approvalSheet.data.approvalSheetId;
            let response = await updateApprovalsItems(approvalSheetId, sheetId,{ resolution: 'Approved', comment: '' });
            if (response.headers.success) {
                if (!isBookerOccupation()) {
                    props.history.push('/Agreement');
                } else {
                    if (props.isAdvance) {
                        dispatch(updateDetailedAO(props.id))
                    } else {
                        dispatch(updateApplicationForExpenseDetail(props.id))
                    }
                }
            } else {
                dispatch(showErrors({ code: 'approvals_action', message: 'Не удалось согласовать' }))
            }
        } else {
            dispatch(showErrors({ code: 'approvals_action', message: 'Не удалось получить данных о листе' }))
        }
        dispatch(loaderUnlock());
    }

    const reviseSubmit = async (event?: any) => {
        dispatch(loaderLock());
        let approvalSheet = await getApprovalsByObject({ params: { id: props.id, logicalName: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication' } });
        if (approvalSheet.headers.success) {
            let sheetId = approvalSheet.data.id;
            let approvalSheetId = approvalSheet.data.approvalSheetId;
            let response = await updateApprovalsItems(approvalSheetId, sheetId,{ resolution: 'Revision', comment: comment });
            if (response.headers.success) {
                analitiks(props.isAdvance ? 'ReturnAOToRevision' : 'ReturnZNRToRevision', props.isAdvance ? {
                    aoId: +props.id,
                    employeeId: user.id,
                    occupationId: user.occupations[0].occupationId,
                    companyId: user.occupations[0].company.id
                } : {
                        znrId: +props.id,
                        employeeId: user.id,
                    occupationId: user.occupations[0].occupationId,
                    companyId: user.occupations[0].company.id
                    })
                if (!isBookerOccupation) {
                    props.history.push('/Agreement');
                } else {
                    if (props.isAdvance) {
                        dispatch(updateDetailedAO(props.id))
                    } else {
                        dispatch(updateApplicationForExpenseDetail(props.id))
                    }
                }
            } else {
                dispatch(showErrors({ code: 'approvals_action', message: 'Не удалось отправить на доработку' }))
            }
        } else {
            dispatch(showErrors({ code: 'approvals_action', message: 'Не удалось получить данных о листе' }))
        }
        dispatch(loaderUnlock());
    }

    const renewSubmit = async (event?: any) => {
        dispatch(loaderLock());
        let agreementList = await agreementService.getList();
        // @ts-ignore
        let approvalSheetId = agreementList.id
        if (agreementList) {
            let response = await updateApprovalsReNew(approvalSheetId, { commentImproved: comment });
            if (response.headers.success) {
                if (isBookerOccupation()) { //исправлено в рамках задачи SV-11876
                    props.history.push('/Agreement');
                } else {
                    if (props.isAdvance) {
                        dispatch(updateDetailedAO(props.id))
                    } else {
                        dispatch(updateApplicationForExpenseDetail(props.id))
                    }
                }
            } else {
                dispatch(showErrors({ code: 'approvals_action', message: 'Не удалось вернуть на согласование' }))
            }
        } else {
            dispatch(showErrors({ code: 'approvals_action', message: 'Не удалось получить данных о листе' }))
        }
        dispatch(loaderUnlock());
    }

    const generateAOfromZNRSubmit = async () => {
        dispatch(loaderLock());
        let response = await postAdvanceReportApplications(expense ? expense.id : 0);
        if (response.headers.success) {
            analitiks('CreateAOfromZNRDetail', { employeeId: user.id, occupationId: user.occupations[0].occupationId, companyId: user.occupations[0].company.id, znrId: expense ? expense.id : 0, aoId: response.data.id })
            dispatch(showInfo({ code: 'create_advance_report', message: t('request_detail.request_button_controls.AOfromZNRcreated') }));
            props.history.push(`/AdvanceReportApplication/Detail/${response.data.id}`);
        } else {
            switch (response.status) {
                case 400: dispatch(showErrors({ code: 'save_advance_report', message: response.data }));
                    break;
                default: dispatch(showErrors({ code: 'save_advance_report', message: 'Не удалось создать авансовый отчет' }));
                    break;
            }
        }
        dispatch(loaderUnlock());
    }

    const generatePostings = async () => {
        dispatch(loaderLock());
        let response = await postApiTransactions({
            objectId: {
                id: props.isAdvance ? report.id : expense ? expense.id : 0,
                logicalName: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication',
                name: {
                    ru: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication',
                    en: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication'
                }
            }
        });
        if (response.headers.success) {
            analitiks('CreatePostings', { aoId: +report.id, employeeId: user.id, occupationId: user.occupations[0].occupationId, companyId: user.occupations[0].company.id })
            props.setPostingsMode();
            dispatch(updatePostings(props.isAdvance ? report.id : expense ? expense.id : 0, props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication', {}))
        }
        else {
            dispatch(showErrors({ code: 'generate_postings', message: 'Не удалось создать проводки' }));
        }
        dispatch(loaderUnlock());
    }

    const postingsSubmit = async () => {
        dispatch(loaderLock());
        //await approvalSubmit(null, true);
        let response = await updateApiTransactions({
            action: 'put', objectId: {
                id: props.isAdvance ? report.id : expense ? expense.id : 0,
                logicalName: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication',
                name: {
                    ru: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication',
                    en: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication'
                }
            }
        });
        if (response.headers.success) {
            analitiks('AcceptPostings', props.isAdvance ? {
                aoId: report.id,
                employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id
            } : {
                    znrId: expense ? expense.id : 0,
                    employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id
                })
            dispatch(updatePostings(props.isAdvance ? report.id : expense ? expense.id : 0, props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication', {}));
            if (props.isAdvance) {
                dispatch(updateDetailedAO(report.id));
            } else {
                dispatch(updateApplicationForExpenseDetail(expense ? expense.id : 0));
            }
        }
        else {
            dispatch(showErrors({ code: 'approve_postings', message: 'Не удалось согласовать проводки' }));
        }
        togglePostingsApprovalModal();
        dispatch(loaderUnlock());
    }

    const returnFromArchive = async() => {
        dispatch(loaderLock());
        let response = await updateBatchesArchive(props.isAdvance ? report.id : expense ? expense.id : 0,props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication',{archive: false});
        if (response.headers.success) {
            dispatch(showInfo({ code: 'return_from_archive', message: `${!props.isAdvance ? t('postings.return _expense_info') : t('postings.return_advance_info')}`}));
            if (props.isAdvance) {
                dispatch(updateDetailedAO(report.id));
            } else {
                dispatch(updateApplicationForExpenseDetail(expense ? expense.id : 0));
            }
        }
        dispatch(loaderUnlock());
    }

    const postingsRollback = async () => {
        dispatch(loaderLock());
        let response = await updateApiTransactions({
            action: 'rollback',
            objectId: {
                id: props.isAdvance ? report.id : expense ? expense.id : 0,
                logicalName: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication',
                name: {
                    ru: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication',
                    en: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication'
                }
            }
        });
        if (response.headers.success) {
            analitiks('RefusePostings', props.isAdvance ? {
                aoId: report.id,
                employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id
            } : {
                    znrId: expense ? expense.id : 0,
                    employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id
                })
            dispatch(updatePostings(props.isAdvance ? report.id : expense ? expense.id : 0, props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication', {}))
            if (props.isAdvance) {
                dispatch(updateDetailedAO(report.id));
            } else {
                dispatch(updateApplicationForExpenseDetail(expense ? expense.id : 0));
            }
        }
        else {
            dispatch(showErrors({ code: 'approve_postings', message: 'Не удалось согласовать проводки' }));
        }
        dispatch(loaderUnlock());
    }

    const downloadsPostings = async (date?: string) => {
        dispatch(loaderLock());
        let response = await postTransactionsDownload({}, { responseType: 'blob', params: date ? {
                ObjectType: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication',
                ObjectIds: props.isAdvance ? report.id : expense ? expense.id : 0,
                PostingDate: date
            } : {
                ObjectType: props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication',
                ObjectIds: props.isAdvance ? report.id : expense ? expense.id : 0,
            }
        });
        if (response.headers.success) {
            analitiks('DownloadPostings', props.isAdvance ? {
                transactionsId: +props.id,
                employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id,
                aoId: props.id
            } : {
                transactionsId: +props.id,
                employeeId: user.id,
                occupationId: user.occupations[0].occupationId,
                companyId: user.occupations[0].company.id,
                znrId: props.id
            })
            let contentDisposition = response.headers['content-disposition'];
            let fileName = contentDisposition.slice(contentDisposition.lastIndexOf('\'') + 1);
            FileSaver.saveAs(response.data, decodeURI(fileName));
            dispatch(updatePostings(props.isAdvance ? report.id : expense ? expense.id : 0, props.isAdvance ? 'AdvanceReportApplication' : 'ExpenseApplication', {}));
            if (props.isAdvance) {
                dispatch(updateDetailedAO(report ? report.id : 0));
            }
            else {
                dispatch(updateApplicationForExpenseDetail(expense ? expense.id : 0));
            }
        }
        else {
            //dispatch(showErrors({ code: 'approve_postings', message: 'Не удалось согласовать проводки' }));
        }
        dispatch(loaderUnlock());
    }

    return (
        <div className="request-bottom-controls" >
            <div className="tx-center">
                <CustomPropertyActivation id={props.id} isAdvance={props.isAdvance} />
                {expense && expense.properties.includes(EXPENSE_APPLICATION_PROPETIES.CREATE_AO_FROM_ZNR) ? createAOfromZNR(-1) : null}
                {!isEmpty(stateOptions) && ((expense && expense.properties && expense.properties.includes(EXPENSE_APPLICATION_PROPETIES.APPROVAL_BUTTONS)) || (report && report.properties && report.properties.includes(ADVANCE_APPLICATION_PROPETIES.APPROVAL_BUTTONS))) && stateOptions.map((state: any, index) => {
                    switch (state) {
                        case 'Revoke':
                            return revoke(index);
                        case 'Decline':
                            return decline(index);
                    }
                })}

                {!isEmpty(stateOptions) && ((expense && expense.properties && expense.properties.includes(EXPENSE_APPLICATION_PROPETIES.APPROVAL_BUTTONS)) || (report && report.properties && report.properties.includes(ADVANCE_APPLICATION_PROPETIES.APPROVAL_BUTTONS))) && stateOptions.map((state: any, index) => {
                    switch (state) {
                        case 'Revise':
                            return reviseRequest(index)
                        case 'Renew':
                            return renewRequest(index)
                    }
                })}

                <If condition={props.isPostingsMode}>
                    {postingsButton()}
                </If>

                {!isEmpty(stateOptions) && ((expense && expense.properties && expense.properties.includes(EXPENSE_APPLICATION_PROPETIES.APPROVAL_BUTTONS)) || (report && report.properties && report.properties.includes(ADVANCE_APPLICATION_PROPETIES.APPROVAL_BUTTONS))) && stateOptions.map((state: any, index) => {
                    switch (state) {
                        case 'Activate':
                            return activateRequest(index);
                        case 'ApprovalRequest':
                            return approvalRequest(index);
                        case 'Approve':
                            return approve(index);
                    }
                })}
            </div>
            <SubmitCustomModal isOpen={isOpenRevokeModal} title={t('modals.delete_attachment.refuse_for_approval')} onClose={toggleRevokeModal} onSubmit={revokeSubmit} />
            <If condition={perDiemCalculationType != 2 || !props.isAdvance || (props.isAdvance && report.applicationType.code != 'Travel')}>
                <SubmitCustomModal isOpen={isOpenApprovalRequestModal} title={t('modals.delete_attachment.submit_for_approval')} onClose={toggleApprovalRequestModal} onSubmit={approvalRequestSubmit} />
            </If>
            <If condition={perDiemCalculationType == 2 && props.isAdvance && report.applicationType.code === 'Travel'}>
                <SubmitApprovalModal isOpen={isOpenApprovalRequestModal} onClose={toggleApprovalRequestModal} onSubmit={approvalRequestSubmit} />
            </If>
            <SubmitCustomModal isOpen={isOpenRefuseModal} title={t('modals.delete_attachment.refuse')} onClose={toggleRefuseModal} onSubmit={refuseSubmit} />
            <SubmitCustomModal isOpen={isOpenApprovalModal} title={t('modals.delete_attachment.approve')} onClose={toggleApprovalModal} onSubmit={() => { approvalSubmit(); toggleApprovalModal(); }} />
            <AutorizationModal isOpen={isOpenAuthorizationModal} showPrintButton={showPrintButtonInAuthorizationModal} isAdvance={props.isAdvance} onClose={toggleAuthorizationModal} onSubmit={toggleAuthorizationModal} />

            <CommentModal title={getCommentModalTitle()} isOpen={isOpenCommentModal} onSubmit={submitCommentModal} onClose={toggleCommentModal} />
            <SubmitCustomModal isOpen={isOpenReviseModal} title={t('agreement.warning.revise')} onClose={toggleReviseModal} onSubmit={() => { reviseSubmit(); toggleReviseModal(); }} />
            <SubmitCustomModal isOpen={isOpenRenewModal} title={t('agreement.warning.renew')} onClose={toggleRenewModal} onSubmit={() => { renewSubmit(); toggleRenewModal(); }} />
            <DownloadPostingsModal isOpen={isOpenDownloadPostingsModal} onClose={toggleDownloadPostingsModal} onSubmit={(date) => {toggleDownloadPostingsModal(); downloadsPostings(date)}}/>
        </div>

    )
}

export default withRouter(ButtonsControl);
