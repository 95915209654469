import { JournalActions, JOURNAL } from "./actionTypes";
import { Reducer } from "redux";
import { JournalItem } from "infrastructure/interfaces";
import { uniqBy } from "lodash";

export interface JournalState {
  journalList: JournalItem[],
  fieldPermissions: string[],
  filter: any,
  pageAll: number,
  page: number,
  isArchive: boolean
}

export const initialState: JournalState = {
  journalList: [] as JournalItem[],
  fieldPermissions: null as unknown as string[],
  filter: {},
  pageAll: 0,
  page: 1,
  isArchive: false,
}

export const reducer: Reducer<JournalState> = (state: JournalState = initialState, action) => {
  switch ((action as JournalActions).type) {
    case JOURNAL.SET_JOURNAL_LIST:
      if (action.payload.firstPage) {
        return { ...state, journalList: action.payload.journalList }
      } else {
        return { ...state, journalList: uniqBy([...state.journalList, ...action.payload.journalList], 'id') }
      }
    case JOURNAL.SET_JOURNAL_FILTER:
      return { ...state, filter: action.payload.filter }
    case JOURNAL.SET_JOURNAL_ALL_PAGE:
      return { ...state, pageAll: action.payload.pageAll }
    case JOURNAL.SET_JOURNAL_PAGE:
      return { ...state, page: action.payload.page }
    case JOURNAL.SET_JOURNAL_NEXT_PAGE:
      return { ...state, page: state.page + 1 }
    case JOURNAL.SET_JOURNAL_IS_ARCHIVE:
      return { ...state, isArchive: action.payload.isArchive }
    case JOURNAL.SET_JOURNAL_FIELD_PERMISSIONS: {
      return { ...state, fieldPermissions: action.payload.fieldPermissions }
    }
    default:
      return state;
  }
}

export default reducer;