import React, {useEffect, useRef, useState} from 'react';
import './AOExpenses.css';
import {useTranslation} from "react-i18next";
import DeleteExpenseModal from "../../component/modal/DeleteExpenseModal";
import {IAO, IOccupations} from "../../../infrastructure/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {OCCUPATION_ROLE_TYPE} from "../../../infrastructure/enum/user-role.enum";
import {get} from "lodash";
import {loaderLock, loaderUnlock} from "../../../store/common/actions";
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import ADActModal from 'app/component/modal/ADActModal';
import {getCurrencieCode, toLocaleNumberFormatter} from 'app/utils';
import {STATUS_TYPE} from 'infrastructure/enum/status-type.enum';
import agreementService from 'services/entity/agreement.service';
import moment from 'moment';
import TextCutter from 'app/component/text-cutter/TextCutter';
import { useWidth } from 'app/hooks';
import {ADVANCE_APPLICATION_PROPETIES} from "../../../infrastructure/enum/object-properties.enum";
import {getAdvanceReportApplication, getCommonStateOptions, getCommonUserDetail} from "../../../store/selectors";
import {deleteAdvanceReportAdditionalDocumentsById, getAssistancesById} from "../../../services/ApiService";
import {updateDetailedAO} from "../../../store/report/actions";

interface AOListActsProps {
    documentArr: any[];
}

const AOActs: React.FC<AOListActsProps> = props => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    let stateOptions = useSelector(getCommonStateOptions);
    const advanceReportApplication: IAO = useSelector(getAdvanceReportApplication);
    const user = useSelector(getCommonUserDetail);

    let [isOpenDeleteExpenseModal, setOpenDeleteExpenseModal] = useState(false);
    let [isOpenEditExpenseModal, setOpenEditExpenseModal] = useState(false);
    let [actId, setActId] = useState(0);
    let [isOpenActModal, setOpenActModal] = useState(false);
    let [actAccess, setActAccess] = useState({
        show: false,
        create: false,
        edit: false,
        delete: false
    });

    let nameRef = useRef(null as any);
    let width = useWidth(nameRef);

    useEffect(() => {
        initAccess();
    }, [advanceReportApplication]);

    const initAccess = async () => {
        if (!advanceReportApplication) {
            return;
        }

        let newActAccess = {
            show: false,
            create: false,
            edit: false,
            delete: false
        };

        if (advanceReportApplication && advanceReportApplication.properties && advanceReportApplication.properties.includes('ActOfEntertaimentExpenses')) {
            newActAccess.show = true;
        }

        if (user.id == advanceReportApplication.createdByEmployee.id || user.id == advanceReportApplication.assigneeEmployee.id || isCoordinatorOccupation() || await checkAssistance()/* asist corden */) {
            if (
                advanceReportApplication.status == STATUS_TYPE.DRAFT ||
                advanceReportApplication.status == STATUS_TYPE.APPROVAL_REQUIRES ||
                advanceReportApplication.status == STATUS_TYPE.REVOKED ||
                advanceReportApplication.status == STATUS_TYPE.NOT_APPROVED ||
                advanceReportApplication.status == STATUS_TYPE.NOTAPPROVED ||
                advanceReportApplication.status == STATUS_TYPE.ON_REVISION
            ) {
                newActAccess.create = true;
                newActAccess.edit = true;
                newActAccess.delete = true;
            }
        }

        if (advanceReportApplication.status == STATUS_TYPE.ON_APPROVAL && isBookerOccupation() && await userInAgreementList() ) {
            newActAccess.create = true;
            newActAccess.edit = true;
            newActAccess.delete = true;
        }

        setActAccess(newActAccess);
    };

    const isBookerOccupation = (): boolean => {
        let occupations = user.occupations.map((occupation: IOccupations) => occupation.roles).flat().map((item) => item.code);
        return occupations.includes(OCCUPATION_ROLE_TYPE.BOOKER);
    }

    const isCoordinatorOccupation = (): boolean => {
        let occupations = user.occupations.map((occupation: IOccupations) => occupation.roles).flat().map((item) => item.code);
        return occupations.includes(OCCUPATION_ROLE_TYPE.COORDINATOR);
    }

    const userInAgreementList = async () => {
        let agreementList = await agreementService.getList();

        return agreementList && agreementList.approvalTiers.flatMap(item => item.employees).filter(item => item.id == user.id).length > 0;
    }

    const checkAssistance = async () => {
        if (advanceReportApplication && advanceReportApplication.assigneeEmployee && advanceReportApplication.assigneeEmployee.id) {
            let massRequest = user.occupations.map(occupation => getAssistancesById(occupation.occupationId));
            let massResponse = await Promise.all(massRequest);
            let massList = massResponse.map(response => {
                if (response.headers.success) {
                    let assistancesList = get(response, 'data.value.assistances', []);
                    return assistancesList.map((item: any) => item.employeeId);
                }
                return [];
            }).flat();

            return massList.includes(advanceReportApplication.assigneeEmployee.id);
        }

        return false;
    }

    const handleActAdd = async () => {
        await setActId(0);
        setOpenActModal(true);
    }

    const handleClose = () => {
        setOpenActModal(false);
    }

    const handleComplete = () => {
        setOpenActModal(false);
        dispatch(updateDetailedAO(advanceReportApplication.id));
    }

    const handleActEdit = async (id: number) => {
        await setActId(id);
        setOpenActModal(true);
    }

    const handleActDelete = async (id: number) => {
        dispatch(loaderLock());
        let result = await deleteAdvanceReportAdditionalDocumentsById(id);

        if (result.headers.success) {
            dispatch(updateDetailedAO(advanceReportApplication.id));
        }
        dispatch(loaderUnlock());
    }


    const toggleEditExpenseModal = () => {
        setOpenEditExpenseModal(!isOpenEditExpenseModal);
    }

    const toggleDeleteExpenseModal = () => {
        setOpenDeleteExpenseModal(!isOpenDeleteExpenseModal);
    }


    const toggleAddExpenseModal = () => {

    };

    const handleDelete = async () => {
        dispatch(loaderLock());
        dispatch(loaderUnlock());
    }

    return (
        <>
            <div className="request-expenses" style={{ display: advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES) ? undefined : 'none' }}>
                <div className="request-expenses-title" style={{ height: '40px' }}>
                    <span>{t('request_detail.application_expenses.representationExpenses')}</span>
                    {advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES_UPDATE) ? <a className="btn btn_gray pointer" onClick={handleActAdd} style={{ width: '100px', height: '40px', textTransform: 'none', fontSize: '20px' }}>
                        {t('request_detail.application_expenses.addAct')}
                    </a> : null}
                </div>
                <div className="request-expenses-wrap">
                    {props.documentArr.length ? props.documentArr.map((item) => {
                        return <div className="request-expenses-item" style={{ paddingLeft: '5px' }}>
                            <div className="request-expenses-block info" ref={nameRef}>
                                <div className="request-expenses-item-text" style={{order:1}}>{t('request_detail.application_expenses.actTitle', { actNumber: item.actNumber })}</div>
                                <div className="request-item-name" style={{order:2}} >
                                    <TextCutter
                                        text={item.description}
                                        parentWidth={width}
                                        defaultLength={50}
                                        options={[
                                            { maxLength: 50, minWidth: 401, maxWidth: 900, symbolSize: 29 },
                                            { maxLength: 20, minWidth: 0, maxWidth: 400, symbolSize: 29 }
                                        ]}
                                    />
                                </div>
                                <div className="request-expenses-item-file" style={{ fontSize: '14px' }}>
                                    <div><TextCutter
                                        text={item.meetingPlace}
                                        parentWidth={width}
                                        defaultLength={75}
                                        options={[
                                            { maxLength: 75, minWidth: 401, maxWidth: 900, symbolSize: 14 },
                                            { maxLength: 20, minWidth: 0, maxWidth: 400, symbolSize: 14 }
                                        ]}
                                    /> {moment(item.meetingDate).format('DD.MM.YYYY')}</div>
                                </div>
                            </div>
                            <div className="request-expenses-block">
                                <div className="request-list-item-status">{t('request_detail.application_expenses.totalAmountTitle')}<span>
                                </span>
                                </div>
                            </div>
                            <div className="flex-sm-row flex-sm-between">
                                <div>
                                    <div className="request-list-item-cost">{`${toLocaleNumberFormatter(item.totalAmount)} ${getCurrencieCode('RUB')}`}</div>
                                </div>
                                <div className="request-expenses-block controls">
                                    <SvgIcon className="icon icon-more pointer" href="#svg_icon_more" />
                                    <div className="request-files-item-dropdown" style={{ display: 'none', top: '40px' }}>
                                        {advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES_UPDATE) ? <a className="pointer" style={{ display: 'flex', marginBottom: '25px' }} onClick={() => { handleActEdit(item.id) }}>
                                            <SvgIcon className="icon icon-edit" href="#svg_icon_edit" />
                                            {t('request_detail.application_expenses.edit')}
                                        </a> : null}
                                        {advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES_UPDATE) ? <a className="pointer" style={{ display: 'flex' }} onClick={() => { handleActDelete(item.id) }}>
                                            <SvgIcon className="icon icon-remove" href="#svg_icon_remove" />
                                            {t('request_detail.application_expenses.delete')}
                                        </a> : null}
                                    </div>
                                    <div className="controls-dropdown">
                                        {advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES_UPDATE) ? <a className="pointer" onClick={() => { handleActEdit(item.id) }}>
                                            <SvgIcon className="icon icon-edit" href="#svg_icon_edit" />
                                        </a> : null}
                                        {advanceReportApplication.properties.includes(ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES_UPDATE) ? <a className="delete pointer" onClick={() => { handleActDelete(item.id) }}>
                                            <SvgIcon className="icon icon-remove" href="#svg_icon_remove" />
                                        </a> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : null
                    }
                </div>
                <ADActModal isOpen={isOpenActModal} editId={actId} documentId={advanceReportApplication.id} onClose={handleClose} onComplete={handleComplete} />

                <DeleteExpenseModal id={0} isOpen={isOpenDeleteExpenseModal} onSubmit={handleDelete}
                    onClose={toggleDeleteExpenseModal} />
            </div>
        </>
    )
}

export default AOActs;
