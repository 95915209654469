import { BaseMapper } from './base.mapper';
import { BasicType } from 'infrastructure/interfaces';
import { get } from 'lodash';

export class BasicTypeMapper extends BaseMapper<BasicType, any> {
  responseToEntity(response: any): BasicType {
    return {
      id: get(response, 'id', 0),
      name:{
        ru: get(response, 'name.ru', 'Имя[STUB]'),
        en: get(response, 'name.en', 'Name[STUB]')
      }
    };
  };
}
