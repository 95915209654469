import * as React from 'react';

/**
 * interface to configure navigation var in date picker
 */
interface NavBarDatePickerProps {
  onPreviousClick: () => void,
  onNextClick: () => void,
  className: any,

  isDisableBeforeToday?: boolean,
  month: Date,
}

/**
 * Custom navigation bar in date picker
 */
const NavBar: React.FC<NavBarDatePickerProps> = (prop) => {
  const isDisablePreviewMonth = () => {
    return prop.isDisableBeforeToday && prop.month.getMonth() === new Date().getMonth()
  }

  return (
      <div className={prop.className}>
        <div style={{
          position: 'absolute',
          left: '20px',
          top: '29px',
          cursor:'pointer',
          pointerEvents: isDisablePreviewMonth() ? 'none' : 'auto',
          opacity: isDisablePreviewMonth() ? 0.5 : 1
        }} onClick={() => prop.onPreviousClick()}>
          <div className="icon icon-arrow-left">
            <svg>
              <use x="0" y="0" href="#svg_icon_arrow_right"></use>
            </svg>
          </div>
        </div>
        <div style={{position: 'absolute', right: '20px', top: '29px', cursor:'pointer'}} onClick={() => prop.onNextClick()}>
          <div className="icon icon-arrow-right">
            <svg>
              <use x="0" y="0" href="#svg_icon_arrow_right"></use>
            </svg>
          </div>
        </div>
      </div>
  );
};

export default NavBar;