import { createSelector } from "reselect";
import { ApplicationState } from 'store/reducers';

//EXCEPTION
export const getException = (state: ApplicationState) => state.exception;

export const getExceptionArray = createSelector(getException, ({exceptionArray}) => exceptionArray);

export const getExceptionInfoArray = createSelector(getException, ({infoArray}) => infoArray);

export const getIsOpenExceptionModal = createSelector(getException, ({isOpenExceptionModal}) => isOpenExceptionModal);

//COMMON
export const getCommon = (state: ApplicationState) => state.common;

export const getCommonUserDetail = createSelector(getCommon, ({userDetail}) => userDetail);

export const getCommonUserDetailEmail = createSelector(getCommonUserDetail, ({email}) => email);

export const getCommonModalIdCounter = createSelector(getCommon, ({modalIdCounter}) => modalIdCounter);

export const getCommonBusinessGoals = createSelector(getCommon, ({businessGoals}) => businessGoals);

export const getCommonPostings = createSelector(getCommon, ({postings}) => postings);

export const getCommonIsArchivedPostings = createSelector(getCommon, ({isArchivedPostings}) => isArchivedPostings);

export const getCommonTransactionBatchId = createSelector(getCommon, ({transactionBatchId}) => transactionBatchId);

export const getCommonExpenseTypes = createSelector(getCommon, ({expenseTypes}) => expenseTypes);

export const getCommonOpenModal = createSelector(getCommon, ({openModal}) => openModal);

export const getCommonOnboardState = createSelector(getCommon, ({onboardState}) => onboardState);

export const getCommonFilterState = createSelector(getCommon, ({filterState}) => filterState);

export const getCommonFilterParams = createSelector(getCommon, ({filterParams}) => filterParams);

export const getCommonChatState = createSelector(getCommon, ({chatState}) => chatState);

export const getCommonHistoryState = createSelector(getCommon, ({historyState}) => historyState);

export const getCommonHistoryNeedUpdating = createSelector(getCommon, ({historyNeedUpdating}) => historyNeedUpdating);

export const getCommonBusinessTargetsCatalog = createSelector(getCommon, ({businessTargetsCatalog}) => businessTargetsCatalog);

export const getCommonExpenseTypesCatalog = createSelector(getCommon, ({expenseTypesCatalog}) => expenseTypesCatalog);

export const getCommonIsAttachmentsToggle = createSelector(getCommon, ({isAttachmentsToggle}) => isAttachmentsToggle);

export const getCommonClearingData = createSelector(getCommon, ({clearingData}) => clearingData);

export const getCommonConsumptionTypes = createSelector(getCommon, ({consumptionTypes}) => consumptionTypes);

export const getCommonPaymentForms = createSelector(getCommon, ({paymentForms}) => paymentForms);

export const getCommonCurrencies = createSelector(getCommon, ({currencies}) => currencies);

export const getCommonCurrencyRate = createSelector(getCommon, ({currencyRate}) => currencyRate);

export const getCommonApprovedCurrencyRate = createSelector(getCommon, ({approvedCurrencyRate}) => approvedCurrencyRate);

export const getCommonManualExpenseCurrencyPermission = createSelector(getCommon, ({manualExpenseCurrencyPermission}) => manualExpenseCurrencyPermission);

export const getCommonIsAOManualExpenseCurrencyPermission = createSelector(getCommonManualExpenseCurrencyPermission, ({isAOManualExpenseCurrencyPermission}) => isAOManualExpenseCurrencyPermission);

export const getCommonAttachmentPermission = createSelector(getCommon, ({attachmentPermission}) => attachmentPermission);

export const getCommonStateOptions = createSelector(getCommon, ({stateOptions}) => stateOptions);

export const getCommonAttachmentsExpense = createSelector(getCommon, ({attachmentsExpense}) => attachmentsExpense);

export const getCommonNoticePanel = createSelector(getCommon, ({noticePanel}) => noticePanel);

export const getCommonNoticePanelIsOpen = createSelector(getCommonNoticePanel, ({open}) => open);

export const getCommonNoticePanelNewMessagesCount = createSelector(getCommonNoticePanel, ({newMessageCount}) => newMessageCount);

export const getCommonCompaniesCatalog = createSelector(getCommon, ({companiesCatalog}) => companiesCatalog);

export const getCommonLoaderState = createSelector(getCommon, ({loaderState}) => loaderState);

export const getCommonAttachmentList = createSelector(getCommon, ({attachmentList}) => attachmentList);

//LOGO
export const getLogo = (state: ApplicationState) => state.logo;

//JOURNAL
export const getJournal = (state: ApplicationState) => state.journal;

export const getJournalIsArchive = createSelector(getJournal, ({isArchive}) => isArchive);

export const getJournalFilter = createSelector(getJournal, ({filter}) => filter);

export const getJournalList = createSelector(getJournal, ({journalList}) => journalList);


//APPLICATION FOR EXPENSE
export const getApplicationForExpense = (state: ApplicationState) => state.applicationForExpense;

export const getApplicationForExpenseDetail = createSelector(getApplicationForExpense, ({applicationForExpenseDetail}) => applicationForExpenseDetail);

export const getApplicationForExpenseList = createSelector(getApplicationForExpense, ({applicationForExpenseList}) => applicationForExpenseList);

export const getIsApplicationForExpenseDetailDownloaded = createSelector(getApplicationForExpense, ({isApplicationForExpenseDetailDownloaded}) => isApplicationForExpenseDetailDownloaded);

//ADVANCE REPORT
export const getAdvanceReport = (state: ApplicationState) => state.advanceReport;

export const getAdvanceReportApplication = createSelector(getAdvanceReport, ({advanceReportApplication}) => advanceReportApplication);

export const getAdvanceReportIsReportListDownloaded = createSelector(getAdvanceReport, ({isReportListDownloaded}) => isReportListDownloaded);

export const getAdvanceReportList = createSelector(getAdvanceReport, ({advanceReportList}) => advanceReportList);

export const getAdvanceReportIsReportDetailDownloaded = createSelector(getAdvanceReport, ({isReportDetailDownloaded}) => isReportDetailDownloaded);

//AGREEMENT
export const getAgreement = (state: ApplicationState) => state.agreement;

export const getAgreementList = createSelector(getAgreement, ({agreementList}) => agreementList);

//SUBSTITUTION
export const getSubstitution = (state: ApplicationState) => state.substitution;

export const getSubstitutionList = createSelector(getSubstitution, ({substitutionList}) => substitutionList);

export const getSubstitutionItemsCount = createSelector(getSubstitution, ({itemsCount}) => itemsCount);




