/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  ReactNode,
  useEffect,
} from "react";
import "./CustomProperty.css";
import {
  loaderLock,
  loaderUnlock,
  setCostCenterData,
  setCostCenterIsChanged,
  setSubcontoData,
  setSubcontoIsChanged,
} from "store/common/actions";
import { useDispatch, useSelector } from "react-redux";
import Select, { SelectOptionType } from "../select/Select";
import SvgIcon from "../svg-icon/SvgIcon";
import CostCenterPercentAutocomplete from "../autocomplete/CostCenterPercentAutocomplete";
import SelectAutocomplete from "../autocomplete/SelectAutocomplete";
import {
  getNextKey,
  capitalizeFirstLetter,
  formatter,
  toDate,
} from "app/utils";
import qs from "qs";
import { ApplicationState } from "store/reducers";
import { useTranslation } from "react-i18next";
import { uniq, get, toString } from "lodash";
import { showErrors } from "store/exception/actions";
import Tooltip from "../tooltip/Tooltip";
import DatePickerCalendar from "../date-picker/DatePickerCalendar";
import { getCommonUserDetail } from "../../../store/selectors";
import {
  getCustomProperties,
  getCustomPropertiesById,
  getCustomPropertiesCodes,
  getCustomPropertiesValuesByIds,
  getCustomPropertyListValues,
  getCustomPropertyListValuesDependencies,
  getLogicalName,
  postCustomPropertiesListValues,
  postCustomPropertyValuesByObject,
  updateCustomPropertyValues,
} from "../../../services/ApiService";
import { CustomPropertyData } from "../../../infrastructure/interfaces";
import api from "../../../services/interceptor";
import { Form } from "react-bootstrap";
import {
  defaultPercentOptions,
  widgetPercentOptions,
} from "../../../infrastructure/constants/PercentOptions";

interface CustomPropertyProps {
  id?: number;
  isEdit?: boolean;
  isZNR?: boolean;
  isAO?: boolean;
  isExpense?: boolean;
  isTransaction?: boolean;
  permissions: string[];
  occupationsId?: number;
  companyId?: number;
  tabIndex?: -1 | 0;
  dataExpense: any;
  type: string;
  isWidget?: boolean;
}

interface CustomField {
  refId: number;
  id: number;
  editId: number;
  parentKey: string;
  key: string;
  name: string;
  type: string;
  ref: any;
  size: number;
  required: boolean;
  regexp: string;
  regexpText: string;
  codeName: string;
  isChangeable: boolean;
  isVisible: boolean;
  defaultValue: {
    listValueId: number;
    nameRu: string;
    nameEn: string;
  };
}

const CustomProperty: React.FC<CustomPropertyProps> = (props, ref) => {
  let { t, i18n } = useTranslation();

  let [сustomFields, setCustomFields] = useState([] as CustomField[]);
  let [deleteDependentCF, setDeleteDependentCF] = useState([] as any[]);
  let [deleteAllocationRow, setDeleteAllocationRow] = useState([] as any[]);
  let [objectLogicalNameId, setObjectLogicalNameId] = useState(0);
  let [permissions, setPermissions] = useState([] as string[]);
  let [customProperty, setCustomProperty] = useState({} as CustomPropertyData);

  const dispatch = useDispatch();
  const subcontoIsChanged = useSelector(
    (state: ApplicationState) => state.common.subcontoIsChanged
  );
  const userDetail = useSelector(getCommonUserDetail);

  let rawCP = [] as {
    id: number;
    editId: number;
    name: string;
    fieldSize: number;
    isSelect: boolean;
    isDate: boolean;
    IsAllocationAvailable: boolean;
    ordinal: number;
    isRequired: boolean;
    codeName: string;
    isChangeable: boolean;
    regexp: string;
    regexpText: string;
    isVisible: boolean;
    defaultValue: {
      listValueId: number;
      nameRu: string;
      nameEn: string;
    };
  }[];

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return сustomFields.map((item) => item.ref.current.getValue());
    },
    validate: () => {
      let validateResults = сustomFields.map((item) =>
        item.ref.current.validate()
      );
      return !validateResults.includes(false);
    },
    saveById: async (id: number, companyId?: number) => {
      await save(id, companyId);
    },
    edit: async () => {
      await edit();
    },
    build: async () => {
      await build();
    },
    clear: async () => {
      await clear();
    },
    rebuild: async () => {
      await rebuild();
    },
  }));

  useEffect(() => {
    setPermissions(
      props.permissions
        .filter((item) => item.includes("CustomProperty"))
        .map((item) => item.substr("CustomProperty".length + 1))
    );
  }, [props.permissions]);

  const getObjectLogicalId = async () => {
    let objectLogicalName = "";

    if (props.isZNR) {
      objectLogicalName = "ExpenseApplication";
    }

    if (props.isAO) {
      objectLogicalName = "AdvanceReportApplication";
    }

    if (props.isExpense) {
      objectLogicalName = "Expense";
    }

    if (props.isTransaction) {
      objectLogicalName = "Transaction";
    }

    let objectLogicalNameId = 0;

    let resultON = await getLogicalName({
      params: { NameEn: objectLogicalName },
    });
    if (resultON.headers.success) {
      objectLogicalNameId = resultON.data[0].id;
      setObjectLogicalNameId(objectLogicalNameId);
    }

    return objectLogicalNameId;
  };

  const clear = async () => {
    сustomFields.forEach((item) => {
      item.ref.current.clear();
    });
  };

  const rebuild = async () => {
    dispatch(loaderLock());

    let objectLogicalNameId = await getObjectLogicalId();

    let occupationsId = props.occupationsId
      ? props.occupationsId
      : userDetail.occupations[0]
      ? userDetail.occupations[0].occupationId
      : undefined;
    let companyId = props.companyId
      ? props.companyId
      : userDetail.occupations[0]
      ? userDetail.occupations[0].company.id
      : undefined;

    let request = await api.get(`/customproperties`, {
      params: {
        CompanyId: companyId,
        ObjectLogicalNameId: objectLogicalNameId,
        EmployeeID: userDetail.id,
        OccupationID: occupationsId,
      },
    });
    if (request.headers.success) {
      rawCP = request.data.map((item: any) => {
        //filter
        return {
          id: item.id,
          name: item["clientName" + capitalizeFirstLetter(i18n.language)],
          fieldSize: item.fieldSize,
          isSelect: item.isList,
          isDate: item.isDate,
          IsAllocationAvailable: item.isAllocationAvailable,
          ordinal: item.ordinal,
          isRequired: item.isRequired,
          codeName: item.codeName,
          isChangeable: item.isChangeable,
          regexp: item.regularExpression,
          regexpText:
            item[
              "regularExpressionError" + capitalizeFirstLetter(i18n.language)
            ],
          isVisible: item.isVisible,
          defaultValue: {
            listValueId: get(item, "defaultListValue.listValueId", 0),
            nameRu: get(item, "defaultListValue.nameRu", ""),
            nameEn: get(item, "defaultListValue.nameEn", ""),
          },
        };
      });
    } else {
      dispatch(
        showErrors({ code: "", message: "Не удалось получить доп. данные" })
      );
    }
    rawCP = rawCP.sort((a: any, b: any) => a.ordinal - b.ordinal);

    let rusultCP = [] as CustomField[];

    rusultCP = rawCP.map((item) => {
      let itemType = "text";

      if (item.isSelect) {
        itemType = "select";
      }

      if (item.IsAllocationAvailable) {
        itemType = "allocation";
      }

      if (item.isDate) {
        itemType = "date";
      }

      return {
        refId: getNextKey("CPID"),
        id: item.id,
        editId: 0,
        parentKey: "",
        key: String(getNextKey("CPKey")),
        name: item.name,
        type: itemType,
        ref: React.createRef(),
        size: item.fieldSize,
        required: item.isRequired,
        regexp: item.regexp,
        regexpText: item.regexpText,
        codeName: item.codeName,
        isChangeable: item.isChangeable,
        isVisible: item.isVisible,
        defaultValue: item.defaultValue,
      };
    });

    await setCustomFields(rusultCP);

    for (let index = 0; index < rusultCP.length; index++) {
      await rusultCP[index].ref.current.build();
    }
    setCustomProperty({} as CustomPropertyData);
    dispatch(loaderUnlock());
  };

  const build = async () => {
    dispatch(loaderLock());

    let objectLogicalNameId = await getObjectLogicalId();

    let occupationsId = props.occupationsId
      ? props.occupationsId
      : userDetail.occupations[0]
      ? userDetail.occupations[0].occupationId
      : undefined;
    let companyId = props.companyId
      ? props.companyId
      : userDetail.occupations[0]
      ? userDetail.occupations[0].company.id
      : undefined;

    let cPRequest = await getCustomProperties({
      params: {
        CompanyId: companyId,
        ObjectLogicalNameId: objectLogicalNameId,
        EmployeeID: userDetail.id,
        OccupationID: occupationsId,
      },
    });
    if (cPRequest.headers.success) {
      rawCP = cPRequest.data.map((item: any) => {
        //filter
        return {
          id: item.id,
          name: item["clientName" + capitalizeFirstLetter(i18n.language)],
          fieldSize: item.fieldSize,
          isSelect: item.isList,
          isDate: item.isDate,
          IsAllocationAvailable: item.isAllocationAvailable,
          ordinal: item.ordinal,
          isRequired: item.isRequired,
          codeName: item.codeName,
          isChangeable: item.isChangeable,
          regexp: item.regularExpression,
          regexpText:
            item[
              "regularExpressionError" + capitalizeFirstLetter(i18n.language)
            ],
          isVisible: item.isVisible,
          defaultValue: {
            listValueId: get(item, "defaultListValue.listValueId", 0),
            nameRu: get(item, "defaultListValue.nameRu", ""),
            nameEn: get(item, "defaultListValue.nameEn", ""),
          },
        };
      });
    } else {
      dispatch(
        showErrors({ code: "", message: "Не удалось получить доп. данные" })
      );
    }
    rawCP = rawCP.sort((a: any, b: any) => a.ordinal - b.ordinal);

    let rusultCP = [] as CustomField[];

    rusultCP = rawCP.map((item) => {
      let itemType = "text";

      if (item.isSelect) {
        itemType = "select";
      }

      if (item.IsAllocationAvailable) {
        itemType = "allocation";
      }

      if (item.isDate) {
        itemType = "date";
      }

      return {
        refId: getNextKey("CPID"),
        id: item.id,
        editId: 0,
        parentKey: "",
        key: String(getNextKey("CPKey")),
        name: item.name,
        type: itemType,
        ref: React.createRef(),
        size: item.fieldSize,
        required: item.isRequired,
        regexp: item.regexp,
        regexpText: item.regexpText,
        codeName: item.codeName,
        isChangeable: item.isChangeable,
        isVisible: item.isVisible,
        defaultValue: item.defaultValue,
      };
    });

    await setCustomFields(rusultCP);

    for (let index = 0; index < rusultCP.length; index++) {
      await rusultCP[index].ref.current.build();
    }
    setCustomProperty({} as CustomPropertyData);

    //for edit
    if (props.isEdit) {
      let cPCodes = cPRequest.data.map((item: any) => item.codeName);
      if (cPCodes.length > 0) {
        let result = await getCustomPropertiesCodes({
          params: {
            ObjectLogicalNameId: objectLogicalNameId,
            ExternalObjectId: props.id,
            CustomPropertyCodes: cPCodes,
          },
          paramsSerializer: (params: any) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        });
        if (result.headers.success) {
          for (let indexEV = 0; indexEV < result.data.length; indexEV++) {
            let elementValue = result.data[indexEV];

            for (let indexCp = 0; indexCp < rusultCP.length; indexCp++) {
              const elementCP = rusultCP[indexCp];
              if (elementValue.customPropertyId === elementCP.id) {
                switch (elementCP.type) {
                  case "text":
                    elementCP.editId = elementValue.id;
                    //elementCP.key = elementValue.id;

                    elementCP.ref.current.setValue(
                      elementValue[
                        "name" + capitalizeFirstLetter(i18n.language)
                      ]
                    );
                    elementCP.ref.current.setStartValue(
                      elementValue[
                        "name" + capitalizeFirstLetter(i18n.language)
                      ]
                    );
                    break;
                  case "date":
                    elementCP.editId = elementValue.id;

                    elementCP.ref.current.setValue(
                      elementValue[
                        "name" + capitalizeFirstLetter(i18n.language)
                      ]
                    );
                    elementCP.ref.current.setStartValue(
                      elementValue[
                        "name" + capitalizeFirstLetter(i18n.language)
                      ]
                    );
                    break;
                  case "select":
                    elementCP.editId = elementValue.id;

                    elementCP.ref.current.setValue({
                      text: elementValue[
                        "name" + capitalizeFirstLetter(i18n.language)
                      ],
                      value: elementValue.listValueId,
                    });
                    elementCP.ref.current.setStartValue({
                      text: elementValue[
                        "name" + capitalizeFirstLetter(i18n.language)
                      ],
                      value: elementValue.listValueId,
                    });
                    break;
                  case "allocation":
                    let isDefault = false;
                    elementCP.ref.current
                      .getValue()
                      .value.forEach((item: any) => {
                        if (item.isDefault) {
                          isDefault = true;
                        }
                      });

                    if (isDefault) {
                      elementCP.ref.current.clear();
                    }
                    await elementCP.ref.current.addRow({
                      id: elementValue.id,
                      value: elementValue.listValueId,
                      name: elementValue[
                        "name" + capitalizeFirstLetter(i18n.language)
                      ],
                      percent: elementValue.percent,
                      validSelect: true,
                      validPercent: true,
                      editedValue: true,
                    });
                    break;
                }
              }
            }
          }
        } else {
          dispatch(
            showErrors({ code: "", message: "Не удалось получить доп. данные" })
          );
        }
      }
    }

    //for default

    rusultCP
      .filter(
        (item: any) =>
          (item.type === "select" || item.type === "allocation") &&
          item.defaultValue &&
          item.defaultValue.listValueId !== 0
      )
      .forEach((item: any) => {
        if (
          item.type === "select" &&
          item.ref.current.getValue().value.value === 0
        ) {
          item.ref.current.setValue({
            value: item.defaultValue.listValueId,
            text: item.defaultValue[
              ("name" + capitalizeFirstLetter(i18n.language)) as "nameRu"
            ],
          });
          item.ref.current.setStartValue({
            value: item.defaultValue.listValueId,
            text: item.defaultValue[
              ("name" + capitalizeFirstLetter(i18n.language)) as "nameRu"
            ],
          });
        }

        if (
          item.type === "allocation" &&
          item.ref.current.getValue().value.length === 0
        ) {
          item.ref.current.setValue([
            {
              id: `${item.id}-${getNextKey("CPAllocation")}`,
              value: item.defaultValue.listValueId,
              name: item.defaultValue[
                ("name" + capitalizeFirstLetter(i18n.language)) as "nameRu"
              ],
              percent: 100,
              validSelect: true,
              validPercent: true,
              isDefault: true,
            },
          ]);
          item.ref.current.setStartValue([
            {
              id: `${item.id}-${getNextKey("CPAllocation")}`,
              value: item.defaultValue.listValueId,
              name: item.defaultValue[
                ("name" + capitalizeFirstLetter(i18n.language)) as "nameRu"
              ],
              percent: 100,
              validSelect: true,
              validPercent: true,
            },
          ]);
        }
      });
    dispatch(loaderUnlock());
  };

  const render = (): ReactNode[] => {
    let result = [] as ReactNode[];
    let visibleCustomFields = сustomFields;

    for (let index = 0; index < visibleCustomFields.length; ) {
      if (visibleCustomFields[index].type === "allocation") {
        result.push(generateCF(visibleCustomFields[index]));
        index++;
        continue;
      }

      if (visibleCustomFields[index].size === 1) {
        result.push(
          <div
            className="input-item-row"
            key={visibleCustomFields[index].refId}
            style={{
              display: visibleCustomFields[index].isVisible
                ? undefined
                : "none",
            }}
          >
            {generateCF(visibleCustomFields[index])}
          </div>
        );
        index++;
        continue;
      }

      if (
        visibleCustomFields[index].size === 2 &&
        visibleCustomFields[index + 1] &&
        visibleCustomFields[index + 1].size === 2
      ) {
        result.push(
          <div
            className="input-item-row"
            key={visibleCustomFields[index].refId}
            style={{
              display: visibleCustomFields[index].isVisible
                ? undefined
                : "none",
            }}
          >
            {generateCF(visibleCustomFields[index])}
            {generateCF(visibleCustomFields[index + 1])}
          </div>
        );
        index += 2;
        continue;
      }

      if (
        visibleCustomFields[index].size === 2 &&
        visibleCustomFields[index + 1] &&
        visibleCustomFields[index + 1].size === 1
      ) {
        result.push(
          <div
            className="input-item-row"
            key={visibleCustomFields[index].refId}
            style={{
              display: visibleCustomFields[index].isVisible
                ? undefined
                : "none",
            }}
          >
            {generateCF(visibleCustomFields[index])}
          </div>
        );
        index++;
        continue;
      }

      if (
        visibleCustomFields[index].size === 2 &&
        !visibleCustomFields[index + 1]
      ) {
        result.push(
          <div
            className="input-item-row"
            key={visibleCustomFields[index].refId}
            style={{
              display: visibleCustomFields[index].isVisible
                ? undefined
                : "none",
            }}
          >
            {generateCF(visibleCustomFields[index])}
          </div>
        );
        index++;
        continue;
      }

      index++;
    }

    return result;
  };

  const changeDependency = async (
    id: number,
    listValueId: number[],
    propertyId: number[],
    key: string
  ) => {
    let newCPIds = [...propertyId];
    let newCPСodeNames = [] as string[];
    let mapCPValues = new Map();
    let newDependentCP = [] as CustomField[];

    if (listValueId.length + propertyId.length === 0) {
      //return;
    }

    if (listValueId.length !== 0) {
      let result = await getCustomPropertiesValuesByIds({
        params: { Ids: listValueId },
        paramsSerializer: (params: any) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });

      if (result.headers.success) {
        result.data.forEach((item: any) => {
          if (!newCPIds.includes(item.customPropertyId)) {
            newCPIds.push(item.customPropertyId);
          }
          if (!mapCPValues.has(item.customPropertyId)) {
            mapCPValues.set(item.customPropertyId, [item.id]);
          } else {
            mapCPValues.get(item.customPropertyId).push(item.id);
          }
        });
      }
    }

    for (let index = 0; index < newCPIds.length; index++) {
      let result2 = await getCustomPropertiesById(newCPIds[index]);
      if (result2.headers.success) {
        let cpType = "text";

        if (result2.data.isList) {
          cpType = "select";
        }

        if (result2.data.isAllocationAvailable) {
          cpType = "allocation";
        }

        if (result2.data.isDate) {
          cpType = "date";
        }

        newDependentCP.push({
          refId: getNextKey("CPID"),
          id: result2.data.id,
          parentKey: key,
          key: String(getNextKey("CPKey")),
          editId: 0,
          name: result2.data[
            "clientName" + capitalizeFirstLetter(i18n.language)
          ],
          type: cpType,
          ref: React.createRef(),
          size: result2.data.fieldSize,
          required: result2.data.isRequired,
          regexp: result2.data.regularExpression,
          regexpText:
            result2.data[
              "regularExpressionError" + capitalizeFirstLetter(i18n.language)
            ],
          codeName: result2.data.codeName,
          isChangeable: result2.data.isChangeable,
          isVisible: result2.data.isVisible,
          defaultValue: {
            listValueId: get(result2.data, "defaultListValue.listValueId", 0),
            nameRu: get(result2.data, "defaultListValue.nameRu", ""),
            nameEn: get(result2.data, "defaultListValue.nameEn", ""),
          },
        });
        newCPСodeNames.push(result2.data.codeName);
      }
    }

    let parentIds = [key];

    сustomFields.forEach((item) => {
      if (parentIds.includes(item.parentKey)) {
        parentIds.push(item.key);
      }
    });

    let oldDependentCPValue = сustomFields
      .filter((item) => parentIds.includes(item.parentKey))
      .map((item) => ({ ...item.ref.current.getValue(), key: item.key }));
    let deleteDependentCPValue = сustomFields
      .filter((item) => parentIds.includes(item.parentKey))
      .map((item) => item);
    let newCustomFields = [
      ...сustomFields.filter((item) => !parentIds.includes(item.parentKey)),
    ];

    await setCustomFields(newCustomFields);

    newDependentCP.forEach((dependentCP) => {

      let newCustomFields2 = [] as CustomField[];
      let notPushed = true;

      for (let index = newCustomFields.length - 1; index >= 0; index--) {
        const element = newCustomFields[index];
        let parentKeys = [element.key];
        if (element.type === "allocation") {
          parentKeys = element.ref.current.getValue().value.map((item: any) => {
            return item.id;
          });
        }

        let dependentCPParentKey = dependentCP.parentKey;

        if (parentKeys.includes(dependentCPParentKey)) {
          if (notPushed) {
            notPushed = false;
            newCustomFields2.push(dependentCP);
          }

          newCustomFields2.push(element);
        } else {
          newCustomFields2.push(element);
        }
      }

      let newCustomFields3 = [] as CustomField[];
      for (let index = newCustomFields2.length - 1; index >= 0; index--) {
        newCustomFields3.push(newCustomFields2[index]);
      }

      newCustomFields = newCustomFields3;
    });

    await setCustomFields(newCustomFields);

    for (let index = 0; index < newDependentCP.length; index++) {
      let element = newDependentCP[index];
      await element.ref.current.build();
      if (element.type === "select" || element.type === "allocation") {
        await element.ref.current.setAllowedValue(
          mapCPValues.has(element.id) ? mapCPValues.get(element.id) : []
        );
      }
      oldDependentCPValue.forEach((oldItem) => {
        if (element.key === oldItem.key) {
          element.ref.current.setValue(oldItem.value);
        }
      });
    }

    await setDeleteDependentCF([
      ...deleteDependentCF,
      ...deleteDependentCPValue,
    ]);

    newDependentCP
      .filter(
        (item) =>
          (item.type === "select" || item.type === "allocation") &&
          item.defaultValue &&
          item.defaultValue.listValueId !== 0
      )
      .forEach((item) => {
        if (item.type === "select") {
          item.ref.current.setValue({
            value: item.defaultValue.listValueId,
            text: item.defaultValue[
              ("name" + capitalizeFirstLetter(i18n.language)) as "nameRu"
            ],
          });
          item.ref.current.setStartValue({
            value: item.defaultValue.listValueId,
            text: item.defaultValue[
              ("name" + capitalizeFirstLetter(i18n.language)) as "nameRu"
            ],
          });
        } else if (item.type === "allocation") {
          item.ref.current.setValue([
            {
              id: getNextKey("CPAllocation" + item.id),
              value: item.defaultValue.listValueId,
              name: item.defaultValue[
                ("name" + capitalizeFirstLetter(i18n.language)) as "nameRu"
              ],
              percent: 100,
              validSelect: true,
              validPercent: true,
            },
          ]);
          item.ref.current.setStartValue([
            {
              id: getNextKey("CPAllocation" + item.id),
              value: item.defaultValue.listValueId,
              name: item.defaultValue[
                ("name" + capitalizeFirstLetter(i18n.language)) as "nameRu"
              ],
              percent: 100,
              validSelect: true,
              validPercent: true,
            },
          ]);
        }
      });

    if (props.isEdit && newCPСodeNames.length > 0) {
      let result = await getCustomPropertiesCodes({
        params: {
          ObjectLogicalNameId: objectLogicalNameId,
          ExternalObjectId: props.id,
          CustomPropertyCodes: newCPСodeNames,
        },
        paramsSerializer: (params: any) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      });
      if (result.headers.success) {
        for (let indexEV = 0; indexEV < result.data.length; indexEV++) {
          const elementValue = result.data[indexEV];
          for (let indexCP = 0; indexCP < newCustomFields.length; indexCP++) {
            const elementCP = newCustomFields[indexCP];

            if (
              elementValue.customPropertyId === elementCP.id &&
              elementValue.parentId === elementCP.parentKey
            ) {
              switch (elementCP.type) {
                case "text":
                  elementCP.editId = elementValue.id;
                  elementCP.key = elementValue.id;break;
                case "date":
                  elementCP.editId = elementValue.id;
                  elementCP.key = elementValue.id;break;
                case "select":
                  elementCP.editId = elementValue.id;
                  elementCP.key = elementValue.id;break;
                case "allocation":
                  elementCP.editId = elementValue.id;
                  break;
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (subcontoIsChanged) {
      rebuild();
    }
  }, [subcontoIsChanged]);

  const generateCF = (item: CustomField): ReactNode => {
    switch (item.type) {
      case "text":
        return (
          <CPTextTypeRef
            key={item.refId}
            id={item.id}
            name={item.name}
            ref={item.ref}
            required={item.required}
            regexp={item.regexp}
            regexpText={item.regexpText}
            changeable={item.isChangeable}
            permissions={permissions}
            codeName={item.codeName}
            tabIndex={props.tabIndex}
            isWidget={props.isWidget}
          />
        );
      case "date":
        return (
          <CPDateTypeRef
            key={item.refId}
            id={item.id}
            name={item.name}
            ref={item.ref}
            required={item.required}
            regexp={item.regexp}
            regexpText={item.regexpText}
            changeable={item.isChangeable}
            permissions={permissions}
            codeName={item.codeName}
            tabIndex={props.tabIndex}
            isWidget={props.isWidget}
          />
        );
      case "select":
        return (
          <CPSelectTypeRef
            type={props.type}
            setCustomPropertyData={setCustomProperty}
            customPropertyData={customProperty}
            dataExpense={props.dataExpense}
            key={item.refId}
            id={item.id}
            keyId={item.key}
            name={item.name}
            ref={item.ref}
            required={item.required}
            regexp={item.regexp}
            regexpText={item.regexpText}
            onChangeDependency={changeDependency}
            changeable={item.isChangeable}
            permissions={permissions}
            codeName={item.codeName}
            tabIndex={props.tabIndex}
            isWidget={props.isWidget}
          />
        );
      case "allocation":
        return (
          <CPAllocationTypeRef
            type={props.type}
            dataExpense={props.dataExpense}
            key={item.refId}
            keyId={item.key}
            id={item.id}
            name={item.name}
            ref={item.ref}
            required={item.required}
            regexp={item.regexp}
            regexpText={item.regexpText}
            onChangeDependency={changeDependency}
            onDeleteRow={onDeleteRow}
            changeable={item.isChangeable}
            permissions={permissions}
            codeName={item.codeName}
            tabIndex={props.tabIndex}
            isWidget={props.isWidget}
          />
        );
      default:
        return null;
    }
  };

  const save = async (id: number, companyId?: number) => {
    dispatch(loaderLock());

    let objectLogicalNameId = await getObjectLogicalId();

    let cFData = [] as any[];

    сustomFields.forEach((item) => {
      let cFValue = item.ref.current.getValue();

      switch (cFValue.type) {
        case "select":
          if (cFValue.value.value !== 0) {
            cFData.push({
              parentkey: item.parentKey,
              key: item.key,
              customPropertyId: item.id,
              customPropertyListValueId: cFValue.value.value,
              isAllocation: false,
            });
          }
          break;

        case "text":
          if (cFValue.value.trim() !== "") {
            cFData.push({
              parentkey: item.parentKey,
              key: item.key,
              customPropertyId: item.id,
              nameEn: cFValue.value,
              nameRu: cFValue.value,
              isAllocation: false,
            });
          }
          break;
        case "date":
          if (cFValue.value.trim() !== "") {
            cFData.push({
              parentkey: item.parentKey,
              key: item.key,
              customPropertyId: item.id,
              nameEn: cFValue.value,
              nameRu: cFValue.value,
              isAllocation: false,
            });
          }
          break;
        case "allocation":
          cFValue.value.forEach((value: any) => {
            cFData.push({
              parentkey: item.parentKey,
              key: `${value.id}`,
              customPropertyId: item.id,
              customPropertyListValueId: +value.value,
              percent: +value.percent,
              isAllocation: true,
            });
          });
          break;
      }
    });

    let trustCompanyId = companyId
      ? companyId
      : props.companyId
      ? props.companyId
      : undefined;

    let data = {
      objectLogicalNameId: objectLogicalNameId,
      externalObjectId: id,
      companyId: trustCompanyId,
      valueItems: cFData,
    };
    let result = await postCustomPropertyValuesByObject(data);
    if (!result.headers.success) {
      dispatch(
        showErrors({ code: "", message: "Не удалось сохранить доп. данные" })
      );
    }

    await activtion();

    dispatch(loaderUnlock());
  };

  const edit = async () => {
    dispatch(loaderLock());

    let objectLogicalNameId = await getObjectLogicalId();

    let cFData = [] as any[];

    сustomFields.forEach((item) => {
      let cFValue = item.ref.current.getValue();

      switch (cFValue.type) {
        case "select":
          if (cFValue.value.value !== 0) {
            cFData.push({
              id: item.editId ? item.editId : undefined,
              isDeleted: false,
              parentkey: item.parentKey,
              key: item.key,
              customPropertyId: item.id,
              customPropertyListValueId: cFValue.value.value,
              isAllocation: false,
            });
          } else {
            if (!!item.editId) {
              cFData.push({
                id: item.editId,
                isDeleted: true,
                parentkey: item.parentKey,
                key: item.key,
                customPropertyId: item.id,
                customPropertyListValueId: cFValue.value.value,
                isAllocation: false,
              });
            }
          }
          break;

        case "text":
          if (cFValue.value.trim() !== "") {
            cFData.push({
              id: item.editId ? item.editId : undefined,
              isDeleted: false,
              parentkey: item.parentKey,
              key: item.key,
              customPropertyId: item.id,
              nameEn: cFValue.value,
              nameRu: cFValue.value,
              isAllocation: false,
            });
          } else {
            if (!!item.editId) {
              cFData.push({
                id: item.editId,
                isDeleted: true,
                parentkey: item.parentKey,
                key: item.key,
                customPropertyId: item.id,
                nameEn: cFValue.value,
                nameRu: cFValue.value,
                isAllocation: false,
              });
            }
          }
          break;

        case "date":
          if (cFValue.value.trim() !== "") {
            cFData.push({
              id: item.editId ? item.editId : undefined,
              isDeleted: false,
              parentkey: item.parentKey,
              key: item.key,
              customPropertyId: item.id,
              nameEn: cFValue.value,
              nameRu: cFValue.value,
              isAllocation: false,
            });
          } else {
            if (!!item.editId) {
              cFData.push({
                id: item.editId,
                isDeleted: true,
                parentkey: item.parentKey,
                key: item.key,
                customPropertyId: item.id,
                nameEn: cFValue.value,
                nameRu: cFValue.value,
                isAllocation: false,
              });
            }
          }
          break;

        case "allocation":
          cFValue.value.forEach((value: any) => {
            cFData.push({
              id: value.editedValue ? value.id : undefined,
              isDeleted: false,
              parentkey: item.parentKey,
              key: value.id,
              customPropertyId: item.id,
              customPropertyListValueId: +value.value,
              percent: +value.percent,
              isAllocation: true,
            });
          });
          break;
      }
    });

    let editedCFId = cFData.map((item) => item.id);

    deleteDependentCF
      .filter((item) => {
        return item.editId !== 0;
      })
      .filter((item) => !editedCFId.includes(item.editId))
      .forEach((item) => {
        switch (item.type) {
          case "select":
            cFData.push({
              id: item.editId,
              isDeleted: true,
              parentkey: item.parentKey,
              key: item.key,
              customPropertyId: item.id,
              customPropertyListValueId: 0,
              isAllocation: false,
            });
            break;

          case "text":
            cFData.push({
              id: item.editId,
              isDeleted: true,
              parentkey: item.parentKey,
              key: item.key,
              customPropertyId: item.id,
              nameEn: "",
              nameRu: "",
              isAllocation: false,
            });
            break;

          case "date":
            cFData.push({
              id: item.editId,
              isDeleted: true,
              parentkey: item.parentKey,
              key: item.key,
              customPropertyId: item.id,
              nameEn: "",
              nameRu: "",
              isAllocation: false,
            });
            break;
        }
      });

    deleteAllocationRow
      .filter((item) => {
        return !!item.editedValue;
      })
      .filter((item) => !editedCFId.includes(item.id))
      .forEach((item) => {
        cFData.push({
          id: item.id,
          isDeleted: true,
          parentkey: "",
          key: item.id,
          customPropertyId: item.customPropertyId,
          customPropertyListValueId: item.value,
          percent: item.percent,
          isAllocation: true,
        });
      });

    let editData = {
      objectLogicalNameId: objectLogicalNameId,
      externalObjectId: props.id,
      companyId: props.companyId ? props.companyId : undefined,
      valueItems: cFData,
    };

    let result = await updateCustomPropertyValues(editData);
    if (!result.headers.success) {
      dispatch(
        showErrors({
          code: "",
          message: "Не удалось редактировать доп. данные",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  const onDeleteRow = async (
    value: {
      id: string;
      value: number;
      name: string;
      percent: number;
      validSelect: boolean;
      validPercent: boolean;
    }[]
  ) => {
    await setDeleteAllocationRow([...deleteAllocationRow, ...value]);
  };

  const activtion = async () => {
    /*
    let data = {
      objectLogicalNameId: objectLogicalNameId,
      externalObjectId: props.id
    }
    let result = await Api.post(`/CustomPropertyValues/byobject`, data);
    if (result.headers.success) {

    }
    */
  };

  return (
    <>
      <div className="box-modal-form-block custom-property">{render()}</div>
    </>
  );
};

export default forwardRef(CustomProperty);

interface CPTextTypeProps {
  id: number;
  name: string;
  required?: boolean;
  readOnly?: boolean;
  onChange?: (value: string) => void;
  changeable?: boolean;
  permissions: string[];
  codeName: string;
  regexp?: string;
  regexpText?: string;
  tabIndex?: -1 | 0;
  isWidget?: boolean;
}

const CPTextType: React.FC<CPTextTypeProps> = (props, ref) => {
  let { t, i18n } = useTranslation();

  let [value, setValue] = useState("");
  let [startValue, setStartValue] = useState("");
  let [valid, setValid] = useState(true);

  const handleChange = (event: any) => {
    let newValue = event.target.value;
    setValue(newValue);
    validate(newValue);
    props.onChange && props.onChange(event.target.value);
  };

  const validate = (checkedValue: string) => {
    let isValid = true;

    if (props.required) {
      isValid = checkedValue.trim() !== "";
    }

    if (props.regexp && checkedValue.trim() !== "") {
      let re = new RegExp(props.regexp);
      if (!re.test(checkedValue)) {
        isValid = false;
      }
    }

    setValid(isValid);

    return isValid;
  };

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return { type: "text", value };
    },
    setValue: (value: any) => {
      setValue(value);
    },
    setStartValue: (value: any) => {
      setStartValue(value);
    },
    getStartValue: () => {
      return startValue;
    },
    getType: () => {
      return "text";
    },
    clear: () => {
      setValue("");
      setValid(true);
    },
    validate: () => {
      return validate(value);
    },
    isChanged: () => {
      return startValue.trim() !== value.trim();
    },
    build: () => {},
  }));

  return props.isWidget ? (
    <Form.Group
      className={
        "input-item " +
        (valid ? "" : "error") +
        (props.permissions.includes(`CustomProperty_${props.codeName}`)
          ? ""
          : " disabled")
      }
    >
      <Form.Label className="input-label">
        {props.name}
        {props.required && <i className="input-required">*</i>}{" "}
      </Form.Label>
      <Tooltip
        fontSize={14}
        width={350}
        textArray={[props.regexpText]}
        active={!valid}
        notHovered={true}
        block={true}
      >
        <Form.Control
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={"Введите значение "}
          disabled={
            !props.changeable ||
            !props.permissions.includes(`CustomProperty_${props.codeName}`)
          }
          style={{ fontSize: "16px" }}
          tabIndex={props.tabIndex}
        />
      </Tooltip>
    </Form.Group>
  ) : (
    <div
      className={
        "input-item " +
        (valid ? "" : "error") +
        (props.permissions.includes(props.codeName) ? "" : " disabled")
      }
    >
      {/* <div className={"input-item " + (valid ? '' : 'error')}> */}
      <label className="input-label">
        {props.name}
        {props.required && <i className="input-required">*</i>}{" "}
      </label>
      <Tooltip
        fontSize={14}
        width={350}
        textArray={[props.regexpText]}
        active={!valid}
        notHovered={true}
        block={true}
      >
        <input
          className="input"
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={"Введите значение "}
          disabled={!props.permissions.includes(props.codeName)}
          style={{ fontSize: "16px" }}
          tabIndex={props.tabIndex}
        />
      </Tooltip>
    </div>
  );
};

const CPTextTypeRef = forwardRef(CPTextType);

interface CPDateTypeProps {
  id: number;
  name: string;
  required?: boolean;
  readOnly?: boolean;
  onChange?: (value: string) => void;
  changeable?: boolean;
  permissions: string[];
  codeName: string;
  regexp?: string;
  regexpText?: string;
  tabIndex?: -1 | 0;
  isWidget?: boolean;
}

const CPDateType: React.FC<CPDateTypeProps> = (props, ref) => {
  let { t, i18n } = useTranslation();

  let [value, setValue] = useState(null as unknown as Date);
  let [startValue, setStartValue] = useState(null as unknown as Date);
  let [valid, setValid] = useState(true);
  let [isOpenDate, setOpenDate] = useState(false);

  const validate = (checkedValue: Date) => {
    let isValid = true;

    if (props.required) {
      isValid = !!checkedValue;
    }

    setValid(isValid);

    return isValid;
  };

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return {
        type: "date",
        value: value ? formatter("DD.MM.YYYY", i18n.language, value) : "",
      };
    },
    setValue: (value: any) => {
      setValue(toDate(value, "DD.MM.YYYY"));
    },
    setStartValue: (value: any) => {
      setStartValue(toDate(value, "DD.MM.YYYY"));
    },
    getStartValue: () => {
      return startValue
        ? formatter("DD.MM.YYYY", i18n.language, startValue)
        : "";
    },
    getType: () => {
      return "date";
    },
    clear: () => {
      setValue(null as unknown as Date);
      setValid(true);
    },
    validate: () => {
      return validate(value);
    },
    isChanged: () => {
      return startValue !== value;
    },
    build: () => {},
  }));

  const toggleDate = () => {
    setOpenDate(!isOpenDate);
  };

  const onSelectDate = (value: any) => {
    setValue(value.startDate);
    toggleDate();
    props.onChange &&
      props.onChange(formatter("DD.MM.YYYY", i18n.language, value.startDate));
  };

  return props.isWidget ? (
    <Form.Group
      className={
        "input-item " +
        (valid ? "" : "error") +
        (props.permissions.includes(`CustomProperty_${props.codeName}`)
          ? ""
          : " disabled")
      }
    >
      <Form.Label className="input-label">
        {props.name}
        {props.required && <i className="input-required">*</i>}{" "}
      </Form.Label>
      <div className="input-date">
        <Form.Control
          type="text"
          className={"pointer"}
          value={value ? formatter("DD.MM.YYYY", i18n.language, value) : ""}
          onClick={toggleDate}
          tabIndex={props.tabIndex}
          disabled={
            !props.permissions.includes(`CustomProperty_${props.codeName}`)
          }
        />
        <SvgIcon
          className="icon icon-calendar pointer"
          href="#svg_icon_calendar"
        />
      </div>
      <DatePickerCalendar
        startDate={value}
        isCleansed={true}
        isMultiChoice={false}
        isShown={isOpenDate}
        onSelected={onSelectDate}
        month={value ? value : new Date()}
        onClose={toggleDate}
      />
    </Form.Group>
  ) : (
    <div
      className={
        "input-item " +
        (valid ? "" : "error") +
        (props.permissions.includes(props.codeName) ? "" : " disabled")
      }
    >
      <label className="input-label">
        {props.name}
        {props.required && <i className="input-required">*</i>}{" "}
      </label>
      <div className="input-date">
        <input
          className="input pointer"
          type="text"
          value={value ? formatter("DD.MM.YYYY", i18n.language, value) : ""}
          onClick={toggleDate}
          readOnly
          tabIndex={props.tabIndex}
        />
        <SvgIcon
          className="icon icon-calendar pointer"
          href="#svg_icon_calendar"
        />
      </div>
      <DatePickerCalendar
        startDate={value}
        isCleansed={true}
        isMultiChoice={false}
        isShown={isOpenDate}
        onSelected={onSelectDate}
        month={value ? value : new Date()}
        onClose={toggleDate}
      />
    </div>
  );
};

const CPDateTypeRef = forwardRef(CPDateType);

interface CPSelectTypeProps {
  id: number;
  keyId: string;
  name: string;
  required?: boolean;
  readOnly?: boolean;
  regexp?: string;
  regexpText?: string;
  onChange?: (value: SelectOptionType) => void;
  onChangeDependency: (
    id: number,
    listValueId: number[],
    propertyId: number[],
    key: string
  ) => void;
  changeable?: boolean;
  permissions: string[];
  codeName: string;
  dataExpense: any;
  tabIndex?: -1 | 0;
  customPropertyData: CustomPropertyData;
  setCustomPropertyData: any;
  type: string;
  isWidget?: boolean;
}

const CPSelectType: React.FC<CPSelectTypeProps> = (props, ref) => {
  let { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  let [value, setValue] = useState({ text: "", value: 0 });
  let [option, setOption] = useState([] as SelectOptionType[]);
  let [startValue, setStartValue] = useState({ text: "", value: 0 });
  let [allowedValue, setAllowedValue] = useState([] as number[]);
  let [valid, setValid] = useState(true);

  const subcontoData = useSelector(
    (state: ApplicationState) => state.common.subcontoData
  );
  const subcontoIsChanged = useSelector(
    (state: ApplicationState) => state.common.subcontoIsChanged
  );
  const costCenterIsChanged = useSelector(
    (state: ApplicationState) => state.common.costCenterIsChanged
  );
  const costCenterData = useSelector(
    (state: ApplicationState) => state.common.costCenterData
  );

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return { type: "select", value };
    },
    setValue: async (newValue: any) => {
      setValue(newValue);
      handleChangeDependency(newValue.value);
      checkAllowedCurrentValues(newValue.value);
    },
    setAllowedValue: (newValue: any) => {
      setAllowedValue(newValue);
      checkAllowedCurrentValues(value.value);
    },
    setStartValue: (newValue: any) => {
      setStartValue(newValue);
    },
    getStartValue: () => {
      return startValue;
    },
    getType: () => {
      return "select";
    },
    updateValue: () => {
      build();
    },
    clear: () => {
      setValue({ text: "", value: 0 });
      setValid(true);
    },
    validate: () => {
      let isValid = true;

      if (props.required) {
        isValid = value.value !== 0;
      }

      setValid(isValid);
      return isValid;
    },
    isChanged: () => {
      return startValue.value !== value.value;
    },
    build: async () => {
      await build();
    },
  }));

  const build = async () => {
    let cPRequest = await postCustomPropertiesListValues({
      CustomPropertyId: props.id,
      Object: {
        LogicalName: props.type,
        ...props.dataExpense,
      },
      ObjectCustomProperties: {
        [props.customPropertyData.customPropertyId || 0]: {
          Value: props.customPropertyData.text,
          ListValueId: props.customPropertyData.value,
        },
      },
    });
    let rawCPList = [] as { id: number; name: { ru: string; en: string } }[];
    if (cPRequest.headers.success) {
      rawCPList = cPRequest.data.map((item: any) => {
        return {
          id: item.id,
          name: { ru: item.nameRu, en: item.nameEn },
          customPropertyId: item.customPropertyId,
          customPropertyCode: item.customPropertyCode,
        };
      });
    }

    let rusult = rawCPList.map((item: any) => {
      return {
        value: item.id,
        text: item.name[i18n.language as "ru"],
        customPropertyId: item.customPropertyId,
        customPropertyCode: item.customPropertyCode,
      };
    });
    if (subcontoIsChanged && rusult[0].customPropertyCode === "Subconto") {
      props.setCustomPropertyData(subcontoData);
      setValue(subcontoData);
      dispatch(setSubcontoIsChanged(false));
    } else {
      if (rusult[0].customPropertyCode === "Cost account" && costCenterData) {
        const isExistCostAccount = isExist(costCenterData, rusult);
        if (isExistCostAccount) {
          props.setCustomPropertyData(costCenterData);
          setValue(costCenterData);
        } else {
          dispatch(setCostCenterIsChanged(false));
          dispatch(setCostCenterData(null));
          props.setCustomPropertyData(rusult[0]);
          setValue(rusult[0]);
        }
      } else {
        props.setCustomPropertyData(rusult[0]);
        setValue(rusult[0]);
      }
    }
    setOption(rusult);
  };

  const handleChange = async (option: any) => {
    if (option.customPropertyCode === "Subconto") {
      dispatch(setSubcontoIsChanged(true));
      dispatch(setSubcontoData(option));
    } else if (option.customPropertyCode === "Cost account") {
      dispatch(setCostCenterIsChanged(true));
      dispatch(setCostCenterData(option));
    }
    props.onChange && props.onChange(option);
    setValid(true);
    setValue(option);

    handleChangeDependency(option.value);
  };

  const handleChangeDependency = async (valueId: number) => {
    if (!valueId || valueId === 0) {
      return;
    }

    let cPRequest = await getCustomPropertyListValuesDependencies({
      params: { CustomPropertyListValueIds: valueId },
    });
    if (cPRequest.headers.success && cPRequest.data[0]) {
      props.onChangeDependency(
        props.id,
        cPRequest.data[0].valueOnValue,
        cPRequest.data[0].propertyOnValue,
        props.keyId
      );
    }
  };

  const filterAllowedValues = (values: SelectOptionType[]) => {
    return values.filter(
      (item) => allowedValue.length === 0 || allowedValue.includes(+item.value)
    );
  };

  const checkAllowedCurrentValues = async (newValue: number) => {
    if (allowedValue.length !== 0 && !allowedValue.includes(newValue)) {
      await setValue({ text: "", value: 0 });
    }
  };

  useEffect(() => {
    let filteredOption = option.filter(
      (item) => allowedValue.length === 0 || allowedValue.includes(+item.value)
    );
    if (filteredOption.length === 1) {
      setValue({
        text: filteredOption[0].text,
        value: +filteredOption[0].value,
      });
      handleChangeDependency(+filteredOption[0].value);
      checkAllowedCurrentValues(+filteredOption[0].value);
    }
  }, [option, allowedValue]);

  const isExist = (defaultValue: SelectOptionType, rusult?: any) => {
    const allowed = rusult || filterAllowedValues(option);
    let bool = false;
    allowed.forEach((item: any) => {
      if (item.value === defaultValue.value) {
        bool = true;
      }
    });
    return bool;
  };

  return props.isWidget ? (
    <Form.Group
      className={
        "input-item " +
        (valid ? "" : "error") +
        (props.permissions.includes(`CustomProperty_${props.codeName}`)
          ? ""
          : " disabled")
      }
    >
      <Form.Label className="input-label">
        {props.name}
        {props.required && <i className="input-required">*</i>}
      </Form.Label>
      {filterAllowedValues(option).length > 20 ? (
        <SelectAutocomplete
          name={props.name}
          options={filterAllowedValues(option)}
          defaultValue={value}
          onChoose={handleChange}
          readOnly={props.readOnly}
          disabled={
            !props.changeable ||
            !props.permissions.includes(`CustomProperty_${props.codeName}`)
          }
          tabIndex={props.tabIndex}
          isWidget={props.isWidget}
        />
      ) : (
        <Select
          options={filterAllowedValues(option)}
          defaultValue={value}
          onChoose={handleChange}
          readOnly={props.readOnly}
          disabled={
            !props.changeable ||
            !props.permissions.includes(`CustomProperty_${props.codeName}`)
          }
          isWidget={props.isWidget}
        />
      )}
    </Form.Group>
  ) : (
    <div
      className={
        "input-item " +
        (valid ? "" : "error") +
        (props.permissions.includes(props.codeName) ? "" : " disabled")
      }
    >
      <label className="input-label">
        {props.name}
        {props.required && <i className="input-required">*</i>}
      </label>
      {filterAllowedValues(option).length > 20 ? (
        <SelectAutocomplete
          name={props.name}
          options={filterAllowedValues(option)}
          defaultValue={isExist(value) ? value : null}
          onChoose={handleChange}
          readOnly={props.readOnly}
          disabled={!props.permissions.includes(props.codeName)}
          tabIndex={props.tabIndex}
        />
      ) : (
        <Select
          options={filterAllowedValues(option)}
          defaultValue={isExist(value) ? value : null}
          onChoose={handleChange}
          readOnly={props.readOnly}
          disabled={!props.permissions.includes(props.codeName)}
        />
      )}
    </div>
  );
};

const CPSelectTypeRef = forwardRef(CPSelectType);

interface CPAllocationTypeProps {
  id: number;
  keyId: string;
  name: string;
  required?: boolean;
  readOnly?: boolean;
  regexp?: string;
  regexpText?: string;
  onChange?: (
    value: {
      id: string;
      value: number;
      name: string;
      percent: number;
      validSelect: boolean;
      validPercent: boolean;
    }[]
  ) => void;
  onChangeDependency: (
    id: number,
    listValueId: number[],
    propertyId: number[],
    key: string
  ) => void;
  onDeleteRow?: (
    value: {
      id: string;
      value: number;
      name: string;
      percent: number;
      validSelect: boolean;
      validPercent: boolean;
    }[]
  ) => void;
  changeable?: boolean;
  permissions: string[];
  codeName: string;
  dataExpense: any;
  tabIndex?: -1 | 0;
  type: string;
  isWidget?: boolean;
}

const CPAllocationType: React.FC<CPAllocationTypeProps> = (props, ref) => {
  let { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  let [value, setValue] = useState(
    [] as {
      id: string;
      value: number;
      name: string;
      percent: number;
      validSelect: boolean;
      validPercent: boolean;
      editedValue?: boolean;
    }[]
  );
  let [percentOptions, setPercentOptions] = useState(
    props.isWidget ? widgetPercentOptions : defaultPercentOptions
  );
  let [option, setOption] = useState([] as SelectOptionType[]);
  let [startValue, setStartValue] = useState(value);
  let [valid, setValid] = useState(true);
  let [validPercent, setValidPercent] = useState(true);
  let [allowedValue, setAllowedValue] = useState([] as number[]);

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return { type: "allocation", value };
    },
    setValue: (value: any) => {
      setValue(value);
      value.forEach((item: any) => {
        handleChangeDependency(item.value, item.id); // TODO
      });
      checkAllowedCurrentValues(value);
    },
    addRow: async (value: any) => {
      await addRowIh(value);
    },
    setAllowedValue: (newValue: any) => {
      setAllowedValue(newValue);
      checkAllowedCurrentValues(value);
    },
    setStartValue: (value: any) => {
      setStartValue(value);
    },
    getStartValue: () => {
      return startValue;
    },
    getType: () => {
      return "allocation";
    },
    clear: () => {
      setValue([]);
      setValid(true);
    },
    validate: () => {
      let isValid = true;
      let isValidPercent = true;
      let newValue = [...value];

      if (props.required) {
        let percentSum = 0;

        newValue.forEach((item) => {
          if (!+item.value) {
            isValid = false;
            item.validSelect = false;
          }

          if (!+item.percent) {
            isValid = false;
            item.validPercent = false;
          }
          percentSum += +item.percent;
        });

        let allIds = newValue.map((item) => item.value);
        newValue.forEach((item) => {
          if (allIds.filter((it) => it === item.value).length > 1) {
            isValid = false;
            item.validSelect = false;
          }
        });

        if (percentSum !== 100) {
          isValid = false;
          isValidPercent = false;
          dispatch(
            showErrors({
              code: "",
              message: t("custom_property.allocation.not_hundred_percent", {
                name: props.name,
                percent: percentSum,
              }),
            })
          );
        }
      }

      setValid(isValid);
      setValidPercent(isValidPercent);
      setValue(newValue);
      return isValid;
    },
    isChanged: () => {
      let newValueKey = value.map((item) => item.value + "_" + item.percent);
      let oldValueKey = startValue.map(
        (item) => item.value + "_" + item.percent
      );
      let diffValueKey = [
        ...newValueKey.filter((item) => !oldValueKey.includes(item)),
        ...oldValueKey.filter((item) => !newValueKey.includes(item)),
      ];
      return diffValueKey.length > 0;
    },
    build: async () => {
      await build();
    },
  }));

  const addRow = () => {
    let newValue = [...value];
    newValue.push({
      id: `${props.id}-${getNextKey("CPAllocation")}`,
      value: 0,
      name: "",
      percent: 0,
      validSelect: true,
      validPercent: true,
    });
    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  const addRowIh = async (newValue: any) => {
    let newValues = [...value];
    newValues.push({
      id: newValue.id,
      value: newValue.value,
      name: newValue.name,
      percent: newValue.percent,
      validSelect: newValue.validSelect,
      validPercent: newValue.validPercent,
      editedValue: true,
    });
    setValue(newValues);
    await handleChangeDependency(newValue.value, newValue.id);
    props.onChange && (await props.onChange(newValue));
  };

  const deleteRow = async (id: string) => {
    let newValue = [...value];
    let newDeleteValue = [];
    newValue = value.filter((item) => item.id !== id);
    newDeleteValue = value
      .filter((item) => item.id === id)
      .map((item) => {
        return { ...item, customPropertyId: props.id };
      });
    setValue(newValue);
    await props.onChangeDependency(props.id, [], [], id);
    props.onChange && (await props.onChange(newValue));
    props.onDeleteRow && (await props.onDeleteRow(newDeleteValue));
  };

  const build = async () => {
    let cPRequest = await getCustomPropertyListValues({
      params: { CustomPropertyId: props.id },
    });
    let rawCPList = [] as { id: number; name: { ru: string; en: string } }[];
    if (cPRequest.headers.success) {
      rawCPList = cPRequest.data.map((item: any) => {
        return { id: item.id, name: { ru: item.nameRu, en: item.nameEn } };
      });

      let rusult = [] as SelectOptionType[];

      rusult = rawCPList.map((item) => {
        return { value: item.id, text: item.name[i18n.language as "ru"] };
      });

      setOption(rusult);
    }

    let rawCP = [
    ] as any[];

    let newValue = rawCP.map((item) => ({
      id: `${props.id}-${getNextKey("CPAllocation")}`,
      value: item.value,
      name: item.name,
      percent: item.percent,
      validSelect: true,
      validPercent: true,
    }));
    setValue(newValue);
  };

  const handleChangeSelect = async (option: any, row: any) => {
    let newValue = value.map((item) => {
      if (item.id === row.id) {
        return {
          ...item,
          value: option.value,
          name: option.text,
          validSelect: true,
        };
      } else {
        return item;
      }
    });
    setValue(newValue);
    props.onChange && props.onChange(newValue);

    handleChangeDependency(option.value, row.id);
  };

  const handleChangeDependency = async (valueId: number, rowId: string) => {
    if (!valueId || valueId === 0) {
      return;
    }

    let cPRequest = await getCustomPropertyListValuesDependencies({
      params: { CustomPropertyListValueIds: valueId },
    });
    if (cPRequest.headers.success && cPRequest.data[0]) {
      await props.onChangeDependency(
        props.id,
        cPRequest.data[0].valueOnValue,
        cPRequest.data[0].propertyOnValue,
        rowId
      );
    }
  };

  const handleChangePercent = async (option: any, row: any) => {
    let newValue = value.map((item) => {
      if (item.id === row.id) {
        return { ...item, percent: option, validPercent: true };
      } else {
        return item;
      }
    });
    setValue(newValue);
  };

  const filterAllowedValues = (values: SelectOptionType[]) => {
    return option.filter(
      (item) => allowedValue.length === 0 || allowedValue.includes(+item.value)
    );
  };

  const checkAllowedCurrentValues = (
    newValue: {
      id: string;
      value: number;
      name: string;
      percent: number;
      validSelect: boolean;
      validPercent: boolean;
    }[]
  ) => {
    if (allowedValue.length !== 0) {
      let newValues = [] as {
        id: string;
        value: number;
        name: string;
        percent: number;
        validSelect: boolean;
        validPercent: boolean;
      }[];
      let changed = false;
      newValue.forEach((item) => {
        if (!allowedValue.includes(item.value)) {
          newValues.push({ ...item, value: 0, name: "" });
          changed = true;
        } else {
          newValues.push({ ...item });
        }
      });
      if (changed) {
        setValue(newValues);
      }
    }
  };

  return (
    <>
      {value.map((item, index) => {
        return (
          <div key={item.id} className="input-item-row">
            <div className={"input-item " + (item.validSelect ? "" : "error")}>
              <label className="input-label">
                {props.name} {index + 1}
                {props.required && <i className="input-required">*</i>}
              </label>
              {filterAllowedValues(option).length > 20 ? (
                <SelectAutocomplete
                  name={props.name}
                  options={filterAllowedValues(option)}
                  defaultValue={{ text: item.name, value: item.id }}
                  readOnly={props.readOnly}
                  disabled={!props.permissions.includes(props.codeName)}
                  onChoose={(option) => handleChangeSelect(option, item)}
                  tabIndex={props.tabIndex}
                  isWidget={props.isWidget}
                />
              ) : (
                <Select
                  options={filterAllowedValues(option)}
                  defaultValue={{ text: item.name, value: item.id }}
                  readOnly={props.readOnly}
                  onChoose={(option) => handleChangeSelect(option, item)}
                  disabled={!props.permissions.includes(props.codeName)}
                  isWidget={props.isWidget}
                />
              )}
            </div>
            <div
              className={
                "input-item input-item--little" +
                (validPercent && item.validPercent ? "" : "error") +
                (props.permissions.includes(props.codeName) ? "" : " disabled")
              }
              style={{ width: "20%" }}
            >
              {/* <div className={"input-item " + (validPercent && item.validPercent ? '' : 'error')} style={{ width: '20%' }}> */}
              <label className="input-label">
                {t("custom_property.allocation.percent")} {index + 1}
                {props.required && <i className="input-required">*</i>}
              </label>
              <CostCenterPercentAutocomplete
                onChoose={(option) => handleChangePercent(option, item)}
                defaultText={item.percent ? item.percent.toString() : ""}
                options={percentOptions}
                readOnly={props.readOnly}
                disabled={!props.permissions.includes(props.codeName)}
                tabIndex={props.tabIndex}
                isWidget={props.isWidget}
              />
            </div>
            <div
              className={`input-item input-remove ${
                props.permissions.includes(props.codeName) ? "" : " disabled"
              }`}
              style={{ width: "22px" }}
            >
              {/* <div className={`input-item input-remove ${props.changeable ? '' : 'disabled'}`} style={{ width: '22px' }}> */}
              {value.length > 1 && !props.readOnly && props.changeable && (
                <>
                  <label className="input-label" />
                  <a
                    onClick={() => {
                      deleteRow(item.id);
                    }}
                    title={"delete"}
                    tabIndex={props.tabIndex}
                  >
                    <SvgIcon
                      className="icon icon-remove"
                      href="#svg_icon_remove"
                    />
                  </a>
                </>
              )}
              {value.length > 1 && (props.readOnly || !props.changeable) && (
                <>
                  <label className="input-label" />
                  <a style={{ opacity: 0.5 }} tabIndex={props.tabIndex}>
                    <SvgIcon
                      className="icon icon-remove"
                      href="#svg_icon_remove"
                    />
                  </a>
                </>
              )}
            </div>
          </div>
        );
      })}

      <div className="input-item-row">
        <div className="cost-centers">
          {props.permissions.includes(props.codeName) ? (
            <div className={`tx-center`}>
              <a
                className="btn-add-cost pointer"
                onClick={addRow}
                tabIndex={props.tabIndex}
              >
                <SvgIcon className="icon icon-plus" href="#svg_icon_plus" />
                {t("custom_property.allocation.add", {
                  property: props.name.toLowerCase(),
                })}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const CPAllocationTypeRef = forwardRef(CPAllocationType);
