import ProfileMenu from 'app/component/profile-user/ProfileMenu';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import SvgIcon from '../svg-icon/SvgIcon';
import {NameFormats, nameFormatter } from 'app/utils';
import {getCommonUserDetail} from "../../../store/selectors";

interface ProfileUserProps {
  accessDenied: boolean,
  isMobile?: boolean,
  onCloseMobile?: () => void
}

const ProfileUser: React.FC<ProfileUserProps> = (props) => {
  const {t, i18n} = useTranslation();

  const user = useSelector(getCommonUserDetail);

  const [isShowMenu, setShowMenu] = useState(false);

  const openMenuClickHandler = () => {
    setShowMenu(!isShowMenu);
  };

  let roleName = {} as {ru: string, en: string};
  if (user.occupations.length === 1) {
    roleName = user.occupations[0].name;
  }

  return (
      <div className="profile-user-wrap">
        <a className="profile-user pointer" onClick={openMenuClickHandler}>
          <SvgIcon className="icon icon-user" href="#svg_icon_user"/>
          <div className="profile-user__info">
            <div className="profile-user__name">{user.name ? nameFormatter(user.name, NameFormats.NAME_WITH_LASTNAME, i18n.language as 'en' | 'ru') : ""}</div>
            <div className="profile-user__role">{`${user.occupations.length === 1 ? roleName[i18n.language as 'en' | 'ru'] : `${t('profile.number_occupations')}: ${user.occupations.length}`}`}</div>
          </div>
        </a>
        <ProfileMenu accessDenied={props.accessDenied} isMobile={props.isMobile} toggleMobile={props.onCloseMobile} isOpen={isShowMenu} onClose={openMenuClickHandler}/>
      </div>
  );
}
export default ProfileUser;