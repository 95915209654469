import React, { useState, useEffect, useImperativeHandle, forwardRef, CSSProperties, useRef } from 'react';
import SvgIcon from 'app/component/svg-icon/SvgIcon';
import Slidebar from '../slidebar/Slidebar';
import TextCutter from '../text-cutter/TextCutter';

export interface SelectWithSectionProps {
    options: SelectWithSectionOptionType[],
    onChoose?: (option: SelectWithSectionOptionType) => void,
    defaultText?: string,
    defaultValue?: SelectWithSectionOptionType | any,
    readOnly?: boolean
    style?: CSSProperties
    disabled?: boolean
}

export interface SelectWithSectionRef {
    clear(): void
}

export interface SelectWithSectionOptionType {
    value: string | number,
    text: string,
    section: string
}

const SelectWithSection: React.RefForwardingComponent<SelectWithSectionRef, SelectWithSectionProps> = (props, ref) => {
    let [isOpen, setOpen] = useState(false);
    let [labelText, setLabelText] = useState(props.defaultValue && props.defaultValue.text ? props.defaultValue.text : (props.defaultText ? props.defaultText : '---'));
    let [sectionText, setSectionText] = useState(props.defaultValue && props.defaultValue.section ? props.defaultValue.section : '');
    let [width, setWidth] = useState(0);

    let nameRef = useRef({} as any)

    useEffect(() => {
        window.addEventListener('resize', resizeEventHander)
        return () => {
            window.removeEventListener('resize', resizeEventHander);
        }
    })

    useEffect(() => {
        resizeEventHander();
    }, [])

    const resizeEventHander = () => {
        setWidth(nameRef.current.offsetWidth);
    }

    const titleClickHandler = () => {
        if (props.readOnly) {
            return;
        } else {
            setOpen(!isOpen);
        }
    }

    const titleBlurHandler = () => {
        setOpen(false);
    }

    const optionClickHandler = (option: SelectWithSectionOptionType) => {
        setLabelText(option.text);
        setSectionText(option.section);
        setOpen(false);
        if (props.onChoose) props.onChoose(option);
    }

    const missClickHandler = () => {
        if (isOpen) {
            setOpen(false);
        }
    }

    useEffect(() => {
        if (props.defaultValue && props.defaultValue.text) {
            setLabelText(props.defaultValue.text);
            setSectionText(props.defaultValue.section);
        } else if (props.defaultText) {
            setLabelText(props.defaultText);
            setSectionText('');
        }
    }, [props.defaultValue]);

    useImperativeHandle(ref, () => ({
        clear: () => {
            setOpen(false);
            setLabelText(props.defaultValue && props.defaultValue.text ? props.defaultValue.text : (props.defaultText ? props.defaultText : '---'));
            setSectionText(props.defaultValue && props.defaultValue.section ? props.defaultValue.section : '');
        }
    }));

    return (
        <div className={`select ${isOpen ? 'active' : ''} ${props.disabled ? 'disabled' : ''}`} style={props.style ? props.style : undefined}>
            {isOpen ? <div className="miss-click-handler" onClick={missClickHandler} /> : ''}
            <div ref={nameRef} className={`select-title`} onClick={titleClickHandler} style={{ fontSize: '16px' }}>
                <div style={{ overflow: 'hidden', height: '40px', width: 'calc(100% - 145px)', textOverflow: 'ellipsis' }}>
                    {labelText}
                </div>
                <div style={{ width: '110px', position: 'absolute', right: '40px', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <span style={{ borderLeft: '1px solid #D9E2E7' }}>&nbsp;&nbsp;</span>
                    <b>{sectionText}</b>
                </div>
                <SvgIcon className="icon icon-arrow-top" href="#svg_icon_arrow_top" />
            </div>
            <Slidebar className="select-content" style={{ zIndex: 20, maxHeight: '372px', fontSize: '16px' }}>
                {props.options.map((option, index) => {
                    return <div className="select-content-item" key={index} onClick={() => optionClickHandler(option)}>
                        <div style={{ overflow: 'hidden', height: '17px', width: 'calc(100% - 145px)', textOverflow: 'ellipsis' }}>
                            {option.text}
                        </div>
                        <div style={{ width: '150px', position: 'absolute', right: '0px', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            <span style={{ borderLeft: '1px solid #D9E2E7' }}>&nbsp;&nbsp;</span>
                            <b>{option.section}</b>
                        </div>
                    </div>
                })}
            </Slidebar>
        </div>
    );
}

export default forwardRef(SelectWithSection);
